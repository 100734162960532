import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import { Screens } from 'shared-parts/constants/screens';
/* eslint-disable */
const StyledBubble = styled.div`
   width: ${({ size = '20px' }) => size};
   height: ${({ size = '20px' }) => size};
   border-radius: 15px;
   background-color: ${({ backgroundColor = COLORS.systemRed }) => backgroundColor};
   color: ${COLORS.white};
   font-family: ${FONTS.mainFont};
   font-size: 14px;
   position: absolute;
   top: 4px;
   right: 2px;
   text-align: center;

   @media (max-width: ${Screens.xl}) {
     top: ${({ center }) =>
       center
         ? `50%;
         transform: translateY(-50%);
        `
         : '4px;'}
`;

export { StyledBubble };
/* eslint-enable */
