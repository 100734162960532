import type { ReactChild } from 'react';
import { createContext, useContext } from 'react';

import { EnvVar, useEnvVar } from '@frontend/config';

import { AppConfig, EnvironmentType, FeatureFlag } from './types';

type Props = {
  children: ReactChild;
};

const isEnabled = (feature: EnvVar) =>
  (typeof feature === 'string' && feature.toLowerCase() === 'true') ||
  (typeof feature === 'boolean' && feature);

const getEnabledFeatureFlags = () =>
  Object.values(FeatureFlag).filter(flag => isEnabled(useEnvVar(flag)));

const initialContext: AppConfig = {
  featureFlags: getEnabledFeatureFlags(),
  sendbirdId: useEnvVar('SENDBIRD_APP_ID') as string,
  environment: useEnvVar('GLOBACAP_ENVIRONMENT') as EnvironmentType,
};

const AppConfigContext = createContext<AppConfig>(initialContext);

AppConfigContext.displayName = 'AppConfigContext';

const AppConfigProvider = ({ children }: Props) => (
  <AppConfigContext.Provider value={initialContext}>{children}</AppConfigContext.Provider>
);

const useAppConfig = () => useContext(AppConfigContext);

const useFeatureFlag = (flag: FeatureFlag): boolean => {
  const featureFlags = getEnabledFeatureFlags();
  return featureFlags.includes(flag);
};

export { AppConfigProvider, useAppConfig, useFeatureFlag };
