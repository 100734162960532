import { FC } from 'react';

import CloseIcon from 'shared-parts/components/svg-icons/close-icon';
import ErrorOutline from 'shared-parts/components/svg-icons/error-outline';
import { COLORS } from 'shared-parts/constants';

import { CH_ERROR_MODAL } from '../constants';
import { IconContainer, ModalContainer, StyledParagraph } from '../index.styled';

import { CloseButtonContainer, StyledHeading } from './ch-error-modal.styled';

const { neutral500 } = COLORS;

const CompaniesHouseErrorModal: FC<
  React.PropsWithChildren<{ hideModal: () => void; errorText: string }>
> = ({ hideModal, errorText }) => (
  <ModalContainer>
    <CloseButtonContainer>{CloseIcon(27, 27, neutral500, hideModal)}</CloseButtonContainer>
    <IconContainer>{ErrorOutline(40, 40)}</IconContainer>
    <StyledHeading>{CH_ERROR_MODAL.TITLE}</StyledHeading>
    <StyledParagraph>{errorText || CH_ERROR_MODAL.TEXT}</StyledParagraph>
  </ModalContainer>
);

export default CompaniesHouseErrorModal;
