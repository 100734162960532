import { COLORS } from 'shared-parts/constants';

const { highlight } = COLORS;

export interface NewPlusIcon {
  readonly color?: string;
}

export const NewPlusIcon = ({ color = highlight }: NewPlusIcon) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33301 8.66699L5.33301 1.33366"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path d="M9 5.00049L1.66667 5.00049" stroke={color} strokeWidth="1.5" strokeLinecap="square" />
  </svg>
);
