import * as constants from 'app-state/constants';

export const acceptTerms = (companyUuid, userUuid, formData, onSuccess, form) => ({
  type: constants.ACCEPT_TERMS,
  companyUuid,
  userUuid,
  formData,
  onSuccess,
  form,
});
export const acceptTermsError = error => ({ type: constants.ACCEPT_TERMS_ERROR, error });
