export const SAVE_USER_DATA = 'SAVE_USER_DATA';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const RESET_SIGN_UP = 'RESET_SIGN_UP';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const OAUTH2_LOGIN = 'OAUTH2_LOGIN';
export const OAUTH2_LOGOUT = 'OAUTH2_LOGOUT';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const TWO_FACTOR_AUTH_GET_CODE = 'TWO_FACTOR_AUTH_GET_CODE';
export const TWO_FACTOR_AUTH_GET_CODE_SUCCESS = 'TWO_FACTOR_AUTH_GET_CODE_SUCCESS';
export const TWO_FACTOR_AUTH_GET_CODE_ERROR = 'TWO_FACTOR_AUTH_GET_CODE_ERROR';

export const TWO_FACTOR_AUTH_GET_CODE_CLEAR = 'TWO_FACTOR_AUTH_GET_CODE_CLEAR';

export const TWO_FACTOR_AUTH_CONFIRM = 'TWO_FACTOR_AUTH_CONFIRM';
export const TWO_FACTOR_AUTH_DISABLE = 'TWO_FACTOR_AUTH_DISABLE';
