import styled from 'styled-components';

import { Link } from 'shared-parts/components';
import { COLORS, FONTS } from 'shared-parts/constants';

const Container = styled.div`
  align-items: center;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  padding: 20px 0;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  text-align: center;
`;

const Header = styled.div`
  color: ${COLORS.primary};
  font-family: ${FONTS.oswaldFont};
  font-size: 30px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 300)};
  letter-spacing: 0.1px;
  line-height: 35px;
  margin-bottom: 20px;
`;

const LoginLink = styled(Link)`
  color: ${COLORS.primary};
  user-select: none;
`;

export { Container, Header, Wrapper, LoginLink };
