import { COLORS } from 'shared-parts/constants';

const TableIcon = (fillColor = COLORS.highlight, height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="UI-Style-Guide">
          <g id="Group_3777" data-name="Group 3777">
            <rect
              id="Rectangle_2314"
              data-name="Rectangle 2314"
              className="icon"
              width="40"
              height="40"
              rx="2"
            />
            <g id="Group_3775" data-name="Group 3775">
              <path
                id="Path_1558"
                fill="#eeeff3"
                d="M11,25h2V23H11Zm2,4V27H11a2,2,0,0,0,2,2Zm6-8H15v4h4Zm-8-4h2V15H11Zm0-4h2V11a2,2,0,0,0-2,2Zm0,8h2V19H11Zm8-6H15v4h4Zm8-4v2h2a2,2,0,0,0-2-2Zm-6,0H19v2h2Zm-4,0H15v2h2ZM27,29a2,2,0,0,0,2-2H27Zm0-4h2V23H27ZM15,29h2V27H15ZM27,17h2V15H27Zm-4-4h2V11H23Zm4,8h2V19H27Zm-4,8h2V27H23Zm-4,0h2V27H19Zm2-10h4V15H21Zm0,6h4V21H21Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TableIcon;
