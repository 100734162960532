import { createAsyncActionType } from 'helpers/redux.helpers';

const prefix = 'UPLOAD_CSV';

export const sendUploadTransactionsRequest = createAsyncActionType(
  prefix,
  'SEND_UPLOAD_TRANSACTION_REQUEST',
);

export const checkJobProgress = createAsyncActionType(prefix, 'CHECK_JOB_PROGRESS');

export const resetUploadCSV: string = prefix.concat(':RESET_UPLOAD_CSV');

export const downloadCSVTemplate = createAsyncActionType(prefix, 'DOWNLOAD_CSV_TEMPLATE');

export const sendUploadConvertibleNotesRequest = createAsyncActionType(
  prefix,
  'SEND_UPLOAD_CONVERTIBLE_NOTE_REQUEST',
);

export const sendUploadShareholdersRequest = createAsyncActionType(
  prefix,
  'SEND_UPLOAD_SHAREHOLDERS_REQUEST',
);

export const sendUploadBondsAndLoansRequest = createAsyncActionType(
  prefix,
  'SEND_UPLOAD_BONDS_AND_LOANS_REQUEST',
);
