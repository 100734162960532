import * as constants from 'app-state/constants';

export const getShareIncentivePlan = shareIncentivePlanId => ({
  type: constants.GET_SHARE_INCENTIVE_PLAN,
  shareIncentivePlanId,
});
export const getShareIncentivePlanError = error => ({
  type: constants.GET_SHARE_INCENTIVE_PLAN_ERROR,
  error,
});
export const getShareIncentivePlanSuccess = data => ({
  type: constants.GET_SHARE_INCENTIVE_PLAN_SUCCESS,
  data,
});

export const resetShareIncentivePlan = () => ({ type: constants.CLEAR_SHARE_INCENTIVE_PLAN });

export const editShareIncentivePlan = ({ onSuccess, data, id }) => ({
  type: constants.EDIT_SHARE_INCENTIVE_PLAN,
  data,
  onSuccess,
  id,
});
export const editShareIncentivePlanError = error => ({
  type: constants.EDIT_SHARE_INCENTIVE_PLAN_ERROR,
  error,
});
export const editShareIncentivePlanSuccess = data => ({
  type: constants.EDIT_SHARE_INCENTIVE_PLAN_SUCCESS,
  data,
});

export const importPurchaseTemplate = planId => ({
  type: constants.IMPORT_PURCHASE_TEMPLATE,
  planId,
});

export const importSaleAndTransferTemplate = planId => ({
  type: constants.IMPORT_SALE_AND_TRANSFER_TEMPLATE,
  planId,
});

export const blockHolder = (id, onSuccess) => ({ type: constants.BLOCK_HOLDER, id, onSuccess });
export const blockHolderError = error => ({
  type: constants.BLOCK_HOLDER_ERROR,
  error,
});
export const blockHolderSuccess = () => ({
  type: constants.BLOCK_HOLDER_SUCCESS,
});

export const unblockHolder = (id, onSuccess) => ({ type: constants.UNBLOCK_HOLDER, id, onSuccess });
export const unblockHolderError = error => ({
  type: constants.UNBLOCK_HOLDER_ERROR,
  error,
});
export const unblockHolderSuccess = () => ({
  type: constants.UNBLOCK_HOLDER_SUCCESS,
});
