import { useEffect, useState } from 'react';

import { NDA_DOCUMENT_TYPE } from 'modules/company/deal-management/deal-form/constants';
import { StandardButton } from 'shared-parts/components/button';
import Pagination from 'shared-parts/components/pagination';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

import { HEADER, KEYS, UPLOAD_DOCUMENT_LABEL } from './constants';
import { ButtonBlock, StyledLoader, StyledTable } from './documents-table.styled';

const DocumentsTable = ({
  data,
  type,
  error,
  loading,
  itemUuid,
  pagination,
  isUploading,
  uploadDocument,
  deleteDocument,
  getItemsAction,
  resetItemsAction,
  changeAgreedStatus,
  checkIsUnSignDisabled,
  checkIsDeleteDisabled,
  disableUploadButton = false,
}) => {
  const fetchData = ({
    page = pagination.page,
    perPage = pagination.perPage,
    totalPages = pagination.totalPages,
    totalObjects = pagination.totalObjects,
  }) => {
    getItemsAction({ page, perPage, totalPages, totalObjects });
  };
  const [disableUpload, setDisableUpload] = useState(disableUploadButton);
  const handlePageChange = page => {
    fetchData({ page });
  };

  const handlePaginationOptionChange = perPage => {
    fetchData({
      page: 1,
      perPage,
      totalPages: 0,
    });
  };

  const transformData = documentsData => {
    const documents = [];
    if (documentsData) {
      documentsData.forEach(item => {
        let document = item;

        if (
          document.type === NDA_DOCUMENT_TYPE &&
          item.ndaProcess === 'e_signature' &&
          (document.agreed ||
            document.signatureStatus === 'rejected' ||
            document.signatureStatus === 'signed')
        ) {
          document = { ...document, disableAction: true };
          if (!disableUpload) {
            setDisableUpload(true);
          }
        }

        if (
          document.type === NDA_DOCUMENT_TYPE &&
          document.ndaProcess === 'e_signature' &&
          !document.isTemplate
        ) {
          document = { ...document, uploadedBy: '--' };
        } else {
          document = { ...document };
        }

        documents.push(document);
      });
    }

    return documents;
  };

  useEffect(() => {
    fetchData({});

    return () => {
      resetItemsAction();
    };
  }, [type, itemUuid]);

  const keys = KEYS({
    changeAgreedStatus,
    checkIsUnSignDisabled,
    checkIsDeleteDisabled,
    deleteDocument,
  });

  return (
    <div>
      <StyledTable
        header={HEADER}
        keys={keys}
        data={transformData(data)}
        error={error && defaultErrorMessage}
        loading={loading}
      />
      <Pagination
        disabled={loading}
        currentPage={+pagination.page}
        currentOption={+pagination.perPage}
        totalPages={+pagination.totalPages}
        handlePageChange={handlePageChange}
        handlePaginationOptionChange={handlePaginationOptionChange}
      />
      <ButtonBlock>
        <StandardButton
          isSuccess
          disabled={disableUpload || isUploading || disableUploadButton}
          onClick={uploadDocument}
        >
          {UPLOAD_DOCUMENT_LABEL}
          {isUploading && <StyledLoader isLocal alwaysVisible localSpinnerSize={22} zIndex={1} />}
        </StandardButton>
      </ButtonBlock>
    </div>
  );
};

export default DocumentsTable;
