import { Field } from 'formik';

import InputWrapper from './text-input';
import { Container, Input, ShowPassword } from './underlined-text-input.styled';

const InputComponent = (
  { onIconClick, placeholder, ...props }, // eslint-disable-line
) => (
  <Container {...props}>
    <Input {...props} placeholder={placeholder} data-e2e={props.name} />
    {props.password && (
      <ShowPassword
        color={props.showPasswordColor || props.placeholderColor}
        fontWeight={props.showPasswordFontWeight}
        touched={props.touched}
        onClick={props.handleShowPasswordChange}
      >
        Show Password
      </ShowPassword>
    )}
  </Container>
);

export default props => <Field {...props} component={InputWrapper} styledInput={InputComponent} />;
