import { COLORS } from 'shared-parts/constants';

const DocumentIcon = (fillColor = COLORS.highlight, height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="UI-Style-Guide">
          <g id="Group_3777" data-name="Group 3777">
            <rect
              id="Rectangle_2314"
              data-name="Rectangle 2314"
              className="icon"
              width="40"
              height="40"
              rx="2"
            />
            <g id="Group_3775" data-name="Group 3775">
              <path
                id="Path_1558"
                fill="#eeeff3"
                d="M23.33,12.71h-8.5a1.41,1.41,0,0,0-1.41,1.41V24h1.41V14.12h8.5Zm2.13,2.83h-7.8A1.43,1.43,0,0,0,16.24,17v9.92a1.42,1.42,0,0,0,1.42,1.41h7.8a1.41,1.41,0,0,0,1.41-1.41V17A1.42,1.42,0,0,0,25.46,15.54Zm0,11.34h-7.8V17h7.8Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DocumentIcon;
