import * as constants from 'app-state/constants';

export const fetchDealSummaryData = payload => ({
  type: constants.FETCH_DEAL_SUMMARY_DATA,
  payload,
});
export const fetchDealSummaryDataSuccess = payload => ({
  type: constants.FETCH_DEAL_SUMMARY_DATA_SUCCESS,
  payload,
});
export const fetchDealSummaryDataError = payload => ({
  type: constants.FETCH_DEAL_SUMMARY_DATA_ERROR,
  payload,
});
export const resetDealSummaryData = () => ({ type: constants.RESET_DEAL_SUMMARY_DATA });

export const fetchInvestorDocuments = payload => ({
  type: constants.FETCH_INVESTOR_DOCUMENTS,
  payload,
});
export const fetchInvestorDocumentsSuccess = payload => ({
  type: constants.FETCH_INVESTOR_DOCUMENTS_SUCCESS,
  payload,
});
export const fetchInvestorDocumentsError = payload => ({
  type: constants.FETCH_INVESTOR_DOCUMENTS_ERROR,
  payload,
});
export const resetInvestorDocuments = () => ({ type: constants.RESET_INVESTOR_DOCUMENTS });

export const deleteInvestorDocument = payload => ({
  type: constants.DELETE_INVESTOR_DOCUMENT,
  payload,
});
export const uploadInvestorDocument = payload => ({
  type: constants.UPLOAD_INVESTOR_DOCUMENT,
  payload,
});
export const uploadInvestorDocumentFinished = payload => ({
  type: constants.UPLOAD_INVESTOR_DOCUMENT_FINISHED,
  payload,
});

export const changeDocumentAgreedStatus = payload => ({
  type: constants.CHANGE_DOCUMENT_AGREED_STATUS,
  payload,
});
export const changeDocumentAgreedStatusSuccess = payload => ({
  type: constants.CHANGE_DOCUMENT_AGREED_STATUS_SUCCESS,
  payload,
});

export const fetchDealData = payload => {
  return { type: constants.FETCH_DEAL_DATA, payload };
};
export const fetchDealDataSuccess = payload => ({
  type: constants.FETCH_DEAL_DATA_SUCCESS,
  payload,
});

export const fetchDealDocumentsDataError = payload => ({
  type: constants.FETCH_DEAL_DOCUMENTS_DATA_ERROR,
  payload,
});
export const resetDealDocumentsData = () => ({ type: constants.RESET_DEAL_DOCUMENTS_DATA });

export const fetchDealDocumentsData = payload => {
  return { type: constants.FETCH_DEAL_DOCUMENTS_DATA, payload };
};
export const fetchDealDocumentsDataSuccess = payload => ({
  type: constants.FETCH_DEAL_DOCUMENTS_DATA_SUCCESS,
  payload,
});
export const fetchDealDataError = payload => ({ type: constants.FETCH_DEAL_DATA_ERROR, payload });
export const resetDealData = () => ({ type: constants.RESET_DEAL_DATA });
export const updateDealBankAccounts = payload => ({
  type: constants.UPDATE_DEAL_BANK_ACCOUNTS,
  payload,
});

export const createDealDetails = payload => ({ type: constants.CREATE_DEAL_DETAILS, payload });
export const createDealDetailsSuccess = payload => ({
  type: constants.CREATE_DEAL_DETAILS_SUCCESS,
  payload,
});
export const createDealDetailsError = payload => ({
  type: constants.CREATE_DEAL_DETAILS_ERROR,
  payload,
});

export const editDealDetails = payload => ({ type: constants.EDIT_DEAL_DETAILS, payload });
export const editDealDetailsSuccess = payload => ({
  type: constants.EDIT_DEAL_DETAILS_SUCCESS,
  payload,
});
export const editDealDetailsError = payload => ({
  type: constants.EDIT_DEAL_DETAILS_ERROR,
  payload,
});

export const editDealStructure = payload => ({ type: constants.EDIT_DEAL_STRUCTURE, payload });

export const editDealConfiguration = payload => ({
  type: constants.EDIT_DEAL_CONFIGURATION,
  payload,
});

export const uploadDealDocument = payload => ({ type: constants.UPLOAD_DEAL_DOCUMENT, payload });

export const fetchDealInfo = payload => ({ type: constants.FETCH_DEAL_INFO, payload });
export const fetchDealInfoSuccess = payload => ({
  type: constants.FETCH_DEAL_INFO_SUCCESS,
  payload,
});
export const fetchDealInfoError = payload => ({ type: constants.FETCH_DEAL_INFO_ERROR, payload });
export const resetDealInfo = () => ({ type: constants.RESET_DEAL_INFO });

export const fetchDealList = payload => ({ type: constants.FETCH_DEAL_LIST, payload });
export const fetchDealListSuccess = payload => ({
  type: constants.FETCH_DEAL_LIST_SUCCESS,
  payload,
});
export const fetchDealListError = payload => ({ type: constants.FETCH_DEAL_LIST_ERROR, payload });
export const resetDealList = () => ({ type: constants.RESET_DEAL_LIST });

export const fetchInvestorInfo = payload => ({ type: constants.FETCH_INVESTOR_INFO, payload });
export const fetchInvestorInfoSuccess = payload => ({
  type: constants.FETCH_INVESTOR_INFO_SUCCESS,
  payload,
});
export const fetchInvestorInfoError = payload => ({
  type: constants.FETCH_INVESTOR_INFO_ERROR,
  payload,
});
export const resetInvestorInfo = () => ({ type: constants.RESET_INVESTOR_INFO });

export const resendInvestorInvite = payload => ({
  type: constants.RESEND_INVESTOR_INVITE,
  payload,
});
export const resendInvestorInviteSuccess = () => ({
  type: constants.RESEND_INVESTOR_INVITE_SUCCESS,
});
export const resendInvestorInviteError = payload => ({
  type: constants.RESEND_INVESTOR_INVITE_ERROR,
  payload,
});

export const resendIPInvestorInvite = payload => ({
  type: constants.RESEND_IP_INVESTOR_INVITE,
  payload,
});

export const fetchDealPagesData = params => ({ type: constants.FETCH_DEAL_PAGES_DATA, ...params });
export const fetchDealPagesDataSuccess = () => ({ type: constants.FETCH_DEAL_PAGES_DATA_SUCCESS });
export const fetchDealPagesDataError = error => ({
  type: constants.FETCH_DEAL_PAGES_DATA_ERROR,
  error,
});

export const updateDealPageHeader = params => ({
  type: constants.UPDATE_DEAL_PAGE_HEADER,
  ...params,
});
export const updateDealPageHeaderSuccess = () => ({
  type: constants.UPDATE_DEAL_PAGE_HEADER_SUCCESS,
});
export const updateDealPageHeaderError = error => ({
  type: constants.UPDATE_DEAL_PAGE_HEADER_ERROR,
  error,
});

export const updateDealData = params => ({ type: constants.UPDATE_DEAL_DATA, ...params });
export const updateDealDataSuccess = () => ({ type: constants.UPDATE_DEAL_DATA_SUCCESS });
export const updateDealDataError = error => ({ type: constants.UPDATE_DEAL_DATA_ERROR, error });

export const fetchDeal = permalink => ({ type: constants.FETCH_DEAL, permalink });
export const fetchDealSuccess = data => ({ type: constants.FETCH_DEAL_SUCCESS, data });
export const fetchDealError = error => ({ type: constants.FETCH_DEAL_ERROR, error });
export const resetDeal = () => ({ type: constants.RESET_DEAL });

export const fetchPage = (id, restricted) => ({ type: constants.FETCH_PAGE, id, restricted });
export const fetchPageSuccess = data => ({ type: constants.FETCH_PAGE_SUCCESS, data });
export const updatePage = data => ({ type: constants.UPDATE_PAGE, data });
export const fetchPageError = error => ({ type: constants.FETCH_PAGE_ERROR, error });
export const fetchPageRestricted = () => ({ type: constants.FETCH_PAGE_RESTRICTED });
export const resetPage = () => ({ type: constants.RESET_PAGE });

export const fetchIssuance = (companyUuid, modelUuid) => ({
  type: constants.FETCH_ISSUANCE,
  companyUuid,
  modelUuid,
});
export const fetchIssuanceSuccess = data => ({ type: constants.FETCH_ISSUANCE_SUCCESS, data });
export const fetchIssuanceError = error => ({ type: constants.FETCH_ISSUANCE_ERROR, error });

export const createSection = data => ({ type: constants.CREATE_SECTION, data });
export const createSectionError = error => ({ type: constants.CREATE_SECTION_ERROR, error });
export const editSectionTitle = section => ({ type: constants.EDIT_SECTION_TITLE, section });
export const editSectionTitleError = error => ({ type: constants.EDIT_SECTION_TITLE_ERROR, error });
export const deleteSection = sectionId => ({ type: constants.DELETE_SECTION, sectionId });
export const deleteSectionError = error => ({ type: constants.DELETE_SECTION_ERROR, error });
export const reorderSections = sections => ({ type: constants.REORDER_SECTIONS, sections });
export const reorderSectionsError = error => ({ type: constants.REORDER_SECTIONS_ERROR, error });

export const createTextComponent = component => ({
  type: constants.CREATE_TEXT_COMPONENT,
  component,
});
export const createTextComponentError = error => ({
  type: constants.CREATE_TEXT_COMPONENT_ERROR,
  error,
});
export const editTextComponent = (id, component, setIsEditingContent) => ({
  type: constants.EDIT_TEXT_COMPONENT,
  id,
  component,
  setIsEditingContent,
});
export const editTextComponentError = error => ({
  type: constants.EDIT_TEXT_COMPONENT_ERROR,
  error,
});

export const createTableComponent = component => ({
  type: constants.CREATE_TABLE_COMPONENT,
  component,
});
export const createTableComponentError = error => ({
  type: constants.CREATE_TABLE_COMPONENT_ERROR,
  error,
});
export const editTableComponent = (id, component) => ({
  type: constants.EDIT_TABLE_COMPONENT,
  id,
  component,
});
export const editTableComponentError = error => ({
  type: constants.EDIT_TABLE_COMPONENT_ERROR,
  error,
});

export const createDescriptionListComponent = component => ({
  type: constants.CREATE_DESCRIPTION_LIST_COMPONENT,
  component,
});
export const createDescriptionListComponentError = error => ({
  type: constants.CREATE_DESCRIPTION_LIST_COMPONENT_ERROR,
  error,
});
export const editDescriptionListComponent = (id, component) => ({
  type: constants.EDIT_DESCRIPTION_LIST_COMPONENT,
  id,
  component,
});
export const editDescriptionListComponentError = error => ({
  type: constants.EDIT_DESCRIPTION_LIST_COMPONENT_ERROR,
  error,
});

export const createDocumentComponent = params => ({
  type: constants.CREATE_DOCUMENT_COMPONENT,
  ...params,
});
export const createDocumentComponentError = error => ({
  type: constants.CREATE_DOCUMENT_COMPONENT_ERROR,
  error,
});

export const editDocumentComponent = params => ({
  type: constants.EDIT_DOCUMENT_COMPONENT,
  ...params,
});
export const editDocumentComponentError = error => ({
  type: constants.EDIT_DOCUMENT_COMPONENT_ERROR,
  error,
});

export const editImageComponent = params => ({ type: constants.EDIT_IMAGE_COMPONENT, ...params });
export const editImageComponentSuccess = data => ({
  type: constants.EDIT_IMAGE_COMPONENT_SUCCESS,
  data,
});
export const editImageComponentError = error => ({
  type: constants.EDIT_IMAGE_COMPONENT_ERROR,
  error,
});

export const createImageComponent = params => ({
  type: constants.CREATE_IMAGE_COMPONENT,
  ...params,
});
export const createImageComponentSuccess = data => ({
  type: constants.CREATE_IMAGE_COMPONENT_SUCCESS,
  data,
});
export const createImageComponentError = error => ({
  type: constants.CREATE_IMAGE_COMPONENT_ERROR,
  error,
});

export const editVideoComponent = params => ({ type: constants.EDIT_VIDEO_COMPONENT, ...params });
export const editVideoComponentSuccess = data => ({
  type: constants.EDIT_VIDEO_COMPONENT_SUCCESS,
  data,
});
export const editVideoComponentError = error => ({
  type: constants.EDIT_VIDEO_COMPONENT_ERROR,
  error,
});

export const createVideoComponent = params => ({
  type: constants.CREATE_VIDEO_COMPONENT,
  ...params,
});
export const createVideoComponentSuccess = data => ({
  type: constants.CREATE_VIDEO_COMPONENT_SUCCESS,
  data,
});
export const createVideoComponentError = error => ({
  type: constants.CREATE_VIDEO_COMPONENT_ERROR,
  error,
});

export const createTeamMemberOverviewSection = params => ({
  type: constants.CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION,
  ...params,
});
export const createTeamMemberOverviewSectionSuccess = data => ({
  type: constants.CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION_SUCCESS,
  data,
});
export const createTeamMemberOverviewSectionError = error => ({
  type: constants.CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION_ERROR,
  error,
});

export const createTeamMember = params => ({ type: constants.CREATE_TEAM_MEMBER, ...params });
export const createTeamMemberSuccess = data => ({
  type: constants.CREATE_TEAM_MEMBER_SUCCESS,
  data,
});
export const createTeamMemberError = error => ({ type: constants.CREATE_TEAM_MEMBER_ERROR, error });

export const editTeamMember = params => ({ type: constants.EDIT_TEAM_MEMBER, ...params });
export const editTeamMemberSuccess = data => ({ type: constants.EDIT_TEAM_MEMBER_SUCCESS, data });
export const editTeamMemberError = error => ({ type: constants.EDIT_TEAM_MEMBER_ERROR, error });

export const detachTeamMember = params => ({ type: constants.DETACH_TEAM_MEMBER, ...params });
export const detachTeamMemberSuccess = data => ({
  type: constants.DETACH_TEAM_MEMBER_SUCCESS,
  data,
});
export const detachTeamMemberError = error => ({ type: constants.DETACH_TEAM_MEMBER_ERROR, error });

export const attachTeamMember = params => ({ type: constants.ATTACH_TEAM_MEMBER, ...params });
export const attachTeamMemberSuccess = data => ({
  type: constants.ATTACH_TEAM_MEMBER_SUCCESS,
  data,
});
export const attachTeamMemberError = error => ({ type: constants.ATTACH_TEAM_MEMBER_ERROR, error });

export const reorderTeamMember = params => ({ type: constants.REORDER_TEAM_MEMBER, ...params });
export const reorderTeamMemberSuccess = data => ({
  type: constants.REORDER_TEAM_MEMBER_SUCCESS,
  data,
});
export const reorderTeamMemberError = error => ({
  type: constants.REORDER_TEAM_MEMBER_ERROR,
  error,
});

export const fetchTeamMembers = payload => ({ type: constants.FETCH_TEAM_MEMBERS, payload });
export const fetchTeamMembersSuccess = data => ({
  type: constants.FETCH_TEAM_MEMBERS_SUCCESS,
  data,
});
export const fetchTeamMembersError = error => ({ type: constants.FETCH_TEAM_MEMBERS_ERROR, error });
export const resetTeamMembers = () => ({ type: constants.RESET_TEAM_MEMBERS });

export const createTeamComponentInRegularSection = params => ({
  type: constants.CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION,
  ...params,
});
export const createTeamComponentInRegularSectionSuccess = data => ({
  type: constants.CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION_SUCCESS,
  data,
});
export const createTeamComponentInRegularSectionError = error => ({
  type: constants.CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION_ERROR,
  error,
});

export const createTeamComponent = params => ({ type: constants.CREATE_TEAM_COMPONENT, ...params });
export const createTeamComponentSuccess = data => ({
  type: constants.CREATE_TEAM_COMPONENT_SUCCESS,
  data,
});
export const createTeamComponentError = error => ({
  type: constants.CREATE_TEAM_COMPONENT_ERROR,
  error,
});

export const editTeamComponent = params => ({ type: constants.EDIT_TEAM_COMPONENT, ...params });
export const editTeamComponentSuccess = data => ({
  type: constants.EDIT_TEAM_COMPONENT_SUCCESS,
  data,
});
export const editTeamComponentError = error => ({
  type: constants.EDIT_TEAM_COMPONENT_ERROR,
  error,
});

export const deleteComponent = (componentId, componentType) => ({
  type: constants.DELETE_COMPONENT,
  componentId,
  componentType,
});
export const deleteComponentSuccess = data => ({ type: constants.DELETE_COMPONENT_SUCCESS, data });
export const deleteComponentError = error => ({ type: constants.DELETE_COMPONENT_ERROR, error });

export const reorderComponent = (sectionBoxId, oldPosition, newPosition) => ({
  type: constants.REORDER_COMPONENT,
  sectionBoxId,
  oldPosition,
  newPosition,
});
export const reorderComponentError = error => ({ type: constants.REORDER_COMPONENT_ERROR, error });

export const addComponentStart = sectionId => ({ type: constants.ADD_COMPONENT_START, sectionId });
export const addComponentEnd = sectionId => ({ type: constants.ADD_COMPONENT_END, sectionId });
export const addComponentError = (sectionId, error) => ({
  type: constants.ADD_COMPONENT_ERROR,
  error,
  sectionId,
});
export const addComponentHideError = sectionId => ({
  type: constants.ADD_COMPONENT_HIDE_ERROR,
  sectionId,
});

export const createIssuance = company => ({ type: constants.CREATE_ISSUANCE, company });
export const createIssuanceError = error => ({ type: constants.CREATE_ISSUANCE_ERROR, error });

export const setLastCreatedComponent = (lastCreatedComponentId, lastCreatedComponentType) => ({
  type: constants.SET_LAST_CREATED_COMPONENT,
  lastCreatedComponentId,
  lastCreatedComponentType,
});
export const resetLastCreatedComponent = () => ({ type: constants.RESET_LAST_CREATED_COMPONENT });

export const createDealInvites = payload => ({
  type: constants.CREATE_DEAL_INVITES,
  payload,
});
export const createDealInvitesSuccess = data => ({
  type: constants.CREATE_DEAL_INVITES_SUCCESS,
  data,
});
export const createDealInvitesError = error => ({
  type: constants.CREATE_DEAL_INVITES_ERROR,
  error,
});

export const fetchDealInviteEmailTemplate = payload => ({
  type: constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE,
  payload,
});
export const fetchDealInviteEmailTemplateSuccess = payload => ({
  type: constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE_SUCCESS,
  payload,
});
export const fetchDealInviteEmailTemplateError = payload => ({
  type: constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE_ERROR,
  payload,
});
export const resetDealInviteEmailTemplate = () => ({
  type: constants.RESET_DEAL_INVITE_EMAIL_TEMPLATE,
});

export const requestDealPublish = payload => ({
  type: constants.REQUEST_DEAL_PUBLISH,
  payload,
});
export const requestDealPublishSuccess = () => ({
  type: constants.REQUEST_DEAL_PUBLISH_SUCCESS,
});
export const requestDealPublishError = payload => ({
  type: constants.REQUEST_DEAL_PUBLISH_ERROR,
  payload,
});

export const publishDeal = payload => ({
  type: constants.PUBLISH_DEAL,
  payload,
});
export const publishDealSuccess = () => ({
  type: constants.PUBLISH_DEAL_SUCCESS,
});
export const publishDealError = payload => ({
  type: constants.PUBLISH_DEAL_ERROR,
  payload,
});

export const unpublishDeal = payload => ({
  type: constants.UNPUBLISH_DEAL,
  payload,
});
export const unpublishDealSuccess = () => ({
  type: constants.UNPUBLISH_DEAL_SUCCESS,
});
export const unpublishDealError = payload => ({
  type: constants.UNPUBLISH_DEAL_ERROR,
  payload,
});

export const fetchDealOrders = payload => ({ type: constants.FETCH_DEAL_ORDERS, payload });
export const fetchDealOrdersSuccess = payload => ({
  type: constants.FETCH_DEAL_ORDERS_SUCCESS,
  payload,
});
export const fetchDealOrdersError = payload => ({
  type: constants.FETCH_DEAL_ORDERS_ERROR,
  payload,
});
export const resetDealOrders = () => ({ type: constants.RESET_DEAL_ORDERS });

export const fetchDealOrderDetails = payload => ({
  type: constants.FETCH_DEAL_ORDER_DETAILS,
  payload,
});
export const fetchDealOrderDetailsSuccess = payload => ({
  type: constants.FETCH_DEAL_ORDER_DETAILS_SUCCESS,
  payload,
});
export const fetchDealOrderDetailsError = payload => ({
  type: constants.FETCH_DEAL_ORDER_DETAILS_ERROR,
  payload,
});
export const resetDealOrderDetails = () => ({ type: constants.RESET_DEAL_ORDER_DETAILS });

export const fetchDealOrderHistory = payload => ({
  type: constants.FETCH_DEAL_ORDER_HISTORY,
  payload,
});
export const fetchDealOrderHistorySuccess = payload => ({
  type: constants.FETCH_DEAL_ORDER_HISTORY_SUCCESS,
  payload,
});
export const fetchDealOrderHistoryError = payload => ({
  type: constants.FETCH_DEAL_ORDER_HISTORY_ERROR,
  payload,
});
export const resetDealOrderHistory = () => ({ type: constants.RESET_DEAL_ORDER_HISTORY });

export const approveDealOrder = payload => ({ type: constants.APPROVE_DEAL_ORDER, payload });
export const approveDealOrderSuccess = () => ({ type: constants.APPROVE_DEAL_ORDER_SUCCESS });
export const approveDealOrderError = payload => ({
  type: constants.APPROVE_DEAL_ORDER_ERROR,
  payload,
});

export const markAsFundsReceived = payload => ({
  type: constants.MARK_AS_FUNDS_RECEIVED,
  payload,
});
export const markAsFundsReceivedSuccess = () => ({
  type: constants.MARK_AS_FUNDS_RECEIVED_SUCCESS,
});
export const markAsFundsReceivedError = payload => ({
  type: constants.MARK_AS_FUNDS_RECEIVED_ERROR,
  payload,
});

export const cancelDealOrder = payload => ({ type: constants.CANCEL_DEAL_ORDER, payload });
export const cancelDealOrderSuccess = () => ({ type: constants.CANCEL_DEAL_ORDER_SUCCESS });
export const cancelDealOrderError = payload => ({
  type: constants.CANCEL_DEAL_ORDER_ERROR,
  payload,
});

export const completeDeal = payload => ({ type: constants.COMPLETE_DEAL, payload });
export const completeDealSuccess = () => ({ type: constants.COMPLETE_DEAL_SUCCESS });
export const completeDealError = payload => ({ type: constants.COMPLETE_DEAL_ERROR, payload });

export const fetchDealSettlements = payload => ({
  type: constants.FETCH_DEAL_SETTLEMENTS,
  payload,
});
export const fetchDealSettlementsSuccess = payload => ({
  type: constants.FETCH_DEAL_SETTLEMENTS_SUCCESS,
  payload,
});
export const fetchDealSettlementsError = payload => ({
  type: constants.FETCH_DEAL_SETTLEMENTS_ERROR,
  payload,
});
export const resetDealSettlements = () => ({ type: constants.RESET_DEAL_SETTLEMENTS });

export const editOrderMoneyTransferred = payload => ({
  type: constants.EDIT_ORDER_MONEY_TRANSFERRED,
  payload,
});
export const editOrderMoneyTransferredSuccess = () => ({
  type: constants.EDIT_ORDER_MONEY_TRANSFERRED_SUCCESS,
});
export const editOrderMoneyTransferredError = payload => ({
  type: constants.EDIT_ORDER_MONEY_TRANSFERRED_ERROR,
  payload,
});
