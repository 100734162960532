import { all, call } from 'redux-saga/effects';

import { Role } from 'helpers';

export const attachEffects = (data, roleDetails, groupBy) =>
  data.reduce(
    (previous, entity) => ({
      ...previous,
      [entity[groupBy]]: call(Role.has, roleDetails.has, roleDetails.getCriterias(entity)),
    }),
    {},
  );

export const attachURLs = (urlFieldName, urls, groupBy) => entity => ({
  ...entity,
  [urlFieldName]: urls[entity[groupBy]],
});

export default function* ({ data, roleDetails, urlFieldName, groupBy = 'id' }) {
  const effects = yield attachEffects(data, roleDetails, groupBy);
  const withURLs = yield all(effects);

  return yield data.map(attachURLs(urlFieldName, withURLs, groupBy));
}
