import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  ${({ closeOnClickOutside }) => (closeOnClickOutside ? 'cursor: pointer;' : '')}
`;

const ModalFrame = styled.div`
  cursor: default;
  max-height: 800px;
  ${({ showOverflow }) => (showOverflow ? '' : 'overflow: hidden')};
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  min-width: 300px;
  min-height: ${({ minHeight = 200 }) => minHeight}px;

  @media screen and (max-height: 800px) {
    max-height: 100vh;
    max-width: 100vw;
  }
`;

const ModalHeader = styled.div`
  position: relative;
  text-transform: uppercase;
  background: ${({ backgroundColor }) => backgroundColor};
  min-height: 50px;
  line-height: 50px;
  color: ${COLORS.white};
  padding-left: 30px;
`;

const ModalBody = styled.div`
  overflow-y: ${({ showOverflow }) => (showOverflow ? 'visible' : 'auto')};
  max-height: 700px;
`;

const ModalPreview = styled.div`
  overflow-y: auto;
  max-height: 750px;
`;

const CloseButtonWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: 13px;
  width: 20px;
`;

export { ModalWrapper, ModalFrame, ModalHeader, ModalBody, ModalPreview, CloseButtonWrapper };
