import { COLORS } from 'shared-parts/constants';

const HandshakeIcon = ({ fill = COLORS.alternative, width = 81.182, height = 46.366 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 81.182 46.366"
    fill={fill}
  >
    <g id="Group_6281" data-name="Group 6281" transform="translate(-0.529 -3.025)">
      <path
        id="Path_1745"
        data-name="Path 1745"
        d="M52.32,23.087a15.02,15.02,0,0,0,11.5-1.379l17.33,14.07a3.945,3.945,0,0,1,.9,1.043l4.916-4.512L77.521,17.183l-5.655,2.068-7.408-2.646a5.112,5.112,0,0,0-4.621.606,33.034,33.034,0,0,1-7.585,3.9,1.041,1.041,0,0,0,.069,1.981Z"
        transform="translate(-17.923 -4.665)"
      />
      <path
        id="Path_1746"
        data-name="Path 1746"
        d="M42.592,45.274a4.07,4.07,0,0,0-4.4-4.525,4.069,4.069,0,0,0-6.541-4.116l-.272.224a4.069,4.069,0,0,0-6.659-3.093l-2.606,2.15-2.377-1.745,9.913-16.313a86.26,86.26,0,0,0,9.776-.323,16.4,16.4,0,0,1,7.137-.588,31.448,31.448,0,0,1-6,2.905,2.555,2.555,0,0,0,.167,4.863,16.536,16.536,0,0,0,11.734-1.032L69.019,37.122a2.424,2.424,0,0,1-2.942,3.851l-12.386-8.9a.757.757,0,0,0-.884,1.23l11.955,8.589a2.555,2.555,0,0,1-2.983,4.15L50.495,37.935a.757.757,0,0,0-.882,1.229l9.958,7.156a2.555,2.555,0,1,1-2.981,4.15l-9.367-6.729a.757.757,0,0,0-.883,1.229l7.168,5.15a2.268,2.268,0,0,1-2.647,3.684l-3.677-2.642a4.069,4.069,0,0,0-4.592-5.889Z"
        transform="translate(-6.745 -4.834)"
      />
      <path
        id="Path_1747"
        data-name="Path 1747"
        d="M27.015,50.871l3.018-2.49a2.555,2.555,0,0,0-3.253-3.941l-3.017,2.49a2.555,2.555,0,0,0,3.252,3.941Z"
        transform="translate(-7.843 -14.342)"
      />
      <path
        id="Path_1748"
        data-name="Path 1748"
        d="M39.386,52.807a2.555,2.555,0,0,0-3.253-3.941l-5.453,4.5a2.555,2.555,0,0,0,3.253,3.941Z"
        transform="translate(-10.266 -15.899)"
      />
      <path
        id="Path_1749"
        data-name="Path 1749"
        d="M42.406,64.079l4.267-3.522a2.555,2.555,0,0,0-3.253-3.941l-4.267,3.522a2.555,2.555,0,0,0,3.253,3.941Z"
        transform="translate(-13.241 -18.618)"
      />
      <path
        id="Path_1750"
        data-name="Path 1750"
        d="M52,63.414l-2.827,2.334a2.555,2.555,0,0,0,3.253,3.941l2.827-2.334A2.555,2.555,0,0,0,52,63.414Z"
        transform="translate(-16.761 -21.005)"
      />
      <path
        id="Path_1751"
        data-name="Path 1751"
        d="M23.844,8.559,9.635,31.94.529,26.407,14.737,3.025Z"
      />
      <path
        id="Path_1752"
        data-name="Path 1752"
        d="M100,3.568l13.467,21.556-9.043,5.649L90.961,9.217Z"
        transform="translate(-31.76 -0.191)"
      />
    </g>
  </svg>
);

export default HandshakeIcon;
