import * as constants from 'app-state/constants';

export const createTagline = tagline => ({ type: constants.CREATE_TAGLINE, tagline });
export const createTaglineError = error => ({ type: constants.CREATE_TAGLINE_ERROR, error });

export const editTagline = tagline => ({ type: constants.EDIT_TAGLINE, tagline });
export const editTaglineError = error => ({ type: constants.EDIT_TAGLINE_ERROR, error });

export const deleteTagline = tagline => ({ type: constants.DELETE_TAGLINE, tagline });
export const deleteTaglineError = error => ({ type: constants.DELETE_TAGLINE_ERROR, error });
