import { t } from '@frontend/i18next';

import { capitalize } from 'helpers/capitalize';

const getDealAlias = (options?: { plural?: boolean; capitalize?: boolean }): string => {
  // TODO: replace all instances of getDealAlias with i18next t('deal')
  const alias = t('deal');
  const message = options?.plural ? `${alias}s` : alias;
  return capitalize(message);
};

export { getDealAlias };
