import { AsyncState } from 'helpers/redux.helpers';

export enum JobStatus {
  Queued = 'Queued',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type DownloadCSVStateType = {
  job: AsyncState<{
    id?: string;
    status?: JobStatus;
    url?: string;
  }>;
};

const initialState: DownloadCSVStateType = {
  job: {
    loading: false,
    error: undefined,
    data: undefined,
  },
};

export default initialState;
