import styled from 'styled-components';

import Loader from 'shared-parts/components/loader/loader';
import { Table } from 'shared-parts/components/table';
import {
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS } from 'shared-parts/constants';

const { white, systemRed, systemGreen, primary, neutral300 } = COLORS;

export const AgreedStatus = styled.div`
  color: ${({ isPositive }) => (isPositive ? systemGreen : systemRed)};
`;

export const StyledLoader = styled(Loader)`
  width: 30px;
  height: 10px;
  display: inline-block;
  left: 5px;
  background: transparent;
`;

export const ButtonBlock = styled.div`
  margin: 17px 30px;
  display: flex;
  justify-content: flex-end;
`;

export const StyledTable = styled(Table)`
  background: ${white};
  border-radius: 0;
  border: none;
  overflow: visible;

  ${TableHeader} {
    color: ${primary};
  }

  ${TableHeaderCell} {
    top: 57px;
    box-shadow: inset 0 -1px 0 ${neutral300};
    background: ${white};
    font-weight: 700;
    border: 1px solid ${neutral300};

    :first-of-type {
      border-top-left-radius: 0;
    }

    :last-of-type {
      border-right: none;
      border-top-right-radius: 0;
    }
  }

  ${TableRow} {
    border-color: ${neutral300};
  }

  ${TableCell} {
    border-color: ${neutral300};

    :last-of-type {
      border-right: none;
      padding: 0;
    }
  }
`;
