import * as constants from 'app-state/constants';

export const getCompanies = () => ({ type: constants.GET_COMPANIES });
export const getCompaniesSuccess = data => ({ type: constants.GET_COMPANIES_SUCCESS, data });
export const getCompaniesError = error => ({ type: constants.GET_COMPANIES_ERROR, error });

export const getCompany = (companyId, isExternal = false) => ({
  type: constants.GET_COMPANY,
  companyId,
  isExternal,
});
export const getCompanySuccess = data => ({ type: constants.GET_COMPANY_SUCCESS, data });
export const getCompanyError = error => ({ type: constants.GET_COMPANY_ERROR, error });
export const setCompany = data => ({ type: constants.SET_COMPANY, data });

export const editCompany = payload => ({ type: constants.EDIT_COMPANY, payload });
export const editCompanySuccess = data => ({ type: constants.EDIT_COMPANY_SUCCESS, data });
export const editCompanyError = error => ({ type: constants.EDIT_COMPANY_ERROR, error });

export const fetchCompany = companyUuid => ({ type: constants.FETCH_COMPANY, companyUuid });
export const fetchCompanySuccess = data => ({ type: constants.FETCH_COMPANY_SUCCESS, data });
export const fetchCompanyError = error => ({ type: constants.FETCH_COMPANY_ERROR, error });
export const resetCompany = () => ({ type: constants.RESET_COMPANY });

export const updateCompaniesHouseAuthCodeAction = params => ({
  type: constants.UPDATE_COMPANIES_HOUSE_AUTH_CODE,
  ...params,
});
export const updateCompaniesHouseAuthCodeSuccess = data => ({
  type: constants.UPDATE_COMPANIES_HOUSE_AUTH_CODE_SUCCESS,
  data,
});
export const updateCompaniesHouseAuthCodeError = error => ({
  type: constants.UPDATE_COMPANIES_HOUSE_AUTH_CODE_ERROR,
  error,
});

export const getCompanyTags = companyUuid => ({ type: constants.GET_COMPANY_TAGS, companyUuid });
export const getCompanyTagsSuccess = data => ({ type: constants.GET_COMPANY_TAGS_SUCCESS, data });
export const getCompanyTagsError = error => ({ type: constants.GET_COMPANY_TAGS_ERROR, error });
export const resetCompanyTags = () => ({ type: constants.RESET_COMPANY_TAGS });

export const updateCompanyViewPermissionsError = error => ({
  type: constants.UPDATE_COMPANY_VIEW_PERMISSIONS_ERROR,
  error,
});
export const updateCompanyViewPermissions = params => ({
  type: constants.UPDATE_COMPANY_VIEW_PERMISSIONS,
  ...params,
});
export const updateCompanyViewPermissionsSuccess = data => ({
  type: constants.UPDATE_COMPANY_VIEW_PERMISSIONS_SUCCESS,
  data,
});

export const verifyCompanyKyb = params => ({ type: constants.VERIFY_COMPANY_KYB, ...params });
export const verifyCompanyKybSuccess = data => ({
  type: constants.VERIFY_COMPANY_KYB_SUCCESS,
  data,
});
export const verifyCompanyKybError = error => ({ type: constants.VERIFY_COMPANY_KYB_ERROR, error });

export const createCompanySummary = (companyUuid, body) => ({
  type: constants.CREATE_COMPANY_SUMMARY,
  companyUuid,
  body,
});
export const createCompanySummarySuccess = data => ({
  type: constants.CREATE_COMPANY_SUMMARY_SUCCESS,
  data,
});
export const createCompanySummaryError = error => ({
  type: constants.CREATE_COMPANY_SUMMARY_ERROR,
  error,
});

export const editCompanySummary = (companyUuid, body) => ({
  type: constants.EDIT_COMPANY_SUMMARY,
  companyUuid,
  body,
});
export const editCompanySummarySuccess = data => ({
  type: constants.EDIT_COMPANY_SUMMARY_SUCCESS,
  data,
});
export const editCompanySummaryError = error => ({
  type: constants.EDIT_COMPANY_SUMMARY_ERROR,
  error,
});

export const getCompanySummary = companyUuid => ({
  type: constants.GET_COMPANY_SUMMARY,
  companyUuid,
});
export const getCompanySummarySuccess = data => ({
  type: constants.GET_COMPANY_SUMMARY_SUCCESS,
  data,
});
export const getCompanySummaryError = error => ({
  type: constants.GET_COMPANY_SUMMARY_ERROR,
  error,
});

export const clearCompanySummary = () => ({
  type: constants.CLEAR_COMPANY_SUMMARY,
});
