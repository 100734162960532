const getUpdatedArrowPosition = (contentRef, tooltipPosition) => {
  const contentElementDetails = contentRef.current.getBoundingClientRect();

  switch (tooltipPosition) {
    case 'ts':
      return {
        arrow: `
        margin-left: 5px;
        transform: rotate(180deg);
      `,
        before: `margin-top: ${contentElementDetails.height + 1}px;`,
        after: `margin-top: ${contentElementDetails.height - 2}px;`,
      };
    case 'tm':
      return {
        arrow: `
        margin-left: calc(${contentElementDetails.width}px / 2 - 11px);
        transform: rotate(180deg);
      `,
        before: `margin-top: ${contentElementDetails.height + 1}px;`,
        after: `margin-top: ${contentElementDetails.height - 2}px;`,
      };
    case 'te':
      return {
        arrow: `
        margin-left: calc(${contentElementDetails.width}px - 35px);
        transform: rotate(180deg);
      `,
        before: `margin-top: ${contentElementDetails.height}px;`,
        after: `margin-top: ${contentElementDetails.height - 3}px;`,
      };
    case 'rs':
      return {
        arrow: 'transform: rotate(-90deg); margin-left: -20px;',
        before: 'margin-top: 6px;',
        after: 'margin-top: 5px;',
      };
    case 'rm':
      return {
        arrow: 'transform: rotate(-90deg); margin-left: -20px;',
        before: `margin-top: calc(${contentElementDetails.height}px / 2 - 11px);`,
        after: `margin-top: calc(${contentElementDetails.height}px / 2 - 12px);`,
      };
    case 're':
      return {
        arrow: 'transform: rotate(-90deg); margin-left: -20px;',
        before: `margin-top: calc(${contentElementDetails.height}px - 26px);`,
        after: `margin-top: calc(${contentElementDetails.height}px - 27px);`,
      };
    case 'bs':
      return {
        arrow: 'margin-left: 5px;',
        before: 'margin-top: -19px;',
        after: 'margin-top: -18px;',
      };
    case 'bm':
      return {
        arrow: `margin-left: calc(${contentElementDetails.width}px / 2 - 11px);`, // 11px is the arrow width
        before: 'margin-top: -19px;',
        after: 'margin-top: -18px;',
      };
    case 'be':
      return {
        arrow: `margin-left: calc(${contentElementDetails.width}px - 35px);`,
        before: 'margin-top: -19px;',
        after: 'margin-top: -18px;',
      };
    case 'ls':
      return {
        arrow: `transform: rotate(90deg); margin-left: ${contentElementDetails.width - 2}px;`,
        before: 'margin-top: 3px; border-width: 11px;',
        after: 'margin-top: 4px; border-width: 10px;',
      };
    case 'lm':
      return {
        arrow: `transform: rotate(90deg); margin-left: ${contentElementDetails.width - 2}px;`,
        before: `margin-top: calc(${contentElementDetails.height}px / 2 - 11px); border-width: 11px;`,
        after: `margin-top: calc(${contentElementDetails.height}px / 2 - 10px); border-width: 10px;`,
      };
    case 'le':
      return {
        arrow: `transform: rotate(90deg); margin-left: ${contentElementDetails.width - 2}px;`,
        before: `margin-top: calc(${contentElementDetails.height}px - 27px); border-width: 11px;`,
        after: `margin-top: calc(${contentElementDetails.height}px - 26px);`,
      };
    default:
      return {};
  }
};

export default getUpdatedArrowPosition;
