import { push as pushAction } from 'connected-react-router';
import { connect } from 'react-redux';

import * as actions from 'app-state/actions';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';
import { ExclamationMark } from 'svg-icons';
import { COLORS } from 'shared-parts/constants';

import { ButtonWithMargin, Container, Header, Message } from './forbidden.styled';

const ForbiddenPage = ({ signOut, push }) => {
  const goRoot = () => {
    push({ pathname: Routes.Root() });
  };

  return (
    <Container>
      {ExclamationMark(66, 65, COLORS.error)}
      <Header>FORBIDDEN</Header>
      <Message>You are not authorised to view this page.</Message>
      <ButtonWithMargin onClick={signOut}>SIGN OUT</ButtonWithMargin>
      <ButtonWithMargin onClick={goRoot}>HOME</ButtonWithMargin>
    </Container>
  );
};

const enableOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

const mapDispatchToProps = dispatch => ({
  push: url => dispatch(pushAction(url)),
  signOut: () => dispatch(enableOauth2Login ? actions.oauth2Logout() : actions.logout()),
});

export default connect(undefined, mapDispatchToProps)(ForbiddenPage);
