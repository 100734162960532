import styled from 'styled-components';

import { TextButton } from 'shared-parts/components/button';
import { COLORS, FONTS } from 'shared-parts/constants';

// Modal width seems broken and also type is causing issues so any for now
const ModalWrapper = styled.div<{ modalWidth: any }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${({ modalWidth }) => modalWidth}px;
  height: fit-content;
  padding: 40px 37px;
`;

const ModalTitle = styled.div`
  font-size: 35px;
  line-height: 45px;
  margin: 22px 0 24px;
  font-weight: 300;
  color: ${COLORS.primary};
  font-family: ${FONTS.oswaldFont};
  text-align: center;
`;

const Message = styled.div<{ maxWidth?: string }>`
  color: ${COLORS.neutral800};
  font-size: 16px;
  line-height: 24px;
  font-family: ${FONTS.mainFont};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: center;
  margin-bottom: 1rem;
`;

const ButtonsPart = styled.div`
  margin-top: 24px;
`;

const StyledTextButton = styled(TextButton)`
  margin-right: 20px;
`;

export { ModalWrapper, ModalTitle, ButtonsPart, StyledTextButton, Message };
