import * as constants from 'app-state/constants';

const defaultShowModal = { show: false, content: null };

export const modalReducer = (state = defaultShowModal, action) => {
  switch (action.type) {
    case constants.SHOW_MODAL:
      return { show: true, content: action.data };
    case constants.HIDE_MODAL:
      return defaultShowModal;
    default:
      return state;
  }
};

export const redirectPathReducer = (state = null, action) => {
  switch (action.type) {
    case constants.SAVE_REDIRECT_PATH:
      return action.route;
    case constants.REMOVE_REDIRECT_PATH:
      return null;
    default:
      return state;
  }
};

const defaultSideFormState = { isOpen: false, content: null };

export const sideFormReducer = (state = defaultSideFormState, action) => {
  switch (action.type) {
    case constants.SHOW_SIDE_FORM:
      return { isOpen: true, content: action.data };
    case constants.HIDE_SIDE_FORM:
      return defaultSideFormState;
    default:
      return state;
  }
};

const defaultOverlayState = { show: false, content: null };

export const overlayReducer = (state = defaultOverlayState, action) => {
  switch (action.type) {
    case constants.SHOW_OVERLAY:
      return { isOpen: true, content: action.data };
    case constants.HIDE_OVERLAY:
      return defaultOverlayState;
    default:
      return state;
  }
};
