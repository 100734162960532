import { AnyAction } from 'redux';

import {
  GET_SHARE_CERTIFICATE_CONFIG,
  GET_SHARE_CERTIFICATE_CONFIG_ERROR,
  GET_SHARE_CERTIFICATE_CONFIG_SUCCESS,
  RESET_SHARE_CERTIFICATE_CONFIG,
  UPDATE_SHARE_CERTIFICATE_CONFIG,
  UPDATE_SHARE_CERTIFICATE_CONFIG_ERROR,
  UPDATE_SHARE_CERTIFICATE_CONFIG_SUCCESS,
} from './constants';
import { ShareCertificateConfig } from './types';

type ShareCertificateConfigState = {
  data: ShareCertificateConfig | null;
  loading: boolean;
  saving: boolean;
  error: string | null;
};

const initialState: ShareCertificateConfigState = {
  data: null,
  loading: false,
  saving: false,
  error: null,
};

export const shareCertificateConfigReducer = (
  state = initialState,
  action: AnyAction,
): ShareCertificateConfigState => {
  switch (action.type) {
    case RESET_SHARE_CERTIFICATE_CONFIG:
      return initialState;
    case GET_SHARE_CERTIFICATE_CONFIG:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case GET_SHARE_CERTIFICATE_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SHARE_CERTIFICATE_CONFIG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_SHARE_CERTIFICATE_CONFIG:
      return {
        ...state,
        loading: false,
        data: action.payload,
        saving: true,
      };
    case UPDATE_SHARE_CERTIFICATE_CONFIG_SUCCESS:
    case UPDATE_SHARE_CERTIFICATE_CONFIG_ERROR:
      return {
        ...state,
        saving: false,
      };
    default:
      return state;
  }
};
