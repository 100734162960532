import Tooltip from 'shared-parts/components/tooltip';
import { COLORS } from 'shared-parts/constants';

import { UserEmail, UserName } from './index.styled';

const { secondary100 } = COLORS;

const hideTooltip = changeTooltipVisibility => () => changeTooltipVisibility(false);

const renderButton =
  name =>
  ({ changeTooltipVisibility, toggleTooltipVisibility }) => (
    <UserName
      onClick={toggleTooltipVisibility}
      onMouseOver={toggleTooltipVisibility}
      onFocus={toggleTooltipVisibility}
      onBlur={hideTooltip(changeTooltipVisibility)}
      onMouseOut={hideTooltip(changeTooltipVisibility)}
    >
      {name}
    </UserName>
  );

const renderEmail = email => () => <UserEmail>{email}</UserEmail>;

const UserEmailTooltip = user => {
  if (!user) {
    return null;
  }

  const { name, email, position = 'left-middle' } = user;

  return (
    <Tooltip content={renderEmail(email)} position={position} arrowColor={secondary100}>
      {renderButton(name)}
    </Tooltip>
  );
};

export default UserEmailTooltip;
