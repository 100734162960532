import React, { useContext } from 'react';

import {
  addCommasToNumber,
  formatCurrencySign,
  toAbbrInt,
  toDefinedFractionSizeAndCutZeros,
} from 'shared-parts/helpers/format-number';
import getIssuerName from 'shared-parts/helpers/get-issuer-name';
import IssuanceContext, { ComponentsContext } from 'shared-parts/modules/deal/deal.context';

import { ActiveHeaderPropType } from '../types';

import {
  AgentTag,
  Cell,
  CellInfo,
  CellTitle,
  HeaderContainer,
  InfoGrid,
  LogoContainer,
} from './active-header.styled';

export const SectionHeaderActive: React.FC<React.PropsWithChildren<ActiveHeaderPropType>> = ({
  deal,
  item,
}) => {
  const {
    ownershipUnit,
    company: { logo },
  } = useContext(IssuanceContext);

  const { HeaderDetailedSection } = useContext(ComponentsContext);
  const softTokensEmitted = parseInt(
    (deal.softTargetAmount / deal.pricePerToken) as number | any,
    10,
  );

  const cells = [
    {
      title: 'Issuer',
      info: getIssuerName(deal),
    },
    {
      title: `${ownershipUnit}s`,
      info: toAbbrInt(softTokensEmitted),
    },
    {
      title: `${ownershipUnit} Price`,
      info: `${formatCurrencySign(deal.baseCurrency)}${addCommasToNumber(
        toDefinedFractionSizeAndCutZeros(deal.pricePerToken, 2),
      )}`,
    },
    {
      title: 'Target',
      info: `${formatCurrencySign(deal.baseCurrency)}${toAbbrInt(deal.softTargetAmount)}`,
    },
    {
      title: `${ownershipUnit}s Sold`,
      info: toAbbrInt(deal.totalTokensSold),
    },
  ];

  return (
    <HeaderDetailedSection item={item}>
      <HeaderContainer>
        <AgentTag>Agent</AgentTag>
        <LogoContainer>
          <img src={logo?.small} alt="Agent's logo" height="100%" width="auto" />
        </LogoContainer>
        <InfoGrid>
          {cells.map(cell => (
            <Cell key={cell.title}>
              <CellTitle>{cell.title}</CellTitle>
              <CellInfo>{cell.info}</CellInfo>
            </Cell>
          ))}
        </InfoGrid>
      </HeaderContainer>
    </HeaderDetailedSection>
  );
};
