import styled from 'styled-components';

import { SpinnerContainer } from 'shared-parts/components/loader/loader.styled';
import { COLORS } from 'shared-parts/constants';

const { primary } = COLORS;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  z-index: 1;

  ${SpinnerContainer} {
    background: transparent;
    position: relative;
  }
`;

export const LoaderText = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 16px;
  color: ${primary};
  font-style: italic;
`;
