import { useEnvVar } from '@frontend/config';

import transformArrayToQueryParameter from 'helpers/transform-array-to-query-parameter';
import getApiParamsString from 'shared-parts/helpers/get-api-params-string';

const ShareInvestmentPlanPrefix = () => useEnvVar('SHARE_OPTION_SERVICES_URL');
const bulkUploadServiceBaseUrl = () => useEnvVar('BULK_UPLOAD_SERVICES_URL') || '/api/bulkupload';
const getInvestmentServiceUrl = () => useEnvVar('INVESTMENT_SERVICES_URL') || '/api/investment';
const bankAccountServiceName = () => useEnvVar('BANK_ACCOUNTS_SERVICE_NAME', 'wallet');

export default {
  Timezones: () => '/api/v1/timezones',
  InvestorOpportunities: instrument => {
    const urlSearchParams = new URLSearchParams();
    if (instrument) {
      urlSearchParams.set('instrument', instrument);
    }

    return `/api/v1/investor-opportunities?${urlSearchParams.toString()}`;
  },
  SignOut: () => '/api/v1/management-console/sign-out',
  SignUp: () => '/api/v1/management-console/sign-up',
  ChangePassword: () => '/api/v1/management-console/password',
  Login: () => '/api/v1/management-console/sign-in',
  LiquidityRounds: (companyName = 'companyName', permalink = 'permalink') =>
    `/api/v1/liquidity-rounds/${companyName}/${permalink}`,
  GetCurrencies: currencyFor => `/api/v1/currencies?currency_for=${currencyFor}`,
  GetCountries: () => '/api/v1/countries',
  GetUserAlerts: () => '/api/v1/user-alerts/management',
  GetPermissions: () => '/api/v1/management-console/permissions',
  CurrentUser: () => '/api/v1/management-console/current-user',
  ResendEmailCurrent: () => '/api/v1/management-console/resend-email',
  Deals: () => '/api/v1/management-console/deals',
  KybVerification: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/kyb-verification`,
  UserDeals: (companyUuid = 'companyUuid', userUuid = 'userUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/${userUuid}/deals`,
  DealsPerCompany: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals`,
  CompanyAgents: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/group`,
  DealData: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}`,
  DealDetails: ({ companyUuid = 'companyUuid', dealUuid = 'uuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/details`,
  DealOpportunities: (dealUuid = 'uuid') =>
    `/api/v1/management-console/deals/${dealUuid}/opportunities`,
  DealOpportunitiesUpdate: (dealUuid = 'uuid') =>
    `/api/v1/management-console/deals/${dealUuid}/opportunities/bulk-update`,
  EditDeal: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}`,
  EditDealConfiguration: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/configuration`,
  DealAccessPermission: (companyUuid = 'companyUuid', dealUuid = 'dealUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/users`,
  DeleteDealAccessPermission: (
    companyUuid = 'companyUuid',
    dealUuid = 'dealUuid',
    userUuid = 'userUuid',
  ) => `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/users/${userUuid}`,
  UploadDealDocument: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/documents`,
  CreateDeal: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals`,
  DealSummary: ({ companyUuid = ':companyUuid', dealUuid = ':dealUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/summary`,
  DealInfo: (companyUuid = 'companyUuid', uuid = ':uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/info`,
  DealInvitedInvestorList: ({
    companyUuid = 'companyUuid',
    uuid = ':uuid',
    onboardingStatus = [],
    ...rest
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-invites${getApiParamsString(
      rest,
    )}${transformArrayToQueryParameter('&', onboardingStatus, 'onboarding_status')}`,
  DealInvitedInvestorListDataGrid: (
    companyUuid = 'companyUuid',
    uuid = ':uuid',
    queryParams = new URLSearchParams(),
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-invites?${queryParams.toString()}`,
  DealInvitedInvestorListExport: (
    companyUuid = 'companyUuid',
    uuid = 'uuid',
    { onboardingStatus = [], ...rest },
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-invites/export${getApiParamsString(
      rest,
    )}${transformArrayToQueryParameter('&', onboardingStatus, 'onboarding_status')}`,
  DealOrders: ({
    companyUuid = ':companyUuid',
    dealUuid = ':dealUuid',
    page = 'page',
    perPage = 'perPage',
    order = 'order',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders?page=${page}&per_page=${perPage}&order=${order}`,
  DealOrderDetails: ({
    companyUuid = ':companyUuid',
    dealUuid = ':dealUuid',
    orderUuid = ':orderUuid',
  }) => `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}`,
  DealOrdersExport: ({ companyUuid = ':companyUuid', dealUuid = ':dealUuid', order = 'order' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/export?order=${order}`,
  InvestorDocuments: (companyUuid = 'companyUuid', uuid = 'uuid', rest) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/investor/documents${getApiParamsString(
      rest,
    )}`,
  UploadInvestorDocument: (
    companyUuid = 'companyUuid',
    uuid = 'uuid',
    investorUuid = 'investorUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/investor/${investorUuid}/documents`,
  DeleteInvestorDocument: (
    companyUuid = 'companyUuid',
    uuid = 'uuid',
    investorUuid = 'investorUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/investor/${investorUuid}/documents/${documentId}`,
  InvestorDocumentsAgree: (
    companyUuid = 'companyUuid',
    uuid = 'uuid',
    investorUuid = 'investorUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/investor/${investorUuid}/documents/${documentId}/agree`,
  InvestorDocumentsDisagree: (
    companyUuid = 'companyUuid',
    uuid = 'uuid',
    investorUuid = 'investorUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/investor/${investorUuid}/documents/${documentId}/disagree`,
  InvestorTags: {
    All: () => `/api/v1/management-console/tags`,
    InvestorAssociation: (tagUuid = 'tagUuid') =>
      `/api/v1/management-console/tags/${tagUuid}/associations/investor_users`,
    SpecificTag: (tagUuid = 'tagUuid') => `/api/v1/management-console/tags/${tagUuid}`,
  },
  OrderDocuments: (
    companyUuid = 'companyUuid',
    dealUuid = 'uuid',
    orderUuid = 'orderUuid',
    rest = {},
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/documents${getApiParamsString(
      rest,
    )}`,
  UploadOrderDocument: (companyUuid = 'companyUuid', dealUuid = 'uuid', orderUuid = 'orderUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/documents`,
  DeleteOrderDocument: (
    companyUuid = 'companyUuid',
    dealUuid = 'uuid',
    orderUuid = 'orderUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/documents/${documentId}`,
  OrderDocumentAgree: (
    companyUuid = 'companyUuid',
    dealUuid = 'uuid',
    orderUuid = 'orderUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/documents/${documentId}/agree`,
  OrderDocumentDisagree: (
    companyUuid = 'companyUuid',
    dealUuid = 'uuid',
    orderUuid = 'orderUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/documents/${documentId}/disagree`,
  ApproveDealOrder: ({
    companyUuid = 'companyUuid',
    dealUuid = 'dealUuid',
    orderUuid = 'orderUuid',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/approve`,
  MarkAsFundsReceived: ({
    companyUuid = 'companyUuid',
    dealUuid = 'dealUuid',
    orderUuid = 'orderUuid',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/mark-as-funds-received`,
  CancelDealOrder: ({
    companyUuid = 'companyUuid',
    dealUuid = 'dealUuid',
    orderUuid = 'orderUuid',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/cancel`,
  CompleteDeal: ({ companyUuid = 'companyUuid', dealUuid = 'dealUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/complete`,
  TokenisedInviteLink: (companyUuid = 'companyUuid', dealUuid = 'dealUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/permanent-invite-link`,
  DealInvites: (companyUuid = 'companyUuid', uuid = ':uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-placement-invites`,
  DealInvitesNew: (companyUuid = 'companyUuid', uuid = ':uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-invites-new`,
  DealInviteEmailTemplate: (companyUuid = 'companyUuid', uuid = ':uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-placement-invites/email-template`,
  DealInvitesInvestorCounts: (
    companyUuid = 'companyUuid',
    dealUuid = ':uuid',
    queryParams = new URLSearchParams(),
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/investor-counts?${queryParams.toString()}`,
  PublishDeal: ({ companyUuid = ':companyUuid', dealUuid = ':dealUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/publish`,
  UnpublishDeal: ({ companyUuid = ':companyUuid', dealUuid = ':dealUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/unpublish`,
  DealPublishRequest: ({ companyUuid = ':companyUuid', dealUuid = ':dealUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/request-publish`,
  Deal: permalink => `/api/v1/deals/${permalink}`,
  DealPageURL: ({ companyUuid = ':companyUuid', dealUuid = ':dealUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/copy-link`,
  DealSettlements: ({
    companyUuid = ':companyUuid',
    dealUuid = ':dealUuid',
    page,
    perPage,
    order,
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/settlements?page=${page}&per_page=${perPage}&order=${order}`,
  DealOrderMoneyTransferred: ({
    companyUuid = ':companyUuid',
    dealUuid = ':dealUuid',
    orderUuid = ':orderUuid',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/settlements/${orderUuid}/settle`,
  UpdateDealPageHeader: (uuid = 'uuid', companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/update-images`,
  UpdateDealBankAccounts: (uuid = 'uuid', companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/add-bank-accounts`,
  InvestorInfo: ({
    companyUuid = 'companyUuid',
    dealUuid = 'dealUuid',
    email = 'email@test.com',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/private-invites/item?email=${email}`,
  ResendDealInvite: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/private-placement-invites/resend`,
  ResendIPInvite: (investorUuid = 'investorUuid') =>
    `/api/v1/management-console/investors/${investorUuid}/invite`,
  DownloadAmlReport: (
    companyUuid = 'companyUuid',
    dealUuid = 'dealUuid',
    investorUuid = 'investorUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/investor_user/${investorUuid}/aml_report`,
  SettlementsExport: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/settlements/export`,
  Company: (companyId = 'companyId') => `/api/v1/management-console/companies/${companyId}`,
  EditCompany: (companyId = 'companyId') => `/api/v1/management-console/companies/${companyId}`,
  EmptyCompany: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/empty_company`,
  SetCompaniesHouseAuthCode: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/companies_house_auth_code`,
  Companies: () => '/api/v1/management-console/companies',
  BankAccounts: {
    Get: uuid => `/api/${bankAccountServiceName()}/bank-accounts/${uuid}`,
    GetAll: issuerUuid =>
      `/api/${bankAccountServiceName()}/bank-accounts${getApiParamsString(
        { issuerUuid },
        [],
        false,
      )}`,
    Post: () => `/api/${bankAccountServiceName()}/bank-accounts`,
    Delete: uuid => `/api/${bankAccountServiceName()}/bank-accounts/${uuid}`,
    SetAsDefault: uuid => `/api/${bankAccountServiceName()}/bank-accounts/set-as-default/${uuid}`,
    Update: uuid => `/api/${bankAccountServiceName()}/bank-accounts/${uuid}`,
  },
  ForgotPassword: () => '/api/v1/management-console/forgot-password',
  ResetPassword: () => '/api/v1/management-console/reset-password',
  Dashboard: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/dashboard`,
  TreasuryAccount: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/treasury-account`,
  ShareholdersRegistryExport: (companyId = 'companyId', date) =>
    `/api/v1/management-console/companies/${companyId}/shareholders-registry/export${
      date ? `?on_date=${date}` : ''
    }`,
  ShareholdersRegistry: ({ companyId = 'companyId', tags = [], ...rest }) =>
    `/api/v1/management-console/companies/${companyId}/shareholders-registry/entire-registry-indicators${getApiParamsString(
      rest,
      ['fShrClass'],
    )}${transformArrayToQueryParameter('&', tags, 'tags')}`,
  ShareholderCreate: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/shareholders`,
  Holdings: (companyId = 'companyId', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}/holdings`,
  TransferShareholders: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/shareholders`,
  Shareholders: ({ companyId = 'companyId', pageSize = 10, ...rest }) =>
    `/api/v1/management-console/companies/${companyId}/shareholders${getApiParamsString({
      pageSize,
      ...rest,
    })}`,
  RegisteredShareholders: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/registered-shareholders`,
  ShareholdersSearch: (companyUuid = 'companyUuid', email = 'email') =>
    `/api/v1/management-console/companies/${companyUuid}/shareholders/search?email=${email}`,
  ShareOptions: (companyId = ':companyId') =>
    `/api/v1/management-console/companies/${companyId}/share-options`,
  ShareInvestmentPlan: {
    Plans: issuerId => {
      const params = new URLSearchParams();

      if (issuerId) {
        params.set('issuerId', issuerId);
      }

      const query = [...params.values()].length > 0 ? `?${params.toString()}` : '';

      return `${ShareInvestmentPlanPrefix()}/plans${query}`;
    },

    PlanById: (planId = ':planId') => `${ShareInvestmentPlanPrefix()}/plans/${planId}`,

    Orders: {
      ImportPurchaseTemplate: planId =>
        `${ShareInvestmentPlanPrefix()}/orders/purchases/export?planId=${planId}`,
      ImportSaleAndTransferTemplate: planId =>
        `${ShareInvestmentPlanPrefix()}/orders/sells/export?planId=${planId}`,
      deleteOrder: orderId => `${ShareInvestmentPlanPrefix()}/orders/${orderId}/delete`,
    },
    BrokersNote: {
      Create: planId => `${ShareInvestmentPlanPrefix()}/plans/${planId}/broker-note`,
      Download: (planId, dealId) =>
        `${ShareInvestmentPlanPrefix()}/plans/${planId}/broker-note/${dealId}`,
    },
    Payroll: {
      Upload: () => `${ShareInvestmentPlanPrefix()}/payroll`,
      DownloadTemplate: () => `${ShareInvestmentPlanPrefix()}/payroll/template`,
    },
    Bonus: {
      Upload: planId => `${ShareInvestmentPlanPrefix()}/bonus/${planId}`,
      DownloadTemplate: () => `${ShareInvestmentPlanPrefix()}/bonus/template`,
    },
    InviteEmployees: {
      BulkInvite: id => `${ShareInvestmentPlanPrefix()}/plans/${id}/bulk-invite`,
      Invite: id => `${ShareInvestmentPlanPrefix()}/plans/${id}/invite`,
    },
    Holders: {
      Block: id => `${ShareInvestmentPlanPrefix()}/holders/${id}/block`,
      Unblock: id => `${ShareInvestmentPlanPrefix()}/holders/${id}/unblock`,
    },
  },
  ConvertibleNotes: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/convertible-notes`,
  Holders: ({ companyUuid = 'companyUuid', sharePlanUuid = 'sharePlanUuid', statuses = [] }) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/holders${transformArrayToQueryParameter(
      '?',
      statuses,
      'agreement_status',
    )}`,
  RetrieveGrant: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}`,
  EditGrant: (companyUuid = 'companyUuid', sharePlanUuid = 'sharePlanUuid', grantId = 'grantId') =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}`,
  AddGrantDocument: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}/documents`,
  DeleteGrantDocument: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}/documents/${documentId}`,
  GrantHolders: (companyUuid = 'companyUuid', planUuid) =>
    `/api/v1/management-console/companies/${companyUuid}/grant-holders${
      planUuid ? `?plan_uuid=${planUuid}` : ''
    }`,
  GrantHoldersCapital: (companyUuid = ':companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/grant-holders-capital`,
  GrantHoldersEmails: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/grant-holders/emails`,
  GrantHolderSearch: (companyUuid = 'companyUuid', email = 'email') =>
    `/api/v1/management-console/companies/${companyUuid}/grant-holders/search?email=${email}`,
  GrantHolderDetails: (companyUuid = 'companyUuid', grantHolderUuid = 'grantUuid', email) =>
    `/api/v1/management-console/companies/${companyUuid}/grant-holders/${grantHolderUuid}${
      email ? `?email=${email}` : ''
    }`,
  GrantHoldersExport: ({
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    statuses = [],
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/export${transformArrayToQueryParameter(
      '?',
      statuses,
      'agreement_status',
    )}`,
  AddNewGrant: (companyUuid = 'companyUuid', sharePlanUuid = 'sharePlanUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants`,
  DeleteGrant: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantHolderId = 'grantHolderId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantHolderId}`,
  RemoveUnvestedOptions: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantHolderUuid = 'grantHolderUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/holders/${grantHolderUuid}`,
  ShareOptionPlan: (companyId = 'companyId', sharePlanUuid = 'sharePlanUuid') =>
    `/api/v1/management-console/companies/${companyId}/share-options/${sharePlanUuid}`,
  ShareOptionPlanDocument: (companyUuid = 'companyUuid', sharePlanUuid = 'sharePlanUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/documents`,
  DeleteShareOptionPlanDocument: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    id = 'id',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/documents/${id}`,
  AvailableShareOptionsQuantity: (companyUuid = 'companyUuid', sharePlanUuid = 'sharePlanUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/limits`,
  ShareholderDetails: (companyId = 'companyId', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}`,
  ShareholderTransactionRevert: (
    companyId = 'companyId',
    shareholderId = 'shareholderId',
    transactionId = 'transactionId',
  ) =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}/revert_transaction/${transactionId}`,
  DeleteShareholder: (companyUuid = 'companyUuid', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyUuid}/shareholders/delete-shareholder/${shareholderId}`,
  SecondaryTransaction: (transactionUuid = 'transactionUuid') =>
    `/api/v1/management-console/secondary-transactions/${transactionUuid}`,
  FilterLiquidityRounds: params =>
    `/api/liquidity/issuer/liquidity-rounds/filter${params ? getApiParamsString(params) : ''}`,
  GetLiquidityRounds: params =>
    `/api/liquidity/issuer/liquidity-rounds${params ? getApiParamsString(params) : ''}`,
  LiquidityRound: (uuid = 'uuid') => `/api/liquidity/issuer/liquidity-rounds/${uuid}`,
  UpdateLiquidityRoundDetails: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/details/update`,
  UpdateLiquidityRound: uuid => `/api/liquidity/issuer/liquidity-rounds/${uuid}/update`,
  LiquidityRoundSecurities: uuid => `/api/liquidity/issuer/liquidity-rounds/${uuid}/securities`,
  UploadLiquidityRoundBanner: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/upload/banner`,
  UploadLiquidityRoundLogo: uuid => `/api/liquidity/issuer/liquidity-rounds/${uuid}/upload/logo`,
  CreateLiquidityRound: (companyUuid = 'companyUuid') =>
    `/api/liquidity/issuer/liquidity-rounds?companyUuid=${companyUuid}`,
  UpdateLiquidityRoundStatus: (roundUuid = 'roundUuid') =>
    `/api/liquidity/issuer/liquidity-rounds/${roundUuid}/status`,
  LiquidityRoundsShareClasses: (companyUuid = 'companyUuid') =>
    `/api/liquidity/issuer/liquidity-rounds/company/${companyUuid}/shares`,
  LiquidityRoundInvitees: (uuid, sortQuery = '') =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/invitees${sortQuery}`,
  LiquidityRoundOrders: (uuid, query = '') =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/orders${query}`,
  LiquidityRoundOrdersCsv: uuid => `/api/liquidity/issuer/liquidity-rounds/${uuid}/orders/csv`,
  LiquidityRoundOrderAllocationRequest: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/orders/allocation/request`,
  LiquidityRoundOrderAllocationConfirm: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/orders/allocation/confirm`,
  LiquidityRoundSaveOrderAllocation: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/order-allocations`,
  LiquidityGetOrderAllocations: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/order-allocations`,
  LiquidityRoundSettle: uuid => `/api/liquidity/issuer/liquidity-rounds/${uuid}/settle`,
  LiquidityRoundCancelOrder: (uuid, orderUuid) =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/orders/${orderUuid}/cancel`,
  LiquidityRoundSignatureConfigurations: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations`,
  CreateSignatureConfiguration: uuid =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations`,
  DeleteLiquidityRoundSignatureConfiguration: (uuid, signatureConfigurationUuid) =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations/${signatureConfigurationUuid}`,
  GetSignatureConfiguration: (uuid, signatureConfigurationUuid) =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations/${signatureConfigurationUuid}`,
  PatchSignatureConfiguration: (uuid, signatureConfigurationUuid) =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations/${signatureConfigurationUuid}`,
  GetSignatureConfigurationDocumentTypes: () =>
    `/api/liquidity/issuer/signature-configurations/document-types`,
  GetSignatureConfigurationSignerTypes: () =>
    `/api/liquidity/issuer/signature-configurations/signer-types`,
  SignatureConfigurationSigners: (uuid, signatureConfigurationUuid) =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations/${signatureConfigurationUuid}/signers`,
  GetSignatureConfigurationCategoryOptions: () =>
    `/api/liquidity/issuer/signature-configurations/category-options`,
  GetSignatureConfigurationCountries: () =>
    `/api/liquidity/issuer/signature-configurations/countries`,
  GetSignatureConfigurationRegions: () => `/api/liquidity/issuer/signature-configurations/regions`,
  SignatureConfigurationCategories: (uuid, signatureConfigurationUuid) =>
    `/api/liquidity/issuer/liquidity-rounds/${uuid}/signature-configurations/${signatureConfigurationUuid}/categories`,
  AddSecondaryTransactionDocument: (transactionUuid = 'transactionUuid') =>
    `/api/v1/management-console/secondary-transactions/${transactionUuid}/documents`,
  LiquidityGetAllocationOptions: roundUuid =>
    `/api/liquidity/issuer/liquidity-rounds/${roundUuid}/allocation_options`,
  DeleteSecondaryTransactionDocument: (
    transactionUuid = 'transactionUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/secondary-transactions/${transactionUuid}/documents/${documentId}`,
  ApproveSecondaryTransactionDocument: (
    transactionUuid = 'transactionUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/secondary-transactions/${transactionUuid}/documents/${documentId}/approve`,
  RejectSecondaryTransactionDocument: (
    transactionUuid = 'transactionUuid',
    documentId = 'documentId',
  ) =>
    `/api/v1/management-console/secondary-transactions/${transactionUuid}/documents/${documentId}/reject`,
  ShareClassCreate: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/share-classes`,
  ShareClassEdit: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/share-classes`,
  EditShareClassDescription: (companyUuid = 'companyUuid', shareClassId = 'shareClassId') =>
    `/api/v1/management-console/companies/${companyUuid}/share-classes/${shareClassId}/description`,
  ShareClassEditRetrieveQuantity: (
    companyId = 'companyId',
    shareClassId = 'shareClassId',
    nominalSharePrice = 'nominalSharePrice',
    sharesNumberPrecision = 'sharesNumberPrecision',
    roundingStrategy = 'roundingStrategy',
  ) =>
    `/api/v1/management-console/companies/${companyId}/share-classes/update-quantity?share_class_id=${shareClassId}&nominal_share_price=${nominalSharePrice}&shares_number_precision=${sharesNumberPrecision}&rounding_strategy=${roundingStrategy}`,
  ShareClasses: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/share-classes`,
  ConvertShares: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/share-classes/convert-all`,
  BuybackShares: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/equities/buyback`,
  CancelShares: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/equities/cancel`,
  UpdateTransactionsPermission: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/share-classes/update-transactions-permission`,
  UpdateIntroducerFees: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/secondary-transactions/introducer-fees`,
  GetIntroducerFees: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/secondary-transactions/introducer-fees`,
  ConvertSharesForIndividualHolder: (
    companyId = 'companyId',
    shareholderId = 'shareholderId',
    equityId = 'equityId',
  ) =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}/equity/${equityId}/convert`,
  GetQuantitiesForPair: (
    companyId = 'companyId',
    fromId = 'fromId',
    toId = 'toId',
    roundingStrategy = 'roundingStrategy',
    quantity = '',
  ) =>
    `/api/v1/management-console/companies/${companyId}/share-classes/quantities-for-pair?from_share_class_id=${fromId}&to_share_class_id=${toId}&rounding_strategy=${roundingStrategy}${quantity}`,
  ShareholderEdit: (companyId = 'companyId', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}`,
  EditShareholderPerformance: (companyId = 'companyId', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyId}/performance/${shareholderId}`,
  ShareCertificate: {
    Request: (
      id = 'id',
      companyId = 'companyId',
      investorId = 'investorId',
      shareClassId = 'shareClassId',
    ) =>
      `/api/v1/management-console/share-certificates/${companyId}/${investorId}?share_class_id=${shareClassId}&equity_id=${id}`,
    Status: (documentUuid = ':documentUuid') =>
      `/api/v1/investors/investments/shareholder-certificate/${documentUuid}/status`,
    Download: (documentUuid = ':documentUuid') =>
      `/api/v1/investors/investments/shareholder-certificate/${documentUuid}/download`,
  },
  AllocateShareholding: (companyId = 'companyId', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}/equity`,
  TransferShareholding: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/equities/transfer`,
  Communications: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/communications`,
  Communication: (companyUuid = ':companyUuid', communicationUuid = ':communicationUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}`,
  AggregatedCommunicationResponsesStatistics: (
    companyUuid = 'companyUuid',
    communicationUuid = 'communicationUuid',
    page = 0,
    perPage = 10,
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/responses-statistics-aggregated?page=${page}&per_page=${perPage}`,
  CommunicationResponsesStatistics: (
    companyUuid = 'companyUuid',
    communicationUuid = 'communicationUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/responses-statistics`,

  CommunicationResponsesStatisticsExport: (
    companyUuid = ':companyUuid',
    communicationUuid = ':communicationUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/responses-statistics/export`,
  CommunicationPublish: (companyUuid = 'companyUuid', communicationUuid = 'communicationUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/publish`,
  CommunicationAttachments: (
    companyUuid = 'companyUuid',
    communicationUuid = 'communicationUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/attachments`,
  CommunicationAttachment: (
    companyUuid = 'companyUuid',
    communicationUuid = 'communicationUuid',
    attachmentId = 'attachmentId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/attachments/${attachmentId}`,
  ShareholdersEmails: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/communications/shareholders`,
  BinaryUploads: () => '/api/v1/management-console/binary-uploads',
  DeleteBinary: (token = 'token') => `/api/v1/management-console/binary-uploads/${token}`,
  Issuance: (companyUuid = 'companyUuid', modelUuid = 'modelUuid') =>
    `/api/v1/companies/${companyUuid}/issuance/${modelUuid}`,
  CreateIssuance: () => '/api/v1/content-management/issuances',
  Page: (id = 'id') => `/api/v1/navigation-items/${id}/page.json`,
  LiquidityPage: id => `/api/v1/navigation-items/liquidity/navigation-items/${id}/page`,
  Navigation: (modelUuid = 'modelUuid') => `/api/v1/navigation-items.json?model_uuid=${modelUuid}`,
  GenerateBasicNavigation: () => '/api/v1/content-management/navigation-items/generate-basic',
  EditableNavigation: (modelUuid = 'modelUuid', model = 'model') =>
    `/api/v1/content-management/${modelUuid}/navigation-items?model=${model}`,

  CreateLiquidityNavigationItem: () => `/api/v1/content-management/navigation-items`,
  GetLiquidityNavigationItems: (uuid = 'uuid') =>
    `/api/v1/content-management/navigation-items/liquidity/${uuid}`,
  LiquidityNavigationItem: itemId =>
    `/api/v1/content-management/navigation-items/liquidity/navigation-items/${itemId}`,
  ReorderLiquidityNavigationItems: (uuid = 'uuid') =>
    `/api/v1/content-management/navigation-items/liquidity/${uuid}/positions`,

  DeleteNavigationItem: (itemId = 'itemId') =>
    `/api/v1/content-management/navigation-items/${itemId}`,
  CreateNavigationItem: (modelUuid = 'modelUuid', model = 'model') =>
    `/api/v1/content-management/${modelUuid}/navigation-items?model=${model}`,
  EditNavigationItem: (itemId = 'itemId') =>
    `/api/v1/content-management/navigation-items/${itemId}`,
  ReorderNavigationItem: () => '/api/v1/content-management/navigation-items/positions',
  SubNavigation: (id = 'id') => `/api/v1/navigation-items/${id}.json`,
  CompanyEntities: () => `/api/v1/management-console/entity-companies`,
  Company1: (companyUuid = 'companyUuid') => `/api/v1/companies/${companyUuid}`,
  CompanyByIssuerId: (issuerId = 'issuerId') => `/api/v1/issuers/${issuerId}`,
  Investors: params =>
    `/api/v1/management-console/investors/list${getApiParamsString(params, [], true, [
      'investor_category',
      'tagUuid',
      'onboardingStatus',
      'country',
    ])}`,
  InvestorDetails: email => `/api/v1/management-console/investors/details?email=${email}`,
  InvestorsNew: queryParams =>
    `/api/v1/management-console/investors/list?${queryParams.toString()}`,
  InvestorTypes: () => `/api/v1/management-console/investors/investor-types`,
  SupportedIndividualInvestorTypes: (countryCode = 'countryCode') =>
    `/api/v1/investors/onboarding/individual/supported-investor-types?country_code=${countryCode}`,
  SupportedInstitutionalInvestorTypes: (countryCode = 'countryCode') =>
    `/api/v1/investors/onboarding/institutional/supported-investor-types?country_code=${countryCode}`,
  InvestorsBulkImport: () => '/api/v1/management-console/investors/bulk_upload',
  InviteInvestor: () => '/api/v1/management-console/investors',
  InviteIndividualInvestor: () => '/api/v1/management-console/investors/individual',
  Investor: (uuid = 'uuid') => `/api/v1/management-console/investors/${uuid}`,
  InvestorKycManualVerification: (uuid = 'uuid') =>
    `/api/v1/management-console/investors/${uuid}/kyc-manual-verification`,
  WalletTransactions: ({ uuid = 'uuid', page = 1, perPage = 10 }) =>
    `/api/v1/management-console/investors/${uuid}/transactions?page=${page}&per_page=${perPage}`,
  WalletReference: (uuid = 'uuid', currency = 'currency') =>
    `/api/v1/management-console/investor/${uuid}/wallets/${currency}`,
  WalletTransfer: (uuid = 'uuid') => `/api/v1/management-console/investor/${uuid}/wallets/transfer`,
  InvestorInvestments: ({ page = 1, perPage = 10, uuid = 'uuid' }) =>
    `/api/v1/management-console/investors/${uuid}/investments?page=${page}&per_page=${perPage}`,
  InvestorPreferences: ({ uuid = 'uuid' }) =>
    `/api/v1/management-console/investors/${uuid}/preferences`,
  InvestorInvestmentsExport: (uuid = 'uuid') =>
    `/api/v1/management-console/investors/${uuid}/investments/export`,
  InvestorDeals: (uuid = 'uuid') => `/api/v1/management-console/investors/${uuid}/deals`,
  InvestorAgentsPermissions: ({ uuid = 'uuid' }) =>
    `/api/v1/management-console/investors/${uuid}/permissions`,
  Settings: () => '/api/v1/management-console/settings',
  PlatformSettings: () => '/api/v1/management-console/platform-settings',
  CommonPlatformSettings: () => '/api/v1/platform-settings',
  Users: () => '/api/v1/management-console/users',
  CheckCreateUserPermission: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/can-create-user`,
  CreateUser: () => '/api/v1/management-console/users',
  UpdateUser: (userUuid = 'userUuid') => `/api/v1/management-console/users/${userUuid}`,
  DeleteUser: (userUuid = 'userUuid') => `/api/v1/management-console/users/${userUuid}`,
  TwoFactorAuthentication: () => '/api/v1/management-console/user/tfa',
  Verification: () => '/api/v1/management-console/verification',
  CreateSection: () => '/api/v1/content-management/sections',
  EditSectionTitle: (sectionId = 'sectionId') => `/api/v1/content-management/sections/${sectionId}`,
  DeleteSection: (sectionId = 'sectionId') => `/api/v1/content-management/sections/${sectionId}`,
  ReorderSections: () => '/api/v1/content-management/sections/reorder',
  CreateTextComponent: () => '/api/v1/content-management/text-boxes',
  EditTextComponent: id => `/api/v1/content-management/text-boxes/${id}`,
  CreateTableComponent: () => '/api/v1/content-management/table-boxes',
  EditTableComponent: (id = 'id') => `/api/v1/content-management/table-boxes/${id}`,
  CreateDescriptionListComponent: () => '/api/v1/content-management/description-list-boxes',
  EditDescriptionListComponent: (id = 'id') =>
    `/api/v1/content-management/description-list-boxes/${id}`,
  EditImageComponent: (id = 'id') => `/api/v1/content-management/image-boxes/${id}`,
  CreateImageComponent: () => '/api/v1/content-management/image-boxes',
  CreateDocumentComponent: () => '/api/v1/content-management/document-boxes',
  EditDocumentComponent: (id = 'id') => `/api/v1/content-management/document-boxes/${id}`,
  CreateVideoComponent: () => '/api/v1/content-management/video-boxes',
  EditVideoComponent: (id = 'id') => `/api/v1/content-management/video-boxes/${id}`,
  FetchIndicationOfInterest: ({ dealUuid = 'dealUuid', ...rest }) =>
    `/api/v1/management-console/deals/${dealUuid}/ioi${getApiParamsString(rest)}`,
  IndicationOfInterestExport: ({ dealUuid = 'dealUuid', order }) =>
    `/api/v1/management-console/deals/${dealUuid}/ioi/export?order=${order}`,
  DealIOIDetails: ({ dealUuid = ':dealUuid', ioiUuid = ':ioiUuid' }) =>
    `/api/v1/management-console/deals/${dealUuid}/ioi/${ioiUuid}`,
  ApproveDealIOI: ({ dealUuid = ':dealUuid', ioiUuid = ':ioiUuid' }) =>
    `/api/v1/management-console/deals/${dealUuid}/ioi/${ioiUuid}/approve`,
  AdjustDealIOI: ({ dealUuid = ':dealUuid', ioiUuid = ':ioiUuid' }) =>
    `/api/v1/management-console/deals/${dealUuid}/ioi/${ioiUuid}/adjustments`,
  CancelDealIOI: ({ dealUuid = ':dealUuid', ioiUuid = ':ioiUuid' }) =>
    `/api/v1/management-console/deals/${dealUuid}/ioi/${ioiUuid}/cancel`,
  FetchTeamMembers: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/team_members`,
  CreateTeamMember: (companyUuid = 'companyUuid', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/team_members`,
  EditTeamMember: (companyUuid = 'companyUuid', id = 'id', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/team_members/${id}`,
  DetachTeamMember: (companyUuid = 'companyUuid', id = 'id', uuid = 'uuid') =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${uuid}/team_members/${id}/detach`,
  FetchLiquidityTeamMembers: roundUuid =>
    `/api/v1/content-management/liquidity/${roundUuid}/team-members`,
  CreateLiquidityTeamMember: () => `/api/v1/content-management/liquidity/team-member`,
  EditLiquidityTeamMember: id => `/api/v1/content-management/liquidity/team-member/${id}`,
  DetachLiquidityTeamMember: id => `/api/v1/content-management/liquidity/team-member/${id}/detach`,
  CreateTeamComponent: () => '/api/v1/content-management/team-boxes',
  EditTeamComponent: (id = 'id') => `/api/v1/content-management/team-boxes/${id}`,
  ReorderComponent: (sectionBoxId = 'sectionBoxId') =>
    `/api/v1/content-management/section-boxes/${sectionBoxId}/position`,
  CreateTagline: () => '/api/v1/content-management/taglines',
  EditTagline: (id = 'id') => `/api/v1/content-management/taglines/${id}`,
  DeleteTagline: (id = 'id') => `/api/v1/content-management/taglines/${id}`,
  DeleteComponent: (id, type) => {
    switch (type) {
      case 'DocumentsBox':
        return `/api/v1/content-management/document-boxes/${id}`;
      case 'ImageBox':
        return `/api/v1/content-management/image-boxes/${id}`;
      case 'TabularDataBox':
        return `/api/v1/content-management/table-boxes/${id}`;
      case 'DescriptionListBox':
        return `/api/v1/content-management/description-list-boxes/${id}`;
      case 'VideoBox':
        return `/api/v1/content-management/video-boxes/${id}`;
      case 'TextBox':
        return `/api/v1/content-management/text-boxes/${id}`;
      case 'TeamBox':
        return `/api/v1/content-management/team-boxes/${id}`;
      default:
        return '';
    }
  },
  CommunicationAddResponse: (
    companyUuid = 'companyUuid',
    communicationUuid = 'communicationUuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/response-template/bulk-create`,
  CommunicationDeleteResponse: (
    companyUuid = 'companyUuid',
    communicationUuid = 'communicationUuid',
    uuid = 'uuid',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/communications/${communicationUuid}/response-template/${uuid}`,
  AccountDetails: (companyUuid = 'companyUuid', userUuid = 'userUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/${userUuid}`,
  AccountUsers: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users`,
  CreateAccountUser: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users`,
  UpdateAccountUser: (companyUuid = 'companyUuid', userUuid = 'userUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/${userUuid}`,
  DeleteAccountUser: (companyUuid = 'companyUuid', userUuid = 'userUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/${userUuid}`,
  ResendEmail: (companyUuid = 'companyUuid', userUuid = 'userUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/${userUuid}/resend_email`,
  ConfirmEmail: (emailToken = 'emailToken') =>
    `/api/v1/management-console/${emailToken}/confirm-email`,
  CreatePassword: (emailToken = 'emailToken') =>
    `/api/v1/management-console/${emailToken}/confirm-and-set-password`,
  AcceptTerms: (companyUuid = 'companyUuid', userUuid = 'userUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/users/${userUuid}/accept-terms`,
  PaymentSetupIntent: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/payment-cards/setup-intent`,
  GetPaymentDetails: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/payment-cards/details`,
  CreateCustomer: (companyUuid = 'companyUuid', paymentUuid = 'paymentUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/payment-cards/${paymentUuid}/create-customer`,
  SelectPackage: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/subscription`,
  GenerateOnboardingLink: () => '/api/v1/management-console/settings/discount',
  SubscriptionTemplatesPerCompany: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/subscription/templates`,
  SubscriptionTemplates: () => '/api/v1/management-console/templates',
  PermissionLevels: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/permission-levels/${companyUuid}`,
  ImportShareholders: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/shareholders-registry/import`,
  ImportNewIssuanceTransactions: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/shareholders-registry/import/transactions`,
  ImportShareTransferTransactions: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/shareholders-registry/import/transfer-transactions`,
  ImportAllTransactions: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/shareholders-registry/import/all-types-of-transactions`,
  ImportTemplates: (companyId = 'companyId', templateType = 'templateType') =>
    `/api/v1/management-console/companies/${companyId}/import-templates?template_type=${templateType}`,
  BulkWelcomeEmailInfo: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/invitation-shareholders`,
  SendBulkWelcomeEmail: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/invitation-shareholders`,

  GetAllSecondaryTransactions: ({ page, perPage, order, transactionUuid }) =>
    `/api/v1/management-console/secondary-transactions?page=${page}&per_page=${perPage}${
      order ? `&order=${order}` : ''
    }${transactionUuid ? `&transaction_uuid=${transactionUuid}` : ''}`,
  GetSecondaryTransactions: ({
    companyUuid = 'companyUuid',
    page,
    perPage,
    fromDate,
    toDate,
    actionTypes = [],
    order,
    status = [],
    shareClasses = [],
  }) => {
    const fromDateQuery = fromDate ? `&from_date=${fromDate}` : '';
    const toDateQuery = toDate ? `&to_date=${toDate}` : '';
    const statusQuery = transformArrayToQueryParameter('&', status, 'status');
    const shareClassesQuery = transformArrayToQueryParameter('&', shareClasses, 'share_classes');
    const actionTypesQuery = transformArrayToQueryParameter('&', actionTypes, 'action_types');
    const orderQuery = order ? `&order=${order}` : '';
    return `/api/v1/management-console/companies/${companyUuid}/secondary-transactions?page=${page}&per_page=${perPage}${fromDateQuery}${toDateQuery}${shareClassesQuery}${statusQuery}${actionTypesQuery}${orderQuery}`;
  },
  AddTransactionNote: ({ companyUuid = 'companyUuid', transactionId = 'transactionId' }) =>
    `/api/v1/management-console/companies/${companyUuid}/transactions/${transactionId}/notes`,
  UpdateTransactionNote: ({
    companyUuid = 'companyUuid',
    transactionId = 'transactionId',
    noteId = 'noteId',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/transactions/${transactionId}/notes/${noteId}`,
  RemoveTransactionNote: ({
    companyUuid = 'companyUuid',
    transactionId = 'transactionId',
    noteId = 'noteId',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/transactions/${transactionId}/notes/${noteId}`,
  ShareOptionsPlansExport: (companyId = 'companyId') =>
    `/api/v1/management-console/companies/${companyId}/share-options/plans-export`,
  GetGrantTransactionalHistory: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}/transaction-history`,
  ShareOptionData: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}/share-option-data`,
  ExerciseShareOptions: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}/exercise`,
  ShareholderLookup: (
    companyUuid = 'companyUuid',
    sharePlanUuid = 'sharePlanUuid',
    grantId = 'grantId',
  ) =>
    `/api/v1/management-console/companies/${companyUuid}/share-options/${sharePlanUuid}/grants/${grantId}/shareholder-lookup`,
  UpdateShareholderTags: (companyId = 'companyId', shareholderId = 'shareholderId') =>
    `/api/v1/management-console/companies/${companyId}/shareholder-details/${shareholderId}/update-tags`,
  GetCompanyTags: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/tags`,
  UpdateCompanyTag: (companyUuid = 'companyUuid', tagUuid = 'tagUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/tags/${tagUuid}`,
  UpdateCompanyViewPermissions: (companyId = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyId}/toggle-view-permissions`,
  DeleteCompanyTag: (companyUuid = 'companyUuid', tagUuid = 'tagUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/tags/${tagUuid}`,
  ApproveSecondaryTransaction: (companyUuid = 'companyUuid', transactionUuid = 'transactionUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/secondary-transactions/${transactionUuid}/approve`,
  RejectSecondaryTransaction: (companyUuid = 'companyUuid', transactionUuid = 'transactionUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/secondary-transactions/${transactionUuid}/reject`,
  GetFilingsHistory: ({ companyUuid = 'companyUuid', from, to, order = 'order', statuses = [] }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings?order=${order}${
      from ? `&from_date=${from}` : ''
    }${to ? `&to_date=${to}` : ''}${transformArrayToQueryParameter('&', statuses, 'statuses')}`,
  GetFilingDetails: ({ companyUuid = 'companyUuid', filingUuid = 'filingUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/${filingUuid}`,
  SubmitCs01Form: (companyUuid = 'companyUuid') =>
    `/api/v1/management-console/companies/${companyUuid}/filings/cs01`,
  AddFilingDocument: ({ companyUuid = 'companyUuid', filingUuid = 'filingUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/${filingUuid}/documents`,
  DeleteFilingDocument: ({
    companyUuid = 'companyUuid',
    filingUuid = 'filingUuid',
    documentId = 'documentId',
  }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/${filingUuid}/documents/${documentId}`,
  FilingsCSVExport: ({ companyUuid = 'companyUuid', from, to, order = 'order', statuses = [] }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/export?order=${order}${
      from ? `&from_date=${from}` : ''
    }${to ? `&to_date=${to}` : ''}${transformArrayToQueryParameter('&', statuses, 'statuses')}`,
  CompanyProfile: ({ companyUuid = 'companyUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/profile`,
  ShareCapital: ({ companyUuid = 'companyUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/share-capital`,
  ShareholderInformation: ({ companyUuid = 'companyUuid', isCompanyTraded = 'isCompanyTraded' }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/shareholders?company_traded=${isCompanyTraded}`,
  SicCodes: () => '/api/v1/management-console/sic-codes',
  Ping: () => '/api/ping_dev',
  AllotmentData: ({ companyUuid = 'companyUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/sh01/allotment-data`,
  AllottedShares: ({ companyUuid = 'companyUuid', fromDate = 'fromDate', toDate }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/sh01/allotted-shares?from_date=${fromDate}${
      toDate ? `&to_date=${toDate}` : ''
    }`,
  Sh01ShareCapital: ({ companyUuid = 'companyUuid', toDate }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/sh01/share-capital${
      toDate ? `?to_date=${toDate}` : ''
    }`,
  SubmitSh01Form: ({ companyUuid = 'companyUuid' }) =>
    `/api/v1/management-console/companies/${companyUuid}/filings/sh01`,
  PresignImageForS3Upload: () => '/api/v1/s3/presign_url',
  ExchangeRate: (
    baseCurrency = 'baseCurrency',
    toCurrency = 'toCurrency',
    calcContext = 'calcContext',
  ) =>
    `/api/v1/investors/investment/exchange-rate?base_currency=${baseCurrency}&to_currency=${toCurrency}&calc_context=${calcContext}`,
  CompanySummary: {
    Create: (companyUuid = 'companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/summary/add`,
    Edit: (companyUuid = 'companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/summary/edit`,
    Get: (companyUuid = 'companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/summary`,
  },
  DownloadCSV: {
    CreateCSVJob: () => '/jobs',
    CreateExportCommandCSVJob: () => '/jobs/export-command',
    CheckCSVJobStatus: id => `/jobs/${id}`,
    DownloadCSVfile: id => `/jobs/${id}/file`,
  },
  UploadCsv: {
    CheckJobStatus: id => `${bulkUploadServiceBaseUrl()}/job/${id}`,
    UploadTransactions: () => `${bulkUploadServiceBaseUrl()}/transactions/csv-upload`,
    ConvertibleNotes: () => `${bulkUploadServiceBaseUrl()}/convertible-notes/csv-upload`,
    Shareholders: () => `${bulkUploadServiceBaseUrl()}/security-holders/csv-upload`,
    BondsAndLoans: () => `${bulkUploadServiceBaseUrl()}/bonds-and-loans/csv-upload`,
    DownloadTemplate: templateType =>
      `${bulkUploadServiceBaseUrl()}/download-templates/${templateType}`,
  },
  ShareCertificateConfig: {
    Get: (companyUuid = ':companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/certificate-fields`,
    Update: (companyUuid = ':companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/certificate-fields`,

    UploadSignature: (companyUuid = ':companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/officer-signature`,
    DeleteSignature: (companyUuid = ':companyUuid') =>
      `/api/v1/management-console/companies/${companyUuid}/officer-signature/:uuid`,
  },
  InvestorInvite: {
    Investors: (companyUuid, dealUuid, queryParams) =>
      `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/investors?${queryParams.toString()}`,
  },
  PreviewWatermarkedPdf: () => `/api/data-room/watermarking/preview`,
  GetCompanyIntegrations: (companyUuid = 'companyUuid') =>
    `${getInvestmentServiceUrl()}/management/companies/${companyUuid}/integrations`,
  DeleteCompanyConnection: (companyUuid, integrationKey) =>
    `${getInvestmentServiceUrl()}/management/companies/${companyUuid}/integrations/${integrationKey}`,
  GetCompanyESignatureAccounts: (companyUuid, integrationKey) =>
    `${getInvestmentServiceUrl()}/management/companies/${companyUuid}/e-signature/integrations/${integrationKey}/accounts`,
  GetCompanyESignatureTemplates: (companyUuid, integrationKey, accountId) =>
    `${getInvestmentServiceUrl()}/management/companies/${companyUuid}/e-signature/integrations/${integrationKey}/templates?account_id=${accountId}`,
  GetCompanyESignatureTemplate: (companyUuid, integrationKey, templateId, accountId) =>
    `${getInvestmentServiceUrl()}/management/companies/${companyUuid}/e-signature/integrations/${integrationKey}/templates/${templateId}?account_id=${accountId}`,
  GetDataRoomFile: (uuid = 'uuid') => `/api/data-room/files/${uuid}/download`,
  SupportedCountries: () => `/api/v1/investors/onboarding/supported-countries`,
  CreateCompany: () => '/api/v1/management-console/companies',
  AdjustDealOrder: ({ companyUuid, dealUuid, orderUuid }) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/orders/${orderUuid}/adjust`,
  OrderAdjustmentHistory: orderUuid => `/api/v1/orders/${orderUuid}/adjustment-history`,
  PlacementStartClosing: (companyUuid, dealUuid) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/start-closing`,
  PlacementEndClosing: (companyUuid, dealUuid) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${dealUuid}/end-closing`,
  CreateContract: () => `${getInvestmentServiceUrl()}/contracts`,
  DeleteContract: contractUuid => `${getInvestmentServiceUrl()}/contracts/${contractUuid}`,
  UpdateContract: contractUuid => `${getInvestmentServiceUrl()}/contracts/${contractUuid}`,
  FundClosings: (companyUuid, fundUuid) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${fundUuid}/closings`,
  UpdateClosingsDetails: (companyUuid, fundUuid, closingUuid) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${fundUuid}/closings/${closingUuid}`,
  cancelSideLetter: (companyUuid, fundUuid, orderUuid) =>
    `/api/v1/management-console/companies/${companyUuid}/deals/${fundUuid}/orders/${orderUuid}/cancel-envelope`,
};
