import { push } from 'connected-react-router';
import { sessionService } from 'redux-react-session';

import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import { PING } from 'app-state/constants/shared';

import API from 'constants/api';
import Routes from 'constants/routes';
import Role from 'helpers/role';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import request from 'shared-parts/helpers/request';

import { saveUserDataSaga } from './authentication';

function* createPassword({ formData, token, setSubmitting, setErrors }) {
  try {
    yield call(sessionService.deleteUser);
    yield call(sessionService.deleteSession);
    const { data } = yield call(request, API.CreatePassword(token), 'POST', formData);

    yield call(saveUserDataSaga, actions.saveUserData(data));
    const shouldGetPermissions = yield call(Role.can, 'Get permissions');
    if (shouldGetPermissions) {
      yield put(actions.getPermissions());
    }
  } catch ({ response }) {
    if (response.details) {
      yield setErrors(response.details);
      yield setSubmitting(false);
    } else {
      yield put(push(Routes.Login()));
    }
  }
}

function* createPasswordWatcher() {
  yield takeEvery(constants.CREATE_PASSWORD, createPassword);
}

function* confirmEmail({ emailToken }) {
  try {
    const { data } = yield call(request, API.ConfirmEmail(emailToken), 'POST');

    yield call(saveUserDataSaga, actions.saveUserData(data));
    const url = yield call(Role.has, 'Login Redirection Url', { companyId: data.companyIds[0] });

    yield put(push(url));
    yield gtmTrack({}, 'GCEmailConfirmed', data);
  } catch ({ response }) {
    yield put(push(Routes.Login()));
  }
}

function* confirmEmailWatcher() {
  yield takeEvery(constants.CONFIRM_EMAIL, confirmEmail);
}

function* resendEmailForCurrentUser() {
  try {
    const { data } = yield call(request, API.ResendEmailCurrent(), 'POST');
    const userData = {
      ...data,
      isEmailConfirmationSent: true,
    };
    yield call(saveUserDataSaga, actions.saveUserData(userData));
  } catch (e) {
    yield put(actions.resendEmailForCurrentUserError(e));
  }
}

function* resendEmailForCurrentUserWatcher() {
  yield takeEvery(constants.RESEND_EMAIL_CURRENT, resendEmailForCurrentUser);
}

function* updateCurrentUser({ data }) {
  try {
    return yield call(request, API.CurrentUser(), 'PUT', data);
  } catch (e) {
    yield put(actions.updateCurrentUserError(e));
    throw e;
  }
}

function* updateCurrentUserWatcher() {
  yield takeEvery(constants.UPDATE_CURRENT_USER, updateCurrentUser);
}

function* updateMyDetails({ data, form, showEmailModal }) {
  try {
    const { data: userData } = yield call(updateCurrentUser, actions.updateCurrentUser(data));
    yield call(saveUserDataSaga, actions.saveUserData(userData));
    yield put(actions.hideSideForm());
    if (showEmailModal) yield showEmailModal();
  } catch (e) {
    if (e.status === 400) {
      yield form.setErrors(e.response.details);
      yield form.setSubmitting(false);
    }
  }
}

function* updateMyDetailsWatcher() {
  yield takeEvery(constants.UPDATE_MY_DETAILS, updateMyDetails);
}

function* ping() {
  return yield call(request, API.Ping());
}

function* pingWatcher() {
  yield takeEvery(PING, ping);
}

export {
  createPassword,
  createPasswordWatcher,
  confirmEmail,
  confirmEmailWatcher,
  updateCurrentUser,
  updateCurrentUserWatcher,
  resendEmailForCurrentUser,
  resendEmailForCurrentUserWatcher,
  updateMyDetails,
  updateMyDetailsWatcher,
  pingWatcher,
};
