import { forwardRef } from 'react';

import LabelTooltip from 'modules/company/equity-administration/secondary-transactions/components/label-tooltip';
import { COLORS } from 'shared-parts/constants';

import { TableHeader, TableHeaderCell, TableHeaderText, TableRow } from '../table.styled';

import Arrow from './sorting-arrow';

const { white } = COLORS;

const Header = forwardRef(
  ({ header = [], fixedHeader, sortingData, updateSortingData }, refTableHeader) =>
    header.length ? (
      <TableHeader ref={refTableHeader}>
        <TableRow header>
          {header.map(({ styles = '', name, colspan, sorting, description, tooltipText }, i) => (
            <TableHeaderCell key={i} colSpan={colspan} styles={styles} fixedHeader={fixedHeader}>
              <TableHeaderText onClick={sorting && updateSortingData(sorting)}>
                <Arrow sorting={sorting} sortingData={sortingData} />
                {name}
                {description ? (
                  <>
                    <br />
                    <small>{description}</small>
                  </>
                ) : null}
                {tooltipText && <LabelTooltip content={tooltipText} iconColor={white} />}
              </TableHeaderText>
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
    ) : null,
);

export default Header;
