import type { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const RoundedWarningTriangle: FC<React.PropsWithChildren<Props>> = ({
  width = 40,
  height = 40,
  fillColor = '#cd6d30',
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56.926 49.02">
    <g id="Group_7374" data-name="Group 7374" transform="translate(-1117.945 -360.254)">
      <g
        id="Path_1934"
        data-name="Path 1934"
        transform="translate(1113.532 354.305)"
        fill="none"
        strokeLinecap="round"
      >
        <path
          d="M29.046,8.1a4.486,4.486,0,0,1,7.661,0L60.734,48.477A4.323,4.323,0,0,1,56.9,54.969H8.849a4.323,4.323,0,0,1-3.831-6.492Z"
          stroke="none"
        />
        <path
          d="M 32.87619400024414 11.44101333618164 L 9.948837280273438 49.96897125244141 L 55.80358123779297 49.96897125244141 L 32.87619400024414 11.44101333618164 M 32.87619781494141 5.948963165283203 C 34.36466979980469 5.948963165283203 35.85313415527344 6.666229248046875 36.70680618286133 8.100757598876953 L 60.73386764526367 48.47665786743164 C 62.44850540161133 51.35800933837891 60.31796646118164 54.96897125244141 56.90325546264648 54.96897125244141 L 8.849143981933594 54.96897125244141 C 5.434429168701172 54.96897125244141 3.30389404296875 51.3580207824707 5.018543243408203 48.47665786743164 L 29.04559326171875 8.100757598876953 C 29.89926147460938 6.666229248046875 31.38772964477539 5.948963165283203 32.87619781494141 5.948963165283203 Z"
          stroke="none"
          fill={fillColor}
        />
      </g>
      <path
        id="Path_1933"
        data-name="Path 1933"
        d="M0,0H4.5V10.845H0Z"
        transform="translate(1144.23 380.075)"
        fill={fillColor}
      />
      <path
        id="Rectangle_2722_-_Outline"
        data-name="Rectangle 2722 - Outline"
        d="M.643.678v9.489H3.857V.678H.643M0,0H4.5V10.845H0Z"
        transform="translate(1144.23 380.075)"
        fill={fillColor}
      />
      <path
        id="Path_1932"
        data-name="Path 1932"
        d="M0,0H4.5V4.745H0Z"
        transform="translate(1144.23 394.201)"
        fill={fillColor}
      />
      <path
        id="Rectangle_2723_-_Outline"
        data-name="Rectangle 2723 - Outline"
        d="M.643.678V4.067H3.857V.678H.643M0,0H4.5V4.745H0Z"
        transform="translate(1144.23 394.201)"
        fill={fillColor}
      />
    </g>
  </svg>
);

export default RoundedWarningTriangle;
