import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DataGrid } from '@frontend/design-system';

import { fetchInvestorPreferences, resetInvestorPreferences } from 'app-state/investors/actions';
import {
  getInvestorPreferences,
  getInvestorPreferencesLoading,
} from 'app-state/investors/selectors';

import useTypedSelector from 'hooks/use-typed-selector';
import Loader from 'shared/investor-modal/components/loader';

import { PreferencesPropTypes } from '../types';

import { getPreferencesItem } from './index.helpers';

const Preferences: FC<React.PropsWithChildren<PreferencesPropTypes>> = ({ investorUuid }) => {
  const dispatch = useDispatch();
  const arePreferencesLoading = useTypedSelector(getInvestorPreferencesLoading);
  const preferences = useTypedSelector(getInvestorPreferences);

  useEffect(() => {
    dispatch(fetchInvestorPreferences({ uuid: investorUuid }));

    return () => {
      dispatch(resetInvestorPreferences());
    };
  }, [investorUuid]);

  const renderPreferences = () => {
    const preferencesItems = getPreferencesItem(preferences || {});

    return (
      <DataGrid
        pagination={false}
        paginationMode="client"
        columns={[
          {
            field: 'label',
            flex: 0.5,
          },
          {
            field: 'value',
            flex: 0.4,
          },
        ]}
        rows={preferencesItems}
        getRowId={row => row.label}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        disableMultipleRowSelection
        disableRowGrouping
        disableAggregation
        hideFooter
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            display: 'none',
            minHeight: 0,
            height: 0,
          },
        }}
      />
    );
  };

  return <>{arePreferencesLoading ? <Loader /> : renderPreferences()}</>;
};

export default Preferences;
