import sanitize from 'shared-parts/helpers/html/sanitize';

import { Button, ElementContainer, LinkButton, TableCell, TextWithLink } from '../table.styled';

import MultiActions from './multi-actions';

const renderCell =
  ({ rowIndex, renderCustomCell, isHTMLAllowed } = { isHTMLAllowed: false }) =>
  (
    {
      redirectURL,
      customCell,
      text,
      multiActions,
      action,
      isActionDisabled,
      link,
      styles,
      colspan,
    },
    index,
  ) => {
    if (multiActions) {
      return (
        <TableCell colSpan={colspan} key={index} styles={styles} onClick={e => e.stopPropagation()}>
          <MultiActions multiActions={multiActions} />
        </TableCell>
      );
    }

    if (action) {
      return (
        <TableCell colSpan={colspan} key={index} styles={styles} onClick={e => e.stopPropagation()}>
          <Button onClick={action} disabled={isActionDisabled}>
            {text}
          </Button>
        </TableCell>
      );
    }

    if (link) {
      return (
        <TableCell colSpan={colspan} key={index} styles={styles}>
          <LinkButton to={link}>{text}</LinkButton>
        </TableCell>
      );
    }

    if (text) {
      // presumably "text instanceof Object && !Array.isArray(text)" is used to display icons
      // for example: Visible(20, 20)
      const textElement =
        !isHTMLAllowed || (text instanceof Object && !Array.isArray(text)) ? (
          <ElementContainer colSpan={colspan}>{text}</ElementContainer>
        ) : (
          <ElementContainer
            dangerouslySetInnerHTML={{
              __html: sanitize(text),
            }}
          />
        );

      return (
        <TableCell colSpan={colspan} key={index} styles={styles}>
          {redirectURL ? <TextWithLink to={redirectURL}>{text}</TextWithLink> : textElement}
        </TableCell>
      );
    }

    if (customCell) {
      return (
        <TableCell colSpan={colspan} key={index} styles={styles}>
          {renderCustomCell(rowIndex, index, customCell)}
        </TableCell>
      );
    }

    return (
      <TableCell colSpan={colspan} key={index} styles={styles}>
        -
      </TableCell>
    );
  };

export default renderCell;
