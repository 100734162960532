import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './brokers-note.constants';
import initialState, { BrokersNoteStateType } from './brokers-note.state';

export const brokersNoteReducer = (state = initialState, action: AnyAction): BrokersNoteStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.createBrokersNote.REQUEST:
        draft.createBrokersNote.loading = true;
        draft.createBrokersNote.error = undefined;
        draft.createBrokersNote.created = false;
        break;
      case constants.createBrokersNote.FAILURE:
        draft.createBrokersNote.loading = false;
        draft.createBrokersNote.error = action.error;
        draft.createBrokersNote.created = false;
        break;
      case constants.createBrokersNote.SUCCESS:
        draft.createBrokersNote.loading = false;
        draft.createBrokersNote.error = undefined;
        draft.createBrokersNote.created = true;
        break;
      case constants.resetCreateBrokersNote:
        draft.createBrokersNote.loading = false;
        draft.createBrokersNote.error = undefined;
        draft.createBrokersNote.created = false;
        break;
      default:
        break;
    }
  });
