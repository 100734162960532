import { COLORS } from 'shared-parts/constants';

const DownloadArrowIcon = ({ fill = COLORS.primary, width = 14, height = 17 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width.toString()}
    height={height.toString()}
    viewBox="0 0 14 17"
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M19,9H15V3H9V9H5l7,7ZM5,18v2H19V18Z"
      transform="translate(-5 -3)"
    />
  </svg>
);

export default DownloadArrowIcon;
