import PropTypes from 'prop-types';

import {
  AddButton,
  ButtonsWrapper,
  CancelButton,
  ModalTitle,
  ModalWrapper,
} from 'modules/company/equity-administration/share-plans/components/modal-shared.styled';

import { ADD_PRESCRIBED_PARTICULARS_LATER, ADD_PRESCRIBED_PARTICULARS_NOW } from '../../constants';

type ShareClassSuccessProps = {
  title: string;
  hideModal: () => void;
  openPrescribedParticularsForm: () => void;
};

const ShareClassCreatedModal = ({
  title,
  hideModal,
  openPrescribedParticularsForm,
}: ShareClassSuccessProps): JSX.Element => (
  <ModalWrapper data-e2e="share-class-created-modal">
    <ModalTitle data-e2e="share-class-created-title">{title}</ModalTitle>
    <ButtonsWrapper>
      <CancelButton data-e2e="add-share-class-details-later" onClick={hideModal}>
        {ADD_PRESCRIBED_PARTICULARS_LATER}
      </CancelButton>
      <AddButton data-e2e="add-share-class-details-now" onClick={openPrescribedParticularsForm}>
        {ADD_PRESCRIBED_PARTICULARS_NOW}
      </AddButton>
    </ButtonsWrapper>
  </ModalWrapper>
);

ShareClassCreatedModal.propTypes = {
  title: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  openPrescribedParticularsForm: PropTypes.func.isRequired,
};

export default ShareClassCreatedModal;
