import { Field } from 'formik';

import {
  Container,
  DisableAutocompleteFakeInput,
  HintText,
  IconContainer,
  Input,
  ShowPassword,
} from './regular-text-input.styled';
import InputWrapper from './text-input';

const renderDisableAutocompleteFakeInput = (disabled = false) => (
  <DisableAutocompleteFakeInput
    name="fake"
    id="fake"
    type="text"
    data-e2e="fake"
    disabled={disabled}
  />
);

const InputComponent = ({ onIconClick, hint, ...rest }) => (
  <Container
    className={rest.className}
    isValueValid={rest.isValueValid}
    touched={rest.touched}
    backgroundColor={rest.backgroundColor}
    withIcon={rest.icon}
  >
    {rest.disableAutocomplete && renderDisableAutocompleteFakeInput(rest.disabled)}
    <Input
      {...rest}
      ref={rest.inputRef}
      onClick={rest.onClick}
      autoComplete={rest.disableAutocomplete ? 'nope' : rest.autoComplete}
      data-e2e={rest.name}
      onWheel={e => e.target.blur()}
    />
    {!rest.disabled && rest.password && (
      <ShowPassword
        color={rest.placeholderColor}
        touched={rest.touched}
        onClick={rest.handleShowPasswordChange}
      >
        Show Password
      </ShowPassword>
    )}
    {hint && <HintText touched={rest.touched}>{hint}</HintText>}
    {rest.icon && <IconContainer onClick={onIconClick}>{rest.icon}</IconContainer>}
  </Container>
);

export default props => <Field {...props} component={InputWrapper} styledInput={InputComponent} />;
