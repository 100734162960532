import { createSelector } from 'reselect';

import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

export const getDownloadFileLoadingStatus = state => state.downloadFile.loading;
export const getDownloadFileError = state => state.downloadFile.error;
export const getDownloadFileErrorMessage = createSelector(
  getDownloadFileError,
  err => err && (err.response?.details ? err.response.details.items[0] : defaultErrorMessage),
);
