import { string } from 'yup';

import { createPatternSchema, testPattern } from './password-schema.helpers';

const BASIC_MIN_LENGTH = 12;
const EXTRA_MIN_LENGTH = 24;

export const passwordSchema = string()
  .trim()
  .required('Password is required')
  .min(BASIC_MIN_LENGTH, `Password must be at least ${BASIC_MIN_LENGTH} characters`)
  .test(
    'test-extra-pattern',
    'Is not valid password',
    testPattern(EXTRA_MIN_LENGTH, createPatternSchema(3)),
  )
  .test(
    'test-basic-pattern',
    'Is not valid password',
    testPattern(BASIC_MIN_LENGTH, createPatternSchema(1)),
  );
