import { call, put, takeEvery } from 'redux-saga/effects';

import { getTemplatesURLError, getTemplatesURLSuccess } from 'app-state/actions';
import { GET_TEMPLATES_URL } from 'app-state/constants';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import { request as unauthorizedRequest } from 'shared-parts/helpers/request';

function* getTemplatesURL({ companyId, templateType, urlFieldName }) {
  try {
    const { data } = yield call(request, API.ImportTemplates(companyId, templateType));
    const options = {
      mode: 'cors',
      to: 'blob',
      contentType: 'text/plain',
      credentials: 'omit',
      includeInternalHeaders: false,
    };
    const { data: csvBlob } = yield call(
      unauthorizedRequest,
      undefined,
      data[urlFieldName],
      'GET',
      null,
      options,
    );
    const csv = new Blob([csvBlob], { type: 'text/csv' });
    const fileName = data[urlFieldName].split('/').slice(-1)[0];

    downloadBlob(csv, fileName);
    yield put(getTemplatesURLSuccess(data));
  } catch (e) {
    yield put(getTemplatesURLError(e));
  }
}

function* getTemplatesURLWatcher() {
  yield takeEvery(GET_TEMPLATES_URL, getTemplatesURL);
}

export { getTemplatesURLWatcher };
