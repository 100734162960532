import styled from 'styled-components';

import { Link } from 'shared-parts/components';
import { COLORS, FONTS } from 'shared-parts/constants';

const Header = styled.div`
  color: ${({ headerColor }) => headerColor};
  font-family: ${FONTS.oswaldFont};
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 35px;
  margin-bottom: 30px;
`;

const Message = styled.div`
  color: ${({ messageColor }) => messageColor};
  width: 320px;
`;

const MessagePart = styled.p`
  font-weight: 700;
  margin-bottom: 25px;
`;

const ReturnLink = styled(Link)`
  color: ${({ textColor }) => textColor};
  user-select: none;
`;

const NotReceivedEmail = styled.p`
  color: ${({ textColor }) => textColor};
  margin: 25px 0 38px;
`;

const ResendButton = styled.button`
  background: transparent;
  border: none;
  color: ${COLORS.secondary};
  cursor: pointer;
  font-weight: 100;
  padding: 0;
  margin-left: 5px;
  text-decoration: underline;

  :active,
  :focus,
  :hover {
    color: ${COLORS.accent};
  }

  :focus {
    outline: none;
  }
`;

export { Header, Message, ReturnLink, MessagePart, NotReceivedEmail, ResendButton };
