type EnvVar = string | boolean | number | undefined;
type EnvVarSet<T extends string> = { [k in T]: EnvVar };

export function useEnvVar(key: string): EnvVar;
export function useEnvVar<T extends string>(key: T[]): EnvVarSet<T>;
export function useEnvVar<T extends string>(key: string | T[]) {
  const getVar = (varKey: string): EnvVar => {
    const variable = (window as Window).config[varKey as string];
    if (!variable == null) {
      return undefined;
    }
    return variable === 'undefined' ? undefined : variable;
  };

  if (Array.isArray(key)) {
    return key.reduce((o, k) => {
      // eslint-disable-next-line no-param-reassign
      o[k] = getVar(k);
      return o;
    }, {} as EnvVarSet<T>);
  }

  return getVar(key);
}
