export const GET_ALL_SECONDARY_TRANSACTIONS = 'GET_ALL_SECONDARY_TRANSACTIONS';

export const APPROVE_TRANSACTION = 'APPROVE_TRANSACTION';
export const APPROVE_TRANSACTION_SUCCESS = 'APPROVE_TRANSACTION_SUCCESS';
export const APPROVE_TRANSACTION_ERROR = 'APPROVE_TRANSACTION_ERROR';

export const REJECT_TRANSACTION = 'REJECT_TRANSACTION';
export const REJECT_TRANSACTION_SUCCESS = 'REJECT_TRANSACTION_SUCCESS';
export const REJECT_TRANSACTION_ERROR = 'REJECT_TRANSACTION_ERROR';

export const GET_SECONDARY_TRANSACTIONS = 'GET_SECONDARY_TRANSACTIONS';
export const GET_SECONDARY_TRANSACTIONS_SUCCESS = 'GET_SECONDARY_TRANSACTIONS_SUCCESS';
export const GET_SECONDARY_TRANSACTIONS_ERROR = 'GET_SECONDARY_TRANSACTIONS_ERROR';
export const SET_EMPTY_SECONDARY_TRANSACTIONS = 'SET_EMPTY_SECONDARY_TRANSACTIONS';
export const RESET_SECONDARY_TRANSACTIONS = 'RESET_SECONDARY_TRANSACTIONS';

export const GET_SECONDARY_TRANSACTION = 'GET_SECONDARY_TRANSACTION';
export const GET_SECONDARY_TRANSACTION_SUCCESS = 'GET_SECONDARY_TRANSACTION_SUCCESS';
export const GET_SECONDARY_TRANSACTION_ERROR = 'GET_SECONDARY_TRANSACTION_ERROR';
export const RESET_SECONDARY_TRANSACTION = 'RESET_SECONDARY_TRANSACTION';

export const ADD_SECONDARY_TRANSACTION_DOCUMENT = 'ADD_SECONDARY_TRANSACTION_DOCUMENT';
export const ADD_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS =
  'ADD_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS';
export const ADD_SECONDARY_TRANSACTION_DOCUMENT_ERROR = 'ADD_SECONDARY_TRANSACTION_DOCUMENT_ERROR';

export const DELETE_SECONDARY_TRANSACTION_DOCUMENT = 'DELETE_SECONDARY_TRANSACTION_DOCUMENT';
export const DELETE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS =
  'DELETE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS';
export const DELETE_SECONDARY_TRANSACTION_DOCUMENT_ERROR =
  'DELETE_SECONDARY_TRANSACTION_DOCUMENT_ERROR';

export const APPROVE_SECONDARY_TRANSACTION_DOCUMENT = 'APPROVE_SECONDARY_TRANSACTION_DOCUMENT';
export const APPROVE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS =
  'APPROVE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS';
export const APPROVE_SECONDARY_TRANSACTION_DOCUMENT_ERROR =
  'APPROVE_SECONDARY_TRANSACTION_DOCUMENT_ERROR';

export const REJECT_SECONDARY_TRANSACTION_DOCUMENT = 'REJECT_SECONDARY_TRANSACTION_DOCUMENT';
export const REJECT_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS =
  'REJECT_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS';
export const REJECT_SECONDARY_TRANSACTION_DOCUMENT_ERROR =
  'REJECT_SECONDARY_TRANSACTION_DOCUMENT_ERROR';

export const GET_INTRODUCER_FEES = 'GET_INTRODUCER_FEES';
export const GET_INTRODUCER_FEES_SUCCESS = 'GET_INTRODUCER_FEES_SUCCESS';
export const GET_INTRODUCER_FEES_ERROR = 'GET_INTRODUCER_FEES_ERROR';

export const UPDATE_INTRODUCER_FEES = 'UPDATE_INTRODUCER_FEES';
export const UPDATE_INTRODUCER_FEES_SUCCESS = 'UPDATE_INTRODUCER_FEES_SUCCESS';
export const UPDATE_INTRODUCER_FEES_ERROR = 'UPDATE_INTRODUCER_FEES_ERROR';
