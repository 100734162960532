import { COLORS } from 'shared-parts/constants';

const { secondary } = COLORS;

const FilterIcon = ({ color = secondary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14.555"
    viewBox="0 0 15 14.555"
    focusable="false"
    aria-hidden="true"
  >
    <g transform="translate(44.635 -65.597)">
      <g transform="translate(-41.704 77.959)">
        <path
          d="M201.076,763.107h11.075a.5.5,0,1,0,0-.994H201.076a.5.5,0,1,0,0,.994Z"
          transform="translate(-200.579 -762.113)"
          fill={color}
        />
      </g>
      <g transform="translate(-44.387 76.761)">
        <circle cx="1.446" cy="1.446" r="1.446" transform="translate(0.249 0.248)" fill={color} />
        <g transform="translate(0 0)">
          <path
            d="M51.133,695.126c.049,0,.1,0,.148.008.079.007-.048-.008.03,0l.071.014c.047.01.092.023.138.038l.067.024q.041.016.006,0l.043.02a1.444,1.444,0,0,1,.238.145c-.041-.03.019.017.022.019l.052.046a1.475,1.475,0,0,1,.112.117s.05.062.019.022c.014.018.027.038.04.057.026.038.05.079.072.119.011.02.022.041.032.062,0,0,.032.074.013.026a1.435,1.435,0,0,1,.053.157c.013.046.023.093.031.14.014.078,0-.049,0,.03,0,.024,0,.049.006.073a1.471,1.471,0,0,1,0,.149c0,.025,0,.049-.006.073s0,.032,0,.006q0,.024-.008.048a1.435,1.435,0,0,1-.08.273c.019-.048-.011.023-.013.026-.01.021-.021.042-.032.062a1.457,1.457,0,0,1-.086.138s-.048.064-.017.024c-.014.018-.029.036-.045.053-.03.035-.063.068-.1.1-.017.016-.034.031-.052.046s-.062.05-.022.019a1.437,1.437,0,0,1-.135.09c-.04.024-.082.045-.124.065-.069.033.044-.017-.027.012l-.067.024c-.045.015-.091.027-.138.038l-.071.014c-.03,0-.032.005-.006,0l-.049.005a1.459,1.459,0,0,1-.3-.005c.053.006-.026,0-.03-.006l-.07-.015a1.429,1.429,0,0,1-.159-.048l-.044-.017c.048.019-.023-.011-.026-.013-.035-.017-.069-.035-.1-.055s-.066-.041-.1-.063l-.038-.027c.041.03-.019-.017-.022-.019a1.455,1.455,0,0,1-.163-.163l-.029-.036c.032.039-.015-.021-.017-.024-.022-.032-.043-.065-.063-.1s-.038-.068-.055-.1l-.02-.043c.021.047-.009-.024-.01-.028a1.438,1.438,0,0,1-.063-.229q0-.024-.008-.048c.008.052,0-.027,0-.031,0-.041,0-.082-.005-.124s0-.082.005-.123q0-.024,0-.049c-.006.052.005-.026.006-.03a1.436,1.436,0,0,1,.063-.229l.017-.044c-.019.048.011-.023.013-.026.017-.035.035-.069.055-.1s.041-.066.063-.1l.027-.038c-.03.041.017-.019.019-.022a1.455,1.455,0,0,1,.163-.163l.036-.029c-.039.032.021-.014.024-.017.032-.022.065-.043.1-.063s.068-.038.1-.055l.043-.02c-.047.021.024-.009.028-.01a1.429,1.429,0,0,1,.229-.063l.048-.008c-.052.008.027,0,.031,0,.041,0,.082,0,.123-.005a.248.248,0,1,0,0-.5,1.728,1.728,0,0,0-1.182.48,1.695,1.695,0,1,0,2.488.137,1.682,1.682,0,0,0-1.11-.6,1.649,1.649,0,0,0-.2-.013.248.248,0,1,0,0,.5Z"
            transform="translate(-49.437 -694.629)"
            fill={color}
          />
        </g>
      </g>
      <g transform="translate(-33.303 72.377)">
        <path
          d="M674.438,448.592h2.674a.5.5,0,0,0,0-.994h-2.674a.5.5,0,1,0,0,.994Z"
          transform="translate(-673.941 -447.598)"
          fill={color}
        />
      </g>
      <g transform="translate(-44.635 72.377)">
        <path
          d="M35.964,448.592h8.3a.5.5,0,1,0,0-.994h-8.3a.5.5,0,1,0,0,.994Z"
          transform="translate(-35.467 -447.598)"
          fill={color}
        />
      </g>
      <g transform="translate(-36.014 71.179)">
        <circle cx="1.446" cy="1.446" r="1.446" transform="translate(0.249 0.249)" fill={color} />
        <path
          d="M522.885,380.609c.049,0,.1,0,.148.007.079.007-.048-.008.03,0l.071.014c.047.01.092.023.138.038l.067.024c.027.011.029.012.006,0l.042.02a1.444,1.444,0,0,1,.238.145c-.041-.03.019.017.022.019l.052.046a1.451,1.451,0,0,1,.112.117s.05.062.019.022c.014.018.027.038.04.057.026.038.05.079.072.119.011.02.022.041.032.062,0,0,.032.074.013.026a1.432,1.432,0,0,1,.084.3c.014.078,0-.049,0,.03,0,.024,0,.049.006.073a1.442,1.442,0,0,1,0,.149c0,.025,0,.049-.006.073s0,.032,0,.006q0,.024-.008.048a1.441,1.441,0,0,1-.08.273c.019-.048-.011.023-.013.026-.01.021-.021.042-.032.062a1.455,1.455,0,0,1-.086.138s-.048.064-.017.024c-.014.018-.029.036-.045.053-.03.035-.063.068-.1.1-.017.016-.034.031-.052.046s-.062.05-.022.019a1.457,1.457,0,0,1-.135.09c-.04.024-.082.045-.124.065-.069.033.044-.017-.027.011l-.067.024c-.045.015-.091.027-.138.038l-.071.014q-.045.007-.006,0l-.049,0a1.463,1.463,0,0,1-.3,0c.053.006-.026-.005-.03-.006l-.07-.015a1.435,1.435,0,0,1-.159-.048l-.044-.017c.048.019-.023-.011-.026-.013-.035-.017-.069-.035-.1-.055s-.066-.041-.1-.063l-.038-.027c.041.03-.019-.017-.022-.019a1.452,1.452,0,0,1-.163-.163l-.03-.036c.032.039-.015-.021-.017-.024-.022-.032-.043-.065-.063-.1s-.038-.068-.055-.1l-.02-.043c.021.047-.009-.024-.01-.028a1.441,1.441,0,0,1-.063-.229q0-.024-.008-.048c.008.052,0-.027,0-.031,0-.041,0-.082-.005-.123s0-.082.005-.124q0-.024,0-.049c-.006.052.005-.026.006-.03a1.428,1.428,0,0,1,.063-.229l.017-.044c-.019.048.011-.023.013-.026.017-.035.035-.069.055-.1s.041-.066.063-.1l.027-.038c-.03.041.017-.019.019-.022a1.452,1.452,0,0,1,.163-.163l.036-.029c-.039.032.021-.014.024-.017.032-.022.065-.043.1-.063s.068-.038.1-.055l.043-.02c-.047.021.024-.009.028-.01a1.435,1.435,0,0,1,.229-.063l.048-.008c-.052.008.027,0,.031,0,.041,0,.082,0,.124-.005a.248.248,0,0,0,0-.5,1.728,1.728,0,0,0-1.182.48,1.695,1.695,0,1,0,2.488.137,1.729,1.729,0,0,0-1.306-.617.248.248,0,1,0,0,.5Z"
          transform="translate(-521.189 -380.113)"
          fill={color}
        />
      </g>
      <g transform="translate(-38.631 66.794)">
        <path
          d="M374.23,134.076h8a.5.5,0,0,0,0-.994h-8a.5.5,0,1,0,0,.994Z"
          transform="translate(-373.733 -133.082)"
          fill={color}
        />
      </g>
      <g transform="translate(-44.635 66.794)">
        <path
          d="M35.964,134.076h2.969a.5.5,0,1,0,0-.994H35.964a.5.5,0,0,0,0,.994Z"
          transform="translate(-35.467 -133.082)"
          fill={color}
        />
      </g>
      <g transform="translate(-41.342 65.597)">
        <circle cx="1.446" cy="1.446" r="1.446" transform="translate(0.249 0.248)" fill={color} />
        <path
          d="M222.68,66.094c.05,0,.1,0,.148.007.079.007-.048-.008.03,0l.071.014c.047.01.092.023.138.038l.067.024q.041.016.006,0l.043.02a1.444,1.444,0,0,1,.238.145c-.041-.03.019.017.022.019l.052.046a1.459,1.459,0,0,1,.112.117s.05.062.019.022c.014.018.027.038.04.057.026.038.05.079.072.119.011.02.022.041.032.062,0,0,.032.074.013.026a1.439,1.439,0,0,1,.053.157c.013.046.023.093.031.14.014.078,0-.049,0,.03,0,.024,0,.049.006.073a1.472,1.472,0,0,1,0,.149c0,.025,0,.049-.006.073s0,.032,0,.006q0,.024-.008.048a1.435,1.435,0,0,1-.08.273c.019-.048-.011.023-.013.026-.01.021-.021.042-.032.062a1.436,1.436,0,0,1-.086.138s-.048.064-.017.024c-.014.018-.029.036-.045.053-.03.035-.063.068-.1.1l-.052.046s-.062.05-.022.019a1.447,1.447,0,0,1-.135.09c-.04.024-.082.045-.124.065-.069.033.044-.017-.027.012l-.067.024c-.045.015-.091.027-.138.038l-.071.014q-.045.007-.006,0l-.049,0a1.464,1.464,0,0,1-.3,0c.053.006-.026-.005-.03-.006l-.07-.015a1.431,1.431,0,0,1-.159-.048l-.044-.017c.048.019-.023-.011-.026-.013-.035-.017-.069-.035-.1-.055s-.066-.041-.1-.063l-.038-.027c.041.03-.019-.017-.022-.019a1.458,1.458,0,0,1-.163-.163l-.029-.036c.032.039-.014-.021-.017-.024-.022-.032-.043-.065-.063-.1s-.038-.068-.055-.1l-.02-.043c.021.047-.009-.024-.01-.028a1.437,1.437,0,0,1-.063-.229q0-.024-.008-.048c.008.052,0-.027,0-.031,0-.041,0-.082-.005-.123s0-.082.005-.124q0-.024,0-.049c-.006.053.005-.026.006-.03a1.436,1.436,0,0,1,.063-.229l.017-.044c-.019.048.011-.023.013-.026.017-.035.035-.069.055-.1s.041-.066.063-.1l.027-.038c-.03.041.017-.019.019-.022a1.455,1.455,0,0,1,.163-.163l.036-.029c-.039.032.021-.015.024-.017.032-.022.065-.043.1-.063s.068-.038.1-.055l.043-.02c-.047.021.024-.009.028-.01a1.436,1.436,0,0,1,.229-.063l.048-.008c-.052.008.027,0,.031,0,.041,0,.082,0,.123-.005a.248.248,0,1,0,0-.5,1.728,1.728,0,0,0-1.182.48,1.695,1.695,0,1,0,2.488.137,1.729,1.729,0,0,0-1.306-.617.248.248,0,0,0,0,.5Z"
          transform="translate(-220.984 -65.597)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default FilterIcon;
