import { COLORS } from 'shared-parts/constants';

const { neutral500 } = COLORS;

export interface NewCloseIcon {
  readonly color?: string;
}

export const NewCloseIcon = ({ color = neutral500 }: NewCloseIcon) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 4.25L3.75 11.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 4.25L11.25 11.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
