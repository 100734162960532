import styled from 'styled-components';

import { ArrowRight } from 'svg-icons';
import Table from 'shared-parts/components/table/regular-table/table';
import {
  TableCell,
  TableHeaderCell,
} from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS } from 'shared-parts/constants';

import { getTextTransform } from './helpers';

export const Header = styled.div`
  padding: 0 20px;
  background-color: ${COLORS.highlight};
  line-height: 54px;
  color: ${COLORS.white};
  position: sticky;
  top: 0;
`;

export const SectionHeader = styled.div`
  line-height: 50px;
  font-weight: bold;
  border-bottom: 3px solid ${COLORS.secondary};
  text-align: center;
  color: ${COLORS.primary};
`;

export const Section = styled.div`
  border-bottom: ${({ noUnderline }) => (noUnderline ? 'none' : '1px solid')};
  border-color: ${COLORS.neutral500};
  padding: 30px 20px;
  display: flex;
`;

export const Column = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
`;

export const Line = styled.div`
  display: flex;
`;

export const Cell = styled.span`
  display: flex;
  ${({ isColumn }) =>
    isColumn &&
    `
    flex-direction: column;
  `};
  margin-right: 10px;
`;

export const Text = styled.span`
  display: inline-block;
  color: ${COLORS.neutral800};
  margin-right: 10px;
`;

export const StyledArrowRight = styled(ArrowRight)`
  margin-left: 10px;
`;

export const Value = styled.span`
  display: inline-block;
  text-transform: ${({ isUpperCase, isCapitalize }) =>
    getTextTransform({ isUpperCase, isCapitalize })};
  font-weight: bold;
  color: ${COLORS.primary};
  min-width: 20px;
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop}px;
  `};
`;

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
`;

export const BottomContainer = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`;

export const StyledTable = styled(Table)`
  ${TableHeaderCell} {
    background-color: ${COLORS.neutral300};
    color: ${COLORS.primary};
    text-align: left;
    padding: 10px 20px;
    border-right: 1px solid ${COLORS.neutral500};

    :first-of-type {
      border-top-left-radius: 0;
    }

    :last-of-type {
      border-right: none;
      border-top-right-radius: 0;
    }
  }

  ${TableCell} {
    padding: 10px 20px;
    background-color: ${COLORS.white};

    :not(:first-of-type) {
      text-align: right;
    }
  }
`;
