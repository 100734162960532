import { FC } from 'react';

import { Spinner } from 'shared-parts/components/loader/loader.styled';
import DownloadArrowIcon from 'shared-parts/components/svg-icons/download-arrow-icon';
import { COLORS } from 'shared-parts/constants';

import {
  Button as StyledButton,
  Label,
  SpinnerWrapper,
  Toast as StyledToast,
  ToastText,
} from './csv-download.styled';

const { secondary } = COLORS;

export const Toast = () => {
  return (
    <StyledToast>
      <ToastText>Preparing File ...</ToastText>
      <SpinnerWrapper>
        <Spinner
          globalSpinnerSize={30}
          localSpinnerSize={25}
          isDone={false}
          isLocal
          withMessage={false}
        />
      </SpinnerWrapper>
    </StyledToast>
  );
};

export const Button: FC<
  React.PropsWithChildren<{
    handleClickButton: (payload: any) => void;
    loading: boolean;
    disabled?: boolean;
    label: string;
  }>
> = ({ handleClickButton, loading, disabled, label }) => (
  <StyledButton onClick={handleClickButton} disabled={loading || disabled}>
    <DownloadArrowIcon fill={secondary} />
    <Label> {label} </Label>
  </StyledButton>
);
