export const GET_MY_ACCOUNT_DETAILS = 'GET_MY_ACCOUNT_DETAILS';
export const GET_MY_ACCOUNT_DETAILS_SUCCESS = 'GET_MY_ACCOUNT_DETAILS_SUCCESS';
export const GET_MY_ACCOUNT_DETAILS_ERROR = 'GET_MY_ACCOUNT_DETAILS_ERROR';

export const GET_ACCOUNT_USERS = 'GET_ACCOUNT_USERS';
export const GET_ACCOUNT_USERS_SUCCESS = 'GET_ACCOUNT_USERS_SUCCESS';
export const GET_ACCOUNT_USERS_ERROR = 'GET_ACCOUNT_USERS_ERROR';

export const CREATE_ACCOUNT_USER = 'CREATE_ACCOUNT_USER';

export const UPDATE_ACCOUNT_USER = 'UPDATE_ACCOUNT_USER';

export const DELETE_ACCOUNT_USER = 'DELETE_ACCOUNT_USER';
export const DELETE_ACCOUNT_USER_ERROR = 'DELETE_ACCOUNT_USER_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const RESEND_EMAIL_CONFIRMATION = 'RESEND_EMAIL_CONFIRMATION';
export const RESEND_EMAIL_CONFIRMATION_ERROR = 'RESEND_EMAIL_CONFIRMATION_ERROR';
