export default {
  Root: () => '/',
  Login: () => '/login',
  CreatePassword: (emailToken = ':emailToken') => `/create-password/${emailToken}`,
  ResetPassword: (token = ':token') => `/reset-password/${token}`,
  ConfirmEmail: (emailToken = ':emailToken') => `/confirm-email/${emailToken}`,
  SignUp: () => '/sign-up',
  ForgotPassword: () => '/forgot-password',
  Onboarding: () => '/onboarding',
  Forbidden: () => '/forbidden',
  NotFound: () => '/not-found',
  Globacap: {
    Root: () => '/globacap',
    Verification: () => '/globacap/verification',
    Companies: () => '/globacap/issuers',
    NewCompany: () => '/globacap/issuers/new',
    SecuritiesOverview: () => '/globacap/securities-overview',
    SecurityHolders: () => '/globacap/security-holders',
    AllSecondaryTransactions: () => '/globacap/issuers/secondary-transactions',
    Company: (companyId = ':companyId') => `/globacap/issuers/${companyId}`,
    CompanyOverview: (companyId = ':companyId') => `/globacap/issuers/${companyId}/issuer-overview`,
    Dashboard: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/issuer-overview/dashboard`,
    CompanyLandingPage: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/issuer-overview/welcome-page`,
    DealManagement: (companyId = ':companyId') => `/globacap/issuers/${companyId}/deal-management`,
    BondsAndLoans: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/debt-administration/bonds-and-loans`,
    DebtTransactions: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/debt-administration/transactions`,
    DealManagementDeals: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/deal-management/deals`,
    CreateDeal: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/deal-management/deals/new`,
    CreateInvestmentFund: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/deal-management/deals/new-investment-fund`,
    CreateNomineePlacement: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/deal-management/deals/new-nominee-placement`,
    RegisterAdministration: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/register-administration`,
    EquityAdministration: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration`,
    DebtAdministration: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/debt-administration`,
    AlternativeAdministration: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/alternative-administration`,
    OtherVehicles: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/alternative-administration/fund-vehicles`,
    NewFund: () => `/globacap/issuers/:companyId/alternative-administration/fund-vehicles/new`,
    VehicleRegisterInsights: (companyId = ':companyId', assetUuid = ':assetUuid') =>
      `/globacap/issuers/${companyId}/alternative-administration/fund-vehicles/${assetUuid}/insights`,
    ConvertibleNotes: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/debt-administration/convertible-notes`,
    AddNewShareholder: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/shareholders/add-new-shareholder`,
    ShareholderRegistry: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/shareholder-registry`,
    AllTransactions: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/all-transactions`,
    SecondaryTransactions: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/secondary-transactions`,
    LRPageNavigation: (uuid = ':uuid', pageId = ':pageId?') =>
      `/globacap/liquidity-rounds/${uuid}/${pageId}`,
    ShareClass: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/share-class`,
    Shareholders: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/shareholders`,
    SharePlans: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/share-plans`,
    GrantHolders: (companyId = ':companyId', sharePlanUuid = ':sharePlanUuid') =>
      `/globacap/issuers/${companyId}/equity-administration/share-plans/${sharePlanUuid}/grant-holders`,
    ShareIncentivePlan: (companyId = ':companyId', planId = ':planId') =>
      `/globacap/issuers/${companyId}/equity-administration/share-plans/${planId}/share-incentive-plan`,
    Communication: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/communication/shareholder-communication`,
    Filings: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/equity-administration/filings`,
    CompanyUsers: (companyId = ':companyId') => `/globacap/issuers/${companyId}/users`,
    AddNewDeal: (companyId = ':companyId', modelUuid = ':modelUuid') =>
      `/globacap/issuers/${companyId}/${modelUuid}/add-a-new-deal`,
    Deals: () => '/globacap/deals-list',
    Investors: () => '/globacap/deals-list/investors',
    AllInvestors: () => '/globacap/all-investors',
    DealPage: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}`,
    DealSummary: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/overview`,
    DealList: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/investor-list`,
    InviteInvestors: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/investor-list/invite-investors`,
    InvestorInviteSettings: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/investor-invite-settings`,
    DealIndicationOfInterest: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/indication-of-interest`,
    DealOrders: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/orders`,
    FundSubscriptions: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/subscriptions`,
    DealSettlement: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/settlement`,
    DealSetup: (companyId = ':companyId', dealUuid = ':dealUuid', tab) =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/placement-setup${tab ? `?tab=${tab}` : ''}`,
    DealSettings: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/settings`,
    InvestmentFundSettings: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/investment-fund-settings`,
    NomineePlacementSettings: (companyId = ':companyId', uuid = ':uuid') =>
      `/globacap/issuers/${companyId}/deal/${uuid}/nominee-placement-settings`,
    ContractForm: (
      companyId = ':companyId',
      dealUuid = ':dealUuid',
      contractUuid = ':contractUuid?',
    ) =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/placement-setup/contract-form/${contractUuid}`,
    Deal: (
      permalink = ':permalink',
      navigationURL = '/:navigationURL?',
      subNavigationURL = '/:subNavigationURL?',
    ) => `/globacap/deals/${permalink}${navigationURL}${subNavigationURL}`,
    PlatformSettings: () => '/globacap/platform-settings',
    UsersPage: () => '/globacap/platform-settings/users',
    Payments: () => '/globacap/platform-settings/payments',
    AccountSecurity: () => '/globacap/platform-settings/account-security',
    MyDetails: () => '/globacap/platform-settings/my-details',
    Settings: () => '/globacap/settings',
    Account: (companyId = ':companyId') => `/globacap/issuers/${companyId}/account`,
    AccountUsers: (companyId = ':companyId') => `/globacap/issuers/${companyId}/account/users`,
    AccountCompanyDetails: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/account/issuer-details`,
    AccountIntegrations: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/account/integrations`,
    ObsoleteDealSetup: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/deal-setup`, // This is temporary route to handle redirects from emails on obsolete route
    ObsoleteDealList: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/globacap/issuers/${companyId}/deal/${dealUuid}/deal-list`, // This is temporary route to handle redirects from emails on obsolete route
    ChatCommunication: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/communication/chat`,
    LiquidityRoot: (companyId = ':companyId') => `/globacap/issuers/${companyId}/liquidity`,
    LiquidityOverview: () => `/globacap/liquidity/liquidity-overview`,
    LiquidityList: (companyId = ':companyId') =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds`,
    LiquidityRound: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}`,
    LiquidityRoundPageSetup: (companyId = ':companyId', roundUuid = ':roundUuid', activeTab) =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/page-setup${
        activeTab ? `?activeTab=${activeTab}` : ''
      }`,
    LiquidityRoundInvites: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/invites`,
    LiquidityRoundOrders: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/orders`,
    LiquidityRoundAddContract: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/contracts/new`,
    LiquidityRoundEditContract: (
      companyId = ':companyId',
      roundUuid = ':roundUuid',
      contractUuid = ':contractUuid',
    ) =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/contracts/${contractUuid}/settings`,
  },
  MultiCompany: {
    Root: () => '/multi-company',
    Companies: () => '/multi-company/issuers',
    SecuritiesOverview: () => '/multi-company/securites-overview',
    Company: (companyId = ':companyId') => `/multi-company/issuers/${companyId}`,
    CompanyOverview: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/issuer-overview`,
    Dashboard: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/issuer-overview/dashboard`,
    CompanyLandingPage: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/issuer-overview/welcome-page`,
    DealManagement: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/deal-management`,
    BondsAndLoans: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/debt-administration/bonds-and-loans`,
    DebtTransactions: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/debt-administration/transactions`,
    DealManagementDeals: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/deal-management/deals`,
    CreateDeal: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/deal-management/deals/new`,
    CreateInvestmentFund: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/deal-management/deals/new-investment-fund`,
    CreateNomineePlacement: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/deal-management/deals/new-nominee-placement`,
    RegisterAdministration: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/register-administration`,
    EquityAdministration: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration`,
    AlternativeAdministration: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/alternative-administration`,
    OtherVehicles: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/alternative-administration/fund-vehicles`,
    NewFund: () => `/multi-company/issuers/:companyId/alternative-administration/fund-vehicles/new`,
    VehicleRegisterInsights: (companyId = ':companyId', assetUuid = ':assetUuid') =>
      `/multi-company/issuers/${companyId}/alternative-administration/fund-vehicles/${assetUuid}/insights`,
    DebtAdministration: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/debt-administration`,
    ConvertibleNotes: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/debt-administration/convertible-notes`,
    AddNewShareholder: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/shareholders/add-new-shareholder`,
    ShareholderRegistry: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/shareholder-registry`,
    AllTransactions: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/all-transactions`,
    SecondaryTransactions: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/secondary-transactions`,
    Liquidity: () => '/multi-company/liquidity',
    Register: () => '/multi-company/register',
    LRPageNavigation: (uuid = ':uuid', pageId = ':pageId?') =>
      `/multi-company/liquidity-rounds/${uuid}/${pageId}`,
    ShareClass: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/share-class`,
    Shareholders: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/shareholders`,
    SharePlans: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/share-plans`,
    GrantHolders: (companyId = ':companyId', sharePlanUuid = ':sharePlanUuid') =>
      `/multi-company/issuers/${companyId}/equity-administration/share-plans/${sharePlanUuid}/grant-holders`,
    ShareIncentivePlan: (companyId = ':companyId', planId = ':planId') =>
      `/multi-company/issuers/${companyId}/equity-administration/share-plans/${planId}/share-incentive-plan`,
    Communication: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/communication/shareholder-communication`,
    Filings: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/equity-administration/filings`,
    CompanyUsers: (companyId = ':companyId') => `/multi-company/issuers/${companyId}/users`,
    AddNewDeal: (companyId = ':companyId', modelUuid = ':modelUuid') =>
      `/multi-company/issuers/${companyId}/${modelUuid}/add-a-new-deal`,
    Deals: () => '/multi-company/deals-list',
    Investors: () => '/multi-company/deals-list/investors',
    AllInvestors: () => '/multi-company/all-investors',
    DealPage: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}`,
    DealSummary: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/overview`,
    DealList: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/investor-list`,
    InviteInvestors: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/investor-list/invite-investors`,
    InvestorInviteSettings: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/investor-invite-settings`,
    DealIndicationOfInterest: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/indication-of-interest`,
    DealOrders: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/orders`,
    FundSubscriptions: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/subscriptions`,
    DealSettlement: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/settlement`,
    DealSetup: (companyId = ':companyId', dealUuid = ':dealUuid', tab) =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/placement-setup${
        tab ? `?tab=${tab}` : ''
      }`,
    DealSettings: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/settings`,
    InvestmentFundSettings: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/investment-fund-settings`,
    NomineePlacementSettings: (companyId = ':companyId', uuid = ':uuid') =>
      `/multi-company/issuers/${companyId}/deal/${uuid}/nominee-placement-settings`,
    ContractForm: (
      companyId = ':companyId',
      dealUuid = ':dealUuid',
      contractUuid = ':contractUuid?',
    ) =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/placement-setup/contract-form/${contractUuid}`,
    Deal: (
      permalink = ':permalink',
      navigationURL = '/:navigationURL?',
      subNavigationURL = '/:subNavigationURL?',
    ) => `/multi-company/deals/${permalink}${navigationURL}${subNavigationURL}`,
    PlatformSettings: () => '/multi-company/platform-settings',
    UsersPage: () => '/multi-company/platform-settings/users',
    MyDetails: () => '/multi-company/platform-settings/my-details',
    AccountSecurity: () => '/multi-company/platform-settings/account-security',
    Settings: () => '/multi-company/settings',
    Account: (companyId = ':companyId') => `/multi-company/issuers/${companyId}/account`,
    AccountUsers: (companyId = ':companyId') => `/multi-company/issuers/${companyId}/account/users`,
    AccountCompanyDetails: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/account/issuer-details`,
    AccountIntegrations: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/account/integrations`,
    ObsoleteDealSetup: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/deal-setup`, // This is temporary route to handle redirects from emails on obsolete route
    ObsoleteDealList: (companyId = ':companyId', dealUuid = ':dealUuid') =>
      `/multi-company/issuers/${companyId}/deal/${dealUuid}/deal-list`, // This is temporary route to handle redirects from emails on obsolete route
    ChatCommunication: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/communication/chat`,
    LiquidityRoot: (companyId = ':companyId') => `/multi-company/issuers/${companyId}/liquidity`,
    LiquidityList: (companyId = ':companyId') =>
      `/multi-company/issuers/${companyId}/liquidity/liquidity-rounds`,
    LiquidityRound: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/multi-company/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}`,
    LiquidityRoundPageSetup: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/multi-company/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/page-setup`,
    LiquidityRoundInvites: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/multi-company/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/invites`,
    LiquidityRoundOrders: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/multi-company/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/orders`,
    LiquidityRoundAddContract: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/multi-company/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/contracts/new`,
    LiquidityRoundEditContract: (
      companyId = ':companyId',
      roundUuid = ':roundUuid',
      contractUuid = ':contractUuid',
    ) =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/contracts/${contractUuid}/settings`,
  },
  Admin: {
    Root: () => '/issuer-admin',
    Overview: () => '/issuer-admin/overview',
    Settings: () => '/issuer-admin/settings',
    AdminCommunication: () => `/issuer-admin/communication`,
    AdminChatCommunication: () => `/issuer-admin/communication/chat`,
    SecuritiesOverview: () => '/issuer-admin/securities-overview',
    Companies: () => '/issuer-admin/issuers', // not a route in itself
    AddNewDeal: (companyId = ':companyId', modelUuid = ':modelUuid') =>
      `/issuer-admin/issuers/${companyId}/${modelUuid}/add-a-new-deal`,
    DealManagement: () => '/issuer-admin/deal-management',
    DealManagementDeals: () => '/issuer-admin/deal-management/deals-list',
    CreateDeal: () => `/issuer-admin/deal-management/deals-list/new`,
    CreateInvestmentFund: () => `/issuer-admin/deal-management/deals-list/new-investment-fund`,
    CreateNomineePlacement: () => `/issuer-admin/deal-management/deals-list/new-nominee-placement`,
    DealManagementInvestors: () => '/issuer-admin/deal-management/deals-list/investors',
    DealPage: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}`,
    DealSummary: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/overview`,
    DealList: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/investor-list`,
    AllInvestors: () => '/issuer-admin/all-investors',
    InviteInvestors: (dealUuid = ':dealUuid') =>
      `/issuer-admin/deal/${dealUuid}/investor-list/invite-investors`,
    InvestorInviteSettings: (dealUuid = ':dealUuid') =>
      `/issuer-admin/deal/${dealUuid}/investor-invite-settings`,
    DealIndicationOfInterest: (dealUuid = ':dealUuid') =>
      `/issuer-admin/deal/${dealUuid}/indication-of-interest`,
    DealOrders: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/orders`,
    FundSubscriptions: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/subscriptions`,
    DealSettlement: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/settlement`,
    DealSetup: (dealUuid = ':dealUuid', tab) =>
      `/issuer-admin/deal/${dealUuid}/placement-setup${tab ? `?tab=${tab}` : ''}`,
    DealSettings: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/settings`,
    InvestmentFundSettings: (dealUuid = ':dealUuid') =>
      `/issuer-admin/deal/${dealUuid}/investment-fund-settings`,
    NomineePlacementSettings: (uuid = ':uuid') =>
      `/issuer-admin/deal/${uuid}/nominee-placement-settings`,
    ContractForm: (dealUuid = ':dealUuid', contractUuid = ':contractUuid?') =>
      `/issuer-admin/deal/${dealUuid}/placement-setup/contract-form/${contractUuid}`,
    External: {
      DealPage: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}`,
      DealSummary: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/overview`,
      DealList: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/investor-list`,
      InviteInvestors: (companyUuid = ':companyId', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/investor-list/invite-investors`,
      InvestorInviteSettings: (companyUuid = ':companyId', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/investor-invite-settings`,
      DealIndicationOfInterest: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/indication-of-interest`,
      DealOrders: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/orders`,
      FundSubscriptions: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/subscriptions`,
      DealSettlement: (companyUuid = ':companyUuid', dealUuid = ':dealUuid') =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/settlement`,
      DealSetup: (companyUuid = ':companyUuid', dealUuid = ':dealUuid', tab) =>
        `/issuer-admin/issuers/${companyUuid}/deal/${dealUuid}/placement-setup${
          tab ? `?tab=${tab}` : ''
        }`,
    },
    Deal: (
      permalink = ':permalink',
      navigationURL = '/:navigationURL?',
      subNavigationURL = '/:subNavigationURL?',
    ) => `/issuer-admin/deals/${permalink}${navigationURL}${subNavigationURL}`,
    LRPageNavigation: (uuid = ':uuid', pageId = ':pageId?') =>
      `/issuer-admin/liquidity-rounds/${uuid}/${pageId}`,
    PlatformSettings: () => '/issuer-admin/platform-settings',
    Company: (companyId = ':companyId') => `/issuer-admin/issuers/${companyId}`,
    CompanyOverview: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/issuer-overview`,
    Dashboard: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/issuer-overview/dashboard`,
    CompanyLandingPage: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/issuer-overview/welcome-page`,
    RegisterAdministration: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/register-administration`,
    EquityAdministration: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration`,
    BondsAndLoans: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/debt-administration/bonds-and-loans`,
    DebtTransactions: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/debt-administration/transactions`,
    AlternativeAdministration: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/alternative-administration`,
    OtherVehicles: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/alternative-administration/fund-vehicles`,
    NewFund: () => `/issuer-admin/issuers/:companyId/alternative-administration/fund-vehicles/new`,
    VehicleRegisterInsights: (companyId = ':companyId', assetUuid = ':assetUuid') =>
      `/issuer-admin/issuers/${companyId}/alternative-administration/fund-vehicles/${assetUuid}/insights`,
    DebtAdministration: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/debt-administration`,
    ConvertibleNotes: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/debt-administration/convertible-notes`,
    ShareholderRegistry: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/shareholder-registry`,
    AllTransactions: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/all-transactions`,
    SecondaryTransactions: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/secondary-transactions`,
    ShareClass: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/share-class`,
    Shareholders: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/shareholders`,
    SharePlans: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/share-plans`,
    GrantHolders: (companyId = ':companyId', sharePlanUuid = ':sharePlanUuid') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/share-plans/${sharePlanUuid}/grant-holders`,
    ShareIncentivePlan: (companyId = ':companyId', planId = ':planId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/share-plans/${planId}/share-incentive-plan`,
    Communication: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/communication/shareholder-communication`,
    Filings: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/equity-administration/filings`,
    Account: () => '/issuer-admin/account',
    AccountUsers: () => '/issuer-admin/account/users',
    AccountMyDetails: () => '/issuer-admin/account/my-details',
    AccountSecurity: () => '/issuer-admin/account/security',
    AccountLandingPage: () => '/issuer-admin/account/landing-page',
    AccountCompanyDetails: () => '/issuer-admin/account/issuer-details',
    AccountIntegrations: () => `/issuer-admin/account/integrations`,
    ObsoleteDealSetup: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/deal-setup`, // This is temporary route to handle redirects from emails on obsolete route
    ObsoleteDealList: (dealUuid = ':dealUuid') => `/issuer-admin/deal/${dealUuid}/deal-list`, // This is temporary route to handle redirects from emails on obsolete route
    LiquidityRoot: (companyId = ':companyId') => `/issuer-admin/issuers/${companyId}/liquidity`,
    LiquidityList: (companyId = ':companyId') =>
      `/issuer-admin/issuers/${companyId}/liquidity/liquidity-rounds`,
    LiquidityRound: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/issuer-admin/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}`,
    LiquidityRoundPageSetup: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/issuer-admin/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/page-setup`,
    LiquidityRoundInvites: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/issuer-admin/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/invites`,
    LiquidityRoundOrders: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/issuer-admin/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/orders`,
    LiquidityRoundAddContract: (companyId = ':companyId', roundUuid = ':roundUuid') =>
      `/issuer-admin/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/contracts/new`,
    LiquidityRoundEditContract: (
      companyId = ':companyId',
      roundUuid = ':roundUuid',
      contractUuid = ':contractUuid',
    ) =>
      `/globacap/issuers/${companyId}/liquidity/liquidity-rounds/${roundUuid}/contracts/${contractUuid}/settings`,
  },
};
