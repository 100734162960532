import { createSelector } from 'reselect';

import { useEnvVar } from '@frontend/config';

import { ACTIONS } from 'modules/company/deal-management/deal-page/orders/constants';
import { BE_STATUSES, REVIEW_STATUSES } from 'shared-parts/constants/order-statuses';

import { getUser } from './authentication';

export const getDealsPerCompany = state => state.dealsPerCompany;
export const getDealList = state => state.dealList;
export const getDealListMeta = createSelector(getDealList, ({ data: { meta } }) => meta);
export const getDealSummary = state => state.dealSummary;
export const getDealInfo = state => state.dealInfo;
export const getDealData = state => state.dealData;
export const getDealDocumentsData = state => state.dealDocumentsData;
export const getIssuance = state => state.issuance;
export const getPage = state => state.page;
export const getComponentAddingState = state => state.componentAddingState;

export const getInvestorDocumentsState = state => state.investorDocuments;
export const getInvestorDocumentsItems = createSelector(
  getInvestorDocumentsState,
  ({ data: { items } }) => items,
);
export const getInvestorDocumentsUploading = createSelector(
  getInvestorDocumentsState,
  ({ isUploading }) => isUploading,
);
export const getInvestorDocumentsLoading = createSelector(
  getInvestorDocumentsState,
  ({ loading }) => loading,
);
export const getInvestorDocumentsErrors = createSelector(
  getInvestorDocumentsState,
  ({ errors }) => errors,
);
export const getInvestorDocumentsPagination = createSelector(
  getInvestorDocumentsState,
  ({
    data: {
      meta: { pagination },
    },
  }) => pagination || {},
);

export const getDeal = state => state.deal;

export const getDealDetails = createSelector(getDealData, ({ data }) => data.dealDetail);

export const getDealPageHeaderUpdate = state => state.updateDealPageHeader;
export const getDealLoadingFlag = createSelector(
  getDealData,
  getDealPageHeaderUpdate,
  (deal, headerUpdate) => deal.loading || headerUpdate.loading,
);
export const getDealHeaderData = createSelector(
  getDealData,
  ({ data: { uuid, company, name, images } }) => ({
    uuid,
    name,
    companyUuid: company?.uuid,
    logo: images?.logo?.cover,
    background: images?.background?.cover,
  }),
);

export const getDealDataUpdate = state => state.updateDealData;

export const getDealTileLoadingFlag = createSelector(
  getDealData,
  getDealDataUpdate,
  (deal, tileUpdate) => deal.loading || tileUpdate.loading,
);

export const getLastCreatedComponent = state => state.lastCreatedComponent;
export const getTeamMembers = state => state.teamMembers;

export const getDealCompanyUuid = createSelector(getDealSummary, ({ data }) => data.companyUuid);

export const getDealInfoData = createSelector(
  getDealInfo,
  getDealSummary,
  ({ data }, { data: { currency } }) => ({ ...data, currency }),
);
export const getDealInfoLocationsData = createSelector(
  getDealInfo,
  ({ data: { investorLocations } }) =>
    investorLocations ? [investorLocations.invited, investorLocations.ordered] : [[], []],
);
export const getDealInfoLoadingFlag = createSelector(getDealInfo, ({ loading }) => loading);
export const getDealInfoError = createSelector(getDealInfo, ({ error }) => error);

export const getDealInviteEmailTemplate = state => state.dealInviteEmailTemplate;

export const getInvestorInfo = state => state.investorInfo;
export const getInvestorInfoData = createSelector(getInvestorInfo, ({ data }) => data);
export const getInvestorInfoLoadingFlag = createSelector(getInvestorInfo, ({ loading }) => loading);
export const getInvestorInfoErrorFlag = createSelector(getInvestorInfo, ({ error }) =>
  Boolean(error),
);
export const getInvestorInteractions = createSelector(
  getInvestorInfoData,
  ({ openedInvite, placedOrder, viewedDeal, lastInviteSent }) => ({
    openedInvite,
    placedOrder,
    viewedDeal,
    lastInviteSent,
  }),
);
export const getInvestorViewsStats = createSelector(
  getInvestorInfoData,
  ({ pageViewsStats }) => pageViewsStats || [],
);

export const getResendInvestorInvite = state => state.resendInvestorInvite;
export const getResendInvestorInviteFlag = createSelector(
  getResendInvestorInvite,
  ({ loading }) => loading,
);

export const getDealPageURL = state => state.dealPageUrl;

export const getDealOrdersState = state => state.dealOrders;
export const getDealOrdersItems = createSelector(
  getDealOrdersState,
  ({ data: { items } }) => items,
);
export const getDealOrdersLoading = createSelector(getDealOrdersState, ({ loading }) => loading);
export const getDealOrdersError = createSelector(getDealOrdersState, ({ error }) => error);
export const getDealOrdersMeta = createSelector(getDealOrdersState, ({ data: { meta } }) => meta);

export const getDealOrderDetails = state => state.dealOrderDetails;
export const getDealOrderDetailsActions = createSelector(
  getDealOrderDetails,
  getUser,
  ({ data: { status, reviewStatus } }, user) => {
    const globacapClientMoneyServicesEnabled = useEnvVar('USE_GLOBACAP_CLIENT_MONEY_SERVICES');

    if (status === BE_STATUSES.PLACED && reviewStatus === REVIEW_STATUSES.APPROVED) {
      if (!globacapClientMoneyServicesEnabled && user.operationalAdmin)
        return [ACTIONS.MARK_AS_FUNDS_RECEIVED, ACTIONS.CANCEL];
      return [ACTIONS.CANCEL];
    }
    if (status === BE_STATUSES.PLACED) {
      return [ACTIONS.APPROVE, ACTIONS.ADJUST, ACTIONS.ACCEPT_ADJUSTMENT, ACTIONS.CANCEL];
    }
    return [];
  },
);

export const getDealOrderHistory = state => state.dealOrderHistory;

export const getDealCompleteAvailability = createSelector(
  getDealSummary,
  ({ data: { status, ordersMustBeFilledAt } }) =>
    status !== 'completed' && new Date() > new Date(ordersMustBeFilledAt),
);

export const getDealSettlementsState = state => state.dealSettlements;
export const getDealSettlementsItems = createSelector(
  getDealSettlementsState,
  ({ data: { items } }) => items,
);
export const getDealSettlementsLoading = createSelector(
  getDealSettlementsState,
  ({ loading }) => loading,
);
export const getDealSettlementsErrors = createSelector(
  getDealSettlementsState,
  ({ errors }) => errors,
);
export const getDealSettlementsMeta = createSelector(
  getDealSettlementsState,
  ({ data: { meta } }) => meta,
);
