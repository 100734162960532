import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getMyAccountDetails() {
  try {
    const company = yield select(selectors.getCompany);
    const { uuid: userUuid } = yield select(selectors.getUser);
    const { data } = yield call(request, API.AccountDetails(company.data.uuid, userUuid));

    yield put(actions.getMyAccountDetailsSuccess(data));
  } catch (e) {
    yield put(actions.getMyAccountDetailsError(e));
  }
}

function* getMyAccountDetailsWatcher() {
  yield takeEvery(constants.GET_MY_ACCOUNT_DETAILS, getMyAccountDetails);
}

function* getAccountUsers({ companyUuid }) {
  try {
    const { data } = yield call(request, API.AccountUsers(companyUuid));

    yield put(actions.getAccountUsersSuccess(data.items));
  } catch (e) {
    yield put(actions.getAccountUsersError(e));
  }
}

function* getAccountUsersWatcher() {
  yield takeEvery(constants.GET_ACCOUNT_USERS, getAccountUsers);
}

function* createAccountUser({ companyUuid, data, showUserAddedModal, form }) {
  try {
    const user = yield call(request, API.CreateAccountUser(companyUuid), 'POST', data);

    yield put(actions.getAccountUsers(companyUuid));
    yield showUserAddedModal(user.data.isNewlyCreated);
    yield put(actions.hideSideForm());
  } catch ({ response }) {
    if (response.details) {
      yield form.setErrors(response.details);
      yield form.setSubmitting(false);
    }
    yield put(actions.hideModal());
  }
}

function* createAccountUserWatcher() {
  yield takeEvery(constants.CREATE_ACCOUNT_USER, createAccountUser);
}

function* updateAccountUser({ userUuid, data, form, onSuccess, showEmailModal }) {
  try {
    const company = yield select(selectors.getCompany);
    const { data: userData } = yield call(
      request,
      API.UpdateAccountUser(company.data.uuid, userUuid),
      'PUT',
      data,
    );

    yield onSuccess(userData);
    yield put(actions.hideSideForm());
    if (showEmailModal) yield showEmailModal();
  } catch ({ response }) {
    if (response.details) {
      yield form.setErrors(response.details);
      yield form.setSubmitting(false);
    }
  }
}

function* updateAccountUserWatcher() {
  yield takeEvery(constants.UPDATE_ACCOUNT_USER, updateAccountUser);
}

function* deleteAccountUser({ companyUuid, userUuid }) {
  try {
    yield call(request, API.DeleteAccountUser(companyUuid, userUuid), 'DELETE');

    yield put(actions.getAccountUsers(companyUuid));
    yield put(actions.hideSideForm());
    yield put(actions.hideModal());
  } catch (e) {
    yield put(actions.deleteAccountUserError(e));
  }
}

function* deleteAccountUserWatcher() {
  yield takeEvery(constants.DELETE_ACCOUNT_USER, deleteAccountUser);
}

function* changePassword({ data, showPasswordUpdatedModal, form }) {
  try {
    yield call(request, API.ChangePassword(), 'PUT', data);

    yield put(actions.hideSideForm());
    yield showPasswordUpdatedModal();
  } catch ({ response }) {
    if (response.details) {
      yield form.setErrors(response.details);
      yield form.setSubmitting(false);
    }
  }
}

function* changePasswordWatcher() {
  yield takeEvery(constants.CHANGE_PASSWORD, changePassword);
}

function* resendEmailConfirmation({ companyUuid, userUuid }) {
  try {
    yield call(request, API.ResendEmail(companyUuid, userUuid), 'POST');

    const accountUsers = yield select(selectors.getAccountUsers);
    const updatedAccountUsers = accountUsers.data.map(item =>
      item.uuid === userUuid ? { ...item, isEmailConfirmationSent: true } : item,
    );

    yield put(actions.getAccountUsersSuccess(updatedAccountUsers));
  } catch (e) {
    yield put(actions.resendEmailConfirmationError(e));
  }
}

function* resendEmailConfirmationWatcher() {
  yield takeEvery(constants.RESEND_EMAIL_CONFIRMATION, resendEmailConfirmation);
}

export {
  getAccountUsers,
  getAccountUsersWatcher,
  createAccountUser,
  createAccountUserWatcher,
  deleteAccountUser,
  deleteAccountUserWatcher,
  updateAccountUser,
  updateAccountUserWatcher,
  getMyAccountDetails,
  getMyAccountDetailsWatcher,
  changePassword,
  changePasswordWatcher,
  resendEmailConfirmation,
  resendEmailConfirmationWatcher,
};
