import validation from 'shared-parts/constants/validation';

import { convertToISO8601Date } from './convert-time';
import { toDate, toUTCDate } from './date-time-format';

const isBigger = maxNumber => value => (value ? Number(value) <= maxNumber : true);

/*
  Do not use 'isValidFormat' helper to validate data.
  This is internal helper that is extended with additional functionality.
  Please check 'checkDate' helper for more information.
*/
/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const isValidFormat = (date, isTimeIncluded) =>
  isTimeIncluded ? validation.dateWithTimeRegex.test(date) : validation.dateRegex.test(date);

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const prohibitFutureDate = function (date, { isTimeIncluded } = {}) {
  if (!date) return true;
  if (!isValidFormat(date, isTimeIncluded)) return false;

  const dateToProcess = isTimeIncluded ? date.split(' ')[0] : date;
  const dateFormattedValue = toDate(dateToProcess);

  if (dateFormattedValue instanceof Date && !isNaN(dateFormattedValue)) {
    return dateFormattedValue <= new Date();
  }

  return false;
};

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const prohibitPastDate = function (
  date,
  options = { offsetHours: 0, isTimeIncluded: false, offset: undefined },
) {
  if (!isValidFormat(date, options.isTimeIncluded)) return false;

  const dateFormattedValue = options.isTimeIncluded
    ? new Date(convertToISO8601Date(date, { offset: options.offset }))
    : toDate(date);

  const today = new Date();

  if (options.offsetHours) {
    today.setHours(today.getHours() + options.offsetHours);
  }

  if (dateFormattedValue instanceof Date && !isNaN(dateFormattedValue)) {
    return dateFormattedValue > today;
  }

  return false;
};

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const isAfterBaseDate = function (date, baseUTCDate, { isTimeIncluded } = {}) {
  if (!isValidFormat(date, isTimeIncluded) || !baseUTCDate) return false;

  const dateFormattedValue = isTimeIncluded
    ? new Date(convertToISO8601Date(date))
    : toUTCDate(date).setHours(0, 0, 0, 0);

  const baseDateFormattedValue = isTimeIncluded
    ? new Date(baseUTCDate)
    : new Date(baseUTCDate).setHours(0, 0, 0, 0);

  if (!isNaN(dateFormattedValue) && !isNaN(baseDateFormattedValue)) {
    return dateFormattedValue >= baseDateFormattedValue;
  }

  return false;
};

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const isBeforeBaseDate = function (date, baseDate, options = {}) {
  if (!date || !isValidFormat(date, options.isTimeIncluded) || !baseDate) return false;

  const dateFormattedValue = options.isTimeIncluded
    ? new Date(convertToISO8601Date(date))
    : toDate(date);

  const baseDateFormattedValue = new Date(baseDate);

  if (!isNaN(dateFormattedValue) && !isNaN(baseDateFormattedValue)) {
    return dateFormattedValue < baseDateFormattedValue;
  }
  return false;
};

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const checkDate = (date, { isTimeIncluded } = {}) => {
  if (!date) return true;
  if (!isValidFormat(date, isTimeIncluded)) return false;

  const [fullDate] = date.split(' ');
  const [day, month, year] = fullDate.split('-');

  const numberOfDaysInCurrentMonth = new Date(year, month, 0).getDate();

  const isDayExistingInCurrentMonth = day <= numberOfDaysInCurrentMonth;

  return isValidFormat && isDayExistingInCurrentMonth;
};

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const checkMinMaxDate = function (
  selectedDate,
  date,
  options = { offsetHours: 0, isTimeIncluded: false, comparisonType: 'min', offset: undefined },
) {
  const isSelectedDateValid = isValidFormat(selectedDate, options.isTimeIncluded);
  const isDateValid = isValidFormat(date, options.isTimeIncluded);
  if (!isSelectedDateValid || !isDateValid) return false;

  let dateFormattedSelectedDate = null;
  let dateFormattedDate = null;

  if (options.isTimeIncluded) {
    dateFormattedSelectedDate = new Date(
      convertToISO8601Date(selectedDate, { offset: options.offset }),
    );
    dateFormattedDate = new Date(convertToISO8601Date(date, { offset: options.offset }));
  } else {
    dateFormattedSelectedDate = toDate(selectedDate);
    dateFormattedDate = toDate(date);
  }

  if (dateFormattedSelectedDate instanceof Date && !isNaN(dateFormattedSelectedDate)) {
    return options.comparisonType === 'min'
      ? dateFormattedSelectedDate > dateFormattedDate
      : dateFormattedSelectedDate < dateFormattedDate;
  }

  return false;
};

/**
 * @deprecated use dayjs and iso strings from here. please avoid this helper suite from now.
 * This is to aid the internationali(s|z)ation of the app.
 */
const checkDateRange = (dateRange, separator = 'to') => {
  if (dateRange) {
    const dates = dateRange.split(separator);

    return dateRange.includes(separator) && dates.map(d => d.trim()).every(checkDate);
  }

  return true;
};

function matchPassword(confirmPassword) {
  const { password } = this.parent;

  if (!password || !confirmPassword) return false;

  if (password.normalize) {
    return password.normalize() === confirmPassword.normalize();
  }

  return password === confirmPassword;
}

const validateLoqateAddress = (values, fields = ['line1', 'city', 'postCode', 'countryCode']) => {
  const addressIsInvalid = fields.some(fieldName => !values[fieldName]);

  return !addressIsInvalid;
};

const isUnique = (value, items, key) => items.every(item => item[key] !== value);

const validateName = name => {
  return name ? !validation.specialCharactersRegex.test(name[0]) : true;
};

export default {
  isBigger,
  prohibitFutureDate,
  prohibitPastDate,
  checkDate,
  checkDateRange,
  checkMinMaxDate,
  matchPassword,
  validateLoqateAddress,
  isAfterBaseDate,
  isBeforeBaseDate,
  isUnique,
  validateName,
};
