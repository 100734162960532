import { forwardRef, useLayoutEffect, useRef, useState } from 'react';

import { NanoPop } from 'nanopop';
import PropTypes from 'prop-types';

import { COLORS } from 'shared-parts/constants';

import getUpdatedArrowPosition from './helpers';
import { AppearingPart, Arrow, TooltipWrapper } from './tooltip.styled';

const { white } = COLORS;

const Content = forwardRef(
  ({ content: Component, changeTooltipVisibility, arrowPosition, arrowColor }, reference) => (
    <AppearingPart ref={reference}>
      <Arrow arrowPosition={arrowPosition} arrowColor={arrowColor} />
      <Component changeTooltipVisibility={changeTooltipVisibility} />
    </AppearingPart>
  ),
);

const Tooltip = ({
  className,
  children: Attachable,
  initiallyVisible,
  content,
  position,
  margin,
  arrowColor,
  autohide,
}) => {
  const [visible, changeTooltipVisibility] = useState(initiallyVisible);
  const [arrowPosition, setArrowPosition] = useState({});
  const contentRef = useRef();
  const attachableRef = useRef();

  const updateArrowPosition = nanopopPosition => {
    const nextArrowPosition = getUpdatedArrowPosition(contentRef, nanopopPosition);

    setArrowPosition(nextArrowPosition);
  };

  const createNanopop = () => {
    const nanopop = new NanoPop(attachableRef.current, contentRef.current, {
      position,
      margin,
      container: document.getElementById('content').getBoundingClientRect(),
    });

    const nanopopPosition = nanopop.update();
    updateArrowPosition(nanopopPosition);
  };

  useLayoutEffect(() => {
    if (visible) {
      createNanopop();
      window.addEventListener('scroll', createNanopop);

      return () => window.removeEventListener('scroll', createNanopop);
    }
  }, [visible]);

  const hideTooltip = () => {
    if (visible) {
      changeTooltipVisibility(false);
    }
  };

  const showTooltip = () => {
    if (!visible) {
      changeTooltipVisibility(true);
    }
  };

  const toggleTooltipVisibility = () => {
    changeTooltipVisibility(previous => !previous);
  };

  const disableAutohide = !autohide && {
    onClick: toggleTooltipVisibility,
    onMouseEnter: showTooltip,
    onFocus: showTooltip,
    onBlur: hideTooltip,
    onPointerLeave: hideTooltip,
  };

  return (
    <TooltipWrapper className={className} onClickOutside={hideTooltip} {...disableAutohide}>
      <div ref={attachableRef}>
        <Attachable
          changeTooltipVisibility={changeTooltipVisibility}
          toggleTooltipVisibility={toggleTooltipVisibility}
        />
      </div>
      {visible && (
        <Content
          ref={contentRef}
          arrowColor={arrowColor}
          arrowPosition={arrowPosition}
          content={content}
          changeTooltipVisibility={changeTooltipVisibility}
        />
      )}
    </TooltipWrapper>
  );
};

Tooltip.defaultProps = {
  className: '',
  position: 'bottom-middle',
  initiallyVisible: false,
  margin: 15,
  arrowColor: white,
  autohide: true,
};

Tooltip.propTypes = {
  content: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  position: PropTypes.string,
  initiallyVisible: PropTypes.bool,
  className: PropTypes.string,
  margin: PropTypes.number,
  arrowColor: PropTypes.string,
  autohide: PropTypes.bool,
};

export default Tooltip;
