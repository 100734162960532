import type { FC } from 'react';

import type { DataLabelPropTypes } from '../types';

import { StyledStatus } from './data-label.styled';

const DataLabel: FC<React.PropsWithChildren<DataLabelPropTypes>> = ({ name, status }) => (
  <div>
    <strong>{name}:</strong>
    &nbsp;
    <StyledStatus value={status}>{status ? 'Yes' : 'No'}</StyledStatus>
  </div>
);

export default DataLabel;
