import { useEnvVar } from '@frontend/config';

import { getDealAlias } from 'helpers/get-deal-alias';

const isAgentDealsTableEnabled = useEnvVar('ENABLED_AGENT_DEALS_TABLE');

export const TABLE_HEADER = {
  Operations: () => [
    ...(isAgentDealsTableEnabled ? [{ name: 'Agent' }] : []),
    { name: 'Issuer' },
    { name: `${getDealAlias()} Name` },
    { name: `${getDealAlias()} Type` },
    { name: 'Headline Target Amount' },
    { name: 'Maximum Subscription Amount' },
    { name: 'Value Funded' },
    { name: 'Launch Date' },
    { name: 'Close Date' },
    { name: 'Status' },
    { name: '' },
  ],
  MultiCompany: () => [
    ...(isAgentDealsTableEnabled ? [{ name: 'Agent' }] : []),
    { name: 'Issuer' },
    { name: `${getDealAlias()} Name` },
    { name: `${getDealAlias()} Type` },
    { name: 'Headline Target Amount' },
    { name: 'Maximum Subscription Amount' },
    { name: 'Value Funded' },
    { name: 'Launch Date' },
    { name: 'Close Date' },
    { name: 'Status' },
    { name: '' },
  ],
  Company: () => [
    ...(isAgentDealsTableEnabled ? [{ name: 'Issuer' }] : []),
    { name: `${getDealAlias()} Name` },
    { name: `${getDealAlias()} Type` },
    { name: 'Headline Target Amount' },
    { name: 'Maximum Subscription Amount' },
    { name: 'Value Funded' },
    { name: 'Launch Date' },
    { name: 'Close Date' },
    { name: 'Status' },
    { name: '' },
  ],
};
