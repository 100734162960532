import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './share-plan-bonus.constants';
import initialState, { SharePlanBonusStateType } from './share-plan-bonus.state';

export const sharePlanBonusReducer = (
  state = initialState,
  action: AnyAction,
): SharePlanBonusStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.uploadBonus.REQUEST:
        draft.uploadBonus.loading = true;
        draft.uploadBonus.error = undefined;
        draft.uploadBonus.uploaded = false;
        break;
      case constants.uploadBonus.FAILURE:
        draft.uploadBonus.error = action.error;
        draft.uploadBonus.loading = false;
        break;
      case constants.uploadBonus.SUCCESS:
        draft.uploadBonus.loading = false;
        draft.uploadBonus.uploaded = true;
        break;
      case constants.resetUploadBonus:
        draft.uploadBonus.loading = false;
        draft.uploadBonus.error = undefined;
        draft.uploadBonus.uploaded = false;
        break;
      default:
        break;
    }
  });
