import { createGlobalStyle } from 'styled-components';

import { FONTS } from 'shared-parts/constants';

export default createGlobalStyle`
  html, body {
    font-family: ${FONTS.mainFont};
  }

  h1 {
    font-family: ${FONTS.oswaldFont};
  }

  h3 {
    font-family: ${FONTS.mainFont};
  }
`;
