const ClockIcon = (width = 19, height = 19, fillColor = '#fff') => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <clipPath id="a">
      <path
        fill={fillColor}
        d="M13.87-6.859l-3.37-2v-4.53a.717.717,0,0,0-.72-.72H9.72a.717.717,0,0,0-.72.72v4.72a.99.99,0,0,0,.49.86l3.65,2.19a.713.713,0,0,0,.98-.24.72.72,0,0,0-.25-1ZM19.18-17.1l-3.08-2.56a1,1,0,0,0-1.41.13,1.01,1.01,0,0,0,.13,1.41l3.07,2.56a1,1,0,0,0,1.41-.13,1,1,0,0,0-.12-1.41ZM2.1-15.559l3.07-2.56a.992.992,0,0,0,.13-1.41.988.988,0,0,0-1.4-.13L.82-17.1a1,1,0,0,0-.12,1.41A.988.988,0,0,0,2.1-15.559Zm7.9-2.55a9,9,0,0,0-9,9,9,9,0,0,0,9,9,9,9,0,0,0,9-9A9,9,0,0,0,10-18.109Zm0,16a7.008,7.008,0,0,1-7-7,7.008,7.008,0,0,1,7-7,7.008,7.008,0,0,1,7,7A7.008,7.008,0,0,1,10-2.109Z"
      />
    </clipPath>
    <g style={{ clipPath: 'url(#a)' }} transform="translate(-0.464 19.891)">
      <path fill={fillColor} d="M-4.536-24.891H24.536V4.891H-4.536Z" />
    </g>
  </svg>
);

export default ClockIcon;
