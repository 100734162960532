import styled from 'styled-components';

import FormikButton from 'shared-parts/components/button/formik-button';
import { COLORS, FONTS } from 'shared-parts/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled(Container)`
  padding: 2em 0;
`;

const Paragraph = styled.p`
  margin: 0.5em 0;
  text-align: center;
  font-size: 1.2em;
  color: ${COLORS.neutral800};

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const EmailContainer = styled.div`
  width: 655px;
  font-size: 10px;
  font-family: ${FONTS.mainFont};
`;

const EmailHeader = styled(Container)`
  padding-top: 1.5em;
  width: 100%;
  border-bottom: 1px solid ${COLORS.neutral300};
`;

const EmailBody = styled(Container)`
  padding: 0 3em 1.5em 3em;
`;

const EmailTitle = styled(Section)`
  width: 95%;
  padding-top: 3em;
  border-bottom: 1px solid ${COLORS.neutral300};
`;

const Title = styled.span`
  color: ${COLORS.primary};
  font-family: ${FONTS.oswaldFont};
  font-size: 2em;
  margin-top: 1em;
`;

const RegisterButton = styled(FormikButton)`
  width: 12em;
  height: 2.8em;
  margin: 1em;
  font-size: 1.2em;
  line-height: 0;
  pointer-events: none;
`;

const Link = styled.a`
  color: ${COLORS.secondary};
`;

export {
  Section,
  Paragraph,
  EmailContainer,
  EmailHeader,
  EmailBody,
  EmailTitle,
  Title,
  RegisterButton,
  Link,
};
