import styled from 'styled-components';

import { useEnvVar } from '@frontend/config';

import { getTenantConfig } from 'helpers/get-tenant-config';
import Link from 'shared-parts/components/link';

const Logo = styled.img`
  width: 180px;
  margin-bottom: 28px;
`;

const SecondaryLogo = styled.img`
  position: absolute;
  left: 70%;
  width: 220px;
`;

const AuthLogo = () => {
  const url = useEnvVar('TENANT_WEBSITE_URL') as string;
  const {
    custom: {
      topBar: { logo },
    },
  } = getTenantConfig();
  return (
    <Link url={url} title="" target="_blank" rel="noopener noreferrer">
      <Logo src="/logos/main.svg" alt={`${useEnvVar('PLATFORM_NAME')} logo`} />
      {logo && <SecondaryLogo src={logo} alt={`${useEnvVar('PLATFORM_NAME')} logo`} />}
    </Link>
  );
};

export default AuthLogo;
