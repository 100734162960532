import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const { primary, neutral300, systemRed, white } = COLORS;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 190px);
  padding: 0 20px 0 10px;
  color: ${primary};
`;

export const Section = styled.section`
  padding: 30px 20px 30px 10px;

  &:not(:first-child) {
    border-top: 1px solid #b3b5ba;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  margin: 5px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.span`
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  width: 95%;
  color: ${primary};
  border: 1px solid ${neutral300};
  border-radius: 6px;
`;

export const DeleteButton = styled.button`
  background-color: ${systemRed};
  color: ${white};
  border: none;
`;
