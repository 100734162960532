const MinusIcon = (fillColor = '#B71C1C', height = 20, width = 20) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-688-668H992v2427H-688z" />
      <g fill={fillColor} fillRule="nonzero">
        <path d="M20 40C8.957 40 .004 31.05 0 20.007-.004 8.964 8.944.007 19.987 0 31.03-.007 39.989 8.937 40 19.98c-.009 11.045-8.955 20-20 20.02zm0-36.346c-9.013.01-16.312 7.322-16.306 16.335.006 9.013 7.316 16.316 16.329 16.313 9.013-.003 16.318-7.31 16.319-16.322C36.309 10.97 29.009 3.678 20 3.654z" />
        <path d="M29.69 17.906v4.15H10.384v-4.15z" />
      </g>
    </g>
  </svg>
);

export default MinusIcon;
