import { useEnvVar } from '@frontend/config';

import * as AccessPermissions from 'constants/access-permissions';
import { checkAccessByCompany, checkUniqueAccess } from 'helpers/check-access';

const isShareClassPermissionsDisabled = useEnvVar('DISABLED_SHARE_CLASS_PERMISSIONS');
const isDisableShareCertificateDownload = useEnvVar('DISABLE_SHARE_CERTIFICATE_DOWNLOAD');
const canGenerateOnboardingLink = !useEnvVar('DISABLE_ONBOARD_LINK_GENERATION');

type CriteriaOptions<TKey extends string = never> = {
  criteria: { company: any } & Record<TKey, any>;
};

export default Object.freeze({
  Operations: {
    'See wallets tab': () => true,
    'See equity administration sub-menu': () => true,
    'See historical shareholders registry': () => true,
    'Edit company': () => true,
    'Edit Companies House Auth Code': () => true,
    'Edit communication': () => true,
    'See shareholders': () => true,
    'Export SHR': () => true,
    'Export transactions': () => true,
    'Download certificate': () => true,
    'Import transaction': () => true,
    'Get permissions': () => false,
    'Add response': () => true,
    'Edit users': () => true,
    'Generate onboarding link': () => canGenerateOnboardingLink,
    'Import Shareholders': () => true,
    'See Liquidity Rounds': () => true,
    'See pending investors': () => true,
    'Invite investor': () => true,
    'Edit investor': () => true,
    'See order actions': () => true,
    'Can edit published deal': () => true,
    'See share classes': () => !isShareClassPermissionsDisabled,
    'Edit share classes': () => !isShareClassPermissionsDisabled,
    'Convert share classes': () => !isShareClassPermissionsDisabled,
    'See CompleteDeal action': () => true,
  },
  MultiCompany: {
    'See wallets tab': () => false,
    'Generate onboarding link': () =>
      canGenerateOnboardingLink && checkUniqueAccess([AccessPermissions.GENERATE_ONBOARDING_LINK]),
    'See equity administration sub-menu': () => true,
    'See historical shareholders registry': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.SHR_HISTORICAL]),
    'Edit company': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.EDIT_COMPANY]),
    'Edit Companies House Auth Code': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.FILINGS]),
    'Edit communication': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.COMMUNICATIONS_EDIT]),
    'See shareholders': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.SHAREHOLDERS]),
    'Export SHR': () => true,
    'Export transactions': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.TRANSACTIONS_EXPORT]),
    'Download certificate': ({ criteria: { company } }: CriteriaOptions) =>
      !isDisableShareCertificateDownload &&
      checkAccessByCompany(company, [AccessPermissions.SHARE_CERTIFICATE]),
    'Import transaction': () => false,
    'Add response': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.COMMUNICATIONS_RESPONSE]),
    'Get permissions': () => true,
    'Edit users': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.EDIT_USERS]),
    'Import Shareholders': () => false,
    'See Liquidity Rounds': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.LIQUIDITY_ROUNDS]),
    'See pending investors': () => false,
    'Invite investor': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.DEALS]),
    'Edit investor': () => false,
    'See order actions': ({
      criteria: { company, dealCompanies },
    }: CriteriaOptions<'dealCompanies'>) =>
      checkAccessByCompany(company, [AccessPermissions.ORDERS]) ||
      dealCompanies.includes(company.data.uuid),
    'Can edit published deal': () => false,
    'See share classes': ({ criteria: { company } }: CriteriaOptions) =>
      isShareClassPermissionsDisabled
        ? false
        : checkAccessByCompany(company, [AccessPermissions.SHARE_CLASS]),
    'Edit share classes': ({ criteria: { company } }: CriteriaOptions) =>
      isShareClassPermissionsDisabled
        ? false
        : checkAccessByCompany(company, [AccessPermissions.SHARE_CLASS_EDIT]),
    'Convert share classes': ({ criteria: { company } }: CriteriaOptions) =>
      isShareClassPermissionsDisabled
        ? false
        : checkAccessByCompany(company, [AccessPermissions.SHARE_CLASS_CONVERT]),
    'See CompleteDeal action': () => true,
  },
  Company: {
    'See wallets tab': () => false,
    'Generate onboarding link': () =>
      canGenerateOnboardingLink && checkUniqueAccess([AccessPermissions.GENERATE_ONBOARDING_LINK]),
    'See equity administration sub-menu': () => false,
    'See historical shareholders registry': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.SHR_HISTORICAL]),
    'Edit company': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.EDIT_COMPANY]),
    'Edit Companies House Auth Code': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.FILINGS]),
    'Edit communication': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.COMMUNICATIONS_EDIT]),
    'See shareholders': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.SHAREHOLDERS]),
    'Export SHR': () => true,
    'Export transactions': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.TRANSACTIONS_EXPORT]),
    'Download certificate': ({ criteria: { company } }: CriteriaOptions) =>
      !isDisableShareCertificateDownload &&
      checkAccessByCompany(company, [AccessPermissions.SHARE_CERTIFICATE]),
    'Import transaction': () => false,
    'Add response': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.COMMUNICATIONS_RESPONSE]),
    'Get permissions': () => true,
    'Edit users': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.EDIT_USERS]),
    'Import Shareholders': () => false,
    'See Liquidity Rounds': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.LIQUIDITY_ROUNDS]),
    'See pending investors': () => false,
    'Invite investor': ({ criteria: { company } }: CriteriaOptions) =>
      checkAccessByCompany(company, [AccessPermissions.DEALS]),
    'Edit investor': () => false,
    'See order actions': ({
      criteria: { company, dealCompanies },
    }: CriteriaOptions<'dealCompanies'>) =>
      checkAccessByCompany(company, [AccessPermissions.ORDERS]) ||
      dealCompanies.includes(company.data.uuid),
    'Can edit published deal': () => false,
    'See share classes': ({ criteria: { company } }: CriteriaOptions) =>
      isShareClassPermissionsDisabled
        ? false
        : checkAccessByCompany(company, [AccessPermissions.SHARE_CLASS]),
    'Edit share classes': ({ criteria: { company } }: CriteriaOptions) =>
      isShareClassPermissionsDisabled
        ? false
        : checkAccessByCompany(company, [AccessPermissions.SHARE_CLASS_EDIT]),
    'Convert share classes': ({ criteria: { company } }: CriteriaOptions) =>
      isShareClassPermissionsDisabled
        ? false
        : checkAccessByCompany(company, [AccessPermissions.SHARE_CLASS_CONVERT]),
    'See CompleteDeal action': () => true,
  },
});
