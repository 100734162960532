import { COLORS } from 'shared-parts/constants';

const ExclamationMark = (width = '20', height = '20', fillColor = COLORS.primary) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 64">
    <defs>
      <style>{`.a { fill: ${fillColor} }`}</style>
    </defs>
    <path
      className="a"
      d="M-3030,5a31.908,31.908,0,0,1-32-32,31.908,31.908,0,0,1,32-32,31.908,31.908,0,0,1,32,32A32.094,32.094,0,0,1-3030,5Zm0-58.182A26.166,26.166,0,0,0-3056.182-27,26.073,26.073,0,0,0-3030-.818,26.073,26.073,0,0,0-3003.818-27,26.259,26.259,0,0,0-3030-53.182Z"
      transform="translate(3062 59)"
    />
    <g transform="translate(27.2 12.218)">
      <path
        className="a"
        d="M18.7,8.4h9.745V20.036l-2.473,16H21.318l-2.618-16Z"
        transform="translate(-18.7 -8.4)"
      />
      <circle className="a" cx="4.8" cy="4.8" r="4.8" transform="translate(0 29.964)" />
    </g>
  </svg>
);

export default ExclamationMark;
