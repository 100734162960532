import PropTypes from 'prop-types';

import {
  Amount,
  BluePersonIcon,
  BrownEmailIcon,
  Buttons,
  Cancel,
  GreenPersonIcon,
  Info,
  InformationLine,
  Part,
  PreviewEmailBlock,
  PreviewEmailButton,
  SendEmailButton,
  StyledFormTabs,
  TextWithMargin,
  Wrapper,
} from './bulk-welcome-email.styled';

const tabs = [{ key: 'introduce', name: 'Introduce Your Shareholders To Globacap' }];

const BulkWelcomeEmail = ({
  sendEmail,
  previewEmail,
  hideSideForm,
  shareholdersAmount,
  registered,
  invited,
  willBeSentTo,
}) => (
  <Wrapper>
    <div>
      <StyledFormTabs items={tabs} selected={tabs[0].key} />
      <Part>
        We usually send an email to shareholders of companies that have joined our platform,&nbsp;
        with a guide on the platform, how to access their shareholding,&nbsp; share certificate, and
        more.
        <br />
        <TextWithMargin>
          It is a standard format and cannot be edited. It should be sent to all shareholders that
          are not already registered users.
        </TextWithMargin>
        <PreviewEmailBlock>
          <strong>Would you like us to send this now?</strong>
          <PreviewEmailButton onClick={previewEmail} data-e2e="preview-email-button">
            Preview Email
          </PreviewEmailButton>
        </PreviewEmailBlock>
      </Part>
      <Info>
        <InformationLine>
          <BluePersonIcon />
          <Amount data-e2e="shareholders-amount">{shareholdersAmount}</Amount>Shareholders
        </InformationLine>
        <InformationLine>
          <GreenPersonIcon />
          <Amount data-e2e="registered-shareholders-amount">{registered}</Amount>
          Shareholders already registered on platform
        </InformationLine>
        <InformationLine>
          <BrownEmailIcon />
          <Amount data-e2e="invited-shareholders-amount">{invited}</Amount>Shareholders already been
          sent an invite
        </InformationLine>
        <InformationLine>
          <b>
            The introductory email will be sent to {willBeSentTo} shareholders
            <br />
            The above numbers exclude any shareholders listed in your shareholder registry that do
            not currently hold any shares.
          </b>
        </InformationLine>
      </Info>
    </div>
    <Buttons>
      <Cancel onClick={hideSideForm} data-e2e="cancel-button">
        Cancel
      </Cancel>
      <SendEmailButton
        disabled={willBeSentTo === 0}
        onClick={sendEmail}
        data-e2e="send-email-button"
      >
        SEND EMAIL
      </SendEmailButton>
    </Buttons>
  </Wrapper>
);

BulkWelcomeEmail.propTypes = {
  sendEmail: PropTypes.func.isRequired,
  previewEmail: PropTypes.func.isRequired,
  hideSideForm: PropTypes.func.isRequired,
  shareholdersAmount: PropTypes.number.isRequired,
  registered: PropTypes.number.isRequired,
  invited: PropTypes.number.isRequired,
  willBeSentTo: PropTypes.number.isRequired,
};

export default BulkWelcomeEmail;
