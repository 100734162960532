import * as constants from 'app-state/constants';

export const signUp = params => ({ type: constants.SIGN_UP, params });
export const signUpSuccess = data => ({ type: constants.SIGN_UP_SUCCESS, data });
export const signUpError = error => ({ type: constants.SIGN_UP_ERROR, error });
export const resetSignUp = () => ({ type: constants.RESET_SIGN_UP });

export const saveUserData = user => ({ type: constants.SAVE_USER_DATA, user });
export const logout = isSessionExpired => ({ type: constants.LOGOUT, isSessionExpired });
export const logoutSuccess = () => ({ type: constants.LOGOUT_SUCCESS });
export const logoutError = error => ({ type: constants.LOGOUT_ERROR, error });
export const loginSuccess = data => ({ type: constants.LOGIN_SUCCESS, data });
export const login = params => ({ type: constants.LOGIN, ...params });
export const oauth2Login = params => ({ type: constants.OAUTH2_LOGIN, ...params });
export const oauth2Logout = params => ({ type: constants.OAUTH2_LOGOUT, ...params });

export const forgotPassword = (form, showEmailSentText, setSubmitting, setErrors) => ({
  type: constants.FORGOT_PASSWORD,
  form,
  showEmailSentText,
  setSubmitting,
  setErrors,
});

export const resetPassword = ({
  formData,
  token,
  setSubmitting,
  setErrors,
  setIsTwoFactorAuth,
}) => ({
  type: constants.RESET_PASSWORD,
  formData,
  token,
  setSubmitting,
  setErrors,
  setIsTwoFactorAuth,
});

export const twoFactorAuthGetCode = () => ({ type: constants.TWO_FACTOR_AUTH_GET_CODE });

export const twoFactorAuthGetCodeSuccess = data => ({
  type: constants.TWO_FACTOR_AUTH_GET_CODE_SUCCESS,
  data,
});

export const twoFactorAuthGetCodeError = error => ({
  type: constants.TWO_FACTOR_AUTH_GET_CODE_ERROR,
  error,
});

export const twoFactorAuthCodeClear = () => ({ type: constants.TWO_FACTOR_AUTH_GET_CODE_CLEAR });

export const twoFactorAuthConfirm = (
  data,
  { setSubmitting, setErrors },
  showTwoFactorAuthBackupCodesModal,
) => ({
  type: constants.TWO_FACTOR_AUTH_CONFIRM,
  data,
  setSubmitting,
  setErrors,
  showTwoFactorAuthBackupCodesModal,
});

export const twoFactorAuthDisable = ({ setSubmitting, setErrors }) => ({
  type: constants.TWO_FACTOR_AUTH_DISABLE,
  setSubmitting,
  setErrors,
});
