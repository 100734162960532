import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getCompanyEntitiesError,
  getCompanyEntitiesSuccess,
} from 'app-state/company-entities/actions';
import { GET_COMPANY_ENTITIES } from 'app-state/company-entities/constants';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getCompanyEntities() {
  try {
    const { data } = yield call(request, API.CompanyEntities());
    yield put(getCompanyEntitiesSuccess(data.items));
  } catch (e) {
    yield put(getCompanyEntitiesError(e));
  }
}

function* getCompanyEntitiesWatcher() {
  yield takeEvery(GET_COMPANY_ENTITIES, getCompanyEntities);
}

export { getCompanyEntitiesWatcher };
