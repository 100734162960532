import { InfoIcon } from 'svg-icons';
import { COLORS } from 'shared-parts/constants';

import { StyledTooltip, TooltipContent } from './label-tooltip.styled';

const { alternative } = COLORS;

const renderContent = content => () => <TooltipContent>{content}</TooltipContent>;

const renderIcon = iconColor => () => (
  <div>
    <InfoIcon fillColor={iconColor} />
  </div>
);

const LabelTooltip = ({ content, iconColor }) => (
  <StyledTooltip
    content={renderContent(content)}
    arrowColor={alternative}
    position="bottom-start"
    autohide={false}
  >
    {renderIcon(iconColor)}
  </StyledTooltip>
);

export default LabelTooltip;
