import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import { Screens } from 'shared-parts/constants/screens';

const SectionHeaderBanner = styled.section`
  align-items: center;
  display: flex;
  font-family: ${FONTS.oswaldFont};
  justify-content: center;
  margin-bottom: 56px;
  background: #636eb3;
  color: ${COLORS.white};
  min-height: 80px;
  border-radius: 10px;
`;

const SectionCenteredStatus = styled.div`
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;

  @media (max-width: ${Screens.lg}) {
    font-size: 24px;
  }

  @media (max-width: ${Screens.md}) {
    text-align: center;
  }
`;

export { SectionHeaderBanner, SectionCenteredStatus };
