import { IssuanceStatus } from '@frontend/opportunities';

import { COLORS } from 'shared-parts/constants';

const { systemGreen, systemOrange, systemRed, systemCyan, systemPurple } = COLORS;

const getIssuanceStatusColor = status => {
  switch (status) {
    case IssuanceStatus.PRELAUNCH:
      return systemCyan;
    case IssuanceStatus.LIVE:
      return systemGreen;
    case IssuanceStatus.COMPLETED:
      return systemPurple;
    case IssuanceStatus.PENDING:
      return systemOrange;
    case IssuanceStatus.CLOSED:
      return systemRed;
    default:
      return '';
  }
};

export default getIssuanceStatusColor;
