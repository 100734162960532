const CompleteIcon = ({ width = 18, height = 14, fillColor = 'currentColor' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 1.70687L5.5 13.7069L0 8.20687L1.41 6.79688L5.5 10.8769L16.09 0.296875L17.5 1.70687Z"
      fill={fillColor}
    />
  </svg>
);

export default CompleteIcon;
