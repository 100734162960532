import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import { getCompany } from 'app-state/selectors/companies';

import TransactionsSettingsModal from 'modules/company/equity-administration/secondary-transactions/components/transactions-settings-modal';
import CreateSuccessModal from 'modules/company/equity-administration/share-class/components/create-success-modal';
import ShareClassOverview from 'modules/company/equity-administration/share-class/components/share-class-overview';
import API from 'constants/api';
import { useEnvVar } from 'helpers/use-env-var';
import WarningModal from 'shared/warning-modal';
import { ModalFailed } from 'shared-parts/components';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import { defaultErrorMessage } from 'shared-parts/constants';
import { cutZeros, toDefinedFractionSizeNoRound } from 'shared-parts/helpers/format-number';
import request from 'shared-parts/helpers/request';

function* createShareClass({
  companyId,
  params,
  setSubmitting,
  setErrors,
  hideModal,
  resetForm,
  openEditShareClassSideForm,
  getCustomShowModalParams,
}) {
  try {
    const { data } = yield call(request, API.ShareClassCreate(companyId), 'POST', params);
    const modalParams = yield getCustomShowModalParams
      ? getCustomShowModalParams(data)
      : {
          showHeader: false,
          closable: true,
          component: useEnvVar('ENABLED_SHARE_CLASS_TAB') ? SuccessModal : CreateSuccessModal,
          title: 'SHARE CLASS CREATED',
          hideModal,
          openPrescribedParticularsForm: () => {
            hideModal();
            if (openEditShareClassSideForm) {
              openEditShareClassSideForm(data, true);
            }
          },
        };
    yield put(actions.showModal(modalParams));
    yield put(actions.hideSideForm());

    if (resetForm) {
      yield resetForm();
    }

    yield put(actions.getShareClasses(companyId));
  } catch (e) {
    yield e;
    setSubmitting(false);

    if (e.status === 400) {
      setErrors(e.response.details);
    }
  }
}

function* createShareClassWatcher() {
  yield takeEvery(constants.CREATE_SHARE_CLASS, createShareClass);
}

function* editShareClass({ companyId, params, setSubmitting, setErrors, hideModal }) {
  try {
    setSubmitting(true);
    const {
      data: { conversionMessage },
    } = yield call(request, API.ShareClassEdit(companyId), 'PUT', params);
    yield put(actions.hideModal());
    yield put(actions.hideSideForm());
    yield put(actions.hideOverlay());
    const modalParams = yield {
      showHeader: false,
      closable: true,
      component: conversionMessage ? WarningModal : SuccessModal,
      title: 'SHARE CLASS UPDATED',
      hideModal,
      text: conversionMessage,
    };
    yield put(actions.showModal(modalParams));
    yield put(actions.getShareClasses(companyId));
  } catch ({ response = {}, status }) {
    setSubmitting(false);
    yield put(actions.hideOverlay());

    if (status === 400) {
      setErrors(response.details);
    } else {
      yield put(actions.hideSideForm());
    }
  }
}

function* editShareClassWatcher() {
  yield takeEvery(constants.EDIT_SHARE_CLASS, editShareClass);
}

function* editShareClassDescription({
  companyUuid,
  companyId,
  shareClassId,
  params,
  setSubmitting,
  setErrors,
  hideModal,
}) {
  try {
    setSubmitting(true);
    const {
      data: { conversionMessage },
    } = yield call(
      request,
      API.EditShareClassDescription(companyUuid, shareClassId),
      'PUT',
      params,
    );
    yield put(actions.hideModal());
    yield put(actions.hideSideForm());
    yield put(actions.hideOverlay());
    const modalParams = yield {
      showHeader: false,
      closable: true,
      component: conversionMessage ? WarningModal : SuccessModal,
      title: 'SHARE CLASS UPDATED',
      hideModal,
      text: conversionMessage,
    };
    yield put(actions.showModal(modalParams));
    yield put(actions.getShareClasses(companyId));
  } catch ({ response = {}, status }) {
    setSubmitting(false);
    yield put(actions.hideOverlay());

    if (status === 400) {
      setErrors(response.details);
    } else {
      yield put(actions.hideSideForm());
    }
  }
}

function* editShareClassDescriptionWatcher() {
  yield takeEvery(constants.EDIT_SHARE_CLASS_DESCRIPTION, editShareClassDescription);
}

function* getShareClasses({ companyId }) {
  try {
    const { data } = yield call(request, API.ShareClasses(companyId), 'GET', null, {
      timeout: 20000,
    });

    const formattedData = data.map(shareClass => ({
      ...shareClass,
      // fix conversion from BigInt to string on BE
      nominalSharePrice: cutZeros(shareClass.nominalSharePrice),
    }));
    yield put(actions.getShareClassesSuccess(formattedData));
  } catch (e) {
    yield put(actions.getShareClassesError(e));
  }
}

function* getShareClassesWatcher() {
  yield takeEvery(constants.GET_SHARE_CLASSES, getShareClasses);
}

function* shareClassEditRetrieveQuantity({
  companyId,
  shareClassId,
  nominalSharePrice,
  sharesNumberPrecision,
  shareClass,
  openShareClassEditModal,
  roundingStrategy,
  transactionDate,
  hideOverlay,
}) {
  try {
    const { data } = yield call(
      request,
      API.ShareClassEditRetrieveQuantity(
        companyId,
        shareClassId,
        nominalSharePrice,
        sharesNumberPrecision,
        roundingStrategy,
      ),
    ); // eslint-disable-line

    yield put(
      actions.showOverlay({
        title: `Review Stock ${
          shareClass.nominalSharePrice > nominalSharePrice ? 'Split' : 'Merge'
        } Details`,
        oldNominalSharePrice: shareClass.nominalSharePrice,
        nominalSharePrice,
        handleSubmit: openShareClassEditModal,
        component: ShareClassOverview,
        quantity: data,
        transactionDate,
        sharesNumberPrecision,
        roundingStrategy,
        hideOverlay,
      }),
    );

    yield put(actions.shareClassEditRetrieveQuantitySuccess(data));
  } catch (e) {
    yield put(actions.shareClassEditRetrieveQuantityError(e));
  }
}

function* shareClassEditRetrieveQuantityWatcher() {
  yield takeEvery(constants.SHARE_CLASS_EDIT_RETRIEVE_QUANTITY, shareClassEditRetrieveQuantity);
}

function* convertShares({
  companyId,
  shareholderId,
  fromShareClassId,
  toShareClassId,
  roundingStrategy,
  transactionDate,
  modalParams,
  hideSideForm,
  hideOverlay,
  hideModal,
}) {
  try {
    yield call(request, API.ConvertShares(companyId), 'PUT', {
      fromShareClassId,
      toShareClassId,
      roundingStrategy,
      transactionDate,
    });
    yield put(actions.showModal(modalParams));
    yield put(actions.getHoldings(companyId, shareholderId));
    yield hideSideForm();
    yield hideOverlay();
  } catch ({ response, status }) {
    const { details = {} } = response;

    if (status === 400) {
      const error = details.fromShareClassId || details.toShareClassId;

      if (error) {
        yield put(
          actions.showModal({
            closable: true,
            component: ModalFailed,
            title: error[0],
            hideModal: () => {
              hideModal();
              hideOverlay();
              hideSideForm();
            },
          }),
        );
      }
    }

    yield put(actions.convertSharesError(details));
  }
}

function* convertSharesWatcher() {
  yield takeEvery(constants.CONVERT_SHARES, convertShares);
}

function* buybackShares({
  companyUuid,
  sellerId,
  buyerId,
  pricePaid,
  shareClassId,
  quantity,
  transactionDate,
  modalParams,
  hideSideForm,
  hideOverlay,
  hideModal,
}) {
  try {
    yield call(request, API.BuybackShares(companyUuid), 'POST', {
      sellerId,
      buyerId,
      pricePaid,
      shareClassId,
      quantity,
      transactionDate,
    });
    yield put(actions.showModal(modalParams));
    yield hideSideForm();
    yield hideOverlay();
  } catch (error) {
    if (error.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          component: ModalFailed,
          title: defaultErrorMessage,
          hideModal: () => {
            hideModal();
            hideOverlay();
            hideSideForm();
          },
        }),
      );
    }

    yield put(actions.buybackSharesError(error));
  }
}

function* buybackSharesWatcher() {
  yield takeEvery(constants.BUYBACK_SHARES, buybackShares);
}

function* cancelShares({
  companyUuid,
  equityId,
  quantity,
  transactionDate,
  modalParams,
  hideSideForm,
  hideOverlay,
  hideModal,
}) {
  try {
    yield call(request, API.CancelShares(companyUuid), 'POST', {
      equityId,
      quantity,
      transactionDate,
    });
    yield put(actions.showModal(modalParams));
    yield hideSideForm();
    yield hideOverlay();
  } catch (error) {
    if (error.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          component: ModalFailed,
          title: defaultErrorMessage,
          hideModal: () => {
            hideModal();
            hideOverlay();
            hideSideForm();
          },
        }),
      );
    }

    yield put(actions.cancelSharesError(error));
  }
}

function* cancelSharesWatcher() {
  yield takeEvery(constants.CANCEL_SHARES, cancelShares);
}

function* convertSharesForIndividualHolder({
  companyId,
  shareholderId,
  equityId,
  fromShareClassId,
  toShareClassId,
  roundingStrategy,
  transactionDate,
  quantity,
  modalParams,
  hideSideForm,
  hideOverlay,
  hideModal,
}) {
  try {
    const { data } = yield call(
      request,
      API.ConvertSharesForIndividualHolder(companyId, shareholderId, equityId),
      'PUT',
      {
        fromShareClassId,
        toShareClassId,
        roundingStrategy,
        quantity,
        transactionDate,
      },
    );
    yield put(actions.convertSharesForIndividualHolderSuccess(data));
    yield put(actions.getHoldings(companyId, shareholderId));
    yield put(actions.showModal(modalParams));
    yield hideSideForm();
    yield hideOverlay();
  } catch ({ response, status }) {
    const { details = {} } = response;

    if (status === 400) {
      const error = details.fromShareClassId || details.toShareClassId;

      if (error) {
        yield put(
          actions.showModal({
            closable: true,
            component: ModalFailed,
            title: error[0],
            hideModal: () => {
              hideModal();
              hideOverlay();
              hideSideForm();
            },
          }),
        );
      }
    }

    yield put(actions.convertSharesForIndividualHolderError(response.details));
  }
}

function* convertSharesForIndividualHolderWatcher() {
  yield takeEvery(constants.CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER, convertSharesForIndividualHolder);
}

function* getQuantitiesForPair({
  companyId,
  component,
  title,
  handleSubmit,
  fromShareClass,
  toShareClass,
  roundingStrategy,
  quantity,
  transactionDate,
  currency,
  width,
}) {
  try {
    const fromId = fromShareClass.id;
    const toId = toShareClass.id;
    const quantityParameter = quantity ? [`&quantity=${quantity}`] : [];
    const apiParams = [companyId, fromId, toId, roundingStrategy, ...quantityParameter];
    const { data } = yield call(request, API.GetQuantitiesForPair(...apiParams));

    yield put(
      actions.showOverlay({
        component,
        title,
        handleSubmit,
        fromShareClass,
        toShareClass,
        roundingStrategy,
        transactionDate,
        fromShareClassQuantity: toDefinedFractionSizeNoRound(
          data.fromShareClassQuantity,
          fromShareClass.sharesNumberPrecision,
        ),
        toShareClassQuantity: toDefinedFractionSizeNoRound(
          data.toShareClassQuantity,
          toShareClass.sharesNumberPrecision,
        ),
        currency,
        width,
      }),
    );
  } catch (e) {
    yield put(actions.getQuantitiesForPairError(e));
  }
}

function* getQuantitiesForPairWatcher() {
  yield takeEvery(constants.GET_QUANTITIES_FOR_PAIR, getQuantitiesForPair);
}

function* updateTransactionsPermission({ enabledClasses, disabledClasses }) {
  yield put(showLoader());
  try {
    const {
      data: { uuid },
    } = yield select(getCompany);
    const { data } = yield call(request, API.UpdateTransactionsPermission(uuid), 'PUT', {
      enabledClasses,
      disabledClasses,
    });
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: TransactionsSettingsModal,
      }),
    );
    yield put(actions.updateTransactionsPermissionSuccess(data));
  } catch (e) {
    if (e.status !== 403) {
      yield put(
        actions.showModal({
          closable: true,
          component: ModalFailed,
        }),
      );
    }
  }
  yield put(hideLoader());
}
function* updateTransactionsPermissionWatcher() {
  yield takeEvery(constants.UPDATE_TRANSACTIONS_PERMISSION, updateTransactionsPermission);
}

export {
  createShareClass,
  createShareClassWatcher,
  editShareClass,
  editShareClassWatcher,
  editShareClassDescription,
  editShareClassDescriptionWatcher,
  getShareClasses,
  getShareClassesWatcher,
  shareClassEditRetrieveQuantity,
  shareClassEditRetrieveQuantityWatcher,
  convertShares,
  convertSharesWatcher,
  cancelShares,
  cancelSharesWatcher,
  buybackShares,
  buybackSharesWatcher,
  convertSharesForIndividualHolder,
  convertSharesForIndividualHolderWatcher,
  getQuantitiesForPair,
  getQuantitiesForPairWatcher,
  updateTransactionsPermission,
  updateTransactionsPermissionWatcher,
};
