export default {
  AUD: {
    symbol: 'A$',
    position: 'left',
  },
  GBP: {
    symbol: '£',
    position: 'left',
  },
  EUR: {
    symbol: '€',
    position: 'left',
  },
  USD: {
    symbol: '$',
    position: 'left',
  },
  BRL: {
    symbol: 'R$',
    position: 'left',
  },
  CAD: {
    symbol: '$',
    position: 'left',
  },
  CHF: {
    symbol: 'CHF',
    position: 'left',
  },
  CNY: {
    symbol: '¥',
    position: 'left',
  },
  DKK: {
    symbol: 'kr.',
    position: 'right',
  },
  HKD: {
    symbol: '$',
    position: 'left',
  },
  JPY: {
    symbol: '¥',
    position: 'left',
  },
  NOK: {
    symbol: 'kr',
    position: 'right',
  },
  SEK: {
    symbol: 'kr',
    position: 'right',
  },
  SGD: {
    symbol: '$',
    position: 'left',
  },
  ZAR: {
    symbol: 'R',
    position: 'left',
  },
};
