const UploadIcon = ({ width = 20, height = 20, fillColor = 'currentColor' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.50033 13.3333V8.33333H4.16699L10.0003 2.5L15.8337 8.33333H12.5003V13.3333H7.50033ZM4.16699 16.6667V15H15.8337V16.6667H4.16699Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default UploadIcon;
