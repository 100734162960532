import { call, put, takeEvery } from 'redux-saga/effects';

import {
  addFilingDocumentError,
  addFilingDocumentSuccess,
  deleteFilingDocumentError,
  deleteFilingDocumentSuccess,
  getAllotmentDataError,
  getAllotmentDataSuccess,
  getAllottedSharesError,
  getAllottedSharesSuccess,
  getCompanyProfileError,
  getCompanyProfileSuccess,
  getFilingDetailsError,
  getFilingDetailsSuccess,
  getFilingDocumentsSuccess,
  getFilingsAction,
  getFilingsError,
  getFilingsSuccess,
  getShareCapitalError,
  getShareCapitalSuccess,
  getShareholderInformationError,
  getShareholderInformationSuccess,
  getSicCodesError,
  getSicCodesSuccess,
} from 'app-state/actions/filings';
import { hideSideForm, showModal } from 'app-state/actions/shared';
import {
  ADD_FILING_DOCUMENT,
  CREATE_CONFIRMATION_STATEMENT,
  DELETE_FILING_DOCUMENT,
  DOWNLOAD_FILINGS_AS_CSV,
  GET_ALLOTMENT_DATA,
  GET_ALLOTTED_SHARES,
  GET_COMPANY_PROFILE,
  GET_FILING_DETAILS,
  GET_FILINGS,
  GET_SHARE_CAPITAL,
  GET_SHAREHOLDER_INFORMATION,
  GET_SIC_CODES,
  SUBMIT_SH01_FORM,
} from 'app-state/constants/filings';
import { hideLoader, showLoader } from 'app-state/loader/actions';

import CompaniesHouseErrorModal from 'modules/company/equity-administration/filings/ch-error-modal';
import { CH_SUCCESS_MODAL } from 'modules/company/equity-administration/filings/constants';
import Cs01SuccessModalParams from 'modules/company/equity-administration/filings/cs01-success-modal/cs01-success-modal-params';
import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ModalFailed from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

function* getFilingsHistory({ companyUuid, order, from, to, statuses }) {
  try {
    const queryParams = {
      companyUuid,
      order,
      from,
      to,
      statuses,
    };
    const { data } = yield call(request, API.GetFilingsHistory(queryParams), 'GET');
    yield put(getFilingsSuccess(data));
  } catch (e) {
    yield put(getFilingsError(e));
  }
}

function* getFilingsHistoryWatcher() {
  yield takeEvery(GET_FILINGS, getFilingsHistory);
}

function* getFilingDetails({ companyUuid, filingUuid }) {
  try {
    const { data } = yield call(request, API.GetFilingDetails({ companyUuid, filingUuid }), 'GET');
    const { documents, ...withoutDocuments } = data;
    yield put(getFilingDetailsSuccess(withoutDocuments));
    yield put(getFilingDocumentsSuccess(documents));
  } catch (e) {
    yield put(getFilingDetailsError(e));
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
      }),
    );
  }
}

function* getFilingDetailsWatcher() {
  yield takeEvery(GET_FILING_DETAILS, getFilingDetails);
}

function* addFilingDocument({ companyUuid, filingUuid, fileData, setError }) {
  try {
    const { data } = yield call(
      request,
      API.AddFilingDocument({ companyUuid, filingUuid }),
      'POST',
      fileData,
      { timeout: 180000 },
    );
    yield put(addFilingDocumentSuccess(data));
  } catch (e) {
    const errorMessage = e.status === 400 ? e.response.details.file[0] : defaultErrorMessage;
    yield setError(errorMessage);
    yield put(addFilingDocumentError(errorMessage));
  }
}

function* addFilingDocumentWatcher() {
  yield takeEvery(ADD_FILING_DOCUMENT, addFilingDocument);
}

function* deleteFilingDocument({ companyUuid, filingUuid, documentId, setError }) {
  try {
    yield call(
      request,
      API.DeleteFilingDocument({ companyUuid, filingUuid, documentId }),
      'DELETE',
    );
    yield put(deleteFilingDocumentSuccess(documentId));
  } catch (e) {
    const errorMessage = e.response && e.response.details;
    yield setError(errorMessage);
    yield put(deleteFilingDocumentError(errorMessage));
  }
}

function* deleteFilingDocumentWatcher() {
  yield takeEvery(DELETE_FILING_DOCUMENT, deleteFilingDocument);
}

function* downloadFilingsAsCSV({ companyUuid, from, to, order, statuses }) {
  yield put(showLoader());

  try {
    const queryParams = {
      companyUuid,
      from,
      to,
      order,
      statuses,
    };
    const { data } = yield call(request, API.FilingsCSVExport(queryParams), 'GET', null, {
      to: 'blob',
      timeout: 180000,
    });

    if (data) downloadBlob(data, 'filings.csv');
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  }

  yield put(hideLoader());
}

function* downloadFilingsAsCSVWatcher() {
  yield takeEvery(DOWNLOAD_FILINGS_AS_CSV, downloadFilingsAsCSV);
}

function* getCompanyProfile({ companyUuid }) {
  try {
    const { data } = yield call(request, API.CompanyProfile({ companyUuid }), 'GET', null, {
      timeout: 60000,
    });
    yield put(getCompanyProfileSuccess(data));
  } catch (e) {
    yield put(getCompanyProfileError(e));
  }
}

function* getCompanyProfileWatcher() {
  yield takeEvery(GET_COMPANY_PROFILE, getCompanyProfile);
}

function* getShareCapital({ payload: { companyUuid, formCode, toDate } }) {
  try {
    const getEndpoint = formCode === 'cs01' ? API.ShareCapital : API.Sh01ShareCapital;
    const { data } = yield call(request, getEndpoint({ companyUuid, toDate }), 'GET', null, {
      timeout: 60000,
    });
    yield put(getShareCapitalSuccess(data));
  } catch (e) {
    yield put(getShareCapitalError(e));
  }
}

function* getShareCapitalWatcher() {
  yield takeEvery(GET_SHARE_CAPITAL, getShareCapital);
}

function* getShareholderInformation({ params }) {
  try {
    const { data } = yield call(request, API.ShareholderInformation(params), 'GET', null, {
      timeout: 60000,
    });
    yield put(getShareholderInformationSuccess(data));
  } catch (e) {
    yield put(getShareholderInformationError(e));
  }
}

function* getShareholderInformationWatcher() {
  yield takeEvery(GET_SHAREHOLDER_INFORMATION, getShareholderInformation);
}

function* createConfirmationStatement(action) {
  const { companyUuid, data, filingsQueryParams, setSubmitting } = action.payload;

  try {
    yield call(request, API.SubmitCs01Form(companyUuid), 'POST', data, { timeout: 60000 });
    yield put(hideSideForm());
    yield put(showModal(Cs01SuccessModalParams));
    yield put(getFilingsAction({ ...filingsQueryParams, companyUuid }));
  } catch (error) {
    yield setSubmitting(false);
    let errorText = '';
    if (error.status === 400) {
      errorText = error.response ? error.response.details : '';
    }

    yield put(
      showModal({
        component: CompaniesHouseErrorModal,
        errorText,
      }),
    );
  }
}

function* createConfirmationStatementWatcher() {
  yield takeEvery(CREATE_CONFIRMATION_STATEMENT, createConfirmationStatement);
}

function* getSicCodes() {
  try {
    const { data } = yield call(request, API.SicCodes());

    yield put(getSicCodesSuccess({ data }));
  } catch (e) {
    yield put(getSicCodesError({ error: e }));
  }
}

function* getSicCodesWatcher() {
  yield takeEvery(GET_SIC_CODES, getSicCodes);
}

function* getAllotmentData({ payload: { companyUuid } }) {
  try {
    const { data } = yield call(request, API.AllotmentData({ companyUuid }), 'GET', null, {
      timeout: 60000,
    });
    yield put(getAllotmentDataSuccess(data));
  } catch (e) {
    yield put(getAllotmentDataError(e));
  }
}

function* getAllotmentDataWatcher() {
  yield takeEvery(GET_ALLOTMENT_DATA, getAllotmentData);
}

function* getAllottedShares({ payload: { companyUuid, fromDate, toDate } }) {
  try {
    const { data } = yield call(
      request,
      API.AllottedShares({ companyUuid, fromDate, toDate }),
      'GET',
      null,
      { timeout: 60000 },
    );
    yield put(getAllottedSharesSuccess(data));
  } catch (e) {
    yield put(getAllottedSharesError(e));
  }
}

function* getAllottedSharesWatcher() {
  yield takeEvery(GET_ALLOTTED_SHARES, getAllottedShares);
}

function* submitSh01Form({ payload }) {
  const { companyUuid, data, filingsQueryParams, setSubmitting } = payload;

  try {
    yield setSubmitting(true);
    yield call(request, API.SubmitSh01Form({ companyUuid }), 'POST', data, { timeout: 60000 });
    yield put(hideSideForm());
    yield put(
      showModal({
        component: SuccessModal,
        title: CH_SUCCESS_MODAL.TITLE,
        additionalText: CH_SUCCESS_MODAL.SH01_SUBMITTED_MESSAGE,
        buttonText: CH_SUCCESS_MODAL.BUTTON,
        modalWidth: 600,
        iconSize: 40,
      }),
    );
    yield put(getFilingsAction({ ...filingsQueryParams, companyUuid }));
  } catch (error) {
    yield setSubmitting(false);
    yield put(
      showModal({
        component: CompaniesHouseErrorModal,
      }),
    );
  }
}

function* submitSh01FormWatcher() {
  yield takeEvery(SUBMIT_SH01_FORM, submitSh01Form);
}

// eslint-disable-next-line import/no-unused-modules
export {
  getFilingsHistory,
  getFilingsHistoryWatcher,
  getFilingDetails,
  getFilingDetailsWatcher,
  addFilingDocument,
  addFilingDocumentWatcher,
  deleteFilingDocument,
  deleteFilingDocumentWatcher,
  downloadFilingsAsCSV,
  downloadFilingsAsCSVWatcher,
  getCompanyProfile,
  getCompanyProfileWatcher,
  getShareCapital,
  getShareCapitalWatcher,
  getShareholderInformation,
  getShareholderInformationWatcher,
  createConfirmationStatementWatcher,
  getSicCodesWatcher,
  getAllotmentData,
  getAllotmentDataWatcher,
  getAllottedShares,
  getAllottedSharesWatcher,
  submitSh01Form,
  submitSh01FormWatcher,
};
