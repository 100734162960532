import styled from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';
import { COLORS, FONTS } from 'shared-parts/constants';

const { highlight, neutral500, neutral200, highlightContrastText } = COLORS;

const {
  custom: {
    tab: { headerBackground },
  },
} = getTenantConfig();

const FormWrapper = styled.div`
  position: fixed;
  top: 80px;
  right: 0;
  width: ${({ width = '640px' }) => width};
  background: ${neutral200};
  box-shadow: 0 8px 8px ${neutral500};
  border: 1px solid ${neutral500};
  z-index: 1000;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
  box-sizing: content-box;
`;

const FormContainer = styled.div`
  height: calc(100vh - 131px);
  overflow-y: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormHeader = styled.div`
  height: 50px;
  color: ${highlightContrastText};
  background: ${headerBackground || highlight};
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.div`
  cursor: pointer;
  user-select: none;
  margin-left: auto;
`;

export { FormWrapper, FormContainer, FormHeader, CloseButton };
