import { sessionService } from 'redux-react-session';

import { createRequestClient } from '@frontend/http';

import { logout, showModal } from 'app-state/actions';
import store from 'app-state/store';

import handleForbiddenAccess from 'helpers/handle-forbidden-access'; // we should change this implementation in future
import ModalFailed from 'shared-parts/components/modal-failed';

const client = createRequestClient({
  token: () => {
    return sessionService
      .loadUser()
      .then(user => user?.authToken)
      .catch(error => {
        if (error === 'User not found') {
          return undefined;
        }

        return Promise.reject(error);
      });
  },
  onError() {
    store.dispatch(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        title: 'SOMETHING WENT WRONG',
      }),
    );
  },
  onSessionExpired() {
    store.dispatch(logout(true));
  },
  onForbid: handleForbiddenAccess,
  onBlockchainError() {
    store.dispatch(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
        title: 'Something Went Wrong',
      }),
    );
  },
});

export { client as request };

export default client;
