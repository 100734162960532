import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { shadowColor, white, neutral200, primary } = COLORS;

const Button = styled.button<{
  disabled?: boolean;
  styles?: string;
}>`
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'unset;' : `pointer;`)};
  color: ${white};
  text-decoration: none;
  font-weight: 700;
  padding: 9px 15px;
  border-radius: 5px;
  display: flex;
  width: fit-content;
  font-size: 14px;
  color: ${primary};
  font-family: ${FONTS.mainFont};
  box-shadow: ${({ disabled }) => `${disabled ? '0 1px 2px' : '0 2px 4px'}  ${shadowColor};`};
  background: ${({ disabled }) => (disabled ? neutral200 : white)};

  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : `0 3px 6px ${shadowColor};`)};
    text-decoration: none;
  }
  ${({ styles }) => `${styles}`};
`;

const Label = styled.span`
  margin-left: 10px;
`;

const Toast = styled.div`
  padding: 9px 15px;
  color: ${primary};
  font-weight: 700;
  box-shadow: ${`0 3px 6px ${shadowColor}`};
  border-radius: 5px;
  background: ${white};
  position: absolute;
  z-index: 11;
  right: 900px;
  top: 600px;
  width: 194px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper = styled.div``;

const ToastText = styled.span`
  padding: 0 10px;
`;
const SpinnerWrapper = styled.span`
  position: relative;
  padding: 0 10px;
`;

export { Button, Label, Toast, Wrapper, ToastText, SpinnerWrapper };
