const Pencil = ({ width, height, color = '#333' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 33 33">
    <path
      fill={color}
      fillRule="nonzero"
      d="M0 26.546v5.542c0 .51.401.912.912.912h5.542a.855.855 0 0 0 .638-.273L27 12.837 20.163 6 .273 25.89a.895.895 0 0 0-.273.656zM32.452 7.572c.73-.73.73-1.91 0-2.641L28.07.548c-.73-.73-1.91-.73-2.641 0L22 3.976 29.024 11l3.428-3.428z"
    />
  </svg>
);

export default Pencil;
