import { createSelector } from 'reselect';

import { IssuanceStatus } from '@frontend/opportunities';

import type { RootState } from 'app-state/store';

export const getDeals = (state: RootState) => state.deals;
export const getDealStructureFormError = (state: RootState) => state.dealStructureFormError;

export const getAvailableDeals = createSelector(getDeals, ({ data }) =>
  data.filter(
    ({ status, private: isPrivate }) =>
      isPrivate && [IssuanceStatus.LIVE, IssuanceStatus.PRELAUNCH].includes(status),
  ),
);

export const getInvestorAgentsPermissions = (state: RootState) =>
  state.investorAgentsPermissions.data;

export const getUserDeals = (state: RootState) => state.userDeals;
