export const findSmallestFreeNumber = (arr: number[]): number => {
  const sortedArr = arr.sort((a, b) => a - b);

  let nextNumber = 1;

  sortedArr.forEach(num => {
    if (num === nextNumber) {
      nextNumber += 1;
    }
  });

  return nextNumber;
};
