export const CREATE_SHARE_CLASS = 'CREATE_SHARE_CLASS';

export const EDIT_SHARE_CLASS = 'EDIT_SHARE_CLASS';
export const EDIT_SHARE_CLASS_SUCCESS = 'EDIT_SHARE_CLASS_SUCCESS';
export const EDIT_SHARE_CLASS_ERROR = 'EDIT_SHARE_CLASS_ERROR';

export const EDIT_SHARE_CLASS_DESCRIPTION = 'EDIT_SHARE_CLASS_DESCRIPTION';
export const EDIT_SHARE_CLASS_DESCRIPTION_SUCCESS = 'EDIT_SHARE_CLASS_DESCRIPTION_SUCCESS';
export const EDIT_SHARE_CLASS_DESCRIPTION_ERROR = 'EDIT_SHARE_CLASS_DESCRIPTION_ERROR';

export const GET_SHARE_CLASSES = 'GET_SHARE_CLASSES';
export const GET_SHARE_CLASSES_SUCCESS = 'GET_SHARE_CLASSES_SUCCESS';
export const GET_SHARE_CLASSES_ERROR = 'GET_SHARE_CLASSES_ERROR';

export const SHARE_CLASS_EDIT_RETRIEVE_QUANTITY = 'SHARE_CLASS_EDIT_RETRIEVE_QUANTITY';
export const SHARE_CLASS_EDIT_RETRIEVE_QUANTITY_SUCCESS =
  'SHARE_CLASS_EDIT_RETRIEVE_QUANTITY_SUCCESS';
export const SHARE_CLASS_EDIT_RETRIEVE_QUANTITY_ERROR = 'SHARE_CLASS_EDIT_RETRIEVE_QUANTITY_ERROR';

export const CONVERT_SHARES = 'CONVERT_SHARES';
export const CONVERT_SHARES_SUCCESS = 'CONVERT_SHARES_SUCCESS';
export const CONVERT_SHARES_ERROR = 'CONVERT_SHARES_ERROR';

export const CANCEL_SHARES = 'CANCEL_SHARES';
export const CANCEL_SHARES_SUCCESS = 'CANCEL_SHARES_SUCCESS';
export const CANCEL_SHARES_ERROR = 'CANCEL_SHARES_ERROR';

export const BUYBACK_SHARES = 'BUYBACK_SHARES';
export const BUYBACK_SHARES_SUCCESS = 'BUYBACK_SHARES_SUCCESS';
export const BUYBACK_SHARES_ERROR = 'BUYBACK_SHARES_ERROR';

export const CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER = 'CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER';
export const CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_SUCCESS =
  'CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_SUCCESS';
export const CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_ERROR =
  'CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_ERROR';

export const GET_QUANTITIES_FOR_PAIR = 'GET_QUANTITIES_FOR_PAIR';
export const GET_QUANTITIES_FOR_PAIR_SUCCESS = 'GET_QUANTITIES_FOR_PAIR_SUCCESS';
export const GET_QUANTITIES_FOR_PAIR_ERROR = 'GET_QUANTITIES_FOR_PAIR_ERROR';

export const UPDATE_TRANSACTIONS_PERMISSION = 'UPDATE_TRANSACTIONS_PERMISSION';
export const UPDATE_TRANSACTIONS_PERMISSION_SUCCESS = 'UPDATE_TRANSACTIONS_PERMISSION_SUCCESS';
