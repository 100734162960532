import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { showModal, updatePage } from 'app-state/actions';
import { EDIT_COMPANY_OVERVIEW } from 'app-state/deal/constants';
import { DealActionTypes, DealPage } from 'app-state/deal/types';
import { Deal } from 'app-state/deals/types';
import { getDeal, getPage } from 'app-state/selectors';

import API from 'constants/api';
import ErrorModal from 'shared-parts/components/modal-failed';
import { getUpdatedPageData } from 'shared-parts/helpers';
import request from 'shared-parts/helpers/request';

function* editCompanyOverview({
  payload: { values, changeEditing },
}: DealActionTypes): Generator<StrictEffect | void, void, { data: Deal; content: DealPage }> {
  try {
    const {
      data: { company },
    } = yield select(getDeal);
    yield call(request, API.EditCompany(`${company.id}`), 'PUT', values);
    const page = yield select(getPage);
    yield put(updatePage(getUpdatedPageData(page.content, values, 'editComponent')));
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
  yield changeEditing();
}

function* editCompanyOverviewWatcher() {
  yield takeEvery(EDIT_COMPANY_OVERVIEW, editCompanyOverview);
}

export { editCompanyOverviewWatcher };
