import { connect } from 'react-redux';

import { createPassword } from 'app-state/actions';

import Routes from 'constants/routes';
import { AuthLogo } from 'shared/logos';

import CreatePasswordForm from './components/create-password-form-wrapper';
import { Container, Header, LoginLink, Wrapper } from './create-password.styled';

const CreatePassword = ({ match, confirm }) => {
  const handleSubmit = (data, { setSubmitting, setErrors }) =>
    confirm(data, match.params.emailToken, setSubmitting, setErrors);

  return (
    <Container>
      <Wrapper>
        <AuthLogo />
        <Header isBold>Welcome!</Header>
        <Header>Please create a password:</Header>
        <CreatePasswordForm handleFormSubmit={handleSubmit} />
        <LoginLink
          fontSize="14px"
          additionalText="Already have an account?"
          url={Routes.Login()}
          title="Log in"
        >
          Log in
        </LoginLink>
      </Wrapper>
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  confirm: (data, token, setSubmitting, setErrors) =>
    dispatch(createPassword(data, token, setSubmitting, setErrors)),
});

export default connect(null, mapDispatchToProps)(CreatePassword);
