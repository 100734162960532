import { FC } from 'react';

type LockProps = {
  color: string;
  onClick: () => void;
  className: string;
};

const LockIcon: FC<React.PropsWithChildren<LockProps>> = ({
  color = '#00BBD2',
  onClick,
  className,
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      viewBox="0 0 16 21"
    >
      <path
        fill={color}
        d="M12,17a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,17Zm6-9H17V6A5,5,0,0,0,7,6V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8ZM8.9,6a3.1,3.1,0,0,1,6.2,0V8H8.9ZM18,20H6V10H18Z"
        transform="translate(-4 -1)"
      />
    </svg>
  );
};

export default LockIcon;
