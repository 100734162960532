import * as constants from 'app-state/constants';

/* eslint-disable */
// REMOVE THIS ESLINT DISABLE WHEN MULTIPLE REDUCERS ARE EXPORTED

export const topNavigationReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.SET_TOP_NAVIGATION_ITEMS:
      return action.items;
    case constants.UPDATE_TOP_NAVIGATION_ITEMS:
      return { ...state, ...action.items };
    default:
      return state;
  }
};
