import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './share-plan-invite-employees.constants';
import initialState, {
  SharePlanInviteEmployeesStateType,
} from './share-plan-invite-employees.state';

export const sharePlanInviteEmployeesReducer = (
  state = initialState,
  action: AnyAction,
): SharePlanInviteEmployeesStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.bulkInviteEmployees.REQUEST:
        draft.bulkInvite.loading = true;
        draft.bulkInvite.error = undefined;
        draft.bulkInvite.uploaded = false;
        break;
      case constants.bulkInviteEmployees.FAILURE:
        draft.bulkInvite.loading = false;
        draft.bulkInvite.error = action.error;
        draft.bulkInvite.uploaded = false;
        break;
      case constants.bulkInviteEmployees.SUCCESS:
        draft.bulkInvite.loading = false;
        draft.bulkInvite.error = undefined;
        draft.bulkInvite.uploaded = true;
        break;
      case constants.resetBulkInvite:
        draft.bulkInvite.loading = false;
        draft.bulkInvite.error = undefined;
        draft.bulkInvite.uploaded = false;
        break;
      default:
        break;
    }
  });
