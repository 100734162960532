import {
  call,
  debounce,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import {
  hideSideForm as hideSideFormAction,
  showModal,
  showSideForm,
} from 'app-state/actions/shared';
import {
  addTransactionNoteError,
  addTransactionNoteSuccess,
  allocateShareholdingError,
  editShareholderPerformanceError,
  editShareholderPerformanceSuccess,
  getHoldings as getHoldingsAction,
  getHoldingsError,
  getHoldingsSuccess,
  getRegisteredShareholdersError,
  getRegisteredShareholdersSuccess,
  getShareholdersDropdownDataError,
  getShareholdersDropdownDataSuccess,
  getShareholdersError,
  getShareholdersSuccess,
  getTransferShareholdersError,
  getTransferShareholdersSuccess,
  lookupShareholderError,
  lookupShareholderSuccess,
  removeTransactionNoteError,
  removeTransactionNoteSuccess,
  searchShareholdersError,
  searchShareholdersSuccess,
  shareholderCreateError,
  shareholderCreateSuccess,
  shareholderEditError,
  shareholderEditSuccess,
  shareholderTransactionRevertError,
  transferShareholdingError,
  updateShareholderTransactions as updateShareholderTransactionsAction,
  updateShareholderTransactionsError,
  updateShareholderTransactionsSuccess,
  updateTransactionNoteError,
  updateTransactionNoteSuccess,
} from 'app-state/actions/shareholders';
import {
  ADD_TRANSACTION_NOTE,
  ALLOCATE_SHAREHOLDING,
  CREATE_SHAREHOLDER,
  DOWNLOAD_SHAREHOLDER_CERT,
  DOWNLOAD_SHAREHOLDER_REGISTRY,
  EDIT_SHAREHOLDER,
  EDIT_SHAREHOLDER_PERFORMANCE,
  GET_BULK_WELCOME_EMAIL_INFO,
  GET_HOLDINGS,
  GET_REGISTERED_SHAREHOLDERS,
  GET_SHAREHOLDERS,
  GET_SHAREHOLDERS_DROPDOWN_DATA,
  GET_TRANSFER_SHAREHOLDERS,
  LOOKUP_SHAREHOLDER,
  REMOVE_TRANSACTION_NOTE,
  SEARCH_SHAREHOLDERS,
  SEND_BULK_WELCOME_EMAIL,
  SHAREHOLDER_TRANSACTION_REVERT,
  TRANSFER_SHAREHOLDING,
  UPDATE_SHAREHOLDER_TRANSACTIONS,
  UPDATE_TRANSACTION_NOTE,
} from 'app-state/constants/shareholders';
import getShareholdersGql from 'app-state/graphql/shareholders/';
import { hideLoader, showLoader } from 'app-state/loader/actions';

import BulkWelcomeEmail from 'modules/company/equity-administration/shareholders/bulk-welcome-email';
import ShareholdersInvitedModal from 'modules/company/equity-administration/shareholders/components/shareholders-invited-modal';
import API from 'constants/api';
import downloadBlob from 'helpers/download-blob';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ModalFailed from 'shared-parts/components/modal-failed';
import request from 'shared-parts/helpers/request';

function* getHoldings({ companyId, shareholderId }) {
  try {
    const { data } = yield call(request, API.Holdings(companyId, shareholderId), 'GET');

    yield put(getHoldingsSuccess(data));
  } catch (e) {
    yield put(getHoldingsError(e));
  }
}

function* getHoldingsWatcher() {
  yield takeEvery(GET_HOLDINGS, getHoldings);
}

function* downloadShareholderRegistry({ companyId, date }) {
  yield put(showLoader());

  try {
    const { data } = yield call(
      request,
      API.ShareholdersRegistryExport(companyId, date),
      'GET',
      null,
      { to: 'blob', timeout: 180000 },
    );

    if (data) yield downloadBlob(data, 'shareholder_registry.csv');
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  }

  yield put(hideLoader());
}

function* downloadShareholderRegistryWatcher() {
  yield takeEvery(DOWNLOAD_SHAREHOLDER_REGISTRY, downloadShareholderRegistry);
}

const getShareholdersCriterias = companyId => shareholder => ({
  companyId,
  investorId: shareholder.id,
});

function* getShareholders({ params: { isFiltering, shouldBeRefreshed, ...rest } }) {
  if (isFiltering) yield put(showLoader());
  try {
    const state = yield select();
    const data = yield getShareholdersGql(rest, state);

    yield put(getShareholdersSuccess(data, shouldBeRefreshed, rest.limit));
  } catch (e) {
    yield put(getShareholdersError(e));
  }
  if (isFiltering) yield put(hideLoader());
}

function* getShareholdersWatcher() {
  yield takeEvery(GET_SHAREHOLDERS, getShareholders);
}

function* getRegisteredShareholders({ companyUuid }) {
  try {
    const api = API.RegisteredShareholders(companyUuid);
    const { data } = yield call(request, api, 'GET', null, { timeout: 60000 });

    yield put(getRegisteredShareholdersSuccess(data));
  } catch (e) {
    yield put(getRegisteredShareholdersError(e));
  }
}

function* getRegisteredShareholdersWatcher() {
  yield takeEvery(GET_REGISTERED_SHAREHOLDERS, getRegisteredShareholders);
}

function* getTransferShareholders({ companyId }) {
  try {
    const api = API.TransferShareholders(companyId);
    const { data } = yield call(request, api, 'GET', null, { timeout: 60000 });

    yield put(getTransferShareholdersSuccess(data));
  } catch (e) {
    yield put(getTransferShareholdersError(e));
  }
}

function* getTransferShareholdersWatcher() {
  yield takeEvery(GET_TRANSFER_SHAREHOLDERS, getTransferShareholders);
}

function* getShareholdersDropdownData({ companyId, bookmark, pageSize, name, shouldBeRefreshed }) {
  try {
    const { data } = yield call(request, API.Shareholders({ companyId, pageSize, bookmark, name }));

    yield put(getShareholdersDropdownDataSuccess(data, shouldBeRefreshed));
  } catch (e) {
    yield put(getShareholdersDropdownDataError(e));
  }
}

function* getShareholdersDropdownDataWatcher() {
  yield debounce(1000, GET_SHAREHOLDERS_DROPDOWN_DATA, getShareholdersDropdownData);
}

function* pollShareholderCert(documentUuid) {
  try {
    while (true) {
      const { data } = yield call(request, API.ShareCertificate.Status(documentUuid));

      if (data.status === 'document_ready') {
        return data;
      }

      yield delay(2000);
    }
  } catch (e) {
    yield put({ type: 'CANCEL_SHAREHOLDER_POLL' });
  }
}

function* startPollShareholderCert(documentUuid) {
  try {
    return yield race({
      task: call(pollShareholderCert, documentUuid),
      timeout: delay(1000 * 30),
      cancelled: take('CANCEL_SHAREHOLDER_POLL'),
    });
  } catch (error) {
    console.error('An error occurred polling shareholder cert.', error);
  }
}

function* downloadShareholderCert({ payload: { id, companyId, shareholderId, shareClass } }) {
  try {
    yield put(showLoader());
    const shareClasses = yield call(request, API.ShareClasses(companyId), 'GET', null, {
      timeout: 20000,
    });
    const selectedShareClass = shareClasses.data.find(({ className }) => className === shareClass);
    const selectedShareClassId = selectedShareClass ? selectedShareClass.id : null;

    const { data } = yield call(
      request,
      API.ShareCertificate.Request(id, companyId, shareholderId, selectedShareClassId),
      'GET',
    );

    const result = yield call(startPollShareholderCert, data.documentUuid);

    if (result.task) {
      const { data: certificateBlob } = yield call(
        request,
        API.ShareCertificate.Download(data.documentUuid),
        'GET',
        null,
        {
          contentType: 'application/pdf',
          to: 'blob',
        },
      );

      downloadBlob(certificateBlob, 'certificate.pdf');
    } else {
      throw new Error('An error occurred while downloading share certificate.');
    }
  } catch (e) {
    console.error(e);

    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  } finally {
    yield put(hideLoader());
  }
}

function* downloadShareholderCertWatcher() {
  yield takeEvery(DOWNLOAD_SHAREHOLDER_CERT, downloadShareholderCert);
}

function* createShareholder({ payload: { companyId, params, handleSuccess, form, modalParams } }) {
  try {
    yield put(showLoader());
    const { data } = yield call(request, API.ShareholderCreate(companyId), 'POST', params);

    yield put(shareholderCreateSuccess(data));
    yield put(hideLoader());
    yield handleSuccess?.(data.id);
    yield form.setSubmitting(false);
    yield put(actions.showModal(modalParams));
  } catch (e) {
    yield put(hideLoader());
    if (e.response.errorCode === 422) {
      yield form.setFieldError('email', e.response.errorMessage);
      yield form.setSubmitting(false);
    }
    yield put(shareholderCreateError(e));
  }
}

function* createShareholderWatcher() {
  yield takeEvery(CREATE_SHAREHOLDER, createShareholder);
}

function* editShareholderDetails({
  payload: { companyId, shareholderId, params, form, modalParams, handleSuccess },
}) {
  yield put(showLoader());
  try {
    const { data } = yield call(
      request,
      API.ShareholderEdit(companyId, shareholderId),
      'PUT',
      params,
    );

    yield put(shareholderEditSuccess({ ...data, surname: data.surName }));
    handleSuccess?.(shareholderId);
    yield form.setSubmitting(false);
    yield put(hideLoader());
    yield put(actions.showModal(modalParams));
  } catch (e) {
    yield put(hideLoader());
    if (e.response && e.response.errorCode === 422) {
      return yield form.setFieldError('email', e.response.errorMessage);
    }
    yield put(shareholderEditError(e));
  }
}

function* editShareholderDetailsWatcher() {
  yield takeLatest(EDIT_SHAREHOLDER, editShareholderDetails);
}

function* allocateShareholding({ payload: { data, updateShareholderRegistry } }) {
  yield put(showLoader());
  try {
    yield call(request, API.AllocateShareholding(data.companyId, data.shareholderId), 'POST', data);

    yield put(getHoldingsAction(data.companyId, data.shareholderId));
    yield put(hideSideFormAction());
    if (updateShareholderRegistry) {
      yield delay(4000);
      yield updateShareholderRegistry();
    }
  } catch (e) {
    if (e.status === 400) {
      yield put(allocateShareholdingError(e.response.details));
    }
  }
  yield put(hideLoader());
}

function* allocateShareholdingWatcher() {
  yield takeEvery(ALLOCATE_SHAREHOLDING, allocateShareholding);
}

function* transferShareholding({ data, toggleFormVisibility }) {
  yield put(showLoader());
  try {
    yield call(request, API.TransferShareholding(data.companyUuid), 'POST', data);
    yield put(getHoldingsAction(data.companyId, data.sellerId));
    yield toggleFormVisibility();
  } catch (e) {
    if (e.status === 400) {
      yield put(transferShareholdingError(e.response.details));
    }
  }
  yield put(hideLoader());
}

function* transferShareholdingWatcher() {
  yield takeEvery(TRANSFER_SHAREHOLDING, transferShareholding);
}

function* shareholderTransactionRevert({
  companyId,
  shareholderId,
  transactionId,
  updateTransactionUrl,
}) {
  try {
    yield call(
      request,
      API.ShareholderTransactionRevert(companyId, shareholderId, transactionId),
      'PUT',
    );
    yield put(
      updateShareholderTransactionsAction(shareholderId, transactionId, updateTransactionUrl),
    ); // eslint-disable-line
  } catch (e) {
    yield put(shareholderTransactionRevertError(e));
  }
}

function* shareholderTransactionRevertWatcher() {
  yield takeEvery(SHAREHOLDER_TRANSACTION_REVERT, shareholderTransactionRevert);
}

function* updateShareholderTransactions({ shareholderId, transactionId, updateTransactionUrl }) {
  try {
    const { data } = yield call(request, updateTransactionUrl);
    const transactionData = data.items.find(i => i.transactionId === transactionId);
    yield put(updateShareholderTransactionsSuccess(shareholderId, transactionData));
  } catch (e) {
    yield put(updateShareholderTransactionsError(e));
  }
}

function* updateShareholderTransactionsWatcher() {
  yield takeEvery(UPDATE_SHAREHOLDER_TRANSACTIONS, updateShareholderTransactions);
}

function* getBulkWelcomeEmailInfo({ companyId }) {
  try {
    const { data } = yield call(request, API.BulkWelcomeEmailInfo(companyId), 'GET', null, {
      timeout: 45000,
    });

    yield put(
      showSideForm({
        header: 'Send a Welcome Email',
        component: BulkWelcomeEmail,
        companyId,
        shareholdersAmount: data.all,
        registered: data.registered,
        invited: data.invited,
        willBeSentTo: data.willInvite,
        width: '898px',
      }),
    );
  } catch (e) {
    // handle possible error here (separate ticket is created)
  }
}

function* getBulkWelcomeEmailInfoWatcher() {
  yield takeEvery(GET_BULK_WELCOME_EMAIL_INFO, getBulkWelcomeEmailInfo);
}

function* sendBulkWelcomeEmail({ companyId }) {
  try {
    const { data } = yield call(request, API.SendBulkWelcomeEmail(companyId), 'POST', {});

    yield put(
      showModal({
        showHeader: false,
        closable: true,
        component: ShareholdersInvitedModal,
        invited: data.invited,
      }),
    );
    yield put(hideSideFormAction());
  } catch (e) {
    // handle possible error here (separate ticket is created)
  }
}

function* sendBulkWelcomeEmailWatcher() {
  yield takeEvery(SEND_BULK_WELCOME_EMAIL, sendBulkWelcomeEmail);
}

function* lookupShareholder({ companyUuid, sharePlanUuid, grantId }) {
  try {
    const { data } = yield call(
      request,
      API.ShareholderLookup(companyUuid, sharePlanUuid, grantId),
    );

    yield put(lookupShareholderSuccess(data));
  } catch (e) {
    yield put(lookupShareholderError(e));
  }
}

function* lookupShareholderWatcher() {
  yield takeEvery(LOOKUP_SHAREHOLDER, lookupShareholder);
}

function* addTransactionNote({
  companyUuid,
  transactionId,
  body,
  setSubmitting,
  refetchTransactions,
}) {
  yield put(showLoader());

  try {
    const { data } = yield call(
      request,
      API.AddTransactionNote({ companyUuid, transactionId }),
      'POST',
      { body },
    );
    refetchTransactions();

    yield put(addTransactionNoteSuccess(data));
  } catch (e) {
    yield put(addTransactionNoteError(e));
  }
  yield setSubmitting(false);
  yield put(hideLoader());
}

function* addTransactionNoteWatcher() {
  yield takeEvery(ADD_TRANSACTION_NOTE, addTransactionNote);
}

function* updateTransactionNote({
  companyUuid,
  transactionId,
  noteId,
  body,
  setSubmitting,
  refetchTransactions,
}) {
  try {
    const { data } = yield call(
      request,
      API.UpdateTransactionNote({ companyUuid, transactionId, noteId }),
      'PUT',
      { body },
    );
    refetchTransactions();

    yield put(updateTransactionNoteSuccess(data));
  } catch (e) {
    yield put(updateTransactionNoteError(e));
  }
  yield setSubmitting(false);
}

function* updateTransactionNoteWatcher() {
  yield takeEvery(UPDATE_TRANSACTION_NOTE, updateTransactionNote);
}

function* removeTransactionNote({
  companyUuid,
  transactionId,
  noteId,
  updatedNotes,
  setUpdatedNotes,
  refetchTransactions,
}) {
  try {
    const { data } = yield call(
      request,
      API.RemoveTransactionNote({ companyUuid, transactionId, noteId }),
      'DELETE',
    );

    setUpdatedNotes(updatedNotes.filter(note => note.id !== noteId));
    refetchTransactions();

    yield put(removeTransactionNoteSuccess(data));
  } catch (e) {
    yield put(removeTransactionNoteError(e));
  }
}

function* removeTransactionNoteWatcher() {
  yield takeEvery(REMOVE_TRANSACTION_NOTE, removeTransactionNote);
}

function* searchShareholders({ payload: { companyUuid, email } }) {
  try {
    const { data } = yield call(request, API.ShareholdersSearch(companyUuid, email));
    const dataWithSurname = {
      ...data,
      items: data.items.map(item => ({ ...item, surname: item.surName })),
    };

    yield put(searchShareholdersSuccess(dataWithSurname));
  } catch (e) {
    yield put(searchShareholdersError(e));
  }
}

function* searchShareholdersWatcher() {
  yield takeEvery(SEARCH_SHAREHOLDERS, searchShareholders);
}

function* editShareholderPerformance({ payload: { companyId, shareholderId, params } }) {
  yield put(showLoader());
  try {
    const { data } = yield call(
      request,
      API.EditShareholderPerformance(companyId, shareholderId),
      'PUT',
      params,
    );

    yield put(editShareholderPerformanceSuccess({ ...data }));
    yield put(hideLoader());

    yield put(
      showModal({
        closable: true,
        component: SuccessModal,
        buttonText: 'OK',
        title: 'Shareholder records updated',
      }),
    );
  } catch (e) {
    yield put(hideLoader());
    yield put(editShareholderPerformanceError(e));
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  }
}

function* editShareholderPerformanceWatcher() {
  yield takeEvery(EDIT_SHAREHOLDER_PERFORMANCE, editShareholderPerformance);
}

export {
  getHoldings,
  getHoldingsWatcher,
  downloadShareholderCert,
  downloadShareholderCertWatcher,
  createShareholder,
  createShareholderWatcher,
  getShareholdersCriterias,
  getShareholdersDropdownData,
  getShareholdersDropdownDataWatcher,
  getShareholders,
  getShareholdersWatcher,
  getRegisteredShareholders,
  getRegisteredShareholdersWatcher,
  getTransferShareholders,
  getTransferShareholdersWatcher,
  editShareholderDetails,
  editShareholderDetailsWatcher,
  allocateShareholding,
  allocateShareholdingWatcher,
  transferShareholding,
  transferShareholdingWatcher,
  shareholderTransactionRevert,
  shareholderTransactionRevertWatcher,
  updateShareholderTransactions,
  updateShareholderTransactionsWatcher,
  downloadShareholderRegistry,
  downloadShareholderRegistryWatcher,
  getBulkWelcomeEmailInfo,
  getBulkWelcomeEmailInfoWatcher,
  sendBulkWelcomeEmail,
  sendBulkWelcomeEmailWatcher,
  lookupShareholder,
  lookupShareholderWatcher,
  addTransactionNote,
  addTransactionNoteWatcher,
  updateTransactionNote,
  updateTransactionNoteWatcher,
  removeTransactionNote,
  removeTransactionNoteWatcher,
  searchShareholders,
  searchShareholdersWatcher,
  editShareholderPerformanceWatcher,
};
