const updateItemPosition = item => ({ ...item, position: item.position + 1 });

const reorderItems = (items, item, affectedItem) => {
  const filtered = items.filter(({ id }) => ![item.id, affectedItem.id].includes(id));
  const movingItemUp = item.position > affectedItem.position;
  const breakpoint = movingItemUp ? affectedItem.position - 1 : affectedItem.position - 2;
  const firstPart = filtered.slice(0, breakpoint);
  const secondPart = filtered.slice(breakpoint).map(updateItemPosition);

  if (movingItemUp) {
    return [
      ...firstPart,
      { ...item, position: affectedItem.position },
      { ...affectedItem, position: item.position },
      ...secondPart,
    ];
  }

  return [
    ...firstPart,
    { ...affectedItem, position: item.position },
    { ...item, position: affectedItem.position },
    ...secondPart,
  ];
};

const getReorderedItemsAfterSubItemParentChanging = (items, item, affectedItem) =>
  items.map(navItem => {
    if (navItem.id === (affectedItem.parentId || affectedItem.id)) {
      const { childs = [] } = navItem;
      const draggedItem = { ...item, position: childs.length + 1 };

      return {
        ...navItem,
        childs: [...childs, draggedItem],
        hasSubnavigationItem: true,
        hasPageContent: false,
      };
    }

    if (navItem.id === item.parentId) {
      const childs = navItem.childs.filter(elem => elem.id !== item.id);
      const hasSubnavigationItem = Boolean(childs.length);

      return {
        ...navItem,
        childs,
        hasSubnavigationItem,
        hasPageContent: !hasSubnavigationItem,
      };
    }

    return navItem;
  });

const getReorderedItemsAfterSubItemIsMoved = (items, item, affectedItem) =>
  items.map(navItem => {
    if (navItem.id === item.parentId) {
      return { ...navItem, childs: reorderItems(navItem.childs, item, affectedItem) };
    }

    return navItem;
  });

export {
  reorderItems,
  getReorderedItemsAfterSubItemParentChanging,
  getReorderedItemsAfterSubItemIsMoved,
};
