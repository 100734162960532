import { createSelector } from 'reselect';

import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

export const getShareOptions = state => state.shareOptions;
export const getHolders = state => state.holders;
export const getGrantHolders = state => state.grantHolders;
export const getGrantHoldersCapital = state => state.grantHoldersCapital;
export const getGrantTransactionalHistory = state => state.grantTransactionalHistory;
export const getShareOptionPlan = state => state.shareOptionPlan;
export const getShareOptionPlanLoadingFlag = createSelector(
  getShareOptionPlan,
  ({ loading }) => loading,
);

export const getShareOptionData = state => state.shareOptionData;
export const getShareOptionDataError = state => state.shareOptionData.error;

export const getExerciseShareOptions = state => state.exerciseShareOptions;
export const getExerciseShareOptionsError = state => state.exerciseShareOptions.error;
export const getExerciseStatusFlag = createSelector(
  getExerciseShareOptions,
  ({ loading, data }) => !loading && data,
);

export const getShareOptionPlanDocumentsError = state => state.shareOptionPlanDocuments.error;
export const getShareOptionPlanDocumentsErrorMsg = createSelector(
  getShareOptionPlanDocumentsError,
  err =>
    err &&
    (err.response.details && err.response.details.file
      ? err.response.details.file[0]
      : defaultErrorMessage),
);
export const getShareOptionPlanDocuments = createSelector(
  state => state.shareOptionPlanDocuments,
  getShareOptionPlanDocumentsErrorMsg,
  (data, errorMessage) => ({ ...data, errorMessage }),
);

export const getGrant = state => state.grant;

export const checkIfGrantIsAdding = state => state.newGrantAddingState.loading;
// export const getGrantAddingData = state => state.newGrantAddingState.data;
// export const getGrantAddingError = state => state.newGrantAddingState.error;
export const getGrantHoldersEmails = state => state.grantHoldersEmails;

export const getHoldersSearch = state => state.searchHoldersData;
export const getHoldersSearchData = createSelector(getHoldersSearch, ({ data }) => data);
export const getHoldersSearchEmails = createSelector(getHoldersSearchData, data =>
  data.map(({ grantHolder: { email } }) => email),
);

export const getGrantHolderDetails = state => state.grantHolderDetails;
export const getGrantHolderDetailsLoadingFlag = createSelector(
  getGrantHolderDetails,
  ({ loading }) => loading,
);
export const getGrantHolderDetailsInGrantForm = createSelector(
  getGrantHolderDetails,
  ({ data: { grantHolder, address } }) => ({ data: { grantHolder: { grantHolder, address } } }),
);
export const getGrantHolderShareOptionPlans = createSelector(
  getGrantHolderDetails,
  ({ data: { shareOptionGrants } }) => shareOptionGrants,
);
