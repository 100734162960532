import type { FC } from 'react';

import { COLORS } from 'shared-parts/constants';

type FilledWarningIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const FilledWarningIcon: FC<React.PropsWithChildren<FilledWarningIconProps>> = ({
  width = 19,
  height = 22,
  fillColor = COLORS.systemRed,
}) => (
  <svg
    id="Component_17_80"
    data-name="Component 17 – 80"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 19"
  >
    <path
      id="Polygon_1"
      data-name="Polygon 1"
      d="M9.269,2.99a2,2,0,0,1,3.462,0L20.262,16a2,2,0,0,1-1.731,3H3.469a2,2,0,0,1-1.731-3Z"
      fill={fillColor}
    />
    <path
      id="Path_1740"
      data-name="Path 1740"
      d="M.518-9.884l1.937.01-.5,4.968H1.007ZM2.33-4.2v1.666H.643V-4.2Z"
      transform="translate(9.787 17.913)"
      fill={COLORS.white}
    />
  </svg>
);

export default FilledWarningIcon;
