import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

/* eslint-disable */
// REMOVE THIS ESLINT DISABLE WHEN MULTIPLE REDUCERS ARE EXPORTED
export const initialState = getReducerState({ data: [] });

export const subscriptionTemplatesPerCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY:
      return initialState;
    case constants.GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_ERROR:
      return { loading: false, data: [], error: action.error };
    default:
      return state;
  }
};

export const subscriptionTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SUBSCRIPTION_TEMPLATES:
      return initialState;
    case constants.GET_SUBSCRIPTION_TEMPLATES_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_SUBSCRIPTION_TEMPLATES_ERROR:
      return { loading: false, data: [], error: action.error };
    default:
      return state;
  }
};
