import { DEFAULT_SEARCH_CONFIGURATION } from 'shared-parts/components/select/constants';
import { COLORS } from 'shared-parts/constants';

import {
  getClassificationCodeInput,
  getClassificationCodeSelect,
} from './cs01-form/classification-codes/array-fields-renderer';
import { SwitchableFieldsWrapper } from './cs01-form/cs01-form.styled';
import type { FilingFilter, FilingStatus, StatusColors, StatusLabels } from './types';

const { primary, systemGreen, secondary, systemRed } = COLORS;

export const STATUS_LABELS: StatusLabels = {
  submitted: 'Submitted',
  approved: 'Approved',
  rejected: 'Rejected',
};

export const STATUS_COLORS: StatusColors = {
  submitted: secondary,
  approved: systemGreen,
  rejected: systemRed,
};

export const FILTERS: FilingFilter[] = [
  { label: STATUS_LABELS.submitted, value: 'submitted', color: primary },
  { label: STATUS_LABELS.approved, value: 'approved', color: systemGreen },
  { label: STATUS_LABELS.rejected, value: 'rejected', color: systemRed },
];

const DEFAULT_STATUSES: FilingStatus[] = ['submitted', 'approved', 'rejected'];
export const DEFAULT_FILTERS_STRING = DEFAULT_STATUSES.join(',');

export const TABLE_HEADER = [
  { name: 'Form Code', sorting: { param: 'form_code' } },
  { name: 'Form Name', sorting: { param: 'form_name' } },
  { name: 'Submitted To', sorting: { param: 'submitted_to' } },
  { name: 'Date Submitted', sorting: { param: 'submitted_at' } },
  { name: 'Fee', sorting: { param: 'fee' } },
  { name: 'Submitted By', sorting: { param: 'submitted_by' } },
  { name: 'Status' },
  { name: '', styles: 'width: 42px;' },
];

export const LANDING_MESSAGE_HEADER = 'You must provide a Companies House authentication code';

export const LANDING_MESSAGE = `
  You would have received this from Companies House at incorporation.
  If you don’t have this code please contact Companies House.
`;

export const DEFAULT_SORTING_ORDER = '-submitted_at';

export const FILING_SUMMARY = 'FILING_SUMMARY';
export const FILING_DOCUMENTS = 'FILING_DOCUMENTS';
export const FILING_DETAILS_TABS = [
  { key: FILING_SUMMARY, name: 'Summary' },
  { key: FILING_DOCUMENTS, name: 'Documents' },
];

export const COMBINED_DOCUMENTS_SIZE_LIMIT = 52428800; // 50Mb
export const FILE_SIZE_ERROR = 'Combined files size for all attachments can’t be more than 50 Mb';

export const DOCUMENTS_LIST_TITLE = 'Documents:';
export const UPLOAD_BUTTON_TEXT = 'Upload a Document';
export const BY = 'By:';

export const DELETE_MODAL_TITLE = 'DELETE DOCUMENT';
export const DELETE_MODAL_TEXT = 'Are you sure you want to delete this document?';

export const CS01_CONFIRMATION_STATEMENT = 'CS01 Confirmation Statement (Companies House)';
export const SH01_RETURN_OF_ALLOTMENT = 'SH01 Return of allotment of shares (Companies House)';

export const RADIO_OPTION_YES = 'Yes';
export const RADIO_OPTION_NO = 'No';

export const RADIO_OPTION_YES_VALUE = 'yes';
export const RADIO_OPTION_NO_VALUE = 'no';

export const COMPANY_NAME_LABEL = 'Company name:';
export const COMPANY_NUMBER_LABEL = 'Company number:';
export const LAST_STATEMENT_DATE_LABEL = 'Last statement dated:';

export const CURRENT_SIC_LABEL_SINGULAR =
  'Your current Standard industrial classification (SIC) is:';
export const CURRENT_SIC_LABEL_PLURAL =
  'Your current Standard industrial classifications (SIC) are:';
export const SIC_CHANGED_LABEL_SINGULAR =
  'Has this changed since your last confirmation statement on:';
export const SIC_CHANGED_LABEL_PLURAL =
  'Have any of these changed since your last confirmation statement on:';

export const DTR_5_APPLIES_LABEL =
  'Throughout the confirmation period, was the company a traded company and an issuer to which DTR5 applies?';

export const DTR_5_APPLIES_TOOLTIP = `
  DTR5 refers to the Vote Holder and Issuer Notification Rules contained in Chapter 5 of the
  Disclosure and Transparency Rules source book issued by the Financial Conduct Authority.
`;

export const PSC_REGISTER_STATEMENT_VALUES_EEA = 'eea';
export const PSC_REGISTER_STATEMENT_VALUES_UK = 'uk';
export const PSC_REGISTER_STATEMENT_VALUES_SCHEDULE = 'schedule1';

export const EXEMPT_FROM_PSC_REGISTER_LABEL =
  'Is the company exempt or has it ever been exempt from keeping a PSC register?';

export const PSC_REGISTER_STATEMENT_LABEL = 'Please select the appropriate statement:';

export const PSC_REGISTER_STATEMENT_UK = `
  The company is exempt from the requirement to obtain information and keep a register of its PSC
  because the company has voting shares admitted to trading on a regulated market in the United Kingdom
`;

export const PSC_REGISTER_STATEMENT_EEA = `
  The company is exempt from the requirement to obtain information and keep a register of its PSC
  because the company has voting shares admitted to trading on a regulated market in an EEA State
  other than the United Kingdom
`;

export const PSC_REGISTER_STATEMENT_SCHEDULE_1 = `
  The company is exempt from the requirement to obtain information and keep a register of its PSC
  because the company has voting shares admitted to trading on a market listed in Schedule 1 of the
  Register of People with Significant Control Regulations 2016
`;

export const SHARES_ADMITTED_TO_TRADING_LABEL =
  'Were any shares admitted to trading on a market during the confirmation period?';

export const SHARES_ADMITTED_TO_TRADING_TOOLTIP = [
  `
    A market is one established under the rules of a UK recognised investment exchange or
    any other regulated markets in or outside of the UK. Please see the current UK
  `,
  ' and ',
  ' for more info.',
];

export const REGULATED_MARKETS_LINK = 'regulated markets';

export const RECOGNISED_INVESTMENT_EXCHANGES_LINK = 'recognised investment exchanges';

export const SHARES_ADMITTED_NOTE =
  'Please note: shares sold or transferred via the Globacap platform do not constitute trading on a market.';

export const SHAREHOLDER_INFORMATION_CHANGES_NOTE =
  'There have been changes to shareholder information since your last confirmation statement.';

export const SHAREHOLDER_INFORMATION_NO_CHANGES_NOTE =
  'There have been no changes to shareholder information since your last confirmation statement.';

export const SHAREHOLDER_NOT_DTR5_CHANGES_NOTE = `
  Here are details of any changes to the information (since you last gave it)
  about people who held at least 5% of the issued shares of any class at the end
  of the confirmation period.
`;

export const SHAREHOLDER_NOT_DTR5_CHANGES_NOTE_2 =
  'Shareholders are listed in alphabetical order. Joint shareholders are listed consecutively.';

export const PRESCRIBED_PARTICULARS_NO_CHANGES_NOTE = `
  There have been no changes to your prescribed particulars
  since the last statement of capital was delivered.
`;

export const CLASS_OF_SHARES_LABEL = 'Class of shares:';

export const PRESCRIBED_PARTICULARS_LABEL = 'Prescribed particulars:';

export const PRESCRIBED_PARTICULARS_TOOLTIP = {
  HEADER: 'Prescribed particulars (Prescribed particulars or rights attached to shares)',
  LIST_LABEL: 'The particulars are:',
  A: 'Particulars of any voting rights, including rights that arise only in certain circumstances;',
  B: 'Particulars of any rights, as respects dividends, to participate in distribution;',
  C: 'Particulars of any rights, as respects capital, to participate in distribution (including of winding up); and',
  D: 'Whether the shares are to be redeemed or are liable to be redeemed at the option of the company or the shareholder.',
};

export const FROM_DATE_LABEL = 'From date:';
export const TO_DATE_LABEL = 'To date:';

export const SHARES_ALLOTTED_NOTE = (
  <>
    If all shares were allotted on the same day enter that date in the&nbsp;
    <strong>‘From date’</strong> box.
    <br />
    If shares were allotted over a period of time, complete both&nbsp;
    <strong>‘From date’</strong> and <strong>‘To date’</strong> boxes.
  </>
);

const SIC_SELECT_INDEXED_PROPERTIES = ['text', 'value'];

export const SIC_SELECT_SEARCH_OPTIONS = {
  ...DEFAULT_SEARCH_CONFIGURATION,
  fields: SIC_SELECT_INDEXED_PROPERTIES,
};

export const NEW_SIC_CODES_ARRAY_PROPS = {
  switchable: false,
  renderField: getClassificationCodeSelect,
};

export const OLD_SIC_CODES_ARRAY_PROPS = {
  switchable: true,
  container: SwitchableFieldsWrapper,
  renderField: getClassificationCodeInput,
};

export const CH_ERROR_MODAL = {
  TITLE: 'Something Went Wrong',
  TEXT: 'Unfortunately we can’t complete your submission right now. Please try again in a few minutes',
};

export const CH_SUCCESS_MODAL = {
  TITLE: 'Submitted to Companies House',
  CS01_SUBMITTED_MESSAGE: [
    'CS01 Confirmation Statement successfully submitted to Companies House',
    'We’ll debit the £13.00 Companies House fee for submitting this form from the payment details we hold on file.',
  ],
  SH01_SUBMITTED_MESSAGE:
    'SH01 Submission of Return of allotment of shares successfully submitted to Companies House',
  BUTTON: 'Take me to filings',
};

export const CS01_CONFIRMATION_TEXT = `
  I confirm that all information required to be delivered by the company pursuant to
  section 853A(1)(a) of the Companies Act 2006 in relation to the confirmation period
  either has been delivered or is being delivered with this statement.
`;

export const CS01_LAWFUL_CONFIMATION_TEXT = `
The company confirms that the intended future activities of the company are lawful.
`;

export const REMOVE_SIC_CODES_TEXT = 'Please remove any SIC codes which no longer apply';

export const CLASSIFICATION_CODE_LABEL = 'Classification code';

export const ALLOTTED_SHARES_NOTE =
  'Here are details of the shares allotted, including bonus shares:';
export const NO_SHARES_ALLOTTED_NOTE = 'There are no new allotted shares to submit.';

export const SH01_SHARE_CAPITAL_NOTE = `The table(s) below show the issued share capital at the
 date to which this return is made up.`;

export const SH01_CONFIRMATION_TEXT = 'I confirm that all information required has been provided.';
