import {
  FETCH_DEAL_IOI_DETAILS,
  FETCH_DEAL_IOI_DETAILS_ERROR,
  FETCH_DEAL_IOI_DETAILS_SUCCESS,
  FETCH_INDICATION_OF_INTEREST_DATA,
  FETCH_INDICATION_OF_INTEREST_DATA_ERROR,
  FETCH_INDICATION_OF_INTEREST_DATA_SUCCESS,
  RESET_DEAL_IOI_DETAILS,
  RESET_INDICATION_OF_INTEREST_DATA,
} from './constants';

const initialIndicationOfInterestState = {
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
        totalObjects: 0,
      },
      order: 'intended_amount',
    },
  },
  errors: null,
};

export const indicationOfInterestReducer = (
  state = initialIndicationOfInterestState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_INDICATION_OF_INTEREST_DATA: {
      const { page, perPage, totalPages, totalObjects, order } = payload;
      return {
        ...initialIndicationOfInterestState,
        data: {
          items: [],
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
            order,
          },
        },
      };
    }
    case FETCH_INDICATION_OF_INTEREST_DATA_SUCCESS:
      return {
        loading: false,
        data: {
          items: payload.items,
          meta: {
            pagination: payload.meta.pagination,
            order: state.data.meta.order,
            targetAmount: payload.meta.targetAmount,
            targetPercentAchieved: payload.meta.targetPercentAchieved,
            totalIois: payload.meta.totalIois,
            totalValue: payload.meta.totalValue,
            averageValuation: payload.meta.averageValuation,
          },
        },
        errors: null,
      };
    case FETCH_INDICATION_OF_INTEREST_DATA_ERROR:
      return { ...state, loading: false, errors: payload };
    case RESET_INDICATION_OF_INTEREST_DATA:
      return initialIndicationOfInterestState;
    default:
      return state;
  }
};

const initialDealIOIDetailsState = {
  loading: true,
  data: {
    adjustmentHistory: [],
  },
  errors: null,
};

export const dealIOIDetailsReducer = (state = initialDealIOIDetailsState, { type, payload }) => {
  switch (type) {
    case FETCH_DEAL_IOI_DETAILS:
    case RESET_DEAL_IOI_DETAILS:
      return initialDealIOIDetailsState;
    case FETCH_DEAL_IOI_DETAILS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case FETCH_DEAL_IOI_DETAILS_ERROR:
      return { ...state, loading: false, errors: payload };
    default:
      return state;
  }
};
