import styled from 'styled-components';

import { Link } from 'shared-parts/components';
import { FormikButton as Button } from 'shared-parts/components/button';
import { Container as InputContainer } from 'shared-parts/components/text-input/underlined-text-input.styled.js';
import { COLORS, FONTS } from 'shared-parts/constants';
import { PolicyCheckbox } from 'shared-parts/modules/auth/login/components/form.styled';

const { neutral300, neutral800, alternative, primary, neutral700 } = COLORS;

export const CloseLink = styled(Link)`
  position: absolute;
  right: 47px;
  top: 32px;
`;

export const OuterContainer = styled.div`
  align-items: center;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

export const AuthContainerFormWrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  width: 400px;
  padding: 30px;
  border: 1px solid ${COLORS.neutral300};
  border-radius: 3px;
`;

export const AuthContainerFormWrapperAlternative = styled.div`
  font-family: ${FONTS.mainFont};
  height: 655px;
  text-align: left;
  width: 492px;

  border: 1px solid ${neutral300};
  box-shadow: 5px 5px 10px #9a9a9a26;
  border-radius: 6px;
  padding: 48px 44px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :first-child::after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${neutral300};
    width: 100%;
  }

  ${InputContainer} {
    background-position: 380px;
  }
`;

const ActionButton = styled(Button)`
  height: 40px;
  width: 120px;
  user-select: none;
  color: ${({ disabled }) => (disabled ? neutral700 : primary)};
  background-color: ${({ disabled }) => (disabled ? neutral300 : alternative)};
`;

export const palette: Record<string, any> = {
  accentColorUntouched: neutral300,
  textColor: neutral800,
  placeholderColor: neutral800,
  showPasswordColor: neutral700,
  showPasswordFontWeight: 300,
  PolicyCheckboxComponent: styled(PolicyCheckbox)`
    margin: 28px 0px;
    input:checked ~ span {
      border-color: transparent;
    }
    .link > a {
      color: initial;
      text-decoration-color: ${alternative};
    }
  `,
  LoginButtonComponent: ActionButton,
  SignUpButtonComponent: ActionButton,
  ResetPasswordButtonComponent: styled(ActionButton)`
    margin-top: 32px;
    width: auto;
  `,
  SubmitButtonComponent: styled(ActionButton)`
    margin-top: 32px;
  `,
};
