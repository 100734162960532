import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

import type { StyledStatusPropTypes } from '../types';

const { systemGreen, systemRed } = COLORS;

export const StyledStatus = styled.span<StyledStatusPropTypes>`
  color: ${({ value }) => (value ? systemGreen : systemRed)};
`;
