export const SHARE_CLASS_TABLE_HEADER = [
  { name: 'Share Class' },
  { name: '№ Shares' },
  { name: 'Nominal Share Price' },
  { name: 'Equity %' },
  { name: 'Fully-Diluted %' },
  { name: 'Indicative Total Share Value' },
  { name: 'Voting Rights' },
  { name: 'Dividends' },
  { name: 'Share Options Granted' },
];

export const BULK_FUNCTIONS_TEXT = 'Convert Shares';
export const BULL_FUNCTIONS_SUBMIT_MODAL_HEADER = 'CAUTION: YOU ARE CONVERTING SHARES';

export const PRECISION_TYPE_OPTIONS = [
  { text: 'Whole', value: 'whole' },
  { text: 'Fractional', value: 'fractional' },
];

export const SET_A_NOMINAL_SHARE_PRICE_OPTIONS = [
  { text: 'Yes', value: 'yes' },
  { text: 'No', value: 'no' },
];

export const ADD_SHARE_CLASS_HEADER = 'Add New Share Class';

export const EDIT_SHARE_CLASS_HEADER = 'Edit Share Class';
export const EDIT_SHARE_CLASS_MODAL_HEADER = 'YOU ARE ABOUT TO CHANGE YOUR SHARE CLASS';

export const LANDING_MESSAGE_HEADER = 'Time to add in your first share class!';
export const LANDING_MESSAGE_BUTTON_TEXT = 'ADD SHARE CLASS';

export const CREATE = 'CREATE';
export const SAVE = 'SAVE';

export const FRACTIONAL = 'fractional';
export const WHOLE = 'whole';

export const ROUNDING_ERROR = 'Rounding is required';

export const SHARE_CLASS_VALIDATION_SCHEMA_ERRORS = {
  className: {
    required: 'Share class name is required',
    unique: 'Given share class for the given Company already exists',
  },
  dateCreated: {
    required: 'Date Created is required',
    dateValidation: 'Date Created must be a date',
    prohibitFuture: 'Date Created cannot be in future',
  },
  nominalSharePrice: {
    required: 'Nominal share price is required',
    positive: 'Nominal share price must be greater than 0',
  },
  currency: {
    required: 'Currency is required',
  },
  precisionType: {
    precisionRequiredOrNullable: 'Precision is required',
  },
  roundingStrategy: {
    roundingStrategyRequiredOrNullable: 'Rounding strategy is invalid',
  },
  transactionDate: {
    required: 'Transaction date is required',
    isValidDate: 'Transaction date must be a date',
    prohibitFuture: 'Transaction date cannot be in future',
  },
  calculationMethod: {
    required: 'Calculation method is required',
  },
  indicativeSharePrice: {
    required: 'Share price is required',
    positive: 'Share price must be greater than 0',
  },
};

export const CONVERT_SHARES_VALIDATION_SCHEMA_ERRORS = {
  fromShareClassId: {
    required: 'Share Class you wish to convert is required',
  },
  toShareClassId: {
    required: 'Share Class you wish to convert to is required',
  },
  roundingStrategy: {
    required: 'Rounding is required',
  },
  transactionDate: {
    required: 'Transaction date is required',
    dateValidation: 'Transaction date must be a date',
    prohibitFuture: 'Transaction date cannot be in future',
  },
};

const CHOICES = [
  { text: 'Yes', value: 'yes' },
  { text: 'No', value: 'no' },
];

export const RADIO_SECTIONS = [
  { name: 'hasVotingRights', title: 'Voting Rights?*', choices: CHOICES },
  { name: 'hasDividends', title: 'Dividends?*', choices: CHOICES },
];

export const SHARE_CLASS_DETAILS_NAME = 'Share class details';
export const PRESCRIBED_PARTICULARS = 'Prescribed particulars';

export const CONFIRMATION_FORM_NOTICE_ONE =
  'This action may require certain resolutions, approvals or additional filings in your jurisdiction of incorporation. You must ensure that this action is carried out in accordance with your constitutional documents and applicable laws and requirements.';
export const CONFIRMATION_FORM_NOTICE_TWO =
  'If you are in any doubt about this action or the requirements associated with this action, please speak with your professional advisors.';
export const CONFIRMATION_FORM_TEXT = 'Before proceeding please confirm that:';
export const CONFIRMATION_FORM_CHECKBOX_ONE_TEXT =
  'You are fully aware of the legal requirements and required approvals (for instance shareholder or board approvals), and that those requirements have been satisfied;';
export const CONFIRMATION_FORM_CHECKBOX_TWO_TEXT =
  'You are aware of and will satisfy any notice or filing requirements (if any) associated with this action and you acknowledge that the Globacap platform will not do this for you.';
export const CANCEL = 'CANCEL';
export const OK = 'OK';

export const ROUNDING_LABELS = {
  NORMAL: 'Normal rounding',
  ROUNDUP: 'Round up',
  TRUNCATE: 'Round down',
};

export const CONVERSION_TABS = [{ key: 'conversionDetails', name: 'Conversion Details' }];
export const REVIEW_SHARE_CONVERSION_DETAILS = 'Review Share Conversion Details';
export const CONVERSION_MESSAGE =
  'This will convert all shares in the selected share class. This process cannot be undone.';
export const FROM_SHARE_CLASS_ID_LABEL = 'Select the share class you wish to convert:*';
export const TO_SHARE_CLASS_ID_LABEL = 'Select the share class you wish to convert to:*';
export const ADD_NEW_SHARE_CLASS = 'Add a new share class';
export const ROUNDING_STRATEGY_LABEL = 'Rounding (no. of shares):*';
export const TRANSACTION_DATE_LABEL = 'Transaction Date:*';
export const CONVERT_SHARE_CLASS = 'Convert Share Class';
export const NEXT = 'NEXT';

export const CONVERT_MODAL_NOTICE =
  'You are converting all shares in the share class. This cannot be undone. Are you sure you wish to proceed?';

export const SUMMARY = 'Summary';
export const CONVERTING = 'Converting: ';
export const TO = 'To: ';
export const NOMINAL_SHARE_PRICE_WILL_CHANGE_FROM = 'Nominal share price will change from: ';
export const NUMBER_OF_SHARES_WILL_CHANGE_FROM = 'Number of shares will change from: ';
export const VOTING_RIGHTS_WILL_CHANGE_FROM = 'Voting rights will change from: ';
export const DIVIDEND_RIGHTS_WILL_CHANGE_FROM = 'Dividend rights change from: ';
export const ROUNDING_NUMBER_OF_SHARES = 'Rounding (no. of shares): ';
export const TRANSACTION_DATE = 'Transaction Date: ';

export const SHARE_CLASS = 'Share Class';
export const SHARE_CLASS_LABEL = 'Share Class:*';
export const PRECISION_TYPE_TITLE = 'Precision (number of shares):*';
export const DECIMAL_PLACES = 'Decimal places';
export const NOMINAL_SHARE_PRICE = 'Nominal Share Price';
export const CURRENCY = 'Currency';
export const CURRENCY_LABEL = 'CCY:*';
export const SET_A_NOMINAL_SHARE_PRICE = 'Set a Nominal Share Price?';

export const PRESCRIBED_PARTICULARS_DETAILS = 'Prescribed particulars details:';
export const EDIT = 'Edit';

export const ADD_PRESCRIBED_PARTICULARS_LATER = 'Add prescribed particulars later';
export const ADD_PRESCRIBED_PARTICULARS_NOW = 'Add prescribed particulars now';
export const CURRENCY_NAMESPACE = 'deal_fiat';
