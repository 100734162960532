import { ComponentProps, ReactNode } from 'react';

import {
  CONFIRM_EMAIL,
  CREATE_PASSWORD,
  HIDE_MODAL,
  HIDE_OVERLAY,
  HIDE_SIDE_FORM,
  PING,
  REMOVE_REDIRECT_PATH,
  RESEND_EMAIL_CURRENT,
  RESEND_EMAIL_CURRENT_ERROR,
  SAVE_REDIRECT_PATH,
  SHOW_MODAL,
  SHOW_OVERLAY,
  SHOW_SIDE_FORM,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_ERROR,
  UPDATE_MY_DETAILS,
  UPDATE_NICKELLED_USER,
} from 'app-state/constants/shared';

type ComponentAndProps<T extends React.ComponentType<React.PropsWithChildren<any>>> = {
  component: T;
} & Partial<ComponentProps<T>>;

export const createPassword = (formData: any, token: any, setSubmitting: any, setErrors: any) => ({
  type: CREATE_PASSWORD,
  formData,
  token,
  setSubmitting,
  setErrors,
});
export const confirmEmail = (emailToken: string) => ({ type: CONFIRM_EMAIL, emailToken });
export const resendEmailForCurrentUser = () => ({ type: RESEND_EMAIL_CURRENT });
export const resendEmailForCurrentUserError = (error: any) => ({
  type: RESEND_EMAIL_CURRENT_ERROR,
  error,
});

export const updateMyDetails = (data: any, form: any, showEmailModal: boolean) => ({
  type: UPDATE_MY_DETAILS,
  data,
  form,
  showEmailModal,
});
export const updateCurrentUser = (data: any) => ({ type: UPDATE_CURRENT_USER, data });
export const updateCurrentUserError = (error: any) => ({ type: UPDATE_CURRENT_USER_ERROR, error });

type ShowModalActionProps<T extends React.ComponentType<React.PropsWithChildren<any>>> =
  ComponentAndProps<T> & {
    showHeader?: boolean;
    header?: ReactNode;

    purpleCloseButton?: boolean;
    danger?: boolean;
    minHeight?: number;
    showOverflow?: boolean;

    closable?: boolean;
    customHideModal?: () => void;
  };

export const showModal = <T extends React.ComponentType<React.PropsWithChildren<any>>>(
  data: ShowModalActionProps<T>,
) => ({
  type: SHOW_MODAL,
  data,
});
export const hideModal = () => ({ type: HIDE_MODAL });

type ShowSideFormProps<T extends React.ComponentType<React.PropsWithChildren<any>>> = {
  header: ReactNode;
  info?: ReactNode;
  width?: number | string;
} & ComponentAndProps<T>;

export const showSideForm = <T extends React.ComponentType<React.PropsWithChildren<any>>>(
  data: ShowSideFormProps<T>,
) => ({
  type: SHOW_SIDE_FORM,
  data,
});
export const hideSideForm = () => ({ type: HIDE_SIDE_FORM });

export const showOverlay = (data: any) => ({ type: SHOW_OVERLAY, data });
export const hideOverlay = () => ({ type: HIDE_OVERLAY });

export const saveRedirectPath = (route: string) => ({ type: SAVE_REDIRECT_PATH, route });
export const removeRedirectPath = () => ({ type: REMOVE_REDIRECT_PATH });

export const ping = () => ({ type: PING });

export const updateNickelledUser = () => ({ type: UPDATE_NICKELLED_USER });
