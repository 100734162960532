import { COLORS } from 'shared-parts/constants';

const { systemGreen, systemRed } = COLORS;

export const BUTTON_TYPES = {
  ADD: 'add',
  REMOVE: 'remove',
};

export const BUTTON_CONTENT_CONFIG = {
  [BUTTON_TYPES.ADD]: '+',
  [BUTTON_TYPES.REMOVE]: '-',
};

export const BUTTON_COLOR_CONFIG = {
  [BUTTON_TYPES.ADD]: systemGreen,
  [BUTTON_TYPES.REMOVE]: systemRed,
};

export const BUTTON_DEFAULT_PROPS = {
  [BUTTON_TYPES.ADD]: {
    'data-e2e': 'plus-button',
  },
  [BUTTON_TYPES.REMOVE]: {
    'data-e2e': 'minus-button',
  },
};
