import './application.sass';
import 'normalize.css';

import { createRoot } from 'react-dom/client';
import { sessionService } from 'redux-react-session';

import { User } from '@frontend/auth';
import { initializeOpenFeature, OpenFeatureProvider } from '@frontend/config';
import { LicenseInfo, ThemeProvider } from '@frontend/design-system';

import store from 'app-state/store';

import GlobalStyle from './global-style';
import Router from './router';
import { theme } from './theme';

if (typeof window.config.DATA_GRID_LICENSE_KEY === 'string') {
  LicenseInfo.setLicenseKey(window.config.DATA_GRID_LICENSE_KEY);
}

initializeOpenFeature();

function renderWebsiteContent(): void {
  const app = document.getElementById('content');

  if (!app) {
    throw new Error('Could not find content root.');
  }

  const root = createRoot(app);
  root.render(
    <ThemeProvider theme={theme}>
      <OpenFeatureProvider>
        <GlobalStyle />
        <Router />
      </OpenFeatureProvider>
    </ThemeProvider>,
  );
}

const validateSession = () =>
  sessionService.loadUser().then((user: User) => Boolean(user.companyIds));

sessionService
  .initSessionService(store, {
    driver: 'COOKIES',
    validateSession,
    // TODO: remove optional refreshOnCheckAuth and expires when @types/redux-react-session fixed.
    refreshOnCheckAuth: undefined as unknown as boolean,
    expires: undefined as unknown as number,
  })
  .then(renderWebsiteContent);
