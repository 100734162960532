import { useEnvVar } from '@frontend/config';

import * as AccessPermissions from 'constants/access-permissions';
import LOGIN_REDIRECTION_URL from 'constants/login-redirection-url';
import Routes from 'constants/routes';
import DEAL_MANAGEMENT_MENU_ITEMS from 'constants/user-data/deal-management-menu-items';
import DEALS_PAGE_MENU_ITEMS from 'constants/user-data/deals-page-menu-items';
import { TABLE_HEADER, TABLE_KEYS } from 'constants/user-data/deals-table';
import INVESTORS_PAGE_MENU_ITEMS from 'constants/user-data/investors-page-menu-items';
import { FeatureFlag, useFeatureFlag } from 'providers/app-config';
import { insertIf } from 'helpers';
import { checkAccessByCompany } from 'helpers/check-access';
import { COLORS, tableColumnStyles } from 'shared-parts/constants';
import { BE_STATUSES } from 'shared-parts/constants/order-statuses';
import { addCurrencySign, toDefinedFractionSizeNoRound } from 'shared-parts/helpers/format-number';
import { getOrderStatus } from 'shared-parts/helpers/order-statuses';

const { systemGreen, systemRed } = COLORS;

const areCompanyDetailsVisible = !useEnvVar('DISABLED_COMPANY_DETAILS');
const isIntegrationsEnabled =
  useFeatureFlag(FeatureFlag.LIQUIDITY_ROUND) &&
  useFeatureFlag(FeatureFlag.ENABLED_LIQUIDITY_SIGNATURE_DOCUMENTS);

export default Object.freeze({
  Operations: {
    'Deal Setup URL': ({ criteria: { companyId, modelUuid, tab } }) =>
      Routes.Globacap.DealSetup(companyId, modelUuid, tab),
    'Deal Settings URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.Globacap.DealSettings(companyId, dealUuid),
    'Add New Deal Url': ({ criteria: { companyId, modelUuid } }) =>
      Routes.Globacap.AddNewDeal(companyId, modelUuid),
    'New Deal Creation Url': ({ criteria: { companyId } }) => Routes.Globacap.CreateDeal(companyId),
    'New Investment Fund Creation Url': ({ criteria: { companyId } }) =>
      Routes.Globacap.CreateInvestmentFund(companyId),
    'New Nominee Placement Creation Url': ({ criteria: { companyId } }) =>
      Routes.Globacap.CreateNomineePlacement(companyId),
    'Nominee Placement Settings URL': ({ criteria: { companyId, uuid } }) =>
      Routes.Globacap.NomineePlacementSettings(companyId, uuid),
    'Investment Fund Settings URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.Globacap.InvestmentFundSettings(companyId, dealUuid),
    'Login Redirection Url': LOGIN_REDIRECTION_URL.Operations,
    'Company Landing Page URL': ({ criteria: { company } }) =>
      Routes.Globacap.CompanyLandingPage(company?.data?.id),
    'Account Users URL': ({ criteria: { company } }) =>
      Routes.Globacap.AccountUsers(company.data.id),
    'Account Details URL': () => Routes.Globacap.MyDetails(),
    'Account Security URL': () => Routes.Globacap.AccountSecurity(),
    'Verification page menu items': () => [
      { title: 'Verification', to: Routes.Globacap.Verification() },
    ],
    'Register overview page menu items': () => [
      { title: 'Securities Overview', to: Routes.Globacap.SecuritiesOverview() },
      { title: 'Security Holders', to: Routes.Globacap.SecurityHolders() },
    ],
    'Deals page top menu header': ({ criteria: { company } }) => company.data.name,
    'Deals page menu items': () => DEALS_PAGE_MENU_ITEMS.Operations(),
    'Investors page menu items': () => INVESTORS_PAGE_MENU_ITEMS,
    'Users page menu items': ({ user }) => [
      ...insertIf(user.globacapAdmin, {
        title: 'Settings',
        to: Routes.Globacap.PlatformSettings(),
        exact: true,
      }),
      {
        title: 'User Management',
        to: Routes.Globacap.UsersPage(),
        exact: true,
      },
      // TODO: to remove flag dependency once whole feature shipped
      ...insertIf(useEnvVar('ALLOW_MULTIPLE_BANK_ACCOUNTS'), {
        title: 'Payments',
        to: Routes.Globacap.Payments(),
        exact: true,
      }),
    ],
    'My Settings page menu items': () => [
      {
        title: 'My Details',
        to: Routes.Globacap.MyDetails(),
        exact: true,
      },
      { title: 'Security', to: Routes.Globacap.AccountSecurity(), exact: true },
    ],
    'Other Vehicles URL': ({ criteria: { companyId } }) => Routes.Globacap.OtherVehicles(companyId),
    'Vehicle Investor Insights URL': ({ criteria: { companyId, assetUuid } }) =>
      Routes.Globacap.VehicleRegisterInsights(companyId, assetUuid),
    'Account menu items': ({ criteria: { company } }) => {
      const items = [
        {
          title: 'Users',
          to: Routes.Globacap.AccountUsers(company.data.id),
        },
      ];
      if (areCompanyDetailsVisible)
        items.push({
          title: 'Issuer Details',
          to: Routes.Globacap.AccountCompanyDetails(company.data.id),
          hidden: !areCompanyDetailsVisible,
        });
      if (isIntegrationsEnabled)
        items.push({
          title: 'Integrations',
          to: Routes.Globacap.AccountIntegrations(company.data.id),
        });
      return items;
    },
    ...(isIntegrationsEnabled
      ? {
          'Integrations Tab': ({ criteria: { companyId } }) =>
            Routes.Globacap.AccountIntegrations(companyId),
        }
      : {}),
    'Share Class URL': ({ criteria: { companyId } }) => Routes.Globacap.ShareClass(companyId),
    'Share Plans Url': ({ criteria: { company } }) => ({
      to: Routes.Globacap.SharePlans(company.data.id),
    }),
    'Transactions Url': ({ criteria: { companyId } }) => Routes.Globacap.AllTransactions(companyId),
    'Secondary Transactions Url': ({ criteria: { company } }) =>
      Routes.Globacap.SecondaryTransactions(company.id),
    'Grant Holders Url': ({ criteria: { companyId, shareOptionUuid } }) =>
      Routes.Globacap.GrantHolders(companyId, shareOptionUuid),
    'Share Incentive Plan URL': ({ criteria: { companyId, id } }) =>
      Routes.Globacap.ShareIncentivePlan(companyId, id),
    'Deals URL': () => Routes.Globacap.Deals(),
    'Deal URL': ({ criteria: { permalink, navigationURL, subNavigationURL } }) =>
      Routes.Globacap.Deal(permalink, navigationURL, subNavigationURL),
    'Investor List': ({ criteria: { companyId, dealUuid } }) => ({
      to: Routes.Globacap.DealList(companyId, dealUuid),
    }),
    'Invite Investors to Deal URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.Globacap.InviteInvestors(companyId, dealUuid),
    'Investor Invite Settings': ({ criteria: { companyId, dealUuid } }) =>
      Routes.Globacap.InvestorInviteSettings(companyId, dealUuid),
    'Liquidity URL': ({ criteria: { roundUuid, pageId } }) =>
      Routes.Globacap.LRPageNavigation(roundUuid, pageId),
    'Shareholders URL': ({ criteria: { companyId } }) => Routes.Globacap.Shareholders(companyId),
    'Round Orders URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Globacap.RoundOrders(companyId, roundUuid),
    'Shareholder Registry URL': ({ criteria: { companyId } }) =>
      Routes.Globacap.ShareholderRegistry(companyId),
    'Deals table header': TABLE_HEADER.Operations,
    'Deals table keys': TABLE_KEYS.Operations,
    'Deal management menu items': ({ criteria: { companyId } }) =>
      DEAL_MANAGEMENT_MENU_ITEMS.Operations({ companyId }),
    'Deal settlement table header': ({ criteria: { deal, regulatoryEnvironment } }) => [
      { name: 'Name', sorting: { param: 'investor_name' } },
      { name: 'User', sorting: { param: 'investor_user_name' } },
      { name: 'No of Shares', sorting: { param: 'intended_number_of_tokens' } },
      { name: 'Amount Funded', sorting: { param: 'filled_amount' } },
      { name: 'Total Amount', sorting: { param: 'intended_amount' } },
      {
        name: regulatoryEnvironment?.value === 'FINRA' ? 'Status' : 'Order Status',
        sorting: { param: 'status' },
      },
      { name: 'Reference' },
      { name: 'Action' },
      ...(!useEnvVar('DISABLED_DEAL_SETTLEMENT_ISSUE_SECURITIES_COLUMNS') &&
      deal.type === 'equity' &&
      deal.ownershipUnit === 'share'
        ? [{ name: '' }]
        : []),
    ],
    'Deal settlement table keys': ({
      criteria: { deal, handleIssueSecurity, regulatoryEnvironment },
    }) => [
      { field: 'investorName' },
      { field: 'investorUserName' },
      {
        field: 'intendedNumberOfTokens',
        transform: numberOfTokens => toDefinedFractionSizeNoRound(numberOfTokens, 2),
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'filledAmount',
        transform: (filledAmount, { currency }) =>
          filledAmount
            ? addCurrencySign(toDefinedFractionSizeNoRound(filledAmount, 2), currency)
            : '-',
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'intendedAmount',
        transform: (intendedAmount, { currency }) =>
          intendedAmount
            ? addCurrencySign(toDefinedFractionSizeNoRound(intendedAmount, 2), currency)
            : '-',
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'status',
        styles: (status, { reviewStatus }) => `
          color: ${getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).color};
          text-align: center;
        `,
        transform: (status, { reviewStatus }) =>
          getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).status,
      },
      { field: 'reference' },
      {
        field: 'status',
        styles: status => {
          let colorStyle = '';
          if (status === BE_STATUSES.SECURITIES_ALLOCATED) colorStyle = `color: ${systemGreen};`;
          if (status === BE_STATUSES.CANCELLED) colorStyle = `color: ${systemRed};`;

          return `${colorStyle}text-align: center;`;
        },
        transform: status => {
          if (status === BE_STATUSES.SECURITIES_ALLOCATED) return 'Securities Issued';
          if (status === BE_STATUSES.CANCELLED) return 'N/A';
        },
      },
      ...(!useEnvVar('DISABLED_DEAL_SETTLEMENT_ISSUE_SECURITIES_COLUMNS') &&
      deal.type === 'equity' &&
      deal.ownershipUnit === 'share'
        ? [
            {
              multiActions: [
                {
                  text: 'Issue Securities',
                  action: handleIssueSecurity,
                  checkIsHidden: ({ status }) =>
                    ![BE_STATUSES.FILLED, BE_STATUSES.COMPLETED].includes(status),
                },
              ],
              styles: () => `${tableColumnStyles.action}; border-left: none;`,
            },
          ]
        : []),
    ],
    'Deal Summary URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.Globacap.DealSummary(companyId, dealUuid),
    'Deal Management URL': ({ criteria: { companyId } }) =>
      Routes.Globacap.DealManagement(companyId),
    'Deal Page URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.Globacap.DealPage(companyId, dealUuid),
    'Deal summary menu items': ({
      criteria: {
        companyId,
        dealUuid,
        isAlternativeInvestmentVehicle,
        regulatoryEnvironment,
        isClosedEndFund,
      },
    }) =>
      [
        {
          title: 'Overview',
          to: Routes.Globacap.DealSummary(companyId, dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
        {
          title: 'Setup',
          to: Routes.Globacap.DealSetup(companyId, dealUuid),
        },
        {
          title: 'Investor List',
          to: Routes.Globacap.DealList(companyId, dealUuid),
        },
        {
          title: 'Indication of Interest',
          to: Routes.Globacap.DealIndicationOfInterest(companyId, dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
        {
          title: regulatoryEnvironment?.value === 'FINRA' ? 'Investment Requests' : 'Orders',
          to: Routes.Globacap.DealOrders(companyId, dealUuid),
          hidden: isClosedEndFund,
        },
        {
          title: 'Subscriptions',
          to: Routes.Globacap.FundSubscriptions(companyId, dealUuid),
          hidden: !isClosedEndFund,
        },
        {
          title: 'Settlement',
          to: Routes.Globacap.DealSettlement(companyId, dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
      ]
        .filter(({ hidden }) => !hidden)
        .map(({ title, to }) => ({ title, to })),
    'Communication menu items': ({ criteria: { company } }) => {
      const items = [];
      if (window.config?.SENDBIRD_ENABLED) {
        items.push({
          title: 'Messages',
          to: Routes.Globacap.ChatCommunication(company.data.id),
        });
      }
      if (
        !useEnvVar('DISABLED_CAP_TABLE_MANAGEMENT') &&
        !useEnvVar('ENABLE_MULTIPLE_ISSUERS_BUT_DISABLE_CAP_TABLE_PAGES')
      ) {
        items.push({
          title: 'Security Holder Communication',
          to: Routes.Globacap.Communication(company.data.id),
        });
      }

      return items;
    },
    'Liquidity Round List Url': ({ criteria: { companyId } }) =>
      Routes.Globacap.LiquidityList(companyId),
    'Liquidity Round URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Globacap.LiquidityRound(companyId, roundUuid),
    'Liquidity Round page setup URL': ({ criteria: { companyId, roundUuid, activeTab } }) =>
      Routes.Globacap.LiquidityRoundPageSetup(companyId, roundUuid, activeTab),
    'Liquidity round invites URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Globacap.LiquidityRoundInvites(companyId, roundUuid),
    'Liquidity round orders URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Globacap.LiquidityRoundOrders(companyId, roundUuid),
    'Liquidity menu items': ({ criteria: { companyId } }) => [
      {
        title: 'Liquidity Rounds',
        to: Routes.Globacap.LiquidityList(companyId),
        exact: true,
      },
    ],
    'Liquidity Overview menu items': () => [
      {
        title: 'All Liquidity Rounds',
        to: Routes.Globacap.LiquidityOverview(),
        exact: true,
      },
    ],
    'Liquidity round menu items': ({ criteria: { companyId, roundUuid } }) => [
      {
        title: 'Round Setup',
        to: Routes.Globacap.LiquidityRoundPageSetup(companyId, roundUuid),
      },
      {
        title: 'Invites',
        to: Routes.Globacap.LiquidityRoundInvites(companyId, roundUuid),
      },
      {
        title: 'Orders',
        to: Routes.Globacap.LiquidityRoundOrders(companyId, roundUuid),
      },
    ],
    'Add Liquidity Round Contract': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Globacap.LiquidityRoundAddContract(companyId, roundUuid),
    'Edit Liquidity Round Contract': ({ criteria: { companyId, roundUuid, contractUuid } }) =>
      Routes.Globacap.LiquidityRoundEditContract(companyId, roundUuid, contractUuid),
    'Contract Form URL': ({ criteria: { companyId, dealUuid, contractUuid } }) =>
      Routes.Globacap.ContractForm(companyId, dealUuid, contractUuid),
  },
  MultiCompany: {
    'Add New Deal Url': ({ criteria: { companyId, modelUuid } }) =>
      Routes.MultiCompany.AddNewDeal(companyId, modelUuid),
    'New Deal Creation Url': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.CreateDeal(companyId),
    'New Investment Fund Creation Url': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.CreateInvestmentFund(companyId),
    'New Nominee Placement Creation Url': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.CreateNomineePlacement(companyId),
    'Deal Setup URL': ({ criteria: { companyId, modelUuid, tab } }) =>
      Routes.MultiCompany.DealSetup(companyId, modelUuid, tab),
    'Deal Settings URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.MultiCompany.DealSettings(companyId, dealUuid),
    'Nominee Placement Settings URL': ({ criteria: { companyId, uuid } }) =>
      Routes.MultiCompany.NomineePlacementSettings(companyId, uuid),
    'Investment Fund Settings URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.MultiCompany.InvestmentFundSettings(companyId, dealUuid),
    'Login Redirection Url': LOGIN_REDIRECTION_URL.MultiCompany,
    'Company Landing Page URL': ({ criteria: { company } }) =>
      Routes.MultiCompany.CompanyLandingPage(company.data.id),
    'Account Users URL': ({ criteria: { company } }) =>
      Routes.MultiCompany.AccountUsers(company.data.id),
    'Account Details URL': () => Routes.MultiCompany.MyDetails(),
    'Account Security URL': () => Routes.MultiCompany.AccountSecurity(),
    'Investors page menu items': () => INVESTORS_PAGE_MENU_ITEMS,
    'Register overview page menu items': () => [
      { title: 'Securities Overview', to: Routes.MultiCompany.SecuritiesOverview() },
    ],
    'Deals page top menu header': () => null,
    'Deals page menu items': () => DEALS_PAGE_MENU_ITEMS.MultiCompany(),
    'My Settings page menu items': () =>
      [
        { title: 'My Details', to: Routes.MultiCompany.MyDetails(), hidden: false, exact: true },
        {
          title: 'Security',
          to: Routes.MultiCompany.AccountSecurity(),
          hidden: false,
          exact: true,
        },
      ].filter(({ hidden }) => !hidden),
    'Other Vehicles URL': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.OtherVehicles(companyId),
    'Vehicle Investor Insights URL': ({ criteria: { companyId, assetUuid } }) =>
      Routes.MultiCompany.VehicleRegisterInsights(companyId, assetUuid),
    'Account menu items': ({ criteria: { company } }) =>
      [
        {
          title: 'Users',
          to: Routes.MultiCompany.AccountUsers(company.data.id),
          hidden: !checkAccessByCompany(company, [AccessPermissions.ACCOUNT_USERS]),
        },
        {
          title: 'Issuer Details',
          to: Routes.MultiCompany.AccountCompanyDetails(company.data.id),
          hidden:
            !areCompanyDetailsVisible ||
            !checkAccessByCompany(company, [AccessPermissions.ACCOUNT_COMPANY_DETAILS]),
        },
        {
          title: 'Integrations',
          to: Routes.MultiCompany.AccountIntegrations(company.data.id),
          hidden: !isIntegrationsEnabled,
        },
      ].filter(({ hidden }) => !hidden),
    ...(isIntegrationsEnabled
      ? {
          'Integrations Tab': ({ criteria: { companyId } }) =>
            Routes.MultiCompany.AccountIntegrations(companyId),
        }
      : {}),
    'Deal management menu items': ({ criteria: { companyId } }) =>
      DEAL_MANAGEMENT_MENU_ITEMS.MultiCompany({ companyId }),
    'Share Class URL': ({ criteria: { companyId } }) => Routes.MultiCompany.ShareClass(companyId),
    'Share Plans Url': ({ criteria: { company } }) => ({
      to: Routes.MultiCompany.SharePlans(company.data.id),
      hidden: !checkAccessByCompany(company, [AccessPermissions.SHARE_OPTIONS_EDIT]),
    }),
    'Transactions Url': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.AllTransactions(companyId),
    'Secondary Transactions Url': ({ criteria: { company } }) =>
      Routes.MultiCompany.SecondaryTransactions(company.id),
    'Grant Holders Url': ({ criteria: { companyId, shareOptionUuid } }) =>
      Routes.MultiCompany.GrantHolders(companyId, shareOptionUuid),
    'Share Incentive Plan URL': ({ criteria: { companyId, id } }) =>
      Routes.MultiCompany.ShareIncentivePlan(companyId, id),
    'Deals URL': () => Routes.MultiCompany.Deals(),
    'Deal URL': ({ criteria: { permalink, navigationURL, subNavigationURL } }) =>
      Routes.MultiCompany.Deal(permalink, navigationURL, subNavigationURL),
    'Investor List': ({ criteria: { companyId, dealUuid } }) => ({
      to: Routes.MultiCompany.DealList(companyId, dealUuid),
    }),
    'Invite Investors to Deal URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.MultiCompany.InviteInvestors(companyId, dealUuid),
    'Investor Invite Settings': ({ criteria: { companyId, dealUuid } }) =>
      Routes.MultiCompany.InvestorInviteSettings(companyId, dealUuid),
    'Liquidity URL': ({ criteria: { roundUuid, pageId } }) =>
      Routes.MultiCompany.LRPageNavigation(roundUuid, pageId),
    'Shareholders URL': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.Shareholders(companyId),
    'Round Orders URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.MultiCompany.RoundOrders(companyId, roundUuid),
    'Shareholder Registry URL': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.ShareholderRegistry(companyId),
    'Deals table header': TABLE_HEADER.MultiCompany,
    'Deals table keys': TABLE_KEYS.MultiCompany,
    'Deal settlement table header': ({ criteria: { regulatoryEnvironment } }) => [
      { name: 'Name', sorting: { param: 'investor_name' } },
      { name: 'User', sorting: { param: 'investor_user_name' } },
      { name: 'No of Shares', sorting: { param: 'intended_number_of_tokens' } },
      { name: 'Amount Funded', sorting: { param: 'filled_amount' } },
      { name: 'Total Amount', sorting: { param: 'intended_amount' } },
      {
        name: regulatoryEnvironment?.value === 'FINRA' ? 'Status' : 'Order Status',
        sorting: { param: 'status' },
      },
      { name: 'Reference' },
      { name: 'Action' },
    ],
    'Deal settlement table keys': ({ criteria: { deal, regulatoryEnvironment } }) => [
      { field: 'investorName' },
      { field: 'investorUserName' },
      {
        field: 'intendedNumberOfTokens',
        transform: numberOfTokens => toDefinedFractionSizeNoRound(numberOfTokens, 2),
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'filledAmount',
        transform: (filledAmount, { currency }) =>
          filledAmount
            ? addCurrencySign(toDefinedFractionSizeNoRound(filledAmount, 2), currency)
            : '-',
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'intendedAmount',
        transform: (intendedAmount, { currency }) =>
          intendedAmount
            ? addCurrencySign(toDefinedFractionSizeNoRound(intendedAmount, 2), currency)
            : '-',
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'status',
        styles: (status, { reviewStatus }) => `
          color: ${getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).color};
          text-align: center;
        `,
        transform: (status, { reviewStatus }) =>
          getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).status,
      },
      { field: 'reference' },
      {
        field: 'status',
        styles: status => {
          let colorStyle = '';
          if (status === BE_STATUSES.SECURITIES_ALLOCATED) colorStyle = `color: ${systemGreen};`;
          if (status === BE_STATUSES.CANCELLED) colorStyle = `color: ${systemRed};`;

          return `${colorStyle}text-align: center;`;
        },
        transform: status => {
          if (status === BE_STATUSES.SECURITIES_ALLOCATED) return 'Securities Issued';
          if (status === BE_STATUSES.CANCELLED) return 'N/A';
        },
      },
    ],
    'Deal Management URL': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.DealManagement(companyId),
    'Deal Summary URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.MultiCompany.DealSummary(companyId, dealUuid),
    'Deal Page URL': ({ criteria: { companyId, dealUuid } }) =>
      Routes.MultiCompany.DealPage(companyId, dealUuid),
    'Deal summary menu items': ({
      criteria: {
        companyId,
        dealUuid,
        isAlternativeInvestmentVehicle,
        regulatoryEnvironment,
        isClosedEndFund,
      },
    }) =>
      [
        {
          title: 'Overview',
          to: Routes.MultiCompany.DealSummary(companyId, dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
        {
          title: 'Setup',
          to: Routes.MultiCompany.DealSetup(companyId, dealUuid),
        },
        {
          title: 'Investor List',
          to: Routes.MultiCompany.DealList(companyId, dealUuid),
        },
        {
          title: 'Indication of Interest',
          to: Routes.MultiCompany.DealIndicationOfInterest(companyId, dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
        {
          title: regulatoryEnvironment?.value === 'FINRA' ? 'Investment Requests' : 'Orders',
          to: Routes.MultiCompany.DealOrders(companyId, dealUuid),
          hidden: isClosedEndFund,
        },
        {
          title: 'Subscriptions',
          to: Routes.MultiCompany.FundSubscriptions(companyId, dealUuid),
          hidden: !isClosedEndFund,
        },
        {
          title: 'Settlement',
          to: Routes.MultiCompany.DealSettlement(companyId, dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
      ]
        .filter(({ hidden }) => !hidden)
        .map(({ title, to }) => ({ title, to })),
    'Communication menu items': ({ criteria: { company } }) => {
      const items = [];
      if (
        window.config?.SENDBIRD_ENABLED &&
        checkAccessByCompany(company, [AccessPermissions.COMMUNICATION_CHAT])
      ) {
        items.push({
          title: 'Messages',
          to: Routes.MultiCompany.ChatCommunication(company.data.id),
        });
      }
      if (
        checkAccessByCompany(company, [AccessPermissions.COMMUNICATIONS_VIEW]) &&
        !useEnvVar('ENABLE_MULTIPLE_ISSUERS_BUT_DISABLE_CAP_TABLE_PAGES') &&
        !useEnvVar('DISABLED_CAP_TABLE_MANAGEMENT')
      ) {
        items.push({
          title: 'Security Holder Communication',
          to: Routes.MultiCompany.Communication(company.data.id),
        });
      }
      return items;
    },
    'Liquidity Round List Url': ({ criteria: { companyId } }) =>
      Routes.MultiCompany.LiquidityList(companyId),
    'Liquidity Round URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.MultiCompany.LiquidityRound(companyId, roundUuid),
    'Liquidity Round page setup URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.MultiCompany.LiquidityRoundPageSetup(companyId, roundUuid),
    'Liquidity round invites URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.MultiCompany.LiquidityRoundInvites(companyId, roundUuid),
    'Liquidity round orders URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.MultiCompany.LiquidityRoundOrders(companyId, roundUuid),
    'Liquidity menu items': ({ criteria: { companyId } }) => [
      {
        title: 'Liquidity Rounds',
        to: Routes.MultiCompany.LiquidityList(companyId),
        exact: true,
      },
    ],
    'Liquidity round menu items': ({ criteria: { companyId, roundUuid } }) => [
      {
        title: 'Round Setup',
        to: Routes.MultiCompany.LiquidityRoundPageSetup(companyId, roundUuid),
      },
      {
        title: 'Invites',
        to: Routes.MultiCompany.LiquidityRoundInvites(companyId, roundUuid),
      },
      {
        title: 'Orders',
        to: Routes.MultiCompany.LiquidityRoundOrders(companyId, roundUuid),
      },
    ],
    'Add Liquidity Round Contract': ({ criteria: { companyId, roundUuid } }) =>
      Routes.MultiCompany.LiquidityRoundAddContract(companyId, roundUuid),
    'Edit Liquidity Round Contract': ({ criteria: { companyId, roundUuid, contractUuid } }) =>
      Routes.MultiCompany.LiquidityRoundEditContract(companyId, roundUuid, contractUuid),
    'Contract Form URL': ({ criteria: { companyId, dealUuid, contractUuid } }) =>
      Routes.MultiCompany.ContractForm(companyId, dealUuid, contractUuid),
  },
  Company: {
    'Register overview page menu items': () => [
      { title: 'Securities Overview', to: Routes.Admin.SecuritiesOverview() },
    ],
    'Add New Deal Url': ({ criteria: { companyId, modelUuid } }) =>
      Routes.Admin.AddNewDeal(companyId, modelUuid),
    'New Deal Creation Url': () => Routes.Admin.CreateDeal(),
    'New Investment Fund Creation Url': () => Routes.Admin.CreateInvestmentFund(),
    'New Nominee Placement Creation Url': () => Routes.Admin.CreateNomineePlacement(),
    'Deal Setup URL': ({ criteria: { modelUuid, tab } }) => Routes.Admin.DealSetup(modelUuid, tab),
    'Deal Settings URL': ({ criteria: { dealUuid } }) => Routes.Admin.DealSettings(dealUuid),
    'Nominee Placement Settings URL': ({ criteria: { uuid } }) =>
      Routes.Admin.NomineePlacementSettings(uuid),
    'Investment Fund Settings URL': ({ criteria: { dealUuid } }) =>
      Routes.Admin.InvestmentFundSettings(dealUuid),
    'Login Redirection Url': LOGIN_REDIRECTION_URL.Company,
    'My Settings page menu items': () => [
      { title: 'Settings', to: Routes.Admin.Settings(), exact: true },
    ],
    'Deals page top menu header': ({ criteria: { company } }) => company.data.name,
    'Deals page menu items': () => DEALS_PAGE_MENU_ITEMS.Company(),
    'Deal management menu items': () => DEAL_MANAGEMENT_MENU_ITEMS.Company(),
    'Investors page menu items': () => INVESTORS_PAGE_MENU_ITEMS,
    'Other Vehicles URL': ({ criteria: { companyId } }) => Routes.Admin.OtherVehicles(companyId),
    'Vehicle Investor Insights URL': ({ criteria: { companyId, assetUuid } }) =>
      Routes.Admin.VehicleRegisterInsights(companyId, assetUuid),
    'My Settings items': () =>
      [
        { title: 'My Details', to: Routes.Admin.AccountMyDetails() },
        {
          title: 'Security',
          to: Routes.Admin.AccountSecurity(),
        },
      ].filter(({ hidden }) => !hidden),
    'Account Management items': ({ criteria: { company } }) =>
      [
        {
          title: 'Users',
          to: Routes.Admin.AccountUsers(),
          hidden: !checkAccessByCompany(company, [AccessPermissions.ACCOUNT_USERS]),
        },
        {
          title: 'Issuer Details',
          to: Routes.Admin.AccountCompanyDetails(),
          hidden:
            !areCompanyDetailsVisible ||
            !checkAccessByCompany(company, [AccessPermissions.ACCOUNT_COMPANY_DETAILS]),
        },
        {
          title: 'Integrations',
          to: Routes.Admin.AccountIntegrations(company.data.id),
          hidden: !isIntegrationsEnabled,
        },
      ].filter(({ hidden }) => !hidden),
    ...(isIntegrationsEnabled
      ? {
          'Integrations Tab': ({ criteria: { companyId } }) =>
            Routes.Admin.AccountIntegrations(companyId),
        }
      : {}),
    'Communication page menu items': () => [],
    'Communication menu items': ({ criteria: { company } }) => {
      const items = [];
      if (
        window.config?.SENDBIRD_ENABLED &&
        checkAccessByCompany(company, [AccessPermissions.COMMUNICATION_CHAT])
      ) {
        items.push({
          title: 'Messages',
          to: Routes.Admin.AdminChatCommunication(),
        });
      }
      if (
        checkAccessByCompany(company, [AccessPermissions.COMMUNICATIONS_VIEW]) &&
        !useEnvVar('ENABLE_MULTIPLE_ISSUERS_BUT_DISABLE_CAP_TABLE_PAGES') &&
        !useEnvVar('DISABLED_CAP_TABLE_MANAGEMENT')
      ) {
        items.push({
          title: 'Security Holder Communication',
          to: Routes.Admin.Communication(company.data.id),
        });
      }
      return items;
    },
    'Share Class URL': ({ criteria: { companyId } }) => Routes.Admin.ShareClass(companyId),
    'Landing Page URL': () => Routes.Admin.AccountLandingPage(),
    'Company Landing Page URL': ({ criteria: { company } }) =>
      Routes.Admin.CompanyLandingPage(company.data.id),
    'Account Users URL': ({ criteria: { company } }) => Routes.Admin.AccountUsers(company.data.id),
    'Account Details URL': () => Routes.Admin.AccountMyDetails(),
    'Account Security URL': () => Routes.Admin.AccountSecurity(),
    'Share Plans Url': ({ criteria: { company } }) => ({
      to: Routes.Admin.SharePlans(company.data.id),
      hidden: !checkAccessByCompany(company, [AccessPermissions.SHARE_OPTIONS_EDIT]),
    }),
    'Transactions Url': ({ criteria: { companyId } }) => Routes.Admin.AllTransactions(companyId),
    'Secondary Transactions Url': ({ criteria: { company } }) =>
      Routes.Admin.SecondaryTransactions(company.id),
    'Grant Holders Url': ({ criteria: { companyId, shareOptionUuid } }) =>
      Routes.Admin.GrantHolders(companyId, shareOptionUuid),
    'Share Incentive Plan URL': ({ criteria: { companyId, id } }) =>
      Routes.Admin.ShareIncentivePlan(companyId, id),
    'Deals URL': Routes.Admin.DealManagement,
    'Deal URL': ({ criteria: { permalink, navigationURL, subNavigationURL } }) =>
      Routes.Admin.Deal(permalink, navigationURL, subNavigationURL),
    'Investor List': ({ criteria: { dealUuid } }) => ({
      to: Routes.Admin.DealList(dealUuid),
    }),
    'Invite Investors to Deal URL': ({ criteria: { dealUuid } }) =>
      Routes.Admin.InviteInvestors(dealUuid),
    'Investor Invite Settings': ({ criteria: { dealUuid } }) =>
      Routes.Admin.InvestorInviteSettings(dealUuid),
    'Liquidity URL': ({ criteria: { roundUuid, pageId } }) =>
      Routes.Admin.LRPageNavigation(roundUuid, pageId),
    'Shareholders URL': ({ criteria: { companyId } }) => Routes.Admin.Shareholders(companyId),
    'Round Orders URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Admin.RoundOrders(companyId, roundUuid),
    'Shareholder Registry URL': ({ criteria: { companyId } }) =>
      Routes.Admin.ShareholderRegistry(companyId),
    'Deals table header': TABLE_HEADER.Company,
    'Deals table keys': TABLE_KEYS.Company,
    'Deal settlement table header': ({ criteria: { regulatoryEnvironment } }) => [
      { name: 'Name', sorting: { param: 'investor_name' } },
      { name: 'User', sorting: { param: 'investor_user_name' } },
      { name: 'No of Shares', sorting: { param: 'intended_number_of_tokens' } },
      { name: 'Amount Funded', sorting: { param: 'filled_amount' } },
      { name: 'Total Amount', sorting: { param: 'intended_amount' } },
      {
        name: regulatoryEnvironment?.value === 'FINRA' ? 'Status' : 'Order Status',
        sorting: { param: 'status' },
      },
      { name: 'Reference' },
      { name: 'Action' },
    ],
    'Deal settlement table keys': ({ criteria: { deal, regulatoryEnvironment } }) => [
      { field: 'investorName' },
      { field: 'investorUserName' },
      {
        field: 'intendedNumberOfTokens',
        transform: numberOfTokens => toDefinedFractionSizeNoRound(numberOfTokens, 2),
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'filledAmount',
        transform: (filledAmount, { currency }) =>
          filledAmount
            ? addCurrencySign(toDefinedFractionSizeNoRound(filledAmount, 2), currency)
            : '-',
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'intendedAmount',
        transform: (intendedAmount, { currency }) =>
          intendedAmount
            ? addCurrencySign(toDefinedFractionSizeNoRound(intendedAmount, 2), currency)
            : '-',
        styles: () => tableColumnStyles.number,
      },
      {
        field: 'status',
        styles: (status, { reviewStatus }) => `
          color: ${getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).color};
          text-align: center;
        `,
        transform: (status, { reviewStatus }) =>
          getOrderStatus({ deal, status, reviewStatus }).status,
      },
      { field: 'reference' },
      {
        field: 'status',
        styles: status => {
          let colorStyle = '';
          if (status === BE_STATUSES.SECURITIES_ALLOCATED) colorStyle = `color: ${systemGreen};`;
          if (status === BE_STATUSES.CANCELLED) colorStyle = `color: ${systemRed};`;

          return `${colorStyle}text-align: center;`;
        },
        transform: status => {
          if (status === BE_STATUSES.SECURITIES_ALLOCATED) return 'Securities Issued';
          if (status === BE_STATUSES.CANCELLED) return 'N/A';
        },
      },
    ],
    'Deal Management URL': Routes.Admin.DealManagement,
    'Deal Summary URL': ({ criteria: { dealUuid } }) => Routes.Admin.DealSummary(dealUuid),
    'Deal Page URL': ({ criteria: { dealUuid, companyUuid } }) =>
      companyUuid
        ? Routes.Admin.External.DealSummary(companyUuid, dealUuid)
        : Routes.Admin.DealPage(dealUuid),
    'Deal summary menu items': ({
      criteria: {
        companyUuid,
        dealUuid,
        accessType,
        isAlternativeInvestmentVehicle,
        regulatoryEnvironment,
        isClosedEndFund,
      },
    }) =>
      [
        {
          title: 'Overview',
          to: companyUuid
            ? Routes.Admin.External.DealSummary(companyUuid, dealUuid)
            : Routes.Admin.DealSummary(dealUuid),
          hidden: isAlternativeInvestmentVehicle || isClosedEndFund,
        },
        {
          title: 'Setup',
          to: companyUuid
            ? Routes.Admin.External.DealSetup(companyUuid, dealUuid)
            : Routes.Admin.DealSetup(dealUuid),
          hidden: accessType === 'deal_summary_only',
        },
        {
          title: 'Investor List',
          to: companyUuid
            ? Routes.Admin.External.DealList(companyUuid, dealUuid)
            : Routes.Admin.DealList(dealUuid),
          hidden: accessType === 'deal_summary_only',
        },
        {
          title: 'Indication of Interest',
          to: companyUuid
            ? Routes.Admin.External.DealIndicationOfInterest(companyUuid, dealUuid)
            : Routes.Admin.DealIndicationOfInterest(dealUuid),
          hidden:
            accessType === 'deal_summary_only' || isAlternativeInvestmentVehicle || isClosedEndFund,
        },
        {
          title: regulatoryEnvironment?.value === 'FINRA' ? 'Investment Requests' : 'Orders',
          to: companyUuid
            ? Routes.Admin.External.DealOrders(companyUuid, dealUuid)
            : Routes.Admin.DealOrders(dealUuid),
          hidden: isClosedEndFund || accessType === 'deal_summary_only',
        },
        {
          title: 'Subscriptions',
          to: companyUuid
            ? Routes.Admin.External.FundSubscriptions(companyUuid, dealUuid)
            : Routes.Admin.FundSubscriptions(dealUuid),
          hidden: !isClosedEndFund,
        },
        {
          title: 'Settlement',
          to: companyUuid
            ? Routes.Admin.External.DealSettlement(companyUuid, dealUuid)
            : Routes.Admin.DealSettlement(dealUuid),
          hidden:
            accessType === 'deal_summary_only' || isAlternativeInvestmentVehicle || isClosedEndFund,
        },
      ]
        .filter(({ hidden }) => !hidden)
        .map(({ title, to }) => ({ title, to })),
    'Liquidity Round List Url': ({ criteria: { companyId } }) =>
      Routes.Admin.LiquidityList(companyId),
    'Liquidity Round URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Admin.LiquidityRound(companyId, roundUuid),
    'Liquidity Round page setup URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Admin.LiquidityRoundPageSetup(companyId, roundUuid),
    'Liquidity round invites URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Admin.LiquidityRoundInvites(companyId, roundUuid),
    'Liquidity round orders URL': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Admin.LiquidityRoundOrders(companyId, roundUuid),
    'Liquidity menu items': ({ criteria: { companyId } }) => [
      {
        title: 'Liquidity Rounds',
        to: Routes.Admin.LiquidityList(companyId),
        exact: true,
      },
    ],
    'Liquidity round menu items': ({ criteria: { companyId, roundUuid } }) => [
      {
        title: 'Round Setup',
        to: Routes.Admin.LiquidityRoundPageSetup(companyId, roundUuid),
      },
      {
        title: 'Invites',
        to: Routes.Admin.LiquidityRoundInvites(companyId, roundUuid),
      },
      {
        title: 'Orders',
        to: Routes.Admin.LiquidityRoundOrders(companyId, roundUuid),
      },
    ],
    'Add Liquidity Round Contract': ({ criteria: { companyId, roundUuid } }) =>
      Routes.Admin.LiquidityRoundAddContract(companyId, roundUuid),
    'Edit Liquidity Round Contract': ({ criteria: { companyId, roundUuid, contractUuid } }) =>
      Routes.Admin.LiquidityRoundEditContract(companyId, roundUuid, contractUuid),
    'Contract Form URL': ({ criteria: { dealUuid, contractUuid } }) =>
      Routes.Admin.ContractForm(dealUuid, contractUuid),
  },
});
