export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';
export const SET_COMPANY = 'SET_COMPANY';

export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';

export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const RESET_COMPANY = 'RESET_COMPANY';

export const UPDATE_COMPANIES_HOUSE_AUTH_CODE = 'UPDATE_COMPANIES_HOUSE_AUTH_CODE';
export const UPDATE_COMPANIES_HOUSE_AUTH_CODE_SUCCESS = 'UPDATE_COMPANIES_HOUSE_AUTH_CODE_SUCCESS';
export const UPDATE_COMPANIES_HOUSE_AUTH_CODE_ERROR = 'UPDATE_COMPANIES_HOUSE_AUTH_CODE_ERROR';

export const GET_COMPANY_TAGS = 'GET_COMPANY_TAGS';
export const GET_COMPANY_TAGS_SUCCESS = 'GET_COMPANY_TAGS_SUCCESS';
export const GET_COMPANY_TAGS_ERROR = 'GET_COMPANY_TAGS_ERROR';
export const RESET_COMPANY_TAGS = 'RESET_COMPANY_TAGS';

export const UPDATE_COMPANY_VIEW_PERMISSIONS = 'UPDATE_COMPANY_VIEW_PERMISSIONS';
export const UPDATE_COMPANY_VIEW_PERMISSIONS_SUCCESS = 'UPDATE_COMPANY_VIEW_PERMISSIONS_SUCCESS';
export const UPDATE_COMPANY_VIEW_PERMISSIONS_ERROR = 'UPDATE_COMPANY_VIEW_PERMISSIONS_ERROR';

export const VERIFY_COMPANY_KYB = 'VERIFY_COMPANY_KYB';
export const VERIFY_COMPANY_KYB_SUCCESS = 'VERIFY_COMPANY_KYB_SUCCESS';
export const VERIFY_COMPANY_KYB_ERROR = 'VERIFY_COMPANY_KYB_ERROR';

export const CREATE_COMPANY_SUMMARY = 'CREATE_COMPANY_SUMMARY';
export const CREATE_COMPANY_SUMMARY_SUCCESS = 'CREATE_COMPANY_SUMMARY_SUCCESS';
export const CREATE_COMPANY_SUMMARY_ERROR = 'CREATE_COMPANY_SUMMARY_ERROR';

export const EDIT_COMPANY_SUMMARY = 'EDIT_COMPANY_SUMMARY';
export const EDIT_COMPANY_SUMMARY_SUCCESS = 'EDIT_COMPANY_SUMMARY_SUCCESS';
export const EDIT_COMPANY_SUMMARY_ERROR = 'EDIT_COMPANY_SUMMARY_ERROR';

export const GET_COMPANY_SUMMARY = 'GET_COMPANY_SUMMARY';
export const GET_COMPANY_SUMMARY_SUCCESS = 'GET_COMPANY_SUMMARY_SUCCESS';
export const GET_COMPANY_SUMMARY_ERROR = 'GET_COMPANY_SUMMARY_ERROR';

export const CLEAR_COMPANY_SUMMARY = 'GET_COMPANY_SUMMARY';
