import { UnderlinedTextInput } from 'shared-parts/components';

import { FormWrapper, SubmitButton } from './forgot-password-form.styled';

const renderForgotPasswordForm =
  ({
    formColors,
    textColor,
    placeholderColor,
    accentColorUntouched,
    SubmitButtonComponent = SubmitButton,
  }) =>
  ({ isValid, isSubmitting, dirty }) => (
    <FormWrapper autoComplete="off">
      <UnderlinedTextInput
        name="email"
        textColor={textColor || formColors.textColor}
        backgroundColor={formColors.backgroundColor}
        placeholderColor={placeholderColor || formColors.placeholderColor}
        placeholder="Email"
        accentColorUntouched={accentColorUntouched}
      />
      <SubmitButtonComponent disabled={!dirty || !isValid || isSubmitting} type="submit">
        Send Email
      </SubmitButtonComponent>
    </FormWrapper>
  );

export default renderForgotPasswordForm;
