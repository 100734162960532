import { initReactI18next } from 'react-i18next';

import i18next, { t as tOriginal } from 'i18next';

i18next.use(initReactI18next).init({
  lng: navigator.language,
  fallbackLng: [navigator.language, 'en-GB'],
  ns: ['regulatory', 'whitelabel'], // 'translation' exists by default
  fallbackNS: ['regulatory', 'whitelabel', 'translation'],
  resources: {}, // start with resources unloaded. lazy-load from /server
});

/**
 * Display a translation or whitelabelled copy using the i18next library.
 *
 * apps/management-console/server/helpers/translation.ts
 */
const t = tOriginal;

i18next.services.formatter?.add('existing', (_value, _lng, { interpolationkey }) => {
  return t(interpolationkey);
});

i18next.services.formatter?.add('titlecase', value => {
  return value.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
});

i18next.services.formatter?.add('capitalise', value => {
  return value.toLowerCase().replace(/^\W*\b\w/g, (char: string) => char.toUpperCase());
});

export { t };
export default i18next;
