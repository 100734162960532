import { formatDate, LDF } from '@frontend/datetime';

export const VIEWS_TITLE = 'VIEWS';
export const RESEND_BUTTON_TEXT = 'Re-Send Invite';
export const OPENED_INVITE_LABEL = 'Opened Invite';
export const VIEWED_DEAL_LABEL = 'Viewed Deal';
export const PLACED_ORDER_LABEL = 'Placed Order';
export const LAST_INVITE_INFO_TEXT = 'Last invite sent on';
export const ERROR_MESSAGE = 'Failed to fetch the data';

export const VIEWS_HEADERS = [
  { name: 'Page', styles: 'text-align: left;' },
  { name: 'Total Views' },
  { name: 'Last Viewed Date' },
];

export const VIEWS_KEYS = [
  { field: 'page' },
  {
    field: 'totalViews',
    styles: () => 'text-align: center;',
  },
  {
    field: 'lastViewDate',
    transform: (value: string) => formatDate(value, LDF.DATE_NUMERIC),
    styles: () => 'text-align: center;',
  },
];
