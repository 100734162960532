import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

/* eslint-disable */
// REMOVE THIS ESLINT DISABLE WHEN MULTIPLE REDUCERS ARE EXPORTED
export const initialState = getReducerState();

export const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_VERIFICATION_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_VERIFICATION_ERROR:
      return { loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};
