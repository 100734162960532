import { COLORS } from 'shared-parts/constants';

const CircleInfoIcon = (width = 16, height = 16, color = COLORS.systemOrange) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <path
      fill={color}
      d="M9.2,14h1.6V9.2H9.2ZM10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,14.4A6.4,6.4,0,1,1,16.4,10,6.408,6.408,0,0,1,10,16.4ZM9.2,7.6h1.6V6H9.2Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default CircleInfoIcon;
