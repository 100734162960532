export enum STATUSES {
  // Same as ORDER, used for dynamic UX elements
  ORDER_INVESTMENT_REQUEST = 'Investment Request',
  ORDER = 'Order',
  SIGN_AGREEMENT = 'Sign Agreement',
  TRANSFER_FUNDS = 'Transfer Funds',
  TRANSACTION_COMPLETED = 'Transaction Completed',
  APPROVED = 'Approved',
  AUTO_APPROVED = 'Auto approved',
  AWAITING_CLOSING = 'Awaiting closing',
  CLOSED = 'Closed',
  AWAITING_SETTLEMENT = 'Awaiting Settlement',
  CANCELLED = 'Cancelled',
  UNKNOWN = 'Unknown',
}

export enum INDICATION_OF_INTEREST_STATUSES {
  INDICATION_OF_INTEREST = 'Indication of Interest',
  SIGN_AGREEMENT = 'Sign Agreement',
}

export enum REVIEW_STATUSES {
  PENDING = 'pending',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
}

export enum BE_STATUSES {
  PLACED = 'placed',
  PARTIALLY_FILLED = 'partially_filled',
  FILLED = 'filled',
  PLACED_AGREEMENT = 'placed_agreement',
  SIGNED_AGREEMENT = 'signed_agreement',
  COMPLETED = 'completed',
  SECURITIES_ALLOCATED = 'securities_allocated',
  CANCELLED = 'cancelled',
  ADJUSTED = 'adjusted',
  APPROVED = 'approved',
  AWAITING_CLOSING = 'awaiting_closing',
}

export enum ORDER_TYPES {
  ORDER = 'Order',
  IOI = 'IndicationOfInterest',
}
