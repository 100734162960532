import { object, ObjectSchema, string } from 'yup';

import { useEnvVar } from '@frontend/config';

import { InvestorDetailsFormProps } from 'modules/investors/types';
import validation from 'shared-parts/constants/validation';

import {
  ADDRESS_LABEL,
  CITY_LABEL,
  COUNTRY_CODE_LABEL,
  EMAIL_LABEL,
  ENTITY_LABEL,
  FIRST_NAME_LABEL,
  INSTITUTION_COUNTRY_LABEL,
  INSTITUTION_NAME_LABEL,
  INVESTOR_DETAILS,
  LAST_NAME_LABEL,
  POST_CODE_LABEL,
} from '../constants';

const institutionalInvestorFields = {
  [INVESTOR_DETAILS.INSTITUTION_NAME]: string().required(`${INSTITUTION_NAME_LABEL} is required`),
  [INVESTOR_DETAILS.INSTITUTION_COUNTRY]: string().required(
    `${INSTITUTION_COUNTRY_LABEL} is required`,
  ),
};

export default ({ investorType, isEditing }: InvestorDetailsFormProps): ObjectSchema => {
  const isInstitutionalFieldsVisible = investorType === 'institutional_investor' || !isEditing;

  return object({
    [INVESTOR_DETAILS.FIRST_NAME]: string().required(`${FIRST_NAME_LABEL} is required`),
    [INVESTOR_DETAILS.LAST_NAME]: string().required(`${LAST_NAME_LABEL} is required`),
    [INVESTOR_DETAILS.EMAIL]: string()
      .required(`${EMAIL_LABEL} is required`)
      .matches(validation.noBlankSpaceFormat, {
        message: 'Email cannot contain spaces',
      })
      .matches(validation.emailFormat, {
        message: 'Invalid email address',
      }),
    ...(useEnvVar('ENABLE_ENTITY_COMPANY_INVESTOR_FORM')
      ? {
          [INVESTOR_DETAILS.ENTITY_COMPANY_ID]: string().required(
            `${ENTITY_LABEL} must be provided`,
          ),
        }
      : {}),
    ...(isInstitutionalFieldsVisible ? institutionalInvestorFields : {}),
    [INVESTOR_DETAILS.COUNTRY_CODE]: string().required(`${COUNTRY_CODE_LABEL} is required`),
    [INVESTOR_DETAILS.ADDRESS_FIRST_LINE]: string().required(`${ADDRESS_LABEL} is required`),
    [INVESTOR_DETAILS.CITY]: string().required(`${CITY_LABEL} is required`),
    [INVESTOR_DETAILS.POST_CODE]: string().required(`${POST_CODE_LABEL} is required`),
  }).defined();
};
