import { connectRouter } from 'connected-react-router';
import { sessionReducer } from 'redux-react-session';

import type { History } from 'history';
import { combineReducers } from 'redux';

import { brokersNoteReducer } from 'app-state/brokers-note/brokers-note.reducer';
import {
  aggregatedCommunicationResponsesStatisticsReducer,
  communicationResponsesStatisticsReducer,
  communicationsReducer,
  draftCommunicationReducer,
  publishedCommunicationReducer,
  shareholdersEmailsReducer,
} from 'app-state/communication/reducers';
import { companyEntitiesReducer } from 'app-state/company-entities/reducers';
import { currenciesReducer } from 'app-state/currencies/reducers';
import {
  dealsReducer,
  dealStructureFormErrorReducer,
  userDealsReducer,
} from 'app-state/deals/reducers';
import { orderDocumentsReducer } from 'app-state/documents/reducers';
import {
  dealIOIDetailsReducer,
  indicationOfInterestReducer,
} from 'app-state/indication-of-interest/reducers';
import {
  investorAgentsPermissionsReducer,
  investorDealsReducer,
  investorInvestmentsReducer,
  investorPreferencesReducer,
  investorsReducer,
  walletReferenceReducer,
  walletTransactionsReducer,
} from 'app-state/investors/reducers';
import { loaderReducer } from 'app-state/loader/reducers';
import { accountUsersReducer, myAccountDetailsReducer } from 'app-state/reducers/account';
import { signUpReducer, twoFactorAuthCodesReducer } from 'app-state/reducers/authentication';
import {
  companiesReducer,
  companyReducer,
  companySummaryReducer,
  companyTagsReducer,
} from 'app-state/reducers/companies';
import { countriesReducer } from 'app-state/reducers/countries';
import { currencyRatesReducer } from 'app-state/reducers/currency-rate';
import { dashboardReducer } from 'app-state/reducers/dashboard';
import {
  componentAddingStateReducer,
  dealDataReducer,
  dealDocumentsDataReducer,
  dealInfoReducer,
  dealInviteEmailTemplateReducer,
  dealListReducer,
  dealOrderDetailsReducer,
  dealOrderHistoryReducer,
  dealOrdersReducer,
  dealReducer,
  dealSettlementsReducer,
  dealSummaryReducer,
  investorDocumentsReducer,
  investorInfoReducer,
  issuanceReducer,
  lastCreatedComponentReducer,
  pageReducer,
  resendInvestorInviteReducer,
  teamMembersReducer,
  updateDealDataReducer,
  updateDealPageHeaderReducer,
} from 'app-state/reducers/deal';
import { downloadFileReducer } from 'app-state/reducers/file';
import {
  allotmentDataReducer,
  allottedSharesReducer,
  companyProfileReducer,
  filingDetailsReducer,
  filingDocumentsReducer,
  filingsReducer,
  shareCapitalReducer,
  shareholderInformationReducer,
  sicCodesReducer,
} from 'app-state/reducers/filings';
import {
  editableNavigationReducer,
  navigationReducer,
  subNavigationReducer,
} from 'app-state/reducers/navigation';
import { generateOnboardingLinkErrorReducer } from 'app-state/reducers/onboarding-link';
import {
  subscriptionTemplatesPerCompanyReducer,
  subscriptionTemplatesReducer,
} from 'app-state/reducers/package';
import { permissionLevelsReducer, permissionsReducer } from 'app-state/reducers/permissions';
import {
  introducerFeesReducer,
  secondaryTransactionReducer,
  secondaryTransactionsReducer,
} from 'app-state/reducers/secondary-transactions';
import { settingsReducer } from 'app-state/reducers/settings';
import { convertSharesErrorReducer, shareClassesReducer } from 'app-state/reducers/share-class';
import { shareIncentivePlanReducer } from 'app-state/reducers/share-incentive';
import {
  exerciseShareOptionsReducer,
  grantHolderDetailsReducer,
  grantHoldersCapitalReducer,
  grantHoldersEmailsReducer,
  grantHoldersReducer,
  grantReducer,
  grantTransactionalHistoryReducer,
  holdersReducer,
  newGrantAddingStateReducer,
  searchHoldersReducer,
  shareOptionDataReducer,
  shareOptionPlanDocumentsReducer,
  shareOptionPlanReducer,
  shareOptionsReducer,
} from 'app-state/reducers/share-options';
import {
  modalReducer,
  overlayReducer,
  redirectPathReducer,
  sideFormReducer,
} from 'app-state/reducers/shared';
import {
  allocateShareholdingErrorReducer,
  currentShareholderTransactionReducer,
  holdingsReducer,
  lookupShareholderReducer,
  registeredShareholdersReducer,
  searchShareholdersReducer,
  shareholderDetailsReducer,
  shareholderPerformanceReducer,
  shareholdersDropdownDataReducer,
  shareholdersReducer,
  shareholderTransactionsReducer,
  transferShareholdersReducer,
  transferShareholdingErrorReducer,
} from 'app-state/reducers/shareholders';
import { rowScrollDataReducer } from 'app-state/reducers/table';
import { templatesReducer } from 'app-state/reducers/templates';
import { topNavigationReducer } from 'app-state/reducers/top-navigation';
import { treasuryAccountReducer } from 'app-state/reducers/treasury-account';
import { usersReducer } from 'app-state/reducers/users';
import { verificationReducer } from 'app-state/reducers/verification';
import { sharePlanBonusReducer } from 'app-state/share-plan-bonus/share-plan-bonus.reducer';
import { sharePlanInviteEmployeesReducer } from 'app-state/share-plan-invite-employees/share-plan-invite-employees.reducer';
import { sharePlanPayrollReducer } from 'app-state/share-plan-payroll/share-plan-payroll.reducer';
import { timezonesReducer } from 'app-state/timezones/reducers';

import { downloadCSVReducer } from './csv-download/csv-download.reducer';
import { downloadExportCommandCSVReducer } from './csv-export/csv-export.reducer';
import { shareCertificateConfigReducer } from './share-certificates/reducer';
import { gridConfigsReducer } from './stored-grid-state/reducer';
import { uploadCSVReducer } from './uploadCSV/uploadCSV.reducer';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    myAccountDetails: myAccountDetailsReducer,
    redirectPath: redirectPathReducer,
    timezones: timezonesReducer,
    indicationOfInterest: indicationOfInterestReducer,
    accountUsers: accountUsersReducer,
    investorDocuments: investorDocumentsReducer,
    session: sessionReducer,
    dashboardInfo: dashboardReducer,
    deal: dealReducer,
    lastCreatedComponent: lastCreatedComponentReducer,
    deals: dealsReducer,
    userDeals: userDealsReducer,
    dealStructureFormError: dealStructureFormErrorReducer,
    company: companyReducer,
    companies: companiesReducer,
    companyTags: companyTagsReducer,
    companySummary: companySummaryReducer,
    orderDocuments: orderDocumentsReducer,
    holdings: holdingsReducer,
    shareholders: shareholdersReducer,
    registeredShareholders: registeredShareholdersReducer,
    transferShareholders: transferShareholdersReducer,
    shareholdersDropdownData: shareholdersDropdownDataReducer,
    shareholderDetails: shareholderDetailsReducer,
    searchShareholdersData: searchShareholdersReducer,
    shareholderPerformance: shareholderPerformanceReducer,
    allocateShareholdingError: allocateShareholdingErrorReducer,
    transferShareholdingError: transferShareholdingErrorReducer,
    convertSharesError: convertSharesErrorReducer,
    shareholderTransactions: shareholderTransactionsReducer,
    currentShareholderTransaction: currentShareholderTransactionReducer,
    shareholderDraftCommunication: draftCommunicationReducer,
    shareholderPublishedCommunication: publishedCommunicationReducer,
    shareholderCommunications: communicationsReducer,
    shareholderLookup: lookupShareholderReducer,
    communicationResponsesStatistics: communicationResponsesStatisticsReducer,
    aggregatedCommunicationResponsesStatistics: aggregatedCommunicationResponsesStatisticsReducer,
    shareOptions: shareOptionsReducer,
    holders: holdersReducer,
    grantHolders: grantHoldersReducer,
    grantHoldersCapital: grantHoldersCapitalReducer,
    grant: grantReducer,
    shareOptionPlan: shareOptionPlanReducer,
    shareOptionData: shareOptionDataReducer,
    shareIncentivePlan: shareIncentivePlanReducer,
    exerciseShareOptions: exerciseShareOptionsReducer,
    shareOptionPlanDocuments: shareOptionPlanDocumentsReducer,
    modal: modalReducer,
    sideForm: sideFormReducer,
    overlay: overlayReducer,
    shareClasses: shareClassesReducer,
    loader: loaderReducer,
    currencies: currenciesReducer,
    countries: countriesReducer,
    navigation: navigationReducer,
    subNavigation: subNavigationReducer,
    page: pageReducer,
    dealSummary: dealSummaryReducer,
    dealInfo: dealInfoReducer,
    dealData: dealDataReducer,
    dealDocumentsData: dealDocumentsDataReducer,
    dealList: dealListReducer,
    companyEntities: companyEntitiesReducer,
    updateDealPageHeader: updateDealPageHeaderReducer,
    updateDealData: updateDealDataReducer,
    investorInfo: investorInfoReducer,
    resendInvestorInvite: resendInvestorInviteReducer,
    issuance: issuanceReducer,
    investors: investorsReducer,
    investorWalletReference: walletReferenceReducer,
    walletTransactions: walletTransactionsReducer,
    investorInvestments: investorInvestmentsReducer,
    investorPreferences: investorPreferencesReducer,
    investorDeals: investorDealsReducer,
    investorAgentsPermissions: investorAgentsPermissionsReducer,
    settings: settingsReducer,
    users: usersReducer,
    verification: verificationReducer,
    editableNavigation: editableNavigationReducer,
    componentAddingState: componentAddingStateReducer,
    rowScrollData: rowScrollDataReducer,
    teamMembers: teamMembersReducer,
    topNavigation: topNavigationReducer,
    subscriptionTemplates: subscriptionTemplatesReducer,
    subscriptionTemplatesPerCompany: subscriptionTemplatesPerCompanyReducer,
    generateOnboardingLinkError: generateOnboardingLinkErrorReducer,
    permissionLevels: permissionLevelsReducer,
    permissions: permissionsReducer,
    templates: templatesReducer,
    twoFactorAuthCodes: twoFactorAuthCodesReducer,
    secondaryTransactions: secondaryTransactionsReducer,
    downloadFile: downloadFileReducer,
    grantTransactionalHistory: grantTransactionalHistoryReducer,
    signUp: signUpReducer,
    secondaryTransaction: secondaryTransactionReducer,
    filings: filingsReducer,
    filingDetails: filingDetailsReducer,
    filingDocuments: filingDocumentsReducer,
    newGrantAddingState: newGrantAddingStateReducer,
    companyProfile: companyProfileReducer,
    shareCapital: shareCapitalReducer,
    shareholderInformation: shareholderInformationReducer,
    sicCodes: sicCodesReducer,
    allotmentData: allotmentDataReducer,
    allottedShares: allottedSharesReducer,
    grantHoldersEmails: grantHoldersEmailsReducer,
    searchHoldersData: searchHoldersReducer,
    grantHolderDetails: grantHolderDetailsReducer,
    dealInviteEmailTemplate: dealInviteEmailTemplateReducer,
    dealOrders: dealOrdersReducer,
    dealOrderDetails: dealOrderDetailsReducer,
    dealOrderHistory: dealOrderHistoryReducer,
    dealSettlements: dealSettlementsReducer,
    dealIOIDetails: dealIOIDetailsReducer,
    shareholdersEmails: shareholdersEmailsReducer,
    introducerFees: introducerFeesReducer,
    treasuryAccount: treasuryAccountReducer,
    downloadCSV: downloadCSVReducer,
    downloadExportCommandCSV: downloadExportCommandCSVReducer,
    uploadCSV: uploadCSVReducer,
    brokersNote: brokersNoteReducer,
    sharePlanPayroll: sharePlanPayrollReducer,
    sharePlanBonus: sharePlanBonusReducer,
    sharePlanInviteEmployees: sharePlanInviteEmployeesReducer,
    currencyRates: currencyRatesReducer,
    shareCertificateConfig: shareCertificateConfigReducer,
    gridConfigs: gridConfigsReducer,
  });
