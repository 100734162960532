import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'helpers';

function* getSettings() {
  try {
    const { data } = yield call(request, API.Settings());

    yield put(actions.getSettingsSuccess(data));
  } catch (e) {
    yield put(actions.getSettingsError(e));
  }
}

function* getSettingsWatcher() {
  yield takeEvery(constants.GET_SETTINGS, getSettings);
}

export { getSettings, getSettingsWatcher };
