import getPermissionsByCompany from './get-permissions-by-company';
import getUniquePermissions from './get-unique-permissions';

const UK_REGISTERED_COUNTRY_CODE = 'GB';

const checkAccess = (userPermissions = [], permissionsToCheck, mode) => {
  const checkPermission = permission => userPermissions.includes(permission);

  switch (mode) {
    case 'any':
      return permissionsToCheck.some(checkPermission);
    case 'all':
    default:
      return permissionsToCheck.every(checkPermission);
  }
};

export const checkUniqueAccess = (permissionsToCheck, mode) =>
  checkAccess(getUniquePermissions(), permissionsToCheck, mode);

export const checkAccessByCompany = (company, permissionsToCheck, mode) =>
  checkAccess(getPermissionsByCompany({ company }), permissionsToCheck, mode);

export const checkIfCompanyIsUKBased = company => {
  return !!(company && company.data && company.data.countryCode === UK_REGISTERED_COUNTRY_CODE);
};
