import { AnyAction } from 'redux';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { showModal } from 'app-state/actions/shared';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import { useEnvVar } from 'helpers/use-env-var';
import ErrorModal from 'shared-parts/components/modal-failed';

import { ResponseGenerator } from '../types';

import {
  checkExportCommandJobProgressFailure,
  checkExportCommandJobProgressRequest,
  checkExportCommandJobProgressSuccess,
} from './csv-export.actions';
import * as constants from './csv-export.constants';
import { JobStatus } from './csv-export.state';

const graphXlEndpoint = useEnvVar('GRAPHXL_ENDPOINT');
const downloadCSVPollInterval = Number(useEnvVar('DOWNLOAD_CSV_POLL_INTERVAL')) || 3000;
const downloadCSVTimeout = Number(useEnvVar('DOWNLOAD_CSV_TIMEOUT')) || 120000;

function* sendDownloadRequest(action: AnyAction) {
  try {
    const { data } = yield call(
      request,
      `${graphXlEndpoint}${API.DownloadCSV.CreateExportCommandCSVJob()}`,
      'POST',
      action.payload,
      { snakeCaseParams: false, mode: 'cors' },
    );

    yield put({
      type: constants.sendDownloadExportCommandRequest.SUCCESS,
      data,
    });
    yield put(checkExportCommandJobProgressRequest({ data, startTime: new Date() }));
  } catch (e: any) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
    yield put({
      type: constants.sendDownloadExportCommandRequest.FAILURE,
      error: 'Something went wrong',
    });
  }
}

function* checkJobProgress(action: AnyAction) {
  try {
    const isFinish = (status: string) => status === JobStatus.Completed;
    const isFailed = (status: string) => status === JobStatus.Failed;

    if (action.startTime.getTime() - new Date().getTime() > downloadCSVTimeout) {
      yield put(checkExportCommandJobProgressFailure({ error: 'Time out' }));
      return;
    }
    yield delay(downloadCSVPollInterval);

    const response: ResponseGenerator = yield call(
      request,
      `${graphXlEndpoint}${API.DownloadCSV.CheckCSVJobStatus(action.data.id)}`,
      'GET',
    );

    if (isFinish(response.data.status)) {
      const { data }: ResponseGenerator = yield call(
        request,
        `${graphXlEndpoint}${API.DownloadCSV.DownloadCSVfile(response.data.id)}`,
        'GET',
        null,
        { mode: 'cors', to: 'blob' },
      );

      downloadBlob(data, `data.csv`);

      yield put(checkExportCommandJobProgressSuccess({ data: response.data }));
    } else if (isFailed(response.data.status)) {
      yield put(checkExportCommandJobProgressFailure());
      yield put(
        showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    } else {
      yield put(
        checkExportCommandJobProgressRequest({ data: action.data, startTime: action.startTime }),
      );
    }
  } catch (e) {
    console.error('Error downloading file', e);
    yield put(checkExportCommandJobProgressFailure());
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}
export function* checkExportCommandJobProgressWatcher() {
  yield takeLatest(constants.checkExportCommandJobProgress.REQUEST, checkJobProgress);
}

export function* sendDownloadExportCommandRequestWatcher() {
  yield takeLatest(constants.sendDownloadExportCommandRequest.REQUEST, sendDownloadRequest);
}
