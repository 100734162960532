import { FC } from 'react';

import { Box, useTheme } from '@mui/material';

import { Button } from '../../buttons';

interface MenuItemButtonProps {
  text: string;
  onClick: () => void;
}

const MenuItemButton: FC<MenuItemButtonProps> = ({ text, onClick }) => {
  const theme = useTheme();

  return (
    <Box
      key="box"
      sx={{
        padding: '16px 8px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button
        sx={{
          padding: '0px !important',
          color: `${theme.palette.secondary.main} !important`,
        }}
        variant="text"
        onClick={onClick}
      >
        {text}
      </Button>
    </Box>
  );
};

export default MenuItemButton;
