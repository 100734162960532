import { Form } from 'formik';
import styled from 'styled-components';

import { FormikButton as Button, FormikCheckbox, Link, TextButton } from 'shared-parts/components';
import { COLORS, FONTS } from 'shared-parts/constants';

const FormWrapper = styled(Form)`
  text-align: left;
`;
const PolicyWrapper = styled.div`
  color: ${({ policyColor }) => policyColor || ''};
  font-size: 12px;
  padding-top: 2px;
`;
const ButtonWrapper = styled.div`
  margin-top: 25px;
`;
const LoginButton = styled(Button)`
  height: 40px;
  width: 100px;
  user-select: none;
`;

const ForgotPassword = styled(Link)`
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PolicyCheckbox = styled(FormikCheckbox)`
  margin: 13px 0 0 0;
`;

const LostDevice = styled.div`
  cursor: pointer;
  text-align: left;
  font-size: 12px;
  font-family: ${FONTS.mainFont};
  color: ${COLORS.neutral800};
  padding-top: 10px;
  &:active,
  &:focus,
  &:hover {
    color: ${COLORS.secondary};
  }
`;

const CancelButton = styled(TextButton)`
  margin-right: 20px;
`;

const Title = styled.div`
  color: ${props => props.titleColor || ''};
  font-family: ${FONTS.oswaldFont};
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 35px;
  margin-bottom: 55px;
`;

const HoneyPot = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

export {
  Title,
  FormWrapper,
  PolicyWrapper,
  ButtonWrapper,
  LoginButton,
  ForgotPassword,
  PolicyCheckbox,
  LostDevice,
  CancelButton,
  HoneyPot,
};
