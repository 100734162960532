import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const OverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 80px;
  right: 0;
  width: ${({ width = '640px' }) => width};
  background: ${COLORS.neutral200};
  box-shadow: 0 8px 8px ${COLORS.neutral500};
  border: 1px solid ${COLORS.neutral500};
  z-index: 1001;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export { OverlayWrapper, OverlayContainer };
