import Routes from 'constants/routes';
import UserData from 'constants/user-data';
import getUsersOrganizationType from 'helpers/get-users-organization-type';

const getOperationalUserRedirectUrl = ({ companyIds }) =>
  companyIds
    ? UserData.Operations['Login Redirection Url']({
        criteria: {
          companyId: companyIds[0],
        },
      })
    : Routes.Globacap.UsersPage();

export const getCompanyRedirectUrl = ({ companyIds = [] }) =>
  UserData.Company['Login Redirection Url']({
    criteria: {
      companyId: companyIds[0],
    },
  });

export const getUrlDependingOnOnboardingCompletion = (
  isOnboardingComplete,
  userOrganizationType,
  user = {},
) => {
  if (!isOnboardingComplete) return Routes.Onboarding();

  return userOrganizationType === 'Company'
    ? getCompanyRedirectUrl(user)
    : UserData.MultiCompany['Login Redirection Url']();
};

export default user => {
  const unauthenticated = JSON.stringify(user) === '{}';
  const userOrganizationType = unauthenticated ? 'Unauthenticated' : getUsersOrganizationType(user);
  const { onboardingStatus } = user;
  const isOnboardingComplete = onboardingStatus === 'complete';

  return {
    Unauthenticated: {
      redirectUrl: { path: Routes.Login() },
      white: [],
      black: [
        { path: Routes.Root(), exact: true },
        { path: Routes.Globacap.Root() },
        { path: Routes.Admin.Root() },
        { path: Routes.MultiCompany.Root() },
        { path: Routes.Forbidden() },
        { path: Routes.Onboarding() },
      ],
    },
    Operations: {
      redirectUrl: {
        path: getOperationalUserRedirectUrl(user),
      },
      white: [],
      black: [
        { path: Routes.Admin.Root() },
        { path: Routes.MultiCompany.Root() },
        { path: Routes.Root(), exact: true },
        { path: Routes.Login() },
        { path: Routes.ResetPassword() },
        { path: Routes.CreatePassword() },
        { path: Routes.Onboarding() },
      ],
    },
    MultiCompany: {
      redirectUrl: {
        path: getUrlDependingOnOnboardingCompletion(
          isOnboardingComplete,
          userOrganizationType,
          user,
        ),
      },
      white: isOnboardingComplete ? [] : [{ path: Routes.Onboarding() }],
      black: [
        { path: Routes.Admin.Root() },
        { path: Routes.Globacap.Root() },
        { path: Routes.Root(), exact: true },
        { path: Routes.Login() },
        { path: Routes.ResetPassword() },
        { path: Routes.CreatePassword() },
        {
          path: isOnboardingComplete ? Routes.Onboarding() : Routes.MultiCompany.Root(),
        },
      ],
    },
    Company: {
      redirectUrl: {
        path: getUrlDependingOnOnboardingCompletion(
          isOnboardingComplete,
          userOrganizationType,
          user,
        ),
      },
      white: isOnboardingComplete ? [] : [{ path: Routes.Onboarding() }],
      black: [
        { path: Routes.Globacap.Root() },
        { path: Routes.MultiCompany.Root() },
        { path: Routes.Root(), exact: true },
        { path: Routes.Login() },
        { path: Routes.ResetPassword() },
        { path: Routes.CreatePassword() },
        { path: Routes.Admin.Overview() },
        { path: isOnboardingComplete ? Routes.Onboarding() : Routes.Admin.Root() },
      ],
    },
  }[userOrganizationType];
};
