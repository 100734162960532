import { AnyAction } from 'redux';

import * as constants from 'app-state/constants';

export const currencyRatesReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.GET_CURRENCY_RATE_SUCCESS:
      return { ...state, [action.currencyPairCode]: action.exchangeRate };
    case constants.GET_CURRENCY_RATE_ERROR:
      return action.error;
    default:
      return state;
  }
};
