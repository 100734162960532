import { Dispatch } from 'redux';

import { useSubmit } from '@frontend/http';

import { hideModal, showModal } from 'app-state/actions';

import API from 'constants/api';
import { getDealAlias } from 'helpers/get-deal-alias';
import { ExclamationMark } from 'svg-icons';
import ConfirmationModal from 'shared/confirmation-modal';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ErrorModal from 'shared-parts/components/modal-failed';
import { COLORS } from 'shared-parts/constants';

const useVerifyInvestor = (dispatch: Dispatch<any>) => {
  return useSubmit(API.InvestorKycManualVerification(':uuid'), 'POST', {
    onSuccess: () => {
      dispatch(
        showModal({
          component: SuccessModal,
          modalWidth: 600,
          iconSize: 40,
          title: 'User Verified',
        }),
      );
    },
    onError: () => {
      dispatch(
        showModal({
          component: ErrorModal,
        }),
      );
    },
  });
};

const confirmAndVerifyInvestor = (
  dispatch: Dispatch<any>,
  investorName: string,
  verifyCallback: () => void,
) => {
  dispatch(
    showModal({
      component: ConfirmationModal,
      icon: ExclamationMark('64', '64', COLORS.systemRed),
      title: 'Verify Investor',
      text: `You are about to approve the verification request for ${investorName}. They will then be permitted to submit orders and IOIs for ${getDealAlias()}s they have access to.`,
      handleConfirmation: verifyCallback,
      hideModal: () => dispatch(hideModal()),
      closable: true,
    }),
  );
};

export { useVerifyInvestor, confirmAndVerifyInvestor };
