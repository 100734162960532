import { AnyAction } from 'redux';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { hideSideForm, showModal } from 'app-state/actions/shared';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import { useEnvVar } from 'helpers/use-env-var';
import ErrorModal from 'shared-parts/components/modal-failed';

import { ResponseGenerator } from '../types';

import * as constants from './uploadCSV.constants';
import { JobStatus } from './uploadCSV.state';

const bulkUploadPollInterval = Number(useEnvVar('BULK_UPLOAD_POLL_INTERVAL')) || 3000;
const bulkUploadTimeout = Number(useEnvVar('BULK_UPLOAD_TIMEOUT')) || 120000;

function* sendUploadTransactionsRequest(action: AnyAction) {
  try {
    const { data } = yield call(request, API.UploadCsv.UploadTransactions(), 'POST', action.data, {
      mode: 'cors',
      ignoredErrorCodes: [500],
    });

    yield put({
      type: constants.sendUploadTransactionsRequest.SUCCESS,
      data,
      uploadKey: action.uploadKey,
    });

    yield put({
      type: constants.checkJobProgress.REQUEST,
      data,
      startTime: new Date(),
      uploadKey: action.uploadKey,
    });
  } catch (e: any) {
    const error = e?.response?.message?.details
      ? JSON.stringify(e?.response?.message?.details)
      : e?.response?.message?.details || e?.response?.message;
    yield put({
      type: constants.sendUploadTransactionsRequest.FAILURE,
      error: error || 'Something went wrong',
      uploadKey: action.uploadKey,
    });
  }
}

function* sendUploadConvertibleNotesRequest(action: AnyAction) {
  try {
    const { data } = yield call(request, API.UploadCsv.ConvertibleNotes(), 'POST', action.data, {
      mode: 'cors',
      ignoredErrorCodes: [500],
    });

    yield put({
      type: constants.sendUploadConvertibleNotesRequest.SUCCESS,
      data,
      uploadKey: action.uploadKey,
    });
    yield put({
      type: constants.checkJobProgress.REQUEST,
      data,
      startTime: new Date(),
      uploadKey: action.uploadKey,
    });
  } catch (e: any) {
    const error = e?.response?.message?.details
      ? JSON.stringify(e?.response?.message?.details)
      : e?.response?.message?.details || e?.response?.message;
    yield put({
      type: constants.sendUploadConvertibleNotesRequest.FAILURE,
      error: error || 'Something went wrong',
      uploadKey: action.uploadKey,
    });
  }
}

function* sendUploadShareholdersRequest(action: AnyAction) {
  try {
    const { data } = yield call(request, API.UploadCsv.Shareholders(), 'POST', action.data, {
      mode: 'cors',
      ignoredErrorCodes: [500],
      timeout: bulkUploadTimeout,
    });

    yield put({
      type: constants.sendUploadShareholdersRequest.SUCCESS,
      data,
      uploadKey: action.uploadKey,
    });
    yield put({
      type: constants.checkJobProgress.REQUEST,
      data,
      startTime: new Date(),
      uploadKey: action.uploadKey,
    });
  } catch (e: any) {
    const error = e?.response?.message?.details
      ? JSON.stringify(e?.response?.message?.details)
      : e?.response?.message?.details;
    yield put({
      type: constants.sendUploadShareholdersRequest.FAILURE,
      error: error || 'Something went wrong',
      uploadKey: action.uploadKey,
    });
  }
}

function* checkJobProgress(action: AnyAction) {
  try {
    const isFinished = (status: string) => status === JobStatus.Completed;
    const isFailed = (status: string) => status === JobStatus.Failed;

    if (action.startTime.getTime() - new Date().getTime() > bulkUploadTimeout) {
      yield put({
        type: constants.checkJobProgress.FAILURE,
        error: 'Time out',
        uploadKey: action.uploadKey,
      });
      return;
    }
    yield delay(bulkUploadPollInterval);
    const response: ResponseGenerator = yield call(
      request,
      API.UploadCsv.CheckJobStatus(action.data.job.id),
      'GET',
      null,
      { mode: 'cors', ignoredErrorCodes: [500] },
    );
    if (isFinished(response.data.status)) {
      yield put({
        type: constants.checkJobProgress.SUCCESS,
        data: response.data,
        uploadKey: action.uploadKey,
      });
    } else if (isFailed(response.data.status)) {
      yield put({
        type: constants.checkJobProgress.FAILURE,
        error: response.data.message,
        uploadKey: action.uploadKey,
      });
    } else {
      yield put({
        type: constants.checkJobProgress.REQUEST,
        data: action.data,
        uploadKey: action.uploadKey,
        startTime: action.startTime,
      });
    }
  } catch (e: any) {
    yield put({
      type: constants.checkJobProgress.FAILURE,
      error: e?.response?.message || 'Something went wrong',
      uploadKey: action.uploadKey,
    });
  }
}

function* downloadCSVTemplate(action: AnyAction) {
  try {
    const { data }: ResponseGenerator = yield call(
      request,
      API.UploadCsv.DownloadTemplate(action.payload.templateType),
      'GET',
      null,
      { mode: 'cors', to: 'blob' },
    );

    downloadBlob(data, `${action.payload.templateType}.csv`);
  } catch (e) {
    yield put(hideSideForm());
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* sendUploadBondsAndLoansRequest(action: AnyAction) {
  try {
    const { data } = yield call(request, API.UploadCsv.BondsAndLoans(), 'POST', action.data, {
      mode: 'cors',
      ignoredErrorCodes: [500],
    });

    yield put({
      type: constants.sendUploadBondsAndLoansRequest.SUCCESS,
      data,
      uploadKey: action.uploadKey,
    });

    yield put({
      type: constants.checkJobProgress.REQUEST,
      data,
      startTime: new Date(),
      uploadKey: action.uploadKey,
    });
  } catch (e: any) {
    const error = e?.response?.message?.details
      ? JSON.stringify(e?.response?.message?.details)
      : e?.response?.message?.details || e?.response?.message;
    yield put({
      type: constants.sendUploadBondsAndLoansRequest.FAILURE,
      error: error || 'Something went wrong',
      uploadKey: action.uploadKey,
    });
  }
}

export function* downloadCSVTemplateWatcher() {
  yield takeLatest(constants.downloadCSVTemplate.REQUEST, downloadCSVTemplate);
}

export function* checkUploadCSVJobProgressWatcher() {
  yield takeLatest(constants.checkJobProgress.REQUEST, checkJobProgress);
}

export function* sendUploadTransactionsRequestWatcher() {
  yield takeLatest(constants.sendUploadTransactionsRequest.REQUEST, sendUploadTransactionsRequest);
}

export function* sendUploadConvertibleNotesRequestWatcher() {
  yield takeLatest(
    constants.sendUploadConvertibleNotesRequest.REQUEST,
    sendUploadConvertibleNotesRequest,
  );
}

export function* sendUploadShareholdersRequestWatcher() {
  yield takeLatest(constants.sendUploadShareholdersRequest.REQUEST, sendUploadShareholdersRequest);
}

export function* sendUploadBondsAndLoansRequestWatcher() {
  yield takeLatest(
    constants.sendUploadBondsAndLoansRequest.REQUEST,
    sendUploadBondsAndLoansRequest,
  );
}
