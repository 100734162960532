export const GET_SHARE_OPTIONS = 'GET_SHARE_OPTIONS';
export const GET_SHARE_OPTIONS_SUCCESS = 'GET_SHARE_OPTIONS_SUCCESS';
export const GET_SHARE_OPTIONS_ERROR = 'GET_SHARE_OPTIONS_ERROR';
export const CLEAR_SHARE_OPTIONS = 'CLEAR_SHARE_OPTIONS';

export const GET_GRANT_HOLDERS = 'GET_GRANT_HOLDERS';
export const GET_GRANT_HOLDERS_SUCCESS = 'GET_GRANT_HOLDERS_SUCCESS';
export const GET_GRANT_HOLDERS_ERROR = 'GET_GRANT_HOLDERS_ERROR';

export const GET_GRANT_HOLDERS_CAPITAL = 'GET_GRANT_HOLDERS_CAPITAL';
export const GET_GRANT_HOLDERS_CAPITAL_SUCCESS = 'GET_GRANT_HOLDERS_CAPITAL_SUCCESS';
export const GET_GRANT_HOLDERS_CAPITAL_ERROR = 'GET_GRANT_HOLDERS_CAPITAL_ERROR';

export const GET_HOLDERS = 'GET_HOLDERS';
export const GET_HOLDERS_SUCCESS = 'GET_HOLDERS_SUCCESS';
export const GET_HOLDERS_ERROR = 'GET_HOLDERS_ERROR';

export const RESET_HOLDERS = 'RESET_HOLDERS';

export const REMOVE_UNVESTED_OPTIONS = 'REMOVE_UNVESTED_OPTIONS';
export const REMOVE_UNVESTED_OPTIONS_SUCCESS = 'REMOVE_UNVESTED_OPTIONS_SUCCESS';
export const REMOVE_UNVESTED_OPTIONS_ERROR = 'REMOVE_UNVESTED_OPTIONS_ERROR';

export const CREATE_SHARE_OPTION_PLAN = 'CREATE_SHARE_OPTION_PLAN';

export const ADD_SHARE_OPTION_PLAN_DOCUMENT = 'ADD_SHARE_OPTION_PLAN_DOCUMENT';
export const ADD_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS = 'ADD_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS';
export const ADD_SHARE_OPTION_PLAN_DOCUMENT_ERROR = 'ADD_SHARE_OPTION_PLAN_DOCUMENT_ERROR';

export const DELETE_SHARE_OPTION_PLAN_DOCUMENT = 'DELETE_SHARE_OPTION_PLAN_DOCUMENT';
export const DELETE_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS =
  'DELETE_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS';
export const DELETE_SHARE_OPTION_PLAN_DOCUMENT_ERROR = 'DELETE_SHARE_OPTION_PLAN_DOCUMENT_ERROR';

export const GET_SHARE_OPTION_PLAN = 'GET_SHARE_OPTION_PLAN';
export const GET_SHARE_OPTION_PLAN_SUCCESS = 'GET_SHARE_OPTION_PLAN_SUCCESS';
export const GET_SHARE_OPTION_PLAN_ERROR = 'GET_SHARE_OPTION_PLAN_ERROR';

export const UPDATE_SHARE_OPTION_PLAN = 'UPDATE_SHARE_OPTION_PLAN';
export const UPDATE_SHARE_OPTION_PLAN_SUCCESS = 'UPDATE_SHARE_OPTION_PLAN_SUCCESS';
export const UPDATE_SHARE_OPTION_PLAN_ERROR = 'UPDATE_SHARE_OPTION_PLAN_ERROR';

export const GET_SHARE_OPTION_DATA = 'GET_SHARE_OPTION_DATA';
export const GET_SHARE_OPTION_DATA_SUCCESS = 'GET_SHARE_OPTION_DATA_SUCCESS';
export const GET_SHARE_OPTION_DATA_ERROR = 'GET_SHARE_OPTION_DATA_ERROR';
export const RESET_SHARE_OPTION_DATA = 'RESET_SHARE_OPTION_DATA';

export const EXERCISE_SHARE_OPTIONS = 'EXERCISE_SHARE_OPTIONS';
export const EXERCISE_SHARE_OPTIONS_SUCCESS = 'EXERCISE_SHARE_OPTIONS_SUCCESS';
export const EXERCISE_SHARE_OPTIONS_ERROR = 'EXERCISE_SHARE_OPTIONS_ERROR';
export const RESET_EXERCISE_SHARE_OPTIONS = 'RESET_EXERCISE_SHARE_OPTIONS';

export const CLEAR_SHARE_OPTION_PLAN = 'CLEAR_SHARE_OPTION_PLAN';

export const ADD_NEW_GRANT = 'ADD_NEW_GRANT';
export const ADD_NEW_GRANT_SUCCESS = 'ADD_NEW_GRANT_SUCCESS';
export const ADD_NEW_GRANT_ERROR = 'ADD_NEW_GRANT_ERROR';
export const RESET_NEW_GRANT_ADDING_STATE = 'RESET_NEW_GRANT_ADDING_STATE';

export const GET_GRANT = 'GET_GRANT';
export const GET_GRANT_SUCCESS = 'GET_GRANT_SUCCESS';
export const GET_GRANT_ERROR = 'GET_GRANT_ERROR';

export const EDIT_GRANT = 'EDIT_GRANT';
export const EDIT_GRANT_SUCCESS = 'EDIT_GRANT_SUCCESS';
export const EDIT_GRANT_ERROR = 'EDIT_GRANT_ERROR';

export const ADD_GRANT_DOCUMENT = 'ADD_GRANT_DOCUMENT';
export const ADD_GRANT_DOCUMENT_SUCCESS = 'ADD_GRANT_DOCUMENT_SUCCESS';
export const ADD_GRANT_DOCUMENT_ERROR = 'ADD_GRANT_DOCUMENT_ERROR';

export const DELETE_GRANT_DOCUMENT = 'DELETE_GRANT_DOCUMENT';
export const DELETE_GRANT_DOCUMENT_SUCCESS = 'DELETE_GRANT_DOCUMENT_SUCCESS';
export const DELETE_GRANT_DOCUMENT_ERROR = 'DELETE_GRANT_DOCUMENT_ERROR';

export const GET_GRANT_HOLDER_DETAILS = 'GET_GRANT_HOLDER_DETAILS';
export const GET_GRANT_HOLDER_DETAILS_SUCCESS = 'GET_GRANT_HOLDER_DETAILS_SUCCESS';
export const GET_GRANT_HOLDER_DETAILS_ERROR = 'GET_GRANT_HOLDER_DETAILS_ERROR';
export const RESET_GRANT_HOLDER_DETAILS = 'RESET_GRANT_HOLDER_DETAILS';

export const UPDATE_GRANT_HOLDER_DETAILS = 'UPDATE_GRANT_HOLDER_DETAILS';

export const DELETE_GRANT = 'DELETE_GRANT';
export const DELETE_GRANT_ERROR = 'DELETE_GRANT_ERROR';

export const VIEW_GRANT = 'VIEW_GRANT';

export const GET_GRANT_TRANSACTIONAL_HISTORY = 'GET_GRANT_TRANSACTIONAL_HISTORY';
export const GET_GRANT_TRANSACTIONAL_HISTORY_SUCCESS = 'GET_GRANT_TRANSACTIONAL_HISTORY_SUCCESS';
export const GET_GRANT_TRANSACTIONAL_HISTORY_ERROR = 'GET_GRANT_TRANSACTIONAL_HISTORY_ERROR';

export const GET_GRANT_HOLDERS_EMAILS = 'GET_GRANT_HOLDERS_EMAILS';
export const GET_GRANT_HOLDERS_EMAILS_SUCCESS = 'GET_GRANT_HOLDERS_EMAILS_SUCCESS';
export const GET_GRANT_HOLDERS_EMAILS_ERROR = 'GET_GRANT_HOLDERS_EMAILS_ERROR';
export const RESET_GRANT_HOLDERS_EMAILS = 'RESET_GRANT_HOLDERS_EMAILS';

export const SEARCH_HOLDERS = 'SEARCH_HOLDERS';
export const SEARCH_HOLDERS_SUCCESS = 'SEARCH_HOLDERS_SUCCESS';
export const SEARCH_HOLDERS_ERROR = 'SEARCH_HOLDERS_ERROR';
export const RESET_HOLDERS_SEARCH = 'RESET_HOLDERS_SEARCH';
