import { COLORS } from 'shared-parts/constants';

const BinXIcon = (width = '14', height = '18', fillColor = COLORS.systemRed) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 18">
    <path
      fill={fillColor}
      fillOpacity="nonzero"
      transform="translate(-417 -1261)"
      d="M418 1277c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2h-8c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0l1.42 1.42 1.42-1.42a.996.996 0 1 1 1.41 1.41l-1.42 1.42 1.42 1.42a.996.996 0 1 1-1.41 1.41l-1.42-1.42-1.42 1.42a.996.996 0 1 1-1.41-1.41l1.42-1.42-1.42-1.42a.996.996 0 0 1 0-1.41zm6.33-7.17l-.71-.71c-.18-.18-.44-.29-.7-.29h-4.18c-.26 0-.52.11-.7.29l-.71.71H418c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
    />
  </svg>
);

export default BinXIcon;
