import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchInvestorInvestments, resetInvestorInvestments } from 'app-state/investors/actions';
import {
  getInvestorInvestmentsItems,
  getInvestorInvestmentsLoading,
  getInvestorInvestmentsPagination,
} from 'app-state/investors/selectors';

import API from 'constants/api';
import useTypedSelector from 'hooks/use-typed-selector';
import DownloadButton from 'shared/download-button';
import DownloadArrowIcon from 'shared-parts/components/svg-icons/download-arrow-icon';
import { COLORS } from 'shared-parts/constants';

import { DOWNLOAD_AS_CSV, INVESTOR_HISTORY_HEADERS, INVESTOR_HISTORY_KEYS } from '../constants';
import type { InvestorInvestments } from '../types';

import { DownloadContainer, StyledPagination, Table } from './investor-history.styled';

const { secondary } = COLORS;

const DownloadIcon = <DownloadArrowIcon fill={secondary} />;

const InvestorHistory = ({ investorUuid }: InvestorInvestments) => {
  const dispatch = useDispatch();
  const investments = useTypedSelector(getInvestorInvestmentsItems);
  const loading = useTypedSelector(getInvestorInvestmentsLoading);
  const pagination = useTypedSelector(getInvestorInvestmentsPagination);

  const fetchInvestorInvestmentsData = ({
    page = pagination.page,
    perPage = pagination.perPage,
    totalPages = pagination.totalPages,
    totalObjects = pagination.totalObjects,
    uuid = investorUuid,
  }) => {
    dispatch(fetchInvestorInvestments({ page, perPage, uuid, totalPages, totalObjects }));
  };

  useEffect(() => {
    if (pagination) {
      fetchInvestorInvestmentsData({
        page: 1,
        totalPages: 0,
        totalObjects: 0,
      });
    }

    return () => {
      dispatch(resetInvestorInvestments());
    };
  }, []);

  const handlePageChange = (page: number) => {
    fetchInvestorInvestmentsData({ page });
  };

  const handlePaginationOptionChange = (perPage: number) => {
    fetchInvestorInvestmentsData({
      page: 1,
      perPage,
      totalPages: 0,
    });
  };

  return (
    <>
      <Table
        header={INVESTOR_HISTORY_HEADERS}
        keys={INVESTOR_HISTORY_KEYS}
        data={investments}
        loading={loading}
      />
      <StyledPagination
        disabled={loading}
        currentPage={+pagination.page}
        currentOption={+pagination.perPage}
        totalPages={+pagination.totalPages}
        handlePageChange={handlePageChange}
        handlePaginationOptionChange={handlePaginationOptionChange}
        className=""
      />
      <DownloadContainer>
        <DownloadButton
          icon={DownloadIcon}
          fileName="Investor-Investments.csv"
          api={API.InvestorInvestmentsExport(investorUuid)}
          disabled={!investments || investments.length === 0}
        >
          {DOWNLOAD_AS_CSV}
        </DownloadButton>
      </DownloadContainer>
    </>
  );
};

export default InvestorHistory;
