import { all, AllEffect, ForkEffect, spawn } from 'redux-saga/effects';

import {
  createBrokersNoteWatcher,
  downloadBrokersNoteWatcher,
} from 'app-state/brokers-note/brokers-note.saga';
import {
  deleteDraftCommunicationWatcher,
  draftCommunicationAddAttachmentWatcher,
  draftCommunicationAddResponseWatcher,
  draftCommunicationDeleteAttachmentWatcher,
  draftCommunicationDeleteResponseWatcher,
  exportCommunicationResponsesStatisticsWatcher,
  fetchShareholdersEmailsWatcher,
  getAggregatedResponsesStatisticsWatcher,
  getCommunicationResponsesStatisticsWatcher,
  getCommunicationsWatcher,
  getDraftCommunicationWatcher,
  getPublishedCommunicationWatcher,
  publishDraftCommunicationWatcher,
  updateDraftCommunicationWatcher,
} from 'app-state/communication/sagas';
import { getCompanyEntitiesWatcher } from 'app-state/company-entities/sagas';
import {
  checkJobProgressWatcher,
  sendDownloadRequestWatcher,
} from 'app-state/csv-download/csv-download.saga';
import {
  checkExportCommandJobProgressWatcher,
  sendDownloadExportCommandRequestWatcher,
} from 'app-state/csv-export/csv-export.saga';
import { getCurrenciesWatcher } from 'app-state/currencies/sagas';
import { editCompanyOverviewWatcher } from 'app-state/deal/sagas';
import { createDealWatcher, getDealsWatcher, getUserDealsWatcher } from 'app-state/deals/sagas';
import {
  changeOrderDocumentAgreedStatusWatcher,
  deleteOrderDocumentWatcher,
  fetchOrderDocumentsWatcher,
  uploadOrderDocumentWatcher,
} from 'app-state/documents/sagas';
import {
  adjustDealIOIWatcher,
  approveDealIOIWatcher,
  cancelDealIOIWatcher,
  fetchDealIOIDetailsWatcher,
  fetchIndicationOfInterestDataWatcher,
} from 'app-state/indication-of-interest/sagas';
import {
  addWalletTransferWatcher,
  editInvestorWatcher,
  fetchInvestorAgentsPermissionsWatcher,
  fetchInvestorDealsWatcher,
  fetchInvestorInvestmentsWatcher,
  fetchInvestorPreferencesWatcher,
  fetchInvestorsWatcher,
  fetchInvestorWatcher,
  fetchWalletReferenceWatcher,
  fetchWalletTransactionsWatcher,
  inviteInvestorWatcher,
  updateInvestorDealsWatcher,
} from 'app-state/investors/sagas';
import {
  changePasswordWatcher,
  createAccountUserWatcher,
  deleteAccountUserWatcher,
  getAccountUsersWatcher,
  getMyAccountDetailsWatcher,
  resendEmailConfirmationWatcher,
  updateAccountUserWatcher,
} from 'app-state/sagas/account';
import {
  forgotPasswordWatcher,
  loginWatcher,
  logoutWatcher,
  oauth2LoginWatcher,
  oauth2LogoutWatcher,
  resetPasswordWatcher,
  saveUserDataWatcher,
  signUpWatcher,
  twoFactorAuthConfirmWatcher,
  twoFactorAuthDisableWatcher,
  twoFactorAuthGetCodeWatcher,
} from 'app-state/sagas/authentication';
import {
  createCompanySummaryWatcher,
  editCompanySummaryWatcher,
  editCompanyWatcher,
  fetchCompanyWatcher,
  getCompaniesWatcher,
  getCompanySummaryWatcher,
  getCompanyTagsWatcher,
  getCompanyWatcher,
  updateCompaniesHouseAuthCodeWatcher,
  updateCompanyViewPermissionsWatcher,
  verifyCompanyKybWatcher,
} from 'app-state/sagas/companies';
import { getCountriesWatcher } from 'app-state/sagas/countries';
import { getCurrencyRateWatcher } from 'app-state/sagas/currency-rate';
import { getDashboardInfoWatcher } from 'app-state/sagas/dashboard';
import {
  approveDealOrderWatcher,
  attachTeamMemberWatcher,
  cancelDealOrderWatcher,
  changeDocumentAgreedStatusWatcher,
  completeDealWatcher,
  createDealDetailsWatcher,
  createDealInvitesWatcher,
  createDescriptionListComponentWatcher,
  createDocumentComponentWatcher,
  createImageComponentWatcher,
  createIssuanceWatcher,
  createSectionWatcher,
  createTableComponentWatcher,
  createTeamComponentInRegularSectionWatcher,
  createTeamComponentWatcher,
  createTeamMemberInOverviewSectionWatcher,
  createTextComponentWatcher,
  createVideoComponentWatcher,
  deleteComponentWatcher,
  deleteInvestorDocumentWatcher,
  deleteSectionWatcher,
  detachTeamMemberWatcher,
  editDealConfigurationWatcher,
  editDealDetailsWatcher,
  editDealStructureWatcher,
  editDescriptionListComponentWatcher,
  editDocumentComponentWatcher,
  editImageComponentWatcher,
  editOrderMoneyTransferredWatcher,
  editSectionTitleWatcher,
  editTableComponentWatcher,
  editTeamMemberWatcher,
  editTextComponentWatcher,
  editVideoComponentWatcher,
  fetchDealDataWatcher,
  fetchDealDocumentsDataWatcher,
  fetchDealInfoWatcher,
  fetchDealInviteEmailTemplateWatcher,
  fetchDealListWatcher,
  fetchDealOrderDetailsWatcher,
  fetchDealOrderHistoryWatcher,
  fetchDealOrdersWatcher,
  fetchDealPagesDataWatcher,
  fetchDealSettlementsWatcher,
  fetchDealSummaryWatcher,
  fetchDealWatcher,
  fetchInvestorDocumentsWatcher,
  fetchInvestorInfoWatcher,
  fetchIssuanceWatcher,
  fetchPageWatcher,
  fetchTeamMembersWatcher,
  markAsFundsReceivedWatcher,
  publishDealWatcher,
  reorderComponentWatcher,
  reorderSectionsWatcher,
  reorderTeamMemberWatcher,
  requestDealPublishWatcher,
  resendInvestorInviteWatcher,
  resendIPInvestorInviteWatcher,
  unpublishDealWatcher,
  updateDealDataWatcher,
  updateDealPageHeaderWatcher,
  uploadDealDocumentWatcher,
  uploadInvestorDocumentWatcher,
} from 'app-state/sagas/deal';
import { downloadFileWatcher } from 'app-state/sagas/file';
import {
  addFilingDocumentWatcher,
  createConfirmationStatementWatcher,
  deleteFilingDocumentWatcher,
  downloadFilingsAsCSVWatcher,
  getAllotmentDataWatcher,
  getAllottedSharesWatcher,
  getCompanyProfileWatcher,
  getFilingDetailsWatcher,
  getFilingsHistoryWatcher,
  getShareCapitalWatcher,
  getShareholderInformationWatcher,
  getSicCodesWatcher,
  submitSh01FormWatcher,
} from 'app-state/sagas/filings';
import {
  createNavigationItemWatcher,
  deleteNavigationItemWatcher,
  editNavigationItemWatcher,
  fetchEditableNavigationWatcher,
  fetchNavigationWatcher,
  fetchSubNavigationWatcher,
  generateBasicNavigationWatcher,
  reorderNavigationItemWatcher,
} from 'app-state/sagas/navigation';
import { updateNickelledUserWatcher } from 'app-state/sagas/nickelled';
import { generateOnboardingLinkWatcher } from 'app-state/sagas/onboarding-link';
import {
  getSubscriptionTemplatesPerCompanyWatcher,
  getSubscriptionTemplatesWatcher,
  selectPackageWatcher,
} from 'app-state/sagas/package';
import { getPermissionLevelsWatcher, getPermissionsWatcher } from 'app-state/sagas/permissions';
import {
  addSecondaryTransactionDocumentWatcher,
  approveSecondaryTransactionDocumentWatcher,
  approveTransactionWatcher,
  deleteSecondaryTransactionDocumentWatcher,
  getAllSecondaryTransactionsWatcher,
  getIntroducerFeesWatcher,
  getSecondaryTransactionsWatcher,
  getSecondaryTransactionWatcher,
  rejectSecondaryTransactionDocumentWatcher,
  rejectTransactionWatcher,
  updateIntroducerFeesWatcher,
} from 'app-state/sagas/secondary-transactions';
import { getSettingsWatcher } from 'app-state/sagas/settings';
import {
  buybackSharesWatcher,
  cancelSharesWatcher,
  convertSharesForIndividualHolderWatcher,
  convertSharesWatcher,
  createShareClassWatcher,
  editShareClassDescriptionWatcher,
  editShareClassWatcher,
  getQuantitiesForPairWatcher,
  getShareClassesWatcher,
  shareClassEditRetrieveQuantityWatcher,
  updateTransactionsPermissionWatcher,
} from 'app-state/sagas/share-class';
import {
  blockHolderWatcher,
  editShareIncentivePlansWatcher,
  getShareIncentivePlanWatcher,
  importPurchaseTemplateWatcher,
  importSaleAndTransferTemplateWatcher,
  unblockHolderWatcher,
} from 'app-state/sagas/share-incentive';
import {
  addGrantDocumentWatcher,
  addNewGrantWatcher,
  addShareOptionPlanDocumentWatcher,
  createShareOptionWatcher,
  deleteGrantDocumentWatcher,
  deleteGrantWatcher,
  deleteShareOptionPlanDocumentWatcher,
  editGrantWatcher,
  exerciseShareOptionsWatcher,
  getGrantHolderDetailsWatcher,
  getGrantHoldersCapitalWatcher,
  getGrantHoldersEmailsWatcher,
  getGrantHoldersWatcher,
  getGrantTransactionalHistoryWatcher,
  getGrantWatcher,
  getHoldersWatcher,
  getShareOptionDataWatcher,
  getShareOptionPlanWatcher,
  getShareOptionsWatcher,
  removeUnvestedOptionsWatcher,
  searchHoldersWatcher,
  updateGrantHolderDetailsWatcher,
  updateShareOptionWatcher,
} from 'app-state/sagas/share-options';
import {
  confirmEmailWatcher,
  createPasswordWatcher,
  pingWatcher,
  resendEmailForCurrentUserWatcher,
  updateCurrentUserWatcher,
  updateMyDetailsWatcher,
} from 'app-state/sagas/shared';
import {
  addTransactionNoteWatcher,
  allocateShareholdingWatcher,
  createShareholderWatcher,
  downloadShareholderCertWatcher,
  downloadShareholderRegistryWatcher,
  editShareholderDetailsWatcher,
  editShareholderPerformanceWatcher,
  getBulkWelcomeEmailInfoWatcher,
  getHoldingsWatcher,
  getRegisteredShareholdersWatcher,
  getShareholdersDropdownDataWatcher,
  getShareholdersWatcher,
  getTransferShareholdersWatcher,
  lookupShareholderWatcher,
  removeTransactionNoteWatcher,
  searchShareholdersWatcher,
  sendBulkWelcomeEmailWatcher,
  shareholderTransactionRevertWatcher,
  transferShareholdingWatcher,
  updateShareholderTransactionsWatcher,
  updateTransactionNoteWatcher,
} from 'app-state/sagas/shareholders';
import {
  createTaglineWatcher,
  deleteTaglineWatcher,
  editTaglineWatcher,
} from 'app-state/sagas/tagline';
import { getTemplatesURLWatcher } from 'app-state/sagas/templates';
import { acceptTermsWatcher } from 'app-state/sagas/terms';
import { getTreasuryAccountWatcher } from 'app-state/sagas/treasury-account';
import {
  checkCreateUserPermissionWatcher,
  createUserWatcher,
  deleteUserWatcher,
  getUsersWatcher,
  updateUserWatcher,
} from 'app-state/sagas/users';
import { getVerificationWatcher } from 'app-state/sagas/verification';
import {
  getShareCertificateConfigWatcher,
  updateShareCertificateConfigWatcher,
} from 'app-state/share-certificates/sagas';
import {
  importBonusTemplateWatcher,
  uploadBonusDataWatcher,
} from 'app-state/share-plan-bonus/share-plan-bonus.saga';
import {
  sharePlanBulkInviteEmployeesWatcher,
  sharePlanInviteEmployeesWatcher,
} from 'app-state/share-plan-invite-employees/share-plan-invite-employees.saga';
import {
  importPayrollTemplateWatcher,
  uploadPayrollDataWatcher,
} from 'app-state/share-plan-payroll/share-plan-payroll.saga';
import { getTimezonesWatcher } from 'app-state/timezones/sagas';
import {
  checkUploadCSVJobProgressWatcher,
  downloadCSVTemplateWatcher,
  sendUploadBondsAndLoansRequestWatcher,
  sendUploadConvertibleNotesRequestWatcher,
  sendUploadShareholdersRequestWatcher,
  sendUploadTransactionsRequestWatcher,
} from 'app-state/uploadCSV/uploadCSV.saga';

export default function* rootSaga(): Generator<AllEffect<ForkEffect>> {
  yield all([
    spawn(fetchOrderDocumentsWatcher),
    spawn(uploadOrderDocumentWatcher),
    spawn(deleteOrderDocumentWatcher),
    spawn(changeOrderDocumentAgreedStatusWatcher),
    spawn(getDealsWatcher),
    spawn(getUserDealsWatcher),
    spawn(createDealWatcher),
    spawn(saveUserDataWatcher),
    spawn(editDealConfigurationWatcher),
    spawn(uploadDealDocumentWatcher),
    spawn(getTimezonesWatcher),
    spawn(signUpWatcher),
    spawn(loginWatcher),
    spawn(getPermissionsWatcher),
    spawn(logoutWatcher),
    spawn(forgotPasswordWatcher),
    spawn(resetPasswordWatcher),
    spawn(twoFactorAuthGetCodeWatcher),
    spawn(twoFactorAuthConfirmWatcher),
    spawn(twoFactorAuthDisableWatcher),
    spawn(uploadInvestorDocumentWatcher),
    spawn(deleteInvestorDocumentWatcher),
    spawn(generateOnboardingLinkWatcher),
    spawn(adjustDealIOIWatcher),
    spawn(getCompanyWatcher),
    spawn(editCompanyWatcher),
    spawn(getCompaniesWatcher),
    spawn(getHoldingsWatcher),
    spawn(downloadShareholderRegistryWatcher),
    spawn(getShareholdersWatcher),
    spawn(getRegisteredShareholdersWatcher),
    spawn(getTransferShareholdersWatcher),
    spawn(getShareholdersDropdownDataWatcher),
    spawn(downloadShareholderCertWatcher),
    spawn(createShareholderWatcher),
    spawn(createShareClassWatcher),
    spawn(editShareClassWatcher),
    spawn(editShareClassDescriptionWatcher),
    spawn(shareClassEditRetrieveQuantityWatcher),
    spawn(fetchInvestorDocumentsWatcher),
    spawn(convertSharesWatcher),
    spawn(buybackSharesWatcher),
    spawn(cancelSharesWatcher),
    spawn(convertSharesForIndividualHolderWatcher),
    spawn(resendIPInvestorInviteWatcher),
    spawn(getQuantitiesForPairWatcher),
    spawn(getCurrenciesWatcher),
    spawn(getCountriesWatcher),
    spawn(updateTransactionsPermissionWatcher),
    spawn(changeDocumentAgreedStatusWatcher),
    spawn(editShareholderDetailsWatcher),
    spawn(lookupShareholderWatcher),
    spawn(addTransactionNoteWatcher),
    spawn(updateTransactionNoteWatcher),
    spawn(removeTransactionNoteWatcher),
    spawn(editCompanyOverviewWatcher),
    spawn(searchShareholdersWatcher),
    spawn(editShareholderPerformanceWatcher),
    spawn(getCompanyTagsWatcher),
    spawn(updateCompanyViewPermissionsWatcher),
    spawn(verifyCompanyKybWatcher),
    spawn(createCompanySummaryWatcher),
    spawn(editCompanySummaryWatcher),
    spawn(getCompanySummaryWatcher),
    spawn(getShareClassesWatcher),
    spawn(fetchDealSummaryWatcher),
    spawn(fetchDealDataWatcher),
    spawn(fetchDealDocumentsDataWatcher),
    spawn(editDealStructureWatcher),
    spawn(fetchDealInfoWatcher),
    spawn(fetchDealListWatcher),
    spawn(fetchInvestorInfoWatcher),
    spawn(resendInvestorInviteWatcher),
    spawn(getShareOptionsWatcher),
    spawn(getHoldersWatcher),
    spawn(getGrantHoldersWatcher),
    spawn(getGrantHoldersCapitalWatcher),
    spawn(removeUnvestedOptionsWatcher),
    spawn(getShareOptionPlanWatcher),
    spawn(updateShareOptionWatcher),
    spawn(getShareOptionDataWatcher),
    spawn(exerciseShareOptionsWatcher),
    spawn(createShareOptionWatcher),
    spawn(addShareOptionPlanDocumentWatcher),
    spawn(deleteShareOptionPlanDocumentWatcher),
    spawn(editShareIncentivePlansWatcher),
    spawn(getShareIncentivePlanWatcher),
    spawn(importPurchaseTemplateWatcher),
    spawn(importSaleAndTransferTemplateWatcher),
    spawn(blockHolderWatcher),
    spawn(unblockHolderWatcher),
    spawn(allocateShareholdingWatcher),
    spawn(transferShareholdingWatcher),
    spawn(getDraftCommunicationWatcher),
    spawn(getPublishedCommunicationWatcher),
    spawn(updateDraftCommunicationWatcher),
    spawn(deleteDraftCommunicationWatcher),
    spawn(draftCommunicationAddAttachmentWatcher),
    spawn(draftCommunicationDeleteAttachmentWatcher),
    spawn(publishDraftCommunicationWatcher),
    spawn(getCommunicationsWatcher),
    spawn(getCommunicationResponsesStatisticsWatcher),
    spawn(exportCommunicationResponsesStatisticsWatcher),
    spawn(getAggregatedResponsesStatisticsWatcher),
    spawn(fetchSubNavigationWatcher),
    spawn(fetchNavigationWatcher),
    spawn(fetchCompanyWatcher),
    spawn(fetchDealPagesDataWatcher),
    spawn(fetchPageWatcher),
    spawn(fetchIssuanceWatcher),
    spawn(fetchDealWatcher),
    spawn(updateDealPageHeaderWatcher),
    spawn(updateDealDataWatcher),
    spawn(fetchInvestorsWatcher),
    spawn(fetchIndicationOfInterestDataWatcher),
    spawn(fetchDealIOIDetailsWatcher),
    spawn(approveDealIOIWatcher),
    spawn(cancelDealIOIWatcher),
    spawn(inviteInvestorWatcher),
    spawn(fetchInvestorWatcher),
    spawn(editInvestorWatcher),
    spawn(fetchWalletReferenceWatcher),
    spawn(addWalletTransferWatcher),
    spawn(fetchWalletTransactionsWatcher),
    spawn(fetchInvestorInvestmentsWatcher),
    spawn(fetchInvestorPreferencesWatcher),
    spawn(fetchInvestorDealsWatcher),
    spawn(fetchInvestorAgentsPermissionsWatcher),
    spawn(updateInvestorDealsWatcher),
    spawn(getSettingsWatcher),
    spawn(getUsersWatcher),
    spawn(getVerificationWatcher),
    spawn(fetchEditableNavigationWatcher),
    spawn(editNavigationItemWatcher),
    spawn(deleteNavigationItemWatcher),
    spawn(createNavigationItemWatcher),
    spawn(reorderNavigationItemWatcher),
    spawn(createSectionWatcher),
    spawn(editSectionTitleWatcher),
    spawn(deleteSectionWatcher),
    spawn(reorderSectionsWatcher),
    spawn(createTextComponentWatcher),
    spawn(editTextComponentWatcher),
    spawn(createTableComponentWatcher),
    spawn(editTableComponentWatcher),
    spawn(createDescriptionListComponentWatcher),
    spawn(editDescriptionListComponentWatcher),
    spawn(createDocumentComponentWatcher),
    spawn(editDocumentComponentWatcher),
    spawn(editImageComponentWatcher),
    spawn(createImageComponentWatcher),
    spawn(editVideoComponentWatcher),
    spawn(createVideoComponentWatcher),
    spawn(deleteComponentWatcher),
    spawn(reorderComponentWatcher),
    spawn(createTeamComponentInRegularSectionWatcher),
    spawn(editTeamMemberWatcher),
    spawn(createTeamMemberInOverviewSectionWatcher),
    spawn(detachTeamMemberWatcher),
    spawn(fetchTeamMembersWatcher),
    spawn(createTeamComponentWatcher),
    spawn(attachTeamMemberWatcher),
    spawn(reorderTeamMemberWatcher),
    spawn(createIssuanceWatcher),
    spawn(generateBasicNavigationWatcher),
    spawn(shareholderTransactionRevertWatcher),
    spawn(updateShareholderTransactionsWatcher),
    spawn(draftCommunicationAddResponseWatcher),
    spawn(draftCommunicationDeleteResponseWatcher),
    spawn(getAccountUsersWatcher),
    spawn(createAccountUserWatcher),
    spawn(updateAccountUserWatcher),
    spawn(createPasswordWatcher),
    spawn(confirmEmailWatcher),
    spawn(deleteAccountUserWatcher),
    spawn(getMyAccountDetailsWatcher),
    spawn(resendEmailConfirmationWatcher),
    spawn(changePasswordWatcher),
    spawn(createTaglineWatcher),
    spawn(editTaglineWatcher),
    spawn(deleteTaglineWatcher),
    spawn(addNewGrantWatcher),
    spawn(acceptTermsWatcher),
    spawn(getGrantWatcher),
    spawn(editGrantWatcher),
    spawn(deleteGrantWatcher),
    spawn(getGrantHolderDetailsWatcher),
    spawn(updateGrantHolderDetailsWatcher),
    spawn(addGrantDocumentWatcher),
    spawn(deleteGrantDocumentWatcher),
    spawn(updateUserWatcher),
    spawn(createUserWatcher),
    spawn(selectPackageWatcher),
    spawn(getSubscriptionTemplatesWatcher),
    spawn(getSubscriptionTemplatesPerCompanyWatcher),
    spawn(deleteUserWatcher),
    spawn(checkCreateUserPermissionWatcher),
    spawn(getPermissionLevelsWatcher),
    spawn(getTemplatesURLWatcher),
    spawn(updateCurrentUserWatcher),
    spawn(resendEmailForCurrentUserWatcher),
    spawn(updateMyDetailsWatcher),
    spawn(getBulkWelcomeEmailInfoWatcher),
    spawn(sendBulkWelcomeEmailWatcher),
    spawn(getSecondaryTransactionsWatcher),
    spawn(downloadFileWatcher),
    spawn(getDashboardInfoWatcher),
    spawn(getGrantTransactionalHistoryWatcher),
    spawn(approveTransactionWatcher),
    spawn(rejectTransactionWatcher),
    spawn(getSecondaryTransactionWatcher),
    spawn(addSecondaryTransactionDocumentWatcher),
    spawn(deleteSecondaryTransactionDocumentWatcher),
    spawn(approveSecondaryTransactionDocumentWatcher),
    spawn(rejectSecondaryTransactionDocumentWatcher),
    spawn(getAllSecondaryTransactionsWatcher),
    spawn(updateCompaniesHouseAuthCodeWatcher),
    spawn(createConfirmationStatementWatcher),
    spawn(getFilingsHistoryWatcher),
    spawn(getFilingDetailsWatcher),
    spawn(addFilingDocumentWatcher),
    spawn(deleteFilingDocumentWatcher),
    spawn(downloadFilingsAsCSVWatcher),
    spawn(getCompanyProfileWatcher),
    spawn(getShareCapitalWatcher),
    spawn(getShareholderInformationWatcher),
    spawn(getSicCodesWatcher),
    spawn(pingWatcher),
    spawn(updateNickelledUserWatcher),
    spawn(getAllotmentDataWatcher),
    spawn(getAllottedSharesWatcher),
    spawn(submitSh01FormWatcher),
    spawn(getGrantHoldersEmailsWatcher),
    spawn(searchHoldersWatcher),
    spawn(createDealInvitesWatcher),
    spawn(fetchDealInviteEmailTemplateWatcher),
    spawn(requestDealPublishWatcher),
    spawn(publishDealWatcher),
    spawn(unpublishDealWatcher),
    spawn(fetchDealOrdersWatcher),
    spawn(fetchDealOrderDetailsWatcher),
    spawn(fetchDealOrderHistoryWatcher),
    spawn(approveDealOrderWatcher),
    spawn(markAsFundsReceivedWatcher),
    spawn(cancelDealOrderWatcher),
    spawn(completeDealWatcher),
    spawn(createDealDetailsWatcher),
    spawn(editDealDetailsWatcher),
    spawn(fetchDealSettlementsWatcher),
    spawn(editOrderMoneyTransferredWatcher),
    spawn(fetchShareholdersEmailsWatcher),
    spawn(getIntroducerFeesWatcher),
    spawn(updateIntroducerFeesWatcher),
    spawn(getTreasuryAccountWatcher),
    spawn(sendDownloadRequestWatcher),
    spawn(checkJobProgressWatcher),
    spawn(checkUploadCSVJobProgressWatcher),
    spawn(sendUploadTransactionsRequestWatcher),
    spawn(sendUploadConvertibleNotesRequestWatcher),
    spawn(downloadCSVTemplateWatcher),
    spawn(sendUploadShareholdersRequestWatcher),
    spawn(sendUploadBondsAndLoansRequestWatcher),
    spawn(createBrokersNoteWatcher),
    spawn(downloadBrokersNoteWatcher),
    spawn(uploadPayrollDataWatcher),
    spawn(importPayrollTemplateWatcher),
    spawn(uploadBonusDataWatcher),
    spawn(importBonusTemplateWatcher),
    spawn(sharePlanBulkInviteEmployeesWatcher),
    spawn(sharePlanInviteEmployeesWatcher),
    spawn(getCompanyEntitiesWatcher),
    spawn(getCurrencyRateWatcher),
    spawn(getShareCertificateConfigWatcher),
    spawn(updateShareCertificateConfigWatcher),
    spawn(sendDownloadExportCommandRequestWatcher),
    spawn(checkExportCommandJobProgressWatcher),
    spawn(oauth2LoginWatcher),
    spawn(oauth2LogoutWatcher),
  ]);
}
