import Bubble from 'shared-parts/components/bubble';
import { dashed } from 'shared-parts/helpers/format-string';

import { Tab, TabsContainer, TabWrapper } from './tabs.styled';

const Tabs = ({ items, selected, onClick, className, getTabStyles }) => (
  <TabsContainer className={className} role="tablist">
    {items.map(tab => {
      const isTabSelected = selected === tab.key;

      return (
        <TabWrapper
          role="tab"
          key={tab.key}
          onClick={tab.disabled || !onClick ? () => {} : () => onClick(tab)}
        >
          <Tab
            selected={isTabSelected}
            disabled={tab.disabled}
            tabStyles={getTabStyles ? () => getTabStyles(isTabSelected) : ''}
            data-e2e={`${dashed(tab.name)}-tab`}
          >
            {tab.name}
          </Tab>
          {tab.bubbleContent ? <Bubble content={tab.bubbleContent} /> : ''}
        </TabWrapper>
      );
    })}
  </TabsContainer>
);

export default Tabs;
