import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const getButtonColor = ({ danger, filledBackground }) => {
  const color = danger ? COLORS.systemRed : COLORS.alternative;

  return `
    background: ${COLORS.white};
    border: 1px solid ${color};
    color: ${color};

    &:hover {
      background: ${color};
      color: ${COLORS.white};
    }

    ${
      filledBackground &&
      `
      background: ${color};
      color: ${COLORS.white};
    `
    }
  `;
};

const SmallButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 14px;
  outline: 0;
  padding: 8px;
  height: 30px;
  min-width: 100px;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
  ${getButtonColor}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: auto;
    background: ${COLORS.neutral800};
    color: ${COLORS.white};
    border-color: ${COLORS.neutral800};
    &:hover {
      background: ${COLORS.neutral800};
    }
  `}
`;

export default SmallButton;
