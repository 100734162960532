import { useEnvVar } from '@frontend/config';

import { DealValuesType } from 'shared-parts/components/section-header/active/types';

const enableManualDealIssuer = useEnvVar('ENABLED_MANUAL_DEAL_ISSUER');

type Props<D> = (deal: D) => string;

const getIssuerName: Props<DealValuesType> = ({ company: { name }, dealDetail }) =>
  enableManualDealIssuer ? dealDetail?.issuerName : name;

export default getIssuerName;
