import styled from 'styled-components';

import { Spinner, SpinnerContainer } from 'shared-parts/components/loader/loader.styled';
import { COLORS, FONTS } from 'shared-parts/constants';

const StyledDownloadButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 14px;
  font-family: ${FONTS.mainFont};

  ${({ disabled }) =>
    disabled
      ? `
    color: ${COLORS.neutral800};
    cursor: not-allowed;
  `
      : `
    color: ${COLORS.primary};
    cursor: pointer;
  `}

  ${({ styles }) => styles}
`;

const IconContainer = styled.span`
  margin-right: 5px;
  width: 20px;
  height: 20px;

  ${SpinnerContainer} {
    background: transparent;
    position: relative;

    ${Spinner} {
      border-width: 3px;
    }
  }
`;

export { StyledDownloadButton, IconContainer };
