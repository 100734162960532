import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const TabsContainer = styled.div`
  display: flex;
  margin: 25px 0 0 0;
  overflow: auto;
  user-select: none;
`;

const Tab = styled.p`
  box-sizing: content-box;
  color: ${COLORS.neutral800};
  font-size: 14px;
  line-height: 14px;
  margin: 20px 20px 0 0;
  padding-bottom: 15px;
  text-transform: uppercase;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? `
    border-bottom: 3px solid ${COLORS.secondary};
    color: ${COLORS.secondary};
  `
      : ''}

  ${({ tabStyles = '' }) => tabStyles}

  ${({ disabled }) => (disabled ? `color: ${COLORS.neutral500};cursor:default;` : '')}
`;

const TabWrapper = styled.div`
  position: relative;
`;

export { TabsContainer, Tab, TabWrapper };
