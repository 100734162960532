import styled from 'styled-components';

import FormTabs from 'shared-parts/components/tabs/form-tabs';
import { COLORS } from 'shared-parts/constants';

const SideFormTabs = styled(FormTabs)`
  height: 58px;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 103;
  background-color: ${COLORS.neutral200};

  ${({ marginTop }) => marginTop && `p { margin-top: ${marginTop}px }`}
`;

export default SideFormTabs;
