import allCountries from 'shared-parts/constants/all-countries';

import sortByParamAlphabetically from './sorting';

export default () =>
  allCountries
    .map(country => ({
      text: country.countryName,
      value: country.countryCode,
    }))
    .sort(sortByParamAlphabetically('text', true));
