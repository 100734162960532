import { FC, ReactNode, useEffect } from 'react';

import { usePlatformSettings } from '@frontend/config';
import { useRequest } from '@frontend/http';
import i18next from '@frontend/i18next';

/**
 * This component gives the app access to the i18next translations.
 *
 * It will re-load regulatory translations from from the server
 * when the regulatory environment changes.
 */
export const I18nextLoader: FC<
  React.PropsWithChildren<{
    regulatoryApiPath?: string;
    loader: ReactNode;
  }>
> = ({ regulatoryApiPath = '/translations/regulatory', loader, children }) => {
  const { regulatoryEnvironment } = usePlatformSettings();
  const {
    data: _data,
    refetch,
    isFetching,
    error,
  } = useRequest(
    `${regulatoryApiPath}/${regulatoryEnvironment.value}`,
    {
      onSuccess: data => {
        if (!data) return;
        // must remove old bundle - keys absent from `data` are not overwritten
        i18next.removeResourceBundle('en-GB', 'regulatory');
        i18next.addResourceBundle('en-GB', 'regulatory', data, true, true);
        i18next.changeLanguage(i18next.language); // force re-render
      },
    },
    {
      camelCase: false, // our i18next keys are snake_case
    },
  );

  useEffect(() => {
    // Load the default translations from the server - after init
    Object.keys(window.i18nextResouceBundle).forEach(lang => {
      Object.keys(window.i18nextResouceBundle[lang]).forEach(namespace => {
        i18next.addResourceBundle(lang, namespace, window.i18nextResouceBundle[lang][namespace]);
      });
    });
  }, []);

  useEffect(() => {
    refetch();
  }, [regulatoryEnvironment.value]);

  if (error) {
    throw new Error('Unable to load regulatory translations');
  }

  if (isFetching || !i18next.hasResourceBundle('en-GB', 'regulatory')) {
    return <>{loader}</>;
  }

  return <>{children}</>;
};
