import { createSelector } from 'reselect';

import { getUser } from 'app-state/selectors/authentication';

import { WAITING_FOR_APPROVAL } from 'shared/secondary-transaction-documents/constants';

export const getSecondaryTransactions = state => state.secondaryTransactions;
const getSecondaryTransactionData = state => state.secondaryTransaction.data;

export const getSecondaryTransactionUuid = createSelector(
  getSecondaryTransactionData,
  ({ uuid }) => uuid,
);
export const getSecondaryTransactionBuyer = createSelector(
  getSecondaryTransactionData,
  ({ buyer }) => buyer,
);
export const getSecondaryTransactionSeller = createSelector(
  getSecondaryTransactionData,
  ({ seller }) => seller,
);
export const getSecondaryTransactionStatus = createSelector(
  getSecondaryTransactionData,
  ({
    state,
    stateChangedByName,
    stateChangedByEmail,
    stateChangedAt,
    transactionId,
    rejectionReason,
  }) => ({
    state,
    stateChangedByName,
    stateChangedByEmail,
    stateChangedAt,
    transactionId,
    rejectionReason,
  }),
);
export const getSecondaryTransactionDocuments = createSelector(
  getSecondaryTransactionData,
  ({ transactionDocuments }) => transactionDocuments,
);
export const getSecondaryTransactionDocumentsState = state => state.secondaryTransaction.data.state;
export const getSecondaryTransactionStateChangedAt = state =>
  state.secondaryTransaction.data.stateChangedAt;
export const getSecondaryTransactionStockTransferForm = createSelector(
  getSecondaryTransactionDocuments,
  ({ data }) => data.find(doc => doc.stockTransferForm),
);
const getSecondaryTransactionFormWaitingFlag = createSelector(
  getSecondaryTransactionStockTransferForm,
  stf => (stf ? stf.status === WAITING_FOR_APPROVAL : false),
);
export const getAbilityToApprove = createSelector(
  getUser,
  getSecondaryTransactionStockTransferForm,
  getSecondaryTransactionFormWaitingFlag,
  (user, stf, waitingFlag) =>
    waitingFlag && user.email !== (stf.changeLogs[0] ? stf.changeLogs[0].performerEmail : ''),
);
export const getSecondaryTransactionLoadingStatus = state => state.secondaryTransaction.loading;
export const getIntroducerFees = state => state.introducerFees;
