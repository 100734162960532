import styled from 'styled-components';

import Tooltip from 'shared-parts/components/tooltip';
import { COLORS } from 'shared-parts/constants';

const { primary, systemYellow } = COLORS;

export const StyledTooltip = styled(Tooltip)`
  margin-left: 8px;
  z-index: 102;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipContent = styled.div`
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${systemYellow};
  color: ${primary};
  font-size: 14px;
  font-weight: normal;
`;
