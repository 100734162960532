import { Component, createRef } from 'react';

import { Field, FieldProps, getIn } from 'formik';
import MiniSearch from 'minisearch';

import { compose, memoize } from '@frontend/utils';

import Error from 'shared-parts/components/error';
import useIntersect from 'shared-parts/helpers/useIntersect';

import { DEFAULT_SEARCH_CONFIGURATION, NOT_APPLICABLE_OPTION } from './constants';
import {
  AddNewSelectButton,
  ArrowWrapper,
  ClickOutsideWrapper,
  DownArrow,
  Dropdown,
  Input,
  Option,
  OptionsLoader,
  ProgressLine,
  SelectContainer,
  SelectedOptionText,
  UpArrow,
} from './select.styled';
import type {
  HandleOptionMouseDown,
  IntersectBoxComponent,
  OptionType,
  Props,
  RenderOptionTextPros,
  State,
} from './select.types';

const getOptionsIdsMap = (result: { id: string }[]) =>
  result.reduce((mappedIds, { id }) => {
    Object.assign(mappedIds, { [id]: true });

    return mappedIds;
  }, {});

const renderOptionText = ({ text, value, useValueAsPrefix }: RenderOptionTextPros) => {
  if (!useValueAsPrefix) {
    return <span>{text}</span>;
  }

  return (
    <span>
      <strong>{value}</strong>&nbsp;
      {text}
    </span>
  );
};

const findItemIndex = (options: OptionType[], value: string) =>
  value ? options.findIndex(item => item.value === value) : 0;

const IntersectBox: IntersectBoxComponent = ({
  onInfiniteScroll,
  threshold = 0.01,
  rootMargin = '100px 0px 0px 0px',
}) => {
  const [ref, entry] = useIntersect({ threshold, rootMargin });

  if (entry && entry.intersectionRatio > 0.01) onInfiniteScroll();

  return <div ref={ref} />;
};

class CustomSelectComponent<T> extends Component<FieldProps<string, T> & Props<T>, State> {
  private searchInstance: MiniSearch<OptionType>;

  textInput = createRef<HTMLInputElement>();

  dropdown = createRef<HTMLDivElement>();

  selectedOption = createRef<HTMLButtonElement>();

  constructor(props: FieldProps & Props<T>) {
    super(props);

    this.searchInstance = new MiniSearch({
      ...DEFAULT_SEARCH_CONFIGURATION,
      ...(props.searchConfiguration ? props.searchConfiguration : {}),
    });
    this.getMatchedOptionIds = memoize(
      compose(getOptionsIdsMap, this.searchInstance.search.bind(this.searchInstance)),
    );
  }

  state = {
    inputText: '',
    options: [],
    dropdownVisible: false,
    activeItemIndex: 0,
  };

  componentDidMount() {
    const {
      loading,
      field: { name, value },
      form: { setFieldTouched },
      isBEAutocomplete,
      options = [],
    } = this.props;

    if (value) {
      setFieldTouched(name, true);
    }

    if (!isBEAutocomplete && !loading && options.length) {
      this.setSearchData(options);
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    const { loading, isBEAutocomplete, options = [] } = this.props;
    // Update memoize/cache if it was previously unloaded
    if (!loading && prevProps.loading) {
      this.getMatchedOptionIds = memoize(
        compose(getOptionsIdsMap, this.searchInstance.search.bind(this.searchInstance)),
      );
    }

    if (!loading && prevProps.loading !== loading && isBEAutocomplete) {
      this.updateOptions();
    } else if (!loading && options.length) {
      this.setSearchData(options);
    }
  }

  onKeyDown = (event: React.KeyboardEvent & React.BaseSyntheticEvent) => {
    const { maxRequestLength = Infinity } = this.props;
    const { options, activeItemIndex } = this.state;
    const { target, keyCode } = event;
    const backspaceIsClicked = keyCode === 8 || keyCode === 46;
    const arrowUpOrDownIsClicked = keyCode === 38 || keyCode === 40;
    const enterIsClicked = keyCode === 13;
    const preventDefaultConditions = [
      target.value.length >= maxRequestLength && !backspaceIsClicked,
    ];
    const shouldTheDefaultBehaviourBePrevented = preventDefaultConditions.some(
      condition => condition,
    );

    if (shouldTheDefaultBehaviourBePrevented) {
      event.preventDefault();
    } else if (options.length) {
      if (arrowUpOrDownIsClicked) {
        event.preventDefault();
        this.handleArrowKeyClick(keyCode);
      } else if (enterIsClicked) {
        const { value }: OptionType = options[activeItemIndex];
        this.handleOptionMouseDown({ externalValue: value, index: activeItemIndex })();
      }
    }
  };

  getStringImportance = (text: string) => {
    const { inputText } = this.state;
    const preparedInputText = inputText.trim().toLowerCase();
    let importance = Infinity;
    const words = text.split(' ').map(el => el.toLowerCase());
    const startsWithIndex = words.findIndex(word => word.startsWith(preparedInputText));

    if (startsWithIndex !== -1) {
      importance = startsWithIndex + 1; // add 1 to always be truthy
    }

    return importance;
  };

  setSearchData = (options: OptionType[]) => {
    this.searchInstance.addAll(options);

    if (this.props.isUnselectAvailable) {
      this.searchInstance.add(NOT_APPLICABLE_OPTION);
    }
  };

  getFilteredOptions = (queryString: string) => {
    const resultIdsMap = this.getMatchedOptionIds(queryString.toLowerCase());
    return this.props.options.filter(
      (option: OptionType) => option && option.value && option.value in resultIdsMap,
    );
  };

  private getMatchedOptionIds: (query: string) => { [id: string]: any };

  handleArrowKeyClick = (keyCode: number) => {
    const { activeItemIndex, options } = this.state;
    const isArrowDown = keyCode === 40;

    if (isArrowDown && activeItemIndex !== options.length - 1) {
      this.handleMoveToNextItem(activeItemIndex + 1);
    } else if (!isArrowDown && activeItemIndex !== 0) {
      this.handleMoveToNextItem(activeItemIndex - 1);
    }
  };

  findActiveOptionElement = (
    nextItemIndex: number,
    dropdownElement: HTMLDivElement,
  ): HTMLButtonElement | undefined => {
    const { options } = this.state;
    const optionsElements: HTMLCollectionOf<HTMLElementTagNameMap['button']> =
      dropdownElement.getElementsByTagName('button');

    return Array.from(optionsElements).find(({ innerText }) => {
      const nextItem: OptionType = options[nextItemIndex];

      return innerText === nextItem.text;
    });
  };

  handleMoveToNextItem = (nextItemIndex: number) => {
    if (this.dropdown.current) {
      const { offsetTop } = this.findActiveOptionElement(nextItemIndex, this.dropdown.current) || {
        offsetTop: 0,
      };

      this.setState({ activeItemIndex: nextItemIndex });
      this.dropdown.current.scrollTop = offsetTop;
    }
  };

  handleChange = (e: React.BaseSyntheticEvent) => {
    const { form, field, onChange } = this.props;
    const { value } = e.target;

    if (onChange) onChange(value);

    this.setState(
      {
        inputText: value,
        dropdownVisible: true,
        activeItemIndex: 0,
      },
      () => {
        this.updateOptions();
        this.scrollDropdownToTop();

        if (this.props.isCreatingNewEnabled) {
          form.setFieldValue(field.name, value);
        }
      },
    );
  };

  scrollToSelected = () => {
    if (this.dropdown.current && this.selectedOption.current) {
      this.dropdown.current.scrollTop = this.selectedOption.current.offsetTop;
    }
  };

  handleClickOnSelect = () => {
    const { options, value, disabled } = this.props;

    if (disabled) return;

    if (this.textInput.current) {
      this.textInput.current.focus();
    }

    if (value) {
      this.setState({
        activeItemIndex: findItemIndex(options, value) || 0,
      });
    }

    this.setState(
      {
        dropdownVisible: true,
        options: this.prepareOptions(),
      },
      this.scrollToSelected,
    );
  };

  hideDropdown = () => {
    this.setState({
      dropdownVisible: false,
      options: [], // form optimization
    });
  };

  handleOptionMouseDown: HandleOptionMouseDown =
    ({ externalValue, index, disabled }) =>
    (e?: React.SyntheticEvent) => {
      if (e) {
        e.preventDefault();
      }
      if (disabled) return;

      const { form, field, onOptionSelect, options: optionsProps } = this.props;
      const { options: optionsState } = this.state;
      let optionIndex = index;

      form.setFieldValue(field.name, externalValue);

      if (onOptionSelect) {
        onOptionSelect(externalValue, form);
      }

      if (optionsProps.length > optionsState.length) {
        optionIndex = findItemIndex(optionsProps, externalValue);
      }

      this.setState({ activeItemIndex: optionIndex, inputText: '' }, this.hideDropdown);
    };

  handleArrowClick = (e: React.SyntheticEvent) => {
    const { options, value, disabled, onArrowClick } = this.props;
    e.stopPropagation();
    if (disabled) return;

    if (onArrowClick) {
      onArrowClick();
    }

    if (this.textInput.current) {
      this.textInput.current.focus();
    }

    if (value) {
      this.setState({
        activeItemIndex: findItemIndex(options, value) || 0,
      });
    }

    this.setState(
      ({ dropdownVisible }) => ({
        dropdownVisible: !dropdownVisible,
        options: this.prepareOptions(),
      }),
      this.scrollToSelected,
    );
  };

  scrollDropdownToTop = () => {
    if (this.dropdown.current) this.dropdown.current.scrollTop = 0;
  };

  handleClickOutside = () => {
    this.setState(
      prevState => ({ inputText: this.props.isCreatingNewEnabled ? prevState.inputText : '' }),
      this.hideDropdown,
    );
  };

  updateOptions = () => {
    this.setState({ options: this.prepareOptions() });
  };

  addNotApplicableOption = (options: OptionType[]) =>
    this.props.isUnselectAvailable && options.length
      ? [NOT_APPLICABLE_OPTION, ...options]
      : options;

  sortOptionsByName = (a: OptionType, b: OptionType) =>
    this.getStringImportance(a.text) - this.getStringImportance(b.text);

  prepareOptions = (): OptionType[] => {
    const {
      props: { isBEAutocomplete },
      state: { inputText },
      props: { options, sortBy },
    } = this;

    if (inputText) {
      const filteredOptions = isBEAutocomplete ? options : this.getFilteredOptions(inputText);
      const sortedOptions =
        sortBy === 'name' && !inputText.includes(' ')
          ? filteredOptions.sort(this.sortOptionsByName)
          : filteredOptions;

      return this.addNotApplicableOption(sortedOptions);
    }

    return this.addNotApplicableOption(options);
  };

  renderOption = (option: OptionType, index: number) => {
    const { text, value, disabled = false } = option;
    const { options, activeItemIndex } = this.state;
    const { field, renderCustomOption, useValueAsPrefix = false } = this.props;
    const isSelectedOption = field.value === value;
    const activeOption: OptionType = options[activeItemIndex];
    const isActive = activeOption ? activeOption.value === value : false;
    const optionProps = {
      key: value,
      disabled,
      active: isActive,
      selected: isSelectedOption,
      onMouseDown: this.handleOptionMouseDown({ externalValue: value, index, disabled }),
      ref: isSelectedOption ? this.selectedOption : null,
    };

    if (renderCustomOption) {
      return renderCustomOption({ ...optionProps, option });
    }

    return (
      <Option type="button" {...optionProps}>
        {renderOptionText({ value, text, useValueAsPrefix })}
      </Option>
    );
  };

  renderOptions = (options: OptionType[]) => {
    const { loading = false } = this.props;
    const { length } = options;

    if (loading && length === 0) {
      return <OptionsLoader alwaysVisible isLocal onUnmount={this.updateOptions} />;
    }

    if (length) {
      return (
        <>
          {options.map(this.renderOption)}
          {loading && <ProgressLine />}
        </>
      );
    }

    return <Option type="button">No results found</Option>;
  };

  renderDropdown(dropdownVisible: boolean) {
    const {
      showAddNewItem,
      addNewItemClickAction,
      text,
      dropdownHeight,
      onInfiniteScroll,
      loading,
    } = this.props;
    const { options } = this.state;
    const { length } = options;
    const itemsLength = showAddNewItem ? length + 1 : length;
    const handleAddNewItemClick = () => {
      this.setState({ dropdownVisible: false });
      if (addNewItemClickAction) {
        addNewItemClickAction();
      }
    };

    return (
      <Dropdown
        dropdownVisible={dropdownVisible}
        length={itemsLength}
        ref={this.dropdown}
        dropdownHeight={dropdownHeight}
        data-e2e={`${this.props.field.name}-dropdown`}
      >
        {this.renderOptions(options)}
        {onInfiniteScroll && !loading && <IntersectBox onInfiniteScroll={onInfiniteScroll} />}
        {showAddNewItem && (
          <AddNewSelectButton text={text || 'Add New'} action={handleAddNewItemClick} />
        )}
      </Dropdown>
    );
  }

  renderArrow = (dropdownVisible: boolean, disabled: boolean) => (
    <ArrowWrapper
      type="button"
      disabled={disabled}
      onClick={this.handleArrowClick}
      dropdownVisible={dropdownVisible}
    >
      {dropdownVisible ? <UpArrow /> : <DownArrow />}
    </ArrowWrapper>
  );

  renderInput = (selectedOptionText: string, isInvalid: boolean) => (
    <Input
      name={this.props.field.name}
      disableAutocomplete={this.props.disableAutocomplete}
      autoComplete={this.props.disableAutocomplete ? 'off' : this.props.autoComplete}
      ref={this.textInput}
      value={this.state.inputText}
      disabled={this.props.disabled}
      onChange={this.handleChange}
      onBlur={this.props.field.onBlur}
      onKeyDown={this.onKeyDown}
      isInvalid={isInvalid}
      placeholder={selectedOptionText ? '' : this.props.placeholder}
      data-e2e={this.props.field.name}
    />
  );

  render() {
    const {
      className,
      field,
      form,
      onClick,
      disabled = false,
      height = 50,
      useValueAsPrefix = false,
      options = [],
    } = this.props;
    const { name, value } = field;
    const { errors, touched } = form;
    const { inputText, dropdownVisible } = this.state;
    const { text = '' } = options.find(option => option.value === value) || {};
    const selectedOptionText = useValueAsPrefix ? `${value} ${text}` : text;
    const fieldErrors = getIn(errors, name);
    const isFieldTouched = getIn(touched, name);
    const error = Array.isArray(fieldErrors) ? fieldErrors[0] : fieldErrors;

    return (
      <ClickOutsideWrapper className={className} onClickOutside={this.handleClickOutside}>
        <SelectContainer
          onClick={onClick || this.handleClickOnSelect}
          touched={Boolean(isFieldTouched)}
          isInvalid={Boolean(error)}
          disabled={disabled}
          height={height}
        >
          {!inputText && (
            <SelectedOptionText disabled={this.props.disabled}>
              {selectedOptionText}
            </SelectedOptionText>
          )}
          {this.renderInput(selectedOptionText, Boolean(error))}
          {this.renderArrow(dropdownVisible, disabled)}
        </SelectContainer>
        {this.renderDropdown(dropdownVisible)}
        {error && isFieldTouched && <Error message={error} data-e2e={`error-${name}`} />}
      </ClickOutsideWrapper>
    );
  }
}

export default <T,>(props: Props<T>): JSX.Element => (
  <Field {...props} component={CustomSelectComponent} />
);
