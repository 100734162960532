import { FC } from 'react';

import { theme } from 'theme';

import { Box, Button, Typography } from '@frontend/design-system';

import { EmptyTableMessageProps } from './types';

const EmptyTableMessage: FC<React.PropsWithChildren<EmptyTableMessageProps>> = ({
  message = 'No items created',
  addButtonText = 'Add item',
  isAddButtonVisible = true,
  onAdd = undefined,
}) => {
  return (
    <tr>
      <td colSpan={100}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            justifyContent: 'center',
            padding: '55.52px 0 59px 0',
          }}
        >
          <Typography sx={{ color: theme.palette.neutral[500], fontWeight: 700 }}>
            {message}
          </Typography>
          {isAddButtonVisible && (
            <Button variant="contained" onClick={onAdd}>
              {addButtonText}
            </Button>
          )}
        </Box>
      </td>
    </tr>
  );
};

export default EmptyTableMessage;
