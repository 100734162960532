import { useState } from 'react';

import { COLORS } from 'shared-parts/constants';

import Documents from './documents';
import { StyledTabs } from './index.styled';

const { primary } = COLORS;

const tabs = [
  {
    type: 'NdaDocument',
    key: 'ndaDocuments',
    name: 'NDA DOCUMENTS',
    component: Documents,
  },
  {
    type: 'TermSheetDocument',
    key: 'termSheets',
    name: 'TERM SHEETS',
    component: Documents,
  },
];

const DocumentsTab = ({ dealUuid, investor }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const Component = activeTab.component;

  return (
    <>
      <StyledTabs
        items={tabs}
        selected={activeTab.key}
        onClick={setActiveTab}
        activeColor={primary}
      />
      <Component uuid={dealUuid} investor={investor} type={activeTab.type} />
    </>
  );
};

export default DocumentsTab;
