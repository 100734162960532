import { COLORS } from 'shared-parts/constants';

import { SortingArrow, SortingLines } from '../table.styled';

const Arrow = ({ sorting, sortingData }) => {
  if (!sorting) return null;

  const { param, asc } = sortingData || {};
  const isSorted = sorting.param === param;

  return sorting.isSortingLinesIcon ? (
    <SortingLines
      isSorted={isSorted}
      rotateDown={isSorted && asc}
      color={isSorted ? COLORS.primary : COLORS.neutral500}
    />
  ) : (
    <SortingArrow color={COLORS.white} isSorted={isSorted} rotateDown={isSorted && !asc} />
  );
};

export default Arrow;
