import { Fragment } from 'react';

import Loader from 'shared-parts/components/loader/loader';
import { dashed } from 'shared-parts/helpers/format-string';

import {
  AddButton,
  AddButtonText,
  AddButtonWrapper,
  AddLinkWrapper,
} from './add-new-button.styled';

const AddNewButton = ({
  action,
  to = '',
  className = '',
  text,
  showLoader = false,
  isDisabled = false,
  secondary = false,
  ...props
}) => {
  const renderButton = () => (
    <Fragment>
      {showLoader ? (
        <Loader alwaysVisible isLocal zIndex="999" />
      ) : (
        <AddButton isDisabled={isDisabled} secondary={secondary}>
          +
        </AddButton>
      )}
      <AddButtonText left={6} isDisabled={isDisabled}>
        {text}
      </AddButtonText>
    </Fragment>
  );

  if (action) {
    return (
      <AddButtonWrapper
        alignItems="center"
        disabled={showLoader || isDisabled}
        onClick={showLoader || isDisabled ? () => {} : action}
        className={className}
        data-e2e={`${dashed(text)}-button`}
        role="button"
        {...props}
      >
        {renderButton()}
      </AddButtonWrapper>
    );
  }

  return (
    <AddLinkWrapper to={to} className={className} data-e2e={`${dashed(text)}-button`}>
      {renderButton()}
    </AddLinkWrapper>
  );
};

export default AddNewButton;
