import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, neutral800 } = COLORS;

const IntroducerFeesSettingsModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 580px;
  height: 350px;
`;
const ModalText = styled.div`
  text-align: center;
  width: 500px;
  margin: -10px 0 20px 0;
  color: ${neutral800};
`;

const ModalTitle = styled.div`
  font-size: 35px;
  margin: 20px 0 40px 0;
  font-weight: 300;
  color: ${primary};
  font-family: ${FONTS.oswaldFont};
`;

export { IntroducerFeesSettingsModalWrapper, ModalTitle, ModalText };
