import styled from 'styled-components';

import { AreaBelowTheInput } from 'shared-parts/components/address/components/loqate.styled';
import { FormButton } from 'shared-parts/components/button';
import { TableWrapper } from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS } from 'shared-parts/constants';

const { accent, primary, neutral300, neutral200, accentContrastText } = COLORS;

export const FiltersWrapperUpper = styled.div`
  display: flex;
`;

export const FiltersWrapperLower = styled.div`
  flex: 1;
`;

export const TableContainer = styled.div<{
  withTabs: boolean;
}>`
  ${({ withTabs }) =>
    withTabs
      ? `
    ${TableWrapper} {
      border-radius: 0 6px 6px;
      border-top: none;
    }
  `
      : ''}
`;

export const TabsContainer = styled.div`
  margin-left: 1px;
`;

export const TableTab = styled.button<{
  isActive: boolean;
}>`
  background-color: ${({ isActive }) => (isActive ? accent : neutral200)};
  color: ${({ isActive }) => (isActive ? accentContrastText : accent)};
  margin: 0;
  border: none;
  height: 30px;
  width: 90px;
  font-size: 14px;
  ${({ isActive }) => (isActive ? 'font-weight: bold;' : '')}

  :first-child {
    border-radius: 6px 0 0;
  }
  :last-child {
    border-radius: 0 6px 0 0;
  }
`;

export const FormContainer = styled.div<{
  isBorderHidden?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 20px 0 10px;
  color: ${primary};
  font-weight: bold;

  :not(:last-child) {
    ${({ isBorderHidden }) =>
      !isBorderHidden &&
      `
      border-bottom: 1px solid ${neutral300};
      padding-bottom: 37px;
    `};
  }

  ${AreaBelowTheInput} {
    padding: 10px;
  }
`;

export const FormGroup = styled.div<{
  width?: string;
}>`
  display: inline-block;
  padding: 0 10px;
  width: ${({ width }) => width || '100%'};
  position: relative;
`;

export const AddressFormGroup = styled(FormGroup)`
  margin-bottom: 30px;

  :first-of-type,
  :last-of-type {
    margin-bottom: 0;
  }
`;

export const Label = styled.label<{
  htmlFor?: string;
  isRequired?: boolean;
}>`
  color: ${primary};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  &:after {
    content: ':${({ isRequired = false }) => (isRequired ? '*' : '')}';
  }
`;

export const ResendInviteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 37px 10px 0;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 41px 30px 28px;
`;

export const StyledButton = styled(FormButton)`
  margin: 0 0 0 20px;
`;
