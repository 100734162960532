import {
  ADJUST_DEAL_IOI,
  APPROVE_DEAL_IOI,
  APPROVE_DEAL_IOI_ERROR,
  APPROVE_DEAL_IOI_SUCCESS,
  CANCEL_DEAL_IOI,
  CANCEL_DEAL_IOI_ERROR,
  CANCEL_DEAL_IOI_SUCCESS,
  FETCH_DEAL_IOI_DETAILS,
  FETCH_DEAL_IOI_DETAILS_ERROR,
  FETCH_DEAL_IOI_DETAILS_SUCCESS,
  FETCH_INDICATION_OF_INTEREST_DATA,
  FETCH_INDICATION_OF_INTEREST_DATA_ERROR,
  FETCH_INDICATION_OF_INTEREST_DATA_SUCCESS,
  RESET_DEAL_IOI_DETAILS,
  RESET_INDICATION_OF_INTEREST_DATA,
} from './constants';

export const fetchIndicationOfInterestData = payload => ({
  type: FETCH_INDICATION_OF_INTEREST_DATA,
  payload,
});
export const fetchIndicationOfInterestDataSuccess = payload => ({
  type: FETCH_INDICATION_OF_INTEREST_DATA_SUCCESS,
  payload,
});
export const fetchIndicationOfInterestDataError = payload => ({
  type: FETCH_INDICATION_OF_INTEREST_DATA_ERROR,
  payload,
});
export const resetIndicationOfInterestData = () => ({ type: RESET_INDICATION_OF_INTEREST_DATA });

export const fetchDealIOIDetails = payload => ({ type: FETCH_DEAL_IOI_DETAILS, payload });
export const fetchDealIOIDetailsSuccess = payload => ({
  type: FETCH_DEAL_IOI_DETAILS_SUCCESS,
  payload,
});
export const fetchDealIOIDetailsError = payload => ({
  type: FETCH_DEAL_IOI_DETAILS_ERROR,
  payload,
});
export const resetDealIOIDetails = () => ({ type: RESET_DEAL_IOI_DETAILS });

export const adjustDealIOI = payload => ({ type: ADJUST_DEAL_IOI, payload });

export const approveDealIOI = payload => ({ type: APPROVE_DEAL_IOI, payload });
export const approveDealIOISuccess = () => ({ type: APPROVE_DEAL_IOI_SUCCESS });
export const approveDealIOIError = payload => ({ type: APPROVE_DEAL_IOI_ERROR, payload });

export const cancelDealIOI = payload => ({ type: CANCEL_DEAL_IOI, payload });
export const cancelDealIOISuccess = () => ({ type: CANCEL_DEAL_IOI_SUCCESS });
export const cancelDealIOIError = payload => ({ type: CANCEL_DEAL_IOI_ERROR, payload });
