import { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch } from 'redux';

import { getCompanyEntities as getEntitiesAction } from 'app-state/company-entities/actions';
import { getCompanyEntitiesSelector } from 'app-state/company-entities/selectors';
import { CompanyEntitiesActionTypes, CompanyEntitiesState } from 'app-state/company-entities/types';
import { RootState } from 'app-state/store';

import Select from 'shared-parts/components/select';
import { OptionType } from 'shared-parts/components/select/select.types';

type EntityProps = {
  getCompanyEntities(): CompanyEntitiesActionTypes;
  companyEntities: CompanyEntitiesState;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
};

const Entity: FC<React.PropsWithChildren<EntityProps>> = ({
  getCompanyEntities,
  companyEntities,
  disabled,
  placeholder = 'Entity',
  name = 'entityCompanyId',
}: EntityProps) => {
  useEffect(() => {
    getCompanyEntities();
  }, []);
  const transformedEntities: OptionType[] = companyEntities.data.map(entity => ({
    text: entity.name,
    // TODO: Make OptionType value as generic
    value: entity.id as unknown as string,
  }));

  return (
    <Select
      name={name}
      placeholder={placeholder}
      disableAutocomplete
      options={transformedEntities}
      disabled={disabled}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  companyEntities: getCompanyEntitiesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCompanyEntities: () => dispatch(getEntitiesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entity);
