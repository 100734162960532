import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const { primary } = COLORS;

const FormGroup = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || 18}px;
  width: ${({ width }) => `${width || '100%'}`};
  padding: 0 30px 0 20px;
  color: ${primary};
`;

export default FormGroup;
