const ListIcon = (fillColor = '#333', height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="UI-Style-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="09.-Iconography" transform="translate(-574.000000, -759.000000)">
        <rect fill="#FAFAFA" x="0" y="0" width="1680" height="2427" />
        <g id="Group_4752" transform="translate(574.000000, 759.000000)" fillRule="nonzero">
          <rect className="icon" x="0" y="0" width="40" height="40" rx="2" />
          <rect fill="#EEEFF3" x="12" y="12" width="17.002" height="3.11" rx="0.777" />
          <rect fill="#EEEFF3" x="12" y="18" width="17.002" height="3.11" rx="0.777" />
          <rect fill="#EEEFF3" x="12" y="24" width="17.002" height="3.11" rx="0.777" />
        </g>
      </g>
    </g>
  </svg>
);

export default ListIcon;
