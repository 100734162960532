interface User {
  operationalAdmin?: boolean;
  isMulticompanyAdmin?: boolean;
}

export type OrganizationType = 'Operations' | 'MultiCompany' | 'Company';

export default (user: User): OrganizationType => {
  if (user.operationalAdmin) return 'Operations';
  if (user.isMulticompanyAdmin) return 'MultiCompany';
  return 'Company';
};
