import { Fragment } from 'react';

import ExitWithoutSavingButton from 'shared/exit-without-saving-button';
import Button from 'shared-parts/components/button/formik-button';
import { COLORS } from 'shared-parts/constants';

import { CONFIRM, EXIT } from './constants';
import {
  BottomContainer,
  Cell,
  Column,
  Header,
  Line,
  OverviewWrapper,
  Section,
  SectionHeader,
  StyledArrowRight,
  StyledTable,
  Text,
  Value,
} from './index.styled';

const { neutral800 } = COLORS;

const renderCell = ({ label, value, text, isUpperCase, isCapitalize, isColumn = false }) => (
  <Cell isColumn={isColumn}>
    {label && <Text>{label}</Text>}
    {value && (
      <Value isUpperCase={isUpperCase} isCapitalize={isCapitalize} marginTop={isColumn ? 10 : 0}>
        {value}
      </Value>
    )}
    {text && <Text>{text}</Text>}
  </Cell>
);

const renderLine = line =>
  line.map((cell, index) => <Fragment key={index}>{renderCell(cell)}</Fragment>);

const renderMultilineSection = content => (
  <Column>
    {content.map((line, index) => (
      <Line key={index}>{renderLine(line)}</Line>
    ))}
  </Column>
);

const renderFromToRow = ({ from, to }) => (
  <>
    {renderCell(from)}
    <StyledArrowRight width="11" color={neutral800} />
    {renderCell(to)}
  </>
);

const renderTable = props => <StyledTable {...props} />;

const renderSection = ({ type, content }) => {
  switch (type) {
    case 'fromTo':
      return renderFromToRow(content);
    case 'line':
      return renderLine(content);
    case 'multiline':
      return renderMultilineSection(content);
    case 'table':
      return renderTable(content);
    case 'cell':
    default:
      return renderCell(content);
  }
};

const renderSections = sections =>
  sections.map((section, index) =>
    section.type === 'header' ? (
      <SectionHeader key={index}>{section.content}</SectionHeader>
    ) : (
      <Section key={index} noUnderline={section.noUnderline}>
        {renderSection(section)}
      </Section>
    ),
  );

const FormOverview = ({
  title,
  sections,
  hideOverview,
  handleSubmit,
  disabled,
  formContainer: Container = OverviewWrapper,
  backButtonText = EXIT,
  submitButtonText = CONFIRM,
}) => (
  <Container>
    <Header>{title}</Header>
    {renderSections(sections)}
    <BottomContainer>
      <ExitWithoutSavingButton onClick={hideOverview} text={backButtonText} />
      <Button type="button" onClick={handleSubmit} data-e2e="proceed-button" disabled={disabled}>
        {submitButtonText}
      </Button>
    </BottomContainer>
  </Container>
);

export default FormOverview;
