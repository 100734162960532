import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import FinishIcon from 'shared-parts/images/finish-icon.svg';
import FormInvalidIcon from 'shared-parts/images/form-invalid.svg';

const Container = styled.div`
  background: ${({ isValueValid, touched }) =>
    `${COLORS.white} ${
      touched ? `url(${isValueValid ? FinishIcon : FormInvalidIcon}) no-repeat scroll` : ''
    };`};
  background-position: right 8px center;
  background-size: 20px 20px;
  border: ${({ isValueValid, touched }) =>
    (!isValueValid && touched && `1px solid ${COLORS.systemRed}`) ||
    `1px solid ${COLORS.neutral500}`};
  border-radius: 6px;
  height: 50px;
  position: relative;
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}

  :focus-within {
    ${({ isValueValid }) => isValueValid && `border: 1px solid ${COLORS.secondary};`}
  }

  ${({ withIcon }) => withIcon && 'padding-right: 46px;'}
`;

const getRightPadding = password => (password ? 140 : 45);

const Input = styled.input`
  background: none transparent;
  border: 0 none;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  color: ${COLORS.primary};
  display: inline-block;
  font-family: ${FONTS.mainFont};
  font-size: 16px;
  height: 48px;
  padding: 0 ${({ touched, password }) => (touched ? getRightPadding(password) : 21)}px 0 21px;
  width: 100%;
  -webkit-appearance: none;

  /* Chrome, Safari, Edge, Opera hide arrows for type number */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox hide arrows for type number */
  [type='number'] {
    -moz-appearance: textfield;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${COLORS.success100} inset !important;
    -webkit-box-shadow: 0 0 0 1000px ${COLORS.success100} inset !important;
  }

  &:disabled {
    background: ${COLORS.neutral300};
    border-radius: 6px;
    height: 48px;
    left: 0;
    padding: 0 21px;
    width: 100%;
    color: ${COLORS.neutral800};
    cursor: default;

    &::-webkit-input-placeholder {
      color: ${COLORS.neutral500};
    }

    &::-moz-placeholder {
      color: ${COLORS.neutral500};
    }

    &:-ms-input-placeholder {
      color: ${COLORS.neutral500};
    }
  }

  &::-webkit-input-placeholder {
    color: ${COLORS.neutral500};
    display: inline-block;
    font-family: ${FONTS.mainFont};
    font-size: 16px;
  }

  &::-moz-placeholder {
    color: ${COLORS.neutral500};
    display: inline-block;
    font-family: ${FONTS.mainFont};
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    color: ${COLORS.neutral500};
    display: inline-block;
    font-family: ${FONTS.mainFont};
    font-size: 16px;
  }

  ${({ disableAutocomplete }) =>
    disableAutocomplete &&
    `
    &::-webkit-contacts-auto-fill-button,
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  `}
`;

const ShowPassword = styled.span`
  color: ${({ color }) => color || ''};
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: ${({ touched }) => (touched ? '45px' : '20px')};
  top: 15px;
  user-select: none;
`;

const HintText = styled.span`
  color: ${COLORS.neutral800};
  font-size: 14px;
  position: absolute;
  right: ${({ touched }) => (touched ? '45px' : '12px')};
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  width: 48px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border-left: 1px solid ${COLORS.neutral500};
`;

const DisableAutocompleteFakeInput = styled.input`
  display: none;
`;

export { Container, Input, ShowPassword, IconContainer, DisableAutocompleteFakeInput, HintText };
