import { call, put, select, takeEvery } from 'redux-saga/effects';

import { showModal } from 'app-state/actions/shared';

import { INVESTOR_TYPE } from 'modules/all-investors/constants';
import InvestorForm from 'modules/investors/investor-form';
import API from 'constants/api';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import request from 'shared-parts/helpers/request';

import {
  addWalletTransferError,
  addWalletTransferSuccess,
  editInvestorError,
  editInvestorSuccess,
  fetchInvestorAgentsPermissionsError,
  fetchInvestorAgentsPermissionsSuccess,
  fetchInvestorDealsError,
  fetchInvestorDealsSuccess,
  fetchInvestorError,
  fetchInvestorInvestmentsError,
  fetchInvestorInvestmentsSuccess,
  fetchInvestorPreferencesError,
  fetchInvestorPreferencesSuccess,
  fetchInvestors as fetchInvestorsAction,
  fetchInvestorsError,
  fetchInvestorsSuccess,
  fetchInvestorSuccess,
  fetchWalletReferenceError,
  fetchWalletReferenceSuccess,
  fetchWalletTransactions as fetchWalletTransactionsAction,
  fetchWalletTransactionsError,
  fetchWalletTransactionsSuccess,
  inviteInvestorError,
  inviteInvestorSuccess,
  updateInvestorDealsError,
  updateInvestorDealsSuccess,
} from './actions';
import {
  ADD_WALLET_TRANSFER,
  EDIT_INVESTOR,
  FETCH_INVESTOR,
  FETCH_INVESTOR_AGENTS_PERMISSIONS,
  FETCH_INVESTOR_DEALS,
  FETCH_INVESTOR_INVESTMENTS,
  FETCH_INVESTOR_PREFERENCES,
  FETCH_INVESTORS,
  FETCH_WALLET_REFERENCE,
  FETCH_WALLET_TRANSACTIONS,
  INVITE_INVESTOR,
  UPDATE_INVESTOR_DEALS,
} from './constants';
import { getWalletTransactionsPagination } from './selectors';

export function* fetchInvestors({ payload }) {
  try {
    const { data } = yield call(request, API.Investors(payload), 'GET', null, { timeout: 60000 });

    yield put(fetchInvestorsSuccess(data));
  } catch (e) {
    yield put(fetchInvestorsError(e));
  }
}

export function* fetchInvestorsWatcher() {
  yield takeEvery(FETCH_INVESTORS, fetchInvestors);
}

export function* inviteInvestor({
  payload: {
    setSubmitting,
    setErrors,
    closeForm,
    status,
    investorType = INVESTOR_TYPE.INSTITUTIONAL,
    title = 'DONE',
    ...formData
  },
}) {
  const endpointUrl =
    investorType === INVESTOR_TYPE.INDIVIDUAL
      ? API.InviteIndividualInvestor()
      : API.InviteInvestor();

  try {
    const { data } = yield call(request, endpointUrl, 'POST', formData);

    yield put(inviteInvestorSuccess(data));
    yield put(fetchInvestorsAction({ status }));
    yield put(
      showModal({
        component: SuccessModal,
        closable: true,
        showHeader: false,
        modalWidth: '580',
        title,
        buttonText: 'Continue',
      }),
    );
    closeForm();
  } catch (e) {
    yield put(inviteInvestorError(e));
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

export function* inviteInvestorWatcher() {
  yield takeEvery(INVITE_INVESTOR, inviteInvestor);
}

export function* fetchInvestor({
  payload: {
    uuid,
    setIsFormOpen,
    setSideFormParams,
    handleFormSubmit,
    closeForm,
    isFormDisabled,
    isDocumentsVisible,
    isWalletsTabVisible,
    isInteractionsVisible,
    dealUuid,
    updateList,
  },
}) {
  try {
    const { data } = yield call(request, API.Investor(uuid));

    yield put(fetchInvestorSuccess(data));
    closeForm();
    setSideFormParams({
      header: <b>{`${data.firstName} ${data.lastName}`}</b>,
      component: InvestorForm,
      closeForm,
      width: '900px',
      handleFormSubmit,
      updateList,
      initialValues: {
        customerNumber: data.customerNumber,
        email: data.email,
        entityCompanyId: data.entityCompany?.id,
        entityCompanyName: data.entityCompany?.name,
        firstName: data.firstName,
        lastName: data.lastName,
        institutionName: data.investor?.name,
        institutionNumber: data.investor?.institutionNumber,
        institutionCountry: data.investor?.institutionCountry,
        addressFirstLine: data.investor?.address?.addressLine1,
        line2: data.investor?.address?.addressLine2,
        postCode: data.investor?.address?.postCode,
        city: data.investor?.address?.city,
        state: data.investor?.address?.state,
        countryCode: data.investor?.address?.countryCode,
      },
      isEditing: true,
      lastInviteSentAt: data.lastInviteSentAt,
      investorUuid: data.uuid,
      investorStatus: data.status,
      isFormDisabled,
      isDocumentsVisible,
      isWalletsTabVisible,
      isInteractionsVisible,
      investorType: data.investorType,
      dealUuid,
      investor: data,
    });
    if (setIsFormOpen) {
      setIsFormOpen(true);
    }
  } catch (e) {
    yield put(fetchInvestorError(e));
  }
}

export function* fetchInvestorWatcher() {
  yield takeEvery(FETCH_INVESTOR, fetchInvestor);
}

export function* editInvestor({
  payload: {
    uuid,
    setSubmitting,
    setErrors,
    closeForm,
    updateList,
    title = 'INVESTOR UPDATED',
    ...formData
  },
}) {
  try {
    const { data } = yield call(request, API.Investor(uuid), 'PUT', formData);

    yield put(editInvestorSuccess(data));
    yield put(
      showModal({
        component: SuccessModal,
        closable: true,
        showHeader: false,
        modalWidth: '580',
        title,
      }),
    );
    updateList();
    closeForm();
  } catch (e) {
    yield put(editInvestorError(e));
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

export function* editInvestorWatcher() {
  yield takeEvery(EDIT_INVESTOR, editInvestor);
}

export function* fetchWalletReference({ payload: { uuid, currency } }) {
  try {
    const { data } = yield call(request, API.WalletReference(uuid, currency));

    yield put(fetchWalletReferenceSuccess(data));
  } catch (e) {
    yield put(fetchWalletReferenceError(e));
  }
}

export function* fetchWalletReferenceWatcher() {
  yield takeEvery(FETCH_WALLET_REFERENCE, fetchWalletReference);
}

export function* addWalletTransfer({ payload: { uuid, ...data } }) {
  try {
    yield call(request, API.WalletTransfer(uuid), 'POST', data);

    yield put(addWalletTransferSuccess());

    const pagination = yield select(getWalletTransactionsPagination);
    yield put(fetchWalletTransactionsAction({ uuid, ...pagination }));
  } catch (e) {
    yield put(addWalletTransferError(e));
  }
}

export function* addWalletTransferWatcher() {
  yield takeEvery(ADD_WALLET_TRANSFER, addWalletTransfer);
}

export function* fetchWalletTransactions({ payload }) {
  try {
    const { data } = yield call(request, API.WalletTransactions(payload));

    yield put(fetchWalletTransactionsSuccess(data));
  } catch (e) {
    yield put(fetchWalletTransactionsError(e));
  }
}

export function* fetchWalletTransactionsWatcher() {
  yield takeEvery(FETCH_WALLET_TRANSACTIONS, fetchWalletTransactions);
}

export function* fetchInvestorInvestments({ payload }) {
  try {
    const { data } = yield call(request, API.InvestorInvestments(payload));

    yield put(fetchInvestorInvestmentsSuccess(data));
  } catch (e) {
    yield put(fetchInvestorInvestmentsError(e));
  }
}

export function* fetchInvestorInvestmentsWatcher() {
  yield takeEvery(FETCH_INVESTOR_INVESTMENTS, fetchInvestorInvestments);
}

export function* fetchInvestorPreferences({ payload }) {
  try {
    const { data } = yield call(request, API.InvestorPreferences(payload));

    yield put(fetchInvestorPreferencesSuccess(data));
  } catch (e) {
    yield put(fetchInvestorPreferencesError(e));
  }
}

export function* fetchInvestorPreferencesWatcher() {
  yield takeEvery(FETCH_INVESTOR_PREFERENCES, fetchInvestorPreferences);
}

export function* fetchInvestorDeals({ payload: { uuid } }) {
  try {
    const { data } = yield call(request, API.InvestorDeals(uuid));

    yield put(fetchInvestorDealsSuccess(data.items));
  } catch (e) {
    yield put(fetchInvestorDealsError(e));
  }
}

export function* fetchInvestorDealsWatcher() {
  yield takeEvery(FETCH_INVESTOR_DEALS, fetchInvestorDeals);
}

export function* updateInvestorDeals({ payload: { uuid, data, updateList } }) {
  try {
    const { data: deals } = yield call(request, API.InvestorDeals(uuid), 'PUT', data);

    yield put(updateInvestorDealsSuccess(deals.items));
    yield put(fetchInvestorDealsSuccess(deals.items));
    if (updateList) {
      updateList();
    }
  } catch (e) {
    yield put(updateInvestorDealsError(e));
  }
}

export function* updateInvestorDealsWatcher() {
  yield takeEvery(UPDATE_INVESTOR_DEALS, updateInvestorDeals);
}

export function* fetchInvestorAgentsPermissions({ payload }) {
  try {
    const { data } = yield call(request, API.InvestorAgentsPermissions(payload));

    yield put(fetchInvestorAgentsPermissionsSuccess(data.items));
  } catch (e) {
    yield put(fetchInvestorAgentsPermissionsError(e));
  }
}

export function* fetchInvestorAgentsPermissionsWatcher() {
  yield takeEvery(FETCH_INVESTOR_AGENTS_PERMISSIONS, fetchInvestorAgentsPermissions);
}
