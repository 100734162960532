import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { hideSideForm, showModal } from 'app-state/actions';

import API from 'constants/api';
import { request } from 'helpers';
import { ModalFailed } from 'shared-parts/components';
import SuccessModal from 'shared-parts/components/modal/success-modal';

import {
  bulkInviteEmployeesFailure,
  bulkInviteEmployeesSuccess,
  Employee,
  inviteEmployeesFailure,
  inviteEmployeesSuccess,
} from './share-plan-invite-employees.actions';
import * as constants from './share-plan-invite-employees.constants';

function* bulkInviteEmployees(action: AnyAction) {
  try {
    yield call(
      request,
      API.ShareInvestmentPlan.InviteEmployees.BulkInvite(action.id),
      'POST',
      action.data,
      {
        mode: 'cors',
        snakeCaseParams: false,
        to: 'text',
      },
    );

    yield put(bulkInviteEmployeesSuccess());
  } catch (e: any) {
    yield put(
      bulkInviteEmployeesFailure({ error: e?.response?.message || 'Something went wrong' }),
    );
  }
}
export function* sharePlanBulkInviteEmployeesWatcher() {
  yield takeLatest(constants.bulkInviteEmployees.REQUEST, bulkInviteEmployees);
}
function* inviteEmployees(action: AnyAction) {
  try {
    yield call(
      request,
      API.ShareInvestmentPlan.InviteEmployees.Invite(action.id),
      'POST',
      { investorEmails: action.data.map((employee: Employee) => employee.email) },
      {
        mode: 'cors',
        snakeCaseParams: false,
        to: 'text',
      },
    );

    yield put(inviteEmployeesSuccess());
    yield put(hideSideForm());
    yield put(
      showModal({
        component: SuccessModal,
        closable: true,
        showHeader: false,
        modalWidth: 580,
        title: 'INVITE SENT',
      }),
    );
  } catch (e: any) {
    yield put(inviteEmployeesFailure({ error: e?.response?.message || 'Something went wrong' }));
    yield put(hideSideForm());
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 580,
      }),
    );
  }
}
export function* sharePlanInviteEmployeesWatcher() {
  yield takeLatest(constants.inviteEmployees.REQUEST, inviteEmployees);
}
