import * as constants from 'app-state/constants';

export const initialState = null;

export const generateOnboardingLinkErrorReducer = (_state = initialState, action) => {
  switch (action.type) {
    case constants.SET_GENERATE_ONBOARDING_LINK_ERROR:
      return action.error;
    case constants.RESET_GENERATE_ONBOARDING_LINK_ERROR:
    default:
      return initialState;
  }
};
