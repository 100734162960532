import * as constants from './constants';

export const getDraftCommunication = (params: any) => ({
  type: constants.GET_DRAFT_COMMUNICATION,
  ...params,
});
export const getDraftCommunicationSuccess = (data: any) => ({
  type: constants.GET_DRAFT_COMMUNICATION_SUCCESS,
  data,
});
export const getDraftCommunicationError = (error: any) => ({
  type: constants.GET_DRAFT_COMMUNICATION_ERROR,
  error,
});

export const createDraftCommunication = () => ({ type: constants.CREATE_DRAFT_COMMUNICATION });
export const createDraftCommunicationSuccess = (communication: any) => ({
  type: constants.CREATE_DRAFT_COMMUNICATION_SUCCESS,
  data: communication,
});
export const createDraftCommunicationError = (error: any) => ({
  type: constants.CREATE_DRAFT_COMMUNICATION_ERROR,
  error,
});

export const resetDraftCommunication = () => ({ type: constants.RESET_DRAFT_COMMUNICATION });

export const getPublishedCommunication = (params: any) => ({
  type: constants.GET_PUBLISHED_COMMUNICATION,
  ...params,
});
export const getPublishedCommunicationSuccess = (data: any) => ({
  type: constants.GET_PUBLISHED_COMMUNICATION_SUCCESS,
  data,
});
export const getPublishedCommunicationError = (error: any) => ({
  type: constants.GET_PUBLISHED_COMMUNICATION_ERROR,
  error,
});
export const resetPublishedCommunication = () => ({
  type: constants.RESET_PUBLISHED_COMMUNICATION,
});

export const saveDraftCommunication = (patch: any) => ({
  type: constants.SAVE_DRAFT_COMMUNICATION,
  communication: patch,
});
export const saveDraftCommunicationSuccess = (data: any) => ({
  type: constants.SAVE_DRAFT_COMMUNICATION_SUCCESS,
  data,
});
export const saveDraftCommunicationError = (error: any) => ({
  type: constants.SAVE_DRAFT_COMMUNICATION_ERROR,
  error,
});

export const deleteDraftCommunication = (params: any) => ({
  type: constants.DELETE_DRAFT_COMMUNICATION,
  ...params,
});
export const deleteDraftCommunicationSuccess = (communicationUuid: any) => ({
  type: constants.DELETE_DRAFT_COMMUNICATION_SUCCESS,
  communicationUuid,
});
export const deleteDraftCommunicationError = (error: any) => ({
  type: constants.DELETE_DRAFT_COMMUNICATION_ERROR,
  error,
});

export const draftCommunicationAddAttachment = (params: any) => ({
  type: constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT,
  ...params,
});
export const draftCommunicationAddAttachmentSuccess = (data: any) => ({
  type: constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT_SUCCESS,
  data,
});
export const draftCommunicationAddAttachmentError = (error: any) => ({
  type: constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT_ERROR,
  error,
});

export const draftCommunicationDeleteAttachment = (params: any) => ({
  type: constants.DRAFT_COMMUNICATION_DELETE_ATTACHMENT,
  ...params,
});
export const draftCommunicationDeleteAttachmentSuccess = (data: any) => ({
  type: constants.DRAFT_COMMUNICATION_DELETE_ATTACHMENT_SUCCESS,
  data,
});
export const draftCommunicationDeleteAttachmentError = (error: any) => ({
  type: constants.DRAFT_COMMUNICATION_DELETE_ATTACHMENT_ERROR,
  error,
});

export const getCommunications = (companyUuid: string) => ({
  type: constants.GET_COMMUNICATIONS,
  companyUuid,
});
export const getCommunicationsSuccess = (data: any) => ({
  type: constants.GET_COMMUNICATIONS_SUCCESS,
  data,
});
export const getCommunicationsError = (error: any) => ({
  type: constants.GET_COMMUNICATIONS_ERROR,
  error,
});
export const resetCommunications = (companyUuid: string) => ({
  type: constants.RESET_COMMUNICATIONS,
  companyUuid,
});

export const publishDraftCommunication = (params: any) => ({
  type: constants.PUBLISH_DRAFT_COMMUNICATION,
  ...params,
});
export const publishDraftCommunicationSuccess = (data: any) => ({
  type: constants.PUBLISH_DRAFT_COMMUNICATION_SUCCESS,
  data,
});
export const publishDraftCommunicationError = (error: any) => ({
  type: constants.PUBLISH_DRAFT_COMMUNICATION_ERROR,
  error,
});

export const getCommunicationResponsesStatistics = (
  companyUuid: string,
  communicationUuid: string,
) => ({
  type: constants.GET_COMMUNICATION_RESPONSES_STATISTICS,
  companyUuid,
  communicationUuid,
});
export const getCommunicationResponsesStatisticsSuccess = (data: any) => ({
  type: constants.GET_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS,
  data,
});
export const getCommunicationResponsesStatisticsError = (error: any) => ({
  type: constants.GET_COMMUNICATION_RESPONSES_STATISTICS_ERROR,
  error,
});

export const resetCommunicationResponsesStatistics = () => ({
  type: constants.RESET_COMMUNICATION_RESPONSES_STATISTICS,
});

export const exportCommunicationResponsesStatistics = (
  companyUuid: string,
  communicationUuid: string,
) => ({
  type: constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS,
  companyUuid,
  communicationUuid,
});
export const exportCommunicationResponsesStatisticsSuccess = () => ({
  type: constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS,
});
export const exportCommunicationResponsesStatisticsError = (error: any) => ({
  type: constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS_ERROR,
  error,
});

export const getAggregatedCommunicationResponsesStatistics = (
  companyUuid: string,
  communicationUuid: string,
  page: number,
) => ({
  type: constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS,
  companyUuid,
  communicationUuid,
  page,
});
export const getAggregatedCommunicationResponsesStatisticsSuccess = (data: any) => ({
  type: constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS,
  data,
});
export const getAggregatedCommunicationResponsesStatisticsError = (error: any) => ({
  type: constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_ERROR,
  error,
});
export const resetAggregatedCommunicationResponsesStatistics = () => ({
  type: constants.RESET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS,
});

export const draftCommunicationAddResponse = (params: any) => ({
  type: constants.DRAFT_COMMUNICATION_ADD_RESPONSE,
  ...params,
});
export const draftCommunicationAddResponseSuccess = (data: any) => ({
  type: constants.DRAFT_COMMUNICATION_ADD_RESPONSE_SUCCESS,
  data,
});
export const draftCommunicationAddResponseError = (error: any) => ({
  type: constants.DRAFT_COMMUNICATION_ADD_RESPONSE_ERROR,
  error,
});

export const draftCommunicationDeleteResponse = (params: any) => ({
  type: constants.DRAFT_COMMUNICATION_DELETE_RESPONSE,
  ...params,
});
export const draftCommunicationDeleteResponseSuccess = (data: any) => ({
  type: constants.DRAFT_COMMUNICATION_DELETE_RESPONSE_SUCCESS,
  data,
});
export const draftCommunicationDeleteResponseError = (error: any) => ({
  type: constants.DRAFT_COMMUNICATION_DELETE_RESPONSE_ERROR,
  error,
});

export const fetchShareholdersEmails = (payload: any) => ({
  type: constants.FETCH_SHAREHOLDERS_EMAILS,
  payload,
});
export const fetchShareholdersEmailsSuccess = (payload: any) => ({
  type: constants.FETCH_SHAREHOLDERS_EMAILS_SUCCESS,
  payload,
});
export const fetchShareholdersEmailsError = (payload: any) => ({
  type: constants.FETCH_SHAREHOLDERS_EMAILS_ERROR,
  payload,
});
export const resetShareholdersEmails = () => ({ type: constants.RESET_SHAREHOLDERS_EMAILS });
