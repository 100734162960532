import {
  GET_TREASURY_ACCOUNT,
  GET_TREASURY_ACCOUNT_ERROR,
  GET_TREASURY_ACCOUNT_SUCCESS,
} from 'app-state/constants/treasury-account';

export const initialTreasuryAccountState = {
  loading: true,
  data: null,
  error: null,
};

export const treasuryAccountReducer = (
  state = initialTreasuryAccountState,
  { type, data, error },
) => {
  switch (type) {
    case GET_TREASURY_ACCOUNT:
      return { ...state, loading: true };
    case GET_TREASURY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case GET_TREASURY_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error,
      };
    default:
      return state;
  }
};
