const editSubNavigationItem = (state, editedItem) =>
  state.data.map(item => {
    if (item.id === editedItem.parentId) {
      const newChildren = item.childs.map(child =>
        child.id === editedItem.id ? editedItem : child,
      );

      return { ...item, childs: newChildren };
    }
    return item;
  });

const editNavigationItem = (state, editedItem) =>
  state.data.map(item => (item.id === editedItem.id ? editedItem : item));

const createNavigationItem = ({ data }, createdItem) => {
  const [dealSummary] = data;
  const [terms] = data.slice(-1);
  const otherItems = data.slice(1, -1);

  return [dealSummary, ...otherItems, createdItem, terms];
};

const createSubNavigationItem = (state, createdItem) =>
  state.data.map(item => {
    if (item.id === createdItem.parentId) {
      const { childs = [] } = item;
      const newChildren = [...childs, createdItem];

      return {
        ...item,
        hasSubnavigationItem: true,
        hasPageContent: false,
        childs: newChildren,
      };
    }

    return item;
  });

const removeSubNavigationItem = (state, deletedItem) =>
  state.data.map(item => {
    if (item.id === deletedItem.navigationItemId) {
      const newChildren = item.childs.filter(child => child.id !== deletedItem.id);

      return { ...item, childs: newChildren };
    }

    return item;
  });

const removeNavigationItem = (state, deletedItem) =>
  state.data.filter(item => item.id !== deletedItem.id);

export {
  editSubNavigationItem,
  editNavigationItem,
  createSubNavigationItem,
  createNavigationItem,
  removeSubNavigationItem,
  removeNavigationItem,
};
