export const GET_SHAREHOLDERS_DROPDOWN_DATA = 'GET_SHAREHOLDERS_DROPDOWN_DATA';
export const GET_SHAREHOLDERS_DROPDOWN_DATA_SUCCESS = 'GET_SHAREHOLDERS_DROPDOWN_DATA_SUCCESS';
export const GET_SHAREHOLDERS_DROPDOWN_DATA_ERROR = 'GET_SHAREHOLDERS_DROPDOWN_DATA_ERROR';
export const RESET_SHAREHOLDERS_DROPDOWN_DATA = 'RESET_SHAREHOLDERS_DROPDOWN_DATA';

export const GET_HOLDINGS = 'GET_HOLDINGS';
export const GET_HOLDINGS_SUCCESS = 'GET_HOLDINGS_SUCCESS';
export const GET_HOLDINGS_ERROR = 'GET_HOLDINGS_ERROR';
export const RESET_HOLDINGS = 'RESET_HOLDINGS';

export const DOWNLOAD_SHAREHOLDER_CERT = 'DOWNLOAD_SHAREHOLDER_CERT';

export const CREATE_SHAREHOLDER = 'CREATE_SHAREHOLDER';
export const CREATE_SHAREHOLDER_SUCCESS = 'CREATE_SHAREHOLDER_SUCCESS';
export const CREATE_SHAREHOLDER_ERROR = 'CREATE_SHAREHOLDER_ERROR';

export const EDIT_SHAREHOLDER = 'EDIT_SHAREHOLDER';
export const EDIT_SHAREHOLDER_SUCCESS = 'EDIT_SHAREHOLDER_SUCCESS';
export const EDIT_SHAREHOLDER_ERROR = 'EDIT_SHAREHOLDER_ERROR';

export const GET_SHAREHOLDERS = 'GET_SHAREHOLDERS';
export const GET_SHAREHOLDERS_SUCCESS = 'GET_SHAREHOLDERS_SUCCESS';
export const GET_SHAREHOLDERS_ERROR = 'GET_SHAREHOLDERS_ERROR';
export const RESET_SHAREHOLDERS = 'RESET_SHAREHOLDERS';

export const GET_REGISTERED_SHAREHOLDERS = 'GET_REGISTERED_SHAREHOLDERS';
export const GET_REGISTERED_SHAREHOLDERS_SUCCESS = 'GET_REGISTERED_SHAREHOLDERS_SUCCESS';
export const GET_REGISTERED_SHAREHOLDERS_ERROR = 'GET_REGISTERED_SHAREHOLDERS_ERROR';
export const RESET_REGISTERED_SHAREHOLDERS = 'RESET_REGISTERED_SHAREHOLDERS';

export const GET_TRANSFER_SHAREHOLDERS = 'GET_TRANSFER_SHAREHOLDERS';
export const GET_TRANSFER_SHAREHOLDERS_SUCCESS = 'GET_TRANSFER_SHAREHOLDERS_SUCCESS';
export const GET_TRANSFER_SHAREHOLDERS_ERROR = 'GET_TRANSFER_SHAREHOLDERS_ERROR';

export const DOWNLOAD_SHAREHOLDER_REGISTRY = 'DOWNLOAD_SHAREHOLDER_REGISTRY';

export const UPDATE_SHAREHOLDER_TRANSACTIONS = 'UPDATE_SHAREHOLDER_TRANSACTIONS';
export const UPDATE_SHAREHOLDER_TRANSACTIONS_SUCCESS = 'UPDATE_SHAREHOLDER_TRANSACTIONS_SUCCESS';
export const UPDATE_SHAREHOLDER_TRANSACTIONS_ERROR = 'UPDATE_SHAREHOLDER_TRANSACTIONS_ERROR';

export const ALLOCATE_SHAREHOLDING = 'ALLOCATE_SHAREHOLDING';
export const ALLOCATE_SHAREHOLDING_ERROR = 'ALLOCATE_SHAREHOLDING_ERROR';

export const TRANSFER_SHAREHOLDING = 'TRANSFER_SHAREHOLDING';
export const TRANSFER_SHAREHOLDING_ERROR = 'TRANSFER_SHAREHOLDING_ERROR';

export const SHAREHOLDER_TRANSACTION_REVERT = 'SHAREHOLDER_TRANSACTION_REVERT';
export const SHAREHOLDER_TRANSACTION_REVERT_ERROR = 'SHAREHOLDER_TRANSACTION_REVERT_ERROR';

export const GET_BULK_WELCOME_EMAIL_INFO = 'GET_BULK_WELCOME_EMAIL_INFO';

export const SEND_BULK_WELCOME_EMAIL = 'SEND_BULK_WELCOME_EMAIL';

export const LOOKUP_SHAREHOLDER = 'LOOKUP_SHAREHOLDER';
export const LOOKUP_SHAREHOLDER_SUCCESS = 'LOOKUP_SHAREHOLDER_SUCCESS';
export const LOOKUP_SHAREHOLDER_ERROR = 'LOOKUP_SHAREHOLDER_ERROR';
export const RESET_LOOKUP_SHAREHOLDER = 'RESET_LOOKUP_SHAREHOLDER';

export const SAVE_CURRENT_SHAREHOLDER_TRANSACTION = 'SAVE_CURRENT_SHAREHOLDER_TRANSACTION';
export const RESET_CURRENT_SHAREHOLDER_TRANSACTION = 'RESET_CURRENT_SHAREHOLDER_TRANSACTION';

export const ADD_TRANSACTION_NOTE = 'ADD_TRANSACTION_NOTE';
export const ADD_TRANSACTION_NOTE_SUCCESS = 'ADD_TRANSACTION_NOTE_SUCCESS';
export const ADD_TRANSACTION_NOTE_ERROR = 'ADD_TRANSACTION_NOTE_ERROR';

export const UPDATE_TRANSACTION_NOTE = 'UPDATE_TRANSACTION_NOTE';
export const UPDATE_TRANSACTION_NOTE_SUCCESS = 'UPDATE_TRANSACTION_NOTE_SUCCESS';
export const UPDATE_TRANSACTION_NOTE_ERROR = 'UPDATE_TRANSACTION_NOTE_ERROR';

export const REMOVE_TRANSACTION_NOTE = 'REMOVE_TRANSACTION_NOTE';
export const REMOVE_TRANSACTION_NOTE_SUCCESS = 'REMOVE_TRANSACTION_NOTE_SUCCESS';
export const REMOVE_TRANSACTION_NOTE_ERROR = 'REMOVE_TRANSACTION_NOTE_ERROR';

export const SEARCH_SHAREHOLDERS = 'SEARCH_SHAREHOLDERS';
export const SEARCH_SHAREHOLDERS_SUCCESS = 'SEARCH_SHAREHOLDERS_SUCCESS';
export const SEARCH_SHAREHOLDERS_ERROR = 'SEARCH_SHAREHOLDERS_ERROR';
export const RESET_SHAREHOLDERS_SEARCH = 'RESET_SHAREHOLDERS_SEARCH';

export const EDIT_SHAREHOLDER_PERFORMANCE = 'EDIT_SHAREHOLDER_PERFORMANCE';
export const EDIT_SHAREHOLDER_PERFORMANCE_SUCCESS = 'EDIT_SHAREHOLDER_PERFORMANCE_SUCCESS';
export const EDIT_SHAREHOLDER_PERFORMANCE_ERROR = 'EDIT_SHAREHOLDER_PERFORMANCE_ERROR';
