import * as constants from 'app-state/constants';
import {
  ADD_NEW_GRANT,
  ADD_NEW_GRANT_ERROR,
  ADD_NEW_GRANT_SUCCESS,
  RESET_NEW_GRANT_ADDING_STATE,
} from 'app-state/constants/share-options';

import getReducerState from './get-reducer-state';

const initialShareOptionsState = getReducerState();

export const shareOptionsReducer = (state = initialShareOptionsState, action) => {
  switch (action.type) {
    case constants.GET_SHARE_OPTIONS:
      return initialShareOptionsState;
    case constants.GET_SHARE_OPTIONS_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_SHARE_OPTIONS_ERROR:
      return { loading: false, data: [], error: action.error };
    case constants.CLEAR_SHARE_OPTIONS:
      return initialShareOptionsState;
    default:
      return state;
  }
};

const grantHoldersInitialState = getReducerState({ data: { items: [] } });

export const grantHoldersReducer = (state = grantHoldersInitialState, action) => {
  switch (action.type) {
    case constants.GET_GRANT_HOLDERS:
      return grantHoldersInitialState;
    case constants.GET_GRANT_HOLDERS_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_GRANT_HOLDERS_ERROR:
      return { loading: false, data: { items: [] }, error: action.error };
    default:
      return state;
  }
};

const grantHoldersCapitalInitialState = getReducerState({ data: { items: [] } });

export const grantHoldersCapitalReducer = (state = grantHoldersCapitalInitialState, action) => {
  switch (action.type) {
    case constants.GET_GRANT_HOLDERS_CAPITAL:
      return grantHoldersCapitalInitialState;
    case constants.GET_GRANT_HOLDERS_CAPITAL_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_GRANT_HOLDERS_CAPITAL_ERROR:
      return { loading: false, data: { items: [] }, error: action.error };
    default:
      return state;
  }
};

const holdersInitialState = getReducerState({
  data: { items: [] },
  viewGrant: {},
});

export const holdersReducer = (state = holdersInitialState, action) => {
  switch (action.type) {
    case constants.GET_HOLDERS:
    case constants.RESET_HOLDERS:
      return holdersInitialState;
    case constants.GET_HOLDERS_SUCCESS:
      return {
        loading: false,
        viewGrant: {},
        data: action.data,
        error: null,
      };
    case constants.VIEW_GRANT:
      return {
        ...state,
        viewGrant: {
          grantId: action.grantId,
          grantHolder: action.grantHolder,
          openedTab: 'documents',
        },
      };
    case constants.GET_HOLDERS_ERROR:
      return {
        loading: false,
        viewGrant: {},
        data: { items: [] },
        error: action.error,
      };
    default:
      return state;
  }
};

const initialShareOptionPlanState = getReducerState({
  data: { shareClasses: [] },
  loading: false,
});

export const shareOptionPlanReducer = (state = initialShareOptionPlanState, action) => {
  switch (action.type) {
    case constants.GET_SHARE_OPTION_PLAN:
      return { ...initialShareOptionPlanState, loading: true };
    case constants.GET_SHARE_OPTION_PLAN_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_SHARE_OPTION_PLAN_ERROR:
      return { loading: false, data: {}, error: action.error };
    case constants.CLEAR_SHARE_OPTION_PLAN:
      return getReducerState({ data: {}, loading: false });
    default:
      return state;
  }
};

export const initialShareOptionDataState = { loading: false, data: {}, error: null };

export const shareOptionDataReducer = (state = initialShareOptionDataState, action) => {
  switch (action.type) {
    case constants.GET_SHARE_OPTION_DATA:
      return { ...initialShareOptionDataState, loading: true };
    case constants.GET_SHARE_OPTION_DATA_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_SHARE_OPTION_DATA_ERROR:
      return { loading: false, data: {}, error: action.error };
    case constants.RESET_SHARE_OPTION_DATA:
      return initialShareOptionDataState;
    default:
      return state;
  }
};

export const initialExerciseShareOptionsState = { loading: false, data: null, error: null };

export const exerciseShareOptionsReducer = (state = initialExerciseShareOptionsState, action) => {
  switch (action.type) {
    case constants.EXERCISE_SHARE_OPTIONS:
      return { ...initialExerciseShareOptionsState, loading: true };
    case constants.EXERCISE_SHARE_OPTIONS_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.EXERCISE_SHARE_OPTIONS_ERROR:
      return { loading: false, data: null, error: action.error };
    case constants.RESET_EXERCISE_SHARE_OPTIONS:
      return initialExerciseShareOptionsState;
    default:
      return state;
  }
};

export const initialShareOptionPlanDocumentsState = {
  loading: false,
  data: [],
  error: null,
};

export const shareOptionPlanDocumentsReducer = (
  state = initialShareOptionPlanDocumentsState,
  action,
) => {
  switch (action.type) {
    case constants.GET_SHARE_OPTION_PLAN_SUCCESS:
      return {
        ...initialShareOptionPlanDocumentsState,
        data: action.data.shareOptionPlanDocuments,
      };
    case constants.ADD_SHARE_OPTION_PLAN_DOCUMENT:
    case constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT:
      return { loading: true, error: null, data: state.data };
    case constants.ADD_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS:
      return {
        loading: false,
        error: null,
        data: [...state.data, action.document],
      };
    case constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS:
      return {
        loading: false,
        error: null,
        data: state.data.filter(({ id }) => id !== action.documentId),
      };
    case constants.ADD_SHARE_OPTION_PLAN_DOCUMENT_ERROR:
    case constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT_ERROR:
      return { loading: false, error: action.error, data: state.data };
    default:
      return state;
  }
};

const initialNewGrantAddingState = getReducerState({ loading: false });

export const newGrantAddingStateReducer = (
  state = initialNewGrantAddingState,
  { type, data, error },
) => {
  switch (type) {
    case ADD_NEW_GRANT:
      return getReducerState({ loading: true });
    case ADD_NEW_GRANT_SUCCESS:
      return getReducerState({ loading: false, data });
    case ADD_NEW_GRANT_ERROR:
      return getReducerState({ loading: false, error });
    case RESET_NEW_GRANT_ADDING_STATE:
      return initialNewGrantAddingState;
    default:
      return state;
  }
};

const initialGrantState = getReducerState({
  data: {
    shareClass: {},
    vestingSchedulesLineItems: [],
  },
  isDocumentLoading: false,
});

export const grantReducer = (state = initialGrantState, action) => {
  switch (action.type) {
    case constants.GET_GRANT:
      return initialGrantState;
    case constants.GET_GRANT_SUCCESS:
      return { loading: false, isDocumentLoading: false, data: action.data, error: null };
    case constants.GET_GRANT_ERROR:
      return {
        loading: false,
        isDocumentLoading: false,
        data: { shareClass: {}, vestingSchedulesLineItems: [] },
        error: action.error,
      };
    case constants.ADD_GRANT_DOCUMENT:
      return {
        ...state,
        isDocumentLoading: true,
      };
    case constants.ADD_GRANT_DOCUMENT_SUCCESS: {
      const existingGrantDocuments = state.data.grantDocuments ? state.data.grantDocuments : [];
      return {
        ...state,
        data: {
          ...state.data,
          grantDocuments: [...existingGrantDocuments, action.data],
        },
        isDocumentLoading: false,
      };
    }
    case constants.ADD_GRANT_DOCUMENT_ERROR:
      return {
        ...state,
        isDocumentLoading: false,
      };
    case constants.DELETE_GRANT_DOCUMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          grantDocuments: state.data.grantDocuments.filter(d => d.id !== action.data.documentId),
        },
        isDocumentLoading: false,
      };
    default:
      return state;
  }
};

const initialGrantTransactionalHistoryState = { loading: true, data: [], error: null };

export const grantTransactionalHistoryReducer = (state = initialGrantState, action) => {
  switch (action.type) {
    case constants.GET_GRANT_TRANSACTIONAL_HISTORY:
      return initialGrantTransactionalHistoryState;
    case constants.GET_GRANT_TRANSACTIONAL_HISTORY_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_GRANT_TRANSACTIONAL_HISTORY_ERROR:
      return { loading: false, data: state.data, error: action.error };
    default:
      return state;
  }
};

const grantHoldersEmailsInitialState = { loading: false, data: [], error: null };

export const grantHoldersEmailsReducer = (
  state = grantHoldersEmailsInitialState,
  { type, payload },
) => {
  switch (type) {
    case constants.GET_GRANT_HOLDERS_EMAILS:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_GRANT_HOLDERS_EMAILS_SUCCESS:
      return { loading: false, data: payload.data.items, error: null };
    case constants.GET_GRANT_HOLDERS_EMAILS_ERROR:
      return { ...state, loading: false, error: payload.error };
    case constants.RESET_GRANT_HOLDERS_EMAILS:
      return grantHoldersEmailsInitialState;
    default:
      return state;
  }
};

const searchHoldersInitialState = { loading: false, data: [], error: null };

export const searchHoldersReducer = (state = searchHoldersInitialState, { type, payload }) => {
  switch (type) {
    case constants.SEARCH_HOLDERS:
      return { loading: true, data: [], error: null };
    case constants.SEARCH_HOLDERS_SUCCESS:
      return { loading: false, data: payload.items, error: null };
    case constants.SEARCH_HOLDERS_ERROR:
      return { loading: false, data: [], error: payload };
    case constants.RESET_HOLDERS_SEARCH:
      return searchHoldersInitialState;
    default:
      return state;
  }
};

const grantHolderDetailsInitialState = { loading: true, data: {}, error: null };

export const grantHolderDetailsReducer = (
  state = grantHolderDetailsInitialState,
  { type, payload },
) => {
  switch (type) {
    case constants.GET_GRANT_HOLDER_DETAILS:
      return { ...state, loading: true };
    case constants.GET_GRANT_HOLDER_DETAILS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case constants.GET_GRANT_HOLDER_DETAILS_ERROR:
      return { ...state, loading: false, error: payload };
    case constants.RESET_GRANT_HOLDER_DETAILS:
      return grantHolderDetailsInitialState;
    default:
      return state;
  }
};
