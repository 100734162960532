import { useDispatch } from 'react-redux';

import {
  changeDocumentAgreedStatus,
  deleteInvestorDocument,
  fetchInvestorDocuments,
  resetInvestorDocuments,
  uploadInvestorDocument,
} from 'app-state/actions/deal';
import {
  getInvestorDocumentsErrors,
  getInvestorDocumentsItems,
  getInvestorDocumentsLoading,
  getInvestorDocumentsPagination,
  getInvestorDocumentsUploading,
} from 'app-state/selectors/deal';

import useTypedSelector from 'hooks/use-typed-selector';
import DocumentsTable from 'shared/documents-table';
import { uploadFile } from 'shared-parts/helpers/file-management';

const Documents = ({ type, uuid: dealUuid, investor: { email, uuid } }) => {
  const dispatch = useDispatch();
  const documentsItems = useTypedSelector(getInvestorDocumentsItems);
  const isUploading = useTypedSelector(getInvestorDocumentsUploading);
  const documentsLoading = useTypedSelector(getInvestorDocumentsLoading);
  const documentsErrors = useTypedSelector(getInvestorDocumentsErrors);
  const pagination = useTypedSelector(getInvestorDocumentsPagination);

  const uploadDocument = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = e => {
      uploadFile({
        onLoad: params => dispatch(uploadInvestorDocument(params)),
        formDataParams: {
          type,
        },
        validate: false,
        params: {
          dealUuid,
          investorUuid: uuid,
          type,
          email,
        },
      })(e);
    };
    fileInput.click();
    fileInput.remove();
  };

  const getItemsAction = payload => {
    dispatch(
      fetchInvestorDocuments({
        ...payload,
        type,
        uuid,
        email,
        dealUuid,
      }),
    );
  };

  const deleteDocument = ({ id }) => {
    dispatch(
      deleteInvestorDocument({
        type,
        email,
        dealUuid,
        documentId: id,
        investorUuid: uuid,
      }),
    );
  };

  const changeAgreedStatus = (documentId, isAgree) => {
    dispatch(
      changeDocumentAgreedStatus({
        type,
        uuid,
        email,
        dealUuid,
        isAgree,
        documentId,
      }),
    );
  };

  const resetItemsAction = () => {
    dispatch(resetInvestorDocuments());
  };

  return (
    <DocumentsTable
      type={type}
      itemUuid={uuid}
      data={documentsItems}
      error={documentsErrors}
      pagination={pagination}
      isUploading={isUploading}
      loading={documentsLoading}
      deleteDocument={deleteDocument}
      uploadDocument={uploadDocument}
      getItemsAction={getItemsAction}
      resetItemsAction={resetItemsAction}
      changeAgreedStatus={changeAgreedStatus}
    />
  );
};

export default Documents;
