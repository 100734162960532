export const FETCH_DEAL_SUMMARY_DATA = 'FETCH_DEAL_SUMMARY_DATA';
export const FETCH_DEAL_SUMMARY_DATA_SUCCESS = 'FETCH_DEAL_SUMMARY_DATA_SUCCESS';
export const FETCH_DEAL_SUMMARY_DATA_ERROR = 'FETCH_DEAL_SUMMARY_DATA_ERROR';
export const RESET_DEAL_SUMMARY_DATA = 'RESET_DEAL_SUMMARY_DATA';

export const FETCH_DEAL_DATA = 'FETCH_DEAL_DATA';
export const FETCH_DEAL_DATA_SUCCESS = 'FETCH_DEAL_DATA_SUCCESS';
export const FETCH_DEAL_DATA_ERROR = 'FETCH_DEAL_DATA_ERROR';
export const UPDATE_DEAL_BANK_ACCOUNTS = 'UPDATE_DEAL_BANK_ACCOUNTS';
export const RESET_DEAL_DATA = 'RESET_DEAL_DATA';

export const FETCH_DEAL_DOCUMENTS_DATA = 'FETCH_DEAL_DOCUMENTS_DATA';
export const FETCH_DEAL_DOCUMENTS_DATA_SUCCESS = 'FETCH_DEAL_DOCUMENTS_DATA_SUCCESS';
export const FETCH_DEAL_DOCUMENTS_DATA_ERROR = 'FETCH_DEAL_DOCUMENTS_DATA_ERROR';
export const RESET_DEAL_DOCUMENTS_DATA = 'RESET_DEAL_DOCUMENTS_DATA';

export const CREATE_DEAL_DETAILS = 'CREATE_DEAL_DETAILS';
export const CREATE_DEAL_DETAILS_SUCCESS = 'CREATE_DEAL_DETAILS_SUCCESS';
export const CREATE_DEAL_DETAILS_ERROR = 'CREATE_DEAL_DETAILS_ERROR';

export const EDIT_DEAL_DETAILS = 'EDIT_DEAL_DETAILS';
export const EDIT_DEAL_DETAILS_SUCCESS = 'EDIT_DEAL_DETAILS_SUCCESS';
export const EDIT_DEAL_DETAILS_ERROR = 'EDIT_DEAL_DETAILS_ERROR';

export const EDIT_DEAL_STRUCTURE = 'EDIT_DEAL_STRUCTURE';

export const EDIT_DEAL_CONFIGURATION = 'EDIT_DEAL_CONFIGURATION';

export const UPLOAD_DEAL_DOCUMENT = 'UPLOAD_DEAL_DOCUMENT';

export const FETCH_DEAL_INFO = 'FETCH_DEAL_INFO';
export const FETCH_DEAL_INFO_SUCCESS = 'FETCH_DEAL_INFO_SUCCESS';
export const FETCH_DEAL_INFO_ERROR = 'FETCH_DEAL_INFO_ERROR';
export const RESET_DEAL_INFO = 'RESET_DEAL_INFO';

export const FETCH_INVESTOR_DOCUMENTS = 'FETCH_INVESTOR_DOCUMENTS';
export const FETCH_INVESTOR_DOCUMENTS_SUCCESS = 'FETCH_INVESTOR_DOCUMENTS_SUCCESS';
export const FETCH_INVESTOR_DOCUMENTS_ERROR = 'FETCH_INVESTOR_DOCUMENTS_ERROR';
export const RESET_INVESTOR_DOCUMENTS = 'RESET_INVESTOR_DOCUMENTS';

export const DELETE_INVESTOR_DOCUMENT = 'DELETE_INVESTOR_DOCUMENT';
export const UPLOAD_INVESTOR_DOCUMENT = 'UPLOAD_INVESTOR_DOCUMENT';
export const UPLOAD_INVESTOR_DOCUMENT_FINISHED = 'UPLOAD_INVESTOR_DOCUMENT_FINISHED';

export const CHANGE_DOCUMENT_AGREED_STATUS = 'CHANGE_DOCUMENT_AGREED_STATUS';
export const CHANGE_DOCUMENT_AGREED_STATUS_SUCCESS = 'CHANGE_DOCUMENT_AGREED_STATUS_SUCCESS';

export const FETCH_DEAL_LIST = 'FETCH_DEAL_LIST';
export const FETCH_DEAL_LIST_SUCCESS = 'FETCH_DEAL_LIST_SUCCESS';
export const FETCH_DEAL_LIST_ERROR = 'FETCH_DEAL_LIST_ERROR';
export const RESET_DEAL_LIST = 'RESET_DEAL_LIST';

export const FETCH_INVESTOR_INFO = 'FETCH_INVESTOR_INFO';
export const FETCH_INVESTOR_INFO_SUCCESS = 'FETCH_INVESTOR_INFO_SUCCESS';
export const FETCH_INVESTOR_INFO_ERROR = 'FETCH_INVESTOR_INFO_ERROR';
export const RESET_INVESTOR_INFO = 'RESET_INVESTOR_INFO';

export const RESEND_INVESTOR_INVITE = 'RESEND_INVESTOR_INVITE';
export const RESEND_INVESTOR_INVITE_SUCCESS = 'RESEND_INVESTOR_INVITE_SUCCESS';
export const RESEND_INVESTOR_INVITE_ERROR = 'RESEND_INVESTOR_INVITE_ERROR';

export const RESEND_IP_INVESTOR_INVITE = 'RESEND_IP_INVESTOR_INVITE';

export const FETCH_DEAL_PAGES_DATA = 'FETCH_DEAL_PAGES_DATA';
export const FETCH_DEAL_PAGES_DATA_SUCCESS = 'FETCH_DEAL_PAGES_DATA_SUCCESS';
export const FETCH_DEAL_PAGES_DATA_ERROR = 'FETCH_DEAL_PAGES_DATA_ERROR';

export const UPDATE_DEAL_PAGE_HEADER = 'UPDATE_DEAL_PAGE_HEADER';
export const UPDATE_DEAL_PAGE_HEADER_SUCCESS = 'UPDATE_DEAL_PAGE_HEADER_SUCCESS';
export const UPDATE_DEAL_PAGE_HEADER_ERROR = 'UPDATE_DEAL_PAGE_HEADER_ERROR';

export const UPDATE_DEAL_DATA = 'UPDATE_DEAL_DATA';
export const UPDATE_DEAL_DATA_SUCCESS = 'UPDATE_DEAL_DATA_SUCCESS';
export const UPDATE_DEAL_DATA_ERROR = 'UPDATE_DEAL_DATA_ERROR';

export const FETCH_DEAL = 'FETCH_DEAL';
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS';
export const FETCH_DEAL_ERROR = 'FETCH_DEAL_ERROR';
export const RESET_DEAL = 'RESET_DEAL';

export const FETCH_PAGE = 'FETCH_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_ERROR = 'FETCH_PAGE_ERROR';
export const FETCH_PAGE_RESTRICTED = 'FETCH_PAGE_RESTRICTED';
export const RESET_PAGE = 'RESET_PAGE';

export const FETCH_ISSUANCE = 'FETCH_ISSUANCE';
export const FETCH_ISSUANCE_SUCCESS = 'FETCH_ISSUANCE_SUCCESS';
export const FETCH_ISSUANCE_ERROR = 'FETCH_ISSUANCE_ERROR';

export const CREATE_SECTION = 'CREATE_SECTION';
export const CREATE_SECTION_ERROR = 'CREATE_SECTION_ERROR';
export const EDIT_SECTION_TITLE = 'EDIT_SECTION_TITLE';
export const EDIT_SECTION_TITLE_ERROR = 'EDIT_SECTION_TITLE_ERROR';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_SECTION_ERROR = 'DELETE_SECTION_ERROR';
export const REORDER_SECTIONS = 'REORDER_SECTIONS';
export const REORDER_SECTIONS_ERROR = 'REORDER_SECTIONS_ERROR';

export const CREATE_TEXT_COMPONENT = 'CREATE_TEXT_COMPONENT';
export const CREATE_TEXT_COMPONENT_ERROR = 'CREATE_TEXT_COMPONENT_ERROR';
export const EDIT_TEXT_COMPONENT = 'EDIT_TEXT_COMPONENT';
export const EDIT_TEXT_COMPONENT_ERROR = 'EDIT_TEXT_COMPONENT_ERROR';

export const CREATE_TABLE_COMPONENT = 'CREATE_TABLE_COMPONENT';
export const CREATE_TABLE_COMPONENT_ERROR = 'CREATE_TABLE_COMPONENT_ERROR';
export const EDIT_TABLE_COMPONENT = 'EDIT_TABLE_COMPONENT';
export const EDIT_TABLE_COMPONENT_ERROR = 'EDIT_TABLE_COMPONENT_ERROR';

export const CREATE_DESCRIPTION_LIST_COMPONENT = 'CREATE_DESCRIPTION_LIST_COMPONENT';
export const CREATE_DESCRIPTION_LIST_COMPONENT_ERROR = 'CREATE_DESCRIPTION_LIST_COMPONENT_ERROR';
export const EDIT_DESCRIPTION_LIST_COMPONENT = 'EDIT_DESCRIPTION_LIST_COMPONENT';
export const EDIT_DESCRIPTION_LIST_COMPONENT_ERROR = 'EDIT_DESCRIPTION_LIST_COMPONENT_ERROR';

export const CREATE_DOCUMENT_COMPONENT = 'CREATE_DOCUMENT_COMPONENT';
export const CREATE_DOCUMENT_COMPONENT_ERROR = 'CREATE_DOCUMENT_COMPONENT_ERROR';
export const EDIT_DOCUMENT_COMPONENT = 'EDIT_DOCUMENT_COMPONENT';
export const EDIT_DOCUMENT_COMPONENT_ERROR = 'EDIT_DOCUMENT_COMPONENT_ERROR';

export const EDIT_IMAGE_COMPONENT = 'EDIT_IMAGE_COMPONENT';
export const EDIT_IMAGE_COMPONENT_SUCCESS = 'EDIT_IMAGE_COMPONENT_SUCCESS';
export const EDIT_IMAGE_COMPONENT_ERROR = 'EDIT_IMAGE_COMPONENT_ERROR';

export const CREATE_IMAGE_COMPONENT = 'CREATE_IMAGE_COMPONENT';
export const CREATE_IMAGE_COMPONENT_SUCCESS = 'CREATE_IMAGE_COMPONENT_SUCCESS';
export const CREATE_IMAGE_COMPONENT_ERROR = 'CREATE_IMAGE_COMPONENT_ERROR';

export const EDIT_VIDEO_COMPONENT = 'EDIT_VIDEO_COMPONENT';
export const EDIT_VIDEO_COMPONENT_SUCCESS = 'EDIT_VIDEO_COMPONENT_SUCCESS';
export const EDIT_VIDEO_COMPONENT_ERROR = 'EDIT_VIDEO_COMPONENT_ERROR';

export const CREATE_VIDEO_COMPONENT = 'CREATE_VIDEO_COMPONENT';
export const CREATE_VIDEO_COMPONENT_SUCCESS = 'CREATE_VIDEO_COMPONENT_SUCCESS';
export const CREATE_VIDEO_COMPONENT_ERROR = 'CREATE_VIDEO_COMPONENT_ERROR';

export const CREATE_TEAM_MEMBER = 'CREATE_TEAM_MEMBER';
export const CREATE_TEAM_MEMBER_SUCCESS = 'CREATE_TEAM_MEMBER_SUCCESS';
export const CREATE_TEAM_MEMBER_ERROR = 'CREATE_TEAM_MEMBER_ERROR';

export const CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION = 'CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION';
export const CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION_SUCCESS =
  'CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION_SUCCESS';
export const CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION_ERROR =
  'CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION_ERROR';

export const EDIT_TEAM_MEMBER = 'EDIT_TEAM_MEMBER';
export const EDIT_TEAM_MEMBER_SUCCESS = 'EDIT_TEAM_MEMBER_SUCCESS';
export const EDIT_TEAM_MEMBER_ERROR = 'EDIT_TEAM_MEMBER_ERROR';

export const DETACH_TEAM_MEMBER = 'DETACH_TEAM_MEMBER';
export const DETACH_TEAM_MEMBER_SUCCESS = 'DETACH_TEAM_MEMBER_SUCCESS';
export const DETACH_TEAM_MEMBER_ERROR = 'DETACH_TEAM_MEMBER_ERROR';

export const ATTACH_TEAM_MEMBER = 'ATTACH_TEAM_MEMBER';
export const ATTACH_TEAM_MEMBER_SUCCESS = 'ATTACH_TEAM_MEMBER_SUCCESS';
export const ATTACH_TEAM_MEMBER_ERROR = 'ATTACH_TEAM_MEMBER_ERROR';

export const REORDER_TEAM_MEMBER = 'REORDER_TEAM_MEMBER';
export const REORDER_TEAM_MEMBER_SUCCESS = 'REORDER_TEAM_MEMBER_SUCCESS';
export const REORDER_TEAM_MEMBER_ERROR = 'REORDER_TEAM_MEMBER_ERROR';

export const FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS';
export const FETCH_TEAM_MEMBERS_SUCCESS = 'FETCH_TEAM_MEMBERS_SUCCESS';
export const FETCH_TEAM_MEMBERS_ERROR = 'FETCH_TEAM_MEMBERS_ERROR';
export const RESET_TEAM_MEMBERS = 'RESET_TEAM_MEMBERS';

export const CREATE_TEAM_COMPONENT = 'CREATE_TEAM_COMPONENT';
export const CREATE_TEAM_COMPONENT_SUCCESS = 'CREATE_TEAM_COMPONENT_SUCCESS';
export const CREATE_TEAM_COMPONENT_ERROR = 'CREATE_TEAM_COMPONENT_ERROR';

export const EDIT_TEAM_COMPONENT = 'EDIT_TEAM_COMPONENT';
export const EDIT_TEAM_COMPONENT_SUCCESS = 'EDIT_TEAM_COMPONENT_SUCCESS';
export const EDIT_TEAM_COMPONENT_ERROR = 'EDIT_TEAM_COMPONENT_ERROR';

export const CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION = 'CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION';
export const CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION_SUCCESS =
  'CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION_SUCCESS';
export const CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION_ERROR =
  'CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION_ERROR';

export const ADD_COMPONENT_START = 'ADD_COMPONENT_START';
export const ADD_COMPONENT_END = 'ADD_COMPONENT_END';
export const ADD_COMPONENT_ERROR = 'ADD_COMPONENT_ERROR';
export const ADD_COMPONENT_HIDE_ERROR = 'ADD_COMPONENT_HIDE_ERROR';

export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS';
export const DELETE_COMPONENT_ERROR = 'DELETE_COMPONENT_ERROR';

export const REORDER_COMPONENT = 'REORDER_COMPONENT';
export const REORDER_COMPONENT_ERROR = 'REORDER_COMPONENT_ERROR';

export const CREATE_ISSUANCE = 'CREATE_ISSUANCE';
export const CREATE_ISSUANCE_ERROR = 'CREATE_ISSUANCE_ERROR';

export const GENERATE_BASIC_NAVIGATION = 'GENERATE_BASIC_NAVIGATION';
export const GENERATE_BASIC_NAVIGATION_ERROR = 'GENERATE_BASIC_NAVIGATION_ERROR';

export const SET_LAST_CREATED_COMPONENT = 'SET_LAST_CREATED_COMPONENT';
export const RESET_LAST_CREATED_COMPONENT = 'RESET_LAST_CREATED_COMPONENT';

export const CREATE_DEAL_INVITES = 'CREATE_DEAL_INVITES';
export const CREATE_DEAL_INVITES_SUCCESS = 'CREATE_DEAL_INVITES_SUCCESS';
export const CREATE_DEAL_INVITES_ERROR = 'CREATE_DEAL_INVITES_ERROR';

export const FETCH_DEAL_INVITE_EMAIL_TEMPLATE = 'FETCH_DEAL_INVITE_EMAIL_TEMPLATE';
export const FETCH_DEAL_INVITE_EMAIL_TEMPLATE_SUCCESS = 'FETCH_DEAL_INVITE_EMAIL_TEMPLATE_SUCCESS';
export const FETCH_DEAL_INVITE_EMAIL_TEMPLATE_ERROR = 'FETCH_DEAL_INVITE_EMAIL_TEMPLATE_ERROR';
export const RESET_DEAL_INVITE_EMAIL_TEMPLATE = 'RESET_DEAL_INVITE_EMAIL_TEMPLATE';

export const REQUEST_DEAL_PUBLISH = 'REQUEST_DEAL_PUBLISH';
export const REQUEST_DEAL_PUBLISH_SUCCESS = 'REQUEST_DEAL_PUBLISH_SUCCESS';
export const REQUEST_DEAL_PUBLISH_ERROR = 'REQUEST_DEAL_PUBLISH_ERROR';

export const PUBLISH_DEAL = 'PUBLISH_DEAL';
export const PUBLISH_DEAL_SUCCESS = 'PUBLISH_DEAL_SUCCESS';
export const PUBLISH_DEAL_ERROR = 'PUBLISH_DEAL_ERROR';

export const UNPUBLISH_DEAL = 'UNPUBLISH_DEAL';
export const UNPUBLISH_DEAL_SUCCESS = 'UNPUBLISH_DEAL_SUCCESS';
export const UNPUBLISH_DEAL_ERROR = 'UNPUBLISH_DEAL_ERROR';

export const FETCH_DEAL_ORDERS = 'FETCH_DEAL_ORDERS';
export const FETCH_DEAL_ORDERS_SUCCESS = 'FETCH_DEAL_ORDERS_SUCCESS';
export const FETCH_DEAL_ORDERS_ERROR = 'FETCH_DEAL_ORDERS_ERROR';
export const RESET_DEAL_ORDERS = 'RESET_DEAL_ORDERS';

export const FETCH_DEAL_ORDER_DETAILS = 'FETCH_DEAL_ORDER_DETAILS';
export const FETCH_DEAL_ORDER_DETAILS_SUCCESS = 'FETCH_DEAL_ORDER_DETAILS_SUCCESS';
export const FETCH_DEAL_ORDER_DETAILS_ERROR = 'FETCH_DEAL_ORDER_DETAILS_ERROR';
export const RESET_DEAL_ORDER_DETAILS = 'RESET_DEAL_ORDER_DETAILS';

export const FETCH_DEAL_ORDER_HISTORY = 'FETCH_DEAL_ORDER_HISTORY';
export const FETCH_DEAL_ORDER_HISTORY_SUCCESS = 'FETCH_DEAL_ORDER_HISTORY_SUCCESS';
export const FETCH_DEAL_ORDER_HISTORY_ERROR = 'FETCH_DEAL_ORDER_HISTORY_ERROR';
export const RESET_DEAL_ORDER_HISTORY = 'RESET_DEAL_ORDER_HISTORY';

export const APPROVE_DEAL_ORDER = 'APPROVE_DEAL_ORDER';
export const APPROVE_DEAL_ORDER_SUCCESS = 'APPROVE_DEAL_ORDER_SUCCESS';
export const APPROVE_DEAL_ORDER_ERROR = 'APPROVE_DEAL_ORDER_ERROR';

export const MARK_AS_FUNDS_RECEIVED = 'MARK_AS_FUNDS_RECEIVED';
export const MARK_AS_FUNDS_RECEIVED_SUCCESS = 'MARK_AS_FUNDS_RECEIVED_SUCCESS';
export const MARK_AS_FUNDS_RECEIVED_ERROR = 'MARK_AS_FUNDS_RECEIVED_ERROR';

export const CANCEL_DEAL_ORDER = 'CANCEL_DEAL_ORDER';
export const CANCEL_DEAL_ORDER_SUCCESS = 'CANCEL_DEAL_ORDER_SUCCESS';
export const CANCEL_DEAL_ORDER_ERROR = 'CANCEL_DEAL_ORDER_ERROR';

export const COMPLETE_DEAL = 'COMPLETE_DEAL';
export const COMPLETE_DEAL_SUCCESS = 'COMPLETE_DEAL_SUCCESS';
export const COMPLETE_DEAL_ERROR = 'COMPLETE_DEAL_ERROR';

export const FETCH_DEAL_SETTLEMENTS = 'FETCH_DEAL_SETTLEMENTS';
export const FETCH_DEAL_SETTLEMENTS_SUCCESS = 'FETCH_DEAL_SETTLEMENTS_SUCCESS';
export const FETCH_DEAL_SETTLEMENTS_ERROR = 'FETCH_DEAL_SETTLEMENTS_ERROR';
export const RESET_DEAL_SETTLEMENTS = 'RESET_DEAL_SETTLEMENTS';

export const EDIT_ORDER_MONEY_TRANSFERRED = 'EDIT_ORDER_MONEY_TRANSFERRED';
export const EDIT_ORDER_MONEY_TRANSFERRED_SUCCESS = 'EDIT_ORDER_MONEY_TRANSFERRED_SUCCESS';
export const EDIT_ORDER_MONEY_TRANSFERRED_ERROR = 'EDIT_ORDER_MONEY_TRANSFERRED_ERROR';
