import * as constants from 'app-state/constants';

export const selectPackage = (companyUuid, packageId) => ({
  type: constants.SELECT_PACKAGE,
  companyUuid,
  packageId,
});
export const selectPackageError = error => ({ type: constants.SELECT_PACKAGE_ERROR, error });

export const getSubscriptionTemplates = () => ({ type: constants.GET_SUBSCRIPTION_TEMPLATES });
export const getSubscriptionTemplatesSuccess = data => ({
  type: constants.GET_SUBSCRIPTION_TEMPLATES_SUCCESS,
  data,
});
export const getSubscriptionTemplatesError = error => ({
  type: constants.GET_SUBSCRIPTION_TEMPLATES_ERROR,
  error,
});

export const getSubscriptionTemplatesPerCompany = companyUuid => ({
  type: constants.GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY,
  companyUuid,
});
export const getSubscriptionTemplatesPerCompanySuccess = data => ({
  type: constants.GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_SUCCESS,
  data,
});
export const getSubscriptionTemplatesPerCompanyError = error => ({
  type: constants.GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_ERROR,
  error,
});
