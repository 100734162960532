export interface Country {
  alpha3Code: string;
  countryCode: string;
  countryName: string;
  region: string;
}

const countriesList = [
  {
    alpha3Code: 'TJK',
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    region: 'Asia',
  },
  {
    alpha3Code: 'JAM',
    countryCode: 'JM',
    countryName: 'Jamaica',
    region: 'Americas',
  },
  {
    alpha3Code: 'HTI',
    countryCode: 'HT',
    countryName: 'Haiti',
    region: 'Americas',
  },
  {
    alpha3Code: 'STP',
    countryCode: 'ST',
    countryName: 'Sao Tome and Principe',
    region: 'Africa',
  },
  {
    alpha3Code: 'MSR',
    countryCode: 'MS',
    countryName: 'Montserrat',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'ARE',
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    region: 'Asia',
  },
  {
    alpha3Code: 'PAK',
    countryCode: 'PK',
    countryName: 'Pakistan',
    region: 'Asia',
  },
  {
    alpha3Code: 'NLD',
    countryCode: 'NL',
    countryName: 'Netherlands',
    region: 'Europe',
  },
  {
    alpha3Code: 'LUX',
    countryCode: 'LU',
    countryName: 'Luxembourg',
    region: 'Europe',
  },
  {
    alpha3Code: 'BLZ',
    countryCode: 'BZ',
    countryName: 'Belize',
    region: 'Americas',
  },
  {
    alpha3Code: 'IRN',
    countryCode: 'IR',
    countryName: 'Iran (Islamic Republic of)',
    region: 'Asia',
  },
  {
    alpha3Code: 'BOL',
    countryCode: 'BO',
    countryName: 'Bolivia (Plurinational State of)',
    region: 'Americas',
  },
  {
    alpha3Code: 'URY',
    countryCode: 'UY',
    countryName: 'Uruguay',
    region: 'Americas',
  },
  {
    alpha3Code: 'GHA',
    countryCode: 'GH',
    countryName: 'Ghana',
    region: 'Africa',
  },
  {
    alpha3Code: 'SAU',
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    region: 'Asia',
  },
  {
    alpha3Code: 'CIV',
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    region: 'Africa',
  },
  {
    alpha3Code: 'MAF',
    countryCode: 'MF',
    countryName: 'Saint Martin (French part)',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'ATF',
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    region: 'Antarctica',
  },
  {
    alpha3Code: 'AIA',
    countryCode: 'AI',
    countryName: 'Anguilla',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'QAT',
    countryCode: 'QA',
    countryName: 'Qatar',
    region: 'Asia',
  },
  {
    alpha3Code: 'SXM',
    countryCode: 'SX',
    countryName: 'Sint Maarten (Dutch part)',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'LBY',
    countryCode: 'LY',
    countryName: 'Libya',
    region: 'Africa',
  },
  {
    alpha3Code: 'BVT',
    countryCode: 'BV',
    countryName: 'Bouvet Island',
    region: 'Antarctica',
  },
  {
    alpha3Code: 'PNG',
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    region: 'Oceania',
  },
  {
    alpha3Code: 'KGZ',
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    region: 'Asia',
  },
  {
    alpha3Code: 'GNQ',
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    region: 'Africa',
  },
  {
    alpha3Code: 'ESH',
    countryCode: 'EH',
    countryName: 'Western Sahara',
    region: 'Africa',
  },
  {
    alpha3Code: 'NIU',
    countryCode: 'NU',
    countryName: 'Niue',
    region: 'Oceania',
  },
  {
    alpha3Code: 'PRI',
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    region: 'Americas',
  },
  {
    alpha3Code: 'GRD',
    countryCode: 'GD',
    countryName: 'Grenada',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'KOR',
    countryCode: 'KR',
    countryName: 'Korea (Republic of)',
    region: 'Asia',
  },
  {
    alpha3Code: 'HMD',
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    region: 'Antarctica',
  },
  {
    alpha3Code: 'SMR',
    countryCode: 'SM',
    countryName: 'San Marino',
    region: 'Europe',
  },
  {
    alpha3Code: 'SLE',
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    region: 'Africa',
  },
  {
    alpha3Code: 'COD',
    countryCode: 'CD',
    countryName: 'Congo (Democratic Republic of the)',
    region: 'Africa',
  },
  {
    alpha3Code: 'MKD',
    countryCode: 'MK',
    countryName: 'Macedonia (the former Yugoslav Republic of)',
    region: 'Europe',
  },
  {
    alpha3Code: 'TUR',
    countryCode: 'TR',
    countryName: 'Turkey',
    region: 'Asia',
  },
  {
    alpha3Code: 'DZA',
    countryCode: 'DZ',
    countryName: 'Algeria',
    region: 'Africa',
  },
  {
    alpha3Code: 'GEO',
    countryCode: 'GE',
    countryName: 'Georgia',
    region: 'Asia',
  },
  {
    alpha3Code: 'PSE',
    countryCode: 'PS',
    countryName: 'Palestine, State of',
    region: 'Asia',
  },
  {
    alpha3Code: 'BRB',
    countryCode: 'BB',
    countryName: 'Barbados',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'UKR',
    countryCode: 'UA',
    countryName: 'Ukraine',
    region: 'Europe',
  },
  {
    alpha3Code: 'GLP',
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'PYF',
    countryCode: 'PF',
    countryName: 'French Polynesia',
    region: 'Oceania',
  },
  {
    alpha3Code: 'NAM',
    countryCode: 'NA',
    countryName: 'Namibia',
    region: 'Africa',
  },
  {
    alpha3Code: 'BWA',
    countryCode: 'BW',
    countryName: 'Botswana',
    region: 'Africa',
  },
  {
    alpha3Code: 'SYR',
    countryCode: 'SY',
    countryName: 'Syrian Arab Republic',
    region: 'Asia',
  },
  {
    alpha3Code: 'TGO',
    countryCode: 'TG',
    countryName: 'Togo',
    region: 'Africa',
  },
  {
    alpha3Code: 'DOM',
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    region: 'Americas',
  },
  {
    alpha3Code: 'ATA',
    countryCode: 'AQ',
    countryName: 'Antarctica',
    region: 'Antarctica',
  },
  {
    alpha3Code: 'CHE',
    countryCode: 'CH',
    countryName: 'Switzerland',
    region: 'Europe',
  },
  {
    alpha3Code: 'MDG',
    countryCode: 'MG',
    countryName: 'Madagascar',
    region: 'Africa',
  },
  {
    alpha3Code: 'FRO',
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    region: 'Europe',
  },
  {
    alpha3Code: 'VGB',
    countryCode: 'VG',
    countryName: 'Virgin Islands (British)',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'GIB',
    countryCode: 'GI',
    countryName: 'Gibraltar',
    region: 'Europe',
  },
  {
    alpha3Code: 'BRN',
    countryCode: 'BN',
    countryName: 'Brunei Darussalam',
    region: 'Asia',
  },
  {
    alpha3Code: 'LAO',
    countryCode: 'LA',
    countryName: "Lao People's Democratic Republic",
    region: 'Asia',
  },
  {
    alpha3Code: 'ISL',
    countryCode: 'IS',
    countryName: 'Iceland',
    region: 'Europe',
  },
  {
    alpha3Code: 'EST',
    countryCode: 'EE',
    countryName: 'Estonia',
    region: 'Europe',
  },
  {
    alpha3Code: 'UMI',
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    region: 'Oceania',
  },
  {
    alpha3Code: 'LTU',
    countryCode: 'LT',
    countryName: 'Lithuania',
    region: 'Europe',
  },
  {
    alpha3Code: 'SRB',
    countryCode: 'RS',
    countryName: 'Serbia',
    region: 'Europe',
  },
  {
    alpha3Code: 'MRT',
    countryCode: 'MR',
    countryName: 'Mauritania',
    region: 'Africa',
  },
  {
    alpha3Code: 'AND',
    countryCode: 'AD',
    countryName: 'Andorra',
    region: 'Europe',
  },
  {
    alpha3Code: 'HUN',
    countryCode: 'HU',
    countryName: 'Hungary',
    region: 'Europe',
  },
  {
    alpha3Code: 'TKL',
    countryCode: 'TK',
    countryName: 'Tokelau',
    region: 'Oceania',
  },
  {
    alpha3Code: 'MYS',
    countryCode: 'MY',
    countryName: 'Malaysia',
    region: 'Asia',
  },
  {
    alpha3Code: 'AGO',
    countryCode: 'AO',
    countryName: 'Angola',
    region: 'Africa',
  },
  {
    alpha3Code: 'CPV',
    countryCode: 'CV',
    countryName: 'Cabo Verde',
    region: 'Africa',
  },
  {
    alpha3Code: 'NFK',
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    region: 'Oceania',
  },
  {
    alpha3Code: 'PAN',
    countryCode: 'PA',
    countryName: 'Panama',
    region: 'Americas',
  },
  {
    alpha3Code: 'GNB',
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    region: 'Africa',
  },
  {
    alpha3Code: 'BEL',
    countryCode: 'BE',
    countryName: 'Belgium',
    region: 'Europe',
  },
  {
    alpha3Code: 'PRT',
    countryCode: 'PT',
    countryName: 'Portugal',
    region: 'Europe',
  },
  {
    alpha3Code: 'GBR',
    countryCode: 'GB',
    countryName: 'United Kingdom',
    region: 'Europe',
  },
  {
    alpha3Code: 'IMN',
    countryCode: 'IM',
    countryName: 'Isle of Man',
    region: 'Europe',
  },
  {
    alpha3Code: 'USA',
    countryCode: 'US',
    countryName: 'United States of America',
    region: 'Americas',
  },
  {
    alpha3Code: 'YEM',
    countryCode: 'YE',
    countryName: 'Yemen',
    region: 'Asia',
  },
  {
    alpha3Code: 'HKG',
    countryCode: 'HK',
    countryName: 'Hong Kong',
    region: 'Asia',
  },
  {
    alpha3Code: 'AZE',
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    region: 'Asia',
  },
  {
    alpha3Code: 'CCK',
    countryCode: 'CC',
    countryName: 'Cocos (Keeling) Islands',
    region: 'Oceania',
  },
  {
    alpha3Code: 'MLI',
    countryCode: 'ML',
    countryName: 'Mali',
    region: 'Africa',
  },
  {
    alpha3Code: 'SVK',
    countryCode: 'SK',
    countryName: 'Slovakia',
    region: 'Europe',
  },
  {
    alpha3Code: 'VUT',
    countryCode: 'VU',
    countryName: 'Vanuatu',
    region: 'Oceania',
  },
  {
    alpha3Code: 'TLS',
    countryCode: 'TL',
    countryName: 'Timor-Leste',
    region: 'Asia',
  },
  {
    alpha3Code: 'HRV',
    countryCode: 'HR',
    countryName: 'Croatia',
    region: 'Europe',
  },
  {
    alpha3Code: 'SUR',
    countryCode: 'SR',
    countryName: 'Suriname',
    region: 'Americas',
  },
  {
    alpha3Code: 'MUS',
    countryCode: 'MU',
    countryName: 'Mauritius',
    region: 'Africa',
  },
  {
    alpha3Code: 'CZE',
    countryCode: 'CZ',
    countryName: 'Czechia',
    region: 'Europe',
  },
  {
    alpha3Code: 'SPM',
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    region: 'Americas',
  },
  {
    alpha3Code: 'LSO',
    countryCode: 'LS',
    countryName: 'Lesotho',
    region: 'Africa',
  },
  {
    alpha3Code: 'WSM',
    countryCode: 'WS',
    countryName: 'Samoa',
    region: 'Oceania',
  },
  {
    alpha3Code: 'COM',
    countryCode: 'KM',
    countryName: 'Comoros',
    region: 'Africa',
  },
  {
    alpha3Code: 'ITA',
    countryCode: 'IT',
    countryName: 'Italy',
    region: 'Europe',
  },
  {
    alpha3Code: 'BDI',
    countryCode: 'BI',
    countryName: 'Burundi',
    region: 'Africa',
  },
  {
    alpha3Code: 'WLF',
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    region: 'Oceania',
  },
  {
    alpha3Code: 'GIN',
    countryCode: 'GN',
    countryName: 'Guinea',
    region: 'Africa',
  },
  {
    alpha3Code: 'SGP',
    countryCode: 'SG',
    countryName: 'Singapore',
    region: 'Asia',
  },
  {
    alpha3Code: 'COL',
    countryCode: 'CO',
    countryName: 'Colombia',
    region: 'Americas',
  },
  {
    alpha3Code: 'CHN',
    countryCode: 'CN',
    countryName: 'China',
    region: 'Asia',
  },
  {
    alpha3Code: 'ABW',
    countryCode: 'AW',
    countryName: 'Aruba',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'MAR',
    countryCode: 'MA',
    countryName: 'Morocco',
    region: 'Africa',
  },
  {
    alpha3Code: 'FIN',
    countryCode: 'FI',
    countryName: 'Finland',
    region: 'Europe',
  },
  {
    alpha3Code: 'VAT',
    countryCode: 'VA',
    countryName: 'Holy See',
    region: 'Europe',
  },
  {
    alpha3Code: 'ZWE',
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    region: 'Africa',
  },
  {
    alpha3Code: 'CYM',
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'BHR',
    countryCode: 'BH',
    countryName: 'Bahrain',
    region: 'Asia',
  },
  {
    alpha3Code: 'PRY',
    countryCode: 'PY',
    countryName: 'Paraguay',
    region: 'Americas',
  },
  {
    alpha3Code: 'ECU',
    countryCode: 'EC',
    countryName: 'Ecuador',
    region: 'Americas',
  },
  {
    alpha3Code: 'LBR',
    countryCode: 'LR',
    countryName: 'Liberia',
    region: 'Africa',
  },
  {
    alpha3Code: 'RUS',
    countryCode: 'RU',
    countryName: 'Russian Federation',
    region: 'Europe',
  },
  {
    alpha3Code: 'POL',
    countryCode: 'PL',
    countryName: 'Poland',
    region: 'Europe',
  },
  {
    alpha3Code: 'OMN',
    countryCode: 'OM',
    countryName: 'Oman',
    region: 'Asia',
  },
  {
    alpha3Code: 'MLT',
    countryCode: 'MT',
    countryName: 'Malta',
    region: 'Europe',
  },
  {
    alpha3Code: 'SSD',
    countryCode: 'SS',
    countryName: 'South Sudan',
    region: 'Africa',
  },
  {
    alpha3Code: 'DEU',
    countryCode: 'DE',
    countryName: 'Germany',
    region: 'Europe',
  },
  {
    alpha3Code: 'TKM',
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    region: 'Asia',
  },
  {
    alpha3Code: 'SJM',
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    region: 'Europe',
  },
  {
    alpha3Code: 'MMR',
    countryCode: 'MM',
    countryName: 'Myanmar',
    region: 'Asia',
  },
  {
    alpha3Code: 'TTO',
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'ISR',
    countryCode: 'IL',
    countryName: 'Israel',
    region: 'Asia',
  },
  {
    alpha3Code: 'BGD',
    countryCode: 'BD',
    countryName: 'Bangladesh',
    region: 'Asia',
  },
  {
    alpha3Code: 'NRU',
    countryCode: 'NR',
    countryName: 'Nauru',
    region: 'Oceania',
  },
  {
    alpha3Code: 'LKA',
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    region: 'Asia',
  },
  {
    alpha3Code: 'UGA',
    countryCode: 'UG',
    countryName: 'Uganda',
    region: 'Africa',
  },
  {
    alpha3Code: 'NGA',
    countryCode: 'NG',
    countryName: 'Nigeria',
    region: 'Africa',
  },
  {
    alpha3Code: 'BES',
    countryCode: 'BQ',
    countryName: 'Bonaire, Sint Eustatius and Saba',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'MEX',
    countryCode: 'MX',
    countryName: 'Mexico',
    region: 'Americas',
  },
  {
    alpha3Code: 'CUW',
    countryCode: 'CW',
    countryName: 'Curaçao',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'SVN',
    countryCode: 'SI',
    countryName: 'Slovenia',
    region: 'Europe',
  },
  {
    alpha3Code: 'MNG',
    countryCode: 'MN',
    countryName: 'Mongolia',
    region: 'Asia',
  },
  {
    alpha3Code: 'CAN',
    countryCode: 'CA',
    countryName: 'Canada',
    region: 'Americas',
  },
  {
    alpha3Code: 'ALA',
    countryCode: 'AX',
    countryName: 'Åland Islands',
    region: 'Europe',
  },
  {
    alpha3Code: 'VNM',
    countryCode: 'VN',
    countryName: 'Vietnam',
    region: 'Asia',
  },
  {
    alpha3Code: 'TWN',
    countryCode: 'TW',
    countryName: 'Taiwan',
    region: 'Asia',
  },
  {
    alpha3Code: 'JPN',
    countryCode: 'JP',
    countryName: 'Japan',
    region: 'Asia',
  },
  {
    alpha3Code: 'IOT',
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    region: 'Asia',
  },
  {
    alpha3Code: 'ROU',
    countryCode: 'RO',
    countryName: 'Romania',
    region: 'Europe',
  },
  {
    alpha3Code: 'BGR',
    countryCode: 'BG',
    countryName: 'Bulgaria',
    region: 'Europe',
  },
  {
    alpha3Code: 'GUM',
    countryCode: 'GU',
    countryName: 'Guam',
    region: 'Oceania',
  },
  {
    alpha3Code: 'BRA',
    countryCode: 'BR',
    countryName: 'Brazil',
    region: 'Americas',
  },
  {
    alpha3Code: 'ARM',
    countryCode: 'AM',
    countryName: 'Armenia',
    region: 'Asia',
  },
  {
    alpha3Code: 'ZMB',
    countryCode: 'ZM',
    countryName: 'Zambia',
    region: 'Africa',
  },
  {
    alpha3Code: 'DJI',
    countryCode: 'DJ',
    countryName: 'Djibouti',
    region: 'Africa',
  },
  {
    alpha3Code: 'JEY',
    countryCode: 'JE',
    countryName: 'Jersey',
    region: 'Europe',
  },
  {
    alpha3Code: 'AUT',
    countryCode: 'AT',
    countryName: 'Austria',
    region: 'Europe',
  },
  {
    alpha3Code: 'CMR',
    countryCode: 'CM',
    countryName: 'Cameroon',
    region: 'Africa',
  },
  {
    alpha3Code: 'SWE',
    countryCode: 'SE',
    countryName: 'Sweden',
    region: 'Europe',
  },
  {
    alpha3Code: 'FJI',
    countryCode: 'FJ',
    countryName: 'Fiji',
    region: 'Oceania',
  },
  {
    alpha3Code: 'KAZ',
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    region: 'Asia',
  },
  {
    alpha3Code: 'GRL',
    countryCode: 'GL',
    countryName: 'Greenland',
    region: 'Americas',
  },
  {
    alpha3Code: 'GUY',
    countryCode: 'GY',
    countryName: 'Guyana',
    region: 'Americas',
  },
  {
    alpha3Code: 'CXR',
    countryCode: 'CX',
    countryName: 'Christmas Island',
    region: 'Asia',
  },
  {
    alpha3Code: 'MWI',
    countryCode: 'MW',
    countryName: 'Malawi',
    region: 'Africa',
  },
  {
    alpha3Code: 'TUN',
    countryCode: 'TN',
    countryName: 'Tunisia',
    region: 'Africa',
  },
  {
    alpha3Code: 'ZAF',
    countryCode: 'ZA',
    countryName: 'South Africa',
    region: 'Africa',
  },
  {
    alpha3Code: 'TON',
    countryCode: 'TO',
    countryName: 'Tonga',
    region: 'Oceania',
  },
  {
    alpha3Code: 'CYP',
    countryCode: 'CY',
    countryName: 'Cyprus',
    region: 'Asia',
  },
  {
    alpha3Code: 'MDV',
    countryCode: 'MV',
    countryName: 'Maldives',
    region: 'Asia',
  },
  {
    alpha3Code: 'PCN',
    countryCode: 'PN',
    countryName: 'Pitcairn',
    region: 'Oceania',
  },
  {
    alpha3Code: 'RWA',
    countryCode: 'RW',
    countryName: 'Rwanda',
    region: 'Africa',
  },
  {
    alpha3Code: 'NIC',
    countryCode: 'NI',
    countryName: 'Nicaragua',
    region: 'Americas',
  },
  {
    alpha3Code: 'KNA',
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'BEN',
    countryCode: 'BJ',
    countryName: 'Benin',
    region: 'Africa',
  },
  {
    alpha3Code: 'ETH',
    countryCode: 'ET',
    countryName: 'Ethiopia',
    region: 'Africa',
  },
  {
    alpha3Code: 'GMB',
    countryCode: 'GM',
    countryName: 'Gambia',
    region: 'Africa',
  },
  {
    alpha3Code: 'TZA',
    countryCode: 'TZ',
    countryName: 'Tanzania, United Republic of',
    region: 'Africa',
  },
  {
    alpha3Code: 'VCT',
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'FLK',
    countryCode: 'FK',
    countryName: 'Falkland Islands (Malvinas)',
    region: 'Americas',
  },
  {
    alpha3Code: 'SDN',
    countryCode: 'SD',
    countryName: 'Sudan',
    region: 'Africa',
  },
  {
    alpha3Code: 'MCO',
    countryCode: 'MC',
    countryName: 'Monaco',
    region: 'Europe',
  },
  {
    alpha3Code: 'AUS',
    countryCode: 'AU',
    countryName: 'Australia',
    region: 'Oceania',
  },
  {
    alpha3Code: 'CHL',
    countryCode: 'CL',
    countryName: 'Chile',
    region: 'Americas',
  },
  {
    alpha3Code: 'DNK',
    countryCode: 'DK',
    countryName: 'Denmark',
    region: 'Europe',
  },
  {
    alpha3Code: 'FRA',
    countryCode: 'FR',
    countryName: 'France',
    region: 'Europe',
  },
  {
    alpha3Code: 'TCA',
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'CUB',
    countryCode: 'CU',
    countryName: 'Cuba',
    region: 'Americas',
  },
  {
    alpha3Code: 'ALB',
    countryCode: 'AL',
    countryName: 'Albania',
    region: 'Europe',
  },
  {
    alpha3Code: 'MOZ',
    countryCode: 'MZ',
    countryName: 'Mozambique',
    region: 'Africa',
  },
  {
    alpha3Code: 'BHS',
    countryCode: 'BS',
    countryName: 'Bahamas',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'NER',
    countryCode: 'NE',
    countryName: 'Niger',
    region: 'Africa',
  },
  {
    alpha3Code: 'GTM',
    countryCode: 'GT',
    countryName: 'Guatemala',
    region: 'Americas',
  },
  {
    alpha3Code: 'LIE',
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    region: 'Europe',
  },
  {
    alpha3Code: 'NPL',
    countryCode: 'NP',
    countryName: 'Nepal',
    region: 'Asia',
  },
  {
    alpha3Code: 'BFA',
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    region: 'Africa',
  },
  {
    alpha3Code: 'PLW',
    countryCode: 'PW',
    countryName: 'Palau',
    region: 'Oceania',
  },
  {
    alpha3Code: 'KWT',
    countryCode: 'KW',
    countryName: 'Kuwait',
    region: 'Asia',
  },
  {
    alpha3Code: 'IND',
    countryCode: 'IN',
    countryName: 'India',
    region: 'Asia',
  },
  {
    alpha3Code: 'GAB',
    countryCode: 'GA',
    countryName: 'Gabon',
    region: 'Africa',
  },
  {
    alpha3Code: 'TUV',
    countryCode: 'TV',
    countryName: 'Tuvalu',
    region: 'Oceania',
  },
  {
    alpha3Code: 'MAC',
    countryCode: 'MO',
    countryName: 'Macao',
    region: 'Asia',
  },
  {
    alpha3Code: 'SHN',
    countryCode: 'SH',
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    region: 'Africa',
  },
  {
    alpha3Code: 'MDA',
    countryCode: 'MD',
    countryName: 'Moldova (Republic of)',
    region: 'Europe',
  },
  {
    alpha3Code: 'COK',
    countryCode: 'CK',
    countryName: 'Cook Islands',
    region: 'Oceania',
  },
  {
    alpha3Code: 'ARG',
    countryCode: 'AR',
    countryName: 'Argentina',
    region: 'Americas',
  },
  {
    alpha3Code: 'SYC',
    countryCode: 'SC',
    countryName: 'Seychelles',
    region: 'Africa',
  },
  {
    alpha3Code: 'IRL',
    countryCode: 'IE',
    countryName: 'Ireland',
    region: 'Europe',
  },
  {
    alpha3Code: 'ESP',
    countryCode: 'ES',
    countryName: 'Spain',
    region: 'Europe',
  },
  {
    alpha3Code: 'LBN',
    countryCode: 'LB',
    countryName: 'Lebanon',
    region: 'Asia',
  },
  {
    alpha3Code: 'BMU',
    countryCode: 'BM',
    countryName: 'Bermuda',
    region: 'Americas',
  },
  {
    alpha3Code: 'REU',
    countryCode: 'RE',
    countryName: 'Réunion',
    region: 'Africa',
  },
  {
    alpha3Code: 'KIR',
    countryCode: 'KI',
    countryName: 'Kiribati',
    region: 'Oceania',
  },
  {
    alpha3Code: 'ATG',
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'MTQ',
    countryCode: 'MQ',
    countryName: 'Martinique',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'SLV',
    countryCode: 'SV',
    countryName: 'El Salvador',
    region: 'Americas',
  },
  {
    alpha3Code: 'JOR',
    countryCode: 'JO',
    countryName: 'Jordan',
    region: 'Asia',
  },
  {
    alpha3Code: 'THA',
    countryCode: 'TH',
    countryName: 'Thailand',
    region: 'Asia',
  },
  {
    alpha3Code: 'SOM',
    countryCode: 'SO',
    countryName: 'Somalia',
    region: 'Africa',
  },
  {
    alpha3Code: 'MHL',
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    region: 'Oceania',
  },
  {
    alpha3Code: 'COG',
    countryCode: 'CG',
    countryName: 'Congo',
    region: 'Africa',
  },
  {
    alpha3Code: 'PRK',
    countryCode: 'KP',
    countryName: "Korea (Democratic People's Republic of)",
    region: 'Asia',
  },
  {
    alpha3Code: 'GUF',
    countryCode: 'GF',
    countryName: 'French Guiana',
    region: 'Americas',
  },
  {
    alpha3Code: 'BIH',
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    region: 'Europe',
  },
  {
    alpha3Code: 'MYT',
    countryCode: 'YT',
    countryName: 'Mayotte',
    region: 'Africa',
  },
  {
    alpha3Code: 'SGS',
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    region: 'Antarctica',
  },
  {
    alpha3Code: 'KEN',
    countryCode: 'KE',
    countryName: 'Kenya',
    region: 'Africa',
  },
  {
    alpha3Code: 'PER',
    countryCode: 'PE',
    countryName: 'Peru',
    region: 'Americas',
  },
  {
    alpha3Code: 'BTN',
    countryCode: 'BT',
    countryName: 'Bhutan',
    region: 'Asia',
  },
  {
    alpha3Code: 'SWZ',
    countryCode: 'SZ',
    countryName: 'Swaziland',
    region: 'Africa',
  },
  {
    alpha3Code: 'CRI',
    countryCode: 'CR',
    countryName: 'Costa Rica',
    region: 'Americas',
  },
  {
    alpha3Code: 'TCD',
    countryCode: 'TD',
    countryName: 'Chad',
    region: 'Africa',
  },
  {
    alpha3Code: 'DMA',
    countryCode: 'DM',
    countryName: 'Dominica',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'NCL',
    countryCode: 'NC',
    countryName: 'New Caledonia',
    region: 'Oceania',
  },
  {
    alpha3Code: 'GRC',
    countryCode: 'GR',
    countryName: 'Greece',
    region: 'Europe',
  },
  {
    alpha3Code: 'GGY',
    countryCode: 'GG',
    countryName: 'Guernsey',
    region: 'Europe',
  },
  {
    alpha3Code: 'HND',
    countryCode: 'HN',
    countryName: 'Honduras',
    region: 'Americas',
  },
  {
    alpha3Code: 'VIR',
    countryCode: 'VI',
    countryName: 'Virgin Islands (U.S.)',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'CAF',
    countryCode: 'CF',
    countryName: 'Central African Republic',
    region: 'Africa',
  },
  {
    alpha3Code: 'SEN',
    countryCode: 'SN',
    countryName: 'Senegal',
    region: 'Africa',
  },
  {
    alpha3Code: 'AFG',
    countryCode: 'AF',
    countryName: 'Afghanistan',
    region: 'Asia',
  },
  {
    alpha3Code: 'MNP',
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    region: 'Oceania',
  },
  {
    alpha3Code: 'PHL',
    countryCode: 'PH',
    countryName: 'Philippines',
    region: 'Asia',
  },
  {
    alpha3Code: 'BLR',
    countryCode: 'BY',
    countryName: 'Belarus',
    region: 'Europe',
  },
  {
    alpha3Code: 'LVA',
    countryCode: 'LV',
    countryName: 'Latvia',
    region: 'Europe',
  },
  {
    alpha3Code: 'NOR',
    countryCode: 'NO',
    countryName: 'Norway',
    region: 'Europe',
  },
  {
    alpha3Code: 'EGY',
    countryCode: 'EG',
    countryName: 'Egypt',
    region: 'Africa',
  },
  {
    alpha3Code: 'KHM',
    countryCode: 'KH',
    countryName: 'Cambodia',
    region: 'Asia',
  },
  {
    alpha3Code: 'IRQ',
    countryCode: 'IQ',
    countryName: 'Iraq',
    region: 'Asia',
  },
  {
    alpha3Code: 'LCA',
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'NZL',
    countryCode: 'NZ',
    countryName: 'New Zealand',
    region: 'Oceania',
  },
  {
    alpha3Code: 'BLM',
    countryCode: 'BL',
    countryName: 'Saint Barthélemy',
    region: 'Caribbean',
  },
  {
    alpha3Code: 'UZB',
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    region: 'Asia',
  },
  {
    alpha3Code: 'IDN',
    countryCode: 'ID',
    countryName: 'Indonesia',
    region: 'Asia',
  },
  {
    alpha3Code: 'ERI',
    countryCode: 'ER',
    countryName: 'Eritrea',
    region: 'Africa',
  },
  {
    alpha3Code: 'VEN',
    countryCode: 'VE',
    countryName: 'Venezuela (Bolivarian Republic of)',
    region: 'Americas',
  },
  {
    alpha3Code: 'FSM',
    countryCode: 'FM',
    countryName: 'Micronesia (Federated States of)',
    region: 'Oceania',
  },
  {
    alpha3Code: 'SLB',
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    region: 'Oceania',
  },
  {
    alpha3Code: 'MNE',
    countryCode: 'ME',
    countryName: 'Montenegro',
    region: 'Europe',
  },
  {
    alpha3Code: 'ASM',
    countryCode: 'AS',
    countryName: 'American Samoa',
    region: 'Oceania',
  },
] as readonly Country[];

export const countriesMap: Record<string, string> = countriesList.reduce(
  (acc: Record<string, string>, obj: Country) => {
    const accCountries = { ...acc };
    accCountries[obj.countryCode] = obj.countryName;
    return accCountries;
  },
  {},
);

export default countriesList;
