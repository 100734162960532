import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const MemberPhotoBlock = styled.div`
  text-align: left;
  margin-bottom: 10px;

  img {
    max-width: 100%;

    &.small {
      height: 75px;
      width: 75px;
    }
  }
`;

const MemberTextBlock = styled.div`
  color: ${COLORS.neutral800};
  margin-bottom: 0;
`;

export { MemberPhotoBlock, MemberTextBlock };
