import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const { primary } = COLORS;

export const ResendContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${primary};
`;

export const Info = styled.span`
  padding-right: 15px;
  font-size: 12px;
  font-weight: normal;
`;
