import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const disabledButtonStyles = `
  background: ${COLORS.neutral800};
  color: ${COLORS.white};
  border: none;

  &:hover, &:focus {
    background: ${COLORS.neutral800};
  }
`;

const darkButtonStyles = `
  background: ${COLORS.neutral300};
  color: ${COLORS.primary};
  border: none;

  &:hover, &:focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const lightButtonStyles = `
  background: ${COLORS.white};
  color: ${COLORS.primary};
  border: 1px dashed ${COLORS.neutral500};

  &:hover, &:focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const purpleButtonStyles = `
  background: ${COLORS.alternativeContrastText};
  color: ${COLORS.alternative};
  border: 1px solid ${COLORS.alternative};
  margin: 0 5px;

  &:hover, &:focus {
    background: ${COLORS.alternative};
    color: ${COLORS.alternativeContrastText};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const getButtonStyles = (disabled: boolean, color: string) => {
  if (disabled) return disabledButtonStyles;
  if (color === 'dark') return darkButtonStyles;
  if (color === 'light') return lightButtonStyles;
  if (color === 'purple') return purpleButtonStyles;
};

export const ButtonWrapper = styled.button<{ disabled: boolean; color: string }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-family: ${FONTS.mainFont};
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  outline: 0;
  padding: 15px 41px;
  height: 50px;
  min-width: 200px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
  ${({ disabled, color }) => getButtonStyles(disabled, color)}
`;

export const AddIconContainer = styled.div`
  margin-right: 9px;
`;
