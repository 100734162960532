import { COLORS } from 'shared-parts/constants';

const { highlight } = COLORS;

export const NewAddIcon = ({ fill = highlight }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    stroke={fill}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7" strokeWidth="1.5" />
    <path d="M8 10.3335L8 5.66683" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M10.3333 8L5.66665 8" strokeWidth="1.5" strokeLinecap="square" />
  </svg>
);
