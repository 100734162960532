import Loader from 'shared-parts/components/loader/loader';

import { LoaderText, LoaderWrapper } from './loader.styled';

export default () => (
  <LoaderWrapper>
    <Loader alwaysVisible globalSpinnerSize={140} zIndex="5" />
    <LoaderText>Loading...</LoaderText>
  </LoaderWrapper>
);
