import styled from 'styled-components';

import SideFormTabs from 'shared/side-form/tabs.styled';
import { Tab } from 'shared-parts/components/tabs/tabs.styled';

const StyledTabs = styled(SideFormTabs)`
  ${Tab} {
    font-weight: bold;
  }
`;

export {
  StyledTabs, //eslint-disable-line
};
