import styled from 'styled-components';

import Tooltip from 'shared-parts/components/tooltip';
import { COLORS } from 'shared-parts/constants';

const { alternative, alternativeContrastText } = COLORS;

const StyledTooltip = styled(Tooltip)`
  margin-left: 3px;
  margin-top: -4px;
`;

const TooltipContent = styled.div`
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${alternative};
  color: ${alternativeContrastText};
  font-size: 14px;
  font-weight: normal;
`;

export { TooltipContent, StyledTooltip };
