export const SELECT_PACKAGE = 'SELECT_PACKAGE';
export const SELECT_PACKAGE_ERROR = 'SELECT_PACKAGE_ERROR';

export const GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY = 'GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY';
export const GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_SUCCESS =
  'GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_SUCCESS';
export const GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_ERROR =
  'GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY_ERROR';

export const GET_SUBSCRIPTION_TEMPLATES = 'GET_SUBSCRIPTION_TEMPLATES';
export const GET_SUBSCRIPTION_TEMPLATES_SUCCESS = 'GET_SUBSCRIPTION_TEMPLATES_SUCCESS';
export const GET_SUBSCRIPTION_TEMPLATES_ERROR = 'GET_SUBSCRIPTION_TEMPLATES_ERROR';
