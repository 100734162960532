import { useEffect, useRef } from 'react';

import { addMethod, string } from 'yup';

import { TextInput } from 'shared-parts/components/text-input';

import {
  AddressInfoWrapper,
  AreaBelowTheInput,
  AutofillDisableInput,
  EnterAddressManuallyButton,
  LoqateWrapper,
} from './loqate.styled';

addMethod(string, 'validLoqateAddress', function (message, { line1, city, postCode, countryCode }) {
  return this.test('valid-loqate-address', message, function () {
    const { path, createError } = this; // actually path is the field name
    const addressIsValid = line1 && city && postCode && countryCode;

    return addressIsValid ? true : createError({ path, message });
  });
});

const disableAutoCompleteForInput = (element, loqateAutocomplete) =>
  element && element.setAttribute('autocomplete', loqateAutocomplete);

const Loqate = props => {
  const loqateInput = useRef(null);

  useEffect(() => disableAutoCompleteForInput(loqateInput.current, props.loqateAutocomplete));

  return (
    <LoqateWrapper blockStyles={props.blockStyles}>
      <props.FormGroup>
        <label htmlFor="address">{props.loqateLabel}</label>
        <AutofillDisableInput />
        <TextInput
          name="address"
          placeholder="Address"
          disableAutocomplete={props.disableAutocomplete}
          autoComplete={props.loqateAutocomplete}
          handleChange={props.handleLoqateInputEntering}
          disabled={props.countryIsNotSelected}
          inputRef={loqateInput}
        />
      </props.FormGroup>
      <AreaBelowTheInput>
        <AddressInfoWrapper>
          <div>{props.loqateAddress.Line1}</div>
          <div>{props.loqateAddress.Line2}</div>
          <div>{props.loqateAddress.City}</div>
          <div>{props.loqateAddress.PostalCode}</div>
          <div>{props.loqateAddress.CountryName}</div>
        </AddressInfoWrapper>
        <EnterAddressManuallyButton type="button" onClick={props.switchToManualInput}>
          Enter address manually
        </EnterAddressManuallyButton>
      </AreaBelowTheInput>
    </LoqateWrapper>
  );
};

export default Loqate;
