import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'helpers';

function* getVerification() {
  try {
    const { data } = yield call(request, API.Verification());

    yield put(actions.getVerificationSuccess(data));
  } catch (e) {
    yield put(actions.getVerificationError(e));
  }
}

function* getVerificationWatcher() {
  yield takeEvery(constants.GET_VERIFICATION, getVerification);
}

export { getVerification, getVerificationWatcher };
