import styled from 'styled-components';

import Pagination from 'shared-parts/components/pagination';
import { Table as RegularTable } from 'shared-parts/components/table';
import {
  TableCell,
  TableHeader,
  TableHeaderCell,
} from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS } from 'shared-parts/constants';

const { primary, neutral200, neutral300, white } = COLORS;

export const Table = styled(RegularTable)`
  background: ${white};
  border-radius: 0;
  border: none;

  ${TableHeader} {
    color: ${primary};
  }

  ${TableHeaderCell} {
    top: 57px;
    box-shadow: inset 0 -1px 0 ${neutral300};
    background: ${white};
    font-weight: 700;
    border: 1px solid ${neutral200};

    :first-of-type {
      border-top-left-radius: 0;
    }

    :last-of-type {
      border-right: none;
      border-top-right-radius: 0;
    }
  }

  ${TableCell} {
    border: 1px solid ${neutral200};

    :last-of-type {
      border-right: none;
    }
  }
`;

export const DownloadContainer = styled.div`
  padding: 21px 16px 30px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid ${neutral300};
  background-color: ${neutral200};
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const StyledPagination = styled(Pagination)`
  position: sticky;
  bottom: 72px;
  z-index: 2;
  background-color: ${neutral200};
`;
