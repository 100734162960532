import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const { primary, neutral800, neutral300 } = COLORS;

export const Container = styled.div<{ showBorderBottom: boolean }>`
  padding: 30px 20px;
  padding-bottom: 0px;
  ${({ showBorderBottom }) =>
    showBorderBottom &&
    `
      border-bottom: 1px solid ${neutral300};
      padding-bottom: 30px;
    `};
`;

export const Block = styled.div`
  display: inline-block;
  margin: 0 20px;

  :first-child {
    margin: 0;
  }
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${primary};
`;

export const Text = styled.span`
  color: ${({ color = neutral800 }) => color};
`;
