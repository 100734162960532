const transformArrayToQueryParameter = (prefix, elements, parameterName) => {
  if (elements.length) {
    return `${prefix}${parameterName}[]=${elements
      .map(element => encodeURIComponent(element))
      .join(`&${parameterName}[]=`)}`;
  }

  return '';
};

export default transformArrayToQueryParameter;
