import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { useEnvVar } from '@frontend/config';

import { hideModal } from 'app-state/actions/shared';

import {
  PRIMARY_BUTTON_LABEL,
  TEXT_BUTTON_LABEL,
} from 'modules/company/deal-management/deal-form/create-deal-success-modal-buttons/constants';
import { FeatureFlag } from 'providers';
import Role from 'helpers/role';
import { StandardButton } from 'shared-parts/components/button';
import { ButtonsPart, StyledTextButton } from 'shared-parts/components/modal/shared.styled';

const getCreateDealSuccessCustomActions =
  ({ companyId, dealUuid }: { companyId: string; dealUuid: string }) =>
  () => {
    const dispatch = useDispatch();

    const newFormEnabled = useEnvVar(FeatureFlag.NEW_PLACEMENT_CREATION_FLOW);

    const getDealSummaryURLAndRedirect = (openEditDocumentsTab: boolean) => () => {
      if (newFormEnabled) {
        dispatch(hideModal());
        Role.has('Deal Settings URL', {
          companyId,
          dealUuid,
        }).then((path: string) => {
          dispatch(push({ pathname: path, hash: openEditDocumentsTab ? '#Documents' : '' }));
        });
      } else {
        Role.has('Deal Setup URL', { companyId, modelUuid: dealUuid }).then((pathname: string) => {
          dispatch(push({ pathname, state: { openEditDocumentsTab } }));
          dispatch(hideModal());
        });
      }
    };

    return (
      <ButtonsPart>
        <StyledTextButton onClick={getDealSummaryURLAndRedirect(false)}>
          {TEXT_BUTTON_LABEL}
        </StyledTextButton>
        <StandardButton onClick={getDealSummaryURLAndRedirect(true)}>
          {PRIMARY_BUTTON_LABEL}
        </StandardButton>
      </ButtonsPart>
    );
  };

export default getCreateDealSuccessCustomActions;
