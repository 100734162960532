import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const Tumbler = styled.div`
  height: 35px;
  font-family: ${FONTS.oswaldFont};
  border-radius: 20px;
  width: ${({ width }) => width};
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid ${COLORS.neutral300};

  ${({ turnedOn }) =>
    turnedOn
      ? `
    background-color: ${COLORS.success100};
    color: ${COLORS.primary};
  `
      : `
    background-color: ${COLORS.error100};
    color: ${COLORS.primary};
  `}

  ${({ disabled }) =>
    disabled
      ? `
    background-color: ${COLORS.neutral200};
    color: ${COLORS.neutral300};
    cursor: default;
  `
      : ''}
`;

const TumblerLabel = styled.label`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 10px;
  font-weight: 500;
  cursor: inherit;
  line-height: 10px;
  transition: left 0.2s;
  left: ${({ turnedOn }) => (turnedOn ? '16px' : '38px')};
  color: ${({ turnedOn }) => (turnedOn ? COLORS.systemGreen : COLORS.systemRed)};
`;

const TumblerButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 4px;
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ turnedOn }) =>
    turnedOn
      ? `
    border: 1px solid ${COLORS.systemGreen};
    right: 4px;
  `
      : `
    border: 1px solid ${COLORS.systemRed};
    left: 4px;
  `};
  transition: left 0.4s;
  z-index: 1;
  text-align: center;
  line-height: 18px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export { Tumbler, TumblerLabel, TumblerButton };
