import { GrantsRemovedText, GrantsRemovedWrapper, OkButton } from './grants-removed-modal.styled';

const GrantsRemoved = ({ hideModal }) => (
  <GrantsRemovedWrapper>
    <GrantsRemovedText>GRANTS REMOVED</GrantsRemovedText>
    <OkButton type="button" onClick={hideModal}>
      OK
    </OkButton>
  </GrantsRemovedWrapper>
);

export default GrantsRemoved;
