import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const MailLinkWrapper = styled.a<{
  color?: string;
  hoverColor?: string;
}>`
  color: ${({ color }) => color || COLORS.secondary};
  margin-left: 5px;
  &:hover {
    color: ${({ hoverColor }) => hoverColor || COLORS.accent};
  }
`;

export { MailLinkWrapper };
