import { call, put, takeEvery } from 'redux-saga/effects';

import { downloadFileError, downloadFileSuccess } from 'app-state/actions';
import { DOWNLOAD_FILE } from 'app-state/constants';

import { downloadBlob, request } from 'helpers';

function* downloadFile({ fileName, api, timeout }) {
  try {
    const options = {
      to: 'blob',
      contentType: 'text/plain',
      timeout,
    };
    const { data } = yield call(request, api, 'GET', null, options);

    downloadBlob(data, fileName);
    yield put(downloadFileSuccess());
  } catch (e) {
    yield put(downloadFileError(e));
  }
}

function* downloadFileWatcher() {
  yield takeEvery(DOWNLOAD_FILE, downloadFile);
}

export { downloadFile, downloadFileWatcher };
