import {
  ADD_FILING_DOCUMENT,
  ADD_FILING_DOCUMENT_ERROR,
  ADD_FILING_DOCUMENT_SUCCESS,
  DELETE_FILING_DOCUMENT,
  DELETE_FILING_DOCUMENT_ERROR,
  DELETE_FILING_DOCUMENT_SUCCESS,
  GET_ALLOTMENT_DATA,
  GET_ALLOTMENT_DATA_ERROR,
  GET_ALLOTMENT_DATA_SUCCESS,
  GET_ALLOTTED_SHARES,
  GET_ALLOTTED_SHARES_ERROR,
  GET_ALLOTTED_SHARES_SUCCESS,
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_ERROR,
  GET_COMPANY_PROFILE_SUCCESS,
  GET_FILING_DETAILS,
  GET_FILING_DETAILS_ERROR,
  GET_FILING_DETAILS_SUCCESS,
  GET_FILING_DOCUMENTS_SUCCESS,
  GET_FILINGS,
  GET_FILINGS_ERROR,
  GET_FILINGS_SUCCESS,
  GET_SHARE_CAPITAL,
  GET_SHARE_CAPITAL_ERROR,
  GET_SHARE_CAPITAL_SUCCESS,
  GET_SHAREHOLDER_INFORMATION,
  GET_SHAREHOLDER_INFORMATION_ERROR,
  GET_SHAREHOLDER_INFORMATION_SUCCESS,
  GET_SIC_CODES,
  GET_SIC_CODES_ERROR,
  GET_SIC_CODES_SUCCESS,
  RESET_ALLOTMENT_DATA,
  RESET_ALLOTTED_SHARES,
  RESET_COMPANY_PROFILE,
  RESET_FILING_DETAILS,
  RESET_FILINGS,
  RESET_SHARE_CAPITAL,
  RESET_SHAREHOLDER_INFORMATION,
} from 'app-state/constants/filings';

const initialState = {
  loading: false,
  data: {},
  error: null,
};

export const filingsReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case GET_FILINGS:
      return { ...initialState, loading: true };
    case GET_FILINGS_SUCCESS:
      return { ...initialState, data };
    case GET_FILINGS_ERROR:
      return { ...initialState, error };
    case RESET_FILINGS:
      return initialState;
    default:
      return state;
  }
};

export const filingDetailsReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case GET_FILING_DETAILS:
      return { ...initialState, loading: true };
    case GET_FILING_DETAILS_SUCCESS:
      return { ...initialState, data };
    case GET_FILING_DETAILS_ERROR:
      return { ...initialState, error };
    case RESET_FILING_DETAILS:
      return initialState;
    default:
      return state;
  }
};

const initialDocumentsState = {
  loading: false,
  data: [],
  error: null,
};

export const filingDocumentsReducer = (
  state = initialDocumentsState,
  { type, data, documentId, error },
) => {
  switch (type) {
    case GET_FILING_DOCUMENTS_SUCCESS:
      return { ...initialDocumentsState, data };
    case ADD_FILING_DOCUMENT:
    case DELETE_FILING_DOCUMENT:
      return { ...state, loading: true };
    case ADD_FILING_DOCUMENT_SUCCESS:
      return {
        ...initialDocumentsState,
        data: [...state.data, data],
      };
    case DELETE_FILING_DOCUMENT_SUCCESS:
      return {
        ...initialDocumentsState,
        data: [...state.data.filter(({ id }) => id !== documentId)],
      };
    case ADD_FILING_DOCUMENT_ERROR:
    case DELETE_FILING_DOCUMENT_ERROR:
      return { ...state, error };
    case RESET_FILING_DETAILS:
      return initialDocumentsState;
    default:
      return state;
  }
};

const initialProfileState = {
  loading: true,
  data: {},
  error: null,
};

export const companyProfileReducer = (state = initialProfileState, { type, data, error }) => {
  switch (type) {
    case GET_COMPANY_PROFILE:
      return initialProfileState;
    case GET_COMPANY_PROFILE_SUCCESS:
      return { loading: false, data, error: null };
    case GET_COMPANY_PROFILE_ERROR:
      return { loading: false, data: {}, error };
    case RESET_COMPANY_PROFILE:
      return initialProfileState;
    default:
      return state;
  }
};

const initialShareCapitalState = {
  loading: false,
  data: { capital: [], descriptions: [] },
  error: null,
};

export const shareCapitalReducer = (state = initialShareCapitalState, { type, data, error }) => {
  switch (type) {
    case GET_SHARE_CAPITAL:
      return { ...initialShareCapitalState, loading: true };
    case GET_SHARE_CAPITAL_SUCCESS:
      return { ...initialShareCapitalState, data };
    case GET_SHARE_CAPITAL_ERROR:
      return { ...initialShareCapitalState, error };
    case RESET_SHARE_CAPITAL:
      return initialShareCapitalState;
    default:
      return state;
  }
};

const initialShareholderInformationState = {
  loading: true,
  data: [],
  error: null,
};

export const shareholderInformationReducer = (
  state = initialShareholderInformationState,
  { type, data, error },
) => {
  switch (type) {
    case GET_SHAREHOLDER_INFORMATION:
      return initialShareholderInformationState;
    case GET_SHAREHOLDER_INFORMATION_SUCCESS:
      return { loading: false, data, error: null };
    case GET_SHAREHOLDER_INFORMATION_ERROR:
      return { loading: false, data: [], error };
    case RESET_SHAREHOLDER_INFORMATION:
      return initialShareholderInformationState;
    default:
      return state;
  }
};

const initialSicCodesState = {
  loading: false,
  data: [],
  error: null,
};

export const sicCodesReducer = (state = initialSicCodesState, { type, payload }) => {
  switch (type) {
    case GET_SIC_CODES_SUCCESS:
      return { data: payload.data, error: null, loading: false };
    case GET_SIC_CODES_ERROR:
      return { ...state, error: payload.error, loading: false };
    case GET_SIC_CODES:
      return { ...state, loading: true };
    default:
      return state;
  }
};

const initialAllotmentDataState = {
  loading: true,
  data: {},
  error: null,
};

export const allotmentDataReducer = (state = initialAllotmentDataState, { type, payload }) => {
  switch (type) {
    case GET_ALLOTMENT_DATA:
      return initialAllotmentDataState;
    case GET_ALLOTMENT_DATA_SUCCESS:
      return { loading: false, data: payload, error: null };
    case GET_ALLOTMENT_DATA_ERROR:
      return { loading: false, data: {}, error: payload };
    case RESET_ALLOTMENT_DATA:
      return initialAllotmentDataState;
    default:
      return state;
  }
};

const initialAllottedSharesState = {
  loading: true,
  data: {},
  error: null,
};

export const allottedSharesReducer = (state = initialAllottedSharesState, { type, payload }) => {
  switch (type) {
    case GET_ALLOTTED_SHARES:
      return initialAllottedSharesState;
    case GET_ALLOTTED_SHARES_SUCCESS:
      return { loading: false, data: payload, error: null };
    case GET_ALLOTTED_SHARES_ERROR:
      return { loading: false, data: {}, error: payload };
    case RESET_ALLOTTED_SHARES:
      return initialAllottedSharesState;
    default:
      return state;
  }
};
