import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { downloadFile, resetDownloadFile } from 'app-state/actions/file';
import { showModal } from 'app-state/actions/shared';
import { getDownloadFileErrorMessage, getDownloadFileLoadingStatus } from 'app-state/selectors';

import useTypedSelector from 'hooks/use-typed-selector';
import Loader from 'shared-parts/components/loader/loader';
import ModalFailed from 'shared-parts/components/modal-failed';

import { IconContainer, StyledDownloadButton } from './index.styled';

const DownloadButton = ({
  styles,
  api,
  fileName,
  icon,
  children,
  timeout = 60000,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const loading = useTypedSelector(getDownloadFileLoadingStatus);
  const errorMessage = useTypedSelector(getDownloadFileErrorMessage);

  const showErrorModal = () => {
    if (errorMessage) {
      dispatch(
        showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
        }),
      );
    }
  };

  useEffect(
    () => () => {
      dispatch(resetDownloadFile());
    },
    [],
  );

  useEffect(showErrorModal, [errorMessage, showModal]);

  const downloadFileCallback = useCallback(
    () => dispatch(downloadFile(fileName, api, timeout)),
    [downloadFile, fileName, api],
  );

  return (
    <StyledDownloadButton
      styles={styles}
      type="button"
      disabled={loading || disabled}
      onClick={downloadFileCallback}
    >
      <IconContainer>
        {loading ? <Loader alwaysVisible globalSpinnerSize={21} zIndex={1} /> : icon}
      </IconContainer>
      {children}
    </StyledDownloadButton>
  );
};

DownloadButton.defaultProps = {
  styles: '',
};

DownloadButton.propTypes = {
  styles: PropTypes.string,
  icon: PropTypes.node.isRequired,
  api: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DownloadButton;
