export const UAM = 'Assets Under Management (AUM)';
export const HEADQUARTER_COUNTRY = 'Headquarter Country';
export const INVESTMENT_STYLE = 'Investment Style';
export const INVEST_IN_PRIVATE_CAPITAL_OPPORTUNITIES =
  'Do you invest in private capital opportunities?';
export const LOOKING_FOR_INVESTMENT_OPPORTUNITIES =
  'Are you actively looking for new private capital investment opportunities?';
export const INTERESTED_GEOGRAPHIES = 'Which geographies are of particular interest?';
export const NOT_INTERESTED_GEOGRAPHIES = 'Which geographies are not of interest?';
export const SECTOR_PREFERENCES = 'Do you have any sector preferences?';
export const ESG = 'ESG Preferences';
export const COMPANY_STAGE = 'Company Stage';
export const EBITDA = 'Financials minimum requirements (EBITDA)';
export const NET_INCOME_POSITIVE = 'Financials minimum requirements (Net Income positive)';
export const IDEAL_TICKET_SIZE = 'Ideal Ticket Size';
export const IDEAL_OWNERSHIP = 'Ideal Ownership';
export const ISSUANCE_PREFERENCE = 'Issuance preference';
export const TARGET_DURATION_OF_HOLDING = 'Target duration of holding';
export const EXIT_PREFERENCE = 'Exit Preference';
export const EXIT_HORIZON = 'Exit Horizon';
export const RETURN_CALCULATION_METHODOLOGY = 'Preferred investment return calculation methodology';
export const MINIMUM_RETURN = 'Minimum return (in the methodology stated above)';
export const CORPORATE_GOVERNANCE_REQUIREMENTS = 'Corporate governance requirements';
export const PRODUCT_TYPE = 'Instrument / Product type preference';
export const POSITION = 'Position Preference';
export const DEAL_PREFERENCE = 'Deal Preference';
export const LEAD_INVESTOR_PREFERENCE = 'Lead investor preference';
