import {
  FETCH_ORDER_DOCUMENTS,
  FETCH_ORDER_DOCUMENTS_ERROR,
  FETCH_ORDER_DOCUMENTS_SUCCESS,
  RESET_ORDER_DOCUMENTS,
  UPLOAD_ORDER_DOCUMENT,
  UPLOAD_ORDER_DOCUMENT_FINISHED,
} from './constants';

const defaultOrderDocumentsState = {
  isUploading: false,
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
        totalObjects: 0,
      },
    },
  },
  errors: null,
};

export const orderDocumentsReducer = (state = defaultOrderDocumentsState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDER_DOCUMENTS: {
      const { page, perPage, totalPages, totalObjects } = payload;
      return {
        ...defaultOrderDocumentsState,
        data: {
          items: [],
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
          },
        },
      };
    }
    case UPLOAD_ORDER_DOCUMENT:
      return { ...state, isUploading: true };
    case UPLOAD_ORDER_DOCUMENT_FINISHED:
      return { ...state, isUploading: false };
    case FETCH_ORDER_DOCUMENTS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case FETCH_ORDER_DOCUMENTS_ERROR:
      return { ...state, loading: false, errors: payload };
    case RESET_ORDER_DOCUMENTS:
      return defaultOrderDocumentsState;
    default:
      return state;
  }
};
