import {
  ADD_SECONDARY_TRANSACTION_DOCUMENT,
  ADD_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  ADD_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  APPROVE_SECONDARY_TRANSACTION_DOCUMENT,
  APPROVE_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  APPROVE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  APPROVE_TRANSACTION,
  APPROVE_TRANSACTION_ERROR,
  APPROVE_TRANSACTION_SUCCESS,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  GET_ALL_SECONDARY_TRANSACTIONS,
  GET_INTRODUCER_FEES,
  GET_INTRODUCER_FEES_ERROR,
  GET_INTRODUCER_FEES_SUCCESS,
  GET_SECONDARY_TRANSACTION,
  GET_SECONDARY_TRANSACTION_ERROR,
  GET_SECONDARY_TRANSACTION_SUCCESS,
  GET_SECONDARY_TRANSACTIONS,
  GET_SECONDARY_TRANSACTIONS_ERROR,
  GET_SECONDARY_TRANSACTIONS_SUCCESS,
  REJECT_SECONDARY_TRANSACTION_DOCUMENT,
  REJECT_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  REJECT_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  REJECT_TRANSACTION,
  REJECT_TRANSACTION_ERROR,
  REJECT_TRANSACTION_SUCCESS,
  RESET_SECONDARY_TRANSACTION,
  RESET_SECONDARY_TRANSACTIONS,
  SET_EMPTY_SECONDARY_TRANSACTIONS,
  UPDATE_INTRODUCER_FEES,
  UPDATE_INTRODUCER_FEES_ERROR,
  UPDATE_INTRODUCER_FEES_SUCCESS,
} from 'app-state/constants/secondary-transactions';

export const getAllSecondaryTransactions = ({ page, perPage, order, transactionUuid }) => ({
  type: GET_ALL_SECONDARY_TRANSACTIONS,
  page,
  perPage,
  order,
  transactionUuid,
});

export const approveTransaction = (transactionUuid, searchParams) => ({
  type: APPROVE_TRANSACTION,
  transactionUuid,
  searchParams,
});
export const approveTransactionSuccess = data => ({ type: APPROVE_TRANSACTION_SUCCESS, data });
export const approveTransactionError = error => ({ type: APPROVE_TRANSACTION_ERROR, error });

export const rejectTransaction = (transactionUuid, reason, searchParams) => ({
  type: REJECT_TRANSACTION,
  transactionUuid,
  reason,
  searchParams,
});
export const rejectTransactionSuccess = data => ({ type: REJECT_TRANSACTION_SUCCESS, data });
export const rejectTransactionError = error => ({ type: REJECT_TRANSACTION_ERROR, error });

export const getSecondaryTransactions = ({
  shouldBeRefreshed,
  page,
  perPage,
  fromDate,
  toDate,
  order,
  actionTypes,
  status,
  shareClasses,
}) => ({
  type: GET_SECONDARY_TRANSACTIONS,
  shouldBeRefreshed,
  page,
  perPage,
  fromDate,
  toDate,
  order,
  actionTypes,
  status,
  shareClasses,
});
export const getSecondaryTransactionsSuccess = data => ({
  type: GET_SECONDARY_TRANSACTIONS_SUCCESS,
  data,
});
export const getSecondaryTransactionsError = error => ({
  type: GET_SECONDARY_TRANSACTIONS_ERROR,
  error,
});
export const setEmptySecondaryTransactions = () => ({ type: SET_EMPTY_SECONDARY_TRANSACTIONS });
export const resetSecondaryTransactions = () => ({ type: RESET_SECONDARY_TRANSACTIONS });

export const getSecondaryTransaction = transactionUuid => ({
  type: GET_SECONDARY_TRANSACTION,
  transactionUuid,
});
export const getSecondaryTransactionSuccess = data => ({
  type: GET_SECONDARY_TRANSACTION_SUCCESS,
  data,
});
export const getSecondaryTransactionError = error => ({
  type: GET_SECONDARY_TRANSACTION_ERROR,
  error,
});
export const resetSecondaryTransaction = () => ({ type: RESET_SECONDARY_TRANSACTION });

export const addSecondaryTransactionDocument = params => ({
  type: ADD_SECONDARY_TRANSACTION_DOCUMENT,
  ...params,
});
export const addSecondaryTransactionDocumentSuccess = data => ({
  type: ADD_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  data,
});
export const addSecondaryTransactionDocumentError = error => ({
  type: ADD_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  error,
});

export const deleteSecondaryTransactionDocument = params => ({
  type: DELETE_SECONDARY_TRANSACTION_DOCUMENT,
  ...params,
});
export const deleteSecondaryTransactionDocumentSuccess = documentId => ({
  type: DELETE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  documentId,
});
export const deleteSecondaryTransactionDocumentError = error => ({
  type: DELETE_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  error,
});

export const approveSecondaryTransactionDocument = documentId => ({
  type: APPROVE_SECONDARY_TRANSACTION_DOCUMENT,
  documentId,
});
export const approveSecondaryTransactionDocumentSuccess = documentId => ({
  type: APPROVE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  documentId,
});
export const approveSecondaryTransactionDocumentError = error => ({
  type: APPROVE_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  error,
});

export const rejectSecondaryTransactionDocument = documentId => ({
  type: REJECT_SECONDARY_TRANSACTION_DOCUMENT,
  documentId,
});
export const rejectSecondaryTransactionDocumentSuccess = document => ({
  type: REJECT_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  document,
});
export const rejectSecondaryTransactionDocumentError = error => ({
  type: REJECT_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  error,
});

export const getIntroducerFees = payload => ({ type: GET_INTRODUCER_FEES, payload });
export const getIntroducerFeesSuccess = data => ({ type: GET_INTRODUCER_FEES_SUCCESS, data });
export const getIntroducerFeesError = error => ({ type: GET_INTRODUCER_FEES_ERROR, error });

export const updateIntroducerFees = payload => ({ type: UPDATE_INTRODUCER_FEES, payload });
export const updateIntroducerFeesSuccess = data => ({ type: UPDATE_INTRODUCER_FEES_SUCCESS, data });
export const updateIntroducerFeesError = error => ({ type: UPDATE_INTRODUCER_FEES_ERROR, error });
