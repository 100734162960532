import { FC, Fragment } from 'react';

import ArrayActionButtonContainer from 'shared/array-action-button-container';
import Select from 'shared-parts/components/select';

import { CLASSIFICATION_CODE_LABEL, SIC_SELECT_SEARCH_OPTIONS } from '../../constants';
import { FormGroup, FullWidthLabel, Label, StyledTextInput } from '../cs01-form.styled';
import type { FieldArrayCustomProps, FieldArrayItemProps } from '../types';

const getClassificationCodeSelect: FC<React.PropsWithChildren<FieldArrayCustomProps>> = ({
  name,
  order,
  options = [],
}) => (
  <Fragment key={order}>
    <Label htmlFor={name}>Classification code {order}</Label>
    <Select
      placeholder="Start typing"
      options={options}
      height={40}
      name={name}
      searchConfiguration={SIC_SELECT_SEARCH_OPTIONS}
      useValueAsPrefix
      isUnselectAvailable
    />
  </Fragment>
);

const getClassificationCodeInput: FC<React.PropsWithChildren<FieldArrayCustomProps>> = ({
  order,
  value,
  active,
  name,
}) => {
  const label = `${CLASSIFICATION_CODE_LABEL} ${order}`;

  return (
    <Fragment key={order}>
      <FullWidthLabel htmlFor={name}>{label}</FullWidthLabel>
      <StyledTextInput name={name} disabled value={value} active={active} />
    </Fragment>
  );
};

const renderField: FC<React.PropsWithChildren<FieldArrayItemProps>> = ({
  active,
  container: Container = FormGroup,
  renderField: renderFieldComponent,
  viewValue,
  index,
  options,
  name: fieldName,
  ...buttonProps
}) => {
  const name = `${fieldName}.${index}`;
  const order = index + 1;

  return (
    <Container key={name}>
      {renderFieldComponent({ name, order, value: viewValue, active, options })}
      <ArrayActionButtonContainer {...buttonProps} />
    </Container>
  );
};

export { renderField, getClassificationCodeSelect, getClassificationCodeInput };
