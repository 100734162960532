import styled from 'styled-components';

import Checkbox from 'shared-parts/components/checkbox';
import { CheckboxInput } from 'shared-parts/components/checkbox/checkbox.styled';
import Loader from 'shared-parts/components/loader/loader';
import { Table } from 'shared-parts/components/table';
import FormTabs from 'shared-parts/components/tabs/form-tabs';
import { Tab } from 'shared-parts/components/tabs/tabs.styled';
import { COLORS, FONTS } from 'shared-parts/constants';
import CheckboxIconWhite from 'shared-parts/images/checkbox-icon-white.svg';

const { neutral800, primary, neutral200, neutral300, neutral500, systemGreen } = COLORS;

export const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftBlock = styled.div`
  display: flex;
`;

export const RightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableComponent = styled(Table)`
  margin-top: 35px;
`;

export const DownloadCSV = styled.button`
  background: transparent;
  border: 0;
  color: ${primary};
  cursor: pointer;
  font-family: ${FONTS.mainFont};
  font-size: 14px;
  padding: 0;
  margin-right: 20px;
`;

export const StyledLoader = styled(Loader)`
  background: transparent;
`;

export const StyledFormTabs = styled(FormTabs)`
  padding: 0 20px;
  position: sticky;
  top: 0;
  background-color: ${neutral200};
  z-index: 3;

  ${Tab} {
    margin: 20px 11px 0 0;
    white-space: nowrap;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

export const SectionContainer = styled.div`
  padding: 30px 30px 30px 20px;

  :not(:last-child) {
    border-bottom: 1px solid ${neutral300};
  }
`;

export const TableContainer = styled(SectionContainer)`
  padding: 30px 0;
`;

export const FormContainer = styled(SectionContainer)<{
  cols?: number;
}>`
  display: grid;
  gap: 30px 14px;
  ${({ cols }) => (cols ? `grid-template-columns: repeat(${cols}, 1fr);` : '')}
  flex-wrap: wrap;
`;

export const FormGroup = styled.div<{
  span?: number;
}>`
  display: inline-block;
  width: 100%;
  ${({ span }) => (span ? `grid-column: span ${span};` : '')}
  position: relative;
`;

export const Label = styled.label<{
  htmlFor?: string;
  isRequired?: boolean;
}>`
  color: ${primary};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;

  &:after {
    content: '${({ isRequired }) => (isRequired ? '*' : '')}';
  }
`;

export const Text = styled.p`
  color: ${primary};
  font-size: 14px;
  line-height: 19px;
  margin: 0;
`;

export const Note = styled.span`
  color: ${primary};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledCheckBox = styled(Checkbox)`
  margin-bottom: 0;

  label {
    padding-left: 20px;
    font-size: 14px;
    color: ${primary};
  }

  ${CheckboxInput} {
    & ~ span {
      background-color: ${neutral500};
      height: 14px;
      width: 14px;
      border: 2px solid transparent;
      border-radius: 2px;
      top: 3px;

      &:before {
        background: url(${CheckboxIconWhite}) no-repeat;
        content: '';
        display: inline-block;
        left: 2px;
        position: absolute;
        top: 1px;
        height: 8px;
        width: 6px;
      }
    }

    &:checked ~ span {
      background-color: ${systemGreen};
    }
  }
`;

export const ModalContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px;
  font-family: ${FONTS.mainFont};
`;

export const IconContainer = styled.div`
  margin-bottom: 25px;
`;

export const StyledParagraph = styled.p`
  color: ${neutral800};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  :not(:first-of-type) {
    margin-top: 8px;
  }
`;
