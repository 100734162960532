import sanitize from 'shared-parts/helpers/html/sanitize';

import { TaglineText } from './tagline.styled';

const Tagline = ({ tagline = {}, isImageExpanded, displayTaglines }) =>
  displayTaglines ? (
    <TaglineText
      dangerouslySetInnerHTML={{ __html: sanitize(tagline.content) }}
      isImageExpanded={isImageExpanded}
    />
  ) : (
    <></>
  );

export default Tagline;
