import * as constants from 'app-state/constants';

// eslint-disable-next-line
export const templatesReducer = (state = {}, { type, data, error }) => {
  switch (type) {
    case constants.GET_TEMPLATES_URL:
      return { loading: true, data: {}, error: null };
    case constants.GET_TEMPLATES_URL_SUCCESS:
      return { loading: false, data, error: null };
    case constants.GET_TEMPLATES_URL_ERROR:
      return { loading: false, data: {}, error };
    default:
      return state;
  }
};
