import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import { GET_COMPANY_SUCCESS } from 'app-state/constants/companies';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import { getCompany } from 'app-state/selectors/companies';

import GrantsRemovedModal from 'modules/company/equity-administration/grant-holders/components/grants-removed-modal';
import OptionsExercisedModal from 'modules/company/equity-administration/grant-holders/components/options-exercised-modal';
import { DOCUMENT_TYPES } from 'modules/company/equity-administration/grant-holders/constants';
import API from 'constants/api';
import { request } from 'helpers';
import ModalFailed from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

import { openHelloSignClientIframe } from './hello-sign-client';

const SIGNATURE_REQUESTS_ERROR_CODE = 1000;

function* makeGetShareOptionsCall(companyData) {
  const { data } = yield call(request, API.ShareOptions(companyData.uuid));
  const mappedData = {
    ...data,
    items: data.items
      ? data.items.map(({ available, granted, limit, shareOptionPlanUuid, planName, type }) => ({
          available,
          granted,
          limit,
          sharePlanUuid: shareOptionPlanUuid,
          planName,
          type,
        }))
      : [],
  };

  yield put(actions.getShareOptionsSuccess(mappedData));
}

function* getShareOptions() {
  try {
    const { loading, data: companyData } = yield select(getCompany);

    if (loading) {
      yield take(GET_COMPANY_SUCCESS);

      const { data } = yield select(getCompany);

      yield* makeGetShareOptionsCall(data);
    } else {
      yield* makeGetShareOptionsCall(companyData);
    }
  } catch (e) {
    yield put(actions.getShareOptionsError(e));
  }
}

function* getShareOptionsWatcher() {
  yield takeEvery(constants.GET_SHARE_OPTIONS, getShareOptions);
}

function* getHolders({ payload: { companyUuid, sharePlanUuid, statuses } }) {
  try {
    // TODO: This timeout is a temporary fix to patch over an optimisation issue.
    const { data } = yield call(
      request,
      API.Holders({ companyUuid, sharePlanUuid, statuses }),
      'GET',
      null,
      {
        timeout: 1000 * 30,
      },
    );

    yield put(actions.getHoldersSuccess(data));
  } catch (e) {
    yield put(actions.getHoldersError(e));
  }
}

function* getHoldersWatcher() {
  yield takeEvery(constants.GET_HOLDERS, getHolders);
}

function* getGrantHolders({ companyUuid, shareOptionUuid }) {
  try {
    const { data } = yield call(request, API.GrantHolders(companyUuid, shareOptionUuid));

    yield put(actions.getGrantHoldersSuccess(data));
  } catch (e) {
    yield put(actions.getGrantHoldersError(e));
  }
}

function* getGrantHoldersWatcher() {
  yield takeEvery(constants.GET_GRANT_HOLDERS, getGrantHolders);
}

function* getGrantHoldersCapital({ companyUuid }) {
  try {
    const { data } = yield call(request, API.GrantHoldersCapital(companyUuid));

    yield put(actions.getGrantHoldersCapitalSuccess(data));
  } catch (e) {
    yield put(actions.getGrantHoldersCapitalError(e));
  }
}

function* getGrantHoldersCapitalWatcher() {
  yield takeEvery(constants.GET_GRANT_HOLDERS_CAPITAL, getGrantHoldersCapital);
}

function* removeUnvestedOptions({
  companyUuid,
  sharePlanUuid,
  grantHolderUuid,
  hideModalAndGetHolders,
}) {
  try {
    const { data } = yield call(
      request,
      API.RemoveUnvestedOptions(companyUuid, sharePlanUuid, grantHolderUuid),
      'DELETE',
    );
    const modalParams = yield {
      showHeader: false,
      closable: true,
      component: GrantsRemovedModal,
      hideModal: hideModalAndGetHolders,
    };

    yield put(actions.showModal(modalParams));
    yield put(actions.hideSideForm());
    yield put(actions.removeUnvestedOptionsSuccess(data));
  } catch (e) {
    yield put(actions.removeUnvestedOptionsError(e));
  }
}

function* removeUnvestedOptionsWatcher() {
  yield takeEvery(constants.REMOVE_UNVESTED_OPTIONS, removeUnvestedOptions);
}

function* getShareOptionPlan({ companyUuid, sharePlanUuid, openEditForm, tabKey }) {
  try {
    const { data } = yield call(request, API.ShareOptionPlan(companyUuid, sharePlanUuid));
    const { data: limitsData } = yield call(
      request,
      API.AvailableShareOptionsQuantity(companyUuid, sharePlanUuid),
    );
    const dataWithLimits = { ...data, ...limitsData };

    yield put(actions.getShareOptionPlanSuccess(dataWithLimits));

    if (openEditForm) {
      yield openEditForm(dataWithLimits, tabKey);
    }
  } catch (e) {
    yield put(actions.getShareOptionPlanError(e));
  }
}

function* getShareOptionPlanWatcher() {
  yield takeEvery(constants.GET_SHARE_OPTION_PLAN, getShareOptionPlan);
}

function* createShareOption({
  companyUuid,
  data,
  hideSideFormAndShowModal,
  setSubmitting,
  setErrors,
}) {
  try {
    const {
      data: { uuid },
    } = yield call(request, API.ShareOptions(companyUuid), 'POST', data);
    yield hideSideFormAndShowModal('Option Plan Created Successfully', uuid);
    yield getShareOptions({ companyUuid });
  } catch (e) {
    yield setSubmitting(false);

    if (e.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
          modalWidth: 800,
        }),
      );

      yield setErrors(e.response.details);
    }
  }
}

function* createShareOptionWatcher() {
  yield takeEvery(constants.CREATE_SHARE_OPTION_PLAN, createShareOption);
}

function* updateShareOption({
  companyUuid,
  sharePlanUuid,
  data,
  hideSideFormAndShowModal,
  setError,
}) {
  yield put(showLoader());

  try {
    yield call(request, API.ShareOptionPlan(companyUuid, sharePlanUuid), 'PUT', data, {
      timeout: 25000,
    });
    yield hideSideFormAndShowModal('OPTION PLAN UPDATED SUCCESSFULLY');
    yield getShareOptions({ companyUuid });
  } catch (e) {
    if (e.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
          modalWidth: 800,
        }),
      );
    }

    yield setError(e);
  }

  yield put(hideLoader());
}

function* updateShareOptionWatcher() {
  yield takeEvery(constants.UPDATE_SHARE_OPTION_PLAN, updateShareOption);
}

function* getShareOptionData({ companyUuid, sharePlanUuid, grantId }) {
  try {
    const { data } = yield call(request, API.ShareOptionData(companyUuid, sharePlanUuid, grantId));

    yield put(actions.getShareOptionDataSuccess(data));
  } catch (e) {
    yield put(actions.getShareOptionDataError(e));
  }
}

function* getShareOptionDataWatcher() {
  yield takeEvery(constants.GET_SHARE_OPTION_DATA, getShareOptionData);
}

function* exerciseShareOptions({
  companyUuid,
  sharePlanUuid,
  grantId,
  grantHolderName,
  exerciseData,
}) {
  yield put(showLoader());

  try {
    const { data } = yield call(
      request,
      API.ExerciseShareOptions(companyUuid, sharePlanUuid, grantId),
      'PUT',
      exerciseData,
      { timeout: 180000 },
    );

    yield put(actions.exerciseShareOptionsSuccess(data));
    yield put(
      actions.showModal({
        showHeader: false,
        closable: true,
        component: OptionsExercisedModal,
        grantHolderName,
        entityName: 'component',
      }),
    );
    yield put(actions.getHolders({ companyUuid, sharePlanUuid }));
  } catch (e) {
    yield put(actions.exerciseShareOptionsError(e));
    if (e.status !== 403) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
        }),
      );
    }
  }

  yield put(hideLoader());
}

function* exerciseShareOptionsWatcher() {
  yield takeEvery(constants.EXERCISE_SHARE_OPTIONS, exerciseShareOptions);
}

function* addShareOptionPlanDocument({ companyUuid, sharePlanUuid, fileData }) {
  try {
    const { data } = yield call(
      request,
      API.ShareOptionPlanDocument(companyUuid, sharePlanUuid),
      'POST',
      fileData,
      { timeout: 180000 },
    );

    yield put(actions.addShareOptionPlanDocumentSuccess(data));
  } catch (e) {
    yield put(actions.addShareOptionPlanDocumentError(e));
  }
}

function* addShareOptionPlanDocumentWatcher() {
  yield takeEvery(constants.ADD_SHARE_OPTION_PLAN_DOCUMENT, addShareOptionPlanDocument);
}

function* deleteShareOptionPlanDocument({ companyUuid, sharePlanUuid, id }) {
  try {
    yield call(
      request,
      API.DeleteShareOptionPlanDocument(companyUuid, sharePlanUuid, id),
      'DELETE',
    );

    yield put(actions.deleteShareOptionPlanDocumentSuccess(id));
  } catch (e) {
    yield put(actions.deleteShareOptionPlanDocumentError(e));
  }
}

function* deleteShareOptionPlanDocumentWatcher() {
  yield takeEvery(constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT, deleteShareOptionPlanDocument);
}

function* addNewGrant({ companyUuid, sharePlanUuid, data, hideSideFormAndShowModal, setErrors }) {
  yield put(showLoader());

  try {
    const { data: newGrantData } = yield call(
      request,
      API.AddNewGrant(companyUuid, sharePlanUuid),
      'POST',
      data,
      { timeout: 25000 },
    );
    yield put(actions.addNewGrantSuccess());
    yield hideSideFormAndShowModal(newGrantData.id);
    yield put(actions.getHolders({ companyUuid, sharePlanUuid }));
  } catch (e) {
    yield put(actions.addNewGrantError(e));

    if (e.status === 400) {
      const {
        response: { details },
      } = e;
      const email =
        details.grantHolder && details.grantHolder[0] && details.grantHolder[0][0] === 'email'
          ? details.grantHolder[0][1]
          : '';

      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
          modalWidth: 800,
        }),
      );

      setErrors({
        ...e.response.details,
        email,
      });
    }
  }

  yield put(hideLoader());
}

function* addNewGrantWatcher() {
  yield takeEvery(constants.ADD_NEW_GRANT, addNewGrant);
}

function* getGrant({ companyUuid, sharePlanUuid, grantId }) {
  try {
    const { data: grant } = yield call(
      request,
      API.RetrieveGrant(companyUuid, sharePlanUuid, grantId),
    );

    yield put(actions.getGrantSuccess(grant));
  } catch (e) {
    yield put(actions.getGrantError(e));
  }
}

function* getGrantWatcher() {
  yield takeEvery(constants.GET_GRANT, getGrant);
}

function* editGrant({
  companyUuid,
  sharePlanUuid,
  grantId,
  grant,
  hideSideFormAndShowModal,
  setSubmitting,
  setErrors,
  hideOverlay,
}) {
  yield put(showLoader());

  try {
    const { data } = yield call(
      request,
      API.EditGrant(companyUuid, sharePlanUuid, grantId),
      'PUT',
      grant,
      { timeout: 25000 },
    );

    yield put(actions.editGrantSuccess(data));
    yield hideSideFormAndShowModal();
    yield put(actions.getHolders({ companyUuid, sharePlanUuid }));
  } catch (e) {
    yield setSubmitting(false);
    yield put(actions.editGrantError(e));

    if (e.status === 400) {
      const msg = e.response.details.vestingSchedulesLineItems?.[0]
        ? e.response.details.vestingSchedulesLineItems?.[0]
        : undefined;

      const message = typeof msg === 'string' ? msg : undefined;
      setErrors(e.response.details);
      hideOverlay();

      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
          modalWidth: 800,
          message,
        }),
      );
    }
  }

  yield put(hideLoader());
}

function* editGrantWatcher() {
  yield takeEvery(constants.EDIT_GRANT, editGrant);
}

function* getGrantHolderDetails({ payload: { companyUuid, uuid, email } }) {
  try {
    const { data } = yield call(request, API.GrantHolderDetails(companyUuid, uuid, email));
    yield put(actions.getGrantHolderDetailsSuccess(data));
  } catch (e) {
    yield put(actions.hideSideForm());
    yield put(actions.getGrantHolderDetailsError(e));
  }
}

function* getGrantHolderDetailsWatcher() {
  yield takeEvery(constants.GET_GRANT_HOLDER_DETAILS, getGrantHolderDetails);
}

function* updateGrantHolderDetails({
  companyUuid,
  sharePlanUuid,
  grantHolderUuid,
  data,
  setErrors,
  setSubmitting,
  hideSideFormAndShowModal,
}) {
  try {
    yield call(request, API.GrantHolderDetails(companyUuid, grantHolderUuid), 'PUT', data);
    yield hideSideFormAndShowModal();
    if (sharePlanUuid) {
      yield put(actions.getHolders({ companyUuid, sharePlanUuid }));
    }
  } catch (e) {
    setSubmitting(false);

    if (e.status === 400) {
      setErrors(e.response.details);
    }
  }
}

function* updateGrantHolderDetailsWatcher() {
  yield takeEvery(constants.UPDATE_GRANT_HOLDER_DETAILS, updateGrantHolderDetails);
}

function* deleteGrant({
  companyUuid,
  sharePlanUuid,
  grantHolderId,
  hideSideFormAndCloseModal,
  openGrantDeletedModal,
}) {
  try {
    yield call(request, API.DeleteGrant(companyUuid, sharePlanUuid, grantHolderId), 'DELETE');
    yield hideSideFormAndCloseModal();
    yield openGrantDeletedModal();
    yield put(actions.getHolders({ companyUuid, sharePlanUuid }));
  } catch (e) {
    yield put(actions.deleteGrantError(e));
    if (e.status !== 403) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ModalFailed,
        }),
      );
    }
  }
}

function* deleteGrantWatcher() {
  yield takeEvery(constants.DELETE_GRANT, deleteGrant);
}

function* addGrantDocument({ companyUuid, sharePlanUuid, grantId, fileData, setError }) {
  try {
    const { data } = yield call(
      request,
      API.AddGrantDocument(companyUuid, sharePlanUuid, grantId),
      'POST',
      fileData,
      { timeout: 180000 },
    );

    if (data.documentType === DOCUMENT_TYPES.AGREEMENT) {
      yield* openHelloSignClientIframe({
        editUrl: data.editUrl,
        doneActions: [actions.getGrant({ companyUuid, sharePlanUuid, grantId })],
      });
    }
    yield put(actions.addGrantDocumentSuccess(data));
  } catch (e) {
    const errorResponse = e.response;
    let errorMessage;
    if (errorResponse && errorResponse.errorCode === SIGNATURE_REQUESTS_ERROR_CODE) {
      errorMessage = errorResponse
        ? errorResponse.details.signatureRequests[0]
        : defaultErrorMessage;
    } else {
      errorMessage = errorResponse ? errorResponse.details.file[0] : defaultErrorMessage;
    }

    setError(errorMessage);
    yield put(actions.addGrantDocumentError(e));
  }
}

function* addGrantDocumentWatcher() {
  yield takeEvery(constants.ADD_GRANT_DOCUMENT, addGrantDocument);
}

function* deleteGrantDocument({ companyUuid, sharePlanUuid, grantId, documentId, setError }) {
  try {
    yield call(
      request,
      API.DeleteGrantDocument(companyUuid, sharePlanUuid, grantId, documentId),
      'DELETE',
    );
    yield put(actions.deleteGrantDocumentSuccess({ documentId }));
    yield put(actions.hideModal());
  } catch (e) {
    setError(e.response.details);
  }
}

function* deleteGrantDocumentWatcher() {
  yield takeEvery(constants.DELETE_GRANT_DOCUMENT, deleteGrantDocument);
}

function* getGrantTransactionalHistory({ companyUuid, sharePlanUuid, grantId }) {
  try {
    const { data } = yield call(
      request,
      API.GetGrantTransactionalHistory(companyUuid, sharePlanUuid, grantId),
    );
    yield put(actions.getGrantTransactionalHistorySuccess(data));
  } catch (e) {
    yield put(actions.getGrantTransactionalHistoryError(e));
  }
}

function* getGrantTransactionalHistoryWatcher() {
  yield takeEvery(constants.GET_GRANT_TRANSACTIONAL_HISTORY, getGrantTransactionalHistory);
}

function* getGrantHoldersEmails({ payload }) {
  const { companyUuid } = payload;

  try {
    const { data } = yield call(request, API.GrantHoldersEmails(companyUuid));

    yield put(actions.getGrantHoldersEmailsSuccess({ data }));
  } catch (error) {
    yield put(actions.getGrantHoldersEmailsError({ error }));
  }
}

function* getGrantHoldersEmailsWatcher() {
  yield takeEvery(constants.GET_GRANT_HOLDERS_EMAILS, getGrantHoldersEmails);
}

function* searchHolders({ payload: { companyUuid, email } }) {
  try {
    const { data } = yield call(request, API.GrantHolderSearch(companyUuid, email));

    yield put(actions.searchHoldersSuccess(data));
  } catch (error) {
    yield put(actions.searchHoldersError(error));
  }
}

function* searchHoldersWatcher() {
  yield takeEvery(constants.SEARCH_HOLDERS, searchHolders);
}

export {
  getShareOptions,
  getShareOptionsWatcher,
  getHolders,
  getHoldersWatcher,
  getGrantHolders,
  getGrantHoldersCapital,
  getGrantHoldersWatcher,
  getGrantHoldersCapitalWatcher,
  removeUnvestedOptions,
  removeUnvestedOptionsWatcher,
  createShareOption,
  createShareOptionWatcher,
  addShareOptionPlanDocument,
  addShareOptionPlanDocumentWatcher,
  deleteShareOptionPlanDocument,
  deleteShareOptionPlanDocumentWatcher,
  getShareOptionPlan,
  getShareOptionPlanWatcher,
  updateShareOption,
  updateShareOptionWatcher,
  getShareOptionData,
  getShareOptionDataWatcher,
  exerciseShareOptions,
  exerciseShareOptionsWatcher,
  addNewGrant,
  addNewGrantWatcher,
  getGrant,
  getGrantWatcher,
  editGrant,
  editGrantWatcher,
  getGrantHolderDetails,
  getGrantHolderDetailsWatcher,
  updateGrantHolderDetails,
  updateGrantHolderDetailsWatcher,
  deleteGrant,
  deleteGrantWatcher,
  addGrantDocument,
  addGrantDocumentWatcher,
  deleteGrantDocument,
  deleteGrantDocumentWatcher,
  getGrantTransactionalHistory,
  getGrantTransactionalHistoryWatcher,
  getGrantHoldersEmailsWatcher,
  searchHolders,
  searchHoldersWatcher,
};
