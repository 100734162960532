import escape from 'escape-html';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { showModal } from 'app-state/actions';
import { debounceQueue } from 'app-state/effects';
import { getCompany } from 'app-state/selectors';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';

function* getDraftCommunication({ companyUuid, communicationUuid }) {
  try {
    const { data } = yield call(request, API.Communication(companyUuid, communicationUuid));

    yield put(actions.getDraftCommunicationSuccess(data));
  } catch (e) {
    yield put(actions.getDraftCommunicationError(e));
  }
}

function* getDraftCommunicationWatcher() {
  yield takeEvery(constants.GET_DRAFT_COMMUNICATION, getDraftCommunication);
}

function* getPublishedCommunication({ companyUuid, communicationUuid }) {
  try {
    const { data } = yield call(request, API.Communication(companyUuid, communicationUuid));
    yield put(actions.getPublishedCommunicationSuccess(data));
  } catch (e) {
    yield put(actions.getPublishedCommunicationError(e));
  }
}

function* getPublishedCommunicationWatcher() {
  yield takeEvery(constants.GET_PUBLISHED_COMMUNICATION, getPublishedCommunication);
}

function* initialiseDraftCommunication(companyUuid) {
  try {
    const { data: createdDraft } = yield call(request, API.Communications(companyUuid), 'POST');
    yield put(actions.createDraftCommunicationSuccess(createdDraft));

    return createdDraft.uuid;
  } catch (e) {
    yield put(actions.createDraftCommunicationError(e));
    throw e;
  }
}

function* saveDraftCommunication({ communication }) {
  try {
    const company = yield select(getCompany);
    const companyUuid = company.data.uuid;

    const selectedDraft = yield select(selectors.getDraftCommunication);
    let communicationUuid = selectedDraft.data.uuid;

    if (!communicationUuid) {
      communicationUuid = yield call(initialiseDraftCommunication, companyUuid);
    }

    const { data: updatedDraft } = yield call(
      request,
      API.Communication(companyUuid, communicationUuid),
      'PUT',
      {
        ...communication,
        body: escape(communication.body),
      },
    );

    yield put(actions.saveDraftCommunicationSuccess(updatedDraft));
    yield put(actions.getCommunications(companyUuid));
  } catch (e) {
    yield put(actions.saveDraftCommunicationError(e.response || e));
  }
}

function* saveDraftCommunicationWatcher() {
  yield debounceQueue(1000, constants.SAVE_DRAFT_COMMUNICATION, saveDraftCommunication);
}

function* deleteDraftCommunication({ companyUuid, communicationUuid }) {
  try {
    yield call(request, API.Communication(companyUuid, communicationUuid), 'DELETE');
    yield put(actions.getCommunications(companyUuid));

    yield put(actions.resetDraftCommunication());
  } catch (e) {
    yield put(actions.deleteDraftCommunicationError(e));
  }
}

function* deleteDraftCommunicationWatcher() {
  yield takeEvery(constants.DELETE_DRAFT_COMMUNICATION, deleteDraftCommunication);
}

function* draftCommunicationAddAttachment({ companyUuid, communicationUuid, fileData }) {
  try {
    let newCommunicationUuid;
    if (!communicationUuid) {
      newCommunicationUuid = yield call(initialiseDraftCommunication, companyUuid);
    }

    const { data } = yield call(request, API.BinaryUploads(), 'POST', fileData, {
      timeout: 180000,
    });

    const { data: attachment } = yield call(
      request,
      API.CommunicationAttachments(companyUuid, communicationUuid || newCommunicationUuid),
      'POST',
      data,
      {
        timeout: 180000,
      },
    );

    yield put(actions.draftCommunicationAddAttachmentSuccess(attachment));
  } catch (e) {
    const errorMessage = e.response.details?.file?.[0] || defaultErrorMessage;
    yield put(actions.draftCommunicationAddAttachmentError(errorMessage));
  }
}

function* draftCommunicationAddAttachmentWatcher() {
  yield takeEvery(constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT, draftCommunicationAddAttachment);
}

function* draftCommunicationDeleteAttachment({ companyUuid, communicationUuid, attachmentId }) {
  try {
    yield call(
      request,
      API.CommunicationAttachment(companyUuid, communicationUuid, attachmentId),
      'DELETE',
    );
    yield put(actions.draftCommunicationDeleteAttachmentSuccess(attachmentId));
  } catch (e) {
    yield put(actions.draftCommunicationDeleteAttachmentError(e));
  }
}

function* draftCommunicationDeleteAttachmentWatcher() {
  yield takeEvery(
    constants.DRAFT_COMMUNICATION_DELETE_ATTACHMENT,
    draftCommunicationDeleteAttachment,
  );
}

function* draftCommunicationAddResponse({ expiryDate, companyUuid, communicationUuid }) {
  try {
    const params = {
      responseExpiredAt: expiryDate,
      communicationResponseTypeIds: ['0'], // 0 is the id of approve/reject response type
    };

    const { data: responseTemplate } = yield call(
      request,
      API.CommunicationAddResponse(companyUuid, communicationUuid),
      'POST',
      params,
      { timeout: 180000 },
    );

    const responseData = {
      responseExpiredAt: expiryDate,
      communicationResponseTemplates: responseTemplate.items,
    };

    yield put(actions.draftCommunicationAddResponseSuccess(responseData));
  } catch (e) {
    const errorMessage = e.response ? e.response.details.responseExpiredAt[0] : defaultErrorMessage;
    yield put(actions.draftCommunicationAddResponseError(errorMessage));
  }
}

function* draftCommunicationAddResponseWatcher() {
  yield takeEvery(constants.DRAFT_COMMUNICATION_ADD_RESPONSE, draftCommunicationAddResponse);
}

function* draftCommunicationDeleteResponse({ responseUuid, companyUuid, communicationUuid }) {
  try {
    yield call(
      request,
      API.CommunicationDeleteResponse(companyUuid, communicationUuid, responseUuid),
      'DELETE',
    );

    yield put(actions.draftCommunicationDeleteResponseSuccess());
  } catch (e) {
    yield put(actions.draftCommunicationDeleteResponseError(e));
  }
}

function* draftCommunicationDeleteResponseWatcher() {
  yield takeEvery(constants.DRAFT_COMMUNICATION_DELETE_RESPONSE, draftCommunicationDeleteResponse);
}

function* publishDraftCommunication({ companyUuid, communicationUuid }) {
  try {
    const { data } = yield call(
      request,
      API.CommunicationPublish(companyUuid, communicationUuid),
      'POST',
    );

    yield put(actions.publishDraftCommunicationSuccess(data));
    yield put(actions.resetDraftCommunication());

    yield put(
      showModal({
        showHeader: false,
        closable: true,
        component: SuccessModal,
        title: 'COMMUNICATION SENT',
        modalWidth: 580,
        additionalText: (
          <>
            You can view your communications via
            <br />
            security holder communications.
          </>
        ),
      }),
    );

    yield put(actions.getCommunications(companyUuid));
  } catch (e) {
    const { status, response } = e;
    const errorMessage =
      status === 400 ? Object.values(response.details).flat()[0] : 'Something went wrong.';
    yield put(actions.publishDraftCommunicationError(errorMessage));
  }
}

function* publishDraftCommunicationWatcher() {
  yield takeEvery(constants.PUBLISH_DRAFT_COMMUNICATION, publishDraftCommunication);
}

function* getCommunications({ companyUuid }) {
  try {
    const { data } = yield call(request, API.Communications(companyUuid));
    yield put(actions.getCommunicationsSuccess(data));
  } catch (e) {
    yield put(actions.getCommunicationsError(e));
  }
}

function* getCommunicationsWatcher() {
  yield takeEvery(constants.GET_COMMUNICATIONS, getCommunications);
}

function* getResponsesStatistics({ companyUuid, communicationUuid }) {
  try {
    const { data } = yield call(
      request,
      API.CommunicationResponsesStatistics(companyUuid, communicationUuid),
    );
    yield put(actions.getCommunicationResponsesStatisticsSuccess(data));
  } catch (e) {
    yield put(actions.getCommunicationResponsesStatisticsError(e));
  }
}

function* getCommunicationResponsesStatisticsWatcher() {
  yield takeEvery(constants.GET_COMMUNICATION_RESPONSES_STATISTICS, getResponsesStatistics);
}

function* exportResponsesStatistics({ companyUuid, communicationUuid }) {
  try {
    const { data } = yield call(
      request,
      API.CommunicationResponsesStatisticsExport(companyUuid, communicationUuid),
      'GET',
      null,
      {
        to: 'blob',
        contentType: 'text/csv',
      },
    );

    downloadBlob(data, `${communicationUuid}_statistics_export.csv`);

    yield put(actions.exportCommunicationResponsesStatisticsSuccess());
  } catch (e) {
    console.error('Error exporting communication responses', e);
    yield put(actions.exportCommunicationResponsesStatisticsError(e));
  }
}

export function* exportCommunicationResponsesStatisticsWatcher() {
  yield takeEvery(constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS, exportResponsesStatistics);
}

function* getAggregatedResponsesStatistics({ companyUuid, communicationUuid, page }) {
  try {
    const apiUrl = API.AggregatedCommunicationResponsesStatistics(
      companyUuid,
      communicationUuid,
      page,
    );
    const { data } = yield call(request, apiUrl);
    yield put(actions.getAggregatedCommunicationResponsesStatisticsSuccess(data));
  } catch (e) {
    yield put(actions.getAggregatedCommunicationResponsesStatisticsError(e));
  }
}

function* getAggregatedResponsesStatisticsWatcher() {
  yield takeEvery(
    constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS,
    getAggregatedResponsesStatistics,
  );
}

function* fetchShareholdersEmails({ payload: { companyUuid } }) {
  try {
    const { data } = yield call(request, API.ShareholdersEmails(companyUuid));

    yield put(actions.fetchShareholdersEmailsSuccess(data));
  } catch (error) {
    yield put(actions.fetchShareholdersEmailsError(error));
  }
}

function* fetchShareholdersEmailsWatcher() {
  yield takeEvery(constants.FETCH_SHAREHOLDERS_EMAILS, fetchShareholdersEmails);
}

export {
  getDraftCommunication,
  getDraftCommunicationWatcher,
  getPublishedCommunicationWatcher,
  saveDraftCommunicationWatcher as updateDraftCommunicationWatcher,
  deleteDraftCommunication,
  deleteDraftCommunicationWatcher,
  draftCommunicationAddAttachment,
  draftCommunicationAddAttachmentWatcher,
  draftCommunicationDeleteAttachment,
  draftCommunicationDeleteAttachmentWatcher,
  draftCommunicationAddResponse,
  draftCommunicationAddResponseWatcher,
  draftCommunicationDeleteResponseWatcher,
  publishDraftCommunicationWatcher,
  getCommunicationsWatcher,
  getCommunicationResponsesStatisticsWatcher,
  getAggregatedResponsesStatistics,
  getAggregatedResponsesStatisticsWatcher,
  fetchShareholdersEmailsWatcher,
};
