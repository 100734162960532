import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, neutral800 } = COLORS;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 580px;
  height: 350px;
`;
const ModalText = styled.div`
  text-align: center;
  margin: 20px;
  color: ${neutral800};
`;

const ShareHolderBox = styled.span`
  font-family: ${FONTS.mainFont};
  font-weight: bold;
`;

const ModalTitle = styled.div`
  font-size: 35px;
  margin: 30px 0 0 0;
  font-weight: 300;
  color: ${primary};
  font-family: ${FONTS.oswaldFont};
`;

export { ModalWrapper, ModalTitle, ModalText, ShareHolderBox };
