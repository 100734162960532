import { FC } from 'react';

import styled from 'styled-components';

import PoweredByImage from 'images/powered-by-globacap.svg';

const PoweredByImgContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const PoweredByImg = styled.img`
  padding: 35px 0px;
  max-width: 149px;
`;

const GFIPoweredByGlobacap: FC<React.PropsWithChildren<unknown>> = () => (
  <PoweredByImgContainer>
    <PoweredByImg src={PoweredByImage} alt="Powered by Globacap" />
  </PoweredByImgContainer>
);

export default GFIPoweredByGlobacap;
