import styled from 'styled-components';

import { Screens } from 'shared-parts/constants/screens';
import ChevronsPattern from 'shared-parts/images/chevrons-pattern.svg';

import { Props } from './types';

const calcWidth =
  (coefficient: number) =>
  ({ width, unit }: Props) =>
    `${width * coefficient}${unit}`;

const setPosition = ({ position, width, unit }: Props) => {
  switch (position) {
    case 'right':
      return `
        right: ${calcWidth(0.95)({ width, unit })};
        clip-path: polygon(
          50% 0%, 100% 0%,
          100% 100%, 29% 100%,
          29% 75%, 100% 75%,
          100% 50%, 0% 50%,
          0% 25%, 50% 25%
        );
      `;
    case 'left':
      return `
        left: ${calcWidth(0.95)({ width, unit })};
        transform: scaleY(-1);
        clip-path: polygon(
          0% 0%, 58% 0%,
          58% 25%, 0% 25%,
          0% 50%, 100% 50%,
          100% 75%, 35% 75%,
          35% 100%, 0% 100%
        );
      `;
    default:
      return '';
  }
};

const setFullSizePosition = ({ position }: Props) => `
  ${position}: 0;
  ${position === 'left' ? 'transform: scaleY(-1);' : ''};
`;

const Chevrons = styled.div<Props>`
  background-image: url(${ChevronsPattern});
  position: absolute;
  bottom: ${({ bottom = '2rem' }) => bottom};
  width: ${calcWidth(1)};
  height: ${calcWidth(0.625)};

  ${props => (props.isFullSize ? setPosition(props) : setFullSizePosition(props))}

  @media only screen and (max-width: ${Screens.sm}) {
    display: none;
  }
`;

export default Chevrons;
