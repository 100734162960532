const SettingIcon = ({ fillColor = '#000', height = 13, width = 22 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22.36 13.04"
    fill={fillColor}
  >
    <path
      d="M7.77,6.76,6.23,5.48.82,12l5.41,6.52,1.54-1.28L3.42,12ZM7,13H9V11H7Zm10-2H15v2h2Zm-6,2h2V11H11Zm6.77-7.52L16.23,6.76,20.58,12l-4.35,5.24,1.54,1.28L23.18,12Z"
      transform="translate(-0.82 -5.48)"
    />
  </svg>
);

export default SettingIcon;
