import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const Container = styled.div<{ paddingRight?: string }>`
  color: ${COLORS.neutral800};
  margin-bottom: 24px;
  padding-right: ${({ paddingRight = '' }) => paddingRight};
`;

export { Container }; // eslint-disable-line
