import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const myAccountDetails = getReducerState();

export const myAccountDetailsReducer = (state = myAccountDetails, action) => {
  switch (action.type) {
    case constants.GET_MY_ACCOUNT_DETAILS:
      return myAccountDetails;
    case constants.GET_MY_ACCOUNT_DETAILS_SUCCESS:
      return getReducerState({
        loading: false,
        data: action.data,
      });
    case constants.GET_MY_ACCOUNT_DETAILS_ERROR:
      return getReducerState({
        loading: false,
        data: state.data,
        error: action.error,
      });
    default:
      return state;
  }
};

export const accountUsers = getReducerState({ data: [] });

export const accountUsersReducer = (state = accountUsers, action) => {
  switch (action.type) {
    case constants.GET_ACCOUNT_USERS:
      return accountUsers;
    case constants.GET_ACCOUNT_USERS_SUCCESS:
      return getReducerState({
        loading: false,
        data: action.data.map(item => ({ ...item, id: item.uuid })),
      });
    case constants.GET_ACCOUNT_USERS_ERROR:
      return getReducerState({
        loading: false,
        data: state.data,
        error: action.error,
      });
    default:
      return state;
  }
};
