import * as constants from 'app-state/constants';

export const shareClassCreate = payload => ({
  type: constants.CREATE_SHARE_CLASS,
  ...payload,
});

export const shareClassEdit = (companyId, params, setSubmitting, setErrors, hideModal) => ({
  type: constants.EDIT_SHARE_CLASS,
  companyId,
  params,
  setSubmitting,
  setErrors,
  hideModal,
});
export const editShareClassDescription = ({
  companyUuid,
  companyId,
  shareClassId,
  params,
  setSubmitting,
  setErrors,
  hideModal,
}) => ({
  type: constants.EDIT_SHARE_CLASS_DESCRIPTION,
  companyUuid,
  companyId,
  shareClassId,
  params,
  setSubmitting,
  setErrors,
  hideModal,
});

export const getShareClasses = companyId => ({ type: constants.GET_SHARE_CLASSES, companyId });
export const getShareClassesSuccess = data => ({ type: constants.GET_SHARE_CLASSES_SUCCESS, data });
export const getShareClassesError = error => ({ type: constants.GET_SHARE_CLASSES_ERROR, error });

export const convertShares = params => ({ type: constants.CONVERT_SHARES, ...params });
export const convertSharesSuccess = data => ({ type: constants.CONVERT_SHARES_SUCCESS, data });
export const convertSharesError = error => ({ type: constants.CONVERT_SHARES_ERROR, error });

export const cancelShares = params => ({ type: constants.CANCEL_SHARES, ...params });
export const cancelSharesSuccess = data => ({ type: constants.CANCEL_SHARES_SUCCESS, data });
export const cancelSharesError = error => ({ type: constants.CANCEL_SHARES_ERROR, error });

export const buybackShares = params => ({ type: constants.BUYBACK_SHARES, ...params });
export const buybackSharesSuccess = data => ({ type: constants.BUYBACK_SHARES_SUCCESS, data });
export const buybackSharesError = error => ({ type: constants.BUYBACK_SHARES_ERROR, error });

export const convertSharesForIndividualHolder = params => ({
  type: constants.CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER,
  ...params,
});
export const convertSharesForIndividualHolderSuccess = data => ({
  type: constants.CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_SUCCESS,
  data,
});
export const convertSharesForIndividualHolderError = error => ({
  type: constants.CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_ERROR,
  error,
});

export const shareClassEditRetrieveQuantity = params => ({
  type: constants.SHARE_CLASS_EDIT_RETRIEVE_QUANTITY,
  ...params,
});
export const shareClassEditRetrieveQuantitySuccess = data => ({
  type: constants.SHARE_CLASS_EDIT_RETRIEVE_QUANTITY_SUCCESS,
  data,
});
export const shareClassEditRetrieveQuantityError = error => ({
  type: constants.SHARE_CLASS_EDIT_RETRIEVE_QUANTITY_ERROR,
  error,
});

export const getQuantitiesForPair = params => ({
  type: constants.GET_QUANTITIES_FOR_PAIR,
  ...params,
});
export const getQuantitiesForPairSuccess = data => ({
  type: constants.GET_QUANTITIES_FOR_PAIR_SUCCESS,
  data,
});
export const getQuantitiesForPairError = error => ({
  type: constants.GET_QUANTITIES_FOR_PAIR_ERROR,
  error,
});

export const updateTransactionsPermission = (enabledClasses, disabledClasses) => ({
  type: constants.UPDATE_TRANSACTIONS_PERMISSION,
  enabledClasses,
  disabledClasses,
});
export const updateTransactionsPermissionSuccess = data => ({
  type: constants.UPDATE_TRANSACTIONS_PERMISSION_SUCCESS,
  data,
});
