import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { hideModal as hideModalAction } from 'app-state/actions';

import SubmitButton from 'shared-parts/components/button/standard-button';
import WarningIcon from 'shared-parts/components/svg-icons/error-outline';

import {
  ButtonsContainer,
  CloseButton,
  Icon,
  ModalText,
  ModalTitle,
  ModalWrapper,
} from './confirmation-modal.styled';
import type { ConfirmationModalComponent } from './types';

const ConfirmationModal: ConfirmationModalComponent = ({
  handleConfirmation,
  hideModal,
  title,
  text,
  icon = WarningIcon(),
  confirmButtonText = 'CONFIRM',
  closeButtonText = 'Back',
  showCloseButton = true,
  buttonsMarginTop,
  iconMarginBottom,
  confirmButtonDataAttribute = 'ok-button',
  closeButtonDataAttribute = 'close-button',
}) => {
  const dispatch = useDispatch();

  const defaultButtonClickHandler = () => {
    dispatch(hideModalAction());
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    if (handleConfirmation) {
      handleConfirmation();
    } else {
      defaultButtonClickHandler();
    }
    setSubmitting(false);
  };

  return (
    <ModalWrapper>
      <Icon marginBottom={iconMarginBottom}>{icon}</Icon>
      <ModalTitle>{title}</ModalTitle>
      <ModalText>{text}</ModalText>
      <ButtonsContainer marginTop={buttonsMarginTop}>
        {showCloseButton && (
          <CloseButton
            onClick={hideModal || defaultButtonClickHandler}
            data-e2e={closeButtonDataAttribute}
          >
            {closeButtonText}
          </CloseButton>
        )}
        <SubmitButton
          onClick={handleSubmit}
          disabled={isSubmitting}
          data-e2e={confirmButtonDataAttribute}
        >
          {confirmButtonText}
        </SubmitButton>
      </ButtonsContainer>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
