import styled from 'styled-components';

import FormikButton from 'shared-parts/components/button/formik-button';
import { COLORS, FONTS } from 'shared-parts/constants';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 800px;
`;

const ModalTitle = styled.h3`
  margin: 45px 0 0 0;
  font-size: 35px;
  font-weight: 200;
  font-family: ${FONTS.oswaldFont};
  letter-spacing: 0.35px;
  line-height: 45px;
  color: ${COLORS.primary};
  text-align: center;
`;

const ModalText = styled.p`
  margin: 21px 74px 0 74px;
  padding: 21px 29px;
  font-size: 16px;
  text-align: center;
  font-family: ${FONTS.mainFont};
  line-height: 24px;
  color: ${COLORS.primary};
  background-color: ${COLORS.systemYellow};
  border: 1px solid ${COLORS.neutral800};
  border-radius: 5px;
`;

const SubmitButton = styled(FormikButton)`
  margin: 40px 0 45px 0;
  height: 40px;
`;

export { ModalWrapper, ModalTitle, ModalText, SubmitButton };
