import { COLORS } from 'shared-parts/constants';

const { systemGreen } = COLORS;

const AddIcon = ({ fill = systemGreen, height = 20, width = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M10.75 9.25h3.34a.75.75 0 1 1 0 1.5h-3.34v3.34a.75.75 0 1 1-1.5 0v-3.34H5.91a.75.75 0 1 1 0-1.5h3.34V5.91a.75.75 0 0 1 1.5 0v3.34zM10 19.75C4.615 19.75.25 15.385.25 10S4.615.25 10 .25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5z"
      />
    </g>
  </svg>
);

export default AddIcon;
