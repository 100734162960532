import PropTypes from 'prop-types';

import { FinishIcon } from 'svg-icons';
import { FormikButton } from 'shared-parts/components';

import {
  ModalText,
  ModalTitle,
  ModalWrapper,
  ShareHolderBox,
} from './options-exercised-modal.styled';

const OptionsExercisedModal = ({ grantHolderName, hideModal }) => (
  <ModalWrapper>
    {FinishIcon()}
    <ModalTitle>Options Exercised</ModalTitle>
    <ModalText>
      Share options has successfully exercised for
      <ShareHolderBox> {grantHolderName} </ShareHolderBox>
    </ModalText>
    <FormikButton onClick={hideModal}>CONTINUE</FormikButton>
  </ModalWrapper>
);

OptionsExercisedModal.propTypes = {
  grantHolderName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default OptionsExercisedModal;
