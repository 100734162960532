import { formatDate, LDF } from '@frontend/datetime';

import Link from 'shared-parts/components/link';
import { COLORS } from 'shared-parts/constants';
import tableColumnStyles from 'shared-parts/constants/table-column-styles';

import { AgreedStatus } from './documents-table.styled';

const { systemRed } = COLORS;

export const HEADER = [
  { name: 'Name' },
  { name: 'Date & Time' },
  { name: 'Uploaded By' },
  { name: 'Agreed', colspan: 2 },
  { name: 'Action' },
];

const POSITIVE_STATUS_LABEL = 'Yes';
const NEGATIVE_STATUS_LABEL = 'No';

const DELETE_DOCUMENT_LABEL = 'Delete';
export const UPLOAD_DOCUMENT_LABEL = 'Upload New';

export const KEYS = ({
  changeAgreedStatus,
  checkIsUnSignDisabled,
  checkIsDeleteDisabled,
  deleteDocument,
}) => [
  {
    field: 'name',
    transform: (name, { url }) => (
      <Link bold fontSize="16px" url={url} target="_blank" rel="noopener noreferrer">
        {name}
      </Link>
    ),
  },
  {
    field: 'createdAt',
    transform: value => formatDate(value, `${LDF.DATE_NUMERIC} ${LDF.TIME}`),
    styles: () => tableColumnStyles.date,
  },
  { field: 'uploadedBy' },
  {
    field: 'agreed',
    transform: value =>
      value ? (
        <AgreedStatus isPositive>{POSITIVE_STATUS_LABEL}</AgreedStatus>
      ) : (
        <AgreedStatus>{NEGATIVE_STATUS_LABEL}</AgreedStatus>
      ),
    styles: () => 'text-align: right; border-right: none;',
  },
  {
    multiActions: [
      {
        text: POSITIVE_STATUS_LABEL,
        action: ({ id }) => changeAgreedStatus(id, true),
        checkIsHidden: ({ agreed }) => agreed,
        styles: () => 'width: 126px; display: flex; justify-content: center;',
        checkDisabledMultiAction: ({ disableAction }) => disableAction,
      },
      {
        text: NEGATIVE_STATUS_LABEL,
        action: ({ id }) => changeAgreedStatus(id, false),
        checkIsHidden: ({ agreed }) => !agreed,
        checkDisabledMultiAction: checkIsUnSignDisabled,
        styles: () => 'width: 126px; display: flex; justify-content: center;',
      },
    ],
    styles: () => 'border-left: none;',
  },
  {
    multiActions: [
      {
        text: DELETE_DOCUMENT_LABEL,
        action: deleteDocument,
        checkDisabledMultiAction: checkIsDeleteDisabled,
        styles: ({ agreed }) =>
          `width: 126px; display: flex; justify-content: center;${
            !agreed && `color: ${systemRed}`
          }`,
      },
    ],
  },
];
