import { Formik } from 'formik';
import { object, string } from 'yup';

import validation from 'shared-parts/constants/validation';

import renderForgotPasswordForm from './forgot-password-form';

const formValidator = object({
  email: string()
    .required('Email is required')
    .matches(validation.noBlankSpaceFormat, {
      message: 'Email cannot contain spaces',
    })
    .matches(validation.emailFormat, {
      message: 'Invalid email address',
    }),
});

const ForgotPasswordWrapper = props => (
  <Formik
    enableReinitialize
    validationSchema={formValidator}
    onSubmit={props.handleFormSubmit}
    initialValues={{ email: '' }}
  >
    {renderForgotPasswordForm(props)}
  </Formik>
);

export default ForgotPasswordWrapper;
