import { createAction } from 'helpers/redux.helpers';
import type { gqlPayloadInterface } from 'shared/csv-download/csv-download';

import * as constants from './csv-download.constants';

export const sendDownloadRequest = createAction<gqlPayloadInterface>(
  constants.sendDownloadRequest.REQUEST,
);

export const checkJobProgressFailure = ({
  error = 'Something went wrong',
}: {
  error?: string;
} = {}) => {
  return {
    type: constants.checkJobProgress.FAILURE,
    error,
  };
};

export const checkJobProgressRequest = ({ data, startTime }: { data: any; startTime: Date }) => {
  return {
    type: constants.checkJobProgress.REQUEST,
    data,
    startTime,
  };
};

export const checkJobProgressSuccess = ({ data }: { data: any }) => {
  return {
    type: constants.checkJobProgress.SUCCESS,
    data,
  };
};
