import styled from 'styled-components';

import LinkComponent from 'shared-parts/components/link';
import { COLORS, Screens } from 'shared-parts/constants';

export const SHOW_BORDER = false;

export const LinkContainer = styled.div`
  text-transform: uppercase;
  // We don't want to increase the size of the footer that is set via padding,
  // yet we still want to increase the line height to allow for the left border to
  // be larger than the text. So we sneak around this with some negative margin
  margin: -2px 0px;
  padding: 4px 8px 2px;
  border-left: 2px solid ${COLORS.alternative};
  width: 84%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Link = styled.a`
  color: ${COLORS.primary};
  text-decoration: none;
  text-transform: uppercase;

  &:active,
  &:focus,
  &:hover {
    color: ${COLORS.primary};
    text-decoration: none;
  }
`;

export const ContainerWrapper = styled.div`
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 14px;
`;

export const Navbar = styled.nav`
  padding: 23px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: -650px;
`;

export const NavFooter = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  @media only screen and (max-width: ${Screens.md}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const NavItem = styled.li`
  margin-left: 50px;

  &:first-child {
    margin-left: 0;
  }

  @media only screen and (max-width: ${Screens.md}) {
    margin-left: 0;
    min-width: 100vw;
    text-align: center;

    &:last-child {
      margin-bottom: 18px;
    }
  }
`;

export const FooterLink = styled(props => <LinkComponent {...props} />)`
  color: ${COLORS.white};
  text-decoration: none;

  @media only screen and (max-width: ${Screens.md}) {
    margin-top: 6px;
  }
`;
