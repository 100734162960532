import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const { neutral200 } = COLORS;

const Container = styled.div`
  padding: 20px;
  width: 50%;
`;

export const DataContainer = styled(Container)`
  border-right: 1px solid ${neutral200};
  display: flex;
  justify-content: space-between;
`;

export const ResendContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.span`
  padding-right: 15px;
  font-size: 12px;
`;
