import { createSelector } from 'reselect';

export const getShareholdersRegistrySelector = state => state.shareholdersRegistry;
export const getHoldingsSelector = state => state.holdings;
export const getShareholdersSelector = state => state.shareholders;
export const getRegisteredShareholdersSelector = state => state.registeredShareholders;
export const getShareholdersDropdownDataSelector = state => state.shareholdersDropdownData;
export const getShareholdersList = createSelector(
  getShareholdersSelector,
  ({ data }) => data.items,
);
export const getShareholdersAmount = createSelector(
  getShareholdersSelector,
  getShareholdersList,
  ({ loading, error }, shareholders) => (loading || error ? -1 : shareholders.length),
);
export const getShareholderDetailsSelector = state => state.shareholderDetails.shareholder;
export const getCurrentShareholderTransactionSelector = state =>
  state.currentShareholderTransaction;
export const getShareholderSharesSelector = state => state.shareholderDetails.shares;
export const getShareholderDetailsBaseCurrency = state => state.shareholderDetails.baseCurrency;
export const getTransferShareholdingError = state => state.transferShareholdingError;
export const getAllocateShareholdingError = state => state.allocateShareholdingError;
export const getLookedUpShareholder = state => state.shareholderLookup;

export const getShareholdersSearch = state => state.searchShareholdersData;
export const getShareholdersSearchData = createSelector(getShareholdersSearch, ({ data }) => data);
export const getShareholdersSearchEmails = createSelector(getShareholdersSearchData, data =>
  data.map(({ email }) => email),
);

export const getShareholderPerformance = state => state.shareholderPerformance;
