export const GET_SHARE_INCENTIVE_PLAN = 'GET_SHARE_INCENTIVE_PLAN';
export const GET_SHARE_INCENTIVE_PLAN_ERROR = 'GET_SHARE_INCENTIVE_PLAN_ERROR';
export const GET_SHARE_INCENTIVE_PLAN_SUCCESS = 'GET_SHARE_INCENTIVE_PLAN_SUCCESS';
export const CLEAR_SHARE_INCENTIVE_PLAN = 'CLEAR_SHARE_INCENTIVE_PLAN';

export const EDIT_SHARE_INCENTIVE_PLAN = 'EDIT_SHARE_INCENTIVE_PLAN';
export const EDIT_SHARE_INCENTIVE_PLAN_ERROR = 'EDIT_SHARE_INCENTIVE_PLAN_ERROR';
export const EDIT_SHARE_INCENTIVE_PLAN_SUCCESS = 'EDIT_SHARE_INCENTIVE_PLAN_SUCCESS';

export const IMPORT_PURCHASE_TEMPLATE = 'IMPORT_PURCHASE_TEMPLATE';
export const IMPORT_SALE_AND_TRANSFER_TEMPLATE = 'IMPORT_SALE_AND_TRANSFER_TEMPLATE';

export const BLOCK_HOLDER = 'BLOCK_HOLDER';
export const BLOCK_HOLDER_ERROR = 'BLOCK_HOLDER_ERROR';
export const BLOCK_HOLDER_SUCCESS = 'BLOCK_HOLDER_SUCCESS';

export const UNBLOCK_HOLDER = 'UNBLOCK_HOLDER';
export const UNBLOCK_HOLDER_ERROR = 'UNBLOCK_HOLDER_ERROR';
export const UNBLOCK_HOLDER_SUCCESS = 'UNBLOCK_HOLDER_SUCCESS';
