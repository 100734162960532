import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, neutral500 } = COLORS;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-weight: bold;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
  color: ${({ disabled }) => (disabled ? neutral500 : primary)};

  svg {
    margin-right: 5px;
  }
`;
