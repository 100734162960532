import getReducerState from '../reducers/get-reducer-state';

import * as constants from './constants';

const draftCommunicationInitialState = getReducerState({
  data: null,
  saving: false,
  responseUploading: false,
  responseError: null,
  attachmentUploading: false,
  attachmentError: null,
});

export const draftCommunicationReducer = (
  state = draftCommunicationInitialState,
  { type, data, error },
) => {
  switch (type) {
    case constants.RESET_DRAFT_COMMUNICATION:
    case constants.GET_DRAFT_COMMUNICATION:
      return draftCommunicationInitialState;
    case constants.CREATE_DRAFT_COMMUNICATION: {
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          subject: '',
          body: '',
          bulkMailing: true,
        },
      };
    }
    case constants.CREATE_DRAFT_COMMUNICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    }
    case constants.GET_DRAFT_COMMUNICATION_SUCCESS:
      return { ...state, loading: false, data };

    case constants.GET_DRAFT_COMMUNICATION_ERROR:
    case constants.PUBLISH_DRAFT_COMMUNICATION_ERROR:
      return getReducerState({
        loading: false,
        data: state.data,
        error,
      });

    case constants.SAVE_DRAFT_COMMUNICATION: {
      return {
        ...state,
        saving: true,
        error: null,
      };
    }
    case constants.SAVE_DRAFT_COMMUNICATION_SUCCESS:
      return { ...state, saving: false, data };
    case constants.SAVE_DRAFT_COMMUNICATION_ERROR:
      return getReducerState({
        data: state.data,
        saving: false,
        error,
      });

    case constants.DELETE_DRAFT_COMMUNICATION_SUCCESS:
      return getReducerState({
        loading: false,
        data: draftCommunicationInitialState.data,
      });

    case constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT:
      return {
        ...state,
        attachmentUploading: true,
        attachmentError: null,
      };
    case constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: [...(state.data.attachments || []), data],
        },
        attachmentUploading: false,
      };
    case constants.DRAFT_COMMUNICATION_ADD_ATTACHMENT_ERROR:
      return {
        ...state,
        attachmentUploading: false,
        attachmentError: error,
      };

    case constants.DRAFT_COMMUNICATION_DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: state.data.attachments?.filter(attachment => attachment.id !== data),
        },
      };

    case constants.DRAFT_COMMUNICATION_ADD_RESPONSE:
      return {
        ...state,
        responseUploading: true,
        responseError: null,
      };
    case constants.DRAFT_COMMUNICATION_ADD_RESPONSE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...data },
        responseUploading: false,
      };
    case constants.DRAFT_COMMUNICATION_ADD_RESPONSE_ERROR:
      return {
        ...state,
        responseUploading: false,
        responseError: error,
      };

    case constants.DRAFT_COMMUNICATION_DELETE_RESPONSE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          responseExpiredAt: null,
          communicationResponseTemplates: [],
        },
      };
    default:
      return state;
  }
};

const publishedCommunicationInitialState = getReducerState({
  data: { attachments: [], export: { loading: false, error: false } },
});

export const publishedCommunicationReducer = (
  state = publishedCommunicationInitialState,
  action,
) => {
  switch (action.type) {
    case constants.GET_PUBLISHED_COMMUNICATION:
    case constants.RESET_PUBLISHED_COMMUNICATION:
      return publishedCommunicationInitialState;
    case constants.GET_PUBLISHED_COMMUNICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case constants.GET_PUBLISHED_COMMUNICATION_ERROR:
      return {
        ...state,
        loading: false,
        data: publishedCommunicationInitialState.data,
        error: action.error,
      };

    case constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS:
      return { ...state, export: { loading: true, error: false } };
    case constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS:
      return { ...state, export: { loading: false, error: false } };
    case constants.EXPORT_COMMUNICATION_RESPONSES_STATISTICS_ERROR:
      return { ...state, export: { loading: false, error: true } };
    default:
      return state;
  }
};

const communicationsInitialState = getReducerState({ data: { items: [] } });

export const communicationsReducer = (state = communicationsInitialState, action) => {
  switch (action.type) {
    case constants.GET_COMMUNICATIONS_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    case constants.GET_COMMUNICATIONS_ERROR:
      return {
        loading: false,
        data: communicationsInitialState.data,
        error: action.error,
      };
    case constants.RESET_COMMUNICATIONS:
      return communicationsInitialState;
    default:
      return state;
  }
};

const communicationResponsesStatisticsInitialState = getReducerState();

export const communicationResponsesStatisticsReducer = (
  state = communicationResponsesStatisticsInitialState,
  action,
) => {
  switch (action.type) {
    case constants.RESET_COMMUNICATION_RESPONSES_STATISTICS:
      return communicationResponsesStatisticsInitialState;
    case constants.GET_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS:
      return getReducerState({
        loading: false,
        data: action.data,
      });
    case constants.GET_COMMUNICATION_RESPONSES_STATISTICS_ERROR:
      return {
        loading: false,
        data: communicationResponsesStatisticsInitialState.data,
        error: action.error,
      };
    default:
      return state;
  }
};

export const defaultAggregatedResponsesState = getReducerState({
  data: { items: [], meta: { pagination: { page: 1 } } },
});

export const aggregatedCommunicationResponsesStatisticsReducer = (
  state = defaultAggregatedResponsesState,
  action,
) => {
  switch (action.type) {
    case constants.RESET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS:
      return defaultAggregatedResponsesState;
    case constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS:
      return { ...state, loading: true };
    case constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS:
      return {
        ...state,
        data: {
          items: [...state.data.items, ...action.data.items],
          meta: {
            ...action.data.meta,
            pagination: {
              ...action.data.meta.pagination,
              page: state.data.meta.pagination.page + 1,
            },
          },
          lastItemFetched:
            action.data.items.length + state.data.items.length ===
            action.data.meta.pagination.recordsCount,
        },
        loading: false,
      };
    case constants.GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_ERROR:
      return { loading: false, data: state.data, error: action.error };
    default:
      return state;
  }
};

const shareholdersEmailsInitialState = { loading: false, data: [], error: null };

export const shareholdersEmailsReducer = (
  state = shareholdersEmailsInitialState,
  { type, payload },
) => {
  switch (type) {
    case constants.FETCH_SHAREHOLDERS_EMAILS:
      return {
        ...state,
        loading: true,
      };
    case constants.FETCH_SHAREHOLDERS_EMAILS_SUCCESS:
      return { loading: false, data: payload, error: null };
    case constants.FETCH_SHAREHOLDERS_EMAILS_ERROR:
      return { ...state, loading: false, error: payload };
    case constants.RESET_SHAREHOLDERS_EMAILS:
      return shareholdersEmailsInitialState;
    default:
      return state;
  }
};
