import React, { FC } from 'react';

import { BUTTON_DEFAULT_PROPS, BUTTON_TYPES } from './constants';
import { Button, ButtonsWrapper } from './styled';
import type { ArrayButtonsContainerProps } from './types';

const ArrayButtonsContainer: FC<React.PropsWithChildren<ArrayButtonsContainerProps>> = ({
  handleAdd,
  handleRemove,
  disableAdd,
  disableRemove,
  isAddHidden,
  isRemoveHidden,
}) => (
  <ButtonsWrapper>
    {!isAddHidden && (
      <Button
        {...BUTTON_DEFAULT_PROPS[BUTTON_TYPES.ADD]}
        type={BUTTON_TYPES.ADD}
        disabled={disableAdd}
        onClick={handleAdd}
      />
    )}
    {!isRemoveHidden && (
      <Button
        {...BUTTON_DEFAULT_PROPS[BUTTON_TYPES.REMOVE]}
        type={BUTTON_TYPES.REMOVE}
        disabled={disableRemove}
        onClick={handleRemove}
      />
    )}
  </ButtonsWrapper>
);

export default ArrayButtonsContainer;
