import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { primary } = COLORS;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const StyledHeading = styled.h1`
  margin-top: 0;
  color: ${primary};
  letter-spacing: 0.35px;
  text-align: center;
  font-family: ${FONTS.oswaldFont};
  font-weight: 300;
`;
