import styled from 'styled-components';

import RedInfoIcon from 'images/red-info.svg';
import { Table as RegularTable } from 'shared-parts/components/table';
import {
  TableCell,
  TableHeader,
  TableHeaderCell,
} from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, white, neutral300, neutral200, neutral800 } = COLORS;

export const Section = styled.div`
  margin-bottom: 60px;
  width: 100%;
`;

export const TopContainer = styled.div`
  display: flex;
  background-color: ${white};
  color: ${primary};
`;

export const TableTitle = styled.span`
  display: inline-block;
  color: ${primary};
  padding: 10px 20px;
  font-weight: bold;
`;

export const FailedMessage = styled.div`
  border-bottom: 1px solid ${neutral300};
  padding: 30px 20px;
  color: ${neutral800};
  font: italic bold 16px ${FONTS.mainFont};

  ::before {
    content: '';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    top: 5px;
    background: transparent url('${RedInfoIcon}') 0 0 no-repeat padding-box;
    height: 20px;
    width: 20px;
  }
`;

export const Table = styled(RegularTable)`
  background: ${white};
  border-radius: 0;
  border: none;
  overflow: visible;

  ${TableHeader} {
    color: ${primary};
  }

  ${TableHeaderCell} {
    top: 57px;
    box-shadow: inset 0 -1px 0 ${neutral300};
    background: ${white};
    font-weight: 700;
    border: 1px solid ${neutral200};

    :first-of-type {
      border-top-left-radius: 0;
    }

    :last-of-type {
      border-right: none;
      border-top-right-radius: 0;
    }
  }

  ${TableCell} {
    border: 1px solid ${neutral200};

    :last-of-type {
      border-right: none;
      padding: 0;
    }
  }
`;
