import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import { Screens } from 'shared-parts/constants/screens';

export const Banner = styled.section`
  align-items: center;
  display: flex;
  font-family: ${FONTS.oswaldFont};
  justify-content: center;
  margin-bottom: 56px;
  background: ${COLORS.primary};
  color: ${COLORS.white};
  min-height: 80px;
  border-radius: 10px;
`;

export const Column = styled.div`
  min-width: 15%;
  max-width: 33%;

  @media (max-width: ${Screens.md}) {
    max-width: 100%;
  }
`;

export const SectionDescription = styled(Column)`
  text-align: right;
  font-size: 36px;
  text-transform: uppercase;

  @media (max-width: ${Screens.xl}) {
    font-size: 24px;
  }

  @media (max-width: ${Screens.md}) {
    text-align: center;
    font-size: 18px;
  }

  @media (max-width: ${Screens.sm}) {
    font-size: 16px;
  }
`;

const InfoColumn = styled(Column)`
  font-family: ${FONTS.mainFont};
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;

  @media (max-width: ${Screens.md}) {
    font-size: 16px;
  }

  @media (max-width: ${Screens.sm}) {
    margin-left: 10px;
  }
`;

export const Issuer = styled(InfoColumn)`
  text-align: center;
`;

export const TokenPrice = InfoColumn;
