export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_ERROR = 'UPDATE_CURRENT_USER_ERROR';
export const UPDATE_MY_DETAILS = 'UPDATE_MY_DETAILS';
export const RESEND_EMAIL_CURRENT = 'RESEND_EMAIL_CURRENT';
export const RESEND_EMAIL_CURRENT_ERROR = 'RESEND_EMAIL_CURRENT_ERROR';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SHOW_SIDE_FORM = 'SHOW_SIDE_FORM';
export const HIDE_SIDE_FORM = 'HIDE_SIDE_FORM';

export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';

export const SAVE_REDIRECT_PATH = 'SAVE_REDIRECT_PATH';
export const REMOVE_REDIRECT_PATH = 'REMOVE_REDIRECT_PATH';

export const PING = 'PING';
export const UPDATE_NICKELLED_USER = 'UPDATE_NICKELLED_USER';
