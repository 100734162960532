import * as constants from 'app-state/constants';

export const resetSubnavigation = () => ({ type: constants.RESET_SUB_NAVIGATION });
export const fetchSubNavigation = navigationItemId => ({
  type: constants.FETCH_SUB_NAVIGATION,
  navigationItemId,
});
export const fetchSubNavigationSuccess = items => ({
  type: constants.FETCH_SUB_NAVIGATION_SUCCESS,
  items,
});
export const fetchSubNavigationError = error => ({
  type: constants.FETCH_SUB_NAVIGATION_ERROR,
  error,
});

export const fetchNavigation = companyId => ({ type: constants.FETCH_NAVIGATION, companyId });
export const fetchNavigationSuccess = items => ({
  type: constants.FETCH_NAVIGATION_SUCCESS,
  items,
});
export const fetchNavigationError = error => ({ type: constants.FETCH_NAVIGATION_ERROR, error });
export const resetNavigation = () => ({ type: constants.RESET_NAVIGATION });

export const fetchEditableNavigation = (modelUuid, model) => ({
  type: constants.FETCH_EDITABLE_NAVIGATION,
  modelUuid,
  model,
});
export const fetchEditableNavigationSuccess = data => ({
  type: constants.FETCH_EDITABLE_NAVIGATION_SUCCESS,
  data,
});
export const fetchEditableNavigationError = error => ({
  type: constants.FETCH_EDITABLE_NAVIGATION_ERROR,
  error,
});
export const resetEditableNavigation = () => ({ type: constants.RESET_EDITABLE_NAVIGATION });

export const editNavigationItem = (itemId, item) => ({
  type: constants.EDIT_NAVIGATION_ITEM,
  itemId,
  item,
});
export const editNavigationItemSuccess = editedItem => ({
  type: constants.EDIT_NAVIGATION_ITEM_SUCCESS,
  editedItem,
});
export const editNavigationItemError = error => ({
  type: constants.EDIT_NAVIGATION_ITEM_ERROR,
  error,
});

export const deleteNavigationItem = payload => ({
  type: constants.DELETE_NAVIGATION_ITEM,
  payload,
});
export const deleteNavigationItemSuccess = deletedItem => ({
  type: constants.DELETE_NAVIGATION_ITEM_SUCCESS,
  deletedItem,
});
export const deleteNavigationItemError = error => ({
  type: constants.DELETE_NAVIGATION_ITEM_ERROR,
  error,
});

export const createNavigationItem = (item, model) => ({
  type: constants.CREATE_NAVIGATION_ITEM,
  item,
  model,
});
export const createNavigationItemSuccess = createdItem => ({
  type: constants.CREATE_NAVIGATION_ITEM_SUCCESS,
  createdItem,
});
export const createNavigationItemError = error => ({
  type: constants.CREATE_NAVIGATION_ITEM_ERROR,
  error,
});

export const reorderNavigationItem = (item, affectedItem, modelUuid, model) => ({
  type: constants.REORDER_NAVIGATION_ITEM,
  item,
  affectedItem,
  modelUuid,
  model,
});
export const reorderNavigationItemSuccess = reorderedItem => ({
  type: constants.REORDER_NAVIGATION_ITEM_SUCCESS,
  reorderedItem,
});
export const reorderNavigationItemError = error => ({
  type: constants.REORDER_NAVIGATION_ITEM_ERROR,
  error,
});

export const generateBasicNavigation = (modelUuid, pagesModel) => ({
  type: constants.GENERATE_BASIC_NAVIGATION,
  modelUuid,
  pagesModel,
});
export const generateBasicNavigationError = error => ({
  type: constants.GENERATE_BASIC_NAVIGATION_ERROR,
  error,
});
