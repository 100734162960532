import * as constants from 'app-state/constants';

export const downloadFileInitialState = { loading: false, error: null };

export const downloadFileReducer = (state = downloadFileInitialState, { type, error }) => {
  switch (type) {
    case constants.DOWNLOAD_FILE:
      return { loading: true, error: null };
    case constants.DOWNLOAD_FILE_SUCCESS:
      return { loading: false, error: null };
    case constants.DOWNLOAD_FILE_ERROR:
      return { loading: false, error };
    case constants.RESET_DOWNLOAD_FILE:
      return downloadFileInitialState;
    default:
      return state;
  }
};
