import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import ModalFailed from 'shared-parts/components/modal-failed';

function* editShareIncentivePlan({ data, id, onSuccess }) {
  try {
    yield call(request, API.ShareInvestmentPlan.PlanById(id), 'PATCH', data, {
      mode: 'cors',
      snakeCaseParams: false,
    });
    yield put(actions.editShareIncentivePlanSuccess({ ...data, version: data.version + 1 }));
    onSuccess();
  } catch (e) {
    actions.editShareIncentivePlanError(e.response.details);
  }
}

function* editShareIncentivePlansWatcher() {
  yield takeEvery(constants.EDIT_SHARE_INCENTIVE_PLAN, editShareIncentivePlan);
}

function* getShareIncentivePlan({ shareIncentivePlanId }) {
  try {
    const { data } = yield call(
      request,
      API.ShareInvestmentPlan.PlanById(shareIncentivePlanId),
      'GET',
      null,
      {
        mode: 'cors',
        ignoredErrorCodes: [500],
      },
    );

    yield put(actions.getShareIncentivePlanSuccess(data));
  } catch (e) {
    yield put(actions.getShareIncentivePlanError(e));
  }
}

function* getShareIncentivePlanWatcher() {
  yield takeEvery(constants.GET_SHARE_INCENTIVE_PLAN, getShareIncentivePlan);
}

function* importPurchaseTemplate({ planId }) {
  try {
    const { data } = yield call(
      request,
      API.ShareInvestmentPlan.Orders.ImportPurchaseTemplate(planId),
      'GET',
      null,
      { mode: 'cors', to: 'blob' },
    );

    downloadBlob(data, `data.csv`);
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
      }),
    );
  }
}

function* importPurchaseTemplateWatcher() {
  yield takeEvery(constants.IMPORT_PURCHASE_TEMPLATE, importPurchaseTemplate);
}

function* importSaleAndTransferTemplate({ planId }) {
  try {
    const { data } = yield call(
      request,
      API.ShareInvestmentPlan.Orders.ImportSaleAndTransferTemplate(planId),
      'GET',
      null,
      { mode: 'cors', to: 'blob' },
    );

    downloadBlob(data, `data.csv`);
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
      }),
    );
  }
}

function* importSaleAndTransferTemplateWatcher() {
  yield takeEvery(constants.IMPORT_SALE_AND_TRANSFER_TEMPLATE, importSaleAndTransferTemplate);
}

function* blockHolder({ id, onSuccess }) {
  try {
    yield call(
      request,
      API.ShareInvestmentPlan.Holders.Block(id),
      'POST',
      {},
      {
        mode: 'cors',
        snakeCaseParams: false,
      },
    );
    yield put(actions.hideModal());
    yield put(actions.blockHolderSuccess());
    onSuccess();
  } catch (e) {
    yield put(actions.blockHolderError());

    yield put(actions.hideSideForm());

    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
      }),
    );
  }
}

function* blockHolderWatcher() {
  yield takeEvery(constants.BLOCK_HOLDER, blockHolder);
}

function* unblockHolder({ id, onSuccess }) {
  try {
    yield call(
      request,
      API.ShareInvestmentPlan.Holders.Unblock(id),
      'POST',
      {},
      {
        mode: 'cors',
        snakeCaseParams: false,
      },
    );
    yield put(actions.hideModal());
    yield put(actions.unblockHolderSuccess());
    onSuccess();
  } catch (e) {
    yield put(actions.unblockHolderSuccess());

    yield put(actions.hideSideForm());

    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
      }),
    );
  }
}

function* unblockHolderWatcher() {
  yield takeEvery(constants.UNBLOCK_HOLDER, unblockHolder);
}
export {
  getShareIncentivePlanWatcher,
  editShareIncentivePlansWatcher,
  importPurchaseTemplateWatcher,
  importSaleAndTransferTemplateWatcher,
  blockHolderWatcher,
  unblockHolderWatcher,
};
