import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from 'app-state/actions';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import { ModalFailed } from 'shared-parts/components';

import * as constants from './brokers-note.constants';

function* createBrokersNote(action: AnyAction) {
  try {
    yield call(
      request,
      API.ShareInvestmentPlan.BrokersNote.Create(String(action.planId)),
      'POST',
      action.data,
      {
        mode: 'cors',
        snakeCaseParams: false,
      },
    );
    yield put({
      type: constants.createBrokersNote.SUCCESS,
    });
  } catch (e: any) {
    yield put({
      type: constants.createBrokersNote.FAILURE,
      error: e?.response?.message || 'Something went wrong',
    });
  }
}
export function* createBrokersNoteWatcher() {
  yield takeLatest(constants.createBrokersNote.REQUEST, createBrokersNote);
}

function* downloadBrokersNote(action: AnyAction) {
  try {
    const { planId, dealId } = action;
    const { data } = yield call(
      request,
      API.ShareInvestmentPlan.BrokersNote.Download(planId, dealId),
      'GET',
      null,
      { mode: 'cors', to: 'blob' },
    );

    downloadBlob(data, `data.pdf`);
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
      }),
    );
  }
}

export function* downloadBrokersNoteWatcher() {
  yield takeLatest(constants.downloadBrokersNote, downloadBrokersNote);
}
