import { createElement } from 'react';
import { connect } from 'react-redux';

import * as actions from 'app-state/actions';
import * as selectors from 'app-state/selectors';

import { CloseIcon } from 'svg-icons';
import InfoTooltip from 'shared/info-tooltip';
import { COLORS } from 'shared-parts/constants';

import { CloseButton, FormContainer, FormHeader, FormWrapper } from './side-form.styled';

const renderForm = content => createElement(content.component, { ...content });

const SideForm = ({ formData: { content, isOpen }, closeSideFrom, className = '' }) =>
  isOpen ? (
    <FormWrapper
      width={content.width}
      className={className}
      role="dialog"
      aria-labelledby="side-form-title"
    >
      <FormHeader>
        <span id="side-form-title" data-e2e="side-form-header">
          {content.header}
        </span>
        {content.info && <InfoTooltip iconColor={COLORS.neutral500}>{content.info}</InfoTooltip>}
        <CloseButton data-e2e="side-form-close">
          {CloseIcon(27, 27, COLORS.neutral300, closeSideFrom)}
        </CloseButton>
      </FormHeader>
      <FormContainer>{renderForm(content)}</FormContainer>
    </FormWrapper>
  ) : null;

const mapStateToProps = state => ({
  formData: selectors.getSideFormSelector(state),
});

const mapDispatchToProps = dispatch => ({
  closeSideFrom: () => dispatch(actions.hideSideForm()),
});

const connected = connect(mapStateToProps, mapDispatchToProps)(SideForm);

export { connected as default, SideForm };
