import styled from 'styled-components';

import Table from 'shared-parts/components/table/regular-table/table';
import { COLORS } from 'shared-parts/constants';

const WrappedTable = styled(Table)`
  background: ${COLORS.neutral200};
  border-color: ${COLORS.white};
  text-align: center;
  margin-bottom: 30px;
`;

const cellStyles = `
  color: ${COLORS.primary};
  font-size: 16px;
  text-align: left;

   :first-child {
    font-weight: normal;
  }
`;

export { WrappedTable, cellStyles };
