import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  showModal,
  updateIntroducerFeesError,
  updateIntroducerFeesSuccess,
} from 'app-state/actions';
import {
  addSecondaryTransactionDocumentError,
  addSecondaryTransactionDocumentSuccess,
  approveSecondaryTransactionDocumentError,
  approveSecondaryTransactionDocumentSuccess,
  approveTransactionError,
  approveTransactionSuccess,
  deleteSecondaryTransactionDocumentError,
  deleteSecondaryTransactionDocumentSuccess,
  getIntroducerFeesError,
  getIntroducerFeesSuccess,
  getSecondaryTransactionError,
  getSecondaryTransactions as getSecondaryTransactionsAction,
  getSecondaryTransactionsError,
  getSecondaryTransactionsSuccess,
  getSecondaryTransactionSuccess,
  rejectSecondaryTransactionDocumentError,
  rejectSecondaryTransactionDocumentSuccess,
  rejectTransactionError,
  rejectTransactionSuccess,
  setEmptySecondaryTransactions,
} from 'app-state/actions/secondary-transactions';
import {
  ADD_SECONDARY_TRANSACTION_DOCUMENT,
  APPROVE_SECONDARY_TRANSACTION_DOCUMENT,
  APPROVE_TRANSACTION,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT,
  GET_ALL_SECONDARY_TRANSACTIONS,
  GET_INTRODUCER_FEES,
  GET_SECONDARY_TRANSACTION,
  GET_SECONDARY_TRANSACTIONS,
  REJECT_SECONDARY_TRANSACTION_DOCUMENT,
  REJECT_TRANSACTION,
  UPDATE_INTRODUCER_FEES,
} from 'app-state/constants/secondary-transactions';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import { withCompany } from 'app-state/sagas/companies';
import { getCompany as getCompanySelector, getCompanyUuid } from 'app-state/selectors/companies';
import { getSecondaryTransactionUuid } from 'app-state/selectors/secondary-transactions';

import IntroducerFeesSettingsModal from 'modules/company/equity-administration/secondary-transactions/components/introducer-fees-settings/introducer-fees-settings-modal';
import TransactionApprovedModal from 'modules/company/equity-administration/secondary-transactions/components/transaction-approved-modal';
import TransactionRejectedModal from 'modules/company/equity-administration/secondary-transactions/components/transaction-rejected-modal';
import API from 'constants/api';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ModalFailed from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';
import request from 'shared-parts/helpers/request';

function* getSecondaryTransaction({ transactionUuid }) {
  try {
    const { data } = yield call(request, API.SecondaryTransaction(transactionUuid));
    yield put(getSecondaryTransactionSuccess(data));
  } catch (e) {
    yield put(getSecondaryTransactionError(e));
  }
}

function* getSecondaryTransactionWatcher() {
  yield takeEvery(GET_SECONDARY_TRANSACTION, getSecondaryTransaction);
}

function* addSecondaryTransactionDocument({ fileData, setError, stockTransferForm }) {
  try {
    const transactionUuid = yield select(getSecondaryTransactionUuid);

    if (stockTransferForm) {
      fileData.append('stock_transfer_form', stockTransferForm);
    }

    const { data } = yield call(
      request,
      API.AddSecondaryTransactionDocument(transactionUuid),
      'POST',
      fileData,
      { timeout: 180000 },
    );
    yield put(addSecondaryTransactionDocumentSuccess(data));
  } catch (e) {
    const errorMessage =
      e.status === 400 ? Object.values(e.response.details).flat()[0] : defaultErrorMessage;

    yield setError(errorMessage);
    yield put(addSecondaryTransactionDocumentError(errorMessage));
  }
}

function* addSecondaryTransactionDocumentWatcher() {
  yield takeEvery(ADD_SECONDARY_TRANSACTION_DOCUMENT, addSecondaryTransactionDocument);
}

function* deleteSecondaryTransactionDocument({ documentId, setError }) {
  try {
    const transactionUuid = yield select(getSecondaryTransactionUuid);

    yield call(
      request,
      API.DeleteSecondaryTransactionDocument(transactionUuid, documentId),
      'DELETE',
    );
    yield put(deleteSecondaryTransactionDocumentSuccess(documentId));
  } catch (e) {
    yield setError();
    yield put(deleteSecondaryTransactionDocumentError(e.response.details));
  }
}

function* deleteSecondaryTransactionDocumentWatcher() {
  yield takeEvery(DELETE_SECONDARY_TRANSACTION_DOCUMENT, deleteSecondaryTransactionDocument);
}

function* approveSecondaryTransactionDocument({ documentId }) {
  try {
    const transactionUuid = yield select(getSecondaryTransactionUuid);

    const updatedDocument = yield call(
      request,
      API.ApproveSecondaryTransactionDocument(transactionUuid, documentId),
      'POST',
    );
    yield put(approveSecondaryTransactionDocumentSuccess(updatedDocument));

    yield put(
      showModal({
        showHeader: false,
        closable: true,
        component: SuccessModal,
        title: 'Document Approved',
        buttonText: 'CONTINUE',
      }),
    );

    yield* getSecondaryTransaction({ transactionUuid });
  } catch (e) {
    const errorMessage = e.status === 400 ? e.response.details : defaultErrorMessage;
    yield put(approveSecondaryTransactionDocumentError(errorMessage));
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  }
}

function* approveSecondaryTransactionDocumentWatcher() {
  yield takeEvery(APPROVE_SECONDARY_TRANSACTION_DOCUMENT, approveSecondaryTransactionDocument);
}

function* rejectSecondaryTransactionDocument({ documentId }) {
  try {
    const transactionUuid = yield select(getSecondaryTransactionUuid);

    const updatedDocument = yield call(
      request,
      API.RejectSecondaryTransactionDocument(transactionUuid, documentId),
      'POST',
    );
    yield put(rejectSecondaryTransactionDocumentSuccess(updatedDocument));
    yield* getSecondaryTransaction({ transactionUuid });
  } catch (e) {
    const errorMessage = e.status === 400 ? e.response.details : defaultErrorMessage;
    yield put(rejectSecondaryTransactionDocumentError(errorMessage));
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  }
}

function* rejectSecondaryTransactionDocumentWatcher() {
  yield takeEvery(REJECT_SECONDARY_TRANSACTION_DOCUMENT, rejectSecondaryTransactionDocument);
}

function* approveTransaction({ transactionUuid, searchParams }) {
  yield put(showLoader());

  try {
    const {
      data: { uuid },
    } = yield select(getCompanySelector);
    const { data } = yield call(
      request,
      API.ApproveSecondaryTransaction(uuid, transactionUuid),
      'POST',
    );

    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: TransactionApprovedModal,
        state: data.state,
      }),
    );

    yield put(getSecondaryTransactionsAction(searchParams));
    yield put(approveTransactionSuccess(data));
  } catch (e) {
    yield put(approveTransactionError(e));
  }

  yield put(hideLoader());
}

function* approveTransactionWatcher() {
  yield takeEvery(APPROVE_TRANSACTION, approveTransaction);
}

function* rejectTransaction({ transactionUuid, reason, searchParams }) {
  yield put(showLoader());

  try {
    const {
      data: { uuid },
    } = yield select(getCompanySelector);
    const { data } = yield call(
      request,
      API.RejectSecondaryTransaction(uuid, transactionUuid),
      'POST',
      { reason },
    );

    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: TransactionRejectedModal,
      }),
    );

    yield put(getSecondaryTransactionsAction(searchParams));
    yield put(rejectTransactionSuccess(data));
  } catch (e) {
    yield put(rejectTransactionError(e));
  }

  yield put(hideLoader());
}

function* rejectTransactionWatcher() {
  yield takeEvery(REJECT_TRANSACTION, rejectTransaction);
}

function* getSecondaryTransactions({
  company,
  page,
  perPage,
  fromDate,
  toDate,
  order,
  actionTypes,
  status,
  shareClasses,
}) {
  if (company.uuid) {
    const queryParams = {
      companyUuid: company.uuid,
      page: 1, // this and next line looks so to match BE logic
      perPage: page * perPage,
      fromDate,
      toDate,
      order,
      actionTypes,
      status,
      shareClasses,
    };
    const { data } = yield call(request, API.GetSecondaryTransactions(queryParams), 'GET');
    yield put(getSecondaryTransactionsSuccess(data));
  } else {
    yield put(setEmptySecondaryTransactions());
  }
}

function* getSecondaryTransactionsWatcher() {
  yield takeEvery(
    GET_SECONDARY_TRANSACTIONS,
    withCompany(getSecondaryTransactions, getSecondaryTransactionsError, true),
  );
}

function* getAllSecondaryTransactions({ page, perPage, order, transactionUuid }) {
  try {
    const queryParams = {
      page: 1, // this and next line looks so to match BE logic
      perPage: page * perPage,
      order,
      transactionUuid,
    };
    const { data } = yield call(request, API.GetAllSecondaryTransactions(queryParams), 'GET');
    yield put(getSecondaryTransactionsSuccess(data));
  } catch (e) {
    yield put(getSecondaryTransactionsError(e));
  }
}

function* getAllSecondaryTransactionsWatcher() {
  yield takeEvery(GET_ALL_SECONDARY_TRANSACTIONS, getAllSecondaryTransactions);
}

function* getIntroducerFees() {
  const companyUuid = yield select(getCompanyUuid);
  try {
    const { data } = yield call(request, API.GetIntroducerFees(companyUuid));
    yield put(getIntroducerFeesSuccess(data));
  } catch (error) {
    yield put(getIntroducerFeesError({ error }));
  }
}

function* getIntroducerFeesWatcher() {
  yield takeEvery(GET_INTRODUCER_FEES, getIntroducerFees);
}

function* updateIntroducerFees({ payload }) {
  yield put(showLoader());
  try {
    const uuid = yield select(getCompanyUuid);
    const { data } = yield call(request, API.UpdateIntroducerFees(uuid), 'PUT', payload);
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: IntroducerFeesSettingsModal,
      }),
    );
    yield put(updateIntroducerFeesSuccess(data));
  } catch (error) {
    if (error.status === 400) {
      yield put(
        showModal({
          closable: true,
          component: ModalFailed,
          title: defaultErrorMessage,
        }),
      );
    }
    yield put(updateIntroducerFeesError(error));
  }
  yield put(hideLoader());
}
function* updateIntroducerFeesWatcher() {
  yield takeEvery(UPDATE_INTRODUCER_FEES, updateIntroducerFees);
}

export {
  approveTransaction,
  approveTransactionWatcher,
  rejectTransaction,
  rejectTransactionWatcher,
  getSecondaryTransactions,
  getSecondaryTransactionsWatcher,
  getAllSecondaryTransactions,
  getAllSecondaryTransactionsWatcher,
  getSecondaryTransaction,
  getSecondaryTransactionWatcher,
  addSecondaryTransactionDocument,
  addSecondaryTransactionDocumentWatcher,
  deleteSecondaryTransactionDocument,
  deleteSecondaryTransactionDocumentWatcher,
  approveSecondaryTransactionDocumentWatcher,
  rejectSecondaryTransactionDocumentWatcher,
  getIntroducerFees,
  getIntroducerFeesWatcher,
  updateIntroducerFees,
  updateIntroducerFeesWatcher,
};
