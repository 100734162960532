type Colors = {
  primary: string;
  primaryContrastText: string;
  accent: string;
  accentContrastText: string;
  highlight: string;
  highlightContrastText: string;
  secondary600: string;
  secondary: string;
  secondary100: string;
  secondaryContrastText: string;
  alternative600: string;
  alternative: string;
  alternative100: string;
  alternativeContrastText: string;
  neutral900: string;
  neutral800: string;
  neutral700: string;
  neutral500: string;
  neutral400: string;
  neutral300: string;
  neutral200: string;
  neutral100: string;
  black: string;
  white: string;
  systemRed: string;
  systemRedBg: string;
  systemGreen: string;
  systemGreenBg: string;
  systemOrange: string;
  systemYellow: string;
  systemCyan: string;
  systemPink: string;
  systemPurple: string;
  systemPurpleBg: string;
  success: string;
  success100: string;
  warning: string;
  error: string;
  error100: string;
  primaryTextColor: string;
  shadowColor: string;
};

type Fonts = {
  mainFont: string;
  oswaldFont: string;
};

type DealCustomization = {
  labelColor?: string;
  tileCloseStatusBackground?: string;
};

type SubNavigationCustomization = {
  activeLinkColor?: string;
  inactiveLinkColor?: string;
  activeHoverColor?: string;
  activeLinkBorder?: string;
  activeSubLinkColor?: string;
  activeSubLinkBorder?: string;
};

type NavigationCustomization = {
  menuToggleBackgroundColor?: string;
  menuToggleColor?: string;
  menuMobileCtaColor?: string;
};

type LocationsCustomization = {
  widgetToogleColor?: string;
  widgetToogleBackgroundColor?: string;
};

type TabCustomization = {
  selectTabBorderColor?: string;
  headerBackground?: string;
};

type MenuCustomization = {
  sidebarBackgroundColor?: string;
  activeMenuItemColor?: string;
  activeMenuItemBackgroundColor?: string;
  activeMenuItemBeforeElementColor?: string;
  InactiveMenuItemColor?: string;
  InactiveMenuItemBackgroundColor?: string;
  activeSubMenuItemColor?: string;
  activeSubMenuItemBackgroundColor?: string;
  menuItemBeforeElementHoverColor?: string;
  borderColor?: string;
  supportButtonColor?: string;
  hoverItemColor?: string;
  hoverSupportButtonBorderColor?: string;
  signOutButtonColor?: string;
  signOutButtonHoverColor?: string;
  buttonWrapperBottom?: string;
  logoutButtonHeight?: string;
};

type TopBarCustomization = {
  containerLogoBackground?: string;
  topNavigationContainerBackground?: string;
  bottomNavigationContainerBackground?: string;
  logo?: string;
};

type TableCustomization = {
  color?: string;
  headerCellBackgroundColor?: string;
  activeTableTabBackgroundColor?: string;
  nonActiveTableTabColor?: string;
};

export type Customizations = {
  table: TableCustomization;
  deal: DealCustomization;
  subnavigation: SubNavigationCustomization;
  navigation: NavigationCustomization;
  locations: LocationsCustomization;
  tab: TabCustomization;
  menu: MenuCustomization;
  topBar: TopBarCustomization;
};

export type TenantConfig = {
  colors: Colors;
  fonts: Fonts;
  custom: Customizations;
  theme: any;
};

export function getTenantConfig(): TenantConfig {
  return (window as Window).tenant as TenantConfig;
}
