import { AsyncState } from 'helpers/redux.helpers';

export enum JobStatus {
  Queued = 'Queued',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum ResetUploadCSVType {
  UploadTransactionsJob = 'uploadTransactionsJob',
  UploadConvertibleNotesJob = 'uploadConvertibleNotesJob',
  UploadSecurityHoldersJob = 'uploadSecurityHoldersJob',
  UploadBondsAndLoansJob = 'uploadBondsAndLoansJob',
}

export enum TemplateType {
  Transactions = 'transactions',
  ConvertibleNotes = 'convertible-notes',
  SecurityHolders = 'security-holders',
  BondsAndLoans = 'bonds-and-loans',
}

type UploadCSVJob = AsyncState<{
  id?: string;
  status?: JobStatus.Failed | JobStatus.Completed | JobStatus.Processing | JobStatus.Queued;
  type?: string;
  message?: string;
  payload?: any;
}>;

export type UploadCSVJobsState = {
  uploadTransactionsJob: UploadCSVJob;
  uploadConvertibleNotesJob: UploadCSVJob;
  uploadSecurityHoldersJob: UploadCSVJob;
  uploadBondsAndLoansJob: UploadCSVJob;
};

const initialUploadJobState = {
  loading: false,
  error: undefined,
  data: {
    id: undefined,
    status: undefined,
    type: undefined,
    message: undefined,
    payload: undefined,
  },
};
const initialState: UploadCSVJobsState = {
  uploadTransactionsJob: initialUploadJobState,
  uploadConvertibleNotesJob: initialUploadJobState,
  uploadSecurityHoldersJob: initialUploadJobState,
  uploadBondsAndLoansJob: initialUploadJobState,
};

export default initialState;
