import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'helpers';

function* getUsers() {
  try {
    const { data } = yield call(request, API.Users(), 'GET', undefined, { timeout: 30000 });

    yield put(actions.getUsersSuccess(data));
  } catch (e) {
    yield put(actions.getUsersError(e));
  }
}

function* getUsersWatcher() {
  yield takeEvery(constants.GET_USERS, getUsers);
}

function* updateUser({ userUuid, data, form, onSuccess, showEmailModal }) {
  try {
    const { data: userData } = yield call(request, API.UpdateUser(userUuid), 'PUT', data);

    yield onSuccess(userData);
    yield put(actions.hideSideForm());
    if (showEmailModal) yield showEmailModal();
  } catch (e) {
    if (e.status === 400) {
      yield form.setErrors(e.response.details);
      yield form.setSubmitting(false);
    }
  }
}

function* updateUserWatcher() {
  yield takeEvery(constants.UPDATE_USER, updateUser);
}

function* deleteUser({ userUuid }) {
  try {
    yield call(request, API.DeleteUser(userUuid), 'DELETE');

    yield put(actions.getUsers());
    yield put(actions.hideModal());
    yield put(actions.hideSideForm());
  } catch (e) {
    yield put(actions.deleteUserError(e));
  }
}

function* deleteUserWatcher() {
  yield takeEvery(constants.DELETE_USER, deleteUser);
}

function* createUser({ data, showUserAddedModal, form }) {
  try {
    yield call(request, API.CreateUser(), 'POST', data);

    yield put(actions.getUsers());
    yield showUserAddedModal(true);
    yield put(actions.hideSideForm());
  } catch (e) {
    if (e.status === 400) {
      yield form.setErrors(e.response.details);
      yield form.setSubmitting(false);
    }
    yield put(actions.hideModal());
  }
}

function* createUserWatcher() {
  yield takeEvery(constants.CREATE_USER, createUser);
}

function* checkCreateUserPermission({ companyUuid, handleResponse }) {
  try {
    const {
      data: { result },
    } = yield call(request, API.CheckCreateUserPermission(companyUuid));

    yield handleResponse(result);
  } catch (e) {
    yield put(actions.checkCreateUserPermissionError(e));
  }
}

function* checkCreateUserPermissionWatcher() {
  yield takeEvery(constants.CHECK_CREATE_USER_PERMISSION, checkCreateUserPermission);
}

export {
  getUsers,
  getUsersWatcher,
  updateUser,
  updateUserWatcher,
  createUser,
  createUserWatcher,
  deleteUser,
  deleteUserWatcher,
  checkCreateUserPermission,
  checkCreateUserPermissionWatcher,
};
