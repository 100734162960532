import { COLORS } from 'shared-parts/constants';

const TeamIcon = (fillColor = COLORS.highlight, height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="UI-Style-Guide">
          <g id="Group_3777" data-name="Group 3777">
            <rect
              id="Rectangle_2314"
              data-name="Rectangle 2314"
              className="icon"
              width="40"
              height="40"
              rx="2"
            />
            <g id="Group_3775" data-name="Group 3775">
              <path
                id="Path_1558"
                fill="#eeeff3"
                d="M27.26,12H13.76A1.74,1.74,0,0,0,12,13.76V27.24A1.74,1.74,0,0,0,13.76,29H27.24A1.74,1.74,0,0,0,29,27.24V13.76a1.74,1.74,0,0,0-.5-1.25A1.72,1.72,0,0,0,27.26,12ZM20.5,15.44a2.48,2.48,0,0,1,1.75,4.25,2.49,2.49,0,1,1-1.75-4.25ZM16,25.65a4.51,4.51,0,0,1,9,0Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TeamIcon;
