import styled, { keyframes } from 'styled-components';

import { COLORS } from 'shared-parts/constants';
import FormInvalidIcon from 'shared-parts/images/form-invalid.svg';
import FormValidIcon from 'shared-parts/images/form-valid.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div<{
  zIndex?: string | number;
  isLocal: boolean;
  whiteBackground: boolean;
}>`
  height: 100%;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex || '10000'};
  background-color: ${({ whiteBackground }) =>
    whiteBackground ? 'rgba(255,255,255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
  position: ${({ isLocal }) => (isLocal ? 'relative' : 'fixed')};
`;

const Spinner = styled.div<{
  isLocal: boolean;
  isDone?: boolean;
  globalSpinnerSize: number;
  localSpinnerSize: number;
  withMessage: boolean;
  positionUnset?: boolean;
  localSpinnerBorderSize?: number;
  globalSpinnerBorderSize?: number;
}>`
  ${({
    isLocal,
    isDone,
    globalSpinnerSize,
    localSpinnerSize,
    localSpinnerBorderSize = 8,
    globalSpinnerBorderSize = 8,
  }) =>
    isLocal
      ? `
    border: ${localSpinnerBorderSize}px solid ${COLORS.neutral500};
    border-top: ${localSpinnerBorderSize}px solid ${COLORS.alternative};
    height: ${localSpinnerSize}px;
    width: ${localSpinnerSize}px;
    left: calc(50% - ${localSpinnerSize / 2}px);
    top: calc(50% - ${localSpinnerSize / 2}px);
  `
      : `
    border: ${globalSpinnerBorderSize}px solid ${COLORS.neutral500};
    border-top: ${globalSpinnerBorderSize}px solid ${COLORS.alternative};
    height: ${globalSpinnerSize}px;
    width: ${globalSpinnerSize}px;
    left: calc(50% - ${globalSpinnerSize / 2}px);
    top: calc(50% - ${globalSpinnerSize / 2}px);

    ${isDone && `border-color: ${COLORS.alternative}`}
  `}
  animation: ${rotate} 2s linear infinite;
  border-radius: 50%;
  ${({ positionUnset }) => !positionUnset && 'position: absolute;'}
  -webkit-animation: ${rotate} 2s linear infinite;
  z-index: 10000;

  ${({ withMessage }) => withMessage && `background: ${COLORS.white}`}
`;

const Message = styled.div`
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  position: absolute;
  color: ${COLORS.neutral800};
  width: 80px;
  height: 80px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;
  z-index: 100000;
`;

const Icon = styled.div<{
  isSuccess: boolean;
  showIcon: boolean;
}>`
  background: url(${({ isSuccess }) => (isSuccess ? FormValidIcon : FormInvalidIcon)}) no-repeat
    scroll 30px;
  background-size: 20px 20px;
  height: 20px;
  width: 100%;

  ${({ showIcon }) => !showIcon && 'display: none'}
`;

export { SpinnerContainer, Spinner, Message, Icon };
