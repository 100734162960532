import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import {
  createCompanySummaryError,
  createCompanySummarySuccess,
  editCompanyError,
  editCompanySuccess,
  editCompanySummaryError,
  editCompanySummarySuccess,
  getCompaniesError,
  getCompaniesSuccess,
  getCompany as getCompanyAction,
  getCompanyError,
  getCompanySuccess,
  getCompanySummaryError,
  getCompanySummarySuccess,
  getCompanyTagsError,
  getCompanyTagsSuccess,
  updateCompaniesHouseAuthCodeError,
  updateCompaniesHouseAuthCodeSuccess,
  updateCompanyViewPermissionsError,
  updateCompanyViewPermissionsSuccess,
  verifyCompanyKybError,
  verifyCompanyKybSuccess,
} from 'app-state/actions/companies';
import { getShareClassesSuccess } from 'app-state/actions/share-class';
import { hideModal, hideSideForm, showModal, updateNickelledUser } from 'app-state/actions/shared';
import {
  CREATE_COMPANY_SUMMARY,
  EDIT_COMPANY,
  EDIT_COMPANY_SUMMARY,
  FETCH_COMPANY,
  GET_COMPANIES,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_SUMMARY,
  GET_COMPANY_TAGS,
  UPDATE_COMPANIES_HOUSE_AUTH_CODE,
  UPDATE_COMPANY_VIEW_PERMISSIONS,
  VERIFY_COMPANY_KYB,
} from 'app-state/constants/companies';
import { hideLoader, showLoader as showLoaderAction } from 'app-state/loader/actions';
import { getCompany as getCompanySelector } from 'app-state/selectors/companies';

import IndicativeShareValueViewModal from 'modules/company/equity-administration/shareholder-registry/components/view-permission/indicative-share-value-view-modal';
import API from 'constants/api';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ErrorModal from 'shared-parts/components/modal-failed';
import request from 'shared-parts/helpers/request';

function* getCompanies() {
  try {
    const { data } = yield call(request, API.Companies());

    yield put(getCompaniesSuccess(data));
  } catch (e) {
    yield put(getCompaniesError(e));
  }
}

function* getCompaniesWatcher() {
  yield takeEvery(GET_COMPANIES, getCompanies);
}

function* getCompany({ companyId, isExternal }) {
  try {
    const { data } = yield call(request, API.Company(companyId));

    yield put(getShareClassesSuccess(data.shareClasses));
    yield put(getCompanySuccess(data));
    if (!isExternal) {
      yield put(updateNickelledUser());
    }
  } catch (e) {
    yield put(getCompanyError(e));
  }
}

function* getCompanyWatcher() {
  yield takeEvery(GET_COMPANY, getCompany);
}

function* editCompany({ payload: { companyId, formData, onSuccess, form } }) {
  try {
    const { data } = yield call(request, API.EditCompany(companyId), 'PUT', formData);

    if (onSuccess) {
      yield onSuccess();
    }
    yield put(editCompanySuccess(data));
    yield put(hideSideForm());
  } catch (e) {
    if (e.response.details) {
      yield form.setErrors(e.response.details);
      yield form.setSubmitting(false);
    }
    yield put(editCompanyError(e));
  }
}

function* editCompanyWatcher() {
  yield takeEvery(EDIT_COMPANY, editCompany);
}

function* fetchCompany({ companyUuid }) {
  try {
    const { data } = yield call(request, API.Company1(companyUuid));

    yield put(getCompanySuccess(data));
  } catch (e) {
    yield put(getCompanyError(e));
  }
}

function* fetchCompanyWatcher() {
  yield takeEvery(FETCH_COMPANY, fetchCompany);
}

function* updateCompaniesHouseAuthCode({ companyId, companyUuid, formData }) {
  yield put(showLoaderAction());

  try {
    yield call(request, API.SetCompaniesHouseAuthCode(companyUuid), 'POST', formData);
    yield put(getCompanyAction(companyId));
    yield put(updateCompaniesHouseAuthCodeSuccess());
  } catch (e) {
    yield put(updateCompaniesHouseAuthCodeError(e));
  }

  yield put(hideLoader());
}

function* updateCompaniesHouseAuthCodeWatcher() {
  yield takeEvery(UPDATE_COMPANIES_HOUSE_AUTH_CODE, updateCompaniesHouseAuthCode);
}

const withCompany = (saga, errorAction = () => {}, showLoader = false) =>
  function* (payload) {
    if (showLoader) yield put(showLoaderAction());

    try {
      const { loading, data: companyData } = yield select(getCompanySelector);

      if (loading) {
        yield take(GET_COMPANY_SUCCESS);

        const { data } = yield select(getCompanySelector);

        yield* saga({ ...payload, company: data });
      } else {
        yield* saga({ ...payload, company: companyData });
      }
    } catch (e) {
      yield put(errorAction(e));
    }

    if (showLoader) yield put(hideLoader());
  };

function* getCompanyTags({ companyUuid }) {
  try {
    const { data } = yield call(request, API.GetCompanyTags(companyUuid));

    yield put(getCompanyTagsSuccess(data));
  } catch (e) {
    yield put(getCompanyTagsError(e));
  }
}

function* getCompanyTagsWatcher() {
  yield takeEvery(GET_COMPANY_TAGS, getCompanyTags);
}

function* updateCompanyViewPermissions({ companyId, formData }) {
  try {
    const { data } = yield call(
      request,
      API.UpdateCompanyViewPermissions(companyId),
      'PUT',
      formData,
    );

    yield put(updateCompanyViewPermissionsSuccess(data));
    yield put(
      showModal({
        showHeader: false,
        closable: false,
        component: IndicativeShareValueViewModal,
        hideModal,
        indicativeShareValueEnabled: formData.displayIndicativeShareValue,
      }),
    );
  } catch (e) {
    yield put(updateCompanyViewPermissionsError(e));
  }
}

function* updateCompanyViewPermissionsWatcher() {
  yield takeEvery(UPDATE_COMPANY_VIEW_PERMISSIONS, updateCompanyViewPermissions);
}

function* verifyCompanyKyb({ companyId, platformName }) {
  try {
    const { data } = yield call(request, API.KybVerification(companyId), 'PUT');

    yield put(verifyCompanyKybSuccess(data));
    yield put(
      showModal({
        component: SuccessModal,
        closable: true,
        showHeader: false,
        modalWidth: 580,
        title: `Verification check submitted to ${platformName}`,
        additionalText: `You will receive an email from ${platformName} with guidelines for KYC/KYB submission. Once you pass the verification check, you will be able to setup, publish and manage a placement.`,
      }),
    );
  } catch (e) {
    yield put(verifyCompanyKybError(e));

    yield put(
      showModal({
        closable: true,
        component: ErrorModal,
      }),
    );
  }
}

function* verifyCompanyKybWatcher() {
  yield takeEvery(VERIFY_COMPANY_KYB, verifyCompanyKyb);
}

function* createCompanySummary({ companyUuid, body }) {
  try {
    const { data } = yield call(request, API.CompanySummary.Create(companyUuid), 'POST', body);

    yield put(createCompanySummarySuccess(data));

    yield put(hideSideForm());

    yield put(
      showModal({
        closable: true,
        component: SuccessModal,
        modalWidth: '580',
        title: 'Company summary added',
      }),
    );
  } catch (e) {
    yield put(createCompanySummaryError(e));

    yield put(
      showModal({
        closable: true,
        component: ErrorModal,
      }),
    );
  }
}

function* createCompanySummaryWatcher() {
  yield takeEvery(CREATE_COMPANY_SUMMARY, createCompanySummary);
}

function* editCompanySummary({ companyUuid, body }) {
  try {
    const { data } = yield call(request, API.CompanySummary.Edit(companyUuid), 'PUT', body);

    yield put(editCompanySummarySuccess(data));

    yield put(
      showModal({
        closable: true,
        component: SuccessModal,
        modalWidth: '580',
        title: 'Company summary updated',
      }),
    );
  } catch (e) {
    yield put(editCompanySummaryError(e));

    yield put(
      showModal({
        closable: true,
        component: ErrorModal,
      }),
    );
  }
}

function* editCompanySummaryWatcher() {
  yield takeEvery(EDIT_COMPANY_SUMMARY, editCompanySummary);
}

function* getCompanySummary({ companyUuid }) {
  try {
    const { data } = yield call(request, API.CompanySummary.Get(companyUuid), 'GET');

    yield put(getCompanySummarySuccess(data));
  } catch (e) {
    yield put(getCompanySummaryError(e));
  }
}

function* getCompanySummaryWatcher() {
  yield takeEvery(GET_COMPANY_SUMMARY, getCompanySummary);
}

export {
  getCompanies,
  getCompaniesWatcher,
  getCompany,
  getCompanyWatcher,
  editCompany,
  editCompanyWatcher,
  fetchCompany,
  fetchCompanyWatcher,
  updateCompaniesHouseAuthCode,
  updateCompaniesHouseAuthCodeWatcher,
  withCompany,
  getCompanyTags,
  getCompanyTagsWatcher,
  updateCompanyViewPermissionsWatcher,
  verifyCompanyKyb,
  verifyCompanyKybWatcher,
  createCompanySummaryWatcher,
  editCompanySummaryWatcher,
  getCompanySummaryWatcher,
};
