import { ModalText, ModalTitle, ModalWrapper, SubmitButton } from './warning-modal.styled';

const WarningModal = ({ hideModal, title, text }) => (
  <ModalWrapper>
    <ModalTitle>{title}</ModalTitle>
    <ModalText>{text}</ModalText>
    <SubmitButton onClick={hideModal}>CONTINUE</SubmitButton>
  </ModalWrapper>
);

export default WarningModal;
