import { Box, BoxProps, Typography } from '@mui/material';

export const WidgetTitle = (props: BoxProps) => {
  const { children, ...rest } = props;

  return (
    <Box {...rest}>
      <Typography fontSize={16} fontWeight={700}>
        {children}
      </Typography>
    </Box>
  );
};
