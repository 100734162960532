import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { confirmEmail as confirmEmailAction } from 'app-state/actions/shared';
import {
  hideLoader as hideLoaderAction,
  showLoader as showLoaderAction,
} from 'app-state/loader/actions';

const ConfirmEmail = ({ match, showLoader, confirmEmail, hideLoader }) => {
  useEffect(() => {
    showLoader();
    confirmEmail(match.params.emailToken);

    return () => {
      hideLoader();
    };
  }, []);

  return <Fragment />;
};

const mapDispatchToProps = dispatch => ({
  confirmEmail: emailToken => dispatch(confirmEmailAction(emailToken)),
  showLoader: () => dispatch(showLoaderAction()),
  hideLoader: () => dispatch(hideLoaderAction()),
});

export default connect(null, mapDispatchToProps)(ConfirmEmail);
