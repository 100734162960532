import { Action } from 'redux';

import { GridInitialState } from '@frontend/design-system';

export const SET_GRID_CONFIG = 'SET_GRID_CONFIG';

export const setGridConfig = (payload: {
  tableId: string;
  currentState: GridInitialState;
}): Action<string> & {
  payload: {
    tableId: string;
    currentState: GridInitialState;
  };
} => {
  return {
    type: SET_GRID_CONFIG,
    payload,
  };
};
