import HelloSign from 'hellosign-embedded';
import { eventChannel } from 'redux-saga';
import { all, put, take } from 'redux-saga/effects';

import { useEnvVar } from 'helpers/use-env-var';

const helloSignClient = new HelloSign();

export function* openHelloSignClientIframe({ editUrl, doneActions = [] }) {
  helloSignClient.open(editUrl, {
    clientId: useEnvVar('HELLO_SIGN_CLIENT_ID'),
    skipDomainVerification: true,
  });

  const channel = eventChannel(emitter => {
    helloSignClient.on('close', () => {
      emitter('closed');
    });

    // To cleanup up
    return () => {};
  });

  while (true) {
    const iFrameStatus = yield take(channel);
    if (iFrameStatus === 'closed') {
      yield all(doneActions.map(action => put(action)));
      break;
    }
  }
}
