import { COLORS } from 'shared-parts/constants';
import {
  BE_STATUSES,
  INDICATION_OF_INTEREST_STATUSES,
  ORDER_TYPES,
  REVIEW_STATUSES,
  STATUSES,
} from 'shared-parts/constants/order-statuses';

const { systemOrange, systemGreen, systemRed, neutral500 } = COLORS;

const INDICATION_OF_INTEREST_ORDER_STATUSES = regulatoryEnvironment => ({
  [BE_STATUSES.PLACED]: {
    status: INDICATION_OF_INTEREST_STATUSES.INDICATION_OF_INTEREST,
    color: systemOrange,
  },
  [BE_STATUSES.CANCELLED]: {
    status: STATUSES.CANCELLED,
    color: systemRed,
  },
  [BE_STATUSES.ADJUSTED]: {
    status: INDICATION_OF_INTEREST_STATUSES.INDICATION_OF_INTEREST,
    color: systemOrange,
  },
  [BE_STATUSES.APPROVED]: {
    status: INDICATION_OF_INTEREST_STATUSES.INDICATION_OF_INTEREST,
    color: systemGreen,
  },
  [BE_STATUSES.COMPLETED]: {
    status:
      regulatoryEnvironment.value === 'FINRA' ? STATUSES.ORDER_INVESTMENT_REQUEST : STATUSES.ORDER,
    color: systemOrange,
  },
});

const UNKNOWN_ORDER_STATUS = { status: STATUSES.UNKNOWN, color: neutral500 };

export const getOrderStatus = ({
  status,
  reviewStatus,
  type = ORDER_TYPES.ORDER,
  deal,
  regulatoryEnvironment,
}) => {
  if (type === ORDER_TYPES.IOI) {
    return {
      ...(INDICATION_OF_INTEREST_ORDER_STATUSES(regulatoryEnvironment)[status] ||
        UNKNOWN_ORDER_STATUS),
    };
  }
  if (status === BE_STATUSES.PLACED && reviewStatus === REVIEW_STATUSES.PENDING) {
    return {
      status:
        regulatoryEnvironment.value === 'FINRA'
          ? STATUSES.ORDER_INVESTMENT_REQUEST
          : STATUSES.ORDER,
      color: systemOrange,
    };
  }
  if (
    status === BE_STATUSES.PLACED &&
    reviewStatus === REVIEW_STATUSES.APPROVED &&
    deal?.spaRequired
  ) {
    return { status: STATUSES.SIGN_AGREEMENT, color: systemOrange };
  }
  if ([BE_STATUSES.PLACED_AGREEMENT].includes(status)) {
    return {
      status: STATUSES.SIGN_AGREEMENT,
      color: systemOrange,
    };
  }
  if (
    [BE_STATUSES.SIGNED_AGREEMENT, BE_STATUSES.PLACED, BE_STATUSES.PARTIALLY_FILLED].includes(
      status,
    )
  ) {
    return { status: STATUSES.TRANSFER_FUNDS, color: systemOrange };
  }
  if ([BE_STATUSES.FILLED, BE_STATUSES.COMPLETED].includes(status)) {
    return { status: STATUSES.AWAITING_SETTLEMENT, color: systemOrange };
  }
  if (status === BE_STATUSES.SECURITIES_ALLOCATED) {
    return { status: STATUSES.TRANSACTION_COMPLETED, color: systemGreen };
  }
  if (status === BE_STATUSES.CANCELLED) {
    return { status: STATUSES.CANCELLED, color: systemRed };
  }
  return { ...UNKNOWN_ORDER_STATUS };
};

export const getAlternativeInvestmentOrderStatus = ({
  status,
  reviewStatus,
  deal,
  theme,
  paymentMethod,
}) => {
  const { dealType } = deal;
  if (dealType !== 'alternative_investment_vehicle') {
    return { ...UNKNOWN_ORDER_STATUS };
  }
  const isUpfrontPayment = paymentMethod === 'upfront_payment';
  if (status === BE_STATUSES.PLACED && reviewStatus === REVIEW_STATUSES.PENDING) {
    return {
      status: STATUSES.ORDER,
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light,
    };
  }
  if (
    [BE_STATUSES.SIGNED_AGREEMENT, BE_STATUSES.PLACED, BE_STATUSES.PARTIALLY_FILLED].includes(
      status,
    )
  ) {
    return {
      status: isUpfrontPayment ? STATUSES.TRANSFER_FUNDS : STATUSES.APPROVED,
      color: isUpfrontPayment ? theme.palette.highlight.dark : theme.palette.success.dark,
      bgColor: isUpfrontPayment ? theme.palette.highlight.light : theme.palette.success.light,
    };
  }
  if ([BE_STATUSES.FILLED, BE_STATUSES.SECURITIES_ALLOCATED].includes(status)) {
    return {
      status: STATUSES.TRANSACTION_COMPLETED,
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light,
    };
  }
  if (status === BE_STATUSES.AWAITING_CLOSING) {
    return {
      status: STATUSES.AWAITING_CLOSING,
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light,
    };
  }
  if (status === BE_STATUSES.COMPLETED) {
    return {
      status: STATUSES.CLOSED,
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light,
    };
  }
  if (status === BE_STATUSES.CANCELLED) {
    return {
      status: STATUSES.CANCELLED,
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light,
    };
  }
  return { ...UNKNOWN_ORDER_STATUS };
};
