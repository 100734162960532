import type { FC } from 'react';

import { useEnvVar } from '@frontend/config';

import StyledChevrons from './chevrons.styled';
import { Props } from './types';

const isChevronBrandingEnabled = useEnvVar('ENABLED_CHEVRON_BRANDING');

export const Chevrons: FC<React.PropsWithChildren<Props>> = ({ width, unit, position, bottom }) => {
  if (!isChevronBrandingEnabled) return null;

  return (
    <>
      <StyledChevrons width={width} unit={unit} position={position} bottom={bottom} isFullSize />
      <StyledChevrons width={width} unit={unit} position={position} bottom={bottom} />
    </>
  );
};
