import { FinishIcon } from 'svg-icons';
import { FormikButton } from 'shared-parts/components';

import {
  IntroducerFeesSettingsModalWrapper,
  ModalText,
  ModalTitle,
} from './introducer-fees-settings-modal.styled';

const IntroducerFeesSettingsModal = ({ hideModal }) => (
  <IntroducerFeesSettingsModalWrapper>
    {FinishIcon()}
    <ModalTitle>Introducer fees</ModalTitle>
    <ModalText>All secondary transactions will now have introducer fees applied.</ModalText>
    <FormikButton onClick={hideModal}>OK</FormikButton>
  </IntroducerFeesSettingsModalWrapper>
);

export default IntroducerFeesSettingsModal;
