const ChevronDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
    <path
      id="Path_1937"
      data-name="Path 1937"
      d="M5.434,32.64a1.493,1.493,0,0,1-1.075-.43l-3.957-4A1.455,1.455,0,0,1,.4,26.1a1.455,1.455,0,0,1,2.108,0l2.925,2.925L8.359,26.1a1.521,1.521,0,0,1,2.151,2.151L6.553,32.21A1.913,1.913,0,0,1,5.434,32.64Z"
      transform="translate(0.05 -25.65)"
      fill="#b3b5ba"
    />
  </svg>
);

export default ChevronDown;
