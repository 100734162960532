import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { showModal } from 'app-state/actions/shared';
import {
  getDealsError,
  getDealsSuccess,
  getUserDealsError,
  getUserDealsSuccess,
  setDealStructureFormError,
} from 'app-state/deals/actions';
import { CREATE_DEAL, GET_DEALS, GET_USER_DEALS } from 'app-state/deals/constants';
import type { AccessType, CreateDealAction, Deal } from 'app-state/deals/types';
import { getUser } from 'app-state/selectors';
import { getCompany as getCompanySelector } from 'app-state/selectors/companies';

import { PLACEMENT_DETAILS } from 'modules/company/deal-management/deal-form/constants';
import getCreateDealSuccessCustomActions from 'modules/company/deal-management/deal-form/create-deal-success-modal-buttons';
import API from 'constants/api';
import { getDealAlias } from 'helpers/get-deal-alias';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import request from 'shared-parts/helpers/request';

function* getDeals(): Generator<StrictEffect, void, { data: Deal[] }> {
  try {
    const { data } = yield call(request, API.Deals());

    yield put(getDealsSuccess(data));
  } catch (e: any) {
    yield put(getDealsError(e));
  }
}

function* getDealsWatcher(): Generator<ForkEffect> {
  yield takeEvery(GET_DEALS, getDeals);
}

function* getUserDeals({ payload }: any): any {
  try {
    const user = yield select(getUser);
    const response: { data: { items: { accessType: AccessType; issuance: Deal }[] } } = yield call(
      request,
      API.UserDeals(payload.companyUuid, user.uuid),
    );

    const items = response.data.items.map(({ accessType, issuance }) => ({
      ...issuance,
      accessType,
    }));

    yield put(getUserDealsSuccess(items));
  } catch (e: any) {
    yield put(getUserDealsError(e));
  }
}

function* getUserDealsWatcher(): Generator<ForkEffect> {
  yield takeEvery(GET_USER_DEALS, getUserDeals);
}

function* createDeal({ payload }: CreateDealAction) {
  try {
    const { values } = payload;
    const params = {
      ...values,
      [PLACEMENT_DETAILS.AUTO_CANCEL_ORDERS]: values[PLACEMENT_DETAILS.AUTO_CANCEL_ORDERS] === 'On',
      [PLACEMENT_DETAILS.AUTO_APPROVE_ORDERS]:
        values[PLACEMENT_DETAILS.AUTO_APPROVE_ORDERS] === 'On',
      [PLACEMENT_DETAILS.SPA_REQUIRED]: values[PLACEMENT_DETAILS.SPA_REQUIRED] === 'Yes',
    };
    const { data: companyData } = yield select(getCompanySelector);
    const {
      data: { uuid },
    } = yield call(request, API.CreateDeal(companyData.uuid), 'POST', params);

    const dealAlias = getDealAlias();
    yield put(
      showModal({
        component: SuccessModal,
        closable: true,
        title: `New ${dealAlias} created`,
        additionalText: `You will need to upload any documents required before the ${dealAlias} goes live.`,
        customActionsBlock: getCreateDealSuccessCustomActions({
          companyId: companyData.id,
          dealUuid: uuid,
        }),
      }),
    );
  } catch (e: any) {
    if (e.status === 400) {
      yield put(setDealStructureFormError(e.response.details));
    }
  }
}

function* createDealWatcher() {
  yield takeEvery(CREATE_DEAL, createDeal);
}

export { getDeals, getDealsWatcher, createDealWatcher, getUserDealsWatcher };
