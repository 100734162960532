import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { alternative, neutral500, neutral800, neutral300, white, secondary, primary } = COLORS;

const Label = styled.label`
  margin: 0 8px 0 0;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
  line-height: 19px;
  color: ${neutral800};
  user-select: none;
`;

const Container = styled.div`
  position: relative;
  font-family: ${FONTS.mainFont};
`;

const Input = styled.input`
  height: 28px;
  width: 140px;
  background: ${white};
  border-radius: 6px;
  border: 1px solid ${neutral300};
  padding: 4px 25px 6px 8px;
  color: ${primary};
  font-size: 14px;
  line-height: 22px;

  ::placeholder {
    color: ${neutral500};
  }

  :focus {
    outline: none;
    border: 1px solid ${secondary};
  }

  :disabled {
    background-color: ${neutral300};
    border-color: ${neutral300};
  }
`;

const SearchButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  ${({ disabled }) => (disabled ? 'pointer-events: none; cursor: normal;' : '')}
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  color: ${alternative};
  padding: 5px 10px;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
`;

export { Label, Container, Input, SearchButton, ClearButton };
