export type KycKybStatus =
  | ''
  | 'pending'
  | 'user_responding'
  | 'in_progress'
  | 'failed'
  | 'completed'
  | 'postponed'
  | 'failed_with_retry';

export enum KycKybStatusEnum {
  PENDING = 'pending',
  USER_RESPONDING = 'user_responding',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  COMPLETED = 'completed',
  POSTPONED = 'postponed',
  FAILED_WITH_RETRY = 'failed_with_retry',
}
