import { call, put, takeEvery } from 'redux-saga/effects';

import { getTreasuryAccountError, getTreasuryAccountSuccess } from 'app-state/actions';
import { GET_TREASURY_ACCOUNT } from 'app-state/constants';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getTreasuryAccount({ companyUuid }) {
  try {
    const { data } = yield call(request, API.TreasuryAccount(companyUuid), 'GET');

    yield put(getTreasuryAccountSuccess(data));
  } catch (e) {
    yield put(getTreasuryAccountError(e));
  }
}

function* getTreasuryAccountWatcher() {
  yield takeEvery(GET_TREASURY_ACCOUNT, getTreasuryAccount);
}

export { getTreasuryAccount, getTreasuryAccountWatcher };
