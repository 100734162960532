import * as constants from 'app-state/constants';

export const initialShareClassesState = { loading: true, data: [], error: null };

export const shareClassesReducer = (state = initialShareClassesState, action) => {
  switch (action.type) {
    case constants.GET_SHARE_CLASSES:
      return initialShareClassesState;
    case constants.GET_SHARE_CLASSES_SUCCESS:
    case constants.UPDATE_TRANSACTIONS_PERMISSION_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_SHARE_CLASSES_ERROR:
      return { loading: false, data: [], error: action.error };
    default:
      return state;
  }
};

export const convertSharesErrorReducer = (state = null, action) => {
  switch (action.type) {
    case constants.CONVERT_SHARES_ERROR:
      return action.error;
    default:
      return state;
  }
};
