import { call, put, takeEvery } from 'redux-saga/effects';

import { showModal } from 'app-state/actions/shared';

import API from 'constants/api';
import ModalFailed from 'shared-parts/components/modal-failed';
import request from 'shared-parts/helpers/request';

import { getTimezonesSuccess } from './actions';
import { GET_TIMEZONES } from './constants';

function* getTimezones() {
  try {
    const { data } = yield call(request, API.Timezones(), 'GET', null, {
      camelCase: false,
    });

    yield put(getTimezonesSuccess(data));
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
        modalWidth: 800,
      }),
    );
  }
}

function* getTimezonesWatcher() {
  yield takeEvery(GET_TIMEZONES, getTimezones);
}

export { getTimezonesWatcher };
