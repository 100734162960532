import humps from 'humps';

export default function generateQueryString(
  apiParams,
  encodeParamsNames = [],
  decamelize = true,
  transformIntoRubyArray = [],
) {
  if (!Object.keys(apiParams).length) {
    return '';
  }

  const queryString = Object.entries(apiParams)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      const formattedKey = decamelize ? humps.decamelize(key) : key;
      if (transformIntoRubyArray.includes(key)) {
        const arrayQueryString = value
          .map(
            val =>
              `${formattedKey}[]=${
                encodeParamsNames.includes(key) ? encodeURIComponent(val) : val
              }`,
          )
          .join('&');
        return arrayQueryString;
      }

      const paramValue = encodeParamsNames.includes(key)
        ? encodeURIComponent(typeof value === 'string' ? value : JSON.stringify(value))
        : value;

      return `${formattedKey}=${paramValue}`;
    })
    .join('&');

  return `?${queryString}`;
}
