import { push, replace } from 'connected-react-router';
import { sessionService } from 'redux-react-session';

import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import { removeRedirectPath } from 'app-state/actions/shared';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';

import API from 'constants/api';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import request from 'shared-parts/helpers/request';

function* saveUserDataSaga({ user }) {
  yield call(sessionService.saveUser, user);
  yield call(sessionService.saveSession, { token: user.authToken });
  const redirectTo = yield select(selectors.getRedirectPath);

  if (redirectTo) {
    yield put(push(redirectTo));
    yield put(removeRedirectPath());
  }
}

function* saveUserDataWatcher() {
  yield takeEvery(constants.SAVE_USER_DATA, saveUserDataSaga);
}

function* signUp({ params: { newEmail, password, actionToken, referrerCode } }) {
  try {
    const requestParams = {
      email: newEmail,
      password,
      actionToken,
    };
    const { data } = yield call(request, API.SignUp(), 'POST', requestParams);
    yield put(actions.signUpSuccess({ email: newEmail }));
    yield localStorage.removeItem('actionToken');
    yield localStorage.removeItem('referrer');
    yield localStorage.removeItem('hasVisitedLandingPage');
    const gtmEventData = {
      referrer: referrerCode,
    };
    const user = {
      email: newEmail,
      id: data.id,
    };
    yield gtmTrack(gtmEventData, 'GCUserSignUp', user);
  } catch (e) {
    yield put(actions.signUpError(e.response.details));
  }
}

function* signUpWatcher() {
  yield takeEvery(constants.SIGN_UP, signUp);
}

function* login({ params, setErrors, setSubmitting, setIsTwoFactorAuth }) {
  try {
    const { data } = yield call(request, API.Login(), 'POST', params, { ignoredErrorCodes: [403] });
    setSubmitting(false);
    yield localStorage.setItem('userUuid', data.uuid);
    yield call(saveUserDataSaga, actions.saveUserData(data));
    yield put(actions.loginSuccess(data));
    yield gtmTrack({}, 'GCUserSignIn', data);
  } catch (e) {
    const getErrorKey = () => {
      if (params.recoveryCode) return 'recoveryCode';
      if (params.otpCode) return 'otpCode';

      return 'password';
    };
    const errorDetails = e.response ? e.response.details : { requestError: e };
    const errorObject =
      e.status === 403 ? { [getErrorKey()]: ['Your account has been locked'] } : errorDetails;

    if (e.status === 400 && errorObject.otpEnabled) {
      setIsTwoFactorAuth(true);
    }

    setSubmitting(false);
    setErrors(errorObject);
  }
}

function* oauth2Login({ setErrors }) {
  try {
    const { data } = yield call(request, API.CurrentUser(), 'GET');

    yield localStorage.setItem('userUuid', data.uuid);
    yield call(saveUserDataSaga, actions.saveUserData(data));
    yield put(actions.loginSuccess(data));

    yield gtmTrack({}, 'GCUserSignIn', data);
  } catch (e) {
    const getErrorKey = () => {
      return 'password';
    };
    const errorDetails = e.response ? e.response.details : { requestError: e };
    const errorObject =
      e.status === 403 ? { [getErrorKey()]: ['Your account has been locked'] } : errorDetails;

    setErrors(errorObject);
  }
}

function* loginWatcher() {
  yield takeEvery(constants.LOGIN, login);
}

export function* oauth2LoginWatcher() {
  yield takeEvery(constants.OAUTH2_LOGIN, oauth2Login);
}

function* logout({ isSessionExpired }) {
  try {
    if (!isSessionExpired) {
      yield call(request, API.SignOut(), 'DELETE');
    }
    yield put(actions.hideModal()); // close outstanding modals
    yield put(actions.clearPermissions());
    yield call(sessionService.deleteSession);
    yield call(sessionService.deleteUser);
    yield put(actions.logoutSuccess());
    yield localStorage.removeItem('userUuid');
    if (!isSessionExpired) {
      // Prevents redirect to the last page on manually logged out
      yield put(replace({ state: null }));
    }
  } catch (e) {
    yield put(actions.logoutError(e));
  }
}

function* oauth2Logout() {
  try {
    yield put(push('/signout'));
    yield put(actions.hideModal()); // close outstanding modals
    yield put(actions.clearPermissions());

    yield localStorage.removeItem('userUuid');

    yield call(sessionService.deleteSession);
    yield call(sessionService.deleteUser);

    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const oauth2LogoutRedirectUrl = `${window.config.OAUTH2_LOGOUT_REDIRECT_URL}${
      window.config.OAUTH2_LOGOUT_APPEND_BASE_URL === true ||
      window.config.OAUTH2_LOGOUT_APPEND_BASE_URL === 'true'
        ? encodeURIComponent(baseUrl)
        : ''
    }`;
    const oauth2Url = `${baseUrl}/oauth2/sign_out?rd=${encodeURIComponent(
      oauth2LogoutRedirectUrl,
    )}`;

    window.location.replace(oauth2Url);
  } catch (e) {
    yield put(actions.logoutError(e));
  }
}

function* logoutWatcher() {
  yield takeEvery(constants.LOGOUT, logout);
}

export function* oauth2LogoutWatcher() {
  yield takeEvery(constants.OAUTH2_LOGOUT, oauth2Logout);
}

function* forgotPassword({ setSubmitting, setErrors, form, showEmailSentText }) {
  try {
    yield call(request, API.ForgotPassword(), 'POST', form);
    setSubmitting(false);
    showEmailSentText();
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

function* forgotPasswordWatcher() {
  yield takeEvery(constants.FORGOT_PASSWORD, forgotPassword);
}

function* resetPassword({ setSubmitting, setErrors, formData, token, setIsTwoFactorAuth }) {
  try {
    const resetData = {
      ...formData,
      token,
    };
    const { data } = yield call(request, API.ResetPassword(), 'PUT', resetData);
    setSubmitting(false);
    const loginParams = {
      email: data.email,
      password: formData.password,
      policy: true,
    };

    if (data.authCode) loginParams.otpCode = data.authCode;

    yield put(actions.login({ params: loginParams, setSubmitting, setErrors }));
  } catch (e) {
    const errorDetails = yield e.response.details;

    const getErrorKey = () => {
      if (formData.recoveryCode) return 'recoveryCode';
      if (formData.otpCode) return 'otpCode';

      return 'password';
    };

    setSubmitting(false);

    if (e.status === 400 && errorDetails.otpEnabled) {
      setIsTwoFactorAuth(true);
    } else {
      const errorObject = {
        [getErrorKey()]:
          e.status === 403
            ? ['Your account has been locked']
            : errorDetails.recoveryCode ||
              errorDetails.otpCode ||
              errorDetails.token ||
              errorDetails.password,
      };

      setErrors(errorObject);
    }
  }
}

function* resetPasswordWatcher() {
  yield takeEvery(constants.RESET_PASSWORD, resetPassword);
}

function* twoFactorAuthGetCode() {
  try {
    yield put(actions.twoFactorAuthCodeClear());
    const { data } = yield call(request, API.TwoFactorAuthentication());
    yield put(actions.twoFactorAuthGetCodeSuccess(data));
  } catch (e) {
    yield put(actions.twoFactorAuthGetCodeError(e.response.details));
  }
}

function* twoFactorAuthGetCodeWatcher() {
  yield takeLatest(constants.TWO_FACTOR_AUTH_GET_CODE, twoFactorAuthGetCode);
}

function* twoFactorAuthConfirm({
  data,
  setSubmitting,
  setErrors,
  showTwoFactorAuthBackupCodesModal,
}) {
  try {
    const {
      data: { recoveryCodes },
    } = yield call(request, API.TwoFactorAuthentication(), 'POST', data, {
      ignoredErrorCodes: [403],
    });
    yield put(actions.twoFactorAuthCodeClear());
    const user = yield select(selectors.getUser);
    yield call(
      saveUserDataSaga,
      actions.saveUserData({
        ...user,
        twoFactorAuthEnabled: true,
      }),
    );
    yield put(actions.hideSideForm());
    showTwoFactorAuthBackupCodesModal(recoveryCodes);
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

function* twoFactorAuthConfirmWatcher() {
  yield takeEvery(constants.TWO_FACTOR_AUTH_CONFIRM, twoFactorAuthConfirm);
}

function* twoFactorAuthDisable({ setSubmitting, setErrors }) {
  try {
    yield call(request, API.TwoFactorAuthentication(), 'DELETE');
    const user = yield select(selectors.getUser);
    yield call(
      saveUserDataSaga,
      actions.saveUserData({
        ...user,
        twoFactorAuthEnabled: false,
      }),
    );
    yield put(actions.hideSideForm());
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

function* twoFactorAuthDisableWatcher() {
  yield takeEvery(constants.TWO_FACTOR_AUTH_DISABLE, twoFactorAuthDisable);
}

export {
  saveUserDataSaga,
  signUp,
  signUpWatcher,
  login,
  logout,
  loginWatcher,
  saveUserDataWatcher,
  logoutWatcher,
  forgotPassword,
  forgotPasswordWatcher,
  resetPassword,
  resetPasswordWatcher,
  twoFactorAuthGetCode,
  twoFactorAuthGetCodeWatcher,
  twoFactorAuthConfirm,
  twoFactorAuthConfirmWatcher,
  twoFactorAuthDisable,
  twoFactorAuthDisableWatcher,
};
