import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const permissionsInitialState = getReducerState();

export const permissionsReducer = (state = permissionsInitialState, action) => {
  switch (action.type) {
    case constants.GET_PERMISSIONS:
      return getReducerState({ loading: true, data: {}, error: null });
    case constants.GET_PERMISSIONS_SUCCESS:
      return getReducerState({ loading: false, data: action.data });
    case constants.GET_PERMISSIONS_ERROR:
      return getReducerState({ loading: false, data: {}, error: action.error });
    case constants.CLEAR_PERMISSIONS:
      return permissionsInitialState;
    default:
      return state;
  }
};

export const permissionLevelsInitialState = getReducerState({ data: [] });

export const permissionLevelsReducer = (state = permissionLevelsInitialState, action) => {
  switch (action.type) {
    case constants.GET_PERMISSION_LEVELS_SUCCESS:
      return getReducerState({ loading: false, data: action.data });
    case constants.GET_PERMISSION_LEVELS_ERROR:
      return getReducerState({ loading: false, data: [], error: action.error });
    case constants.GET_PERMISSION_LEVELS:
    default:
      return state;
  }
};
