import styled from 'styled-components';

import GreenCheckmark from 'images/green-checkmark-2.svg';
import FormikButton from 'shared-parts/components/button/formik-button';
import { COLORS, FONTS } from 'shared-parts/constants';

const { primary } = COLORS;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  padding: 50px 240px;
  width: 800px;
`;

const CheckMarkIcon = styled.div`
  background: transparent url(${GreenCheckmark}) 0% 0% no-repeat padding-box;
  height: 65px;
  width: 65px;
`;

const ShareholdersInvited = styled.div`
  color: ${primary};
  font-family: ${FONTS.oswaldFont};
  font-size: 35px;
  font-weight: 200;
  margin-top: 13px;
`;

const ContinueButton = styled(FormikButton)`
  height: 40px;
  margin-top: 30px;
  width: 120px;
`;

export { Wrapper, CheckMarkIcon, ShareholdersInvited, ContinueButton };
