import {
  GET_DEALS_ERROR,
  GET_DEALS_SUCCESS,
  GET_USER_DEALS_ERROR,
  GET_USER_DEALS_SUCCESS,
  RESET_DEAL_STRUCTURE_FORM_ERROR,
  SET_DEAL_STRUCTURE_FORM_ERROR,
} from 'app-state/deals/constants';
import type { DealsActionTypes, DealsState, UserDealsActionTypes } from 'app-state/deals/types';
import { UserDealsState } from 'app-state/deals/types';

export const initialState: DealsState = {
  loading: true,
  data: [],
  errors: null,
};

export const dealsReducer = (state = initialState, action: DealsActionTypes): DealsState => {
  switch (action.type) {
    case GET_DEALS_SUCCESS:
      return { loading: false, data: action.data, errors: null };
    case GET_DEALS_ERROR:
      return { loading: false, data: [], errors: action.errors };
    default:
      return state;
  }
};

const defaultDealStructureFormErrorState = null;

export const dealStructureFormErrorReducer = (
  state = defaultDealStructureFormErrorState,
  action: DealsActionTypes,
) => {
  switch (action.type) {
    case SET_DEAL_STRUCTURE_FORM_ERROR:
      return action.payload;
    case RESET_DEAL_STRUCTURE_FORM_ERROR:
      return defaultDealStructureFormErrorState;
    default:
      return state;
  }
};

const initialUserDealsState: UserDealsState = {
  loading: true,
  data: [],
  errors: null,
};

export const userDealsReducer = (
  state = initialUserDealsState,
  action: UserDealsActionTypes,
): UserDealsState => {
  switch (action.type) {
    case GET_USER_DEALS_SUCCESS:
      return { loading: false, data: action.data, errors: null };
    case GET_USER_DEALS_ERROR:
      return { loading: false, data: [], errors: action.errors };
    default:
      return state;
  }
};
