import type { FC } from 'react';

import { COLORS } from 'shared-parts/constants';

type FilledCheckIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const FilledCheckIcon: FC<React.PropsWithChildren<FilledCheckIconProps>> = ({
  width = 17,
  height = 17,
  fillColor = COLORS.success,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17">
    <path
      id="ic_check_circle_24px"
      d="M10.5,2A8.5,8.5,0,1,0,19,10.5,8.5,8.5,0,0,0,10.5,2ZM8.8,14.75,4.55,10.5l1.2-1.2L8.8,12.345l6.452-6.452L16.45,7.1Z"
      transform="translate(-2 -2)"
      fill={fillColor}
    />
  </svg>
);

export default FilledCheckIcon;
