const Visible = (width, height, color = '#333') => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 27"
    data-e2e="eye-icon"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M20 0C10.91 0 3.145 5.598 0 13.5 3.145 21.402 10.91 27 20 27s16.855-5.598 20-13.5C36.855 5.598 29.09 0 20 0zm0 22.5c-5.018 0-9.09-4.032-9.09-9s4.072-9 9.09-9 9.09 4.032 9.09 9-4.072 9-9.09 9zm0-14.4c-3.018 0-5.455 2.412-5.455 5.4 0 2.988 2.437 5.4 5.455 5.4 3.018 0 5.455-2.412 5.455-5.4 0-2.988-2.437-5.4-5.455-5.4z"
    />
  </svg>
);

export default Visible;
