import { COLORS } from 'shared-parts/constants';

const { primary, systemGreen, systemRed } = COLORS;

const APPROVED = 'approved';
const REJECTED = 'rejected';
const AWAITING_ACTION = 'awaiting_action';
const NO_FILTERS = 'none';

const FILTERS_URL_VALUES = `${APPROVED},${REJECTED},${AWAITING_ACTION}`;

const FILTERS = [
  { label: 'Approved', value: APPROVED, color: systemGreen },
  { label: 'Rejected', value: REJECTED, color: systemRed },
  { label: 'Awaiting Action', value: AWAITING_ACTION, color: primary },
];

const TABLE_HEADER = [
  { name: 'Seller', sorting: { param: 'from_name' } },
  { name: 'Buyer', sorting: { param: 'to_name' } },
  { name: 'Share Class', sorting: { param: 'class' } },
  { name: 'Share Price', sorting: { param: 'price' } },
  { name: 'Quantity', sorting: { param: 'quantity' } },
  { name: 'Total Sale Price', sorting: { param: 'total_price' } },
  { name: 'Introducer Fees', sorting: { param: 'introducer_fees' } },
  { name: 'Date Initiated', sorting: { param: 'date_initiated' } },
  { name: 'Date Completed', sorting: { param: 'date_completed' } },
  { name: 'Actions' },
  { name: '', styles: 'width: 42px;' },
];

const PER_PAGE = 30;

const DEFAULT_SORTING_ORDER = '-date_initiated';

const TRANSACTION_STATE_HMRC = 'hmrc';

const TRANSACTION_APPROVED = 'Transaction Approved';
const APPROVE_ADDITIONAL_INFO = 'The shareholder registry has been updated';
const APPROVE_ADDITIONAL_INFO_HMRC =
  'The shareholder registry will be updated once we receive the\nStamp Duty form back from HMRC';
const TRANSACTION_REJECTED = 'Transaction Rejected';

const REJECT_MODAL_HEADING = 'Are you sure you want to reject?';
const REJECT_ADDITIONAL_INFO = 'Are you sure you want to reject the transaction?';

const APPROVE_MODAL_HEADING = 'Are you sure you want to approve?';
const APPROVE_MODAL_ADDITIONAL_INFO =
  'Approving this transaction will amend the shareholder registry \n and can’t be undone once completed';

const SELLER_DETAILS = 'SELLER_DETAILS';
const BUYER_DETAILS = 'BUYER_DETAILS';
const TRANSACTION_STATUS = 'TRANSACTION_STATUS';
const STOCK_TRANSFER_FORM = 'STOCK_TRANSFER_FORM';
const TRANSACTION_DOCUMENTS = 'TRANSACTION_DOCUMENTS';

const KYC_STATUS = 'kycStatus';

const TRANSACTION_PARTICIPANT_DETAILS_TABS = [{ key: KYC_STATUS, name: 'KYC status' }];

const VERIFIED = 'VERIFIED';
const NOT_VERIFIED = 'NOT VERIFIED';

const FIRST_NAME = 'First Name:*';
const LAST_NAME = 'Last Name:*';
const EMAIL = 'Email Address:*';
const COUNTRY_OF_RESIDENCE = 'Country of Residence:*';
const NATIONALITY = 'Nationality:*';
const ADDRESS = 'Address:*';
const NAME = 'Name:*';

export {
  FILTERS_URL_VALUES,
  FILTERS,
  NO_FILTERS,
  TABLE_HEADER,
  PER_PAGE,
  DEFAULT_SORTING_ORDER,
  TRANSACTION_APPROVED,
  APPROVE_ADDITIONAL_INFO,
  APPROVE_ADDITIONAL_INFO_HMRC,
  TRANSACTION_REJECTED,
  REJECT_MODAL_HEADING,
  REJECT_ADDITIONAL_INFO,
  APPROVE_MODAL_HEADING,
  APPROVE_MODAL_ADDITIONAL_INFO,
  SELLER_DETAILS,
  BUYER_DETAILS,
  TRANSACTION_STATUS,
  STOCK_TRANSFER_FORM,
  TRANSACTION_DOCUMENTS,
  TRANSACTION_PARTICIPANT_DETAILS_TABS,
  VERIFIED,
  NOT_VERIFIED,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  COUNTRY_OF_RESIDENCE,
  NATIONALITY,
  ADDRESS,
  NAME,
  TRANSACTION_STATE_HMRC,
};
