export const GET_DRAFT_COMMUNICATION = 'GET_DRAFT_COMMUNICATION';
export const GET_DRAFT_COMMUNICATION_SUCCESS = 'GET_DRAFT_COMMUNICATION_SUCCESS';
export const GET_DRAFT_COMMUNICATION_ERROR = 'GET_DRAFT_COMMUNICATION_ERROR';

export const CREATE_DRAFT_COMMUNICATION = 'CREATE_DRAFT_COMMUNICATION';
export const CREATE_DRAFT_COMMUNICATION_SUCCESS = 'CREATE_DRAFT_COMMUNICATION_SUCCESS';
export const CREATE_DRAFT_COMMUNICATION_ERROR = 'CREATE_DRAFT_COMMUNICATION_ERROR';

export const RESET_DRAFT_COMMUNICATION = 'RESET_DRAFT_COMMUNICATION';

export const GET_PUBLISHED_COMMUNICATION = 'GET_PUBLISHED_COMMUNICATION';
export const GET_PUBLISHED_COMMUNICATION_SUCCESS = 'GET_PUBLISHED_COMMUNICATION_SUCCESS';
export const GET_PUBLISHED_COMMUNICATION_ERROR = 'GET_PUBLISHED_COMMUNICATION_ERROR';
export const RESET_PUBLISHED_COMMUNICATION = 'RESET_PUBLISHED_COMMUNICATION';

export const SAVE_DRAFT_COMMUNICATION = 'SAVE_DRAFT_COMMUNICATION';
export const SAVE_DRAFT_COMMUNICATION_SUCCESS = 'SAVE_DRAFT_COMMUNICATION_SUCCESS';
export const SAVE_DRAFT_COMMUNICATION_ERROR = 'SAVE_DRAFT_COMMUNICATION_ERROR';

export const DELETE_DRAFT_COMMUNICATION = 'DELETE_DRAFT_COMMUNICATION';
export const DELETE_DRAFT_COMMUNICATION_SUCCESS = 'DELETE_DRAFT_COMMUNICATION_SUCCESS';
export const DELETE_DRAFT_COMMUNICATION_ERROR = 'DELETE_DRAFT_COMMUNICATION_ERROR';

export const DRAFT_COMMUNICATION_ADD_ATTACHMENT = 'DRAFT_COMMUNICATION_ADD_ATTACHMENT';
export const DRAFT_COMMUNICATION_ADD_ATTACHMENT_SUCCESS =
  'DRAFT_COMMUNICATION_ADD_ATTACHMENT_SUCCESS';
export const DRAFT_COMMUNICATION_ADD_ATTACHMENT_ERROR = 'DRAFT_COMMUNICATION_ADD_ATTACHMENT_ERROR';

export const DRAFT_COMMUNICATION_DELETE_ATTACHMENT = 'DRAFT_COMMUNICATION_DELETE_ATTACHMENT';
export const DRAFT_COMMUNICATION_DELETE_ATTACHMENT_SUCCESS =
  'DRAFT_COMMUNICATION_DELETE_ATTACHMENT_SUCCESS';
export const DRAFT_COMMUNICATION_DELETE_ATTACHMENT_ERROR =
  'DRAFT_COMMUNICATION_DELETE_ATTACHMENT_ERROR';

export const PUBLISH_DRAFT_COMMUNICATION = 'PUBLISH_DRAFT_COMMUNICATION';
export const PUBLISH_DRAFT_COMMUNICATION_SUCCESS = 'PUBLISH_DRAFT_COMMUNICATION_SUCCESS';
export const PUBLISH_DRAFT_COMMUNICATION_ERROR = 'PUBLISH_DRAFT_COMMUNICATION_ERROR';

export const GET_COMMUNICATIONS = 'GET_COMMUNICATIONS';
export const GET_COMMUNICATIONS_SUCCESS = 'GET_COMMUNICATIONS_SUCCESS';
export const GET_COMMUNICATIONS_ERROR = 'GET_COMMUNICATIONS_ERROR';
export const RESET_COMMUNICATIONS = 'RESET_COMMUNICATIONS';

export const GET_COMMUNICATION_RESPONSES_STATISTICS = 'GET_COMMUNICATION_RESPONSES_STATISTICS';
export const GET_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS =
  'GET_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS';
export const GET_COMMUNICATION_RESPONSES_STATISTICS_ERROR =
  'GET_COMMUNICATION_RESPONSES_STATISTICS_ERROR';
export const RESET_COMMUNICATION_RESPONSES_STATISTICS = 'RESET_COMMUNICATION_RESPONSES_STATISTICS';

export const EXPORT_COMMUNICATION_RESPONSES_STATISTICS =
  'EXPORT_COMMUNICATION_RESPONSES_STATISTICS';
export const EXPORT_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS =
  'EXPORT_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS';
export const EXPORT_COMMUNICATION_RESPONSES_STATISTICS_ERROR =
  'EXPORT_COMMUNICATION_RESPONSES_STATISTICS_ERROR';

export const GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS =
  'GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS';
export const GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS =
  'GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_SUCCESS';
export const GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_ERROR =
  'GET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS_ERROR';
export const RESET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS =
  'RESET_AGGREGATED_COMMUNICATION_RESPONSES_STATISTICS';

export const DRAFT_COMMUNICATION_ADD_RESPONSE = 'DRAFT_COMMUNICATION_ADD_RESPONSE';
export const DRAFT_COMMUNICATION_ADD_RESPONSE_SUCCESS = 'DRAFT_COMMUNICATION_ADD_RESPONSE_SUCCESS';
export const DRAFT_COMMUNICATION_ADD_RESPONSE_ERROR = 'DRAFT_COMMUNICATION_ADD_RESPONSE_ERROR';

export const DRAFT_COMMUNICATION_DELETE_RESPONSE = 'DRAFT_COMMUNICATION_DELETE_RESPONSE';
export const DRAFT_COMMUNICATION_DELETE_RESPONSE_SUCCESS =
  'DRAFT_COMMUNICATION_DELETE_RESPONSE_SUCCESS';
export const DRAFT_COMMUNICATION_DELETE_RESPONSE_ERROR =
  'DRAFT_COMMUNICATION_DELETE_RESPONSE_ERROR';

export const FETCH_SHAREHOLDERS_EMAILS = 'FETCH_SHAREHOLDERS_EMAILS';
export const FETCH_SHAREHOLDERS_EMAILS_SUCCESS = 'FETCH_SHAREHOLDERS_EMAILS_SUCCESS';
export const FETCH_SHAREHOLDERS_EMAILS_ERROR = 'FETCH_SHAREHOLDERS_EMAILS_ERROR';
export const RESET_SHAREHOLDERS_EMAILS = 'RESET_SHAREHOLDERS_EMAILS';
