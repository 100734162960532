import { Field } from 'formik';
import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const RadioInput = styled(Field)`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const RadioLabel = styled.label`
  ${({ disabled }) =>
    disabled
      ? `
    color: ${COLORS.neutral500};
    cursor: auto;
  `
      : `
    color: ${COLORS.neutral800};
    cursor: pointer;
  `};
  font-family: ${FONTS.mainFont};
  ${({ checked }) => checked && 'font-weight: bold;'}
  font-size: 16px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ withborder, checked }) =>
    withborder &&
    `
    background-color: ${checked ? COLORS.success100 : 'none'};
    border: 1px solid ${checked ? COLORS.accent : COLORS.neutral500};
    border-radius: 13px;
    color: ${COLORS.primary};
    font-size: 16px;
    padding: 22px 23px 17px 57px;
    text-align: left;
    width: 100%;
    &:hover {
      border-color: ${checked ? COLORS.accent : COLORS.neutral800};
    }
  `}
`;

const Icon = styled.span`
  border: 2px solid ${COLORS.neutral500};
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;

  ${({ withborder }) =>
    withborder &&
    `
    top: 25px;
    left: 29px;
  `}

  ${({ checked, disabled }) =>
    checked && `border: 5px solid ${disabled ? COLORS.neutral500 : COLORS.alternative};`}
`;

const Description = styled.p`
  color: ${COLORS.neutral800};
  font-weight: 400;
  margin: 5px 0 0 0;
`;

export { RadioInput, RadioLabel, Icon, Description };
