import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import { useEnvVar } from '@frontend/config';

import { getDeals } from 'app-state/deals/actions';
import {
  fetchInvestorDeals,
  resetInvestorDeals,
  updateInvestorDeals,
} from 'app-state/investors/actions';
import { getInvestorDealsWithPermissions } from 'app-state/investors/selectors';
import { getUser } from 'app-state/selectors';

import { EXIT_BUTTON_LABEL, PERMISSIONS_TITLE, SAVE } from 'modules/investors/constants';
import { checkIfDifferent } from 'modules/investors/permissions/helpers';
import { ButtonsBlock, StyledButton } from 'modules/investors/styled';
import { isOperationalAdmin } from 'modules/register-overview/securities-overview/helpers';
import useTypedSelector from 'hooks/use-typed-selector';
import { TextButton } from 'shared-parts/components/button';

import { AgentsPermissions } from './agent-permissions';
import { Container, DeleteButton, List, ListItem, Section, Title } from './permissions.styled';

const initialValues = { deal: null };

const Permissions = ({ investorUuid, closeForm, updateList }) => {
  const isAgentPermissionsEnabled = useEnvVar('ENABLED_AGENT_PERMISSIONS');
  const dispatch = useDispatch();
  const user = useTypedSelector(getUser);
  const investorDeals = useTypedSelector(getInvestorDealsWithPermissions);
  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [addedDeals, setAddedDeals] = useState([]);
  const [permissionRevoked, setRevoke] = useState([]);

  const handleDelete = dealUuid => () => {
    setAddedDeals(added => added.filter(checkIfDifferent(dealUuid)));
    setRevoke(rv => [...rv, dealUuid]);
    setSaveDisabled(false);
  };

  const handleSubmit = useCallback(() => {
    dispatch(
      updateInvestorDeals({
        uuid: investorUuid,
        data: { permissionGranted: [], permissionRevoked },
        updateList,
      }),
    );
    setRevoke([]);
    setSaveDisabled(true);
  }, [permissionRevoked]);

  useEffect(() => {
    dispatch(getDeals());
    dispatch(fetchInvestorDeals({ uuid: investorUuid }));

    return () => {
      dispatch(resetInvestorDeals());
    };
  }, []);

  useEffect(() => {
    setAddedDeals(investorDeals);
  }, [investorDeals]);

  const addedDealsList = addedDeals.map(({ name, uuid, isLocked }) => (
    <div key={uuid}>
      <ListItem>{name}</ListItem>
      {!isLocked && (
        <DeleteButton type="button" onClick={handleDelete(uuid)}>
          -
        </DeleteButton>
      )}
    </div>
  ));

  // TODO: Remove Formik and add custom Select
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Container>
        <div>
          <Section>
            <Title>{PERMISSIONS_TITLE}</Title>
            <List>{addedDealsList}</List>
          </Section>
          {isAgentPermissionsEnabled && isOperationalAdmin(user) && (
            <AgentsPermissions investorUuid={investorUuid}>
              {(title, permissions) => (
                <Section>
                  <Title>{title}</Title>
                  <List>
                    {permissions.map(({ name }, id) => (
                      <ListItem key={id}>{name}</ListItem>
                    ))}
                  </List>
                </Section>
              )}
            </AgentsPermissions>
          )}
        </div>
        <ButtonsBlock>
          <TextButton onClick={closeForm}>{EXIT_BUTTON_LABEL}</TextButton>
          <StyledButton disabled={isSaveDisabled} type="button" onClick={handleSubmit}>
            {SAVE}
          </StyledButton>
        </ButtonsBlock>
      </Container>
    </Formik>
  );
};

export default Permissions;
