import { TypedUseSelectorHook, useSelector } from 'react-redux';

import type { RootState } from 'app-state/store';

// TODO: Remove types after moving them to proper modules
// interface Store {
//   totalTransferableShares: {
//     loading: boolean;
//     data: number| null;
//     error: string | string[] | null;
//   };
//   companyProfile: {
//     loading: boolean;
//     data: {
//       companyName: string;
//       companyNumber: string;
//       confirmationStatement: {
//         lastMadeUpTo: string;
//         nextMadeUpTo: string;
//       };
//       sicCodes: {
//         code: string,
//         description: string,
//       }[];
//     };
//     error: string | string[] | null;
//   };
//   shareCapital: {
//     loading: boolean;
//     data: {
//       capital: {
//         shareCurrency: string;
//         shares: {
//           aggregateNominalValue: string;
//           numShares: string;
//           shareClass: string;
//         }[];
//         totalAggregateNominalValue: string;
//         totalNumberOfIssuedShares: string;
//         totalAmountUnpaid: number;
//       }[];
//       prescribedParticulars: {
//         shareClass: string;
//         description: string | null;
//       }[];
//       prescribedParticularsChanged: boolean;
//     };
//     error: string | string[] | null;
//   };
//   shareholderInformation: {
//     loading: boolean;
//     data: {
//       address: {
//         country: string;
//         county: string | null;
//         postTown: string | null;
//         premise: string | null;
//         street: string | null;
//       };
//       name: string;
//       shareClass: string;
//       numberHeld: string;
//       transfers?: {
//         numberSharesTransferred: string;
//         dateOfTransfer: string;
//       }[];
//     }[];
//     error: string | string[] | null;
//   }
// }

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useTypedSelector;
