import styled from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';
import { COLORS } from 'shared-parts/constants';

import Tabs from './tabs';

const {
  custom: {
    tab: { selectTabBorderColor },
  },
} = getTenantConfig();

const getTabStyles = selected => `
    text-transform: initial;
    color: ${COLORS.neutral800};
    line-height: 19px;

    ${
      selected
        ? `
      font-weight: bold;
      color: ${COLORS.highlight};
      border-bottom-color: ${selectTabBorderColor};
  `
        : ''
    }
`;

const StyledFormTabs = styled(props => <Tabs {...props} getTabStyles={getTabStyles} />)`
  margin: 0;
  border-bottom: 1px solid ${COLORS.neutral500};
`;

export { StyledFormTabs }; //eslint-disable-line
