import { Field } from 'formik';

import Error from 'shared-parts/components/error';
import { COLORS } from 'shared-parts/constants';

import { CheckboxInput, CheckboxLabel, CheckboxWrapper, Icon } from './checkbox.styled';

const { neutral800 } = COLORS;

export const CustomCheckboxComponent = ({
  name,
  text,
  value,
  thinText,
  disabled,
  fontSize,
  labelColor = neutral800,
  handleChange,
  ...rest
}) => (
  <CheckboxWrapper>
    <CheckboxLabel
      htmlFor={name}
      labelColor={labelColor}
      thinText={thinText}
      disabled={disabled}
      fontSize={fontSize}
    >
      {text}
      <CheckboxInput
        type="checkbox"
        onChange={handleChange}
        name={name}
        value={value}
        disabled={disabled}
        data-e2e={name}
        {...rest}
      />
      <Icon />
    </CheckboxLabel>
  </CheckboxWrapper>
);

const Checkbox = ({
  text,
  form: { errors, touched, setFieldTouched },
  field,
  thinText,
  className,
  disabled,
  fontSize,
  labelColor = neutral800,
  ...rest
}) => {
  const { name, value = '', onChange, onBlur } = field;
  const handleChange = event => {
    setFieldTouched(name, true, false);
    onChange(event);
  };
  const error = Array.isArray(errors[name]) ? errors[name][0] : errors[name];

  return (
    <CheckboxWrapper className={className}>
      <CheckboxLabel
        labelColor={labelColor}
        thinText={thinText}
        disabled={disabled}
        fontSize={fontSize}
      >
        {text}
        <CheckboxInput
          type="checkbox"
          checked={value}
          onBlur={onBlur}
          onChange={handleChange}
          name={name}
          value={value}
          disabled={disabled}
          {...rest}
          data-e2e={name}
        />
        <Icon />
      </CheckboxLabel>
      {error && touched[name] && <Error message={error} data-e2e={`${name}-error`} />}
    </CheckboxWrapper>
  );
};

export default props => <Field {...props} component={Checkbox} />;
