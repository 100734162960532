import { FC } from 'react';

import { useEnvVar } from '@frontend/config';

import { SectionHeaderActive as Alternative } from './section-header-active-alternative';
import { SectionHeaderActive as Default } from './section-header-active-default';
import { ActiveHeaderPropType } from './types';

export const SectionHeaderActive: FC<React.PropsWithChildren<ActiveHeaderPropType>> = useEnvVar(
  'ENABLE_AGENT_SECTION_HEADER_ACTIVE',
)
  ? Alternative
  : Default;
