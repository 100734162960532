import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.neutral800};
  font-weight: bold;
`;

export default Label;
