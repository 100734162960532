import { Options } from 'minisearch';

export const DEFAULT_SEARCH_CONFIGURATION: Options = {
  fields: ['text'],
  idField: 'value',
  searchOptions: {
    combineWith: 'AND',
    prefix: true,
  },
};
