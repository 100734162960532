import {
  CLEAR_SHARE_INCENTIVE_PLAN,
  EDIT_SHARE_INCENTIVE_PLAN,
  EDIT_SHARE_INCENTIVE_PLAN_ERROR,
  EDIT_SHARE_INCENTIVE_PLAN_SUCCESS,
  GET_SHARE_INCENTIVE_PLAN,
  GET_SHARE_INCENTIVE_PLAN_ERROR,
  GET_SHARE_INCENTIVE_PLAN_SUCCESS,
} from 'app-state/constants/share-incentive';

import getReducerState from './get-reducer-state';

const shareIncentivePlanInitialState = getReducerState({
  loadingEditPlan: false,
  errorsEditPlan: [],
});

export const shareIncentivePlanReducer = (state = shareIncentivePlanInitialState, action) => {
  switch (action.type) {
    case GET_SHARE_INCENTIVE_PLAN:
      return shareIncentivePlanInitialState;

    case GET_SHARE_INCENTIVE_PLAN_SUCCESS:
      return { loading: false, data: action.data, error: null, isOrderDeleted: false };

    case GET_SHARE_INCENTIVE_PLAN_ERROR:
      return { loading: false, data: {}, error: action.error, isOrderDeleted: false };

    case EDIT_SHARE_INCENTIVE_PLAN:
      return { ...state, loadingEditPlan: true, errorsEditPlan: null };

    case EDIT_SHARE_INCENTIVE_PLAN_SUCCESS:
      return {
        ...state,
        loadingEditPlan: false,
        data: { ...state.data, ...action.data },
        error: null,
      };

    case EDIT_SHARE_INCENTIVE_PLAN_ERROR:
      return { ...state, loadingEditPlan: false, error: action.error };

    case CLEAR_SHARE_INCENTIVE_PLAN:
      return shareIncentivePlanInitialState;
    default:
      return state;
  }
};
