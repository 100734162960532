import { Action } from 'redux';

import {
  GET_SHARE_CERTIFICATE_CONFIG,
  GET_SHARE_CERTIFICATE_CONFIG_ERROR,
  GET_SHARE_CERTIFICATE_CONFIG_SUCCESS,
  RESET_SHARE_CERTIFICATE_CONFIG,
  UPDATE_SHARE_CERTIFICATE_CONFIG,
  UPDATE_SHARE_CERTIFICATE_CONFIG_ERROR,
  UPDATE_SHARE_CERTIFICATE_CONFIG_SUCCESS,
} from './constants';
import type { UpdateShareCertificateConfig } from './types';

export const resetShareCertificateConfig = (): Action<string> => {
  return {
    type: RESET_SHARE_CERTIFICATE_CONFIG,
  };
};

export const getShareCertificateConfig = (): Action<string> => {
  return {
    type: GET_SHARE_CERTIFICATE_CONFIG,
  };
};

export const getShareCertificateConfigSuccess = (payload: any): Action<string> & any => {
  return {
    type: GET_SHARE_CERTIFICATE_CONFIG_SUCCESS,
    payload,
  };
};

export const getShareCertificateConfigError = (
  error: string,
): Action<string> & { error: string } => {
  return {
    type: GET_SHARE_CERTIFICATE_CONFIG_ERROR,
    error,
  };
};

export const updateShareCertificateConfig = (
  data: Partial<UpdateShareCertificateConfig>,
): Action<string> & { data: Partial<UpdateShareCertificateConfig> } => {
  return {
    type: UPDATE_SHARE_CERTIFICATE_CONFIG,
    data,
  };
};

export const updateShareCertificateConfigSuccess = (payload: any): Action<string> => {
  return {
    type: UPDATE_SHARE_CERTIFICATE_CONFIG_SUCCESS,
    ...payload,
  };
};

export const updateShareCertificateConfigError = (payload: any): Action<string> => {
  return {
    type: UPDATE_SHARE_CERTIFICATE_CONFIG_ERROR,
    ...payload,
  };
};
