import { object, string } from 'yup';

export default object({
  amount: string()
    .required('Amount is required')
    .test(
      'is-number',
      'Amount must be a number',
      value => !Number.isNaN(+value) && !/\.$/.test(value),
    )
    .test('is-positive', 'Amount must be greater than 0', value => +value > 0),
  currency: string().required('Currency is required'),
  creditDebit: string().required('Credit/Debit is required'),
  reference: string().required('There is no reference for the chosen currency'),
  details: string().trim().required('Details is required'),
});
