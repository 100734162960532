import humps from 'humps';

import { toDefinedFractionSizeRoundUp } from './format-number';

const prepareFormData = (blob, filename, formDataParams) => {
  const form = new FormData();

  form.append('file', blob, filename);

  const decamelized = humps.decamelizeKeys(formDataParams);

  Object.entries(decamelized).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item =>
        typeof item === 'object' && item !== null
          ? Object.entries(item).forEach(([k, v]) => form.append(`${key}[][${k}]`, v))
          : form.append(`${key}[]`, item),
      );
    } else {
      form.append(key, value);
    }
  });

  return form;
};

const getBlobFromDataURL = (dataURL, type) => {
  const binary = atob(dataURL.split(',')[1]);
  const array = binary.split('').map(char => char.charCodeAt(0));

  return new Blob([new Uint8Array(array)], { type });
};

const uploadFile =
  ({
    mimeType = '',
    mimeSubTypes = [],
    params,
    formDataParams = {},
    onLoad,
    setError,
    errors = {},
    maxFileSize = Infinity,
    validate = true,
  }) =>
  e => {
    const [file] = e.target.files;
    const fileUploadHasBeenCancelled = !file;

    if (fileUploadHasBeenCancelled) return;

    if (validate && setError) {
      const supportedMimeRegExp = new RegExp(`^${mimeType}/(${mimeSubTypes.join('|')})$`, 'g');
      const isMIMETypeSupported = file.type && supportedMimeRegExp.test(file.type);

      if (mimeType && mimeSubTypes && !isMIMETypeSupported) {
        return setError(errors.fileType);
      }

      const isFileSizeAllowed = file.size <= maxFileSize;

      if (!isFileSizeAllowed) {
        return setError(errors.fileSize);
      }
    }

    const reader = new FileReader();

    reader.onload = () => {
      const blob = getBlobFromDataURL(reader.result, file.type);
      const fileData = prepareFormData(blob, file.name, formDataParams);

      onLoad({ ...params, fileData, dataURL: reader.result, setError, files: [file] });
    };

    reader.readAsDataURL(file);
  };

const formatFileSize = (bytes, fraction = 1) => {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = ['kb', 'mb', 'gb', 'tb'];
  let u = -1;
  let formattedSize = bytes;

  do {
    formattedSize /= thresh;
    u += 1;
  } while (Math.abs(formattedSize) >= thresh && u < units.length - 1);

  return `${toDefinedFractionSizeRoundUp(formattedSize, fraction)}${units[u]}`;
};

export { prepareFormData, getBlobFromDataURL, uploadFile, formatFileSize };
