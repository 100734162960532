import { AnyAction } from 'redux';

import { GridInitialState } from '@frontend/design-system';

import { SET_GRID_CONFIG } from './actions';

export interface TablesState {
  [tableId: string]: GridInitialState;
}

const initialState: TablesState = {};

export const gridConfigsReducer = (state = initialState, action: AnyAction): TablesState => {
  switch (action.type) {
    case SET_GRID_CONFIG:
      return {
        ...state,
        [action.payload.tableId]: action.payload.currentState,
      };

    default:
      return state;
  }
};
