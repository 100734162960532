import { COLORS } from 'shared-parts/constants';

const BinMinimalIcon = ({ width = '10.877', height = '11.66', fillColor = COLORS.systemRed }) => (
  <svg width={width} height={height} viewBox="0 0 10.877 11.66" xmlns="http://www.w3.org/2000/svg">
    <path
      id="BIN"
      d="M8.3,16.41A1.233,1.233,0,0,1,7.083,15.2v-8.6H6.914a.615.615,0,1,1,0-1.229H9.572a.6.6,0,0,1,.615-.615h3.1a.607.607,0,0,1,.446.184.59.59,0,0,1,.184.43h2.642a.615.615,0,1,1,0,1.229h-.169v8.6A1.233,1.233,0,0,1,15.18,16.41Zm0-9.817v8.6H15.18v-8.6Zm1.6,6.821a.525.525,0,0,0,.522.522.521.521,0,0,0,.384-.154.5.5,0,0,0,.154-.369V8.36a.533.533,0,0,0-.161-.392.516.516,0,0,0-.376-.161.484.484,0,0,0-.376.161.563.563,0,0,0-.146.392Zm2.627,0a.491.491,0,0,0,.161.369.529.529,0,0,0,.376.154.521.521,0,0,0,.384-.154.5.5,0,0,0,.154-.369V8.36a.533.533,0,0,0-.161-.392.516.516,0,0,0-.376-.161.508.508,0,0,0-.384.161.547.547,0,0,0-.154.392ZM8.3,6.594v0Z"
      fill={fillColor}
      transform="translate(-6.3 -4.75)"
    />
  </svg>
);

export default BinMinimalIcon;
