import styled, { css } from 'styled-components';

import Select from 'shared-parts/components/select';
import {
  Dropdown as OptionContainer,
  SelectContainer,
  SelectedOptionText,
} from 'shared-parts/components/select/select.styled';
import { COLORS, FONTS } from 'shared-parts/constants';

const Wrapper = styled.div`
  max-width: 100%;
`;

const DatepickerWrapper = styled.div`
  position: absolute;
  z-index: 100;
  ${({ wrapperStyles }) => ({ ...wrapperStyles })};
`;

const DatepickerContainer = styled.div`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.neutral500};
  border-radius: 3px;
  padding: 30px;
  text-align: center;
  width: 330px;
`;

const Arrow = styled.button`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.neutral500};
  cursor: pointer;
  font-size: 20px;
  font-weight: 100;
  height: 36px;
  line-height: 37px;
  width: 36px;
`;

const Dropdown = styled(props => <Select {...props} />)`
  height: 30px;

  ${SelectContainer} {
    border: none;
    padding-right: 0;

    & > span,
    & > input {
      left: 6px;
    }

    & > button {
      width: 30px;
    }
  }

  ${SelectedOptionText} {
    padding-left: 0;
    width: 70%;
  }

  ${OptionContainer} {
    width: calc(100% + 15px);
  }
`;

const YearDropdown = styled(Dropdown)`
  width: 80px;
`;

const MonthsDropdown = styled(Dropdown)`
  width: 116px;
`;

const DaysOfWeek = styled.div`
  display: inline-block;
`;

const DayOfWeek = styled.span`
  display: inherit;
  font-size: 13px;
  height: 38px;
  line-height: 38px;
  width: 38px;
`;

const Days = styled.div`
  text-align: left;
`;

const Day = styled.button`
  background-color: ${({ highlighted, selected }) =>
    selected && highlighted ? COLORS.alternative : COLORS.white};
  border: 1px solid
    ${({ highlighted, selected }) =>
      selected && highlighted ? COLORS.alternative : COLORS.neutral500};
  color: ${({ highlighted, selected }) =>
    highlighted ? (selected && COLORS.white) || COLORS.primary : COLORS.neutral500};
  cursor: pointer;
  font-size: 13px;
  height: 38px;
  width: 38px;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${COLORS.neutral200};
    cursor: not-allowed;
  `}

  :hover {
    background-color: ${({ highlighted, selected }) =>
      selected && highlighted ? COLORS.alternative : COLORS.success100};
  }
`;

const Time = styled.div`
  align-items: center;
  border-top: 1px solid ${COLORS.neutral500};
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding-top: 13px;
`;

const TimeLabel = styled.span`
  color: ${COLORS.primary};
  font-weight: bold;
`;

const HoursAndMinutesBlock = styled.div`
  display: flex;
  margin: 0 10px;
`;

const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ArrowBase = css`
  border: solid ${COLORS.neutral800};
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin: 3px;
  padding: 3px;
`;

const ArrowUp = styled.i`
  ${ArrowBase}

  cursor: pointer;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

const ArrowDown = styled.i`
  ${ArrowBase}

  cursor: pointer;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

const ArrowLeft = styled.i`
  ${ArrowBase}

  border-width: 0 2px 2px 0;
  margin-left: 5px;
  padding: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

const ArrowRight = styled.i`
  ${ArrowBase}

  border-width: 0 2px 2px 0;
  margin-right: 5px;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const HoursAndMinutesInput = styled.input`
  border: 1px solid ${COLORS.neutral500};
  height: 35px;
  padding: 4px;
  width: 35px;
  text-align: center;

  ${({ isRightBorderHidden }) => isRightBorderHidden && 'border-right: 0;'}
`;

const AMPM = styled.button`
  background: transparent;
  border: 0;
  ${({ highlighted }) =>
    highlighted
      ? `
    color: ${COLORS.secondary};
    text-decoration: underline;
  `
      : `
    color:${COLORS.neutral500};
    cursor: pointer;
  `}
  font-weight: bold;
`;

const Buttons = styled.div`
  border-top: 1px solid ${COLORS.neutral500};
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin-top: 15px;
  width: 100%;
`;

const Today = styled.button`
  border: none;
  background-color: ${({ isTodaySet }) => (isTodaySet ? COLORS.neutral800 : COLORS.alternative)};
  border-radius: 3px;
  color: ${({ isTodaySet }) => (isTodaySet ? COLORS.white : COLORS.alternativeContrastText)};
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  margin-top: 13px;
  width: 75px;
`;

const SetButton = styled(Today)`
  font-family: ${FONTS.mainFont};
  width: 120px;
`;

const ArrowOnTheTop = styled.div`
  margin-top: 15px;

  &:after,
  &:before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin-left: ${({ arrowPosition }) => (arrowPosition ? 50 : 5)}%;
    position: absolute;
    width: 0;
  }

  &:after {
    margin-top: -18px;
    border-color: transparent transparent ${COLORS.white} transparent;
    border-width: 10px;
    z-index: 2;
  }

  &:before {
    margin-top: -20px;
    border-color: transparent transparent ${COLORS.neutral500} transparent;
    border-width: 10.5px;
  }
`;

export {
  Wrapper,
  DatepickerContainer,
  Arrow,
  Dropdown,
  YearDropdown,
  MonthsDropdown,
  DaysOfWeek,
  DayOfWeek,
  Days,
  Day,
  Time,
  TimeLabel,
  HoursAndMinutesBlock,
  InputWrapper,
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  HoursAndMinutesInput,
  AMPM,
  Buttons,
  SetButton,
  Today,
  ArrowOnTheTop,
  DatepickerWrapper,
};
