import { useEnvVar } from '@frontend/config';

import Routes from 'constants/routes';
import { getLoginRedirectionUrlForCompanyUser } from 'helpers/user-data';

export default {
  Operations:
    useEnvVar('DISABLED_CAP_TABLE_MANAGEMENT') ||
    useEnvVar('ENABLE_MULTIPLE_ISSUERS_BUT_DISABLE_CAP_TABLE_PAGES')
      ? Routes.Globacap.Deals
      : Routes.Globacap.Companies,
  MultiCompany:
    useEnvVar('DISABLED_CAP_TABLE_MANAGEMENT') ||
    useEnvVar('ENABLE_MULTIPLE_ISSUERS_BUT_DISABLE_CAP_TABLE_PAGES')
      ? Routes.MultiCompany.Deals
      : Routes.MultiCompany.Companies,
  Company: ({ criteria: { companyId } }) => getLoginRedirectionUrlForCompanyUser(companyId),
};
