import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const TextButton = styled.span<{
  textColor?: string;
  noUnderline?: boolean;
}>`
  color: ${({ textColor }) => textColor || COLORS.primary};
  font-weight: bold;
  cursor: pointer;
  text-decoration: ${({ noUnderline }) => (noUnderline ? '' : 'underline')};
  font-size: 14px;
  line-height: 18px;
  font-family: ${FONTS.mainFont};
`;

export default TextButton;
