export const FETCH_NAVIGATION = 'FETCH_NAVIGATION';
export const FETCH_NAVIGATION_SUCCESS = 'FETCH_NAVIGATION_SUCCESS';
export const FETCH_NAVIGATION_ERROR = 'FETCH_NAVIGATION_ERROR';
export const RESET_NAVIGATION = 'RESET_NAVIGATION';

export const FETCH_EDITABLE_NAVIGATION = 'FETCH_EDITABLE_NAVIGATION';
export const FETCH_EDITABLE_NAVIGATION_SUCCESS = 'FETCH_EDITABLE_NAVIGATION_SUCCESS';
export const FETCH_EDITABLE_NAVIGATION_ERROR = 'FETCH_EDITABLE_NAVIGATION_ERROR';
export const RESET_EDITABLE_NAVIGATION = 'RESET_EDITABLE_NAVIGATION';

export const EDIT_NAVIGATION_ITEM = 'EDIT_NAVIGATION_ITEM';
export const EDIT_NAVIGATION_ITEM_SUCCESS = 'EDIT_NAVIGATION_ITEM_SUCCESS';
export const EDIT_NAVIGATION_ITEM_ERROR = 'EDIT_NAVIGATION_ITEM_ERROR';

export const DELETE_NAVIGATION_ITEM = 'DELETE_NAVIGATION_ITEM';
export const DELETE_NAVIGATION_ITEM_SUCCESS = 'DELETE_NAVIGATION_ITEM_SUCCESS';
export const DELETE_NAVIGATION_ITEM_ERROR = 'DELETE_NAVIGATION_ITEM_ERROR';

export const CREATE_NAVIGATION_ITEM = 'CREATE_NAVIGATION_ITEM';
export const CREATE_NAVIGATION_ITEM_SUCCESS = 'CREATE_NAVIGATION_ITEM_SUCCESS';
export const CREATE_NAVIGATION_ITEM_ERROR = 'CREATE_NAVIGATION_ITEM_ERROR';

export const FETCH_SUB_NAVIGATION = 'FETCH_SUB_NAVIGATION';
export const FETCH_SUB_NAVIGATION_SUCCESS = 'FETCH_SUB_NAVIGATION_SUCCESS';
export const FETCH_SUB_NAVIGATION_ERROR = 'FETCH_SUB_NAVIGATION_ERROR';
export const RESET_SUB_NAVIGATION = 'RESET_SUB_NAVIGATION';

export const REORDER_NAVIGATION_ITEM = 'REORDER_NAVIGATION_ITEM';
export const REORDER_NAVIGATION_ITEM_SUCCESS = 'REORDER_NAVIGATION_ITEM_SUCCESS';
export const REORDER_NAVIGATION_ITEM_ERROR = 'REORDER_NAVIGATION_ITEM_ERROR';
