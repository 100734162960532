import { Formik } from 'formik';

import { INITIAL_INVESTOR_DETAILS_FORM_DATA } from '../constants';
import type { InvestorDetailsFormProps, InvestorDetailsInitialValues } from '../types';

import InvestorDetailsForm from './investor-details-form';
import getValidationSchema from './investor-details-form.validation';

const getInitialValues = (initialValues: InvestorDetailsInitialValues) =>
  initialValues && Object.keys(initialValues).length
    ? initialValues
    : INITIAL_INVESTOR_DETAILS_FORM_DATA;

const InvestorDetailsFormWrapper = (props: InvestorDetailsFormProps) => (
  <Formik
    enableReinitialize
    validateOnMount={!props.initialValues}
    initialValues={getInitialValues(props.initialValues)}
    disabledFields={props.disabledFields}
    validationSchema={getValidationSchema(props)}
    onSubmit={props.handleFormSubmit}
  >
    {InvestorDetailsForm(props)}
  </Formik>
);

export default InvestorDetailsFormWrapper;
