import { pluralize } from '@frontend/utils';

import {
  addCommasToNumber,
  addCurrencySign,
  toDefinedFractionSize,
} from 'shared-parts/helpers/format-number';

import { PreferencesType } from '../types';

import {
  COMPANY_STAGE,
  CORPORATE_GOVERNANCE_REQUIREMENTS,
  DEAL_PREFERENCE,
  EBITDA,
  ESG,
  EXIT_HORIZON,
  EXIT_PREFERENCE,
  HEADQUARTER_COUNTRY,
  IDEAL_OWNERSHIP,
  IDEAL_TICKET_SIZE,
  INTERESTED_GEOGRAPHIES,
  INVEST_IN_PRIVATE_CAPITAL_OPPORTUNITIES,
  INVESTMENT_STYLE,
  ISSUANCE_PREFERENCE,
  LEAD_INVESTOR_PREFERENCE,
  LOOKING_FOR_INVESTMENT_OPPORTUNITIES,
  MINIMUM_RETURN,
  NET_INCOME_POSITIVE,
  NOT_INTERESTED_GEOGRAPHIES,
  POSITION,
  PRODUCT_TYPE,
  RETURN_CALCULATION_METHODOLOGY,
  SECTOR_PREFERENCES,
  TARGET_DURATION_OF_HOLDING,
  UAM,
} from './constants';

const getPercentages = (value = 0): string => `${toDefinedFractionSize(value * 100, 2)}%`;

export const getPreferencesItem = ({
  // eslint-disable-line
  uam,
  headquarterCountry,
  investmentStyle,
  investInPrivateCapitalOpportunities,
  lookingForInvestmentOpportunities,
  interestedGeographies,
  notInterestedGeographies,
  sectorPreferences,
  esg,
  companyStage,
  ebitda,
  netIncomePositive,
  idealTicketSize,
  idealOwnership,
  issuancePreference,
  targetDurationOfHolding,
  exitPreference,
  exitHorizon,
  returnCalculationMethodology,
  minimumReturn,
  corporateGovernanceRequirements,
  productType,
  position,
  dealPreference,
  leadInvestorPreference,
}: PreferencesType) => [
  {
    label: UAM,
    value: uam ? addCurrencySign(addCommasToNumber(toDefinedFractionSize(uam, 2)), '£') : '-',
  },
  {
    label: HEADQUARTER_COUNTRY,
    value: headquarterCountry || '-',
  },
  {
    label: INVESTMENT_STYLE,
    value: investmentStyle || '-',
  },
  {
    label: INVEST_IN_PRIVATE_CAPITAL_OPPORTUNITIES,
    value: investInPrivateCapitalOpportunities ? 'Yes' : 'No',
  },
  {
    label: LOOKING_FOR_INVESTMENT_OPPORTUNITIES,
    value: lookingForInvestmentOpportunities ? 'Yes' : 'No',
  },
  {
    label: INTERESTED_GEOGRAPHIES,
    value: interestedGeographies || '-',
  },
  {
    label: NOT_INTERESTED_GEOGRAPHIES,
    value: notInterestedGeographies || '-',
  },
  {
    label: SECTOR_PREFERENCES,
    value: sectorPreferences || '-',
  },
  {
    label: ESG,
    value: esg ? 'Yes' : 'No',
  },
  {
    label: COMPANY_STAGE,
    value: companyStage || '-',
  },
  {
    label: EBITDA,
    value: getPercentages(ebitda),
  },
  {
    label: NET_INCOME_POSITIVE,
    value: getPercentages(netIncomePositive),
  },
  {
    label: IDEAL_TICKET_SIZE,
    value: idealTicketSize
      ? addCurrencySign(addCommasToNumber(toDefinedFractionSize(idealTicketSize, 2)), '£')
      : '-',
  },
  {
    label: IDEAL_OWNERSHIP,
    value: getPercentages(idealOwnership),
  },
  {
    label: ISSUANCE_PREFERENCE,
    value: issuancePreference || '-',
  },
  {
    label: TARGET_DURATION_OF_HOLDING,
    value: targetDurationOfHolding ? `${pluralize('year', targetDurationOfHolding)}` : '-',
  },
  {
    label: EXIT_PREFERENCE,
    value: exitPreference || '-',
  },
  {
    label: EXIT_HORIZON,
    value: exitHorizon ? `within ${pluralize('year', exitHorizon)}` : '-',
  },
  {
    label: RETURN_CALCULATION_METHODOLOGY,
    value: returnCalculationMethodology || '-',
  },
  {
    label: MINIMUM_RETURN,
    value: getPercentages(minimumReturn),
  },
  {
    label: CORPORATE_GOVERNANCE_REQUIREMENTS,
    value: corporateGovernanceRequirements || '-',
  },
  {
    label: PRODUCT_TYPE,
    value: productType || '-',
  },
  {
    label: POSITION,
    value: position || '-',
  },
  {
    label: LEAD_INVESTOR_PREFERENCE,
    value: leadInvestorPreference || '-',
  },
  {
    label: DEAL_PREFERENCE,
    value: dealPreference || '-',
  },
];
