import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

import { BUTTON_COLOR_CONFIG, BUTTON_CONTENT_CONFIG } from './constants';

const { neutral800, white } = COLORS;

export const Button = styled.div<{
  disabled?: boolean;
  onClick: () => void;
  type: string;
}>`
  cursor: pointer;
  width: 18px;
  height: 18px;
  font-size: 13px;
  background-color: ${({ type }) => BUTTON_COLOR_CONFIG[type]};
  border-radius: 3px;
  color: ${white};
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0 10px 0 0;
  &:after {
    content: '${({ type }) => BUTTON_CONTENT_CONFIG[type]}';
  }
  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${neutral800};
    cursor: default;
    pointer-events: none;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;
