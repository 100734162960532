import {
  ADD_SECONDARY_TRANSACTION_DOCUMENT,
  ADD_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  ADD_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT_ERROR,
  DELETE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS,
  GET_ALL_SECONDARY_TRANSACTIONS,
  GET_INTRODUCER_FEES,
  GET_INTRODUCER_FEES_ERROR,
  GET_INTRODUCER_FEES_SUCCESS,
  GET_SECONDARY_TRANSACTION_ERROR,
  GET_SECONDARY_TRANSACTION_SUCCESS,
  GET_SECONDARY_TRANSACTIONS,
  GET_SECONDARY_TRANSACTIONS_ERROR,
  GET_SECONDARY_TRANSACTIONS_SUCCESS,
  RESET_SECONDARY_TRANSACTION,
  RESET_SECONDARY_TRANSACTIONS,
  SET_EMPTY_SECONDARY_TRANSACTIONS,
} from 'app-state/constants/secondary-transactions';

import getReducerState from './get-reducer-state';

export const secondaryTransactionsInitialState = {
  loading: false,
  data: { items: [], meta: {} },
  error: null,
};

export const secondaryTransactionsReducer = (
  state = secondaryTransactionsInitialState,
  { type, data, error },
) => {
  switch (type) {
    case GET_SECONDARY_TRANSACTIONS:
    case GET_ALL_SECONDARY_TRANSACTIONS:
      return { ...state, loading: true };
    case GET_SECONDARY_TRANSACTIONS_SUCCESS: {
      const { page, totalPages } = data.meta.pagination;

      return {
        loading: false,
        data: {
          items: data.items,
          meta: data.meta,
          isLastItemFetched: page === totalPages,
        },
        error: null,
      };
    }
    case GET_SECONDARY_TRANSACTIONS_ERROR:
      return { loading: false, data: state.data, error };
    case SET_EMPTY_SECONDARY_TRANSACTIONS:
      return {
        loading: false,
        data: {
          items: [],
          meta: {},
          isLastItemFetched: true,
        },
        error: null,
      };
    case RESET_SECONDARY_TRANSACTIONS:
      return secondaryTransactionsInitialState;
    default:
      return state;
  }
};

export const initialSecondaryTransactionState = getReducerState({
  data: {
    transactionDocuments: {
      data: [],
      loading: false,
      errorMessage: null,
      error: null,
    },
  },
});

export const secondaryTransactionReducer = (state = initialSecondaryTransactionState, action) => {
  switch (action.type) {
    case GET_SECONDARY_TRANSACTION_SUCCESS:
      return {
        loading: false,
        data: {
          ...action.data,
          transactionDocuments: {
            loading: false,
            data: action.data.transactionDocuments,
            errorMessage: null,
            error: null,
          },
        },
        error: null,
      };
    case RESET_SECONDARY_TRANSACTION:
      return initialSecondaryTransactionState;
    case ADD_SECONDARY_TRANSACTION_DOCUMENT:
    case DELETE_SECONDARY_TRANSACTION_DOCUMENT:
      return {
        loading: false,
        data: {
          ...state.data,
          transactionDocuments: {
            loading: true,
            data: state.data.transactionDocuments.data,
            errorMessage: null,
            error: null,
          },
        },
        error: null,
      };
    case ADD_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS:
      return {
        loading: false,
        data: {
          ...state.data,
          transactionDocuments: {
            loading: false,
            data: [...state.data.transactionDocuments.data, action.data],
            errorMessage: null,
            error: null,
          },
        },
        error: null,
      };
    case DELETE_SECONDARY_TRANSACTION_DOCUMENT_SUCCESS:
      return {
        loading: false,
        data: {
          ...state.data,
          transactionDocuments: {
            loading: false,
            data: state.data.transactionDocuments.data.filter(({ id }) => id !== action.documentId),
            errorMessage: null,
            error: null,
          },
        },
        error: null,
      };
    case GET_SECONDARY_TRANSACTION_ERROR:
    case ADD_SECONDARY_TRANSACTION_DOCUMENT_ERROR:
    case DELETE_SECONDARY_TRANSACTION_DOCUMENT_ERROR:
      return {
        loading: false,
        error: action.error,
        data: {
          ...state.data,
          transactionDocuments: {
            loading: false,
            data: state.data.transactionDocuments.data,
            errorMessage: null,
            error: null,
          },
        },
      };
    default:
      return state;
  }
};

const initialIntroducerFeesState = {
  loading: true,
  data: null,
  error: null,
};

export const introducerFeesReducer = (
  state = initialIntroducerFeesState,
  { type, data, error },
) => {
  switch (type) {
    case GET_INTRODUCER_FEES:
      return { ...state, loading: true };
    case GET_INTRODUCER_FEES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case GET_INTRODUCER_FEES_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};
