import 'regenerator-runtime/runtime';

import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from 'app-state/root-reducer';
import rootSaga from 'app-state/root-saga';

export const configureRedux = () => {
  const history = createBrowserHistory();

  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers = composeWithDevTools({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  });

  const middlewares = [routerMiddleware(history), sagaMiddleware];

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const reducer = createRootReducer(history);

  const store = createStore(reducer, enhancer);
  sagaMiddleware.run(rootSaga);

  return { store, reducer, history };
};

const { store, reducer, history } = configureRedux();

export type RootState = ReturnType<typeof reducer>;
export { history };
export default store;
