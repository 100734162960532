import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLClient } from 'graphql-request';

import { useEnvVar } from '@frontend/config';

import { logout } from 'app-state/actions';
import { RootState } from 'app-state/store';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location(s): ${locations
          ?.map(l => `[L${l.line}:${l.column}]`)
          .join(', ')}, Path: ${path}`,
      );
    });
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const useGraphQLClient = (url = String(useEnvVar('GRAPHQL_ENDPOINT'))) => {
  const authToken = useSelector<RootState>(
    state => (state.session as { user: { authToken: string } }).user?.authToken,
  );

  return new GraphQLClient(url, {
    headers: {
      'x-auth-token': String(authToken),
    },
  });
};

const AuthenticatedGqlProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch();

  const authToken = useSelector<RootState>(
    state => (state.session as { user: { authToken: string } }).user?.authToken,
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      errorLink,
      new HttpLink({
        uri: window.config.GRAPHQL_ENDPOINT as string,
        headers: {
          'x-auth-token': authToken as string,
        },
        fetch: (uri, options) => {
          return fetch(uri, options).then(response => {
            if (!response.ok) {
              if (response.status === 401) {
                dispatch(logout(true));
              }
            }
            return response;
          });
        },
      }),
    ]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthenticatedGqlProvider;
