import * as constants from 'app-state/constants';
import {
  ADD_NEW_GRANT,
  ADD_NEW_GRANT_ERROR,
  ADD_NEW_GRANT_SUCCESS,
  RESET_NEW_GRANT_ADDING_STATE,
} from 'app-state/constants/share-options';

export const getShareOptions = () => ({ type: constants.GET_SHARE_OPTIONS });
export const getShareOptionsSuccess = data => ({ type: constants.GET_SHARE_OPTIONS_SUCCESS, data });
export const getShareOptionsError = error => ({ type: constants.GET_SHARE_OPTIONS_ERROR, error });
export const clearShareOptions = () => ({ type: constants.CLEAR_SHARE_OPTIONS });

export const getHolders = payload => ({ type: constants.GET_HOLDERS, payload });
export const getHoldersSuccess = data => ({ type: constants.GET_HOLDERS_SUCCESS, data });
export const getHoldersError = error => ({ type: constants.GET_HOLDERS_ERROR, error });

export const resetHolders = () => ({ type: constants.RESET_HOLDERS });

export const getGrantHolders = (companyUuid, shareOptionUuid) => ({
  type: constants.GET_GRANT_HOLDERS,
  companyUuid,
  shareOptionUuid,
});
export const getGrantHoldersSuccess = data => ({ type: constants.GET_GRANT_HOLDERS_SUCCESS, data });
export const getGrantHoldersError = error => ({ type: constants.GET_GRANT_HOLDERS_ERROR, error });

export const getGrantHoldersCapital = companyUuid => ({
  type: constants.GET_GRANT_HOLDERS_CAPITAL,
  companyUuid,
});
export const getGrantHoldersCapitalSuccess = data => ({
  type: constants.GET_GRANT_HOLDERS_CAPITAL_SUCCESS,
  data,
});
export const getGrantHoldersCapitalError = error => ({
  type: constants.GET_GRANT_HOLDERS_CAPITAL_ERROR,
  error,
});

export const removeUnvestedOptions = (
  companyUuid,
  sharePlanUuid,
  grantHolderUuid,
  hideModalAndGetHolders,
) => ({
  type: constants.REMOVE_UNVESTED_OPTIONS,
  companyUuid,
  sharePlanUuid,
  grantHolderUuid,
  hideModalAndGetHolders,
});
export const removeUnvestedOptionsSuccess = data => ({
  type: constants.REMOVE_UNVESTED_OPTIONS_SUCCESS,
  data,
});
export const removeUnvestedOptionsError = error => ({
  type: constants.REMOVE_UNVESTED_OPTIONS_ERROR,
  error,
});

export const createShareOptionPlan = params => ({
  type: constants.CREATE_SHARE_OPTION_PLAN,
  ...params,
});

export const addShareOptionPlanDocument = params => ({
  type: constants.ADD_SHARE_OPTION_PLAN_DOCUMENT,
  ...params,
});
export const addShareOptionPlanDocumentSuccess = document => ({
  type: constants.ADD_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS,
  document,
});
export const addShareOptionPlanDocumentError = error => ({
  type: constants.ADD_SHARE_OPTION_PLAN_DOCUMENT_ERROR,
  error,
});

export const deleteShareOptionPlanDocument = params => ({
  type: constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT,
  ...params,
});
export const deleteShareOptionPlanDocumentSuccess = documentId => ({
  type: constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT_SUCCESS,
  documentId,
});
export const deleteShareOptionPlanDocumentError = error => ({
  type: constants.DELETE_SHARE_OPTION_PLAN_DOCUMENT_ERROR,
  error,
});

export const getShareOptionPlan = params => ({ type: constants.GET_SHARE_OPTION_PLAN, ...params });
export const getShareOptionPlanSuccess = data => ({
  type: constants.GET_SHARE_OPTION_PLAN_SUCCESS,
  data,
});
export const getShareOptionPlanError = error => ({
  type: constants.GET_SHARE_OPTION_PLAN_ERROR,
  error,
});

export const updateShareOptionPlan = params => ({
  type: constants.UPDATE_SHARE_OPTION_PLAN,
  ...params,
});
export const updateShareOptionPlanSuccess = data => ({
  type: constants.UPDATE_SHARE_OPTION_PLAN_SUCCESS,
  data,
});
export const updateShareOptionPlanError = error => ({
  type: constants.UPDATE_SHARE_OPTION_PLAN_ERROR,
  error,
});

export const addNewGrant = params => ({ type: ADD_NEW_GRANT, ...params });
export const addNewGrantSuccess = data => ({ type: ADD_NEW_GRANT_SUCCESS, data });
export const addNewGrantError = error => ({ type: ADD_NEW_GRANT_ERROR, error });
export const resetNewGrantAddingState = () => ({ type: RESET_NEW_GRANT_ADDING_STATE });

export const getShareOptionData = params => ({ type: constants.GET_SHARE_OPTION_DATA, ...params });
export const getShareOptionDataSuccess = data => ({
  type: constants.GET_SHARE_OPTION_DATA_SUCCESS,
  data,
});
export const getShareOptionDataError = error => ({
  type: constants.GET_SHARE_OPTION_DATA_ERROR,
  error,
});
export const resetShareOptionData = () => ({ type: constants.RESET_SHARE_OPTION_DATA });

export const exerciseShareOptions = params => ({
  type: constants.EXERCISE_SHARE_OPTIONS,
  ...params,
});
export const exerciseShareOptionsSuccess = data => ({
  type: constants.EXERCISE_SHARE_OPTIONS_SUCCESS,
  data,
});
export const exerciseShareOptionsError = error => ({
  type: constants.EXERCISE_SHARE_OPTIONS_ERROR,
  error,
});
export const resetExerciseShareOptions = () => ({ type: constants.RESET_EXERCISE_SHARE_OPTIONS });

export const getGrant = params => ({ type: constants.GET_GRANT, ...params });
export const getGrantSuccess = data => ({ type: constants.GET_GRANT_SUCCESS, data });
export const getGrantError = error => ({ type: constants.GET_GRANT_ERROR, error });

export const editGrant = params => ({ type: constants.EDIT_GRANT, ...params });
export const editGrantSuccess = data => ({ type: constants.EDIT_GRANT_SUCCESS, data });
export const editGrantError = error => ({ type: constants.EDIT_GRANT_ERROR, error });

export const deleteGrant = params => ({ type: constants.DELETE_GRANT, ...params });
export const deleteGrantError = error => ({ type: constants.DELETE_GRANT_ERROR, error });

export const viewGrant = params => ({ type: constants.VIEW_GRANT, ...params });

export const getGrantHolderDetails = payload => ({
  type: constants.GET_GRANT_HOLDER_DETAILS,
  payload,
});
export const getGrantHolderDetailsSuccess = payload => ({
  type: constants.GET_GRANT_HOLDER_DETAILS_SUCCESS,
  payload,
});
export const getGrantHolderDetailsError = payload => ({
  type: constants.GET_GRANT_HOLDER_DETAILS_ERROR,
  payload,
});
export const resetGrantHolderDetails = () => ({ type: constants.RESET_GRANT_HOLDER_DETAILS });

export const updateGrantHolderDetails = params => ({
  type: constants.UPDATE_GRANT_HOLDER_DETAILS,
  ...params,
});

export const addGrantDocument = params => ({ type: constants.ADD_GRANT_DOCUMENT, ...params });
export const addGrantDocumentSuccess = data => ({
  type: constants.ADD_GRANT_DOCUMENT_SUCCESS,
  data,
});
export const addGrantDocumentError = error => ({ type: constants.ADD_GRANT_DOCUMENT_ERROR, error });

export const deleteGrantDocument = params => ({ type: constants.DELETE_GRANT_DOCUMENT, ...params });
export const deleteGrantDocumentSuccess = data => ({
  type: constants.DELETE_GRANT_DOCUMENT_SUCCESS,
  data,
});
export const deleteGrantDocumentError = error => ({
  type: constants.DELETE_GRANT_DOCUMENT_ERROR,
  error,
});

export const getGrantTransactionalHistory = (companyUuid, sharePlanUuid, grantId) => ({
  type: constants.GET_GRANT_TRANSACTIONAL_HISTORY,
  companyUuid,
  sharePlanUuid,
  grantId,
});
export const getGrantTransactionalHistorySuccess = data => ({
  type: constants.GET_GRANT_TRANSACTIONAL_HISTORY_SUCCESS,
  data,
});
export const getGrantTransactionalHistoryError = error => ({
  type: constants.GET_GRANT_TRANSACTIONAL_HISTORY_ERROR,
  error,
});

export const getGrantHoldersEmails = payload => ({
  type: constants.GET_GRANT_HOLDERS_EMAILS,
  payload,
});
export const getGrantHoldersEmailsSuccess = payload => ({
  type: constants.GET_GRANT_HOLDERS_EMAILS_SUCCESS,
  payload,
});
export const getGrantHoldersEmailsError = payload => ({
  type: constants.GET_GRANT_HOLDERS_EMAILS_ERROR,
  payload,
});
export const resetHoldersEmails = () => ({ type: constants.RESET_GRANT_HOLDERS_EMAILS });

export const searchHolders = payload => ({ type: constants.SEARCH_HOLDERS, payload });
export const searchHoldersSuccess = payload => ({
  type: constants.SEARCH_HOLDERS_SUCCESS,
  payload,
});
export const searchHoldersError = payload => ({ type: constants.SEARCH_HOLDERS_ERROR, payload });
export const resetHoldersSearch = () => ({ type: constants.RESET_HOLDERS_SEARCH });
