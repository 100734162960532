import styled from 'styled-components';

import Button from 'shared-parts/components/button/standard-button';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import { COLORS } from 'shared-parts/constants';

const { primary } = COLORS;

export const StyledSuccessModal = styled(SuccessModal)`
  padding: 33px;

  ${Button} {
    margin-top: 25px;
    text-transform: uppercase;
  }
`;

export const StyledSecondaryHeading = styled.h2`
  color: ${primary};
  letter-spacing: 0;
  text-align: center;
  font-size: 16px;
`;
