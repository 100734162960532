import { Form } from 'formik';
import styled from 'styled-components';

import { FormButton } from 'shared-parts/components/button/form-buttons';
import { COLORS } from 'shared-parts/constants';

const { primary, systemGreen, neutral800 } = COLORS;

export const FormWrapper = styled(Form)`
  width: 100%;
`;

export const FormRow = styled.div`
  box-sizing: content-box;
  display: flex;
  padding: 20px 0;
`;

export const FormColumn = styled.div`
  display: inline-block;
  padding: 0 5px;
  width: ${({ width = '100%' }) => width};

  :first-child,
  :last-child {
    padding: 0;
  }
`;

export const Label = styled.label`
  color: ${primary};
  font-weight: bold;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AddButton = styled(FormButton)`
  background-color: ${({ disabled }) => (disabled ? neutral800 : systemGreen)};
  margin: 20px 0;
`;
