import { useEnvVar } from '@frontend/config';
import { formatDate, LDF } from '@frontend/datetime';

import getIssuanceStatusColor from 'helpers/get-issuance-status-color';
import transformIssuanceStatus from 'helpers/transform-issuance-status';
import Visible from 'shared-parts/components/svg-icons/visible';
import { COLORS, tableColumnStyles } from 'shared-parts/constants';
import { addCurrencySign, toAbbrInt, valueOrHyphen } from 'shared-parts/helpers';

type DealDetails = {
  issuerName: string;
};

type Deal = {
  baseCurrency: string;
};

type Company = {
  name: string;
};

const isAgentDealsTableEnabled = useEnvVar('ENABLED_AGENT_DEALS_TABLE');

/**
 * table located at x.com/globacap/deals-list
 */
export const TABLE_KEYS = {
  Operations: () => [
    {
      field: 'company',
      transform: (company: Company) => company.name,
      styles: () => 'text-align: left;',
    },
    ...(isAgentDealsTableEnabled
      ? [
          {
            field: 'dealDetail',
            transform: (details: DealDetails) => details?.issuerName,
            styles: () => 'text-align: left;',
          },
        ]
      : []),
    { field: 'name', styles: () => 'text-align: left;' },
    {
      field: 'dealType',
      styles: () => 'text-transform: capitalize;',
      transform: (dealType: string) =>
        dealType === 'alternative_investment_vehicle' ? 'alternatives' : dealType,
    },
    {
      field: 'softTargetAmount',
      transform: (softTargetAmount: string, deal: Deal) =>
        valueOrHyphen(
          softTargetAmount,
          addCurrencySign(toAbbrInt(softTargetAmount), deal.baseCurrency),
        ), // eslint-disable-line
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'targetAmount',
      transform: (targetAmount: string, deal: Deal) =>
        valueOrHyphen(targetAmount, addCurrencySign(toAbbrInt(targetAmount), deal.baseCurrency)),
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'valueFunded',
      transform: (valueFunded: string, deal: Deal) =>
        valueOrHyphen(valueFunded, addCurrencySign(toAbbrInt(valueFunded), deal.baseCurrency)),
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'subscriptionStart',
      transform: (value: any) => formatDate(value, LDF.DATE_TEXTUAL),
      styles: () => tableColumnStyles.date,
    },
    {
      field: 'subscriptionEnd',
      transform: (value: any) => formatDate(value, LDF.DATE_TEXTUAL),
      styles: () => tableColumnStyles.date,
    },
    {
      field: 'status',
      transform: transformIssuanceStatus,
      styles: (status: string) =>
        `color: ${getIssuanceStatusColor(
          status,
        )};font-weight: bold;text-transform: capitalize;white-space: nowrap;`,
    },
    { text: Visible(17, 17, COLORS.neutral800), styles: () => tableColumnStyles.action },
  ],
  MultiCompany: () => [
    {
      field: 'company',
      transform: (company: Company) => company.name,
      styles: () => 'text-align: left;',
    },
    ...(isAgentDealsTableEnabled
      ? [
          {
            field: 'dealDetail',
            transform: (details: DealDetails) => details?.issuerName,
            styles: () => 'text-align: left;',
          },
        ]
      : []),
    { field: 'name', styles: () => 'text-align: left;' },
    {
      field: 'dealType',
      styles: () => 'text-transform: capitalize;',
      transform: (dealType: string) =>
        dealType === 'alternative_investment_vehicle' ? 'alternatives' : dealType,
    },
    {
      field: 'softTargetAmount',
      transform: (softTargetAmount: string, deal: Deal) =>
        valueOrHyphen(
          softTargetAmount,
          addCurrencySign(toAbbrInt(softTargetAmount), deal.baseCurrency),
        ), // eslint-disable-line
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'targetAmount',
      transform: (targetAmount: string, deal: Deal) =>
        valueOrHyphen(targetAmount, addCurrencySign(toAbbrInt(targetAmount), deal.baseCurrency)), // eslint-disable-line
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'valueFunded',
      transform: (valueFunded: string, deal: Deal) =>
        valueOrHyphen(valueFunded, addCurrencySign(toAbbrInt(valueFunded), deal.baseCurrency)),
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'subscriptionStart',
      transform: (value: any) => formatDate(value, LDF.DATE_TEXTUAL),
      styles: () => tableColumnStyles.date,
    },
    {
      field: 'subscriptionEnd',
      transform: (value: any) => formatDate(value, LDF.DATE_TEXTUAL),
      styles: () => tableColumnStyles.date,
    },
    {
      field: 'status',
      transform: transformIssuanceStatus,
      styles: (status: string) =>
        `color: ${getIssuanceStatusColor(
          status,
        )};font-weight: bold;text-transform: capitalize;white-space: nowrap;`,
    },
    { text: Visible(17, 17, COLORS.neutral800), styles: () => tableColumnStyles.action },
  ],
  Company: () => [
    ...(isAgentDealsTableEnabled
      ? [
          {
            field: 'dealDetail',
            transform: (details: DealDetails) => details?.issuerName,
            styles: () => 'text-align: left;',
          },
        ]
      : []),
    { field: 'name', styles: () => 'text-align: left;' },
    {
      field: 'dealType',
      styles: () => 'text-align: left;text-transform: capitalize;',
      transform: (dealType: string) =>
        dealType === 'alternative_investment_vehicle' ? 'alternatives' : dealType,
    },
    {
      field: 'softTargetAmount',
      transform: (softTargetAmount: string, deal: Deal) =>
        valueOrHyphen(
          softTargetAmount,
          addCurrencySign(toAbbrInt(softTargetAmount), deal.baseCurrency),
        ), // eslint-disable-line
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'targetAmount',
      transform: (targetAmount: string, deal: Deal) =>
        valueOrHyphen(targetAmount, addCurrencySign(toAbbrInt(targetAmount), deal.baseCurrency)),
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'valueFunded',
      transform: (valueFunded: string, deal: Deal) =>
        valueOrHyphen(valueFunded, addCurrencySign(toAbbrInt(valueFunded), deal.baseCurrency)),
      styles: () => tableColumnStyles.number,
    },
    {
      field: 'subscriptionStart',
      transform: (value: any) => formatDate(value, LDF.DATE_TEXTUAL),
      styles: () => tableColumnStyles.date,
    },
    {
      field: 'subscriptionEnd',
      transform: (value: any) => formatDate(value, LDF.DATE_TEXTUAL),
      styles: () => tableColumnStyles.date,
    },
    {
      field: 'status',
      transform: transformIssuanceStatus,
      styles: (status: string) =>
        `color: ${getIssuanceStatusColor(
          status,
        )};font-weight: bold;text-transform: capitalize;white-space: nowrap;`,
    },
    { text: Visible(17, 17, COLORS.neutral800), styles: () => tableColumnStyles.action },
  ],
};
