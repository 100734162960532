import {
  APPROVE_ADDITIONAL_INFO,
  APPROVE_ADDITIONAL_INFO_HMRC,
  TRANSACTION_APPROVED,
  TRANSACTION_STATE_HMRC,
} from '../constants';

import BaseResultingModal from './base-resulting-modal';

const TransactionApprovedModal = ({ hideModal, state }) => (
  <BaseResultingModal
    hideModal={hideModal}
    resultingText={TRANSACTION_APPROVED}
    additionalInfo={
      state === TRANSACTION_STATE_HMRC ? APPROVE_ADDITIONAL_INFO_HMRC : APPROVE_ADDITIONAL_INFO
    }
  />
);

export default TransactionApprovedModal;
