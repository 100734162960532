import { checkPathSuitability } from '@frontend/utils';

import getAllowedRoutes from 'constants/allowed-routes';
import Routes from 'constants/routes';
import memorizePath from 'shared-parts/helpers/memorize-path';

export default class {
  constructor({ browserPath, redirectUrl, user }) {
    this.browserPath = browserPath;
    this.redirectUrl = redirectUrl;
    this.user = user;
  }

  checkIfUserShouldBeRedirected(urls, path) {
    if (!urls) return false;
    const someUrlsBlacklisted = urls.black.length;
    const whitelisted = urls.white.some(url => checkPathSuitability(url, path));
    const blacklisted = someUrlsBlacklisted
      ? urls.black.some(url => checkPathSuitability(url, path))
      : !whitelisted;

    return blacklisted && !whitelisted;
  }

  checkIfRedirectPathIsSuitable(urls) {
    const isNotSameUser = this.redirectUrl && this.user.uuid !== this.redirectUrl.userUuid;
    const isRedirectToForbidden = this.redirectUrl && this.redirectUrl.path === Routes.Forbidden();

    if (this.redirectUrl && !isRedirectToForbidden && this.isUserAuthenticated && !isNotSameUser) {
      return !this.checkIfUserShouldBeRedirected(urls, this.redirectUrl.path);
    }

    return false;
  }

  getRedirectURL(urls) {
    const [lastUrlFromWhitelist] = urls.white.slice(-1) || [];
    const defaultRedirect = urls.redirectUrl && urls.redirectUrl.path;
    const lastUrlPath = lastUrlFromWhitelist && lastUrlFromWhitelist.path;
    const suggestedRedirect = defaultRedirect || lastUrlPath || Routes.Root();

    return this.checkIfRedirectPathIsSuitable(urls)
      ? this.redirectUrl
      : { path: suggestedRedirect };
  }

  handleRedirects(urls) {
    if (this.checkIfUserShouldBeRedirected(urls, this.browserPath)) {
      if (!this.isUserAuthenticated) {
        memorizePath();
      }
      return this.getRedirectURL(urls);
    }

    return null;
  }

  get isUserAuthenticated() {
    return JSON.stringify(this.user) !== '{}';
  }

  get to() {
    return this.handleRedirects(getAllowedRoutes(this.user));
  }
}
