import {
  CREATE_DEAL,
  GET_DEALS,
  GET_DEALS_ERROR,
  GET_DEALS_SUCCESS,
  GET_USER_DEALS,
  GET_USER_DEALS_ERROR,
  GET_USER_DEALS_SUCCESS,
  RESET_DEAL_STRUCTURE_FORM_ERROR,
  RESET_USER_DEALS,
  SET_DEAL_STRUCTURE_FORM_ERROR,
} from 'app-state/deals/constants';
import type {
  CreateDealActionPayload,
  Deal,
  DealsActionTypes,
  UserDeal,
  UserDealsActionTypes,
} from 'app-state/deals/types';
import type { Errors } from 'app-state/types';

export const getDeals = (): DealsActionTypes => ({ type: GET_DEALS });
export const getDealsSuccess = (data: Deal[]): DealsActionTypes => ({
  type: GET_DEALS_SUCCESS,
  data,
});
export const getDealsError = (errors: Errors): DealsActionTypes => ({
  type: GET_DEALS_ERROR,
  errors,
});

export const createDeal = (payload: CreateDealActionPayload): DealsActionTypes => ({
  type: CREATE_DEAL,
  payload,
});
export const setDealStructureFormError = (payload: Errors): DealsActionTypes => ({
  type: SET_DEAL_STRUCTURE_FORM_ERROR,
  payload,
});
export const resetDealStructureFormError = (): DealsActionTypes => ({
  type: RESET_DEAL_STRUCTURE_FORM_ERROR,
});

export const getUserDeals = (payload: { companyUuid: string }): UserDealsActionTypes => ({
  type: GET_USER_DEALS,
  payload,
});
export const getUserDealsSuccess = (data: UserDeal[]): UserDealsActionTypes => ({
  type: GET_USER_DEALS_SUCCESS,
  data,
});
export const getUserDealsError = (errors: Errors): UserDealsActionTypes => ({
  type: GET_USER_DEALS_ERROR,
  errors,
});
export const resetUserDeals = (): UserDealsActionTypes => ({
  type: RESET_USER_DEALS,
});
