import * as constants from 'app-state/constants';

import {
  createNavigationItem,
  createSubNavigationItem,
  editNavigationItem,
  editSubNavigationItem,
  removeNavigationItem,
  removeSubNavigationItem,
} from './navigation.helpers';

const getNavigationState = (params = {}) => ({
  loading: true,
  items: {},
  errors: null,
  ...params,
});

export const navigationReducer = (state = getNavigationState(), { type, error, items }) => {
  switch (type) {
    case constants.RESET_NAVIGATION:
      return getNavigationState();
    case constants.FETCH_NAVIGATION:
      return getNavigationState();
    case constants.FETCH_NAVIGATION_SUCCESS:
      return getNavigationState({
        loading: false,
        items: items.reduce((prev, item) => ({ ...prev, [decodeURI(item.url)]: item }), {}),
      });
    case constants.FETCH_NAVIGATION_ERROR:
      return getNavigationState({
        loading: false,
        errors: error,
      });
    default:
      return state;
  }
};

export const formatSubNavigationItems = items =>
  Object.entries(items).reduce((prev, [itemURL, subItems]) => {
    if (!subItems) {
      return prev;
    }

    const byURL = subItems.reduce(
      (previous, currItem) => ({ ...previous, [decodeURI(currItem.url)]: currItem }),
      {},
    );

    return { ...prev, [itemURL]: byURL };
  }, {});

const getSubNavigationState = (params = {}) => ({
  loading: true,
  items: {},
  errors: null,
  ...params,
});

export const subNavigationReducer = (state = getSubNavigationState(), { type, error, items }) => {
  switch (type) {
    case constants.RESET_SUB_NAVIGATION:
      return getSubNavigationState();
    case constants.FETCH_SUB_NAVIGATION:
      return getSubNavigationState();
    case constants.FETCH_SUB_NAVIGATION_SUCCESS:
      return getSubNavigationState({ loading: false, items: formatSubNavigationItems(items) });
    case constants.FETCH_SUB_NAVIGATION_ERROR:
      return getSubNavigationState({
        loading: false,
        items: state.items,
        errors: error,
      });
    default:
      return state;
  }
};

const getEditableNavigationState = (params = {}) => ({
  loading: true,
  data: [],
  error: null,
  ...params,
});

export const editableNavigationReducer = (
  state = getEditableNavigationState(),
  { type, error, data, createdItem, deletedItem, editedItem },
) => {
  switch (type) {
    case constants.RESET_EDITABLE_NAVIGATION:
      return getEditableNavigationState();
    case constants.FETCH_EDITABLE_NAVIGATION:
      return getEditableNavigationState();
    case constants.FETCH_EDITABLE_NAVIGATION_SUCCESS:
      return getEditableNavigationState({ loading: false, data });
    case constants.FETCH_EDITABLE_NAVIGATION_ERROR:
      return getEditableNavigationState({ loading: false, error });
    case constants.EDIT_NAVIGATION_ITEM_SUCCESS: {
      const subNavigationItemHasBeenEdited = Boolean(editedItem.parentId);

      if (subNavigationItemHasBeenEdited) {
        return getEditableNavigationState({
          loading: false,
          data: editSubNavigationItem(state, editedItem),
        });
      }

      return getEditableNavigationState({
        loading: false,
        data: editNavigationItem(state, editedItem),
      });
    }
    case constants.CREATE_NAVIGATION_ITEM_SUCCESS: {
      const subNavigationItemHasBeenCreated = Boolean(createdItem.parentId);

      if (subNavigationItemHasBeenCreated) {
        return getEditableNavigationState({
          loading: false,
          data: createSubNavigationItem(state, createdItem),
        });
      }

      return getEditableNavigationState({
        loading: false,
        data: createNavigationItem(state, createdItem),
      });
    }
    case constants.DELETE_NAVIGATION_ITEM_SUCCESS: {
      const subNavigationItemHasBeenRemoved = Boolean(deletedItem.navigationItemId);

      if (subNavigationItemHasBeenRemoved) {
        return getEditableNavigationState({
          loading: false,
          data: removeSubNavigationItem(state, deletedItem),
        });
      }

      return getEditableNavigationState({
        loading: false,
        data: removeNavigationItem(state, deletedItem),
      });
    }
    default:
      return state;
  }
};
