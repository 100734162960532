import { FC } from 'react';

import { COLORS } from 'shared-parts/constants';

const { secondary } = COLORS;

type Props = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const InfoIcon: FC<React.PropsWithChildren<Props>> = ({
  fillColor = secondary,
  height = 20,
  width = 20,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path
      fill={fillColor}
      d="M9.2,14h1.6V9.2H9.2ZM10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,14.4A6.4,6.4,0,1,1,16.4,10,6.408,6.408,0,0,1,10,16.4ZM9.2,7.6h1.6V6H9.2Z"
    />
  </svg>
);

export default InfoIcon;
