import styled from 'styled-components';

import { FormikButton } from 'shared-parts/components/button';
import { COLORS, FONTS } from 'shared-parts/constants';

const { primary } = COLORS;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 240px;
  padding: 20px;
`;

const ModalTitle = styled.div`
  font-size: 35px;
  margin: 30px;
  font-weight: 300;
  color: ${primary};
  font-family: ${FONTS.oswaldFont};
  text-transform: uppercase;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-family: ${FONTS.mainFont};
`;

const OkButton = styled(FormikButton)`
  height: 40px;
`;

const CancelButton = styled.button`
  background: transparent;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: ${primary};
  padding: 7px;
`;

const AddButton = styled(OkButton)`
  margin-left: 15px;
`;

export { ModalWrapper, ModalTitle, ButtonsWrapper, AddButton, CancelButton, OkButton };
