import styled from 'styled-components';

import StandardButton from 'shared-parts/components/button/standard-button';
import { COLORS, FONTS } from 'shared-parts/constants';

const GrantsRemovedWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 240px;
  padding: 60px 172px;
  width: 580px;
`;

const GrantsRemovedText = styled.span`
  font-size: 35px;
  font-weight: 200;
  color: ${COLORS.primary};
  text-align: center;
  margin-bottom: 30px;
  font-family: ${FONTS.oswaldFont};
`;

const OkButton = styled(StandardButton)`
  height: 40px;
  width: 120px;
`;

export { GrantsRemovedWrapper, GrantsRemovedText, OkButton };
