import { formatDate, LDF } from '@frontend/datetime';

import FormOverview from 'shared/form-overview';
import { toDefinedFractionSizeNoRound } from 'shared-parts/helpers/format-number';

import {
  NOMINAL_SHARE_PRICE_WILL_CHANGE_FROM,
  NUMBER_OF_SHARES_WILL_CHANGE_FROM,
  ROUNDING_LABELS,
  ROUNDING_NUMBER_OF_SHARES,
  SUMMARY,
  TO,
  TRANSACTION_DATE,
} from '../constants';

const getSections = ({
  oldNominalSharePrice,
  nominalSharePrice,
  quantity,
  roundingStrategy,
  sharesNumberPrecision,
  transactionDate,
}) => [
  {
    type: 'header',
    content: SUMMARY,
  },
  {
    type: 'fromTo',
    content: {
      from: {
        label: NOMINAL_SHARE_PRICE_WILL_CHANGE_FROM,
        value: oldNominalSharePrice,
      },
      to: {
        label: TO,
        value: nominalSharePrice,
      },
    },
  },
  {
    type: 'fromTo',
    content: {
      from: {
        label: NUMBER_OF_SHARES_WILL_CHANGE_FROM,
        value: toDefinedFractionSizeNoRound(quantity.fromQuantity, sharesNumberPrecision),
      },
      to: {
        label: TO,
        value: toDefinedFractionSizeNoRound(quantity.toQuantity, sharesNumberPrecision),
      },
    },
  },
  {
    type: 'cell',
    content: {
      label: ROUNDING_NUMBER_OF_SHARES,
      value: ROUNDING_LABELS[roundingStrategy],
    },
  },
  {
    type: 'cell',
    content: {
      label: TRANSACTION_DATE,
      value: formatDate(transactionDate, LDF.DATE_TEXTUAL),
    },
  },
];

const ShareClassOverview = ({ hideOverlay, handleSubmit, title, ...restProps }) => (
  <FormOverview
    title={title}
    hideOverview={hideOverlay}
    handleSubmit={handleSubmit}
    sections={getSections(restProps)}
  />
);

export default ShareClassOverview;
