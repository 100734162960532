import { COLORS } from 'shared-parts/constants';

const { neutral500 } = COLORS;

export interface NewEditIcon {
  readonly color?: string;
}

export const NewEditIcon = ({ color = neutral500 }: NewEditIcon) => (
  <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2179 3.51978C11.4292 3.32478 11.4292 2.99979 11.2179 2.81479L9.95042 1.64479C9.75 1.44979 9.39792 1.44979 9.18667 1.64479L8.19 2.55979L10.2213 4.43478M1.625 8.62478V10.4998H3.65625L9.64708 4.96478L7.61583 3.08978L1.625 8.62478Z"
      fill={color}
    />
  </svg>
);
