import * as constants from './share-plan-invite-employees.constants';

export const bulkInviteEmployees = (payload: { data: FormData; id: string }) => {
  return {
    type: constants.bulkInviteEmployees.REQUEST,
    ...payload,
  };
};

export const bulkInviteEmployeesSuccess = () => {
  return {
    type: constants.bulkInviteEmployees.SUCCESS,
  };
};

export const bulkInviteEmployeesFailure = (payload: { error: string }) => {
  return {
    type: constants.bulkInviteEmployees.FAILURE,
    ...payload,
  };
};

export const resetBulkInvite = () => {
  return {
    type: constants.resetBulkInvite,
  };
};

export type Employee = {
  email: string;
  name: string;
  participantCode?: string;
};

export const inviteEmployees = (payload: { data: Employee[]; id: string }) => {
  return {
    type: constants.inviteEmployees.REQUEST,
    ...payload,
  };
};

export const inviteEmployeesSuccess = () => {
  return {
    type: constants.inviteEmployees.SUCCESS,
  };
};

export const inviteEmployeesFailure = (payload: { error: string }) => {
  return {
    type: constants.inviteEmployees.FAILURE,
    ...payload,
  };
};
