import { Form } from 'formik';
import styled from 'styled-components';

import { StandardButton } from 'shared-parts/components';

const FormWrapper = styled(Form)`
  width: 100%;
  margin: 32px 0 12px;
`;

const SubmitButton = styled(StandardButton)`
  margin-top: 32px;
  width: 200px;
  user-select: none;
`;

export { FormWrapper, SubmitButton };
