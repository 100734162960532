import { createSelector } from 'reselect';

const getIndicationOfInterestState = state => state.indicationOfInterest;
export const getIndicationOfInterestItems = createSelector(
  getIndicationOfInterestState,
  ({ data: { items } }) => items,
);
export const getIndicationOfInterestLoading = createSelector(
  getIndicationOfInterestState,
  ({ loading }) => loading,
);
export const getIndicationOfInterestErrors = createSelector(
  getIndicationOfInterestState,
  ({ errors }) => errors,
);
export const getIndicationOfInterestMeta = createSelector(
  getIndicationOfInterestState,
  ({ data: { meta } }) => meta,
);

const getDealIOIDetailsState = state => state.dealIOIDetails;
export const getDealIOIDetailsData = createSelector(getDealIOIDetailsState, ({ data }) => data);
export const getDealIOIDetailsLoading = createSelector(
  getDealIOIDetailsState,
  ({ loading }) => loading,
);
export const getDealIOIDetailsErrors = createSelector(
  getDealIOIDetailsState,
  ({ errors }) => errors,
);
