import { useEffect, useState } from 'react';

import { DATA_E2E_ATTRIBUTE_KEY } from 'shared-parts/constants/internal';

import Table from '../regular-table/table';

import renderExpandedRow from './components/expanded-row';

export const getExpandableTableControls = (props, stateControls) => {
  const { lastExpandedRow, setLastExpandedRow, expandedItemsIds, setExpandedItemsIds } =
    stateControls;

  const expandRow = (expandId, rowData) => {
    if (!expandedItemsIds.includes(expandId)) {
      setLastExpandedRow(expandId);
      setExpandedItemsIds(props.expandSingleRow ? [expandId] : [...expandedItemsIds, expandId]);

      if (props.onRowExpand) {
        props.onRowExpand(expandId, rowData);
      }
    }
  };
  const closeRow = closeId => {
    setExpandedItemsIds(expandedItemsIds.filter(id => id !== closeId));
    setLastExpandedRow('');
  };

  const handleRowClick = rowData => {
    const { id } = rowData;
    return expandedItemsIds.includes(id) ? closeRow(id, rowData) : expandRow(id, rowData);
  };

  return { expandedItemsIds, lastExpandedRow, expandRow, closeRow, handleRowClick };
};

const ExpandableTable = props => {
  const {
    data,
    keys,
    error,
    header,
    onSort,
    loading,
    className,
    fixedHeader,
    totalRowData,
    onCloseError,
    createControls,
    onInfiniteScroll,
    noRecordsMessage,
    resetRowScrollData,
    isScrollbarVisible,
    createTableControls,
    ...rest
  } = props;
  const dataE2EAttribute = rest[DATA_E2E_ATTRIBUTE_KEY] || 'expanded-table';
  const [lastExpandedRow, setLastExpandedRow] = useState('');
  const [expandedItemsIds, setExpandedItemsIds] = useState([]);
  const stateControls = {
    lastExpandedRow,
    setLastExpandedRow,
    expandedItemsIds,
    setExpandedItemsIds,
  };
  const expandableTableControls = props.createControls(props, stateControls);

  useEffect(() => {
    if (props.defaultExpandedId) expandableTableControls.expandRow(props.defaultExpandedId);
  }, [props.defaultExpandedId]);

  return (
    <Table
      keys={keys}
      data={data}
      error={error}
      header={header}
      onSort={onSort}
      loading={loading}
      className={className}
      fixedHeader={fixedHeader}
      totalRowData={totalRowData}
      onCloseError={onCloseError}
      createControls={createControls}
      onInfiniteScroll={onInfiniteScroll}
      noRecordsMessage={noRecordsMessage}
      expandedItemsIds={expandedItemsIds}
      resetRowScrollData={resetRowScrollData}
      isScrollbarVisible={isScrollbarVisible}
      createTableControls={createTableControls}
      onRowClick={expandableTableControls.handleRowClick}
      renderExpandedRow={renderExpandedRow(props, expandableTableControls)}
      data-e2e={dataE2EAttribute}
    />
  );
};

ExpandableTable.defaultProps = {
  createControls: getExpandableTableControls,
};

export default ExpandableTable;
