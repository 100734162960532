import { FC, useState } from 'react';

import { useRequest } from '@frontend/http';
import { KycKybStatus } from '@frontend/kyc-kyb';

import { getCompany, getUser } from 'app-state/selectors';

import api from 'constants/api';
import { FeatureFlag, useFeatureFlag } from 'providers';
import useTypedSelector from 'hooks/use-typed-selector';
import Documents from 'shared/investor-modal/documents';
import Interactions from 'shared/investor-modal/interactions';
import { SideFormTabs } from 'shared/side-form';

import InvestorDetails from '../investor-details-form/investor-details-form.wrapper';
import InvestorHistory from '../investor-history';
import KYCKYBReportDownload from '../kyc-kyb';
import Permissions from '../permissions';
import Preferences from '../preferences';
import type { InvestorFormProps } from '../types';
import Wallet from '../wallet';

const InvestorForm: FC<React.PropsWithChildren<InvestorFormProps>> = ({
  closeForm,
  isEditing,
  lastInviteSentAt,
  investorUuid,
  isFormDisabled,
  investorStatus,
  handleFormSubmit,
  initialValues,
  isInteractionsVisible,
  isWalletsTabVisible,
  isDocumentsVisible,
  dealUuid,
  investor,
  investorType,
  updateList,
}) => {
  const isPreferencesVisible = isEditing && investorStatus === 'registered';

  const { operationalAdmin: isOperationalAdmin } = useTypedSelector(getUser);

  const {
    data: { id: companyId, uuid: companyUuid },
  } = useTypedSelector(getCompany);

  // fetch current user to get roles, cause sign endpoint doesn't provide it, getUser is using user's session
  const { data: user } = useRequest(api.CurrentUser());

  const isSuperAdmin = Boolean(
    user?.roles?.find((role: { companyId: any }) => role.companyId === companyId)?.name ===
      'super_admin',
  );

  const showKYCKYBTabs =
    (isOperationalAdmin || isSuperAdmin) && useFeatureFlag(FeatureFlag.USE_MUIMOUS);

  const tabs = [
    {
      key: 'investorDetails',
      name: 'Investor Details',
      component: (
        <InvestorDetails
          closeForm={closeForm}
          isEditing={isEditing}
          lastInviteSentAt={lastInviteSentAt}
          investorUuid={investorUuid}
          isFormDisabled={isFormDisabled}
          investorStatus={investorStatus}
          handleFormSubmit={handleFormSubmit}
          initialValues={initialValues}
          investorType={investorType}
          investorData={investor?.investor}
        />
      ),
    },
    ...(showKYCKYBTabs
      ? [
          {
            key: 'KYCKYBReportDownload',
            name: 'KYC/KYB',
            component: (
              <KYCKYBReportDownload
                investorUuid={investorUuid}
                kycProvider={investor?.investor?.kycProvider}
                onboardingStatus={investor?.investor?.onboardingStatus as KycKybStatus | undefined}
                companyUuid={companyUuid}
              />
            ),
          },
        ]
      : []),
    ...(isEditing && investorStatus === 'registered'
      ? [
          {
            key: 'permissions',
            name: 'Permissions',
            component: (
              <Permissions
                investorUuid={investorUuid}
                closeForm={closeForm}
                updateList={updateList}
              />
            ),
          },
          {
            key: 'investorHistory',
            name: 'Investor History',
            component: <InvestorHistory investorUuid={investorUuid} />,
          },
          ...(isWalletsTabVisible
            ? [
                {
                  key: 'wallet',
                  name: 'Wallet',
                  component: <Wallet investorUuid={investorUuid} />,
                },
              ]
            : []),
        ]
      : []),
    ...(isInteractionsVisible
      ? [
          {
            key: 'interactions',
            name: 'Interactions',
            component: <Interactions dealUuid={dealUuid} investor={investor} />,
          },
        ]
      : []),
    ...(isPreferencesVisible
      ? [
          {
            key: 'preferences',
            name: 'Preferences',
            component: <Preferences investorUuid={investorUuid} />,
          },
        ]
      : []),
    ...(isDocumentsVisible
      ? [
          {
            key: 'documents',
            name: 'Documents',
            component: <Documents dealUuid={dealUuid} investor={investor} />,
          },
        ]
      : []),
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <>
      <SideFormTabs items={tabs} selected={activeTab.key} onClick={setActiveTab} />
      {activeTab.component}
    </>
  );
};

export default InvestorForm;
