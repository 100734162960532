import * as constants from 'app-state/constants';

export const getDashboardInfo = companyUuid => ({
  type: constants.GET_DASHBOARD_INFO,
  companyUuid,
});
export const getDashboardInfoSuccess = data => ({
  type: constants.GET_DASHBOARD_INFO_SUCCESS,
  data,
});
export const getDashboardInfoError = error => ({ type: constants.GET_DASHBOARD_INFO_ERROR, error });
export const resetDashboardInfo = () => ({ type: constants.RESET_DASHBOARD_INFO });
