import * as constants from 'app-state/constants/account';

export const getMyAccountDetails = () => ({ type: constants.GET_MY_ACCOUNT_DETAILS });
export const getMyAccountDetailsSuccess = data => ({
  type: constants.GET_MY_ACCOUNT_DETAILS_SUCCESS,
  data,
});
export const getMyAccountDetailsError = error => ({
  type: constants.GET_MY_ACCOUNT_DETAILS_ERROR,
  error,
});

export const getAccountUsers = companyUuid => ({ type: constants.GET_ACCOUNT_USERS, companyUuid });
export const getAccountUsersSuccess = data => ({ type: constants.GET_ACCOUNT_USERS_SUCCESS, data });
export const getAccountUsersError = error => ({ type: constants.GET_ACCOUNT_USERS_ERROR, error });

export const createAccountUser = (companyUuid, data, showUserAddedModal, form) => ({
  type: constants.CREATE_ACCOUNT_USER,
  companyUuid,
  data,
  showUserAddedModal,
  form,
});

export const updateAccountUser = (userUuid, data, form, onSuccess, showEmailModal) => ({
  type: constants.UPDATE_ACCOUNT_USER,
  userUuid,
  data,
  form,
  showEmailModal,
  onSuccess,
});

export const deleteAccountUser = (userUuid, companyUuid) => ({
  type: constants.DELETE_ACCOUNT_USER,
  companyUuid,
  userUuid,
});

export const deleteAccountUserError = error => ({
  type: constants.DELETE_ACCOUNT_USER_ERROR,
  error,
});

export const changePassword = (data, showPasswordUpdatedModal, form) => ({
  type: constants.CHANGE_PASSWORD,
  data,
  showPasswordUpdatedModal,
  form,
});

export const resendEmailConfirmation = (companyUuid, userUuid, isResendForMyself) => ({
  type: constants.RESEND_EMAIL_CONFIRMATION,
  companyUuid,
  userUuid,
  isResendForMyself,
});
export const resendEmailConfirmationError = error => ({
  type: constants.RESEND_EMAIL_CONFIRMATION_ERROR,
  error,
});
