import { formatDate, LDF } from '@frontend/datetime';

import UserEmailTooltip from 'shared/user-email-tooltip';
import { toInt } from 'shared-parts/helpers/format-number';

const INSTITUTIONAL_TYPE = 'institution';
const INDIVIDUAL_TYPE = 'individual';

const FIELD_WITNESS_FULL_NAME = 'witnessFullName';
const FIELD_WITNESS_EMAIL = 'witnessEmail';
const FIELD_SECOND_ISSUER_FULL_NAME = 'secondIssuerFullName';
const FIELD_SECOND_ISSUER_EMAIL = 'secondIssuerEmail';
const FIELD_FIRST_ISSUER_FULL_NAME = 'firstIssuerFullName';
const FIELD_FIRST_ISSUER_EMAIL = 'firstIssuerEmail';
const FIRST_ISSUER = 'firstIssuer';
const SECOND_ISSUER = 'secondIssuer';
const WITNESS = 'witness';
const FIRST_ISSUER_LABEL = 'Are you signing the document?*';
const SECOND_ISSUER_LABEL = 'Is a second authorised signature required?*';
const WITNESS_LABEL = 'Is a witness signature required?*';

const ERROR_MESSAGES = {
  FULL_NAME_REQUIRED: 'Full Name is required',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_NO_SPACES: 'Email cannot contain spaces',
  EMAIL_INVALID: 'Invalid email address',
};
const INVESTOR_TYPES = [
  { text: 'Individual', value: INDIVIDUAL_TYPE },
  { text: 'Institutional', value: INSTITUTIONAL_TYPE },
];

const TRANSACTIONAL_HISTORY_TABLE_HEADER = [
  { name: 'Date' },
  { name: 'Quantity' },
  { name: 'Transaction Type' },
  { name: 'Transaction ID' },
  { name: 'User' },
];

const TRANSACTIONAL_HISTORY_TABLE_KEYS = [
  {
    field: 'date',
    transform: date => formatDate(date, LDF.DATE_TEXTUAL),
    styles: () => 'text-align: right;',
  },
  {
    field: 'quantity',
    transform: toInt,
    styles: () => 'text-align: right;',
  },
  {
    field: 'transactionType',
    styles: () => 'text-transform: capitalize; text-align: left;',
  },
  {
    field: 'transactionId',
    styles: () => 'text-align: right;',
  },
  {
    field: 'user',
    transform: UserEmailTooltip,
  },
];

const AGREEMENT_DETAILS_TEXTS = {
  title: 'Share options agreement:',
  issued: 'Add a grant that has already been issued',
  issuedDesc:
    'Where a document has already been signed or there is no requirement to sign a document',
  eSign: 'Add a grant that will be issued once the agreement is signed (using our e-sign feature)',
  eSignDesc:
    'The new grant will not be issued until the Option Agreement has been signed by all parties',
};

const AGREEMENT_DETAILS_INITIAL_VALUES = {
  agreement: 'no',
};

const NEXT = 'NEXT';

const UPLOAD_AGREEMENT_TEXT = 'Upload Option Agreement for e-signing';
const RE_UPLOAD_AGREEMENT_TEXT = 'Upload revised Option Agreement for e-signing';

const SIGNATURE_REQUEST_STATUS = {
  AWAITING_SIGNATURE: 'awaiting_signature',
  SIGNED: 'signed',
  DECLINED: 'declined',
};

const DOCUMENT_STATUSES = {
  DRAFT: 'draft',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

const UPLOAD_AGREEMENT_INITIAL_VALUES = {
  firstIssuer: null,
  firstIssuerFullName: null,
  firstIssuerEmail: null,
  witness: null,
  witnessFullName: null,
  witnessEmail: null,
  secondIssuer: null,
  secondIssuerFullName: null,
  secondIssuerEmail: null,
  message: null,
};

const ADDITIONAL_SIGNATURE_RADIO = {
  LABEL: {
    YES: 'Yes',
    NO: 'No',
  },
  VALUE: {
    YES: 'yes',
    NO: 'no',
  },
};

const AGREEMENT_STATUS_TABLE_HEADER = [
  { name: 'File name' },
  { name: 'Date uploaded' },
  { name: 'Status' },
];

const DOCUMENT_TYPES = {
  REGULAR: 'regular',
  AGREEMENT: 'agreement',
};

const GRANT_OVERVIEW_SUCCESS_TITLE = 'Grant issued';
const SIGN_OVERVIEW_SUCCESS_TITLE = 'Draft grant created';

const AGREEMENT_DETAILS = 'AGREEMENT_DETAILS';
const GRANT_HOLDER_DETAILS = 'GRANT_HOLDER_DETAILS';
const GRANT_DETAILS = 'GRANT_DETAILS';
const ADD_FORM_STEPS = [AGREEMENT_DETAILS, GRANT_HOLDER_DETAILS, GRANT_DETAILS];

const VESTING_SCHEDULE_TYPE__TIME = 'time';
const VESTING_SCHEDULE_TYPE__EVENT = 'event';

const EVENT_VESTING_SCHEDULE_TEMPLATE_TYPE__PLAN = 'plan';
const EVENT_VESTING_SCHEDULE_TEMPLATE_TYPE__GRANT = 'grant';

const TIME_VESTING_SCHEDULE_TEMPLATE_TYPE__MONTH = 'month';
const TIME_VESTING_SCHEDULE_TEMPLATE_TYPE__YEAR = 'year';

const INDICATVIE_SHARE_HEADER = {
  tooltipText:
    'This is calculated using the current estimated share price. This is an indicative view only and does not guarantee the actual value.',
};

export {
  INSTITUTIONAL_TYPE,
  INDIVIDUAL_TYPE,
  INVESTOR_TYPES,
  TRANSACTIONAL_HISTORY_TABLE_HEADER,
  TRANSACTIONAL_HISTORY_TABLE_KEYS,
  AGREEMENT_DETAILS_TEXTS,
  AGREEMENT_DETAILS_INITIAL_VALUES,
  NEXT,
  UPLOAD_AGREEMENT_TEXT,
  RE_UPLOAD_AGREEMENT_TEXT,
  SIGNATURE_REQUEST_STATUS,
  DOCUMENT_STATUSES,
  UPLOAD_AGREEMENT_INITIAL_VALUES,
  ADDITIONAL_SIGNATURE_RADIO,
  AGREEMENT_STATUS_TABLE_HEADER,
  DOCUMENT_TYPES,
  GRANT_OVERVIEW_SUCCESS_TITLE,
  SIGN_OVERVIEW_SUCCESS_TITLE,
  AGREEMENT_DETAILS,
  GRANT_HOLDER_DETAILS,
  GRANT_DETAILS,
  ADD_FORM_STEPS,
  FIELD_FIRST_ISSUER_FULL_NAME,
  FIELD_FIRST_ISSUER_EMAIL,
  FIELD_WITNESS_FULL_NAME,
  FIELD_WITNESS_EMAIL,
  FIELD_SECOND_ISSUER_FULL_NAME,
  FIELD_SECOND_ISSUER_EMAIL,
  VESTING_SCHEDULE_TYPE__TIME,
  VESTING_SCHEDULE_TYPE__EVENT,
  EVENT_VESTING_SCHEDULE_TEMPLATE_TYPE__PLAN,
  EVENT_VESTING_SCHEDULE_TEMPLATE_TYPE__GRANT,
  TIME_VESTING_SCHEDULE_TEMPLATE_TYPE__MONTH,
  TIME_VESTING_SCHEDULE_TEMPLATE_TYPE__YEAR,
  FIRST_ISSUER,
  SECOND_ISSUER,
  WITNESS,
  ERROR_MESSAGES,
  FIRST_ISSUER_LABEL,
  SECOND_ISSUER_LABEL,
  WITNESS_LABEL,
  INDICATVIE_SHARE_HEADER,
};
