import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from 'app-state/actions';

import API from 'constants/api';
import { downloadBlob, request } from 'helpers';
import ModalFailed from 'shared-parts/components/modal-failed';

import * as constants from './share-plan-bonus.constants';

function* uploadBonusData(action: AnyAction) {
  try {
    yield call(request, API.ShareInvestmentPlan.Bonus.Upload(action.planId), 'POST', action.data, {
      mode: 'cors',
      snakeCaseParams: false,
      to: 'text',
    });
    yield put({
      type: constants.uploadBonus.SUCCESS,
    });
  } catch (e: any) {
    yield put({
      type: constants.uploadBonus.FAILURE,
      error: e?.response?.message || 'Something went wrong',
    });
  }
}
export function* uploadBonusDataWatcher() {
  yield takeLatest(constants.uploadBonus.REQUEST, uploadBonusData);
}

function* importBonusTemplate() {
  try {
    const { data } = yield call(
      request,
      API.ShareInvestmentPlan.Bonus.DownloadTemplate(),
      'GET',
      null,
      { mode: 'cors', to: 'blob' },
    );

    downloadBlob(data, `data.csv`);
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    );
  }
}

export function* importBonusTemplateWatcher() {
  yield takeLatest(constants.downloadBonusTemplate, importBonusTemplate);
}
