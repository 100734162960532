import styled from 'styled-components';

import EmailBrown from 'images/email-brown.svg';
import PersonBlue from 'images/person-blue.svg';
import PersonGreen from 'images/person-green.svg';
import FormikButton from 'shared-parts/components/button/formik-button';
import FormTabs from 'shared-parts/components/tabs/form-tabs';
import { COLORS } from 'shared-parts/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const StyledFormTabs = styled(FormTabs)`
  padding: 0 20px;
  position: sticky;
  top: 0;
  background-color: ${COLORS.neutral200};
  z-index: 3;
`;

const Part = styled.div`
  border-bottom: 1px solid ${COLORS.neutral300};
  padding: 30px 20px;
`;

const TextWithMargin = styled.div`
  margin-top: 10px;
`;

const PreviewEmailBlock = styled(TextWithMargin)`
  display: flex;
  justify-content: space-between;
`;

const PreviewEmailButton = styled.button`
  background: transparent;
  border: 0;
  color: ${COLORS.alternative};
  cursor: pointer;
  font-weight: bold;

  :hover {
    text-decoration: underline;
  }
`;

const Info = styled(Part)`
  border: 0;
`;

const InformationLine = styled.div`
  display: flex;
  height: 30px;
`;

const BluePersonIcon = styled.div`
  background: transparent url(${PersonBlue}) 0% 0% no-repeat padding-box;
  height: 20px;
  width: 20px;
`;

const GreenPersonIcon = styled.div`
  background: transparent url(${PersonGreen}) 0% 0% no-repeat padding-box;
  height: 20px;
  width: 20px;
`;

const BrownEmailIcon = styled.div`
  background: transparent url(${EmailBrown}) 0% 0% no-repeat padding-box;
  height: 16px;
  width: 20px;
`;

const Amount = styled.span`
  font-weight: bold;
  margin-left: 10px;
  margin-right: 5px;
`;

const Buttons = styled(Part)`
  border-bottom: 0;
  display: flex;
  justify-content: flex-end;
`;

const Cancel = styled.button`
  background: transparent;
  border: 0;
  color: ${COLORS.primary};
  cursor: pointer;
  font-weight: bold;
  margin-right: 20px;
  text-decoration: underline;
`;

const SendEmailButton = styled(FormikButton)`
  height: 40px;
  width: 120px;
`;

export {
  Wrapper,
  StyledFormTabs,
  Part,
  PreviewEmailBlock,
  PreviewEmailButton,
  TextWithMargin,
  Info,
  InformationLine,
  BluePersonIcon,
  GreenPersonIcon,
  BrownEmailIcon,
  Amount,
  Buttons,
  Cancel,
  SendEmailButton,
};
