export enum FeatureFlag {
  LIQUIDITY_ROUND = 'LIQUIDITY_ROUND_FEATURE',
  SECONDARY_MARKET_1_1 = 'SECONDARY_MARKET_1_1_FEATURE',
  SENDBIRD = 'SENDBIRD_ENABLED',
  SHOW_REGISTER_OVERVIEW = 'SHOW_REGISTER_OVERVIEW',
  SHOW_BONDS_AND_LOANS = 'SHOW_BONDS_AND_LOANS',
  ENABLE_EVENT_BASED_SCHEDULING = 'ENABLE_EVENT_BASED_SCHEDULING',
  ENABLE_SHAREHOLDER_INCENTIVE_PLAN = 'ENABLE_SHAREHOLDER_INCENTIVE_PLAN',
  ENABLE_FILTER_BY_SHAREHOLDER_ID = 'ENABLE_FILTER_BY_SHAREHOLDER_ID',
  ENABLED_LIQUIDITY_SIGNATURE_DOCUMENTS = 'ENABLED_LIQUIDITY_SIGNATURE_DOCUMENTS',
  ENABLE_INVESTOR_RESTRICTION_FEATURE = 'ENABLE_INVESTOR_RESTRICTION_FEATURE',
  ALLOW_MULTIPLE_BANK_ACCOUNTS = 'ALLOW_MULTIPLE_BANK_ACCOUNTS',
  NEW_INVESTOR_INVITE_FLOW = 'NEW_INVESTOR_INVITE_FLOW',
  PLACEMENT_DISABLED = 'PLACEMENT_DISABLED',
  ENABLE_SPV = 'ENABLE_SPV',
  CUSTOM_KYC_KYB = 'CUSTOM_KYC_KYB',
  NEW_PLACEMENT_CREATION_FLOW = 'NEW_PLACEMENT_CREATION_FLOW',
  USE_MUIMOUS = 'USE_MUIMOUS',
  USE_GLOBACAP_CLIENT_MONEY_SERVICES = 'USE_GLOBACAP_CLIENT_MONEY_SERVICES',
  ENABLE_NDA_ESIGNATURE = 'ENABLE_NDA_ESIGNATURE',
  ENABLE_SHARE_OPTIONS_ESIGNATURE = 'ENABLE_SHARE_OPTIONS_ESIGNATURE',
  OAUTH2_LOGIN_ENABLE = 'OAUTH2_LOGIN_ENABLE',
  ENABLE_INVESTMENT_FUND = 'ENABLE_INVESTMENT_FUND',
}

export enum EnvironmentType {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  PREPROD = 'preproduction',
  PROD = 'production',
}
export interface AppConfig {
  featureFlags: FeatureFlag[];
  sendbirdId: string;
  environment: EnvironmentType;
}
