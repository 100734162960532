import useIntersect from 'shared-parts/helpers/useIntersect';

import { IntersectionDiv, IntersectTableRow } from '../table.styled';

const config = {
  tr: IntersectTableRow,
  div: IntersectionDiv,
};

const IntersectBox = ({
  onInfiniteScroll,
  threshold = 0.01,
  rootMargin = '100px 0px 0px 0px',
  element = 'tr',
}) => {
  const [ref, entry] = useIntersect({ threshold, rootMargin });

  if (entry && entry.intersectionRatio > 0.01) onInfiniteScroll();

  const Component = config[element];

  return <Component ref={ref} />;
};

export default IntersectBox;
