import {
  INDICATIVE_SHARE_VALUE_VIEW_DISABLED_MESSAGE,
  INDICATIVE_SHARE_VALUE_VIEW_DISABLED_TEXT,
  INDICATIVE_SHARE_VALUE_VIEW_ENABLED_MESSAGE,
  INDICATIVE_SHARE_VALUE_VIEW_ENABLED_TEXT,
} from 'modules/company/equity-administration/shareholder-registry/components/view-permission/constants';

import FinishIcon from '../../../../../../shared-parts/components/svg-icons/finish-icon';

import {
  ButtonsWrapper,
  CautionIconWrapper,
  ConfirmButton,
  ModalPart,
  ModalSection,
  ModalText,
  ModalTitle,
  ModalWrapper,
} from './indicative-share-value-view-modal.styled';

type IndicativeShareValueViewProps = {
  hideModal: () => void;
  indicativeShareValueEnabled: boolean;
};

const IndicativeShareValueViewModal = ({
  hideModal,
  indicativeShareValueEnabled = true,
}: IndicativeShareValueViewProps) => {
  const handleShareValueViewClose = () => {
    hideModal();
    location.reload();
  };

  return (
    <ModalWrapper>
      <CautionIconWrapper>{FinishIcon(60, 60)}</CautionIconWrapper>
      <ModalTitle>
        {indicativeShareValueEnabled
          ? INDICATIVE_SHARE_VALUE_VIEW_ENABLED_TEXT
          : INDICATIVE_SHARE_VALUE_VIEW_DISABLED_TEXT}
      </ModalTitle>
      <ModalText>
        <ModalSection>
          <ModalPart>
            {indicativeShareValueEnabled
              ? INDICATIVE_SHARE_VALUE_VIEW_ENABLED_MESSAGE
              : INDICATIVE_SHARE_VALUE_VIEW_DISABLED_MESSAGE}
          </ModalPart>
        </ModalSection>
      </ModalText>
      <ButtonsWrapper>
        <ConfirmButton onClick={handleShareValueViewClose}>OK</ConfirmButton>
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default IndicativeShareValueViewModal;
