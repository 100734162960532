import { FC, ReactNode } from 'react';

import { Checkbox } from '../../checkbox';

interface CheckboxItemProps {
  label: ReactNode;
  selected: boolean;
}

const CheckboxItem: FC<CheckboxItemProps> = ({ label, selected }) => (
  <>
    <Checkbox sx={{ padding: 0 }} checked={selected} />
    {label}
  </>
);

export default CheckboxItem;
