import { gql } from '@apollo/client';

const shareholdersQuery = gql`
  query GetShareholders(
    $offset: Int
    $limit: Int
    $filters: SecurityHolderFilter
    $having: SecurityHolderHaving
    $orderBy: [SecurityHolderOrder!]
  ) {
    securityHolders(
      offset: $offset
      limit: $limit
      filters: $filters
      having: $having
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        address
        email
        givenNames
        id
        investorId
        issuerId
        name
        nationality
        securityHolderExternalId
        surname
        tags {
          uuid
          name
          color
        }
        linkedShareholders
        totalEquityPercent
        totalEquityQuantity
        type
      }
      pageInfo {
        offset
      }
    }
  }
`;

export default shareholdersQuery;
