import 'intersection-observer';

import { ConnectedRouter } from 'connected-react-router';
import { lazy, Suspense, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import LoginRoute from 'router/login-route';
import Onboarding from 'router/onboarding';
import SignUpRoute from 'router/sign-up-route';

import { LoggerProvider, ProductAnalyticsProvider } from '@frontend/analytics';
import {
  clearOpenFeatureUser,
  PlatformSettingsProvider,
  setOpenFeatureUser,
  useEnvVar,
} from '@frontend/config';
import dayjs from '@frontend/datetime';
import { AdapterDayjs, LocalizationProvider } from '@frontend/design-system';
import { QueryDevTools, RequestClientProvider } from '@frontend/http';
import { isEmptyObject } from '@frontend/utils';

import { getPermissions, getPermissionsSuccess } from 'app-state/actions/permissions';
import { updateNickelledUser } from 'app-state/actions/shared';
import {
  getAuthenticationStatus,
  getUser as getUserSelector,
} from 'app-state/selectors/authentication';
import { getPermissions as getPermissionsSelector } from 'app-state/selectors/permissions';
import store, { history } from 'app-state/store';

import ConfirmEmail from 'modules/confirm-email';
import CreatePassword from 'modules/create-password';
import ForbiddenPage from 'modules/forbidden';
import ForgotPassword from 'modules/forgot-password/forgot-password';
import Routes from 'constants/routes';
import { AppConfigProvider, FeatureFlag, useFeatureFlag } from 'providers/app-config';
import { DeviceProvider } from 'providers/device';
import AuthenticatedGqlProvider, { useGraphQLClient } from 'providers/graphql';
import { I18nextLoader } from 'hooks/use-i18next';
import useTypedSelector from 'hooks/use-typed-selector';
import Redirection from 'helpers/redirection';
import Role from 'helpers/role';
import { Toast as CsvToast } from 'shared/csv-download/shared';
import Overlay from 'shared/overlay/overlay';
import { ConnectedSideForm } from 'shared/side-form';
import ErrorBoundary from 'shared-parts/components/error-boundary';
import Loader from 'shared-parts/components/loader/loader';
import Modal from 'shared-parts/components/modal';
import Toaster from 'shared-parts/components/toaster';
import { request } from 'shared-parts/helpers/request';
import { getURLParameters } from 'shared-parts/helpers/url-parameters';

import { oauth2Login } from './app-state/actions';
import UserAlerts from './user-alerts';

const GlobacapWrapper = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "GlobacapWrapper" */ 'shared/globacap-wrapper'
    ),
);
const AdminWrapper = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "AdminWrapper" */ 'shared/admin-wrapper'
    ),
);
const MultiCompanyWrapper = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "MultiCompanyWrapper" */ 'shared/multi-company-admin-wrapper/multi-company-admin-wrapper'
    ),
);
const ResetPassword = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "ResetPassword" */ 'modules/reset-password/reset-password'
    ),
);
const NotFound = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "NotFound" */ 'shared-parts/modules/not-found'
    ),
);

type Location = {
  pathname: string;
  search: string;
  state: {
    from?: {
      path: string;
      search: string;
      userUuid: string | null;
    };
  };
};

const App = () => {
  const dispatch = useDispatch();

  const enableOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

  const permissions = useTypedSelector(getPermissionsSelector);
  const user = useTypedSelector(getUserSelector);
  const authenticated = useTypedSelector(getAuthenticationStatus);
  const { pathname: browserPath, search: browserSearchString, state }: Location = useLocation();
  const isReadyToRedirect = JSON.stringify(user) === '{}' || !permissions.loading;
  const redirectionParams = {
    browserPath,
    redirectUrl: state && state.from,
    user,
  };

  const { to } = (() => {
    // If oauth2 is enabled, and the user is not authenticated, don't do a thing
    // The oauth proxy will handle this scenario
    if (!authenticated && enableOauth2Login) {
      return { to: null };
    }

    if (isReadyToRedirect) {
      return new Redirection(redirectionParams);
    }

    return { to: null };
  })();

  useEffect(() => {
    if (enableOauth2Login) {
      dispatch(oauth2Login());
    }
  }, [enableOauth2Login]);

  useEffect(() => {
    const [actionToken] = getURLParameters(['actionToken']);

    localStorage.setItem('actionToken', actionToken);
  }, []);

  useEffect(() => {
    if (authenticated) {
      Role.can('Get permissions').then((shouldGetPermissions: boolean) => {
        if (shouldGetPermissions) {
          dispatch(getPermissions());
        } else {
          dispatch(getPermissionsSuccess({}));
        }
      });

      dispatch(updateNickelledUser());
    }
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated || !user || isEmptyObject(user)) {
      clearOpenFeatureUser();
    } else {
      setOpenFeatureUser(user);
    }
  }, [user, authenticated]);

  const gqlClient = useGraphQLClient();
  const csvExportPollInterval = Number(useEnvVar('DOWNLOAD_CSV_POLL_INTERVAL')) || 3000;

  // TODO: Maybe pass a component into our hook to handle this?
  const [exporting, setExporting] = useState(false);

  // This only works as we can guarantee no unauthed pages on the application
  // so we can assume that if theyre unauthenticated, proxy will be handling something
  // Without this, there will be nowhere to redirect to until the user is loaded,
  // resulting in a 404 page flash
  if (!authenticated && enableOauth2Login) {
    return <Loader alwaysVisible />;
  }

  if (to) {
    return (
      <Redirect
        to={{
          pathname: to.path,
          search: to.search || '',
          state: {
            from: {
              path: browserPath,
              search: browserSearchString,
              userUuid: localStorage.getItem('userUuid'),
            },
          },
        }}
      />
    );
  }

  return (
    <LoggerProvider appName="management-console" user={user} getId={u => u.uuid}>
      <ProductAnalyticsProvider user={user}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={dayjs.locale().toLowerCase()}
          dateLibInstance={dayjs}
        >
          <ErrorBoundary>
            <Suspense fallback={<Loader alwaysVisible />}>
              <RequestClientProvider
                graphql={gqlClient}
                request={request}
                defaultOptions={{
                  export: {
                    url: window.config.GRAPHXL_ENDPOINT as string,
                    interval: csvExportPollInterval,
                    onExporting: () => setExporting(true),
                    onComplete: () => setExporting(false),
                    onError: () => setExporting(false),
                  },
                }}
              >
                <PlatformSettingsProvider loader={<Loader alwaysVisible />}>
                  <I18nextLoader loader={<Loader alwaysVisible />}>
                    <UserAlerts />
                    <QueryDevTools />
                    <Loader />
                    <Modal />
                    <ConnectedSideForm />
                    <Overlay />
                    <Toaster />
                    {exporting && <CsvToast />}
                    {authenticated && permissions.loading ? (
                      <Loader alwaysVisible />
                    ) : (
                      <Switch>
                        <Route exact path={Routes.Login()} component={LoginRoute} />
                        <Route exact path={Routes.SignUp()} component={SignUpRoute} />
                        <Route path={Routes.Forbidden()} component={ForbiddenPage} />
                        <Route path={Routes.CreatePassword()} component={CreatePassword} />
                        <Route path={Routes.ConfirmEmail()} component={ConfirmEmail} />
                        <Route path={Routes.Onboarding()} component={Onboarding} />
                        <Route path={Routes.ForgotPassword()} component={ForgotPassword} />
                        <Route path={Routes.ResetPassword()} component={ResetPassword} />
                        <Route path="/signout" render={() => <Loader alwaysVisible />} />
                        <Route path={Routes.Globacap.Root()} component={GlobacapWrapper} />
                        <Route path={Routes.Admin.Root()} component={AdminWrapper} />
                        <Route path={Routes.MultiCompany.Root()} component={MultiCompanyWrapper} />
                        <Route component={NotFound} />
                      </Switch>
                    )}
                  </I18nextLoader>
                </PlatformSettingsProvider>
              </RequestClientProvider>
            </Suspense>
          </ErrorBoundary>
        </LocalizationProvider>
      </ProductAnalyticsProvider>
    </LoggerProvider>
  );
};

const HotApp = hot(App);

export default (): JSX.Element => (
  <AppConfigProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <DeviceProvider>
          <AuthenticatedGqlProvider>
            <HotApp />
          </AuthenticatedGqlProvider>
        </DeviceProvider>
      </ConnectedRouter>
    </Provider>
  </AppConfigProvider>
);
