import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './csv-export.constants';
import initialState, { DownloadCSVStateType, JobStatus } from './csv-export.state';

export const downloadExportCommandCSVReducer = (
  state = initialState,
  action: AnyAction,
): DownloadCSVStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.sendDownloadExportCommandRequest.REQUEST:
        draft.job = {
          ...initialState.job,
          loading: true,
        };
        break;
      case constants.sendDownloadExportCommandRequest.SUCCESS:
        draft.job.data = action.data;
        break;
      case constants.checkExportCommandJobProgress.FAILURE:
      case constants.sendDownloadExportCommandRequest.FAILURE:
        draft.job.data = state.job.data || {};
        draft.job.data!.status = JobStatus.Failed;
        draft.job.loading = false;
        draft.job.error = action.error;
        break;
      case constants.checkExportCommandJobProgress.SUCCESS:
        draft.job.data = action.data;
        draft.job.loading = false;
        break;
      default:
        break;
    }
  });
