import ClickOutside from 'react-click-outside';

import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { white, neutral300, primary, shadowColor } = COLORS;

const StyledClickOutside = styled(ClickOutside)<{
  children: JSX.Element;
  onClickOutside: () => void;
}>`
  display: flex;
  align-items: baseline;
`;

const DefaultContainer = styled.div.attrs({ role: 'listbox' })`
  position: absolute;
  top: 34px;
  width: 200px;
  box-shadow: 0px 3px 6px ${shadowColor};
  border: 1px solid ${neutral300};
  border-radius: 6px;
  z-index: 1;
  background: ${white} 0% 0% no-repeat padding-box;
`;

const DefaultItem = styled.div.attrs({ role: 'option' })`
  height: 38px;
  line-height: 38px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  border-bottom: 1px solid ${neutral300};
  padding: 0 16px;
  cursor: pointer;
  color: ${primary};
  font-family: ${FONTS.mainFont};

  :last-child {
    border-bottom-width: 0;
  }

  :hover {
    text-decoration: underline;
  }.
`;

export { DefaultItem, DefaultContainer, StyledClickOutside };
