import { call, put, takeEvery } from 'redux-saga/effects';

import { setGenerateOnboardingLinkError } from 'app-state/actions/onboarding-link';
import { hideSideForm, showModal } from 'app-state/actions/shared';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ModalFailed from 'shared-parts/components/modal-failed';
import { request } from 'shared-parts/helpers';

function* generateOnboardingLink({ data }) {
  try {
    yield call(request, API.GenerateOnboardingLink(), 'POST', data);
    yield put(hideSideForm());
    yield put(
      showModal({
        closable: true,
        component: SuccessModal,
        title: 'Link Sent',
        additionalText: (
          <span>
            An on-boarding link has been sent to <b>{data.email}</b>
          </span>
        ),
        buttonText: 'OK',
        modalWidth: 580,
        messageMaxWidth: '350px',
      }),
    );
  } catch ({ response = {}, status }) {
    if (status === 400) {
      yield put(setGenerateOnboardingLinkError(response.details));
    } else {
      yield put(
        showModal({
          component: ModalFailed,
          closable: true,
          showHeader: false,
        }),
      );
    }
  }
}

function* generateOnboardingLinkWatcher() {
  yield takeEvery(constants.GENERATE_ONBOARDING_LINK, generateOnboardingLink);
}

export { generateOnboardingLink, generateOnboardingLinkWatcher };
