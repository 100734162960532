import { COLORS } from 'shared-parts/constants';

const { neutral500 } = COLORS;

export const NewSearchIcon = ({ fill = neutral500 }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.33335" cy="7.33317" r="4.66667" stroke={fill} strokeWidth="1.5" />
    <path
      d="M13.3333 13.3335L11.3333 11.3335"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
