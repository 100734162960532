import {
  FETCH_INVESTOR_AGENTS_PERMISSIONS,
  FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR,
  FETCH_INVESTOR_AGENTS_PERMISSIONS_SUCCESS,
  FETCH_INVESTOR_DEALS,
  FETCH_INVESTOR_DEALS_ERROR,
  FETCH_INVESTOR_DEALS_SUCCESS,
  FETCH_INVESTOR_INVESTMENTS,
  FETCH_INVESTOR_INVESTMENTS_ERROR,
  FETCH_INVESTOR_INVESTMENTS_SUCCESS,
  FETCH_INVESTOR_PREFERENCES,
  FETCH_INVESTOR_PREFERENCES_ERROR,
  FETCH_INVESTOR_PREFERENCES_SUCCESS,
  FETCH_INVESTORS,
  FETCH_INVESTORS_ERROR,
  FETCH_INVESTORS_SUCCESS,
  FETCH_WALLET_REFERENCE,
  FETCH_WALLET_REFERENCE_ERROR,
  FETCH_WALLET_REFERENCE_SUCCESS,
  FETCH_WALLET_TRANSACTIONS,
  FETCH_WALLET_TRANSACTIONS_ERROR,
  FETCH_WALLET_TRANSACTIONS_SUCCESS,
  RESET_INVESTOR_AGENTS_PERMISSIONS,
  RESET_INVESTOR_DEALS,
  RESET_INVESTOR_INVESTMENTS,
  RESET_INVESTOR_PREFERENCES,
  RESET_INVESTORS,
  RESET_WALLET_TRANSACTIONS,
} from './constants';

export const initialInvestorsState = {
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 100,
        totalPages: 0,
        totalObjects: 0,
      },
    },
  },
  errors: null,
};

export const investorsReducer = (state = initialInvestorsState, { type, payload }) => {
  switch (type) {
    case FETCH_INVESTORS: {
      const { page, perPage, totalPages, totalObjects } = payload;
      return {
        ...initialInvestorsState,
        data: {
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
          },
        },
      };
    }
    case FETCH_INVESTORS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case FETCH_INVESTORS_ERROR:
      return { ...state, loading: false, errors: payload };
    case RESET_INVESTORS:
      return initialInvestorsState;
    default:
      return state;
  }
};

const initialWalletReferenceState = {
  loading: false,
  data: {},
  error: null,
};

export const walletReferenceReducer = (state = initialWalletReferenceState, { type, payload }) => {
  switch (type) {
    case FETCH_WALLET_REFERENCE:
      return { loading: true, data: {}, error: null };
    case FETCH_WALLET_REFERENCE_SUCCESS:
      return { loading: false, data: payload, error: null };
    case FETCH_WALLET_REFERENCE_ERROR:
      return { loading: false, data: {}, error: payload };
    default:
      return state;
  }
};

const initialWalletTransactionsState = {
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
        totalObjects: 0,
      },
    },
  },
  error: null,
};

export const walletTransactionsReducer = (
  state = initialWalletTransactionsState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_WALLET_TRANSACTIONS: {
      const { page, perPage, totalPages, totalObjects } = {
        ...state.data.meta.pagination,
        ...payload,
      };
      return {
        loading: true,
        data: {
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
          },
        },
        error: null,
      };
    }
    case FETCH_WALLET_TRANSACTIONS_SUCCESS:
      return { loading: false, data: payload, error: null };
    case FETCH_WALLET_TRANSACTIONS_ERROR:
      return { loading: false, data: initialWalletTransactionsState.data, error: payload };
    case RESET_WALLET_TRANSACTIONS:
      return initialWalletTransactionsState;
    default:
      return state;
  }
};

export const initialInvestorInvestmentsState = {
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
        totalObjects: 0,
      },
    },
  },
  errors: null,
};

export const investorInvestmentsReducer = (
  state = initialInvestorInvestmentsState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_INVESTOR_INVESTMENTS: {
      const { page, perPage, totalPages, totalObjects } = payload;
      return {
        ...initialInvestorInvestmentsState,
        data: {
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
          },
        },
      };
    }
    case FETCH_INVESTOR_INVESTMENTS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case FETCH_INVESTOR_INVESTMENTS_ERROR:
      return { ...state, loading: false, errors: payload };
    case RESET_INVESTOR_INVESTMENTS:
      return initialInvestorInvestmentsState;
    default:
      return state;
  }
};

export const initialInvestorPreferencesState = {
  loading: true,
  data: {},
  errors: null,
};

export const investorPreferencesReducer = (
  state = initialInvestorPreferencesState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_INVESTOR_PREFERENCES:
      return initialInvestorPreferencesState;
    case FETCH_INVESTOR_PREFERENCES_SUCCESS:
      return { ...state, loading: false, data: payload };
    case FETCH_INVESTOR_PREFERENCES_ERROR:
      return { ...state, loading: false, errors: payload };
    case RESET_INVESTOR_PREFERENCES:
      return initialInvestorPreferencesState;
    default:
      return state;
  }
};

const initialInvestorDealsState = {
  loading: true,
  data: [],
  error: null,
};

export const investorDealsReducer = (state = initialInvestorDealsState, { type, payload }) => {
  switch (type) {
    case FETCH_INVESTOR_DEALS:
      return initialInvestorDealsState;
    case FETCH_INVESTOR_DEALS_SUCCESS:
      return { loading: false, data: payload, error: null };
    case FETCH_INVESTOR_DEALS_ERROR:
      return { loading: false, data: [], error: payload };
    case RESET_INVESTOR_DEALS:
      return initialInvestorDealsState;
    default:
      return state;
  }
};

const initialInvestorAgentsPermissionsState = {
  loading: true,
  data: [],
  error: null,
};

export const investorAgentsPermissionsReducer = (
  state = initialInvestorAgentsPermissionsState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_INVESTOR_AGENTS_PERMISSIONS:
      return initialInvestorAgentsPermissionsState;
    case FETCH_INVESTOR_AGENTS_PERMISSIONS_SUCCESS:
      return { loading: false, data: payload, error: null };
    case FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR:
      return { loading: false, data: [], error: payload };
    case RESET_INVESTOR_AGENTS_PERMISSIONS:
      return initialInvestorAgentsPermissionsState;
    default:
      return state;
  }
};
