import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const dashboardInitialState = getReducerState({
  data: {
    totalSharesCount: '',
    lastTransactionType: '-',
    shareOptionsGranted: '',
    sharesStatistic: [],
    locationsStatistics: {
      totalShares: [],
      totalInvestors: [],
    },
    investorTypesStatistics: [],
  },
});

export const dashboardReducer = (state = dashboardInitialState, { type, data, error }) => {
  switch (type) {
    case constants.GET_DASHBOARD_INFO:
      return dashboardInitialState;
    case constants.GET_DASHBOARD_INFO_SUCCESS:
      return { loading: false, data, error: null };
    case constants.GET_DASHBOARD_INFO_ERROR:
      return { loading: false, data: dashboardInitialState.data, error };
    case constants.RESET_DASHBOARD_INFO:
      return { loading: false, data: dashboardInitialState.data, error: null };
    default:
      return state;
  }
};
