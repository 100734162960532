import PropTypes from 'prop-types';

import { Line, Slider, SubLine } from './progress.styled';

const Progress = ({ className }) => (
  <Slider data-e2e="progress" role="progressbar" className={className}>
    <Line />
    <SubLine reverse />
    <SubLine />
  </Slider>
);

Progress.defaultProps = {
  className: '',
};

Progress.propTypes = {
  className: PropTypes.string,
};

export default Progress;
