import { COLORS } from 'shared-parts/constants';

const { neutral800, systemPurple, systemGreen, systemRed, systemOrange, systemCyan } = COLORS;

export const getOnboardingStatusData = status => {
  switch (status) {
    case 'in_progress':
      return { text: 'In Progress', color: systemPurple };
    case 'user_responding':
      return { text: 'User responding', color: systemPurple };
    case 'completed':
      return { text: 'Verified', color: systemGreen };
    case 'postponed':
      return { text: 'Postponed', color: systemOrange };
    case 'failed':
      return { text: 'Failed', color: systemRed };
    case 'failed_with_retry':
      return { text: 'In Review', color: systemPurple };
    case 'pending':
    case null:
      return { text: 'Not Started', color: systemCyan };
    default:
      return { text: 'Unknown', color: neutral800 };
  }
};

export const getInvestorType = type => {
  switch (type) {
    case 'institutional_investor':
      return 'Institutional';
    case 'individual_investor':
      return 'Individual';
    default:
      return 'Unknown';
  }
};
