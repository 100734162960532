export type BrokersNoteStateType = {
  createBrokersNote: {
    loading: boolean;
    error: string | undefined;
    created: boolean;
  };
};

const initialState: BrokersNoteStateType = {
  createBrokersNote: {
    loading: false,
    error: undefined,
    created: false,
  },
};

export default initialState;
