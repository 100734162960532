import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import BackArrow from 'shared-parts/images/back-arrow.svg';
import BackArrowWhite from 'shared-parts/images/back-arrow-white.svg';
import NextArrow from 'shared-parts/images/next-arrow.svg';
import PlusIcon from 'shared-parts/images/plus-icon.svg';

const {
  accent,
  primary,
  neutral800,
  alternative,
  shadowColor,
  white,
  neutral500,
  accentContrastText,
} = COLORS;

const Wrapper = styled.div`
  margin: 12px 0;
  text-align: center;
  user-select: none;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: ${accent};
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    color: ${accentContrastText};
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    outline: 0;
    padding: 12px;
    min-width: 200px;

    &:hover,
    &:focus {
      background-color: ${primary};
    }

    &:disabled {
      background: ${neutral800};
      cursor: auto;
    }

    &.form-button {
      min-width: 118px;
      background: ${alternative};

      &:hover {
        box-shadow: 0 3px 6px ${shadowColor};
      }

      &:disabled {
        background-color: ${neutral800};
      }

      &.back-button {
        background: ${white};
        border: 1px solid ${alternative};
        color: ${alternative};

        &:before {
          background: url(${BackArrow}) no-repeat;
          content: '';
          display: inline-block;
          height: 12px;
          margin-right: 6px;
          vertical-align: top;
          width: 7.5px;
        }

        &:hover {
          background: ${alternative};
          color: $white;

          &:before {
            background: url(${BackArrowWhite}) no-repeat;
            content: '';
            display: inline-block;
            height: 12px;
            margin-right: 6px;
            vertical-align: top;
            width: 7.5px;
          }
        }
      }

      &.next-button {
        &:after {
          background: url(${NextArrow}) no-repeat;
          content: '';
          display: inline-block;
          height: 12px;
          margin-left: 6px;
          vertical-align: top;
          width: 7.5px;
        }
      }
    }

    &.invest-button {
      background: ${white};
      border: 1px solid ${accent};
      border-radius: 6px;
      color: ${accent};
      min-width: 118px;

      &:hover {
        background: ${neutral500};
      }

      &:active {
        background: ${neutral500};
      }
    }

    &.active-button {
      min-width: 118px;

      &:hover {
        background: ${alternative};
      }

      &:focus {
        background: ${alternative};
      }
    }

    &.important-button {
      font-family: ${FONTS.oswaldFont};
      font-weight: 400;
      letter-spacing: 0.8px;
      text-transform: uppercase;

      &:not([disabled]):hover {
        box-shadow: 0 3px 6px ${shadowColor};
      }

      &.thin {
        min-width: 118px;
      }
    }

    &.plus-button {
      background: transparent;
      border: 0;
      color: ${primary};
      min-width: auto;
      padding: 0;

      &:before {
        background: url(${PlusIcon}) no-repeat;
        content: '';
        display: inline-block;
        height: 28px;
        margin-right: 11px;
        vertical-align: middle;
        width: 28px;
      }
    }
  }
`;

export {
  Wrapper, // eslint-disable-line
};
