import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Message, Spinner, SpinnerContainer } from 'shared-parts/components/loader/loader.styled';
import { COLORS, FONTS } from 'shared-parts/constants';

const AddButtonWrapper = styled.div`
  ${({ disabled }) => !disabled && 'cursor: pointer'};
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  user-select: none;
  position: relative;

  ${SpinnerContainer} {
    width: 21px;
    ${Message} {
      height: 20px;
      width: 20px;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
    }
    ${Spinner} {
      border: 4px solid ${COLORS.neutral500};
      border-top: 4px solid ${COLORS.secondary};
      height: 20px;
      width: 20px;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
    }
  }
`;

const AddButton = styled.div`
  background-color: transparent;
  border-radius: 50%;
  ${({ isDisabled, secondary }) =>
    isDisabled
      ? `
    border: 2px solid ${COLORS.neutral500};
    color: ${COLORS.neutral500};
  `
      : `
    border: 2px solid ${secondary ? COLORS.secondary : COLORS.systemGreen};
    color: ${secondary ? COLORS.secondary : COLORS.systemGreen};
  `};
  font-family: ${FONTS.mainFont};
  font-weight: bold;
  font-size: 20px;
  line-height: 17px;
  width: 22px;
  height: 22px;
  text-align: center;
  margin-right: 5px;
`;

const AddButtonText = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? COLORS.neutral500 : COLORS.primary)};
  font-family: ${FONTS.mainFont};
  font-weight: bold;
  font-size: 14px;
`;

const AddLinkWrapper = styled(Link)`
  align-items: center;
  color: ${COLORS.primary};
  cursor: pointer;
  display: flex;
  font-family: ${FONTS.mainFont};
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  width: max-content;

  &:hover {
    color: ${COLORS.primary};
    text-decoration: none;
  }
`;

export { AddButtonWrapper, AddButton, AddButtonText, AddLinkWrapper };
