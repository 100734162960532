import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useEnvVar } from '@frontend/config';

import { hideModal, showModal } from 'app-state/actions/shared';
import {
  addWalletTransfer,
  fetchWalletReference,
  fetchWalletTransactions,
  resetWalletTransactions,
} from 'app-state/investors/actions';
import {
  getInvestorWalletReference,
  getInvestorWalletReferenceLoadingFlag,
  getWalletTransactionsError,
  getWalletTransactionsItems,
  getWalletTransactionsLoadingFlag,
  getWalletTransactionsPagination,
} from 'app-state/investors/selectors';

import useTypedSelector from 'hooks/use-typed-selector';
import ConfirmationModal from 'shared/confirmation-modal';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';
import { addCurrencySign, toDefinedFractionSizeNoRound } from 'shared-parts/helpers/format-number';

import AddTransactionForm from './add-transaction-form';
import {
  ADD_TRANSACTION_BTN,
  CONFIRM_MODAL_TITLE,
  WALLET_TRANSACTIONS_HEADER,
  WALLET_TRANSACTIONS_KEYS,
  WALLET_TRANSACTIONS_TABLE_TITLE,
} from './constants';
import { AddTransaction, FormContainer, StyledPagination, StyledTable, TableTitle } from './styled';

const transactionInitialValues = {
  amount: '',
  currency: useEnvVar('DEFAULT_CURRENCY'),
  creditDebit: 'credit',
  details: '',
  reference: '-',
};

const getConfirmModalMsg = ({ amount, currency }) => (
  <span>
    Are you sure you want to add a new transaction of&nbsp;
    {addCurrencySign(toDefinedFractionSizeNoRound(amount, 2), currency)}?<br />
    Once the transaction is added it cannot be edited or deleted.
  </span>
);

const Wallet = ({ investorUuid }) => {
  const dispatch = useDispatch();
  const [isFormVisible, setFormVisibility] = useState(false);
  const areTransactionsLoading = useTypedSelector(getWalletTransactionsLoadingFlag);
  const transactionsError = useTypedSelector(getWalletTransactionsError);
  const transactions = useTypedSelector(getWalletTransactionsItems);
  const pagination = useTypedSelector(getWalletTransactionsPagination);
  const isReferenceLoading = useTypedSelector(getInvestorWalletReferenceLoadingFlag);
  const walletReference = useTypedSelector(getInvestorWalletReference);

  const updateWalletReference = useCallback(
    currency => {
      dispatch(fetchWalletReference({ uuid: investorUuid, currency }));
    },
    [investorUuid],
  );

  useEffect(() => {
    if (investorUuid) {
      dispatch(fetchWalletTransactions({ uuid: investorUuid }));
      updateWalletReference(transactionInitialValues.currency);
    }
    return () => {
      dispatch(resetWalletTransactions());
    };
  }, [investorUuid]);

  const handlePageChange = useCallback(
    page => {
      dispatch(fetchWalletTransactions({ uuid: investorUuid, page }));
    },
    [investorUuid],
  );

  const handlePaginationOptionChange = useCallback(
    perPage => {
      dispatch(
        fetchWalletTransactions({
          uuid: investorUuid,
          page: 1,
          perPage,
          totalPages: 0,
        }),
      );
    },
    [investorUuid],
  );

  const showForm = useCallback(() => setFormVisibility(true), [setFormVisibility]);

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = useCallback(
    ({ reference, ...data }, { setSubmitting }) => {
      dispatch(
        showModal({
          closable: false,
          title: CONFIRM_MODAL_TITLE,
          text: getConfirmModalMsg(data),
          component: ConfirmationModal,
          hideModal: () => {
            setSubmitting(false);
            dispatch(hideModal());
          },
          handleConfirmation: () => {
            dispatch(addWalletTransfer({ uuid: investorUuid, ...data }));
            updateWalletReference(transactionInitialValues.currency);
            setFormVisibility(false);
            dispatch(hideModal());
          },
        }),
      );
    },
    [investorUuid, setFormVisibility],
  );

  return (
    <>
      <TableTitle>{WALLET_TRANSACTIONS_TABLE_TITLE}</TableTitle>
      <StyledTable
        loading={areTransactionsLoading}
        header={WALLET_TRANSACTIONS_HEADER}
        keys={WALLET_TRANSACTIONS_KEYS}
        error={transactionsError && defaultErrorMessage}
        data={transactions}
      />
      <StyledPagination
        disabled={areTransactionsLoading}
        currentPage={+pagination.page}
        currentOption={+pagination.perPage}
        totalPages={+pagination.totalPages}
        handlePageChange={handlePageChange}
        handlePaginationOptionChange={handlePaginationOptionChange}
      />
      <FormContainer>
        {isFormVisible ? (
          <AddTransactionForm
            initialValues={transactionInitialValues}
            loading={isReferenceLoading}
            reference={walletReference}
            onCurrencyChange={updateWalletReference}
            onSubmit={handleSubmit}
          />
        ) : (
          <AddTransaction type="button" onClick={showForm} data-e2e="add-transaction-button">
            {ADD_TRANSACTION_BTN}
          </AddTransaction>
        )}
      </FormContainer>
    </>
  );
};

export default Wallet;
