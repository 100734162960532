import ClickOutside from 'react-click-outside';

import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const TooltipWrapper = styled(ClickOutside)`
  display: inline-block;
  position: relative;
`;

const Arrow = styled.div`
  position: absolute;

  &:after,
  &:before {
    border-style: solid;
    content: '';
    display: inline-block;
    height: 0;
    position: absolute;
    z-index: 1;
    width: 0;

    ${({ arrowPosition: { arrow } }) => arrow}
  }

  &:after {
    border-color: ${({ arrowColor }) => `transparent transparent ${arrowColor} transparent`};
    border-width: 10px;

    ${({ arrowPosition: { after } }) => after}
  }

  &:before {
    border-color: transparent transparent ${COLORS.neutral500} transparent;
    border-width: 9px;

    ${({ arrowPosition: { before } }) => before}
  }
`;

const AppearingPart = styled.div`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.neutral500};
  border-radius: 6px;
  filter: drop-shadow(1px 3px 3px ${COLORS.shadowColor});
  position: fixed;
  z-index: 100;
`;

export { TooltipWrapper, Arrow, AppearingPart };
