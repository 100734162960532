import { COLORS } from 'shared-parts/constants';

const { neutral500 } = COLORS;

const SearchIcon = (fillColor = neutral500, height = 13, width = 13) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 13">
    <path
      fill={fillColor}
      d="M12.291,11.176H11.7l-.208-.2a4.839,4.839,0,1,0-.52.52l.2.208v.587L14.893,16,16,14.893Zm-4.46,0a3.345,3.345,0,1,1,3.345-3.345A3.34,3.34,0,0,1,7.831,11.176Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

export default SearchIcon;
