import styled from 'styled-components';

import { useEnvVar } from '@frontend/config';

const LogoImage = styled.img`
  max-width: 160px;
  height: 100%;
  padding: 18px 0;
  margin-left: 35px;
`;

const MainLogo = (props: any) => (
  <LogoImage {...props} src="/logos/white.svg" alt={`${useEnvVar('PLATFORM_NAME')} logo`} />
);

export default MainLogo;
