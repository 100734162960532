import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

const initialState = getReducerState();

export const twoFactorAuthCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.TWO_FACTOR_AUTH_GET_CODE_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.TWO_FACTOR_AUTH_GET_CODE_ERROR:
      return { loading: false, data: {}, error: action.error };
    case constants.TWO_FACTOR_AUTH_GET_CODE_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const signUpInitialState = getReducerState();
export const signUpReducer = (state = signUpInitialState, { type, data, error }) => {
  switch (type) {
    case constants.SIGN_UP_SUCCESS:
      return getReducerState({ data });
    case constants.SIGN_UP_ERROR:
      return getReducerState({ error });
    case constants.RESET_SIGN_UP:
      return getReducerState();
    default:
      return state;
  }
};
