import { COLORS } from 'shared-parts/constants';

const ImageIcon = (fillColor = COLORS.highlight, height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="UI-Style-Guide">
          <g id="Group_3777" data-name="Group 3777">
            <rect
              id="Rectangle_2314"
              data-name="Rectangle 2314"
              className="icon"
              width="40"
              height="40"
              rx="2"
            />
            <g id="Group_3775" data-name="Group 3775">
              <path
                id="Path_1558"
                fill="#eeeff3"
                d="M26.18,12H13.64A1.61,1.61,0,0,0,12,13.64V26.16a1.62,1.62,0,0,0,1.64,1.64H26.16a1.64,1.64,0,0,0,1.64-1.64V13.64A1.59,1.59,0,0,0,26.18,12ZM22.5,25.08H14.07l4.21-7.95,2.2,4.16,1.35-2.55,3.37,6.34ZM24,18.18a1.72,1.72,0,1,1,1.72-1.72A1.72,1.72,0,0,1,24,18.18Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ImageIcon;
