const TrashIcon = ({ width = 20, height = 20, fillColor = 'currentColor' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M8.25033 2.75V3.66667H3.66699V5.5H4.58366V17.4167C4.58366 17.9029 4.77681 18.3692 5.12063 18.713C5.46445 19.0568 5.93076 19.25 6.41699 19.25H15.5837C16.0699 19.25 16.5362 19.0568 16.88 18.713C17.2238 18.3692 17.417 17.9029 17.417 17.4167V5.5H18.3337V3.66667H13.7503V2.75H8.25033ZM6.41699 5.5H15.5837V17.4167H6.41699V5.5ZM8.25033 7.33333V15.5833H10.0837V7.33333H8.25033ZM11.917 7.33333V15.5833H13.7503V7.33333H11.917Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default TrashIcon;
