import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';
import { Screens } from 'shared-parts/constants/screens';

const TaglineText = styled.div`
  color: ${COLORS.primary};
  font-size: 20px;
  width: 300px;
  word-break: break-word;
  padding-right: 40px;
  order: ${({ isImageExpanded }) => (isImageExpanded ? 1 : 0)};

  @media only screen and (max-width: ${Screens.xl}) {
    width: 200px;
  }

  @media only screen and (max-width: ${Screens.lg}) {
    display: none;
  }

  p {
    margin: 0;
  }
`;

export {
  TaglineText, // eslint-disable-line
};
