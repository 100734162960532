import PropTypes from 'prop-types';

import { pluralize } from '@frontend/utils';

import {
  CheckMarkIcon,
  ContinueButton,
  ShareholdersInvited,
  Wrapper,
} from './shareholders-invited-modal.styled';

const ShareholdersInvitedModal = ({ hideModal, invited }) => (
  <Wrapper>
    <CheckMarkIcon />
    <ShareholdersInvited>{pluralize('Shareholder', invited)} Invited</ShareholdersInvited>
    <ContinueButton onClick={hideModal} data-e2e="continue-button">
      CONTINUE
    </ContinueButton>
  </Wrapper>
);

ShareholdersInvitedModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  invited: PropTypes.number.isRequired,
};

export default ShareholdersInvitedModal;
