import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getInvestorAgentsPermissions } from 'app-state/deals/selectors';
import {
  fetchInvestorAgentsPermissions,
  resetInvestorAgentsPermissions,
} from 'app-state/investors/actions';

import useTypedSelector from 'hooks/use-typed-selector';

export const AGENTS_PERMISSIONS_TITLE =
  'Investor has given permission to share information and receive deals from the following agents:';

export interface Props {
  investorUuid: string;
  children: (title: string, permissions: { uuid: string; name: string }) => ReactNode;
}

export const AgentsPermissions = ({ investorUuid, children }: Props) => {
  const dispatch = useDispatch();
  const permissions = useTypedSelector(getInvestorAgentsPermissions);

  useEffect(() => {
    dispatch(fetchInvestorAgentsPermissions({ uuid: investorUuid }));

    return () => {
      dispatch(resetInvestorAgentsPermissions());
    };
  }, []);

  return permissions.length > 0 ? children(AGENTS_PERMISSIONS_TITLE, permissions) : null;
};
