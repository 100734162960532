import type { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const LinkVariant: FC<React.PropsWithChildren<Props>> = ({
  width = 40,
  height = 40,
  fillColor = '#00bbd2',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      id="link-variant_1_"
      data-name="link-variant (1)"
      d="M9.537,11.978a.843.843,0,0,1,0,1.229.872.872,0,0,1-1.229,0,4.331,4.331,0,0,1,0-6.12h0l3.064-3.064a4.327,4.327,0,1,1,6.12,6.12l-1.29,1.29a5.979,5.979,0,0,0-.346-2.095l.407-.415a2.6,2.6,0,0,0-3.67-3.67L9.537,8.308a2.581,2.581,0,0,0,0,3.67m2.441-3.67a.872.872,0,0,1,1.229,0,4.331,4.331,0,0,1,0,6.12h0l-3.064,3.064a4.327,4.327,0,0,1-6.12-6.12l1.29-1.29a6.06,6.06,0,0,0,.346,2.1l-.407.407a2.6,2.6,0,0,0,3.67,3.67l3.055-3.055a2.581,2.581,0,0,0,0-3.67A.843.843,0,0,1,11.978,8.308Z"
      transform="translate(-2.757 -2.757)"
      fill={fillColor}
    />
  </svg>
);

export default LinkVariant;
