import apolloClient from 'app-state/graphql/apollo-provider';
import shareholdersQuery from 'app-state/graphql/shareholders/shareholders-query';

const buildHaving = securityHolderState => {
  switch (securityHolderState) {
    case 'former':
      return { totalEquityQuantity: { eq: 0 } };

    case 'current':
      return { totalEquityQuantity: { gt: 0 } };

    default:
      return {};
  }
};

const buildOrderBy = ({ sortByColumn, sortingOrder }) =>
  sortByColumn && sortingOrder ? [{ [sortByColumn]: sortingOrder }] : [];

const buildFilters = ({ issuerId, name, securityHolderExternalId }) => ({
  issuerId: { eq: issuerId },
  name: { ilike: `%${name}%` },
  ...(securityHolderExternalId && {
    securityHolderExternalId: { eq: securityHolderExternalId },
  }),
});

const getShareholdersGql = async (queryParams, state) => {
  const {
    limit,
    offset,
    issuerId,
    sortByColumn,
    sortingOrder,
    securityHolderState = [],
    name = '',
    securityHolderExternalId = '',
  } = queryParams;

  try {
    const { data } = await apolloClient.query({
      variables: {
        filters: buildFilters({ issuerId, name, securityHolderExternalId }),
        having: buildHaving(securityHolderState[0]),
        orderBy: buildOrderBy({ sortByColumn, sortingOrder }),
        offset,
        limit,
      },
      context: {
        headers: {
          'x-auth-token': state.session.user.authToken,
        },
      },
      query: shareholdersQuery,
    });

    return {
      items: data.securityHolders.nodes,
      meta: {
        pagination: {
          recordsCount: data.securityHolders.nodes.length,
          totalCount: data.securityHolders.totalCount,
          offset: data.securityHolders.pageInfo.offset,
        },
      },
    };
  } catch (e) {
    console.error(e);
  }
};

export default getShareholdersGql;
