import { HIDE_LOADER, SHOW_LOADER } from 'app-state/loader/constants';
import type { LoaderActionTypes, LoaderState } from 'app-state/loader/types';

export const defaultLoaderState: LoaderState = {
  message: '',
  showLoader: false,
  whiteBackground: false,
  showIcon: false,
  isSuccess: false,
  isDone: false,
};

export const loaderReducer = (
  state = defaultLoaderState,
  action: LoaderActionTypes,
): LoaderState => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, ...action.payload, showLoader: true };
    case HIDE_LOADER:
      return defaultLoaderState;
    default:
      return state;
  }
};
