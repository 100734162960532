import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'helpers';
import { useEnvVar } from 'helpers/use-env-var';

const ALLOWED_ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
};

function* getPermissionLevels({ companyUuid }) {
  try {
    const { data } = yield call(request, API.PermissionLevels(companyUuid));
    // TODO: This override must be deleted when API return admin roles only
    const filterData = useEnvVar('DISABLED_SECONDARY_ROLES')
      ? data.filter(({ key }) => key === ALLOWED_ROLES.SUPER_ADMIN || key === ALLOWED_ROLES.ADMIN)
      : data;
    yield put(actions.getPermissionLevelsSuccess(filterData));
  } catch (e) {
    yield put(actions.getPermissionLevelsError(e));
  }
}

function* getPermissionLevelsWatcher() {
  yield takeEvery(constants.GET_PERMISSION_LEVELS, getPermissionLevels);
}

function* getPermissions() {
  try {
    const {
      data: { permissions },
    } = yield call(request, API.GetPermissions(), 'GET', null, { camelCase: false });

    yield put(actions.getPermissionsSuccess(permissions));
  } catch (e) {
    yield put(actions.getPermissionsError(e));
  }
}

function* getPermissionsWatcher() {
  yield takeEvery(constants.GET_PERMISSIONS, getPermissions);
}

export { getPermissionLevels, getPermissionLevelsWatcher, getPermissions, getPermissionsWatcher };
