import { FC } from 'react';

import { AddIcon } from 'svg-icons';

import { AddIconContainer, ButtonWrapper } from './large-button.styled';
import { LargeButtonProps } from './types';

const LargeButton: FC<React.PropsWithChildren<LargeButtonProps>> = ({
  onClick,
  title,
  className,
  disabled = false,
  color = 'dark',
  showAddIcon = true,
}) => (
  <ButtonWrapper className={className} onClick={onClick} disabled={disabled} color={color}>
    {showAddIcon && (
      <AddIconContainer>
        <AddIcon />
      </AddIconContainer>
    )}
    <div>{title}</div>
  </ButtonWrapper>
);

export default LargeButton;
