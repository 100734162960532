const VideoIcon = (fillColor = '#333', height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="UI-Style-Guide">
          <g id="Group_3777" data-name="Group 3777">
            <rect
              id="Rectangle_2314"
              data-name="Rectangle 2314"
              className="icon"
              width="40"
              height="40"
              rx="2"
            />
            <g id="Group_3775" data-name="Group 3775">
              <path
                id="Path_1558"
                fill="#eeeff3"
                d="M26.37,12H13.66A1.64,1.64,0,0,0,12,13.66V26.34A1.64,1.64,0,0,0,13.66,28H26.35a1.65,1.65,0,0,0,1.17-.48A1.69,1.69,0,0,0,28,26.34V13.66a1.6,1.6,0,0,0-.47-1.17A1.58,1.58,0,0,0,26.37,12Zm-3.24,8.41-3.95,3.77a.55.55,0,0,1-.38.16.59.59,0,0,1-.22-.05.6.6,0,0,1-.32-.5V16.24a.61.61,0,0,1,.32-.51.55.55,0,0,1,.59.11l4,3.78a.5.5,0,0,1,.16.4.48.48,0,0,1-.16.39Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default VideoIcon;
