import { useEnvVar } from '@frontend/config';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers/app-config';
import { getDealAlias } from 'helpers/get-deal-alias';

const placementDisabled = useFeatureFlag(FeatureFlag.PLACEMENT_DISABLED);
const isAllInvestorTabEnable = useEnvVar('ENABLE_ALL_INVESTOR_TAB');

export default {
  Operations: ({ companyId }: { companyId: string }) =>
    [
      {
        title: getDealAlias({ plural: true }),
        to: Routes.Globacap.DealManagementDeals(companyId),
        hidden: placementDisabled,
      },
      ...(isAllInvestorTabEnable
        ? [
            {
              title: 'All Investors',
              to: Routes.Admin.DealManagementInvestors(),
              hidden: placementDisabled,
            },
          ]
        : []),
    ].filter(({ hidden }) => !hidden),
  MultiCompany: ({ companyId }: { companyId: string }) =>
    [
      {
        title: getDealAlias({ plural: true }),
        to: Routes.MultiCompany.DealManagementDeals(companyId),
        hidden: placementDisabled,
      },
    ].filter(({ hidden }) => !hidden),
  Company: () =>
    [
      {
        title: getDealAlias({ plural: true }),
        to: Routes.Admin.DealManagementDeals(),
        exact: true,
        hidden: placementDisabled,
      },
      ...(isAllInvestorTabEnable
        ? [
            {
              title: 'All Investors',
              to: Routes.Admin.DealManagementInvestors(),
              hidden: placementDisabled,
            },
          ]
        : []),
    ].filter(({ hidden }) => !hidden),
};
