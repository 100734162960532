import React, { useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import inputAcceptsFileType from 'attr-accept';

import { UploadIcon } from '../../icons';

import {
  ChildrenBox,
  ClickableAnchor,
  DashedBox,
  DocumentNameText,
  DocumentUploadRow,
  StyledPaper,
  UploadDocumentText,
} from './document-upload.styled';
import { DocumentUploadProps } from './types';

const DocumentUpload: React.FC<React.PropsWithChildren<DocumentUploadProps>> = ({
  onFileSelected,
  onInputFileError,
  uploadDocumentCopy = 'Upload document',
  children,
  inputProps,
  initialFile,
  ...paperProps
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(initialFile || null);
  const theme = useTheme();
  const fileUrl = inputProps?.src;

  /**
   * Tests file type before submission
   */
  const processFile = (selectedFile: File) => {
    if (
      inputProps?.accept &&
      !inputAcceptsFileType(
        {
          name: selectedFile.name,
          type: selectedFile.type,
        },
        inputProps.accept.split(',') || '',
      )
    ) {
      onInputFileError?.(`Invalid file type. Valid file types are: ${inputProps.accept}`);
      return;
    }
    setFile(selectedFile);
    onFileSelected?.(selectedFile);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      processFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    if (inputProps?.disabled) return;
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    if (inputProps?.disabled) return;
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (inputProps?.disabled) return;
    setIsDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      processFile(event.dataTransfer.files[0]);
    }
  };

  return (
    <StyledPaper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      {...paperProps}
    >
      <DashedBox isDisabled={inputProps?.disabled} isDragOver={isDragOver} isDocumentAdded={!!file}>
        <DocumentUploadRow>
          <DocumentNameText
            variant="body1"
            isDocumentAdded={!!file}
            hasUrl={!!fileUrl}
            isDisabled={inputProps?.disabled}
            onClick={() => {
              if (fileUrl) {
                window.open(fileUrl, '_blank');
              }
            }}
          >
            {file?.name || 'No document added'}
          </DocumentNameText>
        </DocumentUploadRow>
        <ClickableAnchor
          isDisabled={inputProps?.disabled}
          href="#"
          role="button"
          onClick={() => fileInputRef.current?.click()}
        >
          <DocumentUploadRow>
            <UploadIcon
              fillColor={
                !inputProps?.disabled ? theme.palette.secondary.main : theme.palette.neutral[300]
              }
            />
            <UploadDocumentText isDisabled={inputProps?.disabled} variant="body1">
              {uploadDocumentCopy}
            </UploadDocumentText>
          </DocumentUploadRow>
        </ClickableAnchor>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          role="button"
          aria-label="File Upload"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
        />
      </DashedBox>
      {children && <ChildrenBox>{children}</ChildrenBox>}
    </StyledPaper>
  );
};

export { DocumentUpload };
