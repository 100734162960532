const NotStartedIcon = ({ width = 18, height = 18, fillColor = 'currentColor' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9.74961 1.5V3C13.0421 3.405 15.3746 6.3975 14.9696 9.69C14.6246 12.42 12.4796 14.5725 9.74961 14.91V16.41C13.8746 15.96 16.8371 12.255 16.3871 8.1375C15.9971 4.6425 13.2446 1.875 9.74961 1.5ZM8.24961 1.5C6.77961 1.635 5.39211 2.2125 4.25211 3.15L5.32461 4.305C6.16461 3.63 7.17711 3.195 8.24961 3.045V1.5ZM3.19461 4.2525C2.24961 5.3925 1.67961 6.78 1.53711 8.25H3.03711C3.17961 7.185 3.59961 6.1725 4.26711 5.325L3.19461 4.2525ZM1.54461 9.75C1.69461 11.22 2.27211 12.6075 3.20211 13.7475L4.26711 12.675C3.60711 11.8275 3.17961 10.815 3.04461 9.75H1.54461ZM5.29461 13.7775L4.25211 14.805C5.38461 15.75 6.77961 16.3425 8.24961 16.5V15C7.18461 14.865 6.17211 14.4375 5.32461 13.7775H5.29461Z"
      fill={fillColor}
    />
  </svg>
);

export default NotStartedIcon;
