import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import {
  GET_SUBSCRIPTION_TEMPLATES,
  GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY,
  SELECT_PACKAGE,
} from 'app-state/constants/package';

import API from 'constants/api';
import { request } from 'shared-parts/helpers';

function* selectPackage({ companyUuid, packageId }) {
  try {
    yield call(request, API.SelectPackage(companyUuid), 'PUT', {
      subscriptionTemplateId: packageId,
    });
  } catch (e) {
    yield put(actions.selectPackageError(e));
  }
}

function* selectPackageWatcher() {
  yield takeEvery(SELECT_PACKAGE, selectPackage);
}

function* getSubscriptionTemplates() {
  try {
    const { data } = yield call(request, API.SubscriptionTemplates());

    yield put(actions.getSubscriptionTemplatesSuccess(data));
  } catch (e) {
    yield put(actions.getSubscriptionTemplatesError(e));
  }
}

function* getSubscriptionTemplatesWatcher() {
  yield takeEvery(GET_SUBSCRIPTION_TEMPLATES, getSubscriptionTemplates);
}

function* getSubscriptionTemplatesPerCompany({ companyUuid }) {
  try {
    const { data } = yield call(request, API.SubscriptionTemplatesPerCompany(companyUuid));

    yield put(actions.getSubscriptionTemplatesPerCompanySuccess(data));
  } catch (e) {
    yield put(actions.getSubscriptionTemplatesPerCompanyError(e));
  }
}

function* getSubscriptionTemplatesPerCompanyWatcher() {
  yield takeEvery(GET_SUBSCRIPTION_TEMPLATES_PER_COMPANY, getSubscriptionTemplatesPerCompany);
}

export {
  selectPackage,
  selectPackageWatcher,
  getSubscriptionTemplates,
  getSubscriptionTemplatesWatcher,
  getSubscriptionTemplatesPerCompany,
  getSubscriptionTemplatesPerCompanyWatcher,
};
