import type { MouseEvent } from 'react';

import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

type Props = {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isDark?: boolean;
  isSuccess?: boolean;
  danger?: boolean;
};

const getButtonColor: (props: Props) => string = ({ danger, isDark, isSuccess }) => {
  if (danger) return COLORS.systemRed;
  if (isDark) return COLORS.primary;
  if (isSuccess) return COLORS.success;

  return COLORS.alternative;
};

const StandardButton = styled.button<Props>`
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 6px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 14px;
  outline: 0;
  padding: 8px 18px;
  height: 40px;
  min-width: 120px;
  font-size: 14px;
  font-family: ${FONTS.mainFont};
  color: ${({ color }) => color || COLORS.white};
  background: ${getButtonColor};

  ${({ isDark }) =>
    !isDark &&
    `
    :hover {
      box-shadow: 0 3px 6px 0 ${COLORS.neutral500};
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: auto;
    background: ${COLORS.neutral800};
    color: ${COLORS.white};
    &:hover {
      box-shadow: none;
    }
  `}
`;

export default StandardButton;
