export const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE';
export const GET_COMPANY_PROFILE_SUCCESS = 'GET_COMPANY_PROFILE_SUCCESS';
export const GET_COMPANY_PROFILE_ERROR = 'GET_COMPANY_PROFILE_ERROR';
export const RESET_COMPANY_PROFILE = 'RESET_COMPANY_PROFILE';

export const GET_FILINGS = 'GET_FILINGS';
export const GET_FILINGS_SUCCESS = 'GET_FILINGS_SUCCESS';
export const GET_FILINGS_ERROR = 'GET_FILINGS_ERROR';
export const RESET_FILINGS = 'RESET_FILINGS';

export const GET_FILING_DETAILS = 'GET_FILING_DETAILS';
export const GET_FILING_DETAILS_SUCCESS = 'GET_FILING_DETAILS_SUCCESS';
export const GET_FILING_DETAILS_ERROR = 'GET_FILING_DETAILS_ERROR';
export const RESET_FILING_DETAILS = 'RESET_FILING_DETAILS';
export const GET_FILING_DOCUMENTS_SUCCESS = 'GET_FILING_DOCUMENTS_SUCCESS';

export const ADD_FILING_DOCUMENT = 'ADD_FILING_DOCUMENT';
export const ADD_FILING_DOCUMENT_SUCCESS = 'ADD_FILING_DOCUMENT_SUCCESS';
export const ADD_FILING_DOCUMENT_ERROR = 'ADD_FILING_DOCUMENT_ERROR';

export const DELETE_FILING_DOCUMENT = 'DELETE_FILING_DOCUMENT';
export const DELETE_FILING_DOCUMENT_SUCCESS = 'DELETE_FILING_DOCUMENT_SUCCESS';
export const DELETE_FILING_DOCUMENT_ERROR = 'DELETE_FILING_DOCUMENT_ERROR';

export const DOWNLOAD_FILINGS_AS_CSV = 'DOWNLOAD_FILINGS_AS_CSV';

export const GET_SHARE_CAPITAL = 'GET_SHARE_CAPITAL';
export const GET_SHARE_CAPITAL_SUCCESS = 'GET_SHARE_CAPITAL_SUCCESS';
export const GET_SHARE_CAPITAL_ERROR = 'GET_SHARE_CAPITAL_ERROR';
export const RESET_SHARE_CAPITAL = 'RESET_SHARE_CAPITAL';

export const GET_SHAREHOLDER_INFORMATION = 'GET_SHAREHOLDER_INFORMATION';
export const GET_SHAREHOLDER_INFORMATION_SUCCESS = 'GET_SHAREHOLDER_INFORMATION_SUCCESS';
export const GET_SHAREHOLDER_INFORMATION_ERROR = 'GET_SHAREHOLDER_INFORMATION_ERROR';
export const RESET_SHAREHOLDER_INFORMATION = 'RESET_SHAREHOLDER_INFORMATION';

export const CREATE_CONFIRMATION_STATEMENT = 'CREATE_CONFIRMATION_STATEMENT';
export const SUBMIT_SH01_FORM = 'SUBMIT_SH01_FORM';

export const GET_SIC_CODES = 'GET_SIC_CODES';
export const GET_SIC_CODES_SUCCESS = 'GET_SIC_CODES_SUCCESS';
export const GET_SIC_CODES_ERROR = 'GET_SIC_CODES_ERROR';

export const GET_ALLOTMENT_DATA = 'GET_ALLOTMENT_DATA';
export const GET_ALLOTMENT_DATA_SUCCESS = 'GET_ALLOTMENT_DATA_SUCCESS';
export const GET_ALLOTMENT_DATA_ERROR = 'GET_ALLOTMENT_DATA_ERROR';
export const RESET_ALLOTMENT_DATA = 'RESET_ALLOTMENT_DATA';

export const GET_ALLOTTED_SHARES = 'GET_ALLOTTED_SHARES';
export const GET_ALLOTTED_SHARES_SUCCESS = 'GET_ALLOTTED_SHARES_SUCCESS';
export const GET_ALLOTTED_SHARES_ERROR = 'GET_ALLOTTED_SHARES_ERROR';
export const RESET_ALLOTTED_SHARES = 'RESET_GET_ALLOTTED_SHARES';
