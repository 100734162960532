import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { pluralize } from '@frontend/utils';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import handleForbiddenDealEdit from 'app-state/deal/helpers';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import * as selectors from 'app-state/selectors';
import { getCompany as getCompanySelector } from 'app-state/selectors/companies';
import { getDealDocumentsData, getDealSettlementsMeta } from 'app-state/selectors/deal';

import {
  NDA_PROCESS_FLOW_NAME,
  PLACEMENT_DETAILS,
  RADIO_OPTION_E_SIGNATURE_VALUE,
} from 'modules/company/deal-management/deal-form/constants';
import API from 'constants/api';
import { findFirstError } from 'helpers';
import Role from 'helpers/role';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ErrorModal from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';
import request from 'shared-parts/helpers/request';
import getUpdatedPageData from 'shared-parts/helpers/update-page-data';

import { setDealStructureFormError } from '../deals/actions';

import { openHelloSignClientIframe } from './hello-sign-client';
import { fetchTheNavigation, fetchTheSubNavigation } from './navigation';

function* fetchIssuance({ companyUuid, modelUuid }) {
  try {
    const { data } = yield call(request, API.Issuance(companyUuid, modelUuid));

    yield put(actions.fetchIssuanceSuccess(data));
    return yield data;
  } catch (e) {
    yield put(actions.fetchIssuanceError(e));
    return yield e;
  }
}

function* fetchIssuanceWatcher() {
  yield takeEvery(constants.FETCH_ISSUANCE, fetchIssuance);
}

function* fetchDealSummary({ payload }) {
  try {
    const { data } = yield call(request, API.DealSummary(payload));

    yield put(actions.fetchDealSummaryDataSuccess(data));
  } catch (e) {
    yield put(actions.fetchDealSummaryDataError(e));
  }
}

function* fetchDealSummaryWatcher() {
  yield takeEvery(constants.FETCH_DEAL_SUMMARY_DATA, fetchDealSummary);
}

function* fetchDealInfo({ payload }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data } = yield call(request, API.DealInfo(companyData.uuid, payload));

    yield put(actions.fetchDealInfoSuccess(data));
  } catch (e) {
    yield put(actions.fetchDealInfoError(e));
  }
}

function* fetchDealInfoWatcher() {
  yield takeEvery(constants.FETCH_DEAL_INFO, fetchDealInfo);
}

function* fetchDealData({ payload }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data } = yield call(request, API.DealData(companyData.uuid, payload));

    yield put(actions.fetchDealDataSuccess(data));
  } catch (e) {
    yield put(actions.fetchDealDataError(e));
  }
}

function* fetchDealDataWatcher() {
  yield takeEvery(constants.FETCH_DEAL_DATA, fetchDealData);
}

function* fetchDealDocumentsData({ payload }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data: dealData } = yield call(request, API.DealData(companyData.uuid, payload));

    yield put(actions.fetchDealDocumentsDataSuccess(dealData));
  } catch (e) {
    yield put(actions.fetchDealDocumentsDataError(e));
  }
}

function* fetchDealDocumentsDataWatcher() {
  yield takeEvery(constants.FETCH_DEAL_DOCUMENTS_DATA, fetchDealDocumentsData);
}

function* createDealDetails({ payload: { companyUuid, dealUuid, formData, onSuccess } }) {
  try {
    const { data } = yield call(
      request,
      API.DealDetails({ companyUuid, dealUuid }),
      'POST',
      formData,
    );

    yield put(actions.createDealDetailsSuccess(data));
    yield onSuccess();
    yield put(actions.fetchDealData(dealUuid));
  } catch (e) {
    yield put(actions.createDealDetailsError(e));
  }
}

function* createDealDetailsWatcher() {
  yield takeEvery(constants.CREATE_DEAL_DETAILS, createDealDetails);
}

function* editDealDetails({ payload: { companyUuid, dealUuid, formData, onSuccess } }) {
  try {
    const { data } = yield call(
      request,
      API.DealDetails({ companyUuid, dealUuid }),
      'PUT',
      formData,
    );

    yield put(actions.editDealDetailsSuccess(data));
    yield onSuccess();
    yield put(actions.fetchDealData(dealUuid));
  } catch (e) {
    yield put(actions.editDealDetailsError(e));
  }
}

function* editDealDetailsWatcher() {
  yield takeEvery(constants.EDIT_DEAL_DETAILS, editDealDetails);
}

function* fetchDealList({ payload }) {
  const { uuid, page, perPage, shouldBeRefreshed, ...rest } = payload;
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const api = API.DealInvitedInvestorList({
      companyUuid: companyData.uuid,
      uuid,
      ...{ page, perPage, ...rest },
    });
    const { data } = yield call(request, api, 'GET', null, { timeout: 20000 });

    yield put(actions.fetchDealListSuccess({ data, page, perPage }));
  } catch (error) {
    yield put(actions.fetchDealListError(error));
  }
}

function* fetchDealListWatcher() {
  yield takeEvery(constants.FETCH_DEAL_LIST, fetchDealList);
}

function* fetchInvestorDocuments({ payload: { dealUuid, ...rest } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const response = yield call(request, API.InvestorDocuments(companyData.uuid, dealUuid, rest));

    yield put(actions.fetchInvestorDocumentsSuccess(response.data));
  } catch (e) {
    yield put(actions.fetchInvestorDocumentsError(e));
  }
}

function* fetchInvestorDocumentsWatcher() {
  yield takeEvery(constants.FETCH_INVESTOR_DOCUMENTS, fetchInvestorDocuments);
}

function* uploadInvestorDocument({ payload: { dealUuid, investorUuid, email, type, fileData } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    yield call(
      request,
      API.UploadInvestorDocument(companyData.uuid, dealUuid, investorUuid),
      'POST',
      fileData,
      { timeout: 180000 },
    );
    const investorDocumentsPagination = yield select(selectors.getInvestorDocumentsPagination);
    yield put(
      actions.fetchInvestorDocuments({
        ...investorDocumentsPagination,
        uuid: investorUuid,
        email,
        dealUuid,
        type,
      }),
    );
    yield put(actions.uploadInvestorDocumentFinished());
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* uploadInvestorDocumentWatcher() {
  yield takeEvery(constants.UPLOAD_INVESTOR_DOCUMENT, uploadInvestorDocument);
}

function* deleteInvestorDocument({ payload: { dealUuid, investorUuid, email, type, documentId } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    yield call(
      request,
      API.DeleteInvestorDocument(companyData.uuid, dealUuid, investorUuid, documentId),
      'DELETE',
    );
    const investorDocumentsPagination = yield select(selectors.getInvestorDocumentsPagination);
    yield put(
      actions.fetchInvestorDocuments({
        ...investorDocumentsPagination,
        uuid: investorUuid,
        email,
        dealUuid,
        type,
      }),
    );
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* deleteInvestorDocumentWatcher() {
  yield takeEvery(constants.DELETE_INVESTOR_DOCUMENT, deleteInvestorDocument);
}

function* changeDocumentAgreedStatus({
  payload: { uuid, email, dealUuid, isAgree, documentId, type },
}) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const APIEndpoint = isAgree ? API.InvestorDocumentsAgree : API.InvestorDocumentsDisagree;
    yield call(request, APIEndpoint(companyData.uuid, dealUuid, uuid, documentId), 'PUT');
    const investorDocumentsPagination = yield select(selectors.getInvestorDocumentsPagination);
    yield put(
      actions.fetchInvestorDocuments({
        ...investorDocumentsPagination,
        uuid,
        email,
        dealUuid,
        type,
      }),
    );
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* changeDocumentAgreedStatusWatcher() {
  yield takeEvery(constants.CHANGE_DOCUMENT_AGREED_STATUS, changeDocumentAgreedStatus);
}

function* fetchDeal({ permalink }) {
  try {
    const response = yield call(request, API.Deal(permalink));

    yield put(actions.fetchDealSuccess(response.data));
    return yield response;
  } catch (e) {
    yield put(actions.fetchDealError(e));
    return yield e;
  }
}

function* fetchDealWatcher() {
  yield takeEvery(constants.FETCH_DEAL, fetchDeal);
}

function* updateDealPageHeader({ uuid, companyUuid, data, onSuccess }) {
  try {
    const { data: resData } = yield call(
      request,
      API.UpdateDealPageHeader(uuid, companyUuid),
      'PUT',
      data,
      { timeout: 180000 },
    );

    yield put(actions.updateDealPageHeaderSuccess(resData));
    yield put(actions.fetchDealDataSuccess(resData));
    yield onSuccess();
  } catch (e) {
    yield put(actions.updateDealPageHeaderError(e));
    return yield e;
  }
}

function* updateDealPageHeaderWatcher() {
  yield takeEvery(constants.UPDATE_DEAL_PAGE_HEADER, updateDealPageHeader);
}

function* updateDealData({ uuid, ...newDealData }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data: dealData } = yield call(
      request,
      API.DealData(companyData.uuid, uuid),
      'PUT',
      newDealData,
    );

    yield put(actions.fetchDealDataSuccess(dealData));
    yield put(actions.updateDealDataSuccess());
  } catch (e) {
    yield put(actions.updateDealDataError(e));
    return yield e;
  }
}

function* updateDealDataWatcher() {
  yield takeEvery(constants.UPDATE_DEAL_DATA, updateDealData);
}

function* fetchInvestorInfo({ payload: { dealUuid, email } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data } = yield call(
      request,
      API.InvestorInfo({ companyUuid: companyData.uuid, dealUuid, email }),
    );

    yield put(actions.fetchInvestorInfoSuccess(data));
  } catch (e) {
    yield put(actions.fetchInvestorInfoError(e));

    const { status } = e;
    if (status >= 400 && status < 500) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

function* fetchInvestorInfoWatcher() {
  yield takeEvery(constants.FETCH_INVESTOR_INFO, fetchInvestorInfo);
}

function* resendInvestorInvite({ payload: { dealUuid, companyUuid, email } }) {
  try {
    yield call(request, API.ResendDealInvite(companyUuid, dealUuid), 'POST', {
      companyUuid,
      email,
    });

    yield put(actions.resendInvestorInviteSuccess());
    yield put(
      actions.showModal({
        closable: true,
        component: SuccessModal,
        buttonText: 'OK',
        title: 'Invite Sent',
      }),
    );
    yield put(actions.fetchInvestorInfo({ dealUuid, email }));
  } catch (e) {
    yield put(actions.resendInvestorInviteError(e));

    if (e.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

function* resendInvestorInviteWatcher() {
  yield takeEvery(constants.RESEND_INVESTOR_INVITE, resendInvestorInvite);
}

function* resendIPInvestorInvite({ payload: { investorUuid } }) {
  yield put(showLoader());

  try {
    yield call(request, API.ResendIPInvite(investorUuid), 'POST');

    yield put(
      actions.showModal({
        closable: true,
        component: SuccessModal,
        buttonText: 'OK',
        title: 'Invite Sent',
      }),
    );
  } catch (e) {
    if (e.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }

  yield put(hideLoader());
}

function* resendIPInvestorInviteWatcher() {
  yield takeEvery(constants.RESEND_IP_INVESTOR_INVITE, resendIPInvestorInvite);
}

function* editDealStructure({ payload: { uuid, values, handleSuccess, resetForm } }) {
  try {
    const params = {
      ...values,
      [PLACEMENT_DETAILS.AUTO_CANCEL_ORDERS]: values[PLACEMENT_DETAILS.AUTO_CANCEL_ORDERS] === 'On',
      [PLACEMENT_DETAILS.AUTO_APPROVE_ORDERS]:
        values[PLACEMENT_DETAILS.AUTO_APPROVE_ORDERS] === 'On',
      [PLACEMENT_DETAILS.SPA_REQUIRED]: values[PLACEMENT_DETAILS.SPA_REQUIRED] === 'Yes',
    };
    const { data: companyData } = yield select(getCompanySelector);
    const { data: documentsData } = yield select(getDealDocumentsData);

    const { data } = yield call(request, API.EditDeal(companyData.uuid, uuid), 'PUT', params);

    const updatedDealWithExistingDocumentData = { ...data, ...documentsData };

    yield put(actions.fetchDealSummaryData({ companyUuid: companyData.uuid, dealUuid: uuid }));
    yield put(actions.fetchDealDataSuccess(updatedDealWithExistingDocumentData));

    handleSuccess();
  } catch (e) {
    if (e.status === 400) {
      if (e.response.errorCode === 1000) {
        yield put(
          actions.showModal({
            component: ErrorModal,
            ...findFirstError(e, { pricePerToken: 'Unable to update share price' }),
          }),
        );

        resetForm();
      } else {
        yield put(setDealStructureFormError(e.response.details));
      }
    }
  }
}

function* editDealStructureWatcher() {
  yield takeEvery(constants.EDIT_DEAL_STRUCTURE, editDealStructure);
}

function* editDealConfiguration({ payload: { uuid, values, handleSuccess, setSubmitting } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data } = yield call(
      request,
      API.EditDealConfiguration(companyData.uuid, uuid),
      'PUT',
      values,
    );

    yield put(actions.fetchDealDataSuccess(data));
    handleSuccess();
  } catch (e) {
    if (e.status === 400) {
      yield setSubmitting(false);
      yield put(
        actions.showModal({
          component: ErrorModal,
        }),
      );
    }
  }
}

function* editDealConfigurationWatcher() {
  yield takeEvery(constants.EDIT_DEAL_CONFIGURATION, editDealConfiguration);
}

function* uploadDealDocument({ payload: { uuid, fileData, setError } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data } = yield call(
      request,
      API.UploadDealDocument(companyData.uuid, uuid),
      'POST',
      fileData,
      { timeout: 180000 },
    );

    yield put(actions.fetchDealDocumentsData(uuid));

    if (data.editUrl && data[NDA_PROCESS_FLOW_NAME] === RADIO_OPTION_E_SIGNATURE_VALUE) {
      yield* openHelloSignClientIframe({ editUrl: data.editUrl });
    }
  } catch (e) {
    const errorMessage = e.response ? e.response.details.file[0] : defaultErrorMessage;
    setError(errorMessage);
  }
}

function* uploadDealDocumentWatcher() {
  yield takeEvery(constants.UPLOAD_DEAL_DOCUMENT, uploadDealDocument);
}

function* fetchTheDealPagesData({ permalink }) {
  try {
    const { data: deal, status: dealRequestStatus } = yield call(fetchDeal, { permalink });
    const shouldInterrupt = dealRequestStatus === 403;

    if (shouldInterrupt) {
      const dealsURL = yield call(Role.has, 'Deals URL');
      return yield put(push(dealsURL));
    }

    const modelUuid = deal.uuid;
    const items = yield call(fetchTheNavigation, { modelUuid, permalink: deal.permalink });
    const effects = items
      .filter(item => item.hasSubnavigationItem)
      .reduce(
        (prev, { id, url }) => ({
          ...prev,
          [url]: call(fetchTheSubNavigation, {
            permalink: deal.permalink,
            navigationItemId: id,
            navigationURL: url,
          }),
        }),
        {},
      );
    const subItems = yield all(effects);
    const failed = Object.values(subItems).every(item => !item);

    if (!failed) {
      yield put(actions.fetchSubNavigationSuccess(subItems));
    } else {
      yield put(actions.fetchSubNavigationError({}));
    }
  } catch (e) {
    yield put(actions.fetchDealPagesDataError(e));
  }
}

function* fetchDealPagesDataWatcher() {
  yield takeEvery(constants.FETCH_DEAL_PAGES_DATA, fetchTheDealPagesData);
}

function* fetchThePage({ id, restricted }) {
  if (restricted) {
    return yield put(actions.fetchPageRestricted());
  }

  try {
    const { data } = yield call(request, API.Page(id));

    yield put(actions.fetchPageSuccess(data));
  } catch (e) {
    if (e.status === 403) {
      yield put(actions.logout(true));
    }
    yield put(actions.fetchPageError(e));
  }
}

function* fetchPageWatcher() {
  yield takeEvery(constants.FETCH_PAGE, fetchThePage);
}

function* editSectionTitle({ section }) {
  try {
    const { content } = yield select(selectors.getPage) || {};
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    yield put(actions.updatePage(getUpdatedPageData(content, section, 'updateSection')));
    yield call(request, API.EditSectionTitle(section.id), 'PUT', {
      title: section.title,
      modelUuid,
    });
  } catch (e) {
    yield put(actions.editSectionTitleError(e));
  }
}

function* editSectionTitleWatcher() {
  yield takeEvery(constants.EDIT_SECTION_TITLE, editSectionTitle);
}

function* deleteSection({ sectionId }) {
  try {
    const { content } = yield select(selectors.getPage);
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    yield put(actions.updatePage(getUpdatedPageData(content, sectionId, 'deleteSection')));
    yield call(request, API.DeleteSection(sectionId), 'DELETE', { modelUuid });
  } catch (e) {
    yield put(actions.deleteSectionError(e));
  }
}

function* deleteSectionWatcher() {
  yield takeEvery(constants.DELETE_SECTION, deleteSection);
}

function* reorderSections({ sections }) {
  try {
    const { content } = yield select(selectors.getPage);
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    yield put(actions.updatePage(getUpdatedPageData(content, sections, 'updateSections')));
    const ids = sections.map(section => section.id).toString();
    yield call(request, API.ReorderSections(), 'PUT', { ids, modelUuid });
  } catch (e) {
    yield put(actions.reorderSectionsError(e));
  }
}

function* reorderSectionsWatcher() {
  yield takeEvery(constants.REORDER_SECTIONS, reorderSections);
}

function* createSection({ data }) {
  try {
    const { content } = yield select(selectors.getPage);
    const section = yield call(request, API.CreateSection(), 'POST', data, {
      ignoredErrorCodes: [403],
    });
    yield put(actions.updatePage(getUpdatedPageData(content, section.data, 'createSection')));
  } catch (e) {
    yield put(actions.createSectionError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createSectionWatcher() {
  yield takeEvery(constants.CREATE_SECTION, createSection);
}

function* createTextComponent({ component }) {
  try {
    yield put(actions.addComponentStart(component.sectionId));
    const { content } = yield select(selectors.getPage);
    const result = yield call(request, API.CreateTextComponent(), 'POST', component, {
      ignoredErrorCodes: [403],
    });
    const newComponent = {
      ...result.data,
      sectionId: component.sectionId,
      isEditing: true,
    };

    yield put(actions.updatePage(getUpdatedPageData(content, newComponent, 'createComponent')));
    yield put(actions.setLastCreatedComponent(result.data.id, result.data.type));
    yield put(actions.addComponentEnd(component.sectionId));
  } catch (e) {
    yield put(actions.createTextComponentError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createTextComponentWatcher() {
  yield takeEvery(constants.CREATE_TEXT_COMPONENT, createTextComponent);
}

function* editTextComponent({ id, component, setIsEditingContent }) {
  try {
    const { content } = yield select(selectors.getPage);
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const result = yield call(request, API.EditTextComponent(id), 'PUT', {
      ...component,
      modelUuid,
    });

    yield setIsEditingContent(false);
    yield put(actions.updatePage(getUpdatedPageData(content, result.data, 'editComponent')));
  } catch (e) {
    yield put(actions.editTextComponentError(e));
  }
}

function* editTextComponentWatcher() {
  yield takeEvery(constants.EDIT_TEXT_COMPONENT, editTextComponent);
}

function* createTableComponent({ component }) {
  try {
    yield put(actions.addComponentStart(component.sectionId));
    const { content } = yield select(selectors.getPage);
    const result = yield call(request, API.CreateTableComponent(), 'POST', component, {
      ignoredErrorCodes: [403],
    });
    const newComponent = {
      ...result.data,
      sectionId: component.sectionId,
      isEditing: true,
    };

    yield put(actions.updatePage(getUpdatedPageData(content, newComponent, 'createComponent')));
    yield put(actions.setLastCreatedComponent(result.data.id, result.data.type));
    yield put(actions.addComponentEnd(component.sectionId));
  } catch (e) {
    yield put(actions.createTableComponentError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createTableComponentWatcher() {
  yield takeEvery(constants.CREATE_TABLE_COMPONENT, createTableComponent);
}

function* editTableComponent({ id, component }) {
  try {
    const { content } = yield select(selectors.getPage);
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const { data } = yield call(request, API.EditTableComponent(id), 'PUT', {
      ...component,
      modelUuid,
    });

    yield put(actions.updatePage(getUpdatedPageData(content, data, 'editComponent')));
  } catch (e) {
    yield put(actions.editTableComponentError(e));
  }
}

function* editTableComponentWatcher() {
  yield takeEvery(constants.EDIT_TABLE_COMPONENT, editTableComponent);
}

function* createDescriptionListComponent({ component }) {
  try {
    yield put(actions.addComponentStart(component.sectionId));
    const { content } = yield select(selectors.getPage);
    const result = yield call(request, API.CreateDescriptionListComponent(), 'POST', component, {
      ignoredErrorCodes: [403],
    });
    const newComponent = {
      ...result.data,
      sectionId: component.sectionId,
      isCreating: true,
    };

    yield put(actions.updatePage(getUpdatedPageData(content, newComponent, 'createComponent')));
    yield put(actions.setLastCreatedComponent(result.data.id, result.data.type));
    yield put(actions.addComponentEnd(component.sectionId));
  } catch (e) {
    yield put(actions.createDescriptionListComponentError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createDescriptionListComponentWatcher() {
  yield takeEvery(constants.CREATE_DESCRIPTION_LIST_COMPONENT, createDescriptionListComponent);
}

function* editDescriptionListComponent({ id, component }) {
  try {
    const { content } = yield select(selectors.getPage);
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const { data } = yield call(request, API.EditDescriptionListComponent(id), 'PUT', {
      ...component,
      modelUuid,
    });

    yield put(actions.updatePage(getUpdatedPageData(content, data, 'editComponent')));
  } catch (e) {
    yield put(actions.editDescriptionListComponentError(e));
  }
}

function* editDescriptionListComponentWatcher() {
  yield takeEvery(constants.EDIT_DESCRIPTION_LIST_COMPONENT, editDescriptionListComponent);
}

function* createDocumentComponent({ sectionId, fileData }) {
  try {
    yield put(actions.addComponentStart(sectionId));
    const result = yield call(request, API.CreateDocumentComponent(), 'POST', fileData, {
      timeout: 180000,
      ignoredErrorCodes: [403],
    });
    const newComponent = {
      ...result.data,
      isTitleEditing: true,
      sectionId,
    };

    const { content } = yield select(selectors.getPage);
    yield put(actions.updatePage(getUpdatedPageData(content, newComponent, 'createComponent')));
    yield put(actions.setLastCreatedComponent(result.data.id, result.data.type));
    yield put(actions.addComponentEnd(sectionId));
  } catch (e) {
    const errorMessage = e.response ? e.response.details.file[0] : defaultErrorMessage;
    yield put(actions.addComponentError(sectionId, errorMessage));
    yield handleForbiddenDealEdit(e);
  }
}

function* createDocumentComponentWatcher() {
  yield takeEvery(constants.CREATE_DOCUMENT_COMPONENT, createDocumentComponent);
}

function* editDocumentComponent({ setFileUploading, id, fileData, setError }) {
  if (setFileUploading) yield setFileUploading(true);

  try {
    const result = yield call(request, API.EditDocumentComponent(id), 'PUT', fileData, {
      timeout: 180000,
    });
    const { content } = yield select(selectors.getPage);

    yield put(
      actions.updatePage(
        getUpdatedPageData(
          content,
          { ...result.data, isTitleEditing: !fileData.title },
          'editComponent',
        ),
      ),
    ); // TODO: CHECK incorrect expression '!fileData.title' will be always 'true'
  } catch (e) {
    const errorMessage = e.response ? e.response.details.file[0] : defaultErrorMessage;
    yield setError(errorMessage);
  }

  if (setFileUploading) yield setFileUploading(false);
}

function* editDocumentComponentWatcher() {
  yield takeEvery(constants.EDIT_DOCUMENT_COMPONENT, editDocumentComponent);
}

function* editImageComponent({ id, fileData, setError, setIsLoading }) {
  setIsLoading(true);
  try {
    const result = yield call(request, API.EditImageComponent(id), 'PUT', fileData, {
      timeout: 180000,
    });

    yield put(actions.editImageComponentSuccess(result));
    const { content } = yield select(selectors.getPage);
    yield put(actions.updatePage(getUpdatedPageData(content, result.data, 'editComponent')));
  } catch (e) {
    const errorMessage = e.response ? e.response.details.file[0] : defaultErrorMessage;
    yield setError(errorMessage);
  }
  setIsLoading(false);
}

function* editImageComponentWatcher() {
  yield takeEvery(constants.EDIT_IMAGE_COMPONENT, editImageComponent);
}

function* createImageComponent({ sectionId, fileData }) {
  try {
    yield put(actions.addComponentStart(sectionId));
    const result = yield call(request, API.CreateImageComponent(), 'POST', fileData, {
      timeout: 180000,
      ignoredErrorCodes: [403],
    });
    const newComponent = {
      ...result.data,
      sectionId,
      isCreating: true,
    };

    yield put(actions.createImageComponentSuccess(result));
    const { content } = yield select(selectors.getPage);
    yield put(actions.updatePage(getUpdatedPageData(content, newComponent, 'createComponent')));
    yield put(actions.setLastCreatedComponent(result.data.id, result.data.type));
    yield put(actions.addComponentEnd(sectionId));
  } catch (e) {
    const errorMessage = e.response ? e.response.details.file[0] : defaultErrorMessage;
    yield put(actions.addComponentError(sectionId, errorMessage));
    yield handleForbiddenDealEdit(e);
  }
}

function* createImageComponentWatcher() {
  yield takeEvery(constants.CREATE_IMAGE_COMPONENT, createImageComponent);
}

function* editVideoComponent({ id, url }) {
  try {
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const result = yield call(
      request,
      API.EditVideoComponent(id),
      'PUT',
      { url, modelUuid },
      { timeout: 180000 },
    );

    yield put(actions.editVideoComponentSuccess(result));
    const { content } = yield select(selectors.getPage);
    yield put(actions.updatePage(getUpdatedPageData(content, result.data, 'editComponent')));
  } catch (e) {
    yield put(actions.editVideoComponentError(e));
  }
}

function* editVideoComponentWatcher() {
  yield takeEvery(constants.EDIT_VIDEO_COMPONENT, editVideoComponent);
}

function* createVideoComponent({ sectionId, url, temporaryVideoBoxId }) {
  try {
    yield put(actions.addComponentStart(sectionId));
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const result = yield call(
      request,
      API.CreateVideoComponent(),
      'POST',
      { sectionId, url, modelUuid },
      {
        timeout: 180000,
        ignoredErrorCodes: [403],
      },
    );
    const newComponent = {
      ...result.data,
      sectionId,
      isEditing: true,
    };
    const componentToDelete = {
      id: temporaryVideoBoxId,
      type: 'VideoBox',
    };

    yield put(actions.createVideoComponentSuccess(result));
    const { content: contentBeforeDeletion } = yield select(selectors.getPage);
    yield put(
      actions.updatePage(
        getUpdatedPageData(contentBeforeDeletion, componentToDelete, 'deleteComponent'),
      ),
    );
    const { content: contentAfterDeletion } = yield select(selectors.getPage);
    yield put(
      actions.updatePage(getUpdatedPageData(contentAfterDeletion, newComponent, 'createComponent')),
    );
    yield put(actions.addComponentEnd(sectionId));
  } catch (e) {
    yield put(actions.createVideoComponentError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createVideoComponentWatcher() {
  yield takeEvery(constants.CREATE_VIDEO_COMPONENT, createVideoComponent);
}

function* attachTeamMember({ boxId, memberIds, teamMemberId }) {
  try {
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const { data: component } = yield call(request, API.EditTeamComponent(boxId), 'PUT', {
      memberIds: [...memberIds, teamMemberId],
      modelUuid,
    });
    const { content } = yield select(selectors.getPage);
    const newTeamMember = { id: boxId, type: 'TeamBox', ...component };

    yield put(actions.updatePage(getUpdatedPageData(content, newTeamMember, 'editComponent')));
  } catch (e) {
    yield put(actions.attachTeamMemberError(e));
  }
}

function* attachTeamMemberWatcher() {
  yield takeEvery(constants.ATTACH_TEAM_MEMBER, attachTeamMember); // eslint-disable-line
}

function* createTeamMemberInOverviewSection({
  dealUuid,
  companyUuid,
  boxId,
  fileData,
  firstName,
  lastName,
  roles,
  description,
  linkedInUrl,
  memberIds,
  setSubmitting,
  setErrors,
}) {
  try {
    const {
      data: { token },
    } = yield call(request, API.BinaryUploads(), 'POST', fileData, {
      timeout: 180000,
      ignoredErrorCodes: [403],
    });
    const teamMemberParams = {
      firstName,
      lastName,
      roles,
      description,
      linkedInUrl,
      token,
    };
    const { data: teamMember } = yield call(
      request,
      API.CreateTeamMember(companyUuid, dealUuid),
      'POST',
      teamMemberParams,
    );
    setSubmitting(false);

    yield call(attachTeamMember, { boxId, memberIds, teamMemberId: teamMember.id });
    yield put(actions.hideModal());
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
    yield put(actions.createTeamMemberOverviewSectionError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createTeamMemberInOverviewSectionWatcher() {
  yield takeEvery(
    constants.CREATE_TEAM_MEMBER_IN_OVERVIEW_SECTION,
    createTeamMemberInOverviewSection,
  ); // eslint-disable-line
}

function* createTeamComponent(params) {
  try {
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const { data: component } = yield call(
      request,
      API.CreateTeamComponent(),
      'POST',
      {
        ...params,
        modelUuid,
      },
      {
        ignoredErrorCodes: [403],
      },
    );
    const { content } = yield select(selectors.getPage);
    const newComponent = {
      sectionId: params.sectionId,
      ...component,
    };

    yield put(actions.setLastCreatedComponent(component.id, component.type));
    yield put(actions.updatePage(getUpdatedPageData(content, newComponent, 'createComponent')));
  } catch (e) {
    yield put(actions.createTeamComponentError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createTeamComponentWatcher() {
  yield takeEvery(constants.CREATE_TEAM_COMPONENT, createTeamComponent); // eslint-disable-line
}

function* createTeamComponentInRegularSection({
  sectionId,
  dealUuid,
  fileData,
  firstName,
  lastName,
  roles,
  description,
  linkedInUrl,
  setSubmitting,
  companyUuid,
  changeTeamMemberFormVisibility,
  setErrors,
}) {
  try {
    const {
      data: { token },
    } = yield call(request, API.BinaryUploads(), 'POST', fileData, {
      timeout: 180000,
      ignoredErrorCodes: [403],
    });
    const teamMemberParams = {
      firstName,
      lastName,
      roles,
      description,
      linkedInUrl,
      token,
    };
    const { data: teamMember } = yield call(
      request,
      API.CreateTeamMember(companyUuid, dealUuid),
      'POST',
      teamMemberParams,
    );
    setSubmitting(false);
    changeTeamMemberFormVisibility(false);
    const teamBoxParams = { sectionId, memberIds: [`${teamMember.id}`] };

    yield call(createTeamComponent, teamBoxParams);
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
    yield put(actions.createTeamComponentInRegularSectionError(e));
    yield handleForbiddenDealEdit(e);
  }
}

function* createTeamComponentInRegularSectionWatcher() {
  yield takeEvery(
    constants.CREATE_TEAM_COMPONENT_IN_REGULAR_SECTION,
    createTeamComponentInRegularSection,
  ); // eslint-disable-line
}

function* editTeamMember({
  id,
  dealUuid,
  companyUuid,
  fileData,
  firstName,
  lastName,
  roles,
  description,
  linkedInUrl,
  changeIsEditingFlag,
  setSubmitting,
  setErrors,
}) {
  try {
    const {
      data: { token },
    } = yield fileData
      ? call(request, API.BinaryUploads(), 'POST', fileData, { timeout: 180000 })
      : { data: {} };
    const teamMemberParams = {
      firstName,
      lastName,
      roles,
      description,
      linkedInUrl,
      ...(token ? { token } : {}),
    };
    const { data: teamMember } = yield call(
      request,
      API.EditTeamMember(companyUuid, id, dealUuid),
      'PUT',
      teamMemberParams,
    );
    setSubmitting(false);
    const { content } = yield select(selectors.getPage);

    yield put(actions.updatePage(getUpdatedPageData(content, teamMember, 'editTeamMember')));
    changeIsEditingFlag(false);
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
    yield put(actions.editTeamMemberError(e));
  }
}

function* editTeamMemberWatcher() {
  yield takeEvery(constants.EDIT_TEAM_MEMBER, editTeamMember);
}

function* detachTeamMember({ dealUuid, companyUuid, teamMemberId, teamBoxId }) {
  try {
    yield call(request, API.DetachTeamMember(companyUuid, teamMemberId, dealUuid), 'POST', {
      teamBoxId,
    });
    const { content } = yield select(selectors.getPage);
    const params = { teamMemberId, teamBoxId };

    yield put(actions.updatePage(getUpdatedPageData(content, params, 'detachTeamMember')));
  } catch (e) {
    yield put(actions.detachTeamMemberError(e));
  }
}

function* detachTeamMemberWatcher() {
  yield takeEvery(constants.DETACH_TEAM_MEMBER, detachTeamMember);
}

function* reorderTeamMember({ boxId, memberIds }) {
  try {
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const params = { memberIds, modelUuid };
    const { data: component } = yield call(request, API.EditTeamComponent(boxId), 'PUT', params);
    const { content } = yield select(selectors.getPage);
    const teamComponentParams = { id: boxId, type: 'TeamBox', ...component };

    yield put(
      actions.updatePage(getUpdatedPageData(content, teamComponentParams, 'editComponent')),
    );
  } catch (e) {
    yield put(actions.reorderTeamMemberError(e));
  }
}

function* reorderTeamMemberWatcher() {
  yield takeEvery(constants.REORDER_TEAM_MEMBER, reorderTeamMember);
}

function* fetchTeamMembers({ payload: { companyUuid, dealUuid } }) {
  try {
    const { data } = yield call(request, API.FetchTeamMembers(companyUuid, dealUuid));

    yield put(actions.fetchTeamMembersSuccess(data));
  } catch (e) {
    yield put(actions.fetchTeamMembersError(e));
  }
}

function* fetchTeamMembersWatcher() {
  yield takeEvery(constants.FETCH_TEAM_MEMBERS, fetchTeamMembers);
}

function* deleteComponent({ componentId, componentType }) {
  try {
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    yield call(request, API.DeleteComponent(componentId, componentType), 'DELETE', { modelUuid });
    const componentToDelete = {
      id: componentId,
      type: componentType,
    };

    const { content } = yield select(selectors.getPage);
    yield put(
      actions.updatePage(getUpdatedPageData(content, componentToDelete, 'deleteComponent')),
    );
  } catch (e) {
    yield put(actions.deleteComponentError(e));
  }
}

function* deleteComponentWatcher() {
  yield takeEvery(constants.DELETE_COMPONENT, deleteComponent);
}

function* reorderComponent({ sectionBoxId, oldPosition, newPosition }) {
  try {
    const {
      data: { uuid: modelUuid },
    } = yield select(selectors.getDeal);
    const { data: component } = yield call(request, API.ReorderComponent(sectionBoxId), 'PUT', {
      position: newPosition,
      modelUuid,
    });
    const componentToReorder = {
      ...component,
      oldPosition,
      newPosition,
    };
    const { content } = yield select(selectors.getPage);

    yield put(
      actions.updatePage(getUpdatedPageData(content, componentToReorder, 'reorderComponent')),
    );
  } catch (e) {
    yield put(actions.reorderComponentError(e));
  }
}

function* reorderComponentWatcher() {
  yield takeEvery(constants.REORDER_COMPONENT, reorderComponent);
}

function* createIssuance({ company }) {
  try {
    const { data } = yield call(request, API.CreateIssuance(), 'POST', {
      companyUuid: company.uuid,
    });
    const params = { companyId: company.id, modelUuid: data.uuid };
    const dealSetupUrl = yield call(Role.has, 'Deal Setup URL', params);

    yield put(push(dealSetupUrl));
  } catch (e) {
    yield put(actions.createIssuanceError(e));
  }
}

function* createIssuanceWatcher() {
  yield takeEvery(constants.CREATE_ISSUANCE, createIssuance);
}

function* createDealInvites({ payload }) {
  const { dealUuid, companyUuid, values, setSubmitting, hideSideForm, perPage, handleRestriction } =
    payload;

  try {
    const { data } = yield call(request, API.DealInvites(companyUuid, dealUuid), 'POST', {
      companyUuid,
      ...values,
    });
    const invitesCount = data.invitesCount ?? 0;
    yield put(actions.createDealInvitesSuccess(data));
    yield delay(2000);
    yield hideSideForm();
    yield put(
      actions.showModal({
        component: SuccessModal,
        title: `${pluralize('Invite', invitesCount)} Sent`,
      }),
    );

    const { pagination } = yield select(selectors.getDealListMeta);
    const updatedTotalObjects = pagination.totalObjects + invitesCount;

    yield put(actions.resetDealList());
    yield put(
      actions.fetchDealListSuccess({
        data: {
          items: data.items,
          meta: {
            pagination: {
              ...pagination,
              totalObjects: updatedTotalObjects,
              totalPages: Math.ceil(updatedTotalObjects / perPage),
            },
          },
        },
        page: 1,
        perPage,
      }),
    );
  } catch (e) {
    yield put(actions.createDealInvitesError(e));
    yield setSubmitting(false);

    const messages = e?.response?.details?.invitedUsers || [];
    const restriction = messages
      .filter(error => typeof error === 'string')
      .map(
        message =>
          message.match(
            /The following investors cannot be invited as there are no users at the investor relationship agent who have access to the deal: (.*)/,
          ) || message.match(/The following investors do not meet the investment criteria: (.*)/),
      )
      .filter(Boolean)[0];

    if (restriction && handleRestriction) {
      return handleRestriction(restriction[0], restriction[1].split(', '));
    }

    const msg =
      e?.response?.details?.invitedUsers?.[0]?.[1]?.email?.[0] ||
      e?.response?.details?.invitedUsers?.[0];
    const message = typeof msg === 'string' ? msg : undefined;
    const title = message ? 'Invitation failed' : undefined;
    yield put(
      actions.showModal({
        component: ErrorModal,
        message,
        title,
      }),
    );
  }
}

function* createDealInvitesWatcher() {
  yield takeEvery(constants.CREATE_DEAL_INVITES, createDealInvites);
}

function* fetchDealInviteEmailTemplate({ payload: { dealUuid } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const { data } = yield call(request, API.DealInviteEmailTemplate(companyData.uuid, dealUuid));
    yield put(actions.fetchDealInviteEmailTemplateSuccess(data));
  } catch (e) {
    yield put(actions.fetchDealInviteEmailTemplateError(e));
  }
}

function* fetchDealInviteEmailTemplateWatcher() {
  yield takeEvery(constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE, fetchDealInviteEmailTemplate);
}

function* requestDealPublish({ payload: { companyUuid, dealUuid } }) {
  try {
    yield call(request, API.DealPublishRequest({ companyUuid, dealUuid }), 'POST');
    yield put(actions.requestDealPublishSuccess());
    yield put(actions.hideModal());
    yield put(actions.fetchDealSummaryData({ companyUuid, dealUuid }));
    yield call(toast.success, 'Request success');
  } catch (e) {
    yield put(actions.requestDealPublishError(e));
    yield call(toast.error, 'Request failed');
  }
}

function* requestDealPublishWatcher() {
  yield takeEvery(constants.REQUEST_DEAL_PUBLISH, requestDealPublish);
}

function* publishDeal({ payload: { companyUuid, dealUuid } }) {
  try {
    yield call(request, API.PublishDeal({ companyUuid, dealUuid }), 'PUT');
    yield put(actions.publishDealSuccess());
    yield put(actions.hideModal());
    yield put(actions.fetchDealSummaryData({ companyUuid, dealUuid }));
  } catch (e) {
    yield put(actions.publishDealError(e));
    yield put(
      actions.showModal({
        component: ErrorModal,
      }),
    );
  }
}

function* publishDealWatcher() {
  yield takeEvery(constants.PUBLISH_DEAL, publishDeal);
}

function* unpublishDeal({ payload: { companyUuid, dealUuid, onSuccess } }) {
  try {
    yield call(request, API.UnpublishDeal({ companyUuid, dealUuid }), 'PUT');
    yield put(actions.unpublishDealSuccess());
    yield put(actions.hideModal());
    yield put(actions.fetchDealSummaryData({ companyUuid, dealUuid }));
    if (onSuccess) {
      yield onSuccess();
    }
  } catch (e) {
    yield put(actions.unpublishDealError(e));
    yield put(
      actions.showModal({
        component: ErrorModal,
      }),
    );
  }
}

function* unpublishDealWatcher() {
  yield takeEvery(constants.UNPUBLISH_DEAL, unpublishDeal);
}

function* fetchDealOrders({ payload }) {
  try {
    const { data } = yield call(request, API.DealOrders(payload));

    yield put(actions.fetchDealOrdersSuccess(data));
  } catch (error) {
    yield put(actions.fetchDealOrdersError(error));
  }
}

function* fetchDealOrdersWatcher() {
  yield takeEvery(constants.FETCH_DEAL_ORDERS, fetchDealOrders);
}

function* fetchDealOrderDetails({ payload }) {
  try {
    const { data } = yield call(request, API.DealOrderDetails(payload));

    yield put(actions.fetchDealOrderDetailsSuccess(data));
  } catch (error) {
    yield put(actions.fetchDealOrderDetailsError(error));
  }
}

function* fetchDealOrderDetailsWatcher() {
  yield takeEvery(constants.FETCH_DEAL_ORDER_DETAILS, fetchDealOrderDetails);
}

function* fetchDealOrderHistory({ payload }) {
  const { uuid, page, perPage } = payload;

  try {
    const { data } = yield call(request, API.DealOrders({ uuid, page, perPage }));

    yield put(actions.fetchDealOrderHistorySuccess({ data, page, perPage }));
  } catch (error) {
    yield put(actions.fetchDealOrderHistoryError({ error }));
  }
}

function* fetchDealOrderHistoryWatcher() {
  yield takeEvery(constants.FETCH_DEAL_ORDER_HISTORY, fetchDealOrderHistory);
}

function* approveDealOrder({ payload: { companyUuid, dealUuid, orderUuid } }) {
  try {
    yield call(request, API.ApproveDealOrder({ companyUuid, dealUuid, orderUuid }), 'POST');

    yield put(actions.approveDealOrderSuccess());
    yield put(actions.fetchDealOrderDetails({ companyUuid, dealUuid, orderUuid }));

    const {
      order,
      pagination: { page, perPage },
    } = yield select(selectors.getDealOrdersMeta);
    yield put(
      actions.fetchDealOrders({
        companyUuid,
        dealUuid,
        page,
        perPage,
        order,
      }),
    );
  } catch (error) {
    yield put(actions.approveDealOrderError({ error }));

    if (error.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

function* approveDealOrderWatcher() {
  yield takeEvery(constants.APPROVE_DEAL_ORDER, approveDealOrder);
}

function* markAsFundsReceived({ payload: { companyUuid, dealUuid, orderUuid } }) {
  try {
    yield call(
      request,
      API.MarkAsFundsReceived({ companyUuid, dealUuid, orderUuid }),
      'POST',
      undefined,
      {
        requestConfigOverrides: { onForbid: () => {} }, // do not redirect - show 403 modal instead
      },
    );

    yield put(actions.fetchDealOrderDetails({ companyUuid, dealUuid, orderUuid }));

    const {
      order,
      pagination: { page, perPage },
    } = yield select(selectors.getDealOrdersMeta);
    yield put(
      actions.fetchDealOrders({
        companyUuid,
        dealUuid,
        page,
        perPage,
        order,
      }),
    );
  } catch (error) {
    yield put(actions.markAsFundsReceivedError({ error }));

    if (error.status === 403) {
      yield put(
        actions.showModal({
          title: `Error marking funds as received. Feature not enabled.`,
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }

    if (error.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
  yield put(actions.markAsFundsReceivedSuccess());
}

function* markAsFundsReceivedWatcher() {
  yield takeEvery(constants.MARK_AS_FUNDS_RECEIVED, markAsFundsReceived);
}

function* cancelDealOrder({ payload: { companyUuid, dealUuid, orderUuid, note } }) {
  try {
    yield call(request, API.CancelDealOrder({ companyUuid, dealUuid, orderUuid }), 'POST', {
      cancellationReason: note,
    });

    yield put(actions.cancelDealOrderSuccess());
    yield put(actions.fetchDealOrderDetails({ companyUuid, dealUuid, orderUuid }));

    const {
      order,
      pagination: { page, perPage },
    } = yield select(selectors.getDealOrdersMeta);
    yield put(
      actions.fetchDealOrders({
        companyUuid,
        dealUuid,
        page,
        perPage,
        order,
      }),
    );
  } catch (error) {
    yield put(actions.cancelDealOrderError({ error }));

    if (error.status === 400) {
      yield put(
        actions.showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

function* cancelDealOrderWatcher() {
  yield takeEvery(constants.CANCEL_DEAL_ORDER, cancelDealOrder);
}

function* completeDeal({ payload: { companyUuid, dealUuid } }) {
  try {
    yield call(request, API.CompleteDeal({ companyUuid, dealUuid }), 'PUT');

    yield put(actions.completeDealSuccess());
    yield put(
      actions.showModal({
        closable: true,
        component: SuccessModal,
        buttonText: 'OK',
        title: 'Complete Deal Request Has Been Sent',
      }),
    );
  } catch (error) {
    yield put(actions.completeDealError({ error }));
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* completeDealWatcher() {
  yield takeEvery(constants.COMPLETE_DEAL, completeDeal);
}

function* fetchDealSettlements({ payload }) {
  try {
    const { data } = yield call(request, API.DealSettlements(payload));

    yield put(actions.fetchDealSettlementsSuccess(data));
  } catch (e) {
    yield put(actions.fetchDealSettlementsError(e));
  }
}

function* fetchDealSettlementsWatcher() {
  yield takeEvery(constants.FETCH_DEAL_SETTLEMENTS, fetchDealSettlements);
}

function* editOrderMoneyTransferred({
  payload: { companyUuid, dealUuid, orderUuid, investorUserName },
}) {
  try {
    yield call(
      request,
      API.DealOrderMoneyTransferred({ companyUuid, dealUuid, orderUuid }),
      'POST',
    );

    yield put(actions.editOrderMoneyTransferredSuccess());
    yield put(
      actions.showModal({
        closable: true,
        component: SuccessModal,
        buttonText: 'OK',
        title: 'Securities issued successfully',
        additionalText: `${investorUserName} will receive an email about this action.`,
      }),
    );

    const { pagination, order } = yield select(getDealSettlementsMeta);
    yield put(actions.fetchDealSettlements({ companyUuid, dealUuid, ...pagination, order }));
  } catch (e) {
    yield put(actions.editOrderMoneyTransferredError(e));
  }
}

function* editOrderMoneyTransferredWatcher() {
  yield takeEvery(constants.EDIT_ORDER_MONEY_TRANSFERRED, editOrderMoneyTransferred);
}

// eslint-disable-next-line import/no-unused-modules
export {
  fetchTheDealPagesData,
  fetchDealPagesDataWatcher,
  fetchThePage,
  fetchPageWatcher,
  fetchDealSummary,
  fetchDealSummaryWatcher,
  fetchDealInfo,
  fetchDealInfoWatcher,
  fetchDealList,
  fetchDealListWatcher,
  fetchInvestorInfo,
  fetchInvestorInfoWatcher,
  resendInvestorInvite,
  resendInvestorInviteWatcher,
  fetchIssuance,
  fetchIssuanceWatcher,
  createSection,
  createSectionWatcher,
  editSectionTitle,
  editSectionTitleWatcher,
  deleteSection,
  deleteSectionWatcher,
  reorderSections,
  reorderSectionsWatcher,
  createTextComponent,
  createTextComponentWatcher,
  editTextComponent,
  editTextComponentWatcher,
  createTableComponent,
  createTableComponentWatcher,
  editTableComponent,
  editTableComponentWatcher,
  createDescriptionListComponent,
  createDescriptionListComponentWatcher,
  editDescriptionListComponent,
  editDescriptionListComponentWatcher,
  createDocumentComponent,
  createDocumentComponentWatcher,
  editDocumentComponent,
  editDocumentComponentWatcher,
  editImageComponent,
  editImageComponentWatcher,
  createImageComponent,
  createImageComponentWatcher,
  fetchDeal,
  uploadDealDocumentWatcher,
  fetchDealWatcher,
  updateDealPageHeader,
  updateDealPageHeaderWatcher,
  updateDealData,
  updateDealDataWatcher,
  editVideoComponent,
  editVideoComponentWatcher,
  fetchInvestorDocumentsWatcher,
  createVideoComponent,
  createVideoComponentWatcher,
  deleteComponent,
  deleteComponentWatcher,
  reorderComponent,
  reorderComponentWatcher,
  createTeamComponentInRegularSection,
  createTeamComponentInRegularSectionWatcher,
  editTeamMember,
  editTeamMemberWatcher,
  createTeamMemberInOverviewSection,
  createTeamMemberInOverviewSectionWatcher,
  detachTeamMember,
  detachTeamMemberWatcher,
  fetchTeamMembers,
  fetchTeamMembersWatcher,
  createTeamComponent,
  createTeamComponentWatcher,
  attachTeamMember,
  attachTeamMemberWatcher,
  reorderTeamMember,
  reorderTeamMemberWatcher,
  createIssuance,
  createIssuanceWatcher,
  createDealInvites,
  createDealInvitesWatcher,
  fetchDealDataWatcher,
  fetchDealDocumentsDataWatcher,
  editDealStructureWatcher,
  fetchDealInviteEmailTemplate,
  changeDocumentAgreedStatusWatcher,
  fetchDealInviteEmailTemplateWatcher,
  resendIPInvestorInviteWatcher,
  uploadInvestorDocumentWatcher,
  deleteInvestorDocumentWatcher,
  requestDealPublish,
  requestDealPublishWatcher,
  publishDeal,
  publishDealWatcher,
  unpublishDeal,
  unpublishDealWatcher,
  editDealConfigurationWatcher,
  fetchDealOrdersWatcher,
  fetchDealOrderDetailsWatcher,
  fetchDealOrderHistoryWatcher,
  approveDealOrder,
  approveDealOrderWatcher,
  markAsFundsReceived,
  markAsFundsReceivedWatcher,
  cancelDealOrder,
  cancelDealOrderWatcher,
  completeDeal,
  completeDealWatcher,
  createDealDetails,
  createDealDetailsWatcher,
  editDealDetails,
  editDealDetailsWatcher,
  fetchDealSettlements,
  fetchDealSettlementsWatcher,
  editOrderMoneyTransferred,
  editOrderMoneyTransferredWatcher,
};
