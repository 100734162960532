import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

import Button from './formik-button';

const commonStyles = `
  content: '';
  display: inline-block;
  height: 12px;
  vertical-align: top;
  width: 7.5px;
`;

const FormButton = styled(Button)`
  height: 40px;
  margin: 52px 0 12px 0;
`;

const NextFormButton = styled(FormButton)`
  &:after {
    ${commonStyles}
    margin-left: 6px;
  }
`;

const BackFormButton = styled(FormButton)`
  background: ${COLORS.white};
  color: ${COLORS.alternative};
  border: 1px solid ${COLORS.alternative};

  &:before {
    ${commonStyles}
    margin-right: 6px;
  }
  &:hover {
    &:before {
      ${commonStyles}
    }
  }
  &:disabled {
    background: ${COLORS.neutral800};
    border: 1px solid transparent;
    &:before {
      ${commonStyles}
    }
    &:hover {
      background: ${COLORS.neutral800};
      border: 1px solid transparent;
      &:before {
        ${commonStyles}
      }
    }
  }
`;

export { FormButton, NextFormButton, BackFormButton };
