import { COLORS } from 'shared-parts/constants';

const CheckMarkIcon = (width = '8.415', height = '5.641', color = COLORS.success) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8.415 5.641">
    <path
      fill={color}
      d="M8.006,12.221,5,9.215l.848-.848L8.006,10.52l4.562-3.94.848.854Z"
      transform="translate(-5 -6.58)"
    />
  </svg>
);

export default CheckMarkIcon;
