import { getTenantConfig } from 'helpers/get-tenant-config';

import allCountries from './all-countries';
import tableColumnStyles from './table-column-styles';
import validation from './validation';

export const { colors: COLORS, fonts: FONTS } = getTenantConfig();
export * from './screens';
export * from './dates';
export * from './error-messages';
export * from './internal';

export { allCountries, tableColumnStyles, validation };
