import { COLORS } from 'shared-parts/constants';

const TextIcon = (fillColor = COLORS.highlight, height = 30, width = 30) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={`${height}px`}
    width={`${width}px`}
  >
    <style>{`.icon { fill: ${fillColor} }`}</style>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="UI-Style-Guide">
          <g id="Group_3777" data-name="Group 3777">
            <rect
              id="Rectangle_2314"
              data-name="Rectangle 2314"
              className="icon"
              width="40"
              height="40"
              rx="2"
            />
            <g id="Group_3775" data-name="Group 3775">
              <path
                id="Path_1558"
                fill="#eeeff3"
                d="M20.8,22.88h-5l-.54,1.44a2,2,0,0,0-.36,1.26,1,1,0,0,0,.54.9,6.83,6.83,0,0,0,1.26.18V27H12v-.36a1.85,1.85,0,0,0,1.26-.72,9.92,9.92,0,0,0,1.26-2.16l5-11.32h.18l5.21,11.68A9.92,9.92,0,0,0,26.2,26.3a1.41,1.41,0,0,0,.9.36V27H20.27v-.36h.36a2.71,2.71,0,0,0,1.26-.18.71.71,0,0,0,.18-.54v-.36l-.36-.72Zm-.36-.72-2.15-4.85-2.16,4.85Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TextIcon;
