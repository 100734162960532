import {
  GET_COMPANY_ENTITIES,
  GET_COMPANY_ENTITIES_ERROR,
  GET_COMPANY_ENTITIES_SUCCESS,
} from 'app-state/company-entities/constants';
import type {
  CompanyEntitiesActionTypes,
  CompanyEntitiesState,
} from 'app-state/company-entities/types';

const defaultCompanyEntitiesState: CompanyEntitiesState = {
  loading: false,
  data: [],
  error: null,
};

export const companyEntitiesReducer = (
  state = defaultCompanyEntitiesState,
  action: CompanyEntitiesActionTypes,
): CompanyEntitiesState => {
  switch (action.type) {
    case GET_COMPANY_ENTITIES:
      return { ...state, loading: true };
    case GET_COMPANY_ENTITIES_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case GET_COMPANY_ENTITIES_ERROR:
      return { ...defaultCompanyEntitiesState, error: action.payload };
    default:
      return state;
  }
};
