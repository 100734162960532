import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import { Typography, useTheme } from '@frontend/design-system';
import { useRequest } from '@frontend/http';
import { KycKybStatus } from '@frontend/kyc-kyb';

import { showModal } from 'app-state/actions';

import api from 'constants/api';
import { downloadBlob } from 'helpers';
import { InvestorKYCSection } from 'shared/investor-kyc-info';
import FailedModal from 'shared-parts/components/modal-failed/modal-failed';
import DownloadArrowIcon from 'shared-parts/components/svg-icons/download-arrow-icon';
import { COLORS } from 'shared-parts/constants';

import { DownloadButton } from './styled';

type KYCKYBReportDownloadProps = {
  investorUuid?: string;
  onboardingStatus?: KycKybStatus;
  companyUuid?: string;
  kycProvider?: string;
};

const getReportText = (kycProvider?: string, onboardingStatus?: KycKybStatus) => {
  if (kycProvider === 'sumsub') {
    return 'The KYC/KYB assessment report is unavailable to be downloaded.';
  }
  switch (onboardingStatus) {
    case 'pending':
      return 'You can only download the assessment report once the KYC/KYB result has been finalized.';
    case 'user_responding':
      return 'You can only download the assessment report once the KYC/KYB result has been finalized.';
    case 'in_progress':
      return 'You can only download the assessment report once the KYC/KYB result has been finalized.';
    case 'completed':
      return 'Click to download the finalized KYC/KYB assessment report.';
    case 'failed':
      return 'Click to download the finalized KYC/KYB assessment report.';
    default:
      return 'The KYC/KYB assessment report is unavailable to be downloaded.';
  }
};

const KYCKYBReportDownload: FC<React.PropsWithChildren<KYCKYBReportDownloadProps>> = ({
  investorUuid,
  onboardingStatus,
  companyUuid,
  kycProvider,
}) => {
  const dispatch = useDispatch();
  const { dealUuid } = useParams<{ dealUuid: string }>();

  const { data, refetch: fetchReport } = useRequest(
    api.DownloadAmlReport(companyUuid, dealUuid, investorUuid),
    {
      enabled: false,
      onError: () => {
        dispatch(
          showModal({
            closable: true,
            component: FailedModal,
          }),
        );
      },
    },
    {
      to: 'blob',
    },
  );

  useEffect(() => {
    if (data) {
      downloadBlob(data, `KYC-KYB-Report.pdf`);
    }
  }, [data]);

  const isDownloadReportEnabled =
    kycProvider === 'muinmos' && ['failed', 'completed'].includes(String(onboardingStatus));

  const theme = useTheme();
  return (
    <Stack>
      <InvestorKYCSection onboardingStatus={onboardingStatus} />
      <Stack px="20px" py="30px">
        <Typography py="10px" fontSize="14px" fontWeight="bold" color={COLORS.primary}>
          Download KYC/KYB assessment report
        </Typography>
        <Typography fontSize="14px" py="10px">
          {getReportText(kycProvider, onboardingStatus)}
        </Typography>
      </Stack>
      <Stack maxWidth="220px" mt="20px">
        <DownloadButton
          onClick={() => fetchReport()}
          disabled={!isDownloadReportEnabled}
          style={
            !isDownloadReportEnabled
              ? {
                  color: theme.palette.neutral[300],
                  backgroundColor: theme.palette.neutral[100],
                }
              : {}
          }
        >
          <DownloadArrowIcon
            fill={isDownloadReportEnabled ? COLORS.white : theme.palette.neutral[300]}
          />
          <Typography px="10px"> Download Report</Typography>
        </DownloadButton>
      </Stack>
    </Stack>
  );
};

export default KYCKYBReportDownload;
