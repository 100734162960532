import { EnvironmentType, useAppConfig } from 'providers/app-config';
import StandardButton from 'shared-parts/components/button/standard-button';
import { defaultErrorMessage } from 'shared-parts/constants';

import {
  ButtonWrapper,
  ErrorMessage,
  Heading,
  MessageArea,
  PleaseRefreshMessage,
  RedInfoIcon,
  Wrapper,
} from './index.styled';

const reloadPage = () => {
  location.reload();
};

interface Props {
  machineCode: string;
}

const SomethingWentWrong = ({ machineCode }: Props): JSX.Element => {
  const { environment } = useAppConfig();
  const shouldShowErrorReason = environment === EnvironmentType.LOCAL;
  return (
    <Wrapper>
      <MessageArea>
        <RedInfoIcon />
        <Heading>Something Went Wrong</Heading>
        {shouldShowErrorReason ? (
          <>
            <ErrorMessage>{machineCode}</ErrorMessage>
            <PleaseRefreshMessage>Please refresh and try again</PleaseRefreshMessage>{' '}
          </>
        ) : (
          defaultErrorMessage
        )}

        <ButtonWrapper>
          <StandardButton onClick={reloadPage}>Refresh</StandardButton>
        </ButtonWrapper>
      </MessageArea>
    </Wrapper>
  );
};

export default SomethingWentWrong;
