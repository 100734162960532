import { string, StringSchema, TestFunction, ValidationError } from 'yup';

const atLeastXLowercaseLetters = (atLeast: number) => new RegExp(`(.*[a-z]){${atLeast},}`); // At least x lowercase letter.
const atLeastXUppercaseLetters = (atLeast: number) => new RegExp(`(.*[A-Z]){${atLeast},}`); // At least x uppercase letter.
const atLeastXNumericChar = (atLeast: number) => new RegExp(`(.*\\d){${atLeast},}`, 'g'); // At least x numeric character.
const atLeastXSpecialChar = (atLeast: number) => new RegExp(`(.*[^a-zA-Z\\d\\s]){${atLeast},}`); // At least x special character. Anything that's not alphanumeric or a space.

const pluralize = (word: string, quantity: number) => `${quantity === 1 ? word : `${word}s`}`;

export const createPatternSchema = (quantity: 1 | 3) => {
  const num = quantity === 1 ? 'one' : 'three';
  return string()
    .matches(atLeastXLowercaseLetters(quantity), {
      message: `Password must contain at least ${num} lowercase ${pluralize('letter', quantity)}`,
    })
    .matches(atLeastXUppercaseLetters(quantity), {
      message: `Password must contain at least ${num} uppercase ${pluralize('letter', quantity)}`,
    })
    .matches(atLeastXNumericChar(quantity), {
      message: `Password must contain at least ${num} numeric ${pluralize('character', quantity)}`,
    })
    .matches(atLeastXSpecialChar(quantity), {
      message: `Password must contain at least ${num} special ${pluralize('character', quantity)}`,
    });
};

export const testPattern = (
  minLength: number,
  schema: StringSchema,
): TestFunction<string | undefined | null, unknown> =>
  function (value): ValidationError | boolean {
    if (value && value.length >= minLength) {
      try {
        schema.validateSync(value);
      } catch (e: unknown) {
        if (e instanceof ValidationError) {
          return this.createError({ message: e.message });
        }
        return false;
      }
    }
    return true;
  };
