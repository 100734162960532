import { FC } from 'react';

import { useEnvVar } from '@frontend/config';

import { Container } from 'shared-parts/components';

import {
  ContainerWrapper,
  Link,
  LinkContainer,
  Navbar,
} from './auth-container-navigation-back.styled';

const tenantWebsiteUrl = useEnvVar('TENANT_WEBSITE_URL') as string;
const platformName = useEnvVar('PLATFORM_NAME');

export const AuthContainerBackNavigation: FC<React.PropsWithChildren<any>> = () => (
  <ContainerWrapper>
    <Container>
      <Navbar>
        <LinkContainer>
          <Link target="_blank" href={tenantWebsiteUrl} rel="noreferrer">
            Return to {platformName} Site
          </Link>
        </LinkContainer>
      </Navbar>
    </Container>
  </ContainerWrapper>
);
