import {
  ADD_TRANSACTION_NOTE,
  ADD_TRANSACTION_NOTE_ERROR,
  ADD_TRANSACTION_NOTE_SUCCESS,
  ALLOCATE_SHAREHOLDING,
  ALLOCATE_SHAREHOLDING_ERROR,
  CREATE_SHAREHOLDER,
  CREATE_SHAREHOLDER_ERROR,
  CREATE_SHAREHOLDER_SUCCESS,
  DOWNLOAD_SHAREHOLDER_CERT,
  DOWNLOAD_SHAREHOLDER_REGISTRY,
  EDIT_SHAREHOLDER,
  EDIT_SHAREHOLDER_ERROR,
  EDIT_SHAREHOLDER_PERFORMANCE,
  EDIT_SHAREHOLDER_PERFORMANCE_ERROR,
  EDIT_SHAREHOLDER_PERFORMANCE_SUCCESS,
  EDIT_SHAREHOLDER_SUCCESS,
  GET_BULK_WELCOME_EMAIL_INFO,
  GET_HOLDINGS,
  GET_HOLDINGS_ERROR,
  GET_HOLDINGS_SUCCESS,
  GET_REGISTERED_SHAREHOLDERS,
  GET_REGISTERED_SHAREHOLDERS_ERROR,
  GET_REGISTERED_SHAREHOLDERS_SUCCESS,
  GET_SHAREHOLDERS,
  GET_SHAREHOLDERS_DROPDOWN_DATA,
  GET_SHAREHOLDERS_DROPDOWN_DATA_ERROR,
  GET_SHAREHOLDERS_DROPDOWN_DATA_SUCCESS,
  GET_SHAREHOLDERS_ERROR,
  GET_SHAREHOLDERS_SUCCESS,
  GET_TRANSFER_SHAREHOLDERS,
  GET_TRANSFER_SHAREHOLDERS_ERROR,
  GET_TRANSFER_SHAREHOLDERS_SUCCESS,
  LOOKUP_SHAREHOLDER,
  LOOKUP_SHAREHOLDER_ERROR,
  LOOKUP_SHAREHOLDER_SUCCESS,
  REMOVE_TRANSACTION_NOTE,
  REMOVE_TRANSACTION_NOTE_ERROR,
  REMOVE_TRANSACTION_NOTE_SUCCESS,
  RESET_CURRENT_SHAREHOLDER_TRANSACTION,
  RESET_HOLDINGS,
  RESET_LOOKUP_SHAREHOLDER,
  RESET_REGISTERED_SHAREHOLDERS,
  RESET_SHAREHOLDERS,
  RESET_SHAREHOLDERS_DROPDOWN_DATA,
  RESET_SHAREHOLDERS_SEARCH,
  SAVE_CURRENT_SHAREHOLDER_TRANSACTION,
  SEARCH_SHAREHOLDERS,
  SEARCH_SHAREHOLDERS_ERROR,
  SEARCH_SHAREHOLDERS_SUCCESS,
  SEND_BULK_WELCOME_EMAIL,
  SHAREHOLDER_TRANSACTION_REVERT,
  SHAREHOLDER_TRANSACTION_REVERT_ERROR,
  TRANSFER_SHAREHOLDING,
  TRANSFER_SHAREHOLDING_ERROR,
  UPDATE_SHAREHOLDER_TRANSACTIONS,
  UPDATE_SHAREHOLDER_TRANSACTIONS_ERROR,
  UPDATE_SHAREHOLDER_TRANSACTIONS_SUCCESS,
  UPDATE_TRANSACTION_NOTE,
  UPDATE_TRANSACTION_NOTE_ERROR,
  UPDATE_TRANSACTION_NOTE_SUCCESS,
} from 'app-state/constants/shareholders';

export const getShareholdersDropdownData = params => ({
  type: GET_SHAREHOLDERS_DROPDOWN_DATA,
  ...params,
});
export const getShareholdersDropdownDataSuccess = (data, shouldBeRefreshed) => ({
  type: GET_SHAREHOLDERS_DROPDOWN_DATA_SUCCESS,
  data,
  shouldBeRefreshed,
});
export const getShareholdersDropdownDataError = error => ({
  type: GET_SHAREHOLDERS_DROPDOWN_DATA_ERROR,
  error,
});
export const resetShareholdersDropdownData = () => ({ type: RESET_SHAREHOLDERS_DROPDOWN_DATA });

export const getHoldings = (companyId, shareholderId) => ({
  type: GET_HOLDINGS,
  companyId,
  shareholderId,
});
export const getHoldingsSuccess = data => ({ type: GET_HOLDINGS_SUCCESS, data });
export const getHoldingsError = error => ({ type: GET_HOLDINGS_ERROR, error });
export const resetHoldings = () => ({ type: RESET_HOLDINGS });

export const downloadShareholderCert = payload => ({
  type: DOWNLOAD_SHAREHOLDER_CERT,
  payload,
});

export const shareholderCreate = payload => ({
  type: CREATE_SHAREHOLDER,
  payload,
});
export const shareholderCreateSuccess = data => ({ type: CREATE_SHAREHOLDER_SUCCESS, data });
export const shareholderCreateError = error => ({ type: CREATE_SHAREHOLDER_ERROR, error });

export const shareholderEdit = payload => ({
  type: EDIT_SHAREHOLDER,
  payload,
});
export const shareholderEditSuccess = data => ({ type: EDIT_SHAREHOLDER_SUCCESS, data });
export const shareholderEditError = error => ({ type: EDIT_SHAREHOLDER_ERROR, error });

export const getShareholders = params => ({ type: GET_SHAREHOLDERS, params });
export const getShareholdersSuccess = (data, shouldBeRefreshed, pageSize) => ({
  type: GET_SHAREHOLDERS_SUCCESS,
  data,
  shouldBeRefreshed,
  pageSize,
});
export const getShareholdersError = error => ({ type: GET_SHAREHOLDERS_ERROR, error });
export const resetShareholders = () => ({ type: RESET_SHAREHOLDERS });

export const getRegisteredShareholders = ({ companyUuid }) => ({
  type: GET_REGISTERED_SHAREHOLDERS,
  companyUuid,
});
export const getRegisteredShareholdersSuccess = data => ({
  type: GET_REGISTERED_SHAREHOLDERS_SUCCESS,
  data,
});
export const getRegisteredShareholdersError = error => ({
  type: GET_REGISTERED_SHAREHOLDERS_ERROR,
  error,
});
export const resetRegisteredShareholders = () => ({ type: RESET_REGISTERED_SHAREHOLDERS });

export const getTransferShareholders = params => ({ type: GET_TRANSFER_SHAREHOLDERS, ...params });
export const getTransferShareholdersSuccess = data => ({
  type: GET_TRANSFER_SHAREHOLDERS_SUCCESS,
  data,
});
export const getTransferShareholdersError = error => ({
  type: GET_TRANSFER_SHAREHOLDERS_ERROR,
  error,
});

export const downloadShareholderRegistry = (companyId, date) => ({
  type: DOWNLOAD_SHAREHOLDER_REGISTRY,
  companyId,
  date,
});

export const updateShareholderTransactions = (
  shareholderId,
  transactionId,
  updateTransactionUrl,
) => ({
  type: UPDATE_SHAREHOLDER_TRANSACTIONS,
  shareholderId,
  transactionId,
  updateTransactionUrl,
});
export const updateShareholderTransactionsSuccess = (shareholderId, data) => ({
  type: UPDATE_SHAREHOLDER_TRANSACTIONS_SUCCESS,
  shareholderId,
  data,
});
export const updateShareholderTransactionsError = error => ({
  type: UPDATE_SHAREHOLDER_TRANSACTIONS_ERROR,
  error,
});

export const allocateShareholding = payload => ({ type: ALLOCATE_SHAREHOLDING, payload });
export const allocateShareholdingError = error => ({ type: ALLOCATE_SHAREHOLDING_ERROR, error });

export const transferShareholding = (data, toggleFormVisibility) => ({
  type: TRANSFER_SHAREHOLDING,
  data,
  toggleFormVisibility,
});
export const transferShareholdingError = error => ({ type: TRANSFER_SHAREHOLDING_ERROR, error });

export const shareholderTransactionRevert = (
  companyId,
  shareholderId,
  transactionId,
  updateTransactionUrl, // eslint-disable-line
) => ({
  type: SHAREHOLDER_TRANSACTION_REVERT,
  companyId,
  shareholderId,
  transactionId,
  updateTransactionUrl,
}); // eslint-disable-line
export const shareholderTransactionRevertError = error => ({
  type: SHAREHOLDER_TRANSACTION_REVERT_ERROR,
  error,
});

export const getBulkWelcomeEmailInfo = companyId => ({
  type: GET_BULK_WELCOME_EMAIL_INFO,
  companyId,
});

export const sendBulkWelcomeEmail = companyId => ({ type: SEND_BULK_WELCOME_EMAIL, companyId });

export const lookupShareholder = params => ({ type: LOOKUP_SHAREHOLDER, ...params });
export const lookupShareholderSuccess = data => ({ type: LOOKUP_SHAREHOLDER_SUCCESS, data });
export const lookupShareholderError = error => ({ type: LOOKUP_SHAREHOLDER_ERROR, error });
export const resetShareholderLookup = () => ({ type: RESET_LOOKUP_SHAREHOLDER });

export const saveCurrentShareholderTransaction = transaction => ({
  type: SAVE_CURRENT_SHAREHOLDER_TRANSACTION,
  data: transaction,
});
export const resetCurrentShareholderTransaction = () => ({
  type: RESET_CURRENT_SHAREHOLDER_TRANSACTION,
});

export const addTransactionNote = params => ({ type: ADD_TRANSACTION_NOTE, ...params });
export const addTransactionNoteSuccess = data => ({ type: ADD_TRANSACTION_NOTE_SUCCESS, data });
export const addTransactionNoteError = error => ({ type: ADD_TRANSACTION_NOTE_ERROR, error });

export const updateTransactionNote = params => ({ type: UPDATE_TRANSACTION_NOTE, ...params });
export const updateTransactionNoteSuccess = data => ({
  type: UPDATE_TRANSACTION_NOTE_SUCCESS,
  data,
});
export const updateTransactionNoteError = error => ({ type: UPDATE_TRANSACTION_NOTE_ERROR, error });

export const removeTransactionNote = params => ({ type: REMOVE_TRANSACTION_NOTE, ...params });
export const removeTransactionNoteSuccess = data => ({
  type: REMOVE_TRANSACTION_NOTE_SUCCESS,
  data,
});
export const removeTransactionNoteError = error => ({ type: REMOVE_TRANSACTION_NOTE_ERROR, error });

export const searchShareholders = payload => ({ type: SEARCH_SHAREHOLDERS, payload });
export const searchShareholdersSuccess = payload => ({
  type: SEARCH_SHAREHOLDERS_SUCCESS,
  payload,
});
export const searchShareholdersError = payload => ({ type: SEARCH_SHAREHOLDERS_ERROR, payload });
export const resetShareholdersSearch = () => ({ type: RESET_SHAREHOLDERS_SEARCH });

export const editShareholderPerformance = payload => ({
  type: EDIT_SHAREHOLDER_PERFORMANCE,
  payload,
});
export const editShareholderPerformanceSuccess = data => ({
  type: EDIT_SHAREHOLDER_PERFORMANCE_SUCCESS,
  data,
});
export const editShareholderPerformanceError = error => ({
  type: EDIT_SHAREHOLDER_PERFORMANCE_ERROR,
  error,
});
