import GearIcon from 'svg-icons/gear-icon';
import { dashed } from 'shared-parts/helpers/format-string';

import { StyledButton } from './labeled-button.styled';
import type { LabeledButtonComponent } from './types';

const LabeledButton: LabeledButtonComponent = ({
  type,
  onClick,
  text,
  icon: Icon = GearIcon,
  disabled,
  fillColor,
}) => (
  <StyledButton
    disabled={disabled}
    type={type}
    onClick={onClick}
    data-e2e={`${dashed(text)}-button`}
  >
    <Icon fillColor={fillColor} />
    {text}
  </StyledButton>
);

export default LabeledButton;
