export default (allItems, item, isMovingDown) => {
  const itemIndex = item.position - 1;
  const affectedItemIndex = isMovingDown ? itemIndex + 1 : itemIndex - 1;
  const isMovingFirstElementUp = isMovingDown && itemIndex === allItems.length - 1;
  const isMovingLastElementDown = !isMovingDown && itemIndex === 0;

  if (isMovingFirstElementUp || isMovingLastElementDown) {
    return allItems;
  }

  const affectedItem = allItems[affectedItemIndex];

  return isMovingDown
    ? [
        ...allItems.slice(0, itemIndex),
        { ...affectedItem, position: item.position },
        { ...item, position: affectedItem.position },
        ...allItems.slice(itemIndex + 2),
      ]
    : [
        ...allItems.slice(0, affectedItemIndex),
        { ...item, position: affectedItem.position },
        { ...affectedItem, position: item.position },
        ...allItems.slice(affectedItemIndex + 2),
      ];
};
