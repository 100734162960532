import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './uploadCSV.constants';
import initialState, { JobStatus, UploadCSVJobsState } from './uploadCSV.state';

export const uploadCSVReducer = (state = initialState, action: AnyAction): UploadCSVJobsState => {
  return produce(state, draft => {
    const uploadKey = (action?.uploadKey || action?.payload?.uploadKey) as
      | keyof typeof draft
      | keyof typeof initialState;
    switch (action.type) {
      case constants.resetUploadCSV:
        draft[uploadKey] = { ...initialState[uploadKey] };
        break;
      case constants.sendUploadTransactionsRequest.REQUEST:
      case constants.sendUploadConvertibleNotesRequest.REQUEST:
      case constants.sendUploadShareholdersRequest.REQUEST:
      case constants.sendUploadBondsAndLoansRequest.REQUEST:
        draft[uploadKey].loading = true;
        draft[uploadKey].data!.status = JobStatus.Processing;
        draft[uploadKey].error = initialState[uploadKey].error;
        draft[uploadKey].data = initialState[uploadKey].data;
        break;
      case constants.sendUploadConvertibleNotesRequest.SUCCESS:
      case constants.sendUploadShareholdersRequest.SUCCESS:
      case constants.sendUploadTransactionsRequest.SUCCESS:
      case constants.sendUploadBondsAndLoansRequest.SUCCESS:
        draft[uploadKey].data!.status = JobStatus.Completed;
        draft[uploadKey].error = initialState[uploadKey].error;
        draft[uploadKey].data = action.data;
        break;
      case constants.sendUploadConvertibleNotesRequest.FAILURE:
      case constants.sendUploadShareholdersRequest.FAILURE:
      case constants.sendUploadTransactionsRequest.FAILURE:
      case constants.sendUploadBondsAndLoansRequest.FAILURE:
        draft[uploadKey].loading = false;
        draft[uploadKey].data!.status = JobStatus.Failed;
        draft[uploadKey].error = action.error;
        break;
      case constants.checkJobProgress.SUCCESS:
        draft[uploadKey].error = initialState[uploadKey].error;
        draft[uploadKey].loading = false;
        draft[uploadKey].data = action.data;
        break;
      case constants.checkJobProgress.FAILURE:
        draft[uploadKey].loading = false;
        draft[uploadKey].data!.status = JobStatus.Failed;
        draft[uploadKey].error = action.error;
        break;
      default:
        break;
    }
  });
};
