import { formatDate, LDF } from '@frontend/datetime';

import { addCurrencySign, toDefinedFractionSizeNoRound, valueOrHyphen } from 'shared-parts/helpers';

export const WALLET_TRANSACTIONS_TABLE_TITLE = 'TRANSACTIONS';

export const WALLET_TRANSACTIONS_HEADER = [{ name: 'Amount' }, { name: 'Date' }, { name: 'Time' }];

export const WALLET_TRANSACTIONS_KEYS = [
  {
    field: 'amount',
    transform: (amount, { wallet }) =>
      valueOrHyphen(
        amount,
        addCurrencySign(toDefinedFractionSizeNoRound(amount, 2), wallet.currency),
      ),
  },
  {
    field: 'createdAt',
    transform: value => formatDate(value, LDF.DATE_NUMERIC),
    styles: () => 'text-align: center;',
  },
  {
    field: 'createdAt',
    transform: value => formatDate(value, LDF.TIME),
    styles: () => 'text-align: center;',
  },
];

export const ADD_TRANSACTION_BTN = 'Add Transaction';
export const ADD_BTN = 'Add';
export const DETAILS_LABEL = 'Details:';
export const AMOUNT_LABEL = 'Amount:';
export const CURRENCY_LABEL = 'Currency:';
export const CREDIT_DEBIT_LABEL = 'Credit Debit:';
export const REFERENCE_LABEL = 'Reference:';

export const CONFIRM_MODAL_TITLE = 'Add new transaction';
