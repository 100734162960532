import styled from 'styled-components';

import Pagination from 'shared-parts/components/pagination';
import Table from 'shared-parts/components/table/regular-table/table';
import {
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS } from 'shared-parts/constants';

const { primary, neutral300, systemGreen, white } = COLORS;

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

export const TableTitle = styled.h2`
  margin: 32px 0 20px 20px;
  color: ${primary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;

export const StyledTable = styled(Table)`
  background: ${white};
  border-radius: 0;
  border: none;
  max-height: 500px;

  ${TableHeader} {
    color: ${primary};
  }

  ${TableHeaderCell} {
    top: 57px;
    box-shadow: inset 0 -1px 0 ${neutral300};
    background: ${white};
    font-weight: 700;
    border: 1px solid ${neutral300};
    text-align: left;

    :first-of-type {
      border-top-left-radius: 0;
    }

    :last-of-type {
      border-right: none;
      border-top-right-radius: 0;
    }
  }

  ${TableRow} {
    padding-left: 20px;
  }

  ${TableCell} {
    padding-left: 10px;
    border: 1px solid ${neutral300};
    text-align: left;

    :last-of-type {
      border-right: none;
    }

    :only-child {
      text-align: center;
    }
  }
`;

export const StyledPagination = styled(Pagination)`
  padding-right: 5px;
`;

export const AddTransaction = styled.button`
  padding: 12px 15px;
  background-color: ${systemGreen};
  color: ${white};
  font-weight: bold;
  border: none;
  border-radius: 6px;
`;
