import { getOnboardingStatusData } from './helpers';

// eslint-disable-next-line import/no-unused-modules
export const KYC_HEADER = [
  { name: 'KYC/KYB Status', sorting: { param: 'onboarding_status' }, styles: 'width: 95px;' },
];

// eslint-disable-next-line import/no-unused-modules
export const KYC_KEYS = [
  {
    field: 'onboardingStatus',
    transform: status => getOnboardingStatusData(status).text,
    styles: status =>
      `text-align: center; color:${getOnboardingStatusData(status).color}; width: 155px;`,
  },
];
