const REJECT_ACTION = 'REJECT';
const APPROVE_ACTION = 'APPROVE';
const FILE_SIZE_ERROR = "Combined files size for all attachments can't be more than 50 Mb";
const COMBINED_DOCUMENTS_SIZE_LIMIT = 52428800; // 50Mb
const UPLOAD_BUTTON_TEXT = 'Upload Transaction Documents';
const DOCUMENTS_LIST_TITLE = 'Transaction Documents:';
const STF_DOCUMENT_TITLE = 'Stock Transfer Form Document:';
const FILE_UPLOAD_FAILED = 'FILE UPLOAD FAILED';
const REJECT_MODAL_TITLE = 'Are you sure you want to reject the document?';

const DELETE_MODAL_TITLE = 'DELETE DOCUMENT';
const DELETE_MODAL_TEXT = 'Are you sure you want to delete this document?';

const TRANSACTION_DOCUMENTS_TABS = [
  {
    name: 'Stock Transfer Form Status',
    key: 'status',
  },
];

const COMPLETED = 'Completed';
const REJECTED = 'Rejected';
const WAITING_FOR_APPROVAL = 'Waiting for approval';

const HMRC_STAMP_RECEIVED = 'HMRC stamp received';
const HMRC_NO_STAMP_REQUIRED = 'No stamp required as transaction is less than £1,000';

export {
  REJECT_ACTION,
  APPROVE_ACTION,
  FILE_SIZE_ERROR,
  COMBINED_DOCUMENTS_SIZE_LIMIT,
  UPLOAD_BUTTON_TEXT,
  DOCUMENTS_LIST_TITLE,
  FILE_UPLOAD_FAILED,
  DELETE_MODAL_TITLE,
  DELETE_MODAL_TEXT,
  REJECT_MODAL_TITLE,
  TRANSACTION_DOCUMENTS_TABS,
  COMPLETED,
  REJECTED,
  WAITING_FOR_APPROVAL,
  STF_DOCUMENT_TITLE,
  HMRC_NO_STAMP_REQUIRED,
  HMRC_STAMP_RECEIVED,
};
