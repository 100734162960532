import { call, put, takeEvery } from 'redux-saga/effects';

import { getDashboardInfoError, getDashboardInfoSuccess } from 'app-state/actions/dashboard';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getDashboardInfo({ companyUuid }) {
  try {
    const { data } = yield call(request, API.Dashboard(companyUuid), 'GET', null, {
      timeout: 45000,
    });

    yield put(getDashboardInfoSuccess(data));
  } catch (e) {
    yield put(getDashboardInfoError(e));
  }
}

function* getDashboardInfoWatcher() {
  yield takeEvery(constants.GET_DASHBOARD_INFO, getDashboardInfo);
}

export { getDashboardInfo, getDashboardInfoWatcher };
