import styled from 'styled-components';

import GreenCheckMark2 from 'images/green-checkmark-2.svg';
import FormikButton from 'shared-parts/components/button/formik-button';
import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, neutral800 } = COLORS;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px 76px 40px 76px;
  width: 590px;
`;

const GreenCheckmark = styled.div`
  background: transparent url('${GreenCheckMark2}') 0% 0% no-repeat padding-box;
  height: 65px;
  margin-bottom: 22px;
  width: 65px;
`;

const ResultingText = styled.span`
  color: ${primary};
  font-family: ${FONTS.oswaldFont};
  font-size: 35px;
  font-weight: 300;
`;

const AdditionalInfo = styled.span`
  color: ${neutral800};
  font-family: ${FONTS.mainFont};
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`;

const Button = styled(FormikButton)`
  height: 40px;
  margin-top: 30px;
  width: 120px;
`;

export { Wrapper, GreenCheckmark, ResultingText, AdditionalInfo, Button };
