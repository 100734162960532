export const FETCH_INVESTORS = 'FETCH_INVESTORS';
export const FETCH_INVESTORS_SUCCESS = 'FETCH_INVESTORS_SUCCESS';
export const FETCH_INVESTORS_ERROR = 'FETCH_INVESTORS_ERROR';
export const RESET_INVESTORS = 'RESET_INVESTORS';

export const INVITE_INVESTOR = 'INVITE_INVESTOR';
export const INVITE_INVESTOR_SUCCESS = 'INVITE_INVESTOR_SUCCESS';
export const INVITE_INVESTOR_ERROR = 'INVITE_INVESTOR_ERROR';

export const FETCH_INVESTOR = 'FETCH_INVESTOR';
export const FETCH_INVESTOR_SUCCESS = 'FETCH_INVESTOR_SUCCESS';
export const FETCH_INVESTOR_ERROR = 'FETCH_INVESTOR_ERROR';

export const EDIT_INVESTOR = 'EDIT_INVESTOR';
export const EDIT_INVESTOR_SUCCESS = 'EDIT_INVESTOR_SUCCESS';
export const EDIT_INVESTOR_ERROR = 'EDIT_INVESTOR_ERROR';

export const FETCH_WALLET_REFERENCE = 'FETCH_WALLET_REFERENCE';
export const FETCH_WALLET_REFERENCE_SUCCESS = 'FETCH_WALLET_REFERENCE_SUCCESS';
export const FETCH_WALLET_REFERENCE_ERROR = 'FETCH_WALLET_REFERENCE_ERROR';

export const ADD_WALLET_TRANSFER = 'ADD_WALLET_TRANSFER';
export const ADD_WALLET_TRANSFER_SUCCESS = 'ADD_WALLET_TRANSFER_SUCCESS';
export const ADD_WALLET_TRANSFER_ERROR = 'ADD_WALLET_TRANSFER_ERROR';

export const FETCH_WALLET_TRANSACTIONS = 'FETCH_WALLET_TRANSACTIONS';
export const FETCH_WALLET_TRANSACTIONS_SUCCESS = 'FETCH_WALLET_TRANSACTIONS_SUCCESS';
export const FETCH_WALLET_TRANSACTIONS_ERROR = 'FETCH_WALLET_TRANSACTIONS_ERROR';
export const RESET_WALLET_TRANSACTIONS = 'RESET_WALLET_TRANSACTIONS';

export const FETCH_INVESTOR_INVESTMENTS = 'FETCH_INVESTOR_INVESTMENTS';
export const FETCH_INVESTOR_INVESTMENTS_SUCCESS = 'FETCH_INVESTOR_INVESTMENTS_SUCCESS';
export const FETCH_INVESTOR_INVESTMENTS_ERROR = 'FETCH_INVESTOR_INVESTMENTS_ERROR';
export const RESET_INVESTOR_INVESTMENTS = 'RESET_INVESTOR_INVESTMENTS';

export const FETCH_INVESTOR_PREFERENCES = 'FETCH_INVESTOR_PREFERENCES';
export const FETCH_INVESTOR_PREFERENCES_SUCCESS = 'FETCH_INVESTOR_PREFERENCES_SUCCESS';
export const FETCH_INVESTOR_PREFERENCES_ERROR = 'FETCH_INVESTOR_PREFERENCES_ERROR';
export const RESET_INVESTOR_PREFERENCES = 'RESET_INVESTOR_PREFERENCES';

export const FETCH_INVESTOR_DEALS = 'FETCH_INVESTOR_DEALS';
export const FETCH_INVESTOR_DEALS_SUCCESS = 'FETCH_INVESTOR_DEALS_SUCCESS';
export const FETCH_INVESTOR_DEALS_ERROR = 'FETCH_INVESTOR_DEALS_ERROR';
export const RESET_INVESTOR_DEALS = 'RESET_INVESTOR_DEALS';

export const UPDATE_INVESTOR_DEALS = 'UPDATE_INVESTOR_DEALS';
export const UPDATE_INVESTOR_DEALS_SUCCESS = 'UPDATE_INVESTOR_DEALS_SUCCESS';
export const UPDATE_INVESTOR_DEALS_ERROR = 'UPDATE_INVESTOR_DEALS_ERROR';

export const FETCH_INVESTOR_AGENTS_PERMISSIONS = 'FETCH_INVESTOR_AGENTS_PERMISSIONS';
export const FETCH_INVESTOR_AGENTS_PERMISSIONS_SUCCESS =
  'FETCH_INVESTOR_AGENTS_PERMISSIONS_SUCCESS';
export const FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR = 'FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR';
export const RESET_INVESTOR_AGENTS_PERMISSIONS = 'RESET_INVESTOR_AGENTS_PERMISSIONS';
