import { toCapitalizedWords } from 'helpers/capitalize';

interface HttpError {
  readonly response: {
    readonly details: Record<string, unknown>;
  };
}

interface DisplayError {
  readonly message: string;
  readonly title: string;
}

type TitlesRecord = Record<string, string | undefined>;

const findString = (value: unknown): string | undefined => {
  if (typeof value === 'string') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.find(findString);
  }
};

const findTitle = (titles: TitlesRecord, key: string): string =>
  titles[key] || toCapitalizedWords(key);

export const findFirstError = (
  error: HttpError,
  titles: TitlesRecord = {},
): DisplayError | undefined =>
  Object.entries(error.response.details)
    .filter(([, value]) => findString(value))
    .slice(0, 1)
    .map(([key, message]) => ({ title: findTitle(titles, key), message }) as DisplayError)[0];
