import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const dealSummaryReducer = (state = getReducerState(), { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_SUMMARY_DATA:
    case constants.RESET_DEAL_SUMMARY_DATA:
      return getReducerState();
    case constants.FETCH_DEAL_SUMMARY_DATA_SUCCESS:
      return getReducerState({ loading: false, data: payload });
    case constants.FETCH_DEAL_SUMMARY_DATA_ERROR:
      return getReducerState({ loading: false, error: payload });
    default:
      return state;
  }
};

const dealDataReducerDefaultState = {
  loading: true,
  data: {},
  errors: null,
};

export const dealDataReducer = (state = getReducerState(), { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_DATA:
    case constants.RESET_DEAL_DATA:
      return dealDataReducerDefaultState;
    case constants.FETCH_DEAL_DATA_SUCCESS:
      return { loading: false, data: payload, error: null };
    case constants.FETCH_DEAL_DATA_ERROR:
      return { loading: false, data: state.data, error: payload };
    case constants.UPDATE_DEAL_BANK_ACCOUNTS:
      return { loading: false, data: { ...state.data, bankAccounts: payload }, error: null };
    default:
      return state;
  }
};

const dealDocumentsDataReducerDefaultState = {
  loading: true,
  data: {
    uuid: undefined,
    documents: [],
    ndaProcess: undefined,
  },
  errors: null,
};

export const dealDocumentsDataReducer = (
  state = dealDocumentsDataReducerDefaultState,
  { type, payload },
) => {
  switch (type) {
    case constants.FETCH_DEAL_DATA:
    case constants.RESET_DEAL_DATA:
    case constants.FETCH_DEAL_DOCUMENTS_DATA:
    case constants.RESET_DEAL_DOCUMENTS_DATA:
      return dealDocumentsDataReducerDefaultState;
    case constants.FETCH_DEAL_DATA_SUCCESS:
    case constants.FETCH_DEAL_DOCUMENTS_DATA_SUCCESS:
      return {
        loading: false,
        data: { uuid: payload.uuid, documents: payload.documents, ndaProcess: payload.ndaProcess },
        error: null,
      };
    case constants.FETCH_DEAL_DATA_ERROR:
    case constants.FETCH_DEAL_DOCUMENTS_DATA_ERROR:
      return { loading: false, data: dealDocumentsDataReducerDefaultState.data, error: payload };
    default:
      return state;
  }
};

const defaultInvestorDocumentsState = {
  isUploading: false,
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
        totalObjects: 0,
      },
    },
  },
  errors: null,
};

export const investorDocumentsReducer = (
  state = defaultInvestorDocumentsState,
  { type, payload },
) => {
  switch (type) {
    case constants.FETCH_INVESTOR_DOCUMENTS: {
      const { page, perPage, totalPages, totalObjects } = payload;
      return {
        ...defaultInvestorDocumentsState,
        data: {
          items: [],
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
          },
        },
      };
    }
    case constants.UPLOAD_INVESTOR_DOCUMENT:
      return { ...state, isUploading: true };
    case constants.UPLOAD_INVESTOR_DOCUMENT_FINISHED:
      return { ...state, isUploading: false };
    case constants.FETCH_INVESTOR_DOCUMENTS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case constants.FETCH_INVESTOR_DOCUMENTS_ERROR:
      return { ...state, loading: false, errors: payload };
    case constants.RESET_INVESTOR_DOCUMENTS:
      return defaultInvestorDocumentsState;
    default:
      return state;
  }
};

export const dealInfoReducer = (state = getReducerState(), { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_INFO:
    case constants.RESET_DEAL_INFO:
      return getReducerState();
    case constants.FETCH_DEAL_INFO_SUCCESS:
      return getReducerState({ loading: false, data: payload });
    case constants.FETCH_DEAL_INFO_ERROR:
      return getReducerState({ loading: false, error: payload });
    default:
      return state;
  }
};

const dealListInitialState = getReducerState({
  data: { items: [], meta: { pagination: { totalObjects: 0 } } },
});

export const dealListReducer = (state = dealListInitialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_LIST:
      return {
        data: {
          ...state.data,
          meta: payload.shouldBeRefreshed ? dealListInitialState.data.meta : state.data.meta,
          items: [...(payload.shouldBeRefreshed ? [] : [...state.data.items])],
        },
        loading: true,
        error: null,
      };
    case constants.FETCH_DEAL_LIST_SUCCESS:
      return getReducerState({
        loading: false,
        data: {
          items: [...state.data.items, ...payload.data.items],
          meta: payload.data.meta,
          investorCount: payload.data.investorCount,
          isLastItemFetched: payload.data.items.length < payload.perPage,
          page: payload.page,
        },
      });
    case constants.FETCH_DEAL_LIST_ERROR:
      return getReducerState({
        loading: false,
        data: {
          ...state.data,
        },
        error: payload,
      });
    case constants.RESET_DEAL_LIST:
      return dealListInitialState;
    default:
      return state;
  }
};

export const investorInfoReducer = (state = getReducerState(), { type, payload }) => {
  switch (type) {
    case constants.FETCH_INVESTOR_INFO:
      return getReducerState();
    case constants.FETCH_INVESTOR_INFO_SUCCESS:
      return getReducerState({ loading: false, data: payload });
    case constants.FETCH_INVESTOR_INFO_ERROR:
      return getReducerState({ loading: false, error: payload });
    case constants.RESET_INVESTOR_INFO:
      return getReducerState();
    default:
      return state;
  }
};

const resendInvestorInviteInitialState = { loading: false };

export const resendInvestorInviteReducer = (state = resendInvestorInviteInitialState, { type }) => {
  switch (type) {
    case constants.RESEND_INVESTOR_INVITE:
      return { loading: true };
    case constants.RESEND_INVESTOR_INVITE_SUCCESS:
    case constants.RESEND_INVESTOR_INVITE_ERROR:
      return { loading: false };
    default:
      return state;
  }
};

const getPageState = (params = {}) => ({
  loading: true,
  content: null,
  errors: null,
  ...params,
});

export const pageReducer = (state = getPageState(), { type, data, error }) => {
  switch (type) {
    case constants.FETCH_PAGE:
      return getPageState({
        loading: true,
        content: state.content,
        errors: null,
      });
    case constants.RESET_PAGE:
      return getPageState();
    case constants.FETCH_PAGE_SUCCESS:
    case constants.UPDATE_PAGE:
      return getPageState({ loading: false, content: data });
    case constants.FETCH_PAGE_RESTRICTED:
      return getPageState({ ...state, loading: false });
    case constants.FETCH_PAGE_ERROR:
      return getPageState({ loading: false, errors: error });
    default:
      return state;
  }
};

const updateDealPageHeaderDefaultState = { loading: false, error: null };

export const updateDealPageHeaderReducer = (
  state = updateDealPageHeaderDefaultState,
  { type, payload },
) => {
  switch (type) {
    case constants.UPDATE_DEAL_PAGE_HEADER:
      return { loading: true, error: null };
    case constants.UPDATE_DEAL_PAGE_HEADER_SUCCESS:
      return { loading: false, error: null };
    case constants.UPDATE_DEAL_PAGE_HEADER_ERROR:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

const updateDealDataDefaultState = { loading: false, error: null };

export const updateDealDataReducer = (state = updateDealDataDefaultState, { type, payload }) => {
  switch (type) {
    case constants.UPDATE_DEAL_DATA:
      return { loading: true, error: null };
    case constants.UPDATE_DEAL_DATA_SUCCESS:
      return { loading: false, error: null };
    case constants.UPDATE_DEAL_DATA_ERROR:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

const getIssuanceState = (params = {}) => ({
  loading: true,
  data: {},
  errors: null,
  ...params,
});

export const issuanceReducer = (state = getIssuanceState(), { type, data, error }) => {
  switch (type) {
    case constants.FETCH_ISSUANCE:
      return getIssuanceState();
    case constants.FETCH_ISSUANCE_SUCCESS:
      return getIssuanceState({ loading: false, data });
    case constants.FETCH_ISSUANCE_ERROR:
      return getIssuanceState({ loading: false, errors: error });
    default:
      return state;
  }
};

export const componentAddingStateReducer = (state = {}, { type, sectionId, error }) => {
  switch (type) {
    case constants.ADD_COMPONENT_START:
      return { ...state, [sectionId]: { loading: true } };
    case constants.ADD_COMPONENT_END:
      return { ...state, [sectionId]: { loading: false } };
    case constants.ADD_COMPONENT_ERROR:
      return { ...state, [sectionId]: { loading: false, error } };
    case constants.ADD_COMPONENT_HIDE_ERROR:
      return { ...state, [sectionId]: { loading: false } };
    default:
      return state;
  }
};

const initialEmailTemplateState = { loading: true, data: {}, errors: null };

export const dealInviteEmailTemplateReducer = (
  state = initialEmailTemplateState,
  { type, payload },
) => {
  switch (type) {
    case constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE:
    case constants.RESET_DEAL_INVITE_EMAIL_TEMPLATE:
      return initialEmailTemplateState;
    case constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE_SUCCESS:
      return { loading: false, data: payload, errors: null };
    case constants.FETCH_DEAL_INVITE_EMAIL_TEMPLATE_ERROR:
      return { loading: false, data: {}, errors: payload };
    default:
      return state;
  }
};

export const dealReducer = (state = getReducerState(), { type, data, error }) => {
  switch (type) {
    case constants.FETCH_DEAL:
      return getReducerState();
    case constants.FETCH_DEAL_SUCCESS:
      return getReducerState({ loading: false, data });
    case constants.FETCH_DEAL_ERROR:
      return getReducerState({ loading: false, error });
    case constants.RESET_DEAL:
      return getReducerState();
    default:
      return state;
  }
};

export const lastCreatedComponentReducer = (
  state = {},
  { type, lastCreatedComponentId, lastCreatedComponentType },
) => {
  switch (type) {
    case constants.SET_LAST_CREATED_COMPONENT:
      return { lastCreatedComponentId, lastCreatedComponentType };
    case constants.RESET_LAST_CREATED_COMPONENT:
      return {};
    default:
      return state;
  }
};

const defaultTeamMembersState = getReducerState({ data: { items: [] } });

export const teamMembersReducer = (state = defaultTeamMembersState, { type, data, error }) => {
  switch (type) {
    case constants.FETCH_TEAM_MEMBERS:
      return defaultTeamMembersState;
    case constants.FETCH_TEAM_MEMBERS_SUCCESS:
      return getReducerState({ loading: false, data });
    case constants.FETCH_TEAM_MEMBERS_ERROR:
      return getReducerState({ loading: false, data: { items: [] }, error });
    case constants.RESET_TEAM_MEMBERS:
      return defaultTeamMembersState;
    default:
      return state;
  }
};

const dealOrdersInitialState = getReducerState({
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 20,
        totalPages: 0,
        totalObjects: 0,
      },
      order: '-created_at',
    },
  },
  error: null,
});

export const dealOrdersReducer = (state = dealOrdersInitialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_ORDERS: {
      const { page, perPage, totalPages, totalObjects, order } = payload;
      return {
        ...dealOrdersInitialState,
        data: {
          items: [],
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
            order,
          },
        },
      };
    }
    case constants.FETCH_DEAL_ORDERS_SUCCESS:
      return {
        loading: false,
        data: {
          items: payload.items,
          meta: {
            pagination: payload.meta.pagination,
            order: state.data.meta.order,
            totalOrders: payload.meta.totalOrders,
            targets: payload.meta.targets,
            tokens: payload.meta.tokens,
            amount: payload.meta.amount,
          },
        },
        error: null,
      };
    case constants.FETCH_DEAL_ORDERS_ERROR:
      return { ...state, loading: false, error: payload };
    case constants.RESET_DEAL_ORDERS:
      return dealOrdersInitialState;
    default:
      return state;
  }
};

const initialDealOrderDetailsState = { loading: true, data: [], error: null };

export const dealOrderDetailsReducer = (
  state = initialDealOrderDetailsState,
  { type, payload },
) => {
  switch (type) {
    case constants.FETCH_DEAL_ORDER_DETAILS:
    case constants.RESET_DEAL_ORDER_DETAILS:
      return initialDealOrderDetailsState;
    case constants.FETCH_DEAL_ORDER_DETAILS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case constants.FETCH_DEAL_ORDER_DETAILS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};

const mockedOrderHistory = [
  {
    amount: '155155.0',
    date: '2018-11-14T00:00:00.000+01:00',
    submittedBy: 'J.K.',
  },
];

const initialDealOrderHistoryState = { loading: false, data: [], error: null };

export const dealOrderHistoryReducer = (
  state = initialDealOrderHistoryState,
  { type, payload },
) => {
  switch (type) {
    case constants.FETCH_DEAL_ORDER_HISTORY:
      return { ...state, loading: true };
    case constants.FETCH_DEAL_ORDER_HISTORY_SUCCESS:
      return { ...state, loading: false, data: mockedOrderHistory };
    // return { ...state, loading: false, data: payload.data };
    case constants.FETCH_DEAL_ORDER_HISTORY_ERROR:
      return { ...state, loading: false, error: payload.error };
    case constants.RESET_DEAL_ORDER_HISTORY:
      return initialDealOrderHistoryState;
    default:
      return state;
  }
};

const settlementsInitialState = {
  loading: true,
  data: {
    items: [],
    meta: {
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
        totalObjects: 0,
      },
      order: '-investor_name',
    },
  },
  errors: null,
};

export const dealSettlementsReducer = (state = settlementsInitialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_SETTLEMENTS: {
      const { page, perPage, totalPages, totalObjects, order } = payload;
      return {
        ...settlementsInitialState,
        data: {
          items: [],
          meta: {
            pagination: { page, perPage, totalPages, totalObjects },
            order,
          },
        },
      };
    }
    case constants.FETCH_DEAL_SETTLEMENTS_SUCCESS:
      return {
        loading: false,
        data: {
          items: payload.items,
          meta: {
            pagination: payload.meta.pagination,
            order: state.data.meta.order,
            totalOrders: payload.meta.totalOrders,
            targets: payload.meta.targets,
            tokens: payload.meta.tokens,
            amount: payload.meta.amount,
          },
        },
        error: null,
      };
    case constants.FETCH_DEAL_SETTLEMENTS_ERROR:
      return { ...state, loading: false, errors: payload };
    case constants.RESET_DEAL_SETTLEMENTS:
      return settlementsInitialState;
    default:
      return state;
  }
};
