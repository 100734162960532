const removeBox = (boxes, boxToDelete) => {
  const boxIndex = boxes.findIndex(
    box => box.id === boxToDelete.id && box.type === boxToDelete.type,
  );

  if (boxIndex === -1) {
    // box was not found
    return boxes;
  }

  const firstPart = boxes.slice(0, boxIndex);
  const secondPart = boxes
    .slice(boxIndex + 1)
    .map(item => ({ ...item, position: item.position - 1 }));

  return [...firstPart, ...secondPart];
};

export default (pageContent, data, action) => {
  switch (action) {
    case 'updateSection':
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => (section.id === data.id ? data : section)),
      };
    case 'updateSections':
      return {
        ...pageContent,
        sections: data,
      };
    case 'deleteSection':
      return {
        ...pageContent,
        sections: pageContent.sections.filter(section => section.id !== data),
      };
    case 'createSection':
      return {
        ...pageContent,
        sections: [...pageContent.sections, data],
      };
    case 'createComponent':
      return {
        ...pageContent,
        sections: pageContent.sections.map(section =>
          section.id === data.sectionId
            ? {
                ...section,
                boxes: [...section.boxes, data],
              }
            : section,
        ),
      };
    case 'editComponent':
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box =>
            box.id === data.id && box.type === data.type ? data : box,
          ),
        })),
      };
    case 'deleteComponent': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: removeBox(section.boxes, data),
        })),
      };
    }
    case 'reorderComponent': {
      const sectionContainingBoxWeEdit = pageContent.sections.find(section =>
        section.boxes.some(box => box.sectionBoxId === data.sectionBoxId),
      );
      const { oldPosition, newPosition } = data;

      return {
        ...pageContent,
        sections: pageContent.sections.map(section =>
          section.id === sectionContainingBoxWeEdit.id
            ? {
                ...section,
                boxes: section.boxes.map(box => {
                  if (oldPosition === box.position) {
                    return { ...section.boxes[newPosition - 1], position: oldPosition };
                  }

                  if (newPosition === box.position) {
                    return { ...section.boxes[oldPosition - 1], position: newPosition };
                  }

                  return box;
                }),
              }
            : section,
        ),
      };
    }
    case 'removeIsEditingFlags': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box => {
            const { isCreating, isEditing, isTitleEditing, ...rest } = box; // eslint-disable-line

            return box.id === data.id && box.type === data.type ? rest : box;
          }),
        })),
      };
    }
    case 'editTeamMember': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box => {
            if (box.type === 'TeamBox') {
              const theTeam = box.theTeam.map(teamMember =>
                teamMember.id === data.id ? data : teamMember,
              );

              return { ...box, theTeam };
            }

            return box;
          }),
        })),
      };
    }
    case 'attachTeamMember': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box => {
            if (box.id === data.boxId && box.type === 'TeamBox') {
              return { ...box, theTeam: [...box.theTeam, data] };
            }

            return box;
          }),
        })),
      };
    }
    case 'detachTeamMember': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box => {
            if (box.id === data.teamBoxId && box.type === 'TeamBox') {
              return {
                ...box,
                theTeam: box.theTeam.filter(member => data.teamMemberId !== member.id),
              };
            }

            return box;
          }),
        })),
      };
    }
    case 'createTagline':
    case 'editTagline': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box => {
            if (data && +data.sectionBoxId === box.sectionBoxId) {
              return {
                ...box,
                tagline: data,
              };
            }

            return box;
          }),
        })),
      };
    }
    case 'deleteTagline': {
      return {
        ...pageContent,
        sections: pageContent.sections.map(section => ({
          ...section,
          boxes: section.boxes.map(box => {
            if (data && +data.sectionBoxId === box.sectionBoxId) {
              return {
                ...box,
                tagline: null,
              };
            }

            return box;
          }),
        })),
      };
    }
    default:
      return pageContent;
  }
};
