import { FC } from 'react';

import { useTheme } from '@mui/material';

export type TimezoneItemProps = {
  /**
   * E.g. "American Samoa"
   */
  timezoneLabel?: string;
  /**
   * E.g. "(GMT-11:00)"
   */
  timezoneOffsetString?: string;
};

const TimezoneItem: FC<TimezoneItemProps> = ({ timezoneLabel, timezoneOffsetString }) => {
  const theme = useTheme();
  const heavyStyle = {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.neutral[600],
  };
  const lightStyle = {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.neutral[400],
  };
  return (
    <span>
      {timezoneLabel && <span style={heavyStyle}>{timezoneLabel}</span>}{' '}
      {timezoneOffsetString && <span style={lightStyle}>{timezoneOffsetString}</span>}
    </span>
  );
};

export default TimezoneItem;
