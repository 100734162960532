import styled from 'styled-components';

import { TableContainer } from 'shared-parts/components/table/regular-table/table.styled';
import { COLORS } from 'shared-parts/constants';
import { Screens } from 'shared-parts/constants/screens';

import { SectionRegularStyleType } from './type';

const Container = styled.div`
  margin: 25px 0;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.neutral400};
  border-radius: 8px;
  padding: 45px 40px 40px 40px;
  margin: 0 auto 25px;
  position: relative;
  box-shadow: 0px 3px 6px ${COLORS.shadowColor};
  border-bottom: 1px solid ${COLORS.neutral400};
  position: relative;

  @media (max-width: ${Screens.xs}) {
    flex-wrap: wrap;
  }
`;

const ContentColumn = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const ComponentContainer = styled.div<SectionRegularStyleType>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${({ isImageExpanded }) => (isImageExpanded ? 'column' : 'row')};
  margin-bottom: 20px;

  ${TableContainer} {
    tbody {
      td {
        min-width: 180px; // setting minimal table column width (only for deal)
      }
    }
  }
`;

const Box = styled.div<SectionRegularStyleType>`
  width: 90%;
  @media (max-width: ${Screens.md}) {
    width: 95%;
  }
  @media (max-width: ${Screens.xs}) {
    margin: 0 10px;
  }
`;

export { Container, ContentColumn, ComponentContainer, Box };
