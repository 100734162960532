import styled from 'styled-components';

import { FormikButton } from 'shared-parts/components/button';
import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, neutral300, neutral800, alternative } = COLORS;

const ModalWrapper = styled.div`
  width: 580px;
  max-width: 90vw;
`;

const ConfirmButton = styled(FormikButton)`
  height: 40px;
  margin-left: 15px;
  background: ${alternative};
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto 36px;
  font-family: ${FONTS.mainFont};
`;

const CautionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 46px 0 25px;
`;

const ModalTitle = styled.h2`
  font-size: 35px;
  font-weight: 200;
  color: ${primary};
  text-align: center;
  margin-bottom: 12px;
  font-family: ${FONTS.oswaldFont};
`;

const ModalText = styled.div`
  color: ${neutral800};
  margin: 0 0 2px;
  text-align: center;
  font-family: ${FONTS.mainFont};
`;

const ModalPart = styled.div`
  font-family: ${neutral300};
  margin-bottom: 10px;
`;

const ModalSection = styled.div`
  margin: 0 50px 0 50px;
`;

export {
  ModalWrapper,
  CautionIconWrapper,
  ModalTitle,
  ModalText,
  ConfirmButton,
  ButtonsWrapper,
  ModalPart,
  ModalSection,
};
