import { Action } from 'redux';
import { buffers } from 'redux-saga';
import { actionChannel, call, debounce, delay, fork, race, take } from 'redux-saga/effects';

/**
 * Synchronously run tasks with a debounce pattern (not executing actions until a certain
 * amount of time has passed). Once executing, no other tasks will run. Once the task
 * has finished, it will then run the latest task in the buffer, ignoring any older tasks.
 *
 * For example, considering S (start of task) and E (end of task):
 *
 * -----S1-------------------E1--------------
 *
 * ---------S2----S3-----S4-------------E4---
 */
export const debounceQueue = (
  ...[ms, pattern, task, ...args]: Parameters<typeof debounce>
): ReturnType<typeof debounce> =>
  fork(function* () {
    const buffer = buffers.sliding<Action<any>>(1);
    const channel: any = yield actionChannel(pattern as any, buffer);

    while (true) {
      let action: any = yield take(channel);

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        });

        if (debounced) {
          // Any debounced events will also be on the buffer, which we're not interested in
          // we only want to buffer while we wait for this task, so clear it here
          buffer.flush();
          yield call<any>(task, ...args, action);
          break;
        }

        action = latestAction;
      }
    }
  });
