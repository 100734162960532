export enum INVESTOR_TYPE {
  INDIVIDUAL = 'individual_investor',
  INSTITUTIONAL = 'institutional_investor',
}

export enum INVESTOR_DETAILS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ENTITY_COMPANY_ID = 'entityCompanyId',
  ENTITY_COMPANY_NAME = 'entityCompanyName',
  INSTITUTION_NAME = 'institutionName',
  INSTITUTION_COUNTRY = 'institutionCountry',
  INSTITUTION_NUMBER = 'institutionNumber',
  COUNTRY_CODE = 'countryCode',
  ADDRESS_FIRST_LINE = 'addressFirstLine',
  CITY = 'city',
  POST_CODE = 'postCode',
  LINE2 = 'line2',
  STATE = 'state',
  NATIONALITY = 'nationality',
  INDIVIDUAL_INVESTOR_TYPE = 'individualInvestorTypeId',
  COMPANY_ID = 'companyId',
}

export const INVITE_NEW_INDIVIDUAL_INVESTOR = 'Add investor';
export const INVITE_NEW_INSTITUTIONAL_INVESTOR = 'Add investor';

export const INVITE_INVESTOR_SUCCESS_TITLE = 'Investor has been successfully added';
export const INVITE_INVESTOR_SUCCESS_DESCRIPTION =
  'This investor has been successfully added to the platform, and you are now able to invite them to an opportunity.';
export const EDIT_INVESTOR_SUCCESS_TITLE = 'Investor details have been modified';

export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const EMAIL_LABEL = 'Email';
export const ENTITY_LABEL = 'Entity';
export const INSTITUTION_NAME_LABEL = 'Institution Name';
export const INSTITUTION_NUMBER_LABEL = 'Customer No. (Institution)';
export const INSTITUTION_COUNTRY_LABEL = 'Country of Registration';
export const COUNTRY_CODE_LABEL = 'Country';
export const INDIVIDUAL_COUNTRY_CODE_LABEL = 'Country of Residence';
export const ADDRESS_LABEL = 'Address';
export const CITY_LABEL = 'City';
export const POST_CODE_LABEL = 'Postal / Zip Code';
export const INVESTOR_NATIONALITY_LABEL = 'Nationality';
export const INDIVIDUAL_INVESTOR_TYPE_LABEL = 'Investor sub-type';

export const SAVE = 'SAVE';
export const EXIT_BUTTON_LABEL = 'Exit without saving';
export const SUBMIT_BUTTON_LABEL = 'Add investor';

export const WRONG_INVESTOR_TYPE =
  'The email you entered does not match the corresponding investor type. Please enter an appropriate email';
