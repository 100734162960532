import { UnderlinedTextInput } from 'shared-parts/components';
import { COLORS } from 'shared-parts/constants';

import { FormWrapper, SubmitButton } from './create-password-form.styled';

const renderCreatePasswordForm =
  () =>
  ({ isValid, isSubmitting, dirty }) => (
    <FormWrapper autoComplete="off">
      <UnderlinedTextInput
        type="password"
        name="password"
        textColor={COLORS.primary}
        backgroundColor={COLORS.white}
        placeholderColor={COLORS.neutral800}
        placeholder="Password"
      />
      <UnderlinedTextInput
        type="password"
        name="passwordConfirmation"
        textColor={COLORS.primary}
        backgroundColor={COLORS.white}
        placeholderColor={COLORS.neutral800}
        placeholder="Confirm Password"
      />
      <SubmitButton disabled={!dirty || !isValid || isSubmitting} type="submit">
        REGISTER
      </SubmitButton>
    </FormWrapper>
  );

export default renderCreatePasswordForm;
