import type { FC, ReactNode } from 'react';

import { InfoIcon } from 'svg-icons';
import { COLORS } from 'shared-parts/constants';

import { IconContainer, StyledTooltip, TooltipContent } from './index.styled';

const { systemYellow } = COLORS;

const renderIcon = (color?: string) => () => (
  <IconContainer>
    <InfoIcon fillColor={color} />
  </IconContainer>
);

const renderContent = (content: ReactNode) => () => <TooltipContent>{content}</TooltipContent>;

const InfoTooltip: FC<React.PropsWithChildren<{ iconColor?: string; className?: string }>> = ({
  children,
  iconColor,
  className,
}) => (
  <StyledTooltip
    className={className}
    content={renderContent(children)}
    arrowColor={systemYellow}
    position="bottom-start"
    autohide={false}
  >
    {renderIcon(iconColor)}
  </StyledTooltip>
);

export default InfoTooltip;
