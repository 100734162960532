import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import handleForbiddenDealEdit from 'app-state/deal/helpers';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import * as selectors from 'app-state/selectors';

import API from 'constants/api';
import { attachURL } from 'helpers';
import { request } from 'shared-parts/helpers';

import {
  getReorderedItemsAfterSubItemIsMoved,
  getReorderedItemsAfterSubItemParentChanging,
  reorderItems,
} from './navigation.helpers';

function* fetchTheSubNavigation({ permalink, navigationItemId, navigationURL }) {
  try {
    const { data } = yield call(request, API.SubNavigation(navigationItemId));
    const items = yield attachURL({
      data,
      roleDetails: {
        has: 'Deal URL',
        getCriterias: ({ url }) => ({
          permalink,
          navigationURL: `/${navigationURL}`,
          subNavigationURL: `/${url}`,
        }),
      },
      urlFieldName: 'fullURL',
    });

    return yield items;
  } catch (e) {
    // empty catch block is required
  }
}

function* fetchSubNavigationWatcher() {
  yield takeEvery(constants.FETCH_SUB_NAVIGATION, fetchTheSubNavigation);
}

function* fetchTheNavigation({ permalink, modelUuid }) {
  try {
    const { data } = yield call(request, API.Navigation(modelUuid));
    const items = yield attachURL({
      data,
      roleDetails: {
        has: 'Deal URL',
        getCriterias: ({ url }) => ({
          permalink,
          navigationURL: `/${url}`,
          subNavigationURL: '',
        }),
      },
      urlFieldName: 'fullURL',
    });

    yield put(actions.fetchNavigationSuccess(items));

    return yield items;
  } catch (e) {
    yield put(actions.fetchNavigationError(e));
  }
}

function* fetchNavigationWatcher() {
  yield takeEvery(constants.FETCH_NAVIGATION, fetchTheNavigation);
}

function* fetchEditableNavigation({ modelUuid, model }) {
  try {
    const { data } = yield call(request, API.EditableNavigation(modelUuid, model));

    yield put(actions.fetchEditableNavigationSuccess(data));
  } catch (e) {
    yield put(actions.fetchEditableNavigationError(e));
  }
}

function* fetchEditableNavigationWatcher() {
  yield takeEvery(constants.FETCH_EDITABLE_NAVIGATION, fetchEditableNavigation);
}

function* editNavigationItem({ itemId, item, forceErrorThrowing = false }) {
  try {
    // should be done before we get to the API call
    yield put(actions.editNavigationItemSuccess(item));

    yield call(request, API.EditNavigationItem(itemId), 'PUT', item);
  } catch (e) {
    yield put(actions.editNavigationItemError(e));
    yield put(actions.editNavigationItemSuccess({ ...item, text: item.oldText || item.text }));

    if (forceErrorThrowing) {
      throw e;
    }
  }
}

function* editNavigationItemWatcher() {
  yield takeEvery(constants.EDIT_NAVIGATION_ITEM, editNavigationItem);
}

function* reorderNavigationItem({ item, affectedItem, modelUuid, model }) {
  const { data: navigationItems } = yield select(selectors.getEditableNavigation);
  const { parentId, id, childs = [] } = affectedItem;

  try {
    const changingParentOfSubItem = item.parentId && item.parentId !== parentId;

    if (changingParentOfSubItem) {
      // sub item is reordered with changing of parent item
      const reorderedItems = getReorderedItemsAfterSubItemParentChanging(
        navigationItems,
        item,
        affectedItem,
      );

      yield put(actions.fetchEditableNavigationSuccess(reorderedItems));
      return yield call(editNavigationItem, {
        itemId: item.id,
        item: {
          ...item,
          modelUuid,
          position: childs.length + 1,
          parentId: parentId || id,
        },
        forceErrorThrowing: true,
      });
    }

    if (item.parentId) {
      // sub item is reordered without changing of parent item
      const reorderedItems = getReorderedItemsAfterSubItemIsMoved(
        navigationItems,
        item,
        affectedItem,
      );
      yield put(actions.fetchEditableNavigationSuccess(reorderedItems));
    } else {
      const reorderedItems = reorderItems(navigationItems, item, affectedItem); // reordering item
      yield put(actions.fetchEditableNavigationSuccess(reorderedItems));
    }

    const runThrough = parentId
      ? navigationItems.find(navItem => navItem.id === parentId).childs
      : navigationItems;
    const itemsIds = reorderItems(runThrough, item, affectedItem).map(i => i.id);
    const { data: result } = yield call(request, API.ReorderNavigationItem(), 'PUT', {
      itemsIds,
      parentId,
      modelUuid,
      model,
    });

    yield put(actions.fetchEditableNavigationSuccess(result));
    yield put(actions.reorderNavigationItemSuccess());
  } catch (e) {
    yield put(actions.reorderNavigationItemError(e));
  }
}

function* reorderNavigationItemWatcher() {
  yield takeEvery(constants.REORDER_NAVIGATION_ITEM, reorderNavigationItem);
}

function* deleteNavigationItem({ payload: { itemId, modelUuid, pagesModel } }) {
  try {
    const { data } = yield call(request, API.DeleteNavigationItem(itemId), 'DELETE', {
      modelUuid,
      model: pagesModel,
    });

    yield put(actions.deleteNavigationItemSuccess(data));
  } catch (e) {
    yield put(actions.deleteNavigationItemError(e));
  }
}

function* deleteNavigationItemWatcher() {
  yield takeEvery(constants.DELETE_NAVIGATION_ITEM, deleteNavigationItem);
}

function* createNavigationItem({ item, model }) {
  yield put(showLoader());

  try {
    const { data } = yield call(
      request,
      API.CreateNavigationItem(item.modelUuid, model),
      'POST',
      item,
      {
        ignoredErrorCodes: [403],
      },
    );

    yield put(actions.createNavigationItemSuccess(data));

    if (!item.parentId) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  } catch (e) {
    yield put(actions.createNavigationItemError(e));
    yield handleForbiddenDealEdit(e);
  }

  yield put(hideLoader());
}

function* createNavigationItemWatcher() {
  yield takeEvery(constants.CREATE_NAVIGATION_ITEM, createNavigationItem);
}

function* generateBasicNavigation({ modelUuid, pagesModel }) {
  try {
    const { data } = yield call(request, API.GenerateBasicNavigation(), 'POST', {
      modelUuid,
      model: pagesModel,
    });

    yield put(actions.fetchEditableNavigationSuccess(data));
  } catch (e) {
    yield put(actions.generateBasicNavigationError(e));
  }
}

function* generateBasicNavigationWatcher() {
  yield takeEvery(constants.GENERATE_BASIC_NAVIGATION, generateBasicNavigation);
}

export {
  fetchTheSubNavigation,
  fetchSubNavigationWatcher,
  fetchNavigationWatcher,
  fetchTheNavigation,
  fetchEditableNavigation,
  fetchEditableNavigationWatcher,
  editNavigationItem,
  editNavigationItemWatcher,
  deleteNavigationItem,
  deleteNavigationItemWatcher,
  createNavigationItem,
  createNavigationItemWatcher,
  reorderNavigationItem,
  reorderNavigationItemWatcher,
  generateBasicNavigation,
  generateBasicNavigationWatcher,
};
