import { COLORS } from 'shared-parts/constants';

const ErrorOutline = (width = 70, height = 70, fillColor = COLORS.systemRed) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 69.838 69.838">
    <path
      fill={fillColor}
      d="M33.427,47.395h6.984v6.984H33.427Zm0-27.935h6.984V40.411H33.427ZM36.884,2A34.919,34.919,0,1,0,71.838,36.919,34.9,34.9,0,0,0,36.884,2Zm.035,62.854A27.935,27.935,0,1,1,64.854,36.919,27.927,27.927,0,0,1,36.919,64.854Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default ErrorOutline;
