import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { hideSideForm, showModal } from 'app-state/actions/shared';
import { sendBulkWelcomeEmail } from 'app-state/actions/shareholders';

import WelcomeEmailPreview from 'shared/welcome-email-preview';

import BulkWelcomeEmailForm from './components/bulk-welcome-email';

export const previewEmail = dispatch => () => {
  dispatch(showModal({ closable: true, component: WelcomeEmailPreview }));
};

export const sendEmail = (companyId, dispatch) => () => {
  dispatch(sendBulkWelcomeEmail(companyId));
};

export const hideForm = dispatch => () => {
  dispatch(hideSideForm());
};

const BulkWelcomeEmail = ({ companyId, shareholdersAmount, registered, invited, willBeSentTo }) => {
  const dispatch = useDispatch();

  return (
    <BulkWelcomeEmailForm
      sendEmail={sendEmail(companyId, dispatch)}
      previewEmail={previewEmail(dispatch)}
      hideSideForm={hideForm(dispatch)}
      shareholdersAmount={shareholdersAmount}
      registered={registered}
      invited={invited}
      willBeSentTo={willBeSentTo}
    />
  );
};

BulkWelcomeEmail.propTypes = {
  companyId: PropTypes.string.isRequired,
  shareholdersAmount: PropTypes.number.isRequired,
  registered: PropTypes.number.isRequired,
  invited: PropTypes.number.isRequired,
  willBeSentTo: PropTypes.number.isRequired,
};

export default BulkWelcomeEmail;
