import { ExportCommandPayloadInterface } from 'modules/company/equity-administration/all-transactions/csv-export';
import { createAction } from 'helpers/redux.helpers';

import * as constants from './csv-export.constants';

export const sendDownloadExportCommandRequest = createAction<ExportCommandPayloadInterface>(
  constants.sendDownloadExportCommandRequest.REQUEST,
);

export const checkExportCommandJobProgressFailure = ({
  error = 'Something went wrong',
}: {
  error?: string;
} = {}) => {
  return {
    type: constants.checkExportCommandJobProgress.FAILURE,
    error,
  };
};

export const checkExportCommandJobProgressRequest = ({
  data,
  startTime,
}: {
  data: any;
  startTime: Date;
}) => {
  return {
    type: constants.checkExportCommandJobProgress.REQUEST,
    data,
    startTime,
  };
};

export const checkExportCommandJobProgressSuccess = ({ data }: { data: any }) => {
  return {
    type: constants.checkExportCommandJobProgress.SUCCESS,
    data,
  };
};
