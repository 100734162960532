import { COLORS } from 'shared-parts/constants';
import ForgotPassword from 'shared-parts/modules/auth/forgot-password/forgot-password';

export default () => (
  <ForgotPassword
    xIconColor={COLORS.primary}
    headerColor={COLORS.primary}
    messageColor={COLORS.primary}
    linkTextColor={COLORS.primary}
    backgroundColor={COLORS.white}
    formColors={{
      textColor: COLORS.primary,
      backgroundColor: COLORS.white,
    }}
  />
);
