import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import { getCurrencies, resetCurrencies } from 'app-state/currencies/actions';
import { getCurrenciesSelector } from 'app-state/currencies/selectors';

import useTypedSelector from 'hooks/use-typed-selector';
import Select from 'shared-parts/components/select';
import TextArea from 'shared-parts/components/text-area/text-area';
import TextInput from 'shared-parts/components/text-input/regular-text-input';
import { COLORS } from 'shared-parts/constants';

import {
  AddButton,
  ButtonsWrapper,
  FormColumn,
  FormRow,
  FormWrapper,
  Label,
} from './add-transaction-form.styled';
import validationSchema from './add-transaction-form.validation';
import {
  ADD_BTN,
  AMOUNT_LABEL,
  CREDIT_DEBIT_LABEL,
  CURRENCY_LABEL,
  DETAILS_LABEL,
  REFERENCE_LABEL,
} from './constants';
import { getCurrenciesOptions } from './helpers';

const { white } = COLORS;

const creditDebitOptions = [
  { text: 'Credit', value: 'credit' },
  { text: 'Debit', value: 'debit' },
];

const AddTransactionForm = ({ initialValues, loading, reference, onCurrencyChange, onSubmit }) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
    {({ dirty, isValid, isSubmitting, setFieldValue }) => {
      const dispatch = useDispatch();

      useEffect(() => {
        if (!loading) {
          setFieldValue('reference', reference);
        }
      }, [setFieldValue, reference, loading]);

      useEffect(() => {
        dispatch(getCurrencies('wallet'));

        return () => {
          dispatch(resetCurrencies());
        };
      }, []);

      const currencies = useTypedSelector(getCurrenciesSelector);
      const currencyOptions = getCurrenciesOptions(currencies);

      return (
        <FormWrapper autoComplete="off">
          <FormRow>
            <FormColumn width="40%">
              <Label htmlFor="amount">{AMOUNT_LABEL}</Label>
              <TextInput name="amount" isAmount dpAmount={2} disableAutocomplete />
            </FormColumn>
            <FormColumn width="15%">
              <Label htmlFor="currency">{CURRENCY_LABEL}</Label>
              <Select
                name="currency"
                options={currencyOptions}
                disableAutocomplete
                onOptionSelect={onCurrencyChange}
              />
            </FormColumn>
            <FormColumn width="15%">
              <Label htmlFor="creditDebit">{CREDIT_DEBIT_LABEL}</Label>
              <Select name="creditDebit" options={creditDebitOptions} disableAutocomplete />
            </FormColumn>
            <FormColumn width="30%">
              <Label htmlFor="reference">{REFERENCE_LABEL}</Label>
              <TextInput name="reference" disableAutocomplete disabled />
            </FormColumn>
          </FormRow>
          <Label htmlFor="details">{DETAILS_LABEL}</Label>
          <TextArea
            name="details"
            defaultVisibleLines="6"
            resize="none"
            backgroundColor={white}
            disableAutocomplete
          />
          <ButtonsWrapper>
            <AddButton
              disabled={!dirty || !isValid || isSubmitting}
              type="submit"
              data-e2e="add-button"
            >
              {ADD_BTN}
            </AddButton>
          </ButtonsWrapper>
        </FormWrapper>
      );
    }}
  </Formik>
);

export default AddTransactionForm;
