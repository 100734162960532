import type { FormikProps } from 'formik';
import { Form } from 'formik';

import { useEnvVar } from '@frontend/config';

import { getUser } from 'app-state/selectors';

import Entity from 'modules/investors/investor-details-form/entitiy/entity';
import { isOperationalAdmin } from 'modules/register-overview/securities-overview/helpers';
import useTypedSelector from 'hooks/use-typed-selector';
import { InvestorKYCSection } from 'shared/investor-kyc-info';
import Address from 'shared-parts/components/address';
import { TextButton } from 'shared-parts/components/button';
import Country from 'shared-parts/components/country/country';
import TextInput from 'shared-parts/components/text-input/regular-text-input';
import { COLORS } from 'shared-parts/constants';

import {
  COUNTRY_CODE_LABEL,
  EMAIL_LABEL,
  ENTITY_LABEL,
  EXIT_BUTTON_LABEL,
  FIRST_NAME_LABEL,
  INDIVIDUAL_COUNTRY_CODE_LABEL,
  INSTITUTION_COUNTRY_LABEL,
  INSTITUTION_NAME_LABEL,
  INSTITUTION_NUMBER_LABEL,
  INVESTOR_DETAILS,
  LAST_NAME_LABEL,
  SAVE,
  SUBMIT_BUTTON_LABEL,
} from '../constants';
import ResendInvite from '../resend-invite';
import {
  AddressFormGroup,
  ButtonsBlock,
  FormContainer,
  FormGroup,
  Label,
  ResendInviteContainer,
  StyledButton,
} from '../styled';
import type {
  Address as AddressType,
  InvestorDetailsFormProps,
  InvestorDetailsInitialValues,
} from '../types';

const { primary } = COLORS;

const manualAddressParams = {
  line1Label: 'Address:*',
  line2Label: '',
  postCodeLabel: 'Postal / Zip Code:*',
  cityLabel: 'City:*',
  state: 'State:',
  FormGroup: AddressFormGroup,
};

const loqateParams = {
  loqateLabel: 'Address:*',
  FormGroup,
  blockStyles: `
    width: 100%;
    color: ${primary};
    font-weight: bold;
  `,
};

const onAddressChange =
  (
    values: InvestorDetailsInitialValues,
    setValues: (values: InvestorDetailsInitialValues) => void,
  ) =>
  (address: AddressType, _setFieldValue: unknown, cb: () => void) => {
    if (!Object.keys(address).length) return;

    setValues({
      ...values,
      addressFirstLine: address.line1,
      line2: address.line2,
      postCode: address.postCode,
      city: address.city,
      state: address.state,
    });

    cb();
  };

const InvestorDetails =
  ({
    closeForm,
    isEditing,
    lastInviteSentAt,
    investorUuid,
    isFormDisabled,
    investorStatus,
    investorType,
    investorData,
  }: InvestorDetailsFormProps) =>
  (form: FormikProps<InvestorDetailsInitialValues>) => {
    const {
      dirty,
      isValid,
      isSubmitting,
      values,
      setValues,
      setFieldValue,
      setFieldError,
      setFieldTouched,
    } = form;
    const isResendAllowed = isEditing && investorStatus === 'pending';
    const isInstitutionalFieldsVisible = investorType === 'institutional_investor' || !isEditing;
    const enableKycInfo = !useEnvVar('DISABLED_KYC_INFO');
    const isEntityCompanyEnabled = !!useEnvVar('ENABLE_ENTITY_COMPANY_INVESTOR_FORM');
    const user = useTypedSelector(getUser);
    const isInvestorVerified = investorData?.onboardingStatus === 'completed';

    return (
      <Form>
        {enableKycInfo && (
          <InvestorKYCSection
            onboardingStatus={investorData?.onboardingStatus}
            type={investorType}
            category={investorData?.institutionalInvestorType?.name}
            uuid={investorUuid}
            closeForm={closeForm}
            investorName={`${values[INVESTOR_DETAILS.FIRST_NAME] ?? ''} ${
              values[INVESTOR_DETAILS.LAST_NAME] ?? ''
            }`.trim()}
          />
        )}
        <FormContainer isBorderHidden>
          <FormGroup width="50%">
            <Label isRequired htmlFor={INVESTOR_DETAILS.FIRST_NAME}>
              {FIRST_NAME_LABEL}
            </Label>
            <TextInput
              name={INVESTOR_DETAILS.FIRST_NAME}
              disableAutocomplete
              disabled={isFormDisabled || isInvestorVerified}
            />
          </FormGroup>
          <FormGroup width="50%">
            <Label isRequired htmlFor={INVESTOR_DETAILS.LAST_NAME}>
              {LAST_NAME_LABEL}
            </Label>
            <TextInput
              name={INVESTOR_DETAILS.LAST_NAME}
              disableAutocomplete
              disabled={isFormDisabled || isInvestorVerified}
            />
          </FormGroup>
        </FormContainer>
        <FormContainer isBorderHidden={isEntityCompanyEnabled}>
          <FormGroup width="50%">
            <Label isRequired htmlFor={INVESTOR_DETAILS.EMAIL}>
              {EMAIL_LABEL}
            </Label>
            <TextInput
              name={INVESTOR_DETAILS.EMAIL}
              disableAutocomplete
              disabled={isFormDisabled}
            />
          </FormGroup>
        </FormContainer>
        {isEntityCompanyEnabled && (
          <FormContainer>
            <FormGroup width="50%">
              <Label isRequired htmlFor={INVESTOR_DETAILS.ENTITY_COMPANY_ID}>
                {ENTITY_LABEL}
              </Label>
              {isOperationalAdmin(user) ? (
                <Entity
                  name={INVESTOR_DETAILS.ENTITY_COMPANY_ID}
                  placeholder={ENTITY_LABEL}
                  disabled={isFormDisabled || isInvestorVerified}
                />
              ) : (
                <TextInput name={INVESTOR_DETAILS.ENTITY_COMPANY_NAME} disabled />
              )}
            </FormGroup>
          </FormContainer>
        )}
        {isInstitutionalFieldsVisible && (
          <FormContainer isBorderHidden>
            <FormGroup>
              <Label isRequired htmlFor={INVESTOR_DETAILS.INSTITUTION_NAME}>
                {INSTITUTION_NAME_LABEL}
              </Label>
              <TextInput
                name={INVESTOR_DETAILS.INSTITUTION_NAME}
                disableAutocomplete
                disabled={isFormDisabled || isInvestorVerified}
              />
            </FormGroup>
          </FormContainer>
        )}
        <FormContainer isBorderHidden>
          <FormGroup>
            <Label isRequired htmlFor={INVESTOR_DETAILS.COUNTRY_CODE}>
              {isInstitutionalFieldsVisible ? COUNTRY_CODE_LABEL : INDIVIDUAL_COUNTRY_CODE_LABEL}
            </Label>
            <Country
              name={INVESTOR_DETAILS.COUNTRY_CODE}
              disableAutocomplete
              disabled={isFormDisabled || isInvestorVerified}
            />
          </FormGroup>
        </FormContainer>
        <FormContainer isBorderHidden>
          <Address
            isManual={isEditing}
            disableAutocomplete
            formValues={values}
            onAddressChange={onAddressChange(values, setValues)}
            manualAddressParams={{
              ...manualAddressParams,
              disabled: isFormDisabled,
            }}
            loqateParams={loqateParams}
            country={values.countryCode}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
          />
        </FormContainer>
        <FormContainer>
          {isInstitutionalFieldsVisible && (
            <FormGroup width="50%">
              <Label isRequired htmlFor={INVESTOR_DETAILS.INSTITUTION_COUNTRY}>
                {INSTITUTION_COUNTRY_LABEL}
              </Label>
              <Country
                name={INVESTOR_DETAILS.INSTITUTION_COUNTRY}
                disableAutocomplete
                disabled={isFormDisabled || isInvestorVerified}
              />
            </FormGroup>
          )}
          {isInstitutionalFieldsVisible && (
            <FormGroup width="50%">
              <Label htmlFor={INVESTOR_DETAILS.INSTITUTION_NUMBER}>
                {INSTITUTION_NUMBER_LABEL}
              </Label>
              <TextInput
                name={INVESTOR_DETAILS.INSTITUTION_NUMBER}
                disableAutocomplete
                disabled={isFormDisabled || isInvestorVerified}
              />
            </FormGroup>
          )}{' '}
          {isEditing && (
            <ResendInviteContainer>
              <ResendInvite
                lastInviteSent={lastInviteSentAt}
                investorUuid={investorUuid}
                disabled={isFormDisabled}
                isResendAllowed={isResendAllowed}
              />
            </ResendInviteContainer>
          )}
        </FormContainer>
        <ButtonsBlock>
          <TextButton onClick={closeForm}>{EXIT_BUTTON_LABEL}</TextButton>
          <StyledButton
            disabled={!dirty || !isValid || isSubmitting}
            type="submit"
            data-e2e="complete-add-investor"
          >
            {isEditing ? SAVE : SUBMIT_BUTTON_LABEL}
          </StyledButton>
        </ButtonsBlock>
      </Form>
    );
  };

export default InvestorDetails;
