import { createSelector } from 'reselect';

const getOrderDocumentsState = state => state.orderDocuments;
export const getOrderDocumentsItems = createSelector(
  getOrderDocumentsState,
  ({ data: { items } }) => items,
);
export const getOrderDocumentsUploading = createSelector(
  getOrderDocumentsState,
  ({ isUploading }) => isUploading,
);
export const getOrderDocumentsLoading = createSelector(
  getOrderDocumentsState,
  ({ loading }) => loading,
);
export const getOrderDocumentsErrors = createSelector(
  getOrderDocumentsState,
  ({ errors }) => errors,
);
export const getOrderDocumentsPagination = createSelector(
  getOrderDocumentsState,
  ({
    data: {
      meta: { pagination },
    },
  }) => pagination || {},
);
