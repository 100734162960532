import { useEnvVar } from '@frontend/config';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers/app-config';
import { getDealAlias } from 'helpers/get-deal-alias';

const placementDisabled = useFeatureFlag(FeatureFlag.PLACEMENT_DISABLED);
const isAllInvestorTabEnable = useEnvVar('ENABLE_ALL_INVESTOR_TAB');

export default {
  Operations: () =>
    [
      {
        title: `All ${getDealAlias({ plural: true })}`,
        to: Routes.Globacap.Deals(),
        exact: true,
        hidden: placementDisabled,
      },
      {
        title: 'All Investors',
        to: Routes.Globacap.Investors(),
        hidden: placementDisabled,
      },
    ].filter(({ hidden }) => !hidden),
  MultiCompany: () =>
    [
      {
        title: `All ${getDealAlias({ plural: true })}`,
        to: Routes.MultiCompany.Deals(),
        exact: true,
        hidden: placementDisabled,
      },
    ].filter(({ hidden }) => !hidden),
  Company: () =>
    [
      {
        title: getDealAlias({ plural: true }),
        to: Routes.Admin.DealManagementDeals(),
        exact: true,
        hidden: placementDisabled,
      },
      ...(isAllInvestorTabEnable
        ? [
            {
              title: 'All Investors',
              to: Routes.Admin.DealManagementInvestors(),
              hidden: placementDisabled,
            },
          ]
        : []),
    ].filter(({ hidden }) => !hidden),
};
