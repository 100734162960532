import { PlatformSettings } from '@frontend/config';
import { formatDate, LDF } from '@frontend/datetime';

import { Deal } from 'app-state/deals/types';

import Visible from 'shared-parts/components/svg-icons/visible';
import { COLORS, tableColumnStyles } from 'shared-parts/constants';
import {
  addCommasToNumber,
  addCurrencySign,
  cutZeros,
  toDefinedFractionSizeNoRound,
  valueOrHyphen,
} from 'shared-parts/helpers/format-number';
import { getOrderStatus } from 'shared-parts/helpers/order-statuses';

import type { Order } from './types';

const { neutral800 } = COLORS;

export const DOWNLOAD_BUTTON_LABEL = 'Download list of all orders';
export const CANCEL_ORDER_TEXT = 'CANCEL ORDER';

export const ORDER_TABLE_HEADER = (
  regulatoryEnvironment: PlatformSettings['regulatoryEnvironment'],
) => [
  { name: 'Date Initiated', sorting: { param: 'created_at' } },
  { name: 'Investor', sorting: { param: 'investor_name' } },
  { name: 'User', sorting: { param: 'investor_user_name' } },
  { name: 'No of Shares', sorting: { param: 'intended_number_of_tokens' } },
  { name: 'Amount', sorting: { param: 'intended_amount' } },
  { name: 'Investment fees' },
  { name: regulatoryEnvironment.value === 'FINRA' ? 'Status' : 'Order Status' },
  { name: '' },
];

export const getTableKeys = (
  deal: Deal,
  regulatoryEnvironment: PlatformSettings['regulatoryEnvironment'],
) => [
  {
    field: 'createdAt',
    transform: (value: string): string => formatDate(value, LDF.DATE_TEXTUAL),
  },
  { field: 'investorName' },
  { field: 'investorUserName' },
  {
    field: 'intendedNumberOfTokens',
    transform: (number: number): string => addCommasToNumber(cutZeros(number)),
    styles: (): string => 'text-align: center;',
  },
  {
    field: 'intendedAmount',
    transform: (amount: string, order: Order): string =>
      valueOrHyphen(
        amount,
        addCurrencySign(toDefinedFractionSizeNoRound(amount, 2), order.currency),
      ),
    styles: (): string => 'text-align: center;',
  },
  {
    field: 'totalFeesDue',
    transform: (value: string, order: Order): string =>
      valueOrHyphen(value, addCurrencySign(toDefinedFractionSizeNoRound(value, 2), order.currency)),
    styles: (): string => 'text-align: center;',
  },
  {
    field: 'status',
    styles: (status: string, { reviewStatus }: { reviewStatus: string }): string => `
      color: ${getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).color};
      text-align: center;
    `,
    transform: (status: string, { reviewStatus }: { reviewStatus: string }): string =>
      getOrderStatus({ deal, status, reviewStatus, regulatoryEnvironment }).status,
  },
  {
    text: Visible(17, 17, neutral800),
    styles: (): string => tableColumnStyles.action,
  },
];

export const ORDER_DETAILS_TABLE_HEADER = (
  regulatoryEnvironment: PlatformSettings['regulatoryEnvironment'],
) => [
  { name: regulatoryEnvironment.value === 'FINRA' ? 'Original Amount' : 'Original Order' },
  { name: 'Latest Adjusted Amount' },
  { name: 'Final Amount' },
  { name: 'Status' },
  { name: 'Action' },
];

export enum ACTIONS {
  APPROVE = 'approve',
  ADJUST = 'adjust',
  ACCEPT_ADJUSTMENT = 'accept-adjustment',
  CANCEL = 'cancel',
  MARK_AS_FUNDS_RECEIVED = 'markAsFundsReceived',
}
