import { useEnvVar } from '@frontend/config';

import * as AccessPermissions from 'constants/access-permissions';
import Routes from 'constants/routes';
import { checkUniqueAccess } from 'helpers/check-access';

export const getLoginRedirectionUrlForCompanyUser = companyId => {
  const isCapTableDisabled =
    useEnvVar('DISABLED_CAP_TABLE_MANAGEMENT') ||
    useEnvVar('ENABLE_MULTIPLE_ISSUERS_BUT_DISABLE_CAP_TABLE_PAGES');
  if (!isCapTableDisabled) {
    if (checkUniqueAccess([AccessPermissions.DASHBOARD])) {
      return Routes.Admin.CompanyOverview(companyId);
    }

    if (
      checkUniqueAccess(
        [
          AccessPermissions.SHAREHOLDER_REGISTRY,
          AccessPermissions.SHARE_CLASS,
          AccessPermissions.SHAREHOLDERS,
          AccessPermissions.SHARE_OPTIONS_EDIT,
          AccessPermissions.COMMUNICATIONS_VIEW,
        ],
        'any',
      )
    ) {
      return Routes.Admin.EquityAdministration(companyId);
    }
  }

  if (checkUniqueAccess([AccessPermissions.DEALS])) {
    return Routes.Admin.DealManagement();
  }

  return Routes.Admin.Account();
};
