import { useEnvVar } from '@frontend/config';

import { DEAL_TYPES, SECURITY_TYPES } from 'app-state/deals/types';

import { getDealAlias } from 'helpers/get-deal-alias';

export const SECURITY_TYPE_LABEL = 'Security Type';
export const AUTO_RADIOS_LABEL = 'Would you like to';
export const SHARE_CLASS_CURRENCY_LABEL = 'Currency';
export const ORDER_CURRENCIES_LABEL = 'Order Currency';
export const DEAL_NAME_LABEL = `${getDealAlias()} Name`;
export const DEAL_TYPE_LABEL = `${getDealAlias()} Type`;
export const DEAL_PAGE_URL_LABEL = `${getDealAlias()} Page URL`;
export const SHARE_CLASS_LABEL = 'Share Class';
export const DEAL_CURRENCY_LABEL = `${getDealAlias()} Currency`;
export const NUMBER_OF_SHARES_LABEL = '№ of Shares for Issuance';
export const SHARES_PRECISION_LABEL = 'Share Precision';
export const SECURITY_NAME_LABEL = 'Security Name';
export const HEADLINE_TARGET_AMOUNT_LABEL = 'Headline Target Raise Amount';
export const HARD_CAP_LABEL = 'Hard Cap';
export const PRE_LAUNCH_RAISED_AMOUNT_LABEL = 'Pre-launch Raised Amount';
export const MINIMUM_SHARE_AMOUNT_LABEL = 'Minimum No of Shares per Order';
export const MINIMUM_SUBSCRIPTION_AMOUNT_LABEL = 'Minimum Subscription Amount';
export const MAXIMUM_SUBSCRIPTION_ALLOWED_LABEL = 'Maximum Subscription Amount';
export const MAXIMUM_INVESTABLE_UNITS_LABEL = 'Maximum No of Shares per Order';
export const INDICATIVE_SHARE_PRICE_LABEL = 'Indicative Share Price';
export const FINAL_SCORE_PRICE_LABEL = 'Final Share Price';
export const PRE_MONEY_VALUATION_LABEL = 'Pre-Money Valuation';
export const LAUNCH_DATE_TIME_LABEL = 'Launch Date';
export const CLOSE_DATE_TIME_LABEL = 'Close Date';
export const REQUIRE_CLOSE_DATE = 'Does this placement round have a close date?';
export const ORDERS_MUST_BE_FILLED_BY_LABEL = 'Orders must be filled by';
export const TIMEZONE_LABEL = 'Timezone';
export const PRIVACY_SETTINGS_LABEL = 'What are the privacy settings for this placement?';
export const DECIMAL_POINTS_LABEL = 'Decimal Points';
export const TIMELINE_LABEL =
  'The timeline is where a date must be set for each milestone of the raise.';
export const TIMELINE_FIRST_STEP_LABEL = 'The placement page is published on the Investor Platform';
export const OPTIONAL_LABEL = ' (optional)';

export const TIMELINE_INFO_1 =
  'The amount of time that lapses between each milestone will be unique to your placement, but they must take place in chronological order. The dates you set can be updated as the placement progresses.';
export const TIMELINE_INFO_2 =
  'After you have completed this form, the next step is to create and publish the investor page for the raise. The investor page must be published before the other milestones can take place.';
export const AUTO_CANCEL_TOGGLE = 'Auto-cancel order';
export const AUTO_APPROVE_TOGGLE = 'Auto-approve order';
export const SPA_REQUIRED_TOGGLE = 'Does this placement require a Purchase Agreement?';

export const SELECT_PLACEHOLDER = '—';

export const DEAL_URL_TOOLTIP =
  'This is the web address that will be displayed when an investor views the placement info pages';
export const PRIVACY_SETTINGS_TOOLTIP =
  'An ‘open to all’ placement is open to all investors registered on the platform';
const AUTO_APPROVE_TOOLTIP =
  'When auto-approve is enabled, all new orders will automatically be approved';
const AUTO_CANCEL_TOOLTIP =
  'When auto-cancel is enabled, any orders that haven’t been funded by the transfer deadline will automatically be cancelled';
const SPA_REQUIRED_TOOLTIP =
  'If a Purchase Agreement is required, it must be uploaded and signed for each order placed';
export const DEAL_CURRENCY_TOOLTIP =
  'The currency in which you will receive investors’ funds; also the currency for the issuance';
export const HEADLINE_TARGET_AMOUNT_TOOLTIP =
  'The target amount for your raise; this will be displayed to potential investors';
export const HARD_CAP_TOOLTIP = 'The maximum acceptable amount for the raise';
export const PRE_MONEY_VALUATION_TOOLTIP = 'The value of the company before the raise';
export const PRE_LAUNCH_RAISED_AMOUNT_TOOLTIP =
  'Any funds already received as a part of this raise (off-platform)';
export const FINAL_SHARE_PRICE_TOOLTIP =
  'The final share price is the price to be paid by investors for each share; it must be set before any orders can be placed';
export const INDICATIVE_SHARE_PRICE_TOOLTIP = 'Estimated share price for the raise';
export const NUMBER_OF_SHARES_TOOLTIP =
  'The maximum number of shares that can be issued through the raise; calculated by dividing the hard cap by the final share price';
export const MINIMUM_SUBSCRIPTION_AMOUNT_TOOLTIP =
  'The minimum order value; calculated by multiplying the final share price by the minimum share amount';
export const MAXIMUM_SUBSCRIPTION_ALLOWED_TOOLTIP =
  'The maximum order value; calculated by multiplying the final share price by the maximum share amount';
export const LAUNCH_DATE_TIME_TOOLTIP = 'Investors can place orders';
export const CLOSE_DATE_TIME_TOOLTIP = 'The last day for investors to place new orders';
export const ORDER_CURRENCIES_TOOLTIP = 'The currencies which orders can be placed in';
export const SHARE_CLASS_TOOLTIP = 'Choose or create a share class to set up the placement';
export const ORDERS_MUST_BE_FILLED_BY_TOOLTIP =
  'The date by which investors must fund their approved orders';

export const SHARE_CLASS_CREATED_MODAL_HEADER = 'New share class created';
export const SHARE_CLASS_CREATED_MODAL_PROCEED_TEXT = 'OK';

export enum ISSUER_DETAILS {
  NAME = 'issuerName',
  COMPANY_NUMBER = 'companyNumber',
  CITY = 'city',
  STATE = 'state',
  POST_CODE = 'postCode',
  LINE1 = 'line1',
  LINE2 = 'line2',
  ADDRESS_FIRST_LINE = 'addressFirstLine',
  COUNTRY_CODE = 'countryCode',
}

export enum PLACEMENT_DETAILS {
  CAPITAL_TYPE = 'dealType',
  SECURITY_TYPE = 'ownershipUnit',
  NAME = 'name',
  PAGE_URL = 'permalink',
  SPA_REQUIRED = 'spaRequired',
  PRIVACY_SETTINGS = 'private',
  AUTO_APPROVE_ORDERS = 'autoApproveOrders',
  AUTO_CANCEL_ORDERS = 'autoCancelOrders',
  SET_ALL_AS_ANCHOR_INVESTORS = 'allAnchorInvestors',
}

export enum STRUCTURE_DETAILS {
  DEAL_CURRENCY = 'baseCurrency',
  ORDER_CURRENCIES = 'orderCurrencies',
  HEADLINE_TARGET_AMOUNT = 'softTargetAmount',
  HARD_CAP = 'hardCapAmount',
  PRE_MONEY_VALUATION = 'preMoneyValuation',
  PRE_LAUNCH_RAISED_AMOUNT = 'preLaunchRaisedAmount',
}

export enum SECURITY_DETAILS {
  SHARE_CLASS = 'shareClassName',
  SHARE_CLASS_CURRENCY = 'shareClassCurrency',
  SHARES_PRECISION = 'sharePrecision',
  DECIMAL_POINTS = 'decimalPoints',
  SECURITY_NAME = 'securityName',
  INDICATIVE_SHARE_PRICE = 'indicativeSharePrice',
  FINAL_SHARE_PRICE = 'pricePerToken',
  NUMBER_OF_SHARES = 'totalTokensEmited',
  MINIMUM_SHARE_AMOUNT = 'minInvestableUnits',
  MINIMUM_SUBSCRIPTION_AMOUNT = 'minimumSubscriptionAmount',
  MAXIMUM_INVESTABLE_UNITS = 'maxInvestableUnits',
  MAXIMUM_SUBSCRIPTION_ALLOWED = 'targetAmount',
}

export enum TIMELINE_DETAILS {
  LAUNCH_DATE_TIME = 'subscriptionStart',
  CLOSE_DATE_REQUIRED = 'requireCloseDate',
  CLOSE_DATE_TIME = 'subscriptionEnd',
  ORDERS_MUST_BE_FILLED_BY = 'ordersMustBeFilledAt',
  TIMEZONE = 'timezone',
  TIMEZONE_OFFSET_NUMBER = 'timezoneOffsetNumber',
}

export enum DOCUMENTS_DETAILS {
  NDA_REQUIRED = 'ndaRequired',
  SIGNATURE_TYPE = 'ndaProcess',
}

export enum ACCESS_PERMISSIONS_DETAILS {
  AGENT_PERMISSIONS = 'agentPermissions',
}

export enum PAYMENTS_DETAILS {
  BANK_ACCOUNTS = 'bankAccounts',
}

export const ISSUER_NAME_LABEL = 'Issuer Name';
export const ISSUER_COMPANY_NUMBER_LABEL = 'Company Registration No';
export const ADDRESS_FIRST_LINE_LABEL = 'Registered address';
export const CITY_LABEL = 'City';
export const POST_CODE_LABEL = 'Postal / Zip Code';
export const COUNTRY_CODE_LABEL = 'Country of Registration';

export const RADIO_OPTION_YES_VALUE = 'yes';
export const RADIO_OPTION_NO_VALUE = 'no';

export const PRIVACY_SETTINGS_RADIO_CHOICES = [
  {
    text: 'Open to all',
    value: RADIO_OPTION_NO_VALUE,
  },
  {
    text: 'Invite only',
    value: RADIO_OPTION_YES_VALUE,
  },
];

export const INITIAL_DEAL_STRUCTURE_FORM_DATA = {
  [ISSUER_DETAILS.NAME]: '',
  [ISSUER_DETAILS.CITY]: '',
  [ISSUER_DETAILS.STATE]: '',
  [ISSUER_DETAILS.POST_CODE]: '',
  [ISSUER_DETAILS.LINE1]: '',
  [ISSUER_DETAILS.LINE2]: '',
  [ISSUER_DETAILS.COUNTRY_CODE]: '',
  [ISSUER_DETAILS.COMPANY_NUMBER]: '',
  [ISSUER_DETAILS.ADDRESS_FIRST_LINE]: '',
  [PLACEMENT_DETAILS.NAME]: '',
  [PLACEMENT_DETAILS.CAPITAL_TYPE]: DEAL_TYPES.EQUITY,
  [PLACEMENT_DETAILS.SECURITY_TYPE]: SECURITY_TYPES.SHARES,
  [PLACEMENT_DETAILS.PAGE_URL]: '',
  [SECURITY_DETAILS.SHARE_CLASS]: '',
  [SECURITY_DETAILS.SHARE_CLASS_CURRENCY]: '',
  [STRUCTURE_DETAILS.DEAL_CURRENCY]: '',
  [STRUCTURE_DETAILS.ORDER_CURRENCIES]: [],
  [SECURITY_DETAILS.NUMBER_OF_SHARES]: '',
  [SECURITY_DETAILS.SHARES_PRECISION]: '',
  [SECURITY_DETAILS.SECURITY_NAME]: '',
  [STRUCTURE_DETAILS.HEADLINE_TARGET_AMOUNT]: '',
  [STRUCTURE_DETAILS.HARD_CAP]: '',
  [STRUCTURE_DETAILS.PRE_LAUNCH_RAISED_AMOUNT]: '',
  [TIMELINE_DETAILS.LAUNCH_DATE_TIME]: '',
  [TIMELINE_DETAILS.CLOSE_DATE_REQUIRED]: RADIO_OPTION_YES_VALUE,
  [TIMELINE_DETAILS.CLOSE_DATE_TIME]: undefined,
  [TIMELINE_DETAILS.ORDERS_MUST_BE_FILLED_BY]: undefined,
  [TIMELINE_DETAILS.TIMEZONE]: '',
  [TIMELINE_DETAILS.TIMEZONE_OFFSET_NUMBER]: 0,
  [SECURITY_DETAILS.DECIMAL_POINTS]: '',
  [SECURITY_DETAILS.MAXIMUM_INVESTABLE_UNITS]: '',
  [SECURITY_DETAILS.MAXIMUM_SUBSCRIPTION_ALLOWED]: '',
  [SECURITY_DETAILS.MINIMUM_SHARE_AMOUNT]: '',
  [SECURITY_DETAILS.MINIMUM_SUBSCRIPTION_AMOUNT]: '',
  [SECURITY_DETAILS.INDICATIVE_SHARE_PRICE]: '',
  [SECURITY_DETAILS.FINAL_SHARE_PRICE]: '',
  [STRUCTURE_DETAILS.PRE_MONEY_VALUATION]: '',
  [PLACEMENT_DETAILS.PRIVACY_SETTINGS]: RADIO_OPTION_YES_VALUE,
  [PLACEMENT_DETAILS.AUTO_CANCEL_ORDERS]: 'Off',
  [PLACEMENT_DETAILS.AUTO_APPROVE_ORDERS]: 'Off',
  [PLACEMENT_DETAILS.SPA_REQUIRED]: useEnvVar('ENABLED_DEAL_STRUCTURE_FORM_SPA_DEFAULT_VALUE')
    ? 'Yes'
    : 'No',
  [PLACEMENT_DETAILS.SET_ALL_AS_ANCHOR_INVESTORS]: false,
  [PAYMENTS_DETAILS.BANK_ACCOUNTS]: [],
};

export const NDA_DOCUMENT_UPLOAD = 'NDA DOCUMENT';
export const TERM_SHEET_UPLOAD = 'TERM SHEET';

export const NDA_REQUIREMENT_LABEL = 'Does this placement require an NDA?';
export const NDA_REQUIREMENT_NAME = 'ndaRequired';
export const RADIO_OPTION_YES = 'Yes';
export const RADIO_OPTION_NO = 'No';

export const NDA_PROCESS_FLOW_LABEL = 'Please select the signature type required';
export const NDA_PROCESS_FLOW_NAME = 'ndaProcess';
export const RADIO_OPTION_TICK_BOX = 'Tick Box';
export const RADIO_OPTION_E_SIGNATURE = 'e-Signature';
export const RADIO_OPTION_STANDARD_SIGNATURE = 'Standard Signature';
export const RADIO_OPTION_TICK_BOX_VALUE = 'tick_box';
export const RADIO_OPTION_E_SIGNATURE_VALUE = 'e_signature';
export const RADIO_OPTION_STANDARD_SIGNATURE_VALUE = 'standard_signature';

export const NDA_TOOLTIP_MESSAGE =
  'The NDA document must be uploaded before the placement can go live';

export const UPLOAD_TERM_SHEET_LABEL = 'Upload Term Sheet';
export const RE_UPLOAD_TERM_SHEET_LABEL = 'Upload New Term Sheet';

export const UPLOAD_NDA_LABEL = 'Upload NDA Document';
export const RE_UPLOAD_NDA_LABEL = 'Upload New NDA Document';
export const UPLOAD_NDA_E_SIGNATURE_LABEL = 'Upload NDA Document for e-signing';
export const RE_UPLOAD_NDA_E_SIGNATURE_LABEL = 'Upload New NDA Document for e-signing';

export const NDA_DOCUMENT_TYPE = 'NdaDocument';
export const TERM_SHEET_DOCUMENT_TYPE = 'TermSheetDocument';

export const DEFAULT_ERROR_MESSAGE = 'Please try again';
export const FILE_UPLOAD_FAILED_MESSAGE = 'FILE UPLOAD FAILED';

export const NO_NDA_MESSAGE = 'No NDA document uploaded';
export const NO_TERM_SHEET_MESSAGE = 'No term sheet uploaded';

export const DEFAULT_UPLOAD_INFO = 'All documents uploaded should be in PDF format';
export const NDA_UPLOAD_TOP_INFO = `After uploading a PDF copy of the NDA, you will
be asked to assign any fields that the investor must complete. Any additional signatures
required (i.e. on behalf of Issuer Name) should be added to the document before it is
uploaded to the platform.`;
export const NDA_UPLOAD_BOTTOM_INFO =
  'You will receive a notification via email whenever an investor signs the NDA.';

export const ALL_ANCHOR_INVESTORS_LABEL = 'Set all investors as anchor investors';
export const ALL_ANCHOR_INVESTORS_INFO =
  'Please remember to uncheck this when you wish you to invite investors as follow investors.';

export const DEAL_TYPE_OPTIONS = [
  { text: 'Debt', value: DEAL_TYPES.DEBT },
  { text: 'Equity', value: DEAL_TYPES.EQUITY },
  { text: 'Fund', value: DEAL_TYPES.FUND },
];

export const SECURITY_TYPE_OPTIONS = [
  { text: 'Shares', value: SECURITY_TYPES.SHARES },
  { text: 'Convertible Note', value: SECURITY_TYPES.CONVERTIBLE_NOTE },
  {
    text: 'Non convertible corporate loan',
    value: SECURITY_TYPES.NON_CONVERTIBLE_CORPORATE_LOAN,
  },
  { text: 'SAFE', value: SECURITY_TYPES.SAFE },
  { text: 'Digital Bond', value: SECURITY_TYPES.DIGITAL_BOND },
  { text: 'Token', value: SECURITY_TYPES.TOKEN },
  { text: 'Investment', value: SECURITY_TYPES.INVESTMENT },
];

export const DECIMAL_POINTS_OPTIONS = [
  { text: '1 (i.e.: 0.1)', value: '1' },
  { text: '2 (i.e.: 0.01)', value: '2' },
  { text: '3 (i.e.: 0.001)', value: '3' },
  { text: '4 (i.e.: 0.0001)', value: '4' },
  { text: '5 (i.e.: 0.00001)', value: '5' },
  { text: '6 (i.e.: 0.000001)', value: '6' },
  { text: '7 (i.e.: 0.0000001)', value: '7' },
  { text: '8 (i.e.: 0.00000001)', value: '8' },
];

export enum SHARES_PRECISION_TYPES {
  FRACTIONAL = 'Fractional',
  WHOLE = 'Whole',
}

export const PLACEMENT_DETAILS_RADIO_OPTIONS = [
  {
    labelName: SPA_REQUIRED_TOGGLE,
    name: PLACEMENT_DETAILS.SPA_REQUIRED,
    firstRadioLabel: 'Yes',
    secondRadioLabel: 'No',
    tooltip: true,
    tooltipMessage: SPA_REQUIRED_TOOLTIP,
  },
];

export const PLACEMENT_DETAILS_GROUPED_RADIO_OPTIONS = [
  {
    labelName: AUTO_APPROVE_TOGGLE,
    name: PLACEMENT_DETAILS.AUTO_APPROVE_ORDERS,
    firstRadioLabel: 'On',
    secondRadioLabel: 'Off',
    tooltip: true,
    tooltipMessage: AUTO_APPROVE_TOOLTIP,
  },
  {
    labelName: AUTO_CANCEL_TOGGLE,
    name: PLACEMENT_DETAILS.AUTO_CANCEL_ORDERS,
    firstRadioLabel: 'On',
    secondRadioLabel: 'Off',
    tooltip: true,
    tooltipMessage: AUTO_CANCEL_TOOLTIP,
  },
];

export const SHARES_PRECISION_OPTIONS = [
  { text: 'Whole', value: SHARES_PRECISION_TYPES.WHOLE },
  { text: 'Fractional', value: SHARES_PRECISION_TYPES.FRACTIONAL },
];

export enum STEPS {
  ISSUER = 'issuer',
  PLACEMENT = 'placementDetails',
  STRUCTURE = 'structure',
  SECURITY = 'security',
  TIMELINE = 'timeline',
}

export enum STEP_NAMES {
  ISSUER = 'Issuer',
  PLACEMENT = 'Placement Settings',
  STRUCTURE = 'Structure',
  SECURITY = 'Security Details',
  TIMELINE = 'Timeline',
  DOCUMENTS = 'Documents',
  ACCESS_PERMISSIONS = 'Access Permissions',
  PAYMENTS = 'Payment Details',
}

export enum EDIT_DEAL_TAB_KEYS {
  ISSUER = 'issuer',
  PLACEMENT = 'placementSettings',
  SECURITY = 'securityDetails',
  STRUCTURE = 'structure',
  TIMELINE = 'timeline',
  DOCUMENTS = 'documents',
  ACCESS_PERMISSIONS = 'accessPermissions',
  PAYMENTS = 'paymentsDetails',
}
