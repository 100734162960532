export const FETCH_INDICATION_OF_INTEREST_DATA = 'FETCH_INDICATION_OF_INTEREST_DATA';
export const FETCH_INDICATION_OF_INTEREST_DATA_SUCCESS =
  'FETCH_INDICATION_OF_INTEREST_DATA_SUCCESS';
export const FETCH_INDICATION_OF_INTEREST_DATA_ERROR = 'FETCH_INDICATION_OF_INTEREST_DATA_ERROR';
export const RESET_INDICATION_OF_INTEREST_DATA = 'RESET_INDICATION_OF_INTEREST_DATA';

export const FETCH_DEAL_IOI_DETAILS = 'FETCH_DEAL_IOI_DETAILS';
export const FETCH_DEAL_IOI_DETAILS_SUCCESS = 'FETCH_DEAL_IOI_DETAILS_SUCCESS';
export const FETCH_DEAL_IOI_DETAILS_ERROR = 'FETCH_DEAL_IOI_DETAILS_ERROR';
export const RESET_DEAL_IOI_DETAILS = 'RESET_DEAL_IOI_DETAILS';

export const ADJUST_DEAL_IOI = 'ADJUST_DEAL_IOI';

export const APPROVE_DEAL_IOI = 'APPROVE_DEAL_IOI';
export const APPROVE_DEAL_IOI_SUCCESS = 'APPROVE_DEAL_IOI_SUCCESS';
export const APPROVE_DEAL_IOI_ERROR = 'APPROVE_DEAL_IOI_ERROR';

export const CANCEL_DEAL_IOI = 'CANCEL_DEAL_IOI';
export const CANCEL_DEAL_IOI_SUCCESS = 'CANCEL_DEAL_IOI_SUCCESS';
export const CANCEL_DEAL_IOI_ERROR = 'CANCEL_DEAL_IOI_ERROR';
