import { IssuanceStatus } from '@frontend/opportunities';

import {
  CREATE_DEAL,
  GET_DEALS,
  GET_DEALS_ERROR,
  GET_DEALS_SUCCESS,
  GET_USER_DEALS,
  GET_USER_DEALS_ERROR,
  GET_USER_DEALS_SUCCESS,
  RESET_DEAL_STRUCTURE_FORM_ERROR,
  RESET_USER_DEALS,
  SET_DEAL_STRUCTURE_FORM_ERROR,
} from 'app-state/deals/constants';
import type { BaseState, Errors } from 'app-state/types';

import { NewDealAccessPermission } from 'modules/company/deal-management/deal-page/contexts';

export enum DEAL_TYPES {
  DEBT = 'debt',
  EQUITY = 'equity',
  FUND = 'fund',
  ALTERNATIVE = 'alternative_investment_vehicle',
}

export enum SECURITY_TYPES {
  SHARES = 'share',
  CONVERTIBLE_NOTE = 'convertible note',
  NON_CONVERTIBLE_CORPORATE_LOAN = 'non convertible corporate loan',
  SAFE = 'SAFE',
  DIGITAL_BOND = 'digital bond',
  INVESTMENT = 'investment',
  TOKEN = 'token',
}

// Added for new Closed-End Fund (IM) product. We need to extend it to more types and remove this comment
export enum SECURITY_SUBTYPE {
  CLOSED_END = 'closed_end',
}

export interface Deal {
  allowedCurrencies: string[];
  availableTokens: string;
  baseCurrency: string;
  company: {
    countryCode: string;
    description: string;
    equity: string | null;
    id: number;
    incorporationDate: string | null;
    incorporationId: string | null;
    name: string;
    uuid: string;
  };
  createdAt: string;
  dealType: DEAL_TYPES.DEBT | DEAL_TYPES.EQUITY | DEAL_TYPES.ALTERNATIVE;
  securitySubtype?: SECURITY_SUBTYPE;
  description: string | null;
  equityPercentage: string;
  id: number;
  images: {
    background: {
      cover: string;
      small: string;
    };
    logo: {
      cover: string;
      small: string;
    };
  };
  minInvestableUnits: number;
  maxInvestableUnits: number;
  name: string;
  ownershipUnit: string;
  permalink: string;
  pricePerToken: string;
  published: boolean;
  raisedAmount: string;
  securityName: string;
  softTargetAmount: number;
  status: IssuanceStatus;
  subscriptionEnd: string;
  autoCancelOrders: string | boolean;
  autoApproveOrders: string | boolean;
  allAnchorInvestors: boolean;
  spaRequired: string | boolean;
  subscriptionStart: string;
  ordersMustBeFilledAt: string;
  targetAmount: number;
  totalTokensEmited: number;
  totalTokensSold: string;
  unitTruncateScale: number;
  preLaunchRaisedAmount: number;
  investmentAmount: number;
  indicativeSharePrice: number;
  preMoneyValuation: number;
  decimalPoints: number;
  sharePrecision: string;
  private: boolean;
  type: string;
  timezone: string;
  timezoneOffsetNumber: number;
  uuid: string;
  agentPermissions: NewDealAccessPermission[];
  drawdownThreshold: string;
  dealDetail: any;
}

export interface DealsState extends BaseState {
  data: Deal[];
}

interface GetDealsAction {
  type: typeof GET_DEALS;
}

interface SetDealStructureFormErrorAction {
  type: typeof SET_DEAL_STRUCTURE_FORM_ERROR;
  payload: Errors;
}

interface ResetDealStructureFormErrorAction {
  type: typeof RESET_DEAL_STRUCTURE_FORM_ERROR;
}

interface GetDealsSuccessAction {
  type: typeof GET_DEALS_SUCCESS;
  data: Deal[];
}

interface GetDealsErrorAction {
  type: typeof GET_DEALS_ERROR;
  errors: Errors;
}

export interface CreateDealActionPayload {
  values: Deal;
}

export interface CreateDealAction {
  type: typeof CREATE_DEAL;
  payload: CreateDealActionPayload;
}

export type DealsActionTypes =
  | GetDealsAction
  | GetDealsSuccessAction
  | GetDealsErrorAction
  | CreateDealAction
  | SetDealStructureFormErrorAction
  | ResetDealStructureFormErrorAction;

export interface UserDealsState extends BaseState {
  data: UserDeal[];
}

export interface UserDeal extends Deal {
  accessType: AccessType;
}

export type AccessType = 'full_access' | 'deal_summary_only';

interface GetUserDealsAction {
  type: typeof GET_USER_DEALS;
  payload: { companyUuid: string };
}

interface GetUserDealsSuccessAction {
  type: typeof GET_USER_DEALS_SUCCESS;
  data: UserDeal[];
}

interface GetUserDealsErrorAction {
  type: typeof GET_USER_DEALS_ERROR;
  errors: Errors;
}

interface ResetUserDealsErrorAction {
  type: typeof RESET_USER_DEALS;
}

export type UserDealsActionTypes =
  | GetUserDealsAction
  | GetUserDealsSuccessAction
  | GetUserDealsErrorAction
  | ResetUserDealsErrorAction;
