import { CheckMarkIcon, XIcon } from 'svg-icons';
import { COLORS } from 'shared-parts/constants';

import { Tumbler, TumblerButton, TumblerLabel } from './tumbler.styled';

export default ({
  turnedOn,
  turnedOnText = 'EDIT ON',
  turnedOffText = 'EDIT OFF',
  handleClick,
  disabled = false,
  width = '89px',
  e2e = 'tumbler',
}) => (
  <Tumbler
    width={width}
    turnedOn={turnedOn}
    onClick={disabled ? undefined : handleClick}
    disabled={disabled}
  >
    <TumblerButton turnedOn={turnedOn} disabled={disabled} data-e2e={e2e}>
      {turnedOn ? CheckMarkIcon() : XIcon(7, 7, COLORS.systemRed)}
    </TumblerButton>
    <TumblerLabel turnedOn={turnedOn}>{turnedOn ? turnedOnText : turnedOffText}</TumblerLabel>
  </Tumbler>
);
