import { call, select, takeEvery } from 'redux-saga/effects';

import { UPDATE_NICKELLED_USER } from 'app-state/constants/shared';
import { getUser } from 'app-state/selectors/authentication';
import { getCompany } from 'app-state/selectors/companies';

import API from 'constants/api';
import { useEnvVar } from 'helpers/use-env-var';
import request from 'shared-parts/helpers/request';

function* updateNickelledUser() {
  try {
    const user = yield select(getUser);
    const { data: company } = yield select(getCompany);

    const isUserOperationalAdmin = user.operationalAdmin;
    const canFetchUserDetails = user.uuid && company.uuid;
    const { data: userDetails } =
      !isUserOperationalAdmin && canFetchUserDetails
        ? yield call(request, API.AccountDetails(company.uuid, user.uuid))
        : { data: {} };

    window.NickelledLaunchers.setUser({
      appId: useEnvVar('NICKELLED_ID'),
      userId: user.uuid,
      onboardingStatus: user.onboardingStatus,
      isMulticompanyAdmin: user.isMulticompanyAdmin,
      email: user.email,
      companyName: company.name,
      subscriptionTemplate: company.subscription && company.subscription.subscriptionTemplateId,
      roleType: isUserOperationalAdmin ? 'operational_admin' : userDetails.permissionLevel,
    });
  } catch (e) {
    // TODO: Handle errors
  }
}

function* updateNickelledUserWatcher() {
  yield takeEvery(UPDATE_NICKELLED_USER, updateNickelledUser);
}

export { updateNickelledUserWatcher };
