export const DEAL_SETUP_TAB_1_HEADER = 'Branding';
export const DEAL_SETUP_TAB_2_HEADER = 'Opportunity tile';
export const DEAL_SETUP_TAB_3_HEADER = 'Investor page content';

export const CONFIRM_PUBLISH_REQUEST_MODAL_TITLE = 'Request to publish page';
export const CONFIRM_PUBLISH_REQUEST_MODAL_TEXT = `
  Are you sure you want to continue? Once published, the deal
  tile and deal pages will be visible to any investors invited.
`;

export const CONFIRM_PUBLISH_MODAL_TITLE = 'Publish page';
export const CONFIRM_PUBLISH_MODAL_TEXT = `
  Are you sure you want to continue? Once published, the pages
  will be visible to any investors that are invited to the deal.
`;

export const CONFIRM_UNPUBLISH_MODAL_TITLE = 'Unpublish page';
export const CONFIRM_UNPUBLISH_MODAL_TEXT = `
  Are you sure you want to continue? Once unpublished, the pages
  will not be visible to any investors that are invited to the deal.
`;

export const ON_TEXT = 'ON';
export const OFF_TEXT = 'OFF';
export const SHOW_AMOUNT_FUNDED_LABEL = 'Show amount funded to date?';
export const SHOW_DAYS_LEFT_LABEL = 'Show number of days left to go?';
export const SHOW_TARGET_AMOUNT_LABEL = 'Show target raise amount?';
export const DISPLAY_ACCESS_CLASS = 'Display asset class?';
export const CANCEL_TEXT = 'Cancel';
export const SUBMIT_TEXT = 'Save';

export const SWITCH_TO_EDIT_MODE_CONFIRMATION_TEXT = `
  You are about to switch to Edit Mode, if you choose to proceed your deal page
  will be unpublished. Any changes made in Edit Mode will be saved and can be
  published by requesting to publish in the Deal Setup page.
`;

export const SWITCH_TO_EDIT_MODE_DISABLED_TEXT =
  'Edit mode has been disabled because one or more investors have already been invited.';

export const UNPUBLISHING_DISABLED_TEXT =
  'Unpublishing has been disabled because one or more investors have already been invited.';
