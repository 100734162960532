import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const boxFileStyles = `
  color: ${COLORS.secondary};
  display: block;
  border-bottom: 1px solid ${COLORS.neutral500};
  padding-bottom: 16px;
  position: relative;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 30px;
`;

const FileAnchor = styled.a`
  ${boxFileStyles}
  :hover, :visited {
    color: ${COLORS.secondary};
  }
`;

export { FileAnchor, boxFileStyles };
