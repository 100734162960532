import { createElement } from 'react';
import { connect } from 'react-redux';

import * as actions from 'app-state/actions';
import * as selectors from 'app-state/selectors';

import { OverlayContainer, OverlayWrapper } from './overlay.styled';

const Overlay = ({ overlayData, hideOverlay }) => {
  const renderComponent = content => createElement(content.component, { hideOverlay, ...content });

  return overlayData.isOpen ? (
    <OverlayWrapper>
      <OverlayContainer width={overlayData.content.width}>
        {renderComponent(overlayData.content)}
      </OverlayContainer>
    </OverlayWrapper>
  ) : null;
};

const mapStateToProps = state => ({
  overlayData: selectors.getOverlaySelector(state),
});

const mapDispatchToProps = dispatch => ({
  hideOverlay: () => dispatch(actions.hideOverlay()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
