import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';

import API from 'constants/api';
import { getUpdatedPageData, request } from 'helpers';

const abortController = {};

function* createTagline({ tagline }) {
  try {
    const { content } = yield select(selectors.getPage) || {};
    const { data } = yield call(request, API.CreateTagline(), 'POST', tagline);

    yield put(actions.updatePage(getUpdatedPageData(content, data, 'createTagline')));
  } catch (e) {
    yield put(actions.createTaglineError(e));
  }
}

function* createTaglineWatcher() {
  yield takeEvery(constants.CREATE_TAGLINE, createTagline);
}

function* editTagline({ tagline }) {
  try {
    abortController[tagline.id] =
      typeof AbortController !== 'undefined' ? new AbortController() : {};

    const { content } = yield select(selectors.getPage) || {};
    const { data } = yield call(request, API.EditTagline(tagline.id), 'PUT', tagline, {
      abortController: abortController[tagline.id],
    });

    yield put(actions.updatePage(getUpdatedPageData(content, data, 'editTagline')));
  } catch (e) {
    yield put(actions.editTaglineError(e));
  }
}

function* editTaglineWatcher() {
  yield takeEvery(constants.EDIT_TAGLINE, editTagline);
}

function* deleteTagline({ tagline }) {
  try {
    const { [tagline.id]: controller } = abortController;

    if (controller && controller.abort) {
      controller.abort();
    }

    const { content } = yield select(selectors.getPage) || {};

    yield call(request, API.DeleteTagline(tagline.id), 'DELETE', tagline);

    yield put(actions.updatePage(getUpdatedPageData(content, tagline, 'deleteTagline')));
  } catch (e) {
    yield put(actions.deleteTaglineError(e));
  }
}

function* deleteTaglineWatcher() {
  yield takeEvery(constants.DELETE_TAGLINE, deleteTagline);
}

export {
  createTagline,
  createTaglineWatcher,
  editTagline,
  editTaglineWatcher,
  deleteTagline,
  deleteTaglineWatcher,
};
