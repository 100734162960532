import styled from 'styled-components';

import { FormButton } from 'shared-parts/components/button';

export const DownloadButton = styled(FormButton)`
  margin: 0 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
