export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CHECK_CREATE_USER_PERMISSION = 'CHECK_CREATE_USER_PERMISSION';
export const CHECK_CREATE_USER_PERMISSION_ERROR = 'CHECK_CREATE_USER_PERMISSION_ERROR';
