import { useContext } from 'react';

import Column from 'shared-parts/components/column';
import { decodeHtml } from 'shared-parts/helpers/html/encode';
import { ComponentsContext } from 'shared-parts/modules/deal/deal.context';

import {
  BoxesDestructureValues,
  BoxesObjectType,
  SectionRegularPropType,
} from '../section-regular/type';

import { CompanyOverviewWrapper, ComponentContainer, Content } from './section-overview.styled';
import { BoxParamType } from './type';

const SectionOverview: React.FC<React.PropsWithChildren<SectionRegularPropType>> = ({
  item,
  dealUuid,
  companyUuid,
}) => {
  const {
    BoxText,
    BoxVideo,
    BoxTable,
    BoxDescriptionList,
    BoxImage,
    BoxFile,
    SectionTitle,
    CompanyOverview,
    OverviewSectionWrapper,
    OverviewTeamComponent,
  } = useContext(ComponentsContext);
  const { CompanySummaryBox, TeamBox }: BoxesObjectType | BoxesDestructureValues =
    item.boxes.reduce((prev, curr) => ({ ...prev, [curr.type]: curr }), {});
  const renderBox = (box: BoxParamType) => {
    const key = `${box.id}${box.type}`;

    switch (box.type) {
      case 'TextBox':
        return <BoxText {...box} key={key} paddingRight="65px" />;
      case 'VideoBox':
        return <BoxVideo {...box} section={item} key={key} />;
      case 'TabularDataBox':
        return <BoxTable {...box} key={key} />;
      case 'DescriptionListBox':
        return <BoxDescriptionList {...box} key={key} />;
      case 'ImageBox':
        return <BoxImage {...box} section={item} key={key} />;
      case 'DocumentsBox':
        return <BoxFile {...box} key={key} />;
      default:
        return null;
    }
  };
  const boxes = item.boxes.map(box => (
    <ComponentContainer key={box.sectionBoxId}>{renderBox(box)}</ComponentContainer>
  ));

  return (
    <CompanyOverviewWrapper>
      <OverviewSectionWrapper item={item} dealUuid={dealUuid}>
        <SectionTitle item={item} isSectionOverview>
          {decodeHtml(item.title)}
        </SectionTitle>
        <Content>
          <Column sm={12} md={7} lg={8} xxl={8} xl={8} xs={8}>
            {boxes}
          </Column>
          <Column sm={12} md={7} lg={8} xxl={4} xl={4} xs={4}>
            <CompanyOverview {...CompanySummaryBox} />
            <OverviewTeamComponent {...TeamBox} dealUuid={dealUuid} companyUuid={companyUuid} />
          </Column>
        </Content>
      </OverviewSectionWrapper>
    </CompanyOverviewWrapper>
  );
};

export default SectionOverview;
