import styled from 'styled-components';

import { StandardButton } from 'shared-parts/components';
import { COLORS, FONTS } from 'shared-parts/constants';

const Container = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Header = styled.h3`
  margin: 23px 0 0 0;
  font-size: 35px;
  line-height: 45px;
  font-weight: 200;
  font-family: ${FONTS.oswaldFont};
  letter-spacing: 0.35px;
  color: ${COLORS.primary};
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-family: ${FONTS.mainFont};
  color: ${COLORS.neutral800};
  margin-top: 18px;
`;
const ButtonWithMargin = styled(StandardButton)`
  margin-top: 18px;
  margin-right: 10px;
`;

export { Container, Header, Message, ButtonWithMargin };
