import styled from 'styled-components';

import Radio from 'shared-parts/components/radio';
import { Icon, RadioLabel } from 'shared-parts/components/radio/radio.styled';
import { Input } from 'shared-parts/components/text-input/regular-text-input.styled';
import { COLORS } from 'shared-parts/constants';

const { neutral300, alternative, neutral800, success100, error100, primary } = COLORS;

export const FormGroup = styled.div<{
  span?: number;
}>`
  display: inline-block;
  width: 100%;
  ${({ span }) => (span ? `grid-column: span ${span};` : '')}
  position: relative;
`;

export const Label = styled.label<{
  htmlFor?: string;
}>`
  color: ${primary};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
`;

export const SicCodesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const SicCodeItem = styled.li`
  color: ${alternative};
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 2px 0;
`;

export const HorizontalRadioContainer = styled.div`
  display: flex;
`;

export const StyledRadio = styled(Radio)`
  font-size: 14px;
  margin-bottom: 0;

  ${Icon} {
    height: 14px;
    width: 14px;
  }

  &${RadioLabel} {
    padding-left: 20px;
    :not(:first-child) {
      margin-left: 14px;
    }
  }
`;

export const FullWidthLabel = styled(Label)`
  white-space: nowrap;
`;

export const StyledTextInput = styled(Input)<{
  active?: boolean;
}>`
  flex-grow: 2;
  margin-right: 20px;
  margin-left: 14px;
  border: 1px solid ${neutral300};
  border-radius: 6px;
  color: ${neutral800};

  &:disabled {
    background-color: ${props => (props.active ? success100 : error100)};
    cursor: default;
    pointer-events: none;
    height: 40px;
  }
`;

export const SwitchableFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  align-items: center;
  align-self: start;
`;
