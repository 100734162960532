import { useDispatch } from 'react-redux';

import { formatDate, LDF } from '@frontend/datetime';

import { resendIPInvestorInvite } from 'app-state/actions/deal';

import ResendIcon from 'svg-icons/refresh-icon';
import ResendButton from 'shared/labeled-button';

import { LAST_INVITE_INFO_TEXT, RESEND_BUTTON_TEXT } from './constants';
import { Info, ResendContainer } from './index.styled';

const ResendInvite = ({ lastInviteSent, investorUuid, disabled, isResendAllowed }) => {
  const dispatch = useDispatch();

  const resendInvite = e => {
    e.preventDefault();

    dispatch(resendIPInvestorInvite({ investorUuid }));
  };

  return (
    <ResendContainer>
      <Info>
        {LAST_INVITE_INFO_TEXT}: {formatDate(lastInviteSent, LDF.DATE_TEXTUAL)}
      </Info>
      {isResendAllowed && (
        <ResendButton
          text={RESEND_BUTTON_TEXT}
          onClick={resendInvite}
          icon={ResendIcon}
          disabled={disabled}
        />
      )}
    </ResendContainer>
  );
};

export default ResendInvite;
