import { COLORS } from 'shared-parts/constants';

const GiftIcon = ({ fillColor = COLORS.white, height = 42, wight = 42, className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={wight}
    height={height}
    viewBox="0 0 42.295 41.8"
  >
    <g transform="translate(-7 -7.586)">
      <g transform="translate(7 7.586)">
        <path
          fill={fillColor}
          d="M37,52.377H48a.845.845,0,0,0,.846-.846V27H37Z"
          transform="translate(-11.623 -10.578)"
        />
        <path
          fill={fillColor}
          d="M48.45,15.549h-12.8c2.371-1.853,3.279-4.578,2.051-6.42a3.751,3.751,0,0,0-3.268-1.543,7.373,7.373,0,0,0-4,1.308,7.812,7.812,0,0,0-2.276,2.291,7.8,7.8,0,0,0-2.276-2.292,7.378,7.378,0,0,0-4-1.308A3.747,3.747,0,0,0,18.6,9.129a3.753,3.753,0,0,0-.167,3.61,7.17,7.17,0,0,0,2.248,2.81H7.846A.846.846,0,0,0,7,16.4v5.921H49.3V16.4A.846.846,0,0,0,48.45,15.549Zm-19.212-2.81a6.159,6.159,0,0,1,5.192-3.46,2.208,2.208,0,0,1,1.86.789c.762,1.145-.2,3.186-2.111,4.457a5.682,5.682,0,0,1-3.067,1.024,2.2,2.2,0,0,1-1.859-.79A2.207,2.207,0,0,1,29.237,12.739Zm-7.119,1.787a5.681,5.681,0,0,1-2.125-2.437,2.206,2.206,0,0,1,.014-2.021,2.208,2.208,0,0,1,1.86-.789A5.68,5.68,0,0,1,24.933,10.3a5.676,5.676,0,0,1,2.125,2.437,2.206,2.206,0,0,1-.014,2.021,2.208,2.208,0,0,1-1.86.789A5.677,5.677,0,0,1,22.118,14.526Z"
          transform="translate(-7 -7.586)"
        />
        <rect fill={fillColor} width="5.075" height="25.377" transform="translate(18.61 16.422)" />
        <path
          fill={fillColor}
          d="M13,51.531a.846.846,0,0,0,.846.846h11V27H13Z"
          transform="translate(-7.925 -10.578)"
        />
      </g>
    </g>
  </svg>
);

export default GiftIcon;
