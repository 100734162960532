import PropTypes from 'prop-types';

import {
  AdditionalInfo,
  Button,
  GreenCheckmark,
  ResultingText,
  Wrapper,
} from './base-resulting-modal.styled';

const BaseResultingModal = ({ hideModal, resultingText, additionalInfo }) => (
  <Wrapper>
    <GreenCheckmark />
    <ResultingText>{resultingText}</ResultingText>
    <AdditionalInfo>{additionalInfo}</AdditionalInfo>
    <Button onClick={hideModal}>CONTINUE</Button>
  </Wrapper>
);

BaseResultingModal.defaultProps = {
  additionalInfo: '',
};

BaseResultingModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  resultingText: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string,
};

export default BaseResultingModal;
