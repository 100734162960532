import * as constants from 'app-state/constants/users';

export const getUsers = () => ({ type: constants.GET_USERS });
export const getUsersSuccess = data => ({ type: constants.GET_USERS_SUCCESS, data });
export const getUsersError = error => ({ type: constants.GET_USERS_ERROR, error });

export const updateUser = (userUuid, data, form, onSuccess, showEmailModal) => ({
  type: constants.UPDATE_USER,
  userUuid,
  data,
  form,
  showEmailModal,
  onSuccess,
});

export const createUser = (data, showUserAddedModal, form) => ({
  type: constants.CREATE_USER,
  data,
  showUserAddedModal,
  form,
});

export const deleteUser = userUuid => ({ type: constants.DELETE_USER, userUuid });
export const deleteUserError = error => ({ type: constants.DELETE_USER_ERROR, error });

export const checkCreateUserPermission = (companyUuid, handleResponse) => ({
  type: constants.CHECK_CREATE_USER_PERMISSION,
  companyUuid,
  handleResponse,
});
export const checkCreateUserPermissionError = error => ({
  type: constants.CHECK_CREATE_USER_PERMISSION_ERROR,
  error,
});
