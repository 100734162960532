import * as constants from 'app-state/constants';

export const getPermissions = () => ({ type: constants.GET_PERMISSIONS });
export const getPermissionsSuccess = data => ({ type: constants.GET_PERMISSIONS_SUCCESS, data });
export const getPermissionsError = error => ({ type: constants.GET_PERMISSIONS_ERROR, error });
export const clearPermissions = () => ({ type: constants.CLEAR_PERMISSIONS });

export const getPermissionLevels = companyUuid => ({
  type: constants.GET_PERMISSION_LEVELS,
  companyUuid,
});
export const getPermissionLevelsSuccess = data => ({
  type: constants.GET_PERMISSION_LEVELS_SUCCESS,
  data,
});
export const getPermissionLevelsError = error => ({
  type: constants.GET_PERMISSION_LEVELS_ERROR,
  error,
});
