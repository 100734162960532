import styled, { css, keyframes } from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const increase = keyframes`
  from {
    left: -5%; width: 5%;
  }
  to {
    left: 130%; width: 100%;
  }
`;

const decrease = keyframes`
  from {
    left: -80%; width: 80%;
  }
  to {
    left: 110%; width: 10%;
  }
`;

const commonStyles = `
  position: absolute;
  height: 5px;
  width: 100%;
  overflow: hidden;
`;

const Slider = styled.tr`
  z-index: 1;
  ${commonStyles}
`;

const Line = styled.td`
  background: ${COLORS.accent};
  ${commonStyles}
`;

const SubLine = styled.td`
  background: ${COLORS.secondary};
  animation: ${({ reverse }) =>
    reverse
      ? css`
          ${decrease} 2s 0.5s infinite
        `
      : css`
          ${increase} 2s infinite
        `};
  ${commonStyles}
`;
export { Slider, Line, SubLine };
