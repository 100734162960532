import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import { Screens } from 'shared-parts/constants/screens';

import { SectionTitleFieldProps } from './type';

const SectionTitle = styled.div<SectionTitleFieldProps>`
  display: inline-flex;
  border-bottom: 1px solid ${COLORS.neutral300};
  color: ${props => props.titleColor || COLORS.primary};
  font-family: ${FONTS.oswaldFont};
  font-size: 25px;
  font-weight: 300;
  justify-content: space-between;
  line-height: 31px;
  padding-bottom: 27px;
  text-transform: uppercase;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  width: calc(100% - 10px);

  @media (max-width: ${Screens.sm}) {
    margin: 0 10px;
  }
  width: calc(100% - 20px);
  padding-left: 5px;
  padding-right: 5px;
  ${({ styles }) => styles};
`;

export default SectionTitle;
