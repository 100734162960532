import {
  ADD_WALLET_TRANSFER,
  ADD_WALLET_TRANSFER_ERROR,
  ADD_WALLET_TRANSFER_SUCCESS,
  EDIT_INVESTOR,
  EDIT_INVESTOR_ERROR,
  EDIT_INVESTOR_SUCCESS,
  FETCH_INVESTOR,
  FETCH_INVESTOR_AGENTS_PERMISSIONS,
  FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR,
  FETCH_INVESTOR_AGENTS_PERMISSIONS_SUCCESS,
  FETCH_INVESTOR_DEALS,
  FETCH_INVESTOR_DEALS_ERROR,
  FETCH_INVESTOR_DEALS_SUCCESS,
  FETCH_INVESTOR_ERROR,
  FETCH_INVESTOR_INVESTMENTS,
  FETCH_INVESTOR_INVESTMENTS_ERROR,
  FETCH_INVESTOR_INVESTMENTS_SUCCESS,
  FETCH_INVESTOR_PREFERENCES,
  FETCH_INVESTOR_PREFERENCES_ERROR,
  FETCH_INVESTOR_PREFERENCES_SUCCESS,
  FETCH_INVESTOR_SUCCESS,
  FETCH_INVESTORS,
  FETCH_INVESTORS_ERROR,
  FETCH_INVESTORS_SUCCESS,
  FETCH_WALLET_REFERENCE,
  FETCH_WALLET_REFERENCE_ERROR,
  FETCH_WALLET_REFERENCE_SUCCESS,
  FETCH_WALLET_TRANSACTIONS,
  FETCH_WALLET_TRANSACTIONS_ERROR,
  FETCH_WALLET_TRANSACTIONS_SUCCESS,
  INVITE_INVESTOR,
  INVITE_INVESTOR_ERROR,
  INVITE_INVESTOR_SUCCESS,
  RESET_INVESTOR_DEALS,
  RESET_INVESTOR_INVESTMENTS,
  RESET_INVESTOR_PREFERENCES,
  RESET_INVESTORS,
  RESET_WALLET_TRANSACTIONS,
  UPDATE_INVESTOR_DEALS,
  UPDATE_INVESTOR_DEALS_ERROR,
  UPDATE_INVESTOR_DEALS_SUCCESS,
} from './constants';

export const fetchInvestors = payload => ({
  type: FETCH_INVESTORS,
  payload,
});
export const fetchInvestorsSuccess = payload => ({
  type: FETCH_INVESTORS_SUCCESS,
  payload,
});
export const fetchInvestorsError = payload => ({
  type: FETCH_INVESTORS_ERROR,
  payload,
});
export const resetInvestors = () => ({ type: RESET_INVESTORS });

export const inviteInvestor = payload => ({
  type: INVITE_INVESTOR,
  payload,
});
export const inviteInvestorSuccess = payload => ({
  type: INVITE_INVESTOR_SUCCESS,
  payload,
});
export const inviteInvestorError = payload => ({
  type: INVITE_INVESTOR_ERROR,
  payload,
});

export const fetchInvestor = payload => ({
  type: FETCH_INVESTOR,
  payload,
});
export const fetchInvestorSuccess = payload => ({
  type: FETCH_INVESTOR_SUCCESS,
  payload,
});
export const fetchInvestorError = payload => ({
  type: FETCH_INVESTOR_ERROR,
  payload,
});

export const editInvestor = payload => ({
  type: EDIT_INVESTOR,
  payload,
});
export const editInvestorSuccess = payload => ({
  type: EDIT_INVESTOR_SUCCESS,
  payload,
});
export const editInvestorError = payload => ({
  type: EDIT_INVESTOR_ERROR,
  payload,
});

export const fetchWalletReference = payload => ({
  type: FETCH_WALLET_REFERENCE,
  payload,
});
export const fetchWalletReferenceSuccess = payload => ({
  type: FETCH_WALLET_REFERENCE_SUCCESS,
  payload,
});
export const fetchWalletReferenceError = payload => ({
  type: FETCH_WALLET_REFERENCE_ERROR,
  payload,
});

export const addWalletTransfer = payload => ({
  type: ADD_WALLET_TRANSFER,
  payload,
});
export const addWalletTransferSuccess = () => ({
  type: ADD_WALLET_TRANSFER_SUCCESS,
});
export const addWalletTransferError = payload => ({
  type: ADD_WALLET_TRANSFER_ERROR,
  payload,
});

export const fetchWalletTransactions = payload => ({
  type: FETCH_WALLET_TRANSACTIONS,
  payload,
});
export const fetchWalletTransactionsSuccess = payload => ({
  type: FETCH_WALLET_TRANSACTIONS_SUCCESS,
  payload,
});
export const fetchWalletTransactionsError = payload => ({
  type: FETCH_WALLET_TRANSACTIONS_ERROR,
  payload,
});
export const resetWalletTransactions = () => ({
  type: RESET_WALLET_TRANSACTIONS,
});

export const fetchInvestorInvestments = payload => ({
  type: FETCH_INVESTOR_INVESTMENTS,
  payload,
});
export const fetchInvestorInvestmentsSuccess = payload => ({
  type: FETCH_INVESTOR_INVESTMENTS_SUCCESS,
  payload,
});
export const fetchInvestorInvestmentsError = payload => ({
  type: FETCH_INVESTOR_INVESTMENTS_ERROR,
  payload,
});
export const resetInvestorInvestments = payload => ({
  type: RESET_INVESTOR_INVESTMENTS,
  payload,
});

export const fetchInvestorPreferences = payload => ({
  type: FETCH_INVESTOR_PREFERENCES,
  payload,
});
export const fetchInvestorPreferencesSuccess = payload => ({
  type: FETCH_INVESTOR_PREFERENCES_SUCCESS,
  payload,
});
export const fetchInvestorPreferencesError = payload => ({
  type: FETCH_INVESTOR_PREFERENCES_ERROR,
  payload,
});
export const resetInvestorPreferences = payload => ({
  type: RESET_INVESTOR_PREFERENCES,
  payload,
});

export const fetchInvestorDeals = payload => ({
  type: FETCH_INVESTOR_DEALS,
  payload,
});
export const fetchInvestorDealsSuccess = payload => ({
  type: FETCH_INVESTOR_DEALS_SUCCESS,
  payload,
});
export const fetchInvestorDealsError = payload => ({
  type: FETCH_INVESTOR_DEALS_ERROR,
  payload,
});
export const resetInvestorDeals = () => ({
  type: RESET_INVESTOR_DEALS,
});

export const updateInvestorDeals = payload => ({
  type: UPDATE_INVESTOR_DEALS,
  payload,
});
export const updateInvestorDealsSuccess = payload => ({
  type: UPDATE_INVESTOR_DEALS_SUCCESS,
  payload,
});
export const updateInvestorDealsError = payload => ({
  type: UPDATE_INVESTOR_DEALS_ERROR,
  payload,
});

// eslint-disable-next-line import/no-unused-modules
export const fetchInvestorAgentsPermissions = payload => ({
  type: FETCH_INVESTOR_AGENTS_PERMISSIONS,
  payload,
});
export const fetchInvestorAgentsPermissionsSuccess = payload => ({
  type: FETCH_INVESTOR_AGENTS_PERMISSIONS_SUCCESS,
  payload,
});
export const fetchInvestorAgentsPermissionsError = payload => ({
  type: FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR,
  payload,
});

// eslint-disable-next-line import/no-unused-modules
export const resetInvestorAgentsPermissions = payload => ({
  type: FETCH_INVESTOR_AGENTS_PERMISSIONS_ERROR,
  payload,
});
