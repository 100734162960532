import { decodeHtml } from 'shared-parts/helpers/html/encode';
import sanitize from 'shared-parts/helpers/html/sanitize';

import { MemberPhotoBlock, MemberTextBlock } from './team-member.styled';

const TeamMember = ({ firstName, lastName, url, description, organizationRoles, linkedInUrl }) => {
  const name = `${firstName} ${lastName}`;

  return (
    <div>
      <MemberPhotoBlock>
        {linkedInUrl && linkedInUrl !== '#' ? (
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <img alt={name} width="560" src={url} className="small" loading="lazy" />
          </a>
        ) : (
          <img alt={name} width="560" src={url} className="small" loading="lazy" />
        )}
      </MemberPhotoBlock>
      <MemberTextBlock>
        <div className="description" />
        <div className="content">
          <div className="team-member">
            <p>
              <strong>
                <span className="text-uppercase">{name}</span>
                {organizationRoles.length
                  ? `, ${organizationRoles.map(decodeHtml).join(' & ')}`
                  : ''}
              </strong>
            </p>
            {/* eslint-disable react/no-danger */}
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(description),
              }}
            />
            {/* eslint-enable react/no-danger */}
          </div>
        </div>
      </MemberTextBlock>
    </div>
  );
};

export default TeamMember;
