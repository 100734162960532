import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';
import CheckboxIconWhite from 'shared-parts/images/checkbox-icon-white.svg';

const CheckboxWrapper = styled.div`
  margin: 10px 0 5px 0;
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ span {
    background: ${COLORS.alternative};
    border-color: ${COLORS.neutral500};

    &:before {
      background: url(${CheckboxIconWhite}) no-repeat center;
      color: ${COLORS.neutral200};
      content: '';
      display: inline-block;
      height: 13px;
      left: 3px;
      position: absolute;
      top: 2px;
      width: 13px;
    }
  }

  &:checked {
    &:disabled ~ span {
      background-color: ${COLORS.neutral800};
      border-color: ${COLORS.neutral800};
    }
  }
`;

const CheckboxLabel = styled.label`
  color: ${({ labelColor }) => labelColor};
  font-family: ${FONTS.mainFont};
  font-weight: ${({ thinText }) => (thinText ? 100 : 600)};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  position: relative;
  ${({ disabled }) =>
    disabled
      ? `
    cursor: default;
    color: ${COLORS.neutral500}`
      : 'cursor: pointer'};
  margin-bottom: 0;
  padding-left: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.span`
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background: url(${CheckboxIconWhite}) no-repeat center;
  background-size: 60%;
  background-color: ${COLORS.neutral300};
  border-radius: 2px;
`;

export { CheckboxWrapper, CheckboxInput, CheckboxLabel, Icon };
