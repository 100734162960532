import React from 'react';

import { getIn, setIn } from 'formik';

import Error from 'shared-parts/components/error';
import validation from 'shared-parts/constants/validation';
import { formatInputNumber, removeNumberCommas } from 'shared-parts/helpers/format-number';
import getErrorMessage from 'shared-parts/helpers/get-error-message';

import { InputWrapper } from './text-input.styled';

export default class CustomTextInputComponent extends React.Component {
  state = {
    showPassword: false,
  };

  componentDidMount() {
    const {
      field: { value, name },
      form,
    } = this.props;

    if (value) {
      form.setFieldTouched(name, true);
    }
  }

  onBlur = e => {
    const {
      onBlur: customOnBlur = () => {},
      disableOnBlur,
      field: { onBlur },
      form,
    } = this.props;
    const { name } = e.target;
    const value = getIn(form.values, name) || '';
    form.setFieldValue(name, value.toString().trim());

    if (!disableOnBlur) {
      onBlur(e);
      customOnBlur(e, form);
    }
  };

  handleChange = e => {
    const { form, field, handleChange, isAmount, dpAmount = Infinity } = this.props;
    const { status = {}, setStatus, setFieldValue } = form;
    setStatus(setIn(status, field.name, undefined));

    if (isAmount) {
      const { value, name } = e.target;
      const dpEnteringIsPrevented = dpAmount === 0;
      const valueIncludesDot = value.includes('.');
      const emptyValue = value === '';

      if (
        !(validation.formattedDecimalRegex.test(value) || emptyValue) ||
        (dpEnteringIsPrevented && valueIncludesDot)
      )
        return;
      const formattedValue = formatInputNumber(value || '', dpAmount);

      setFieldValue(name, removeNumberCommas(formattedValue));
      e.target.value = formattedValue;
    } else {
      field.onChange(e);
    }

    if (handleChange) {
      handleChange(e, form);
    }
  };

  handleShowPasswordChange = () => {
    this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
  };

  render() {
    const {
      field,
      form,
      disabled,
      readOnly,
      placeholder,
      styledInput: Component,
      onInputClick,
      type = 'text',
      isAmount,
      backgroundColor,
      placeholderColor,
      textColor,
      className,
      autoComplete,
      disableAutocomplete,
      wrapperHeight,
      dpAmount = Infinity,
      inputRef = null,
      icon,
      onIconClick,
      onKeyDown,
      onPaste,
      transformValue,
      hint,
      ...rest
    } = this.props;
    const { name } = field;
    const { errors, touched, status = {} } = form;
    const error = getErrorMessage(errors, name) || getErrorMessage(status, name);
    const isInvalid = typeof error === 'string';
    const inputType = this.state.showPassword ? 'text' : type;
    const value = isAmount ? formatInputNumber(field.value || '', dpAmount) : field.value;
    const transformedValue = value && transformValue ? transformValue(value) : value;

    return (
      <InputWrapper wrapperHeight={wrapperHeight}>
        <Component
          className={className}
          value={transformedValue || ''}
          onClick={onInputClick}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          name={name}
          autoComplete={autoComplete}
          disableAutocomplete={disableAutocomplete}
          placeholder={placeholder}
          hint={hint}
          disabled={disabled}
          readOnly={readOnly}
          touched={getIn(touched, name)}
          isValueValid={!isInvalid}
          showPassword={this.state.showPassword}
          handleShowPasswordChange={this.handleShowPasswordChange}
          placeholderColor={placeholderColor}
          type={inputType}
          password={type === 'password'}
          backgroundColor={backgroundColor}
          textColor={textColor}
          inputRef={inputRef}
          icon={icon}
          onIconClick={onIconClick}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          {...rest}
        />
        {isInvalid && getIn(touched, name) && <Error message={error} data-e2e={`error-${name}`} />}
      </InputWrapper>
    );
  }
}
