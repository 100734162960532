import { Formik } from 'formik';
import { object, string } from 'yup';

import { passwordSchema as password } from '@frontend/auth';

import { validationHelpers } from 'shared-parts/helpers';

import renderCreatePasswordForm from './create-password-form';

const validationSchema = object({
  password,
  passwordConfirmation: string()
    .trim()
    .required('Password confirmation is required')
    .test(
      'match-password',
      "Passwords don't match. Please, try again",
      validationHelpers.matchPassword,
    ),
});

const ResetPasswordWrapper = props => (
  <Formik
    enableReinitialize
    validationSchema={validationSchema}
    initialValues={{
      password: '',
      passwordConfirmation: '',
    }}
    onSubmit={props.handleFormSubmit}
  >
    {renderCreatePasswordForm(props)}
  </Formik>
);

export default ResetPasswordWrapper;
