import { useEnvVar } from '@frontend/config';

import HandshakeIcon from 'svg-icons/handshake-icon';
import { AuthLogo } from 'shared/logos';

import {
  EmailBody,
  EmailContainer,
  EmailHeader,
  EmailTitle,
  Link,
  Paragraph,
  RegisterButton,
  Section,
  Title,
} from './index.styled';

const WelcomeEmailPreview = () => {
  const platformName = useEnvVar('PLATFORM_NAME') || 'Globacap'; // Default just in case!
  const supportEmail = useEnvVar('SMTP_SUPPORT_EMAIL');

  return (
    <EmailContainer>
      <EmailHeader>
        <Section>
          <AuthLogo />
        </Section>
      </EmailHeader>
      <EmailBody>
        <EmailTitle>
          <HandshakeIcon height={41} />
          <Title>[issuer name] invites you to join {platformName}.</Title>
        </EmailTitle>

        <Section>
          <Paragraph>
            <strong>Dear [first name of shareholder],</strong>
          </Paragraph>

          <Paragraph>
            Welcome to the {platformName} platform. Supercharge your primary placement deals, reduce
            ongoing administrative overheads and enable frictionless secondary liquidity.
          </Paragraph>

          <Paragraph>
            Create an account now and gain access to our end-to-end digital ecosystem.
          </Paragraph>

          <RegisterButton>REGISTER NOW</RegisterButton>

          <Paragraph>
            <strong>Important:</strong> You will need to enter the same e-mail address that this
            invite has been sent to when registering on the platform. If you register with a
            different e-mail address, any account information will not be linked.
          </Paragraph>

          <Paragraph>
            {'If you wish to register with a different e-mail account, please contact '}
            <Link mailto={supportEmail} target="_blank">
              {supportEmail}
            </Link>
          </Paragraph>
          <Paragraph>The Management Team at [issuer name]</Paragraph>
        </Section>
      </EmailBody>
    </EmailContainer>
  );
};

export default WelcomeEmailPreview;
