import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { formatDate, LDF } from '@frontend/datetime';

import { fetchInvestorInfo, resendInvestorInvite, resetInvestorInfo } from 'app-state/actions/deal';
import {
  getDealCompanyUuid,
  getDealSummary,
  getInvestorInfoErrorFlag,
  getInvestorInfoLoadingFlag,
  getInvestorInteractions,
  getInvestorViewsStats,
  getResendInvestorInviteFlag,
} from 'app-state/selectors/deal';

import useTypedSelector from 'hooks/use-typed-selector';
import ResendIcon from 'svg-icons/refresh-icon';
import ResendButton from 'shared/labeled-button';

import DataLabel from '../components/data-label';
import Loader from '../components/loader';
import { FailedMessage, Section, Table, TableTitle, TopContainer } from '../index.styled';
import type { InvestorModalPropTypes } from '../types';

import {
  ERROR_MESSAGE,
  LAST_INVITE_INFO_TEXT,
  OPENED_INVITE_LABEL,
  PLACED_ORDER_LABEL,
  RESEND_BUTTON_TEXT,
  VIEWED_DEAL_LABEL,
  VIEWS_HEADERS,
  VIEWS_KEYS,
  VIEWS_TITLE,
} from './constants';
import { DataContainer, Info, ResendContainer } from './index.styled';

const Interactions: FC<React.PropsWithChildren<InvestorModalPropTypes>> = ({
  dealUuid,
  investor,
}) => {
  const dispatch = useDispatch();
  const deal = useTypedSelector(getDealSummary);
  const published = deal.data?.published;
  const companyUuid = useTypedSelector(getDealCompanyUuid);
  const isDataLoading = useTypedSelector(getInvestorInfoLoadingFlag);
  const { openedInvite, placedOrder, viewedDeal, lastInviteSent } =
    useTypedSelector(getInvestorInteractions);
  const viewsStats = useTypedSelector(getInvestorViewsStats);
  const isInviteSending = useTypedSelector(getResendInvestorInviteFlag);
  const isLoadingFailed = useTypedSelector(getInvestorInfoErrorFlag);

  const resendInvite = () => {
    dispatch(
      resendInvestorInvite({
        dealUuid,
        companyUuid,
        email: investor?.email,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchInvestorInfo({ dealUuid, email: investor?.email }));

    return () => {
      dispatch(resetInvestorInfo());
    };
  }, [investor?.email]);

  if (isDataLoading) {
    return <Loader />;
  }

  if (isLoadingFailed) {
    return <FailedMessage>{ERROR_MESSAGE}</FailedMessage>;
  }

  return (
    <>
      <Section>
        <TopContainer>
          <DataContainer>
            <DataLabel name={OPENED_INVITE_LABEL} status={openedInvite} />
            <DataLabel name={VIEWED_DEAL_LABEL} status={viewedDeal} />
            <DataLabel name={PLACED_ORDER_LABEL} status={placedOrder} />
          </DataContainer>
          <ResendContainer>
            <Info>
              {LAST_INVITE_INFO_TEXT}: {formatDate(lastInviteSent, LDF.DATE_NUMERIC)}
            </Info>
            <ResendButton
              text={RESEND_BUTTON_TEXT}
              onClick={resendInvite}
              icon={ResendIcon}
              disabled={isInviteSending || !published}
            />
          </ResendContainer>
        </TopContainer>
      </Section>
      <Section>
        <TableTitle>{VIEWS_TITLE}</TableTitle>
        <Table header={VIEWS_HEADERS} keys={VIEWS_KEYS} data={viewsStats} loading={false} />
      </Section>
    </>
  );
};

export default Interactions;
