import { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from 'app-state/actions';

import Routes from 'constants/routes';
import { AuthLogo } from 'shared/logos';
import { AuthContainer } from 'shared-parts/modules/auth/auth-container';

import ForgotPasswordForm from './components/forgot-password-form-wrapper';
import {
  Header,
  Message,
  MessagePart,
  NotReceivedEmail,
  ResendButton,
  ReturnLink,
} from './forgot-password.styled';

const ForgotPassword = ({
  forgotPassword,
  headerColor,
  messageColor,
  formColors,
  linkTextColor,
}) => {
  const [emailSent, setEmailSent] = useState(false);
  const showEmailSentText = () => setEmailSent(true);
  const handleResendButtonClick = () => setEmailSent(false);
  const handleSubmit = (form, { setSubmitting, setErrors }) =>
    forgotPassword(form, showEmailSentText, setSubmitting, setErrors);

  const getHeaderText = () => (emailSent ? 'Check Your Email' : 'Forgot Your Password?');

  const renderMessage = () =>
    emailSent ? (
      <Fragment>
        <MessagePart>
          Done! If your email address is in our database, we will send you a link to reset your
          password.
        </MessagePart>
        If you don’t see an email from us, check your junk, spam or other folders.
      </Fragment>
    ) : (
      'Don’t worry! Enter your email address and we’ll send you a link to reset your password.'
    );

  const renderBottomLink = () =>
    emailSent ? (
      <NotReceivedEmail textColor={linkTextColor}>
        Didn&#39;t receive our email?
        <ResendButton title="Resend email" onClick={handleResendButtonClick}>
          <b>Resend it.</b>
        </ResendButton>
      </NotReceivedEmail>
    ) : (
      <ReturnLink
        bold
        fontSize="14px"
        url={Routes.Login()}
        textColor={linkTextColor}
        title="Log in"
        additionalText="Return to"
      >
        Log in
      </ReturnLink>
    );

  return (
    <AuthContainer showClose>
      <AuthLogo />
      <Header headerColor={headerColor}>{getHeaderText()}</Header>
      <Message messageColor={messageColor}>{renderMessage()}</Message>
      {!emailSent && <ForgotPasswordForm handleFormSubmit={handleSubmit} formColors={formColors} />}
      {renderBottomLink()}
    </AuthContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  forgotPassword: (form, showEmailSentText, setSubmitting, setErrors) =>
    dispatch(actions.forgotPassword(form, showEmailSentText, setSubmitting, setErrors)),
});

export default connect(undefined, mapDispatchToProps)(ForgotPassword);
