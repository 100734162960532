import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const ErrorMessage = styled.div`
  color: ${COLORS.error};
  font-size: 12px !important;
  font-family: ${FONTS.mainFont};
  font-weight: bold;
  padding: 0 4px 0 4px;
  margin: 5px 0 10px 0;
  text-align: left;
`;

export default function (props) {
  return <ErrorMessage {...props}>{props.message}</ErrorMessage>;
}
