import { COLORS } from 'shared-parts/constants';

const { alternative } = COLORS;

export default ({ width = 22, height = 16, color = alternative }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={color}
  >
    <path
      d="M19,8l-4,4h3a6,6,0,0,1-6,6,5.87,5.87,0,0,1-2.8-.7L7.74,18.76A7.986,7.986,0,0,0,20,12h3ZM6,12a6,6,0,0,1,6-6,5.87,5.87,0,0,1,2.8.7l1.46-1.46A7.986,7.986,0,0,0,4,12H1l4,4,4-4Z"
      transform="translate(-1 -4)"
    />
  </svg>
);
