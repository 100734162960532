import styled from 'styled-components';

import { COLORS, FONTS } from 'shared-parts/constants';

const { primary, neutral800 } = COLORS;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 580px;
  padding: 50px 60px 40px 60px;
`;

export const Icon = styled.div<{ marginBottom?: string }>`
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}` : '')};
`;

export const ModalTitle = styled.p`
  margin: 10px 0 0 0;
  font-size: 35px;
  font-weight: 300;
  font-family: ${FONTS.oswaldFont};
  letter-spacing: 0.35px;
  line-height: 45px;
  color: ${primary};
`;

export const ModalText = styled.p`
  margin: 25px 0 0 0;
  font-size: 16px;
  font-family: ${FONTS.mainFont};
  line-height: 24px;
  color: ${neutral800};
  text-align: center;
  white-space: pre-line;
`;

export const ButtonsContainer = styled.div<{ marginTop?: string }>`
  display: flex;
  grid-template-columns: min-content min-content;
  gap: 28px;
  ${({ marginTop }) => `margin-top: ${marginTop || '42px'}`};
`;

export const CloseButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: ${primary};
`;
