import { Form, Formik } from 'formik';

import {
  CurrentPage,
  NextControl,
  PaginationContainer,
  PaginationOptionSelect,
  PrevControl,
} from './pagination.styled.jsx';

const defaultPaginationOptions = [
  { value: 10, text: '10' },
  { value: 20, text: '20' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
  { value: 200, text: '200' },
];

const Pagination = ({
  disabled,
  currentOption,
  currentPage,
  totalPages,
  paginationOptions = defaultPaginationOptions,
  handlePaginationOptionChange,
  handlePageChange,
  className = '',
}) => {
  const isLastPage = currentPage === totalPages;
  const handleNextPageClick = () =>
    currentPage + 1 > totalPages ? {} : handlePageChange(currentPage + 1);
  const handlePrevPageClick = () => (currentPage - 1 < 1 ? {} : handlePageChange(currentPage - 1));

  return (
    <PaginationContainer className={className}>
      <span>Display</span>
      <Formik initialValues={{ pagination: currentOption }}>
        <Form>
          <PaginationOptionSelect
            name="pagination"
            height={36}
            disabled={disabled}
            options={paginationOptions}
            onOptionSelect={handlePaginationOptionChange}
          />
        </Form>
      </Formik>
      <PrevControl disabled={disabled || currentPage === 1} onClick={handlePrevPageClick} />
      <CurrentPage disabled={disabled || isLastPage}>{currentPage}</CurrentPage>
      <span>of {totalPages}</span>
      <NextControl disabled={disabled || isLastPage} onClick={handleNextPageClick} />
    </PaginationContainer>
  );
};

export default Pagination;
