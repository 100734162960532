import getReducerState from '../reducers/get-reducer-state';

import { GET_TIMEZONES, GET_TIMEZONES_SUCCESS } from './constants';

const timezonesDefaultState = getReducerState();

export const timezonesReducer = (state = timezonesDefaultState, action) => {
  switch (action.type) {
    case GET_TIMEZONES:
      return timezonesDefaultState;
    case GET_TIMEZONES_SUCCESS:
      return getReducerState({
        loading: false,
        data: action.payload,
      });
    default:
      return state;
  }
};
