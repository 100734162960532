const InProgressIcon = ({ width = 18, height = 20, fillColor = 'currentColor' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87942 0.992188V1.01023V2.81469C13.8402 3.3019 16.6462 6.9018 16.1589 10.8626C15.7439 14.1467 13.1635 16.7542 9.87942 17.1421V18.9466C14.8417 18.4503 18.4506 14.0475 17.9544 9.0852C17.5484 4.7996 14.147 1.41624 9.87942 0.992188ZM8.07496 1.01925C6.31561 1.19068 4.63746 1.86735 3.26607 3.00416L4.55626 4.33946C5.56676 3.52746 6.78477 3.00416 8.07496 2.82372V1.01925ZM1.99393 4.27631C0.857119 5.6477 0.180446 7.31682 0 9.0852H1.80446C1.97588 7.80403 2.48113 6.58602 3.28412 5.5665L1.99393 4.27631ZM0.00902229 10.8897C0.189468 12.658 0.884186 14.3272 2.00295 15.6985L3.28412 14.4084C2.49016 13.3888 1.97588 12.1708 1.81348 10.8897H0.00902229ZM4.55626 15.7346L3.26607 16.9707C4.62844 18.1075 6.30659 18.8203 8.07496 19.0097V17.2053C6.79379 17.0429 5.57578 16.5286 4.55626 15.7346ZM9.42831 5.47627V10.213L13.4883 12.6219L12.8117 13.7317L8.07496 10.8897V5.47627H9.42831Z"
      fill={fillColor}
    />
  </svg>
);

export default InProgressIcon;
