import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { useEnvVar } from '@frontend/config';
import { Button } from '@frontend/design-system';
import { KycKybStatusEnum } from '@frontend/kyc-kyb';

import { confirmAndVerifyInvestor, useVerifyInvestor } from 'hooks/use-verify-investor';

import { getInvestorType, getOnboardingStatusData } from '../helpers';

import { Block, Container, Label, Text } from './styled';

type InvestorKYCSectionProps = {
  onboardingStatus?: string | null;
  type?: string;
  category?: string;
  uuid?: string;
  closeForm?: () => void;
  investorName?: string;
  showBorderBottom?: boolean;
};

const completedText = getOnboardingStatusData(KycKybStatusEnum.COMPLETED).text;

const InvestorKYCSection: FC<React.PropsWithChildren<InvestorKYCSectionProps>> = ({
  onboardingStatus,
  type,
  category,
  uuid,
  closeForm,
  investorName,
  showBorderBottom = true,
}) => {
  const { text, color } = getOnboardingStatusData(onboardingStatus);
  const customKycKyb = useEnvVar('CUSTOM_KYC_KYB');
  const dispatch = useDispatch();
  const { submit: verifyInvestor } = useVerifyInvestor(dispatch);

  return (
    <Container showBorderBottom={showBorderBottom}>
      <Block>
        <Label>KYC/KYB Status: </Label>
        <Text color={color}>{text}</Text>
      </Block>
      {type && (
        <Block>
          <Label>Investor Type: </Label>
          <Text>{getInvestorType(type)}</Text>
        </Block>
      )}
      {category && (
        <Block>
          <Label>Investor Category: </Label>
          <Text>{category}</Text>
        </Block>
      )}
      {customKycKyb && uuid && text !== completedText && (
        <Block>
          <Button
            variant="contained"
            type="button"
            aria-label="Verify"
            onClick={() => {
              confirmAndVerifyInvestor(dispatch, investorName ?? 'the investor', () =>
                verifyInvestor(
                  {},
                  {
                    params: {
                      uuid,
                    },
                  },
                  {
                    onSuccess: () => {
                      if (closeForm) closeForm();
                    },
                  },
                ),
              );
            }}
          >
            Verify
          </Button>
        </Block>
      )}
    </Container>
  );
};

export default InvestorKYCSection;
