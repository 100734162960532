import { call, put, select, takeEvery } from 'redux-saga/effects';

import { showModal } from 'app-state/actions/shared';
import { getCompany as getCompanySelector } from 'app-state/selectors/companies';

import API from 'constants/api';
import ErrorModal from 'shared-parts/components/modal-failed';
import request from 'shared-parts/helpers/request';

import {
  fetchOrderDocuments,
  fetchOrderDocumentsError,
  fetchOrderDocumentsSuccess,
  uploadOrderDocumentFinished,
} from './actions';
import {
  CHANGE_ORDER_DOCUMENT_AGREED_STATUS,
  DELETE_ORDER_DOCUMENT,
  FETCH_ORDER_DOCUMENTS,
  UPLOAD_ORDER_DOCUMENT,
} from './constants';
import { getOrderDocumentsPagination } from './selectors';

function* fetchOrderDocumentsSaga({ payload: { dealUuid, orderUuid, ...rest } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const api = API.OrderDocuments(companyData.uuid, dealUuid, orderUuid, rest);
    const response = yield call(request, api);

    yield put(fetchOrderDocumentsSuccess(response.data));
  } catch (e) {
    yield put(fetchOrderDocumentsError(e));
  }
}

function* fetchOrderDocumentsWatcher() {
  yield takeEvery(FETCH_ORDER_DOCUMENTS, fetchOrderDocumentsSaga);
}

function* uploadOrderDocument({ payload: { dealUuid, orderUuid, type, fileData } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    yield call(
      request,
      API.UploadOrderDocument(companyData.uuid, dealUuid, orderUuid),
      'POST',
      fileData,
      { timeout: 180000 },
    );
    const orderDocumentsPagination = yield select(getOrderDocumentsPagination);
    yield put(
      fetchOrderDocuments({
        ...orderDocumentsPagination,
        dealUuid,
        orderUuid,
        type,
      }),
    );
    yield put(uploadOrderDocumentFinished());
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* uploadOrderDocumentWatcher() {
  yield takeEvery(UPLOAD_ORDER_DOCUMENT, uploadOrderDocument);
}

function* deleteOrderDocument({ payload: { dealUuid, orderUuid, type, documentId } }) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    yield call(
      request,
      API.DeleteOrderDocument(companyData.uuid, dealUuid, orderUuid, documentId),
      'DELETE',
    );
    const orderDocumentsPagination = yield select(getOrderDocumentsPagination);
    yield put(fetchOrderDocuments({ ...orderDocumentsPagination, dealUuid, orderUuid, type }));
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* deleteOrderDocumentWatcher() {
  yield takeEvery(DELETE_ORDER_DOCUMENT, deleteOrderDocument);
}

function* changeOrderDocumentAgreedStatus({
  payload: { orderUuid, dealUuid, isAgree, documentId, type },
}) {
  try {
    const { data: companyData } = yield select(getCompanySelector);
    const APIEndpoint = isAgree ? API.OrderDocumentAgree : API.OrderDocumentDisagree;
    yield call(request, APIEndpoint(companyData.uuid, dealUuid, orderUuid, documentId), 'PUT');
    const orderDocumentsPagination = yield select(getOrderDocumentsPagination);
    yield put(
      fetchOrderDocuments({
        ...orderDocumentsPagination,
        dealUuid,
        orderUuid,
        type,
      }),
    );
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }
}

function* changeOrderDocumentAgreedStatusWatcher() {
  yield takeEvery(CHANGE_ORDER_DOCUMENT_AGREED_STATUS, changeOrderDocumentAgreedStatus);
}

export {
  fetchOrderDocumentsWatcher,
  uploadOrderDocumentWatcher,
  deleteOrderDocumentWatcher,
  changeOrderDocumentAgreedStatusWatcher,
};
