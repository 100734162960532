import { FC } from 'react';

type AllocationIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const AllocationIcon: FC<React.PropsWithChildren<AllocationIconProps>> = ({
  width = 20.145,
  height = 17.644,
  fillColor = '#00bbd2',
}) => (
  <svg
    id="Component_17_160"
    data-name="Component 17 – 160"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20.145 17.644"
  >
    <g id="convert-3209">
      <path
        id="Path_1937"
        data-name="Path 1937"
        d="M1.686,18.448c.016.006.032.01.048.015a.67.67,0,0,0,.09.021c.008,0,.016,0,.024.006a.7.7,0,0,0,.08,0,.672.672,0,0,0,.133-.013.683.683,0,0,0,.076-.023c.016-.005.033-.009.049-.015a.645.645,0,0,0,.081-.043c.011-.006.023-.011.034-.018a.68.68,0,0,0,.1-.084L4.121,16.58a.671.671,0,0,0-.949-.949l-.339.339a7.481,7.481,0,0,1,14.04-1.276.671.671,0,1,0,1.219-.563,8.822,8.822,0,0,0-16.6,1.682l-.246-.4a.672.672,0,1,0-1.147.7l1.257,2.065.009.011a.677.677,0,0,0,.071.091c.006.007.011.014.017.021a.676.676,0,0,0,.109.089c.01.007.022.011.032.018A.659.659,0,0,0,1.686,18.448Z"
        transform="translate(0 -9.001)"
        fill={fillColor}
      />
      <path
        id="Path_1938"
        data-name="Path 1938"
        d="M31.728,70.056l-1.257-2.065-.008-.011a.682.682,0,0,0-.073-.093l-.015-.019a.667.667,0,0,0-.109-.089c-.01-.007-.021-.011-.032-.017a.659.659,0,0,0-.094-.047c-.016-.006-.032-.01-.048-.015a.67.67,0,0,0-.09-.021c-.008,0-.016,0-.024-.006s-.023,0-.035,0-.03,0-.045,0-.026,0-.04,0-.041,0-.062.006a.642.642,0,0,0-.083.019c-.018.005-.036.01-.053.016a.669.669,0,0,0-.087.041c-.013.007-.027.013-.04.021a.672.672,0,0,0-.112.089L27.7,69.583a.671.671,0,0,0,.949.949l.339-.339A7.481,7.481,0,0,1,14.856,71.25a.672.672,0,0,0-1.237.523A8.822,8.822,0,0,0,30.335,70.35l.246.4a.672.672,0,0,0,1.147-.7Z"
        transform="translate(-11.681 -59.518)"
        fill="#00bbd2"
      />
    </g>
  </svg>
);

export default AllocationIcon;
