export type SharePlanPayrollStateType = {
  uploadPayroll: {
    loading: boolean;
    error: string | undefined;
    uploaded: boolean;
  };
};

const initialState: SharePlanPayrollStateType = {
  uploadPayroll: {
    loading: false,
    error: undefined,
    uploaded: false,
  },
};

export default initialState;
