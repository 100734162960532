// TODO: THIS IS ALREADY A THING IN LODASH
// https://lodash.com/docs/4.17.15#get
// TODO: This is also only used in one place?
export const ARGS_OBJECT_TYPE_ERROR = 'Obj is not an object';
export const ARGS_PATH_TYPE_ERROR = 'Prop is not a string';

export const resolveObjectPath = (obj: Record<string, any>, path: string) => {
  if (typeof obj !== 'object' || obj === null) throw ARGS_OBJECT_TYPE_ERROR;
  if (typeof path !== 'string') throw ARGS_PATH_TYPE_ERROR;

  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj || self);
};
