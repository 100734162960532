import { createSelector } from 'reselect';

import { getAvailableDeals, getDeals } from 'app-state/deals/selectors';

export const getInvestorsState = state => state.investors;
export const getInvestorsItems = createSelector(getInvestorsState, ({ data: { items } }) => items);
export const getInvestorsLoading = createSelector(getInvestorsState, ({ loading }) => loading);
export const getInvestorsErrors = createSelector(getInvestorsState, ({ errors }) => errors);
export const getInvestorsPagination = createSelector(
  getInvestorsState,
  ({
    data: {
      meta: { pagination },
    },
  }) => pagination || {},
);

const getInvestorWalletReferenceState = state => state.investorWalletReference;
export const getInvestorWalletReferenceLoadingFlag = createSelector(
  getInvestorWalletReferenceState,
  ({ loading }) => loading,
);
export const getInvestorWalletReference = createSelector(
  getInvestorWalletReferenceState,
  ({ data }) => data.reference,
);

const getWalletTransactions = state => state.walletTransactions;
export const getWalletTransactionsLoadingFlag = createSelector(
  getWalletTransactions,
  ({ loading }) => loading,
);
export const getWalletTransactionsError = createSelector(
  getWalletTransactions,
  ({ error }) => error,
);
export const getWalletTransactionsItems = createSelector(
  getWalletTransactions,
  ({ data }) => data.items,
);
export const getWalletTransactionsPagination = createSelector(
  getWalletTransactions,
  ({ data }) => data.meta.pagination,
);

export const getInvestorInvestmentsState = state => state.investorInvestments;
export const getInvestorInvestmentsItems = createSelector(
  getInvestorInvestmentsState,
  ({ data: { items } }) => items,
);
export const getInvestorInvestmentsLoading = createSelector(
  getInvestorInvestmentsState,
  ({ loading }) => loading,
);

export const getInvestorInvestmentsPagination = createSelector(
  getInvestorInvestmentsState,
  ({
    data: {
      meta: { pagination },
    },
  }) => pagination || {},
);

export const getInvestorPreferencesState = state => state.investorPreferences;
export const getInvestorPreferences = createSelector(
  getInvestorPreferencesState,
  ({ data }) => data,
);
export const getInvestorPreferencesLoading = createSelector(
  getInvestorPreferencesState,
  ({ loading }) => loading,
);

const getInvestorDeals = state => state.investorDeals;

// TODO: Move this logic to BE
export const getInvestorDealsWithPermissions = createSelector(
  getDeals,
  getAvailableDeals,
  getInvestorDeals,
  ({ data: deals }, availableDeals, { data: investorDeals }) =>
    deals.length > 0
      ? investorDeals.map(deal => ({
          ...deal,
          isLocked: !availableDeals.some(({ uuid }) => uuid === deal.uuid),
        }))
      : [],
);
