import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const initialCompaniesState = getReducerState({ data: [] });

export const companiesReducer = (state = initialCompaniesState, action) => {
  switch (action.type) {
    case constants.GET_COMPANIES_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_COMPANIES_ERROR:
      return { loading: false, data: [], error: action.error };
    default:
      return state;
  }
};

export const initialCompanyState = getReducerState();

export const companyReducer = (state = initialCompanyState, action) => {
  switch (action.type) {
    case constants.GET_COMPANY_SUCCESS:
    case constants.EDIT_COMPANY_SUCCESS:
    case constants.SET_COMPANY:
    case constants.VERIFY_COMPANY_KYB_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_COMPANY_ERROR:
      return { loading: false, data: {}, error: action.error };
    case constants.GET_COMPANY:
    case constants.RESET_COMPANY:
      return initialCompanyState;
    default:
      return state;
  }
};

export const initialCompanyTagsState = { loading: false, data: [], error: null };

export const companyTagsReducer = (state = initialCompanyTagsState, { type, data, error }) => {
  switch (type) {
    case constants.GET_COMPANY_TAGS:
      return { ...initialCompanyTagsState, loading: true };
    case constants.GET_COMPANY_TAGS_SUCCESS:
      return { loading: false, data, error: null };
    case constants.GET_COMPANY_TAGS_ERROR:
      return { loading: false, data: null, error };
    case constants.RESET_COMPANY_TAGS:
      return initialCompanyTagsState;
    default:
      return state;
  }
};

const companySummaryState = {
  loading: false,
  data: {},
  error: null,
  loadingEdit: false,
};

export const companySummaryReducer = (state = companySummaryState, { type, data, error }) => {
  switch (type) {
    case constants.CREATE_COMPANY_SUMMARY:
    case constants.EDIT_COMPANY_SUMMARY:
      return { ...state, loadingEdit: true, error: null };
    case constants.CREATE_COMPANY_SUMMARY_SUCCESS:
    case constants.EDIT_COMPANY_SUMMARY_SUCCESS:
      return { ...state, loadingEdit: false, error: null, data };
    case constants.CREATE_COMPANY_SUMMARY_ERROR:
    case constants.EDIT_COMPANY_SUMMARY_ERROR:
      return { ...state, loadingEdit: false, data: null, error };

    case constants.GET_COMPANY_SUMMARY:
      return { ...companySummaryState, loading: true };
    case constants.GET_COMPANY_SUMMARY_SUCCESS:
      return { loading: false, data, error: null };
    case constants.GET_COMPANY_SUMMARY_ERROR:
      return { loading: false, data: null, error };

    case constants.CLEAR_COMPANY_SUMMARY:
      return companySummaryState;

    default:
      return state;
  }
};
