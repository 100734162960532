import { useEffect } from 'react';

import { Container, Image } from './box-image.styled';

interface Index {
  url: string;
  small: boolean;
  handleImageClick: (id?: string | null) => void;
  isImageExpanded: boolean;
  id: string;
}

const BoxImage = ({ url, small, handleImageClick = () => null, isImageExpanded, id }: Index) => {
  useEffect(() => {
    handleImageClick(null);

    return () => {
      handleImageClick(null);
    };
  }, []);

  return (
    <Container>
      <Image
        src={url}
        isSmall={small}
        onClick={() => handleImageClick(isImageExpanded ? null : id)}
        alt=""
        loading="lazy"
      />
    </Container>
  );
};

export default BoxImage;
