import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCountries as getCountriesAction } from 'app-state/actions';
import { getCountriesSelector } from 'app-state/selectors/countries';

import { transformToMap } from 'shared-parts/components/country/helper';
import Select from 'shared-parts/components/select';

const Country = ({
  getCountries,
  countries,
  disabled,
  disableAutocomplete,
  placeholder = 'Country',
  name = 'countryCode',
  onOptionSelect = () => {},
}) => {
  useEffect(() => {
    getCountries();
  }, []);
  const countriesData = countries.data ?? [];
  const transformedCountryData = transformToMap(countriesData);

  return (
    <Select
      name={name}
      placeholder={placeholder}
      disableAutocomplete={disableAutocomplete}
      options={transformedCountryData}
      disabled={disabled}
      onOptionSelect={onOptionSelect}
    />
  );
};

const mapStateToProps = state => ({
  countries: getCountriesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(getCountriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Country);
