export const SHAREHOLDER_REGISTRY = 'mc.register.shr';
export const SHR_EXPORT = 'mc.register.shr_export';
export const SHR_EXPORT_COMPANY_VIEWER = 'mc.register.shr_anonymous_export';
export const SHR_HISTORICAL = 'mc.register.shr_historical';
export const TRANSACTION_IMPORT = 'mc.register.transaction_import';
export const TRANSACTIONS_EXPORT = 'mc.register.transaction_export';
export const SHARE_CERTIFICATE = 'mc.register.share_certificate';
export const SHARE_CLASS = 'mc.register.share_class';
export const SHARE_CLASS_EDIT = 'mc.register.edit_share_class';
export const SHARE_CLASS_CONVERT = 'mc.register.convert_share_class';
export const SHAREHOLDERS = 'mc.register.shareholder';
export const SHAREHOLDER_ADDRESS = 'mc.register.shareholder_address';
export const COMMUNICATIONS_VIEW = 'mc.communications.view';
export const COMMUNICATIONS_EDIT = 'mc.communications';
export const COMMUNICATIONS_RESPONSE = 'mc.communications.responses';
export const COMMUNICATION_CHAT = 'mc.communication.chat';
export const SHARE_OPTIONS_EDIT = 'mc.share_options';
export const ACCOUNT_USERS = 'mc.company_users';
export const ACCOUNT_COMPANY_DETAILS = 'mc.companies';
export const EDIT_COMPANY = 'mc.edit_companies';
export const ALL_USERS = 'mc.users';
export const PERMISSION_LEVELS = 'mc.permission_levels';
export const DEALS = 'mc.deals';
export const DASHBOARD = 'mc.dashboard';
export const EDIT_USERS = 'mc.edit_users';
export const SECONDARY_MARKET = 'mc.sell_securities';
export const CONVERTIBLE_NOTES = 'mc.convertible_notes';
export const FILINGS = 'mc.filing_histories';
export const GENERATE_ONBOARDING_LINK = 'mc.settings.discount';
export const SETTINGS = 'mc.settings';
export const IMPORT_SHAREHOLDERS = 'mc.register.shareholder_import';
export const LIQUIDITY_ROUNDS = 'mc.liquidity_rounds';
export const ORDERS = 'mc.orders';
