import * as constants from 'app-state/constants';

export const generateOnboardingLink = data => ({ type: constants.GENERATE_ONBOARDING_LINK, data });

export const setGenerateOnboardingLinkError = error => ({
  type: constants.SET_GENERATE_ONBOARDING_LINK_ERROR,
  error,
});
export const resetGenerateOnboardingLinkError = () => ({
  type: constants.RESET_GENERATE_ONBOARDING_LINK_ERROR,
});
