import { COLORS } from 'shared-parts/constants';

const BackArrow = ({ height = 22, width = 12, color = COLORS.primary, className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 7.2 11.2"
    height={height}
    width={width}
    className={className}
  >
    <g id="Path_873">
      <path
        fill={color}
        d="M6.7,11.2c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.1-0.5-0.1-0.7L1.3,5.8L7,0.9c0.2-0.2,0.2-0.5,0.1-0.7
        C6.9,0,6.6-0.1,6.4,0.1L0.2,5.4C0.1,5.5,0,5.6,0,5.8s0.1,0.3,0.2,0.4l6.2,5C6.5,11.2,6.6,11.2,6.7,11.2z"
      />
    </g>
  </svg>
);

export default BackArrow;
