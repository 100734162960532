import {
  CHANGE_ORDER_DOCUMENT_AGREED_STATUS,
  DELETE_ORDER_DOCUMENT,
  FETCH_ORDER_DOCUMENTS,
  FETCH_ORDER_DOCUMENTS_ERROR,
  FETCH_ORDER_DOCUMENTS_SUCCESS,
  RESET_ORDER_DOCUMENTS,
  UPLOAD_ORDER_DOCUMENT,
  UPLOAD_ORDER_DOCUMENT_FINISHED,
} from './constants';

export const fetchOrderDocuments = payload => ({ type: FETCH_ORDER_DOCUMENTS, payload });
export const fetchOrderDocumentsSuccess = payload => ({
  type: FETCH_ORDER_DOCUMENTS_SUCCESS,
  payload,
});
export const fetchOrderDocumentsError = payload => ({ type: FETCH_ORDER_DOCUMENTS_ERROR, payload });
export const resetOrderDocuments = () => ({ type: RESET_ORDER_DOCUMENTS });

export const deleteOrderDocument = payload => ({ type: DELETE_ORDER_DOCUMENT, payload });

export const uploadOrderDocument = payload => ({ type: UPLOAD_ORDER_DOCUMENT, payload });
export const uploadOrderDocumentFinished = payload => ({
  type: UPLOAD_ORDER_DOCUMENT_FINISHED,
  payload,
});

export const changeOrderAgreedStatus = payload => ({
  type: CHANGE_ORDER_DOCUMENT_AGREED_STATUS,
  payload,
});
