import {
  ADD_FILING_DOCUMENT,
  ADD_FILING_DOCUMENT_ERROR,
  ADD_FILING_DOCUMENT_SUCCESS,
  CREATE_CONFIRMATION_STATEMENT,
  DELETE_FILING_DOCUMENT,
  DELETE_FILING_DOCUMENT_ERROR,
  DELETE_FILING_DOCUMENT_SUCCESS,
  DOWNLOAD_FILINGS_AS_CSV,
  GET_ALLOTMENT_DATA,
  GET_ALLOTMENT_DATA_ERROR,
  GET_ALLOTMENT_DATA_SUCCESS,
  GET_ALLOTTED_SHARES,
  GET_ALLOTTED_SHARES_ERROR,
  GET_ALLOTTED_SHARES_SUCCESS,
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_ERROR,
  GET_COMPANY_PROFILE_SUCCESS,
  GET_FILING_DETAILS,
  GET_FILING_DETAILS_ERROR,
  GET_FILING_DETAILS_SUCCESS,
  GET_FILING_DOCUMENTS_SUCCESS,
  GET_FILINGS,
  GET_FILINGS_ERROR,
  GET_FILINGS_SUCCESS,
  GET_SHARE_CAPITAL,
  GET_SHARE_CAPITAL_ERROR,
  GET_SHARE_CAPITAL_SUCCESS,
  GET_SHAREHOLDER_INFORMATION,
  GET_SHAREHOLDER_INFORMATION_ERROR,
  GET_SHAREHOLDER_INFORMATION_SUCCESS,
  GET_SIC_CODES,
  GET_SIC_CODES_ERROR,
  GET_SIC_CODES_SUCCESS,
  RESET_ALLOTMENT_DATA,
  RESET_ALLOTTED_SHARES,
  RESET_COMPANY_PROFILE,
  RESET_FILING_DETAILS,
  RESET_FILINGS,
  RESET_SHARE_CAPITAL,
  RESET_SHAREHOLDER_INFORMATION,
  SUBMIT_SH01_FORM,
} from 'app-state/constants/filings';

export const getCompanyProfile = params => ({ type: GET_COMPANY_PROFILE, ...params });
export const getCompanyProfileSuccess = data => ({ type: GET_COMPANY_PROFILE_SUCCESS, data });
export const getCompanyProfileError = error => ({ type: GET_COMPANY_PROFILE_ERROR, error });
export const resetCompanyProfile = () => ({ type: RESET_COMPANY_PROFILE });

export const getFilingsAction = params => ({ type: GET_FILINGS, ...params });
export const getFilingsSuccess = data => ({ type: GET_FILINGS_SUCCESS, data });
export const getFilingsError = error => ({ type: GET_FILINGS_ERROR, error });
export const resetFilingsAction = () => ({ type: RESET_FILINGS });

export const getFilingDetailsAction = params => ({ type: GET_FILING_DETAILS, ...params });
export const getFilingDetailsSuccess = data => ({ type: GET_FILING_DETAILS_SUCCESS, data });
export const getFilingDetailsError = error => ({ type: GET_FILING_DETAILS_ERROR, error });
export const resetFilingDetailsAction = () => ({ type: RESET_FILING_DETAILS });
export const getFilingDocumentsSuccess = data => ({ type: GET_FILING_DOCUMENTS_SUCCESS, data });

export const addFilingDocumentAction = params => ({ type: ADD_FILING_DOCUMENT, ...params });
export const addFilingDocumentSuccess = data => ({ type: ADD_FILING_DOCUMENT_SUCCESS, data });
export const addFilingDocumentError = error => ({ type: ADD_FILING_DOCUMENT_ERROR, error });

export const deleteFilingDocumentAction = params => ({ type: DELETE_FILING_DOCUMENT, ...params });
export const deleteFilingDocumentSuccess = documentId => ({
  type: DELETE_FILING_DOCUMENT_SUCCESS,
  documentId,
});
export const deleteFilingDocumentError = error => ({ type: DELETE_FILING_DOCUMENT_ERROR, error });

export const downloadFilingsAsCSVAction = params => ({ type: DOWNLOAD_FILINGS_AS_CSV, ...params });

export const getShareCapital = payload => ({ type: GET_SHARE_CAPITAL, payload });
export const getShareCapitalSuccess = data => ({ type: GET_SHARE_CAPITAL_SUCCESS, data });
export const getShareCapitalError = error => ({ type: GET_SHARE_CAPITAL_ERROR, error });
export const resetShareCapital = () => ({ type: RESET_SHARE_CAPITAL });

export const getShareholderInformation = params => ({ type: GET_SHAREHOLDER_INFORMATION, params });
export const getShareholderInformationSuccess = data => ({
  type: GET_SHAREHOLDER_INFORMATION_SUCCESS,
  data,
});
export const getShareholderInformationError = error => ({
  type: GET_SHAREHOLDER_INFORMATION_ERROR,
  error,
});
export const resetShareholderInformation = () => ({ type: RESET_SHAREHOLDER_INFORMATION });

export const createConfirmationStatement = payload => ({
  type: CREATE_CONFIRMATION_STATEMENT,
  payload,
});

export const getSicCodes = () => ({ type: GET_SIC_CODES });
export const getSicCodesSuccess = payload => ({ type: GET_SIC_CODES_SUCCESS, payload });
export const getSicCodesError = payload => ({ type: GET_SIC_CODES_ERROR, payload });

export const getAllotmentData = payload => ({ type: GET_ALLOTMENT_DATA, payload });
export const getAllotmentDataSuccess = payload => ({ type: GET_ALLOTMENT_DATA_SUCCESS, payload });
export const getAllotmentDataError = payload => ({ type: GET_ALLOTMENT_DATA_ERROR, payload });
export const resetAllotmentData = () => ({ type: RESET_ALLOTMENT_DATA });

export const getAllottedShares = payload => ({ type: GET_ALLOTTED_SHARES, payload });
export const getAllottedSharesSuccess = payload => ({ type: GET_ALLOTTED_SHARES_SUCCESS, payload });
export const getAllottedSharesError = payload => ({ type: GET_ALLOTTED_SHARES_ERROR, payload });
export const resetAllottedShares = () => ({ type: RESET_ALLOTTED_SHARES });

export const submitSh01Form = payload => ({ type: SUBMIT_SH01_FORM, payload });
