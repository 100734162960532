import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers/app-config';

const investorsDisabled = useFeatureFlag(FeatureFlag.NEW_INVESTOR_INVITE_FLOW);

export default [
  {
    title: 'All Investors',
    to: Routes.Globacap.AllInvestors(),
    hidden: investorsDisabled,
    exact: true,
  },
];
