import { ApolloClient, InMemoryCache } from '@apollo/client';

import { useEnvVar } from 'helpers/use-env-var';

const apolloClient = new ApolloClient({
  uri: useEnvVar('GRAPHQL_ENDPOINT'),
  cache: new InMemoryCache(),
});

export default apolloClient;
