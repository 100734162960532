import * as constants from 'app-state/constants';

export const uploadFile = (fileData, api) => ({
  type: constants.UPLOAD_FILE,
  fileData,
  api,
});
export const uploadFileSuccess = data => ({ type: constants.UPLOAD_FILE_SUCCESS, data });
export const uploadFileError = error => ({ type: constants.UPLOAD_FILE_ERROR, error });

export const downloadFile = (fileName, api, timeout) => ({
  type: constants.DOWNLOAD_FILE,
  fileName,
  api,
  timeout,
});
export const downloadFileSuccess = () => ({ type: constants.DOWNLOAD_FILE_SUCCESS });
export const downloadFileError = error => ({ type: constants.DOWNLOAD_FILE_ERROR, error });
export const resetDownloadFile = () => ({ type: constants.RESET_DOWNLOAD_FILE });
