import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './share-plan-payroll.constants';
import initialState, { SharePlanPayrollStateType } from './share-plan-payroll.state';

export const sharePlanPayrollReducer = (
  state = initialState,
  action: AnyAction,
): SharePlanPayrollStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.uploadPayroll.REQUEST:
        draft.uploadPayroll.loading = true;
        draft.uploadPayroll.error = undefined;
        draft.uploadPayroll.uploaded = false;
        break;
      case constants.uploadPayroll.FAILURE:
        draft.uploadPayroll.loading = false;
        draft.uploadPayroll.error = action.error;
        draft.uploadPayroll.uploaded = false;
        break;
      case constants.uploadPayroll.SUCCESS:
        draft.uploadPayroll.loading = false;
        draft.uploadPayroll.error = undefined;
        draft.uploadPayroll.uploaded = true;
        break;
      case constants.resetUploadPayroll:
        draft.uploadPayroll.loading = false;
        draft.uploadPayroll.error = undefined;
        draft.uploadPayroll.uploaded = false;
        break;
      default:
        break;
    }
  });
