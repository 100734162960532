import { FinishIcon } from 'svg-icons';
import { FormikButton } from 'shared-parts/components';

import { ModalTitle, TransactionsSettingsModalWrapper } from './transactions-settings-modal.styled';

const TransactionsSettingsModal = ({ hideModal }) => (
  <TransactionsSettingsModalWrapper>
    {FinishIcon()}
    <ModalTitle>Transaction Settings Updated</ModalTitle>
    <FormikButton onClick={hideModal}>CONTINUE</FormikButton>
  </TransactionsSettingsModalWrapper>
);

export default TransactionsSettingsModal;
