import { useState } from 'react';
// This works fine for us! React18 has this stable
// and it's way better than merging state tbh
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import {
  DataGridPremiumProps,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridRowGroupingModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 50,
};

type UseDataGridProps = {
  initialState: {
    sort?: GridSortModel;
    filter?: GridFilterModel;
    pagination?: GridPaginationModel;
    grouping?: GridRowGroupingModel;
  };
  additionalFilters?: { key: string; value: string }[];
  quickFilterMap?: (quickFilterValues: any[]) => any[][];
};

type UseLocalDataGrid = {
  gridProps: Partial<DataGridPremiumProps>;
  changeRowGrouping: (grouping: GridRowGroupingModel) => void;
  getCurrentApiRef: () => GridApiPremium;
};

export const getTogglableColumns = (columns: GridColDef<any>[], hiddenColumns: string[]) => {
  const visibleColumns = columns
    .filter(column => !hiddenColumns.some(hiddenColumn => hiddenColumn === column.field))
    .map(column => column.field);

  return visibleColumns;
};

export const useLocalDataGrid = ({ initialState }: UseDataGridProps): UseLocalDataGrid => {
  const apiRef = useGridApiRef();

  const [sortModel, setSortModel] = useState<GridSortModel>(initialState?.sort ?? []);

  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>(
    initialState?.grouping ?? [],
  );

  const changeRowGrouping = (grouping: GridRowGroupingModel) => setRowGroupingModel(grouping);

  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    initialState?.filter ?? { logicOperator: GridLogicOperator.And, items: [] },
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    initialState?.pagination || DEFAULT_PAGINATION_MODEL,
  );

  const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map(item => item.field);
    return columns
      .filter(
        colDef =>
          colDef.filterable && (colDef.field === field || !filteredFields.includes(colDef.field)),
      )
      .map(column => column.field);
  };

  const getColumnForNewFilter = ({ currentFilters, columns }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(colDef => colDef.filterable && !filteredFields.includes(colDef.field))
      .find(colDef => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  return {
    getCurrentApiRef: () => apiRef.current,
    changeRowGrouping,
    gridProps: {
      apiRef,
      sortingMode: 'client',
      sortModel,
      rowGroupingModel,
      onSortModelChange: model => setSortModel(model),
      slotProps: {
        filterPanel: {
          filterFormProps: {
            filterColumns,
          },
          getColumnForNewFilter,
        },
      },
      pagination: true,
      paginationMode: 'client',
      paginationModel,
      onPaginationModelChange: model => setPaginationModel(model),

      disableColumnFilter: false,
      filterMode: 'client',
      filterModel,
      density: 'compact',
      onFilterModelChange: model => {
        batchUpdates(() => {
          const pageModel = initialState?.pagination ?? DEFAULT_PAGINATION_MODEL;
          setPaginationModel(pageModel);
          setFilterModel(model);
        });
      },
    },
  };
};
