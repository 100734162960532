import { CurrenciesState } from 'app-state/currencies/types';

/* eslint-disable-next-line */
export const getCurrenciesOptions = (currencies: CurrenciesState) =>
  currencies.data.wallet
    ? currencies.data.wallet.map((currency: string) => ({
        text: currency,
        value: currency,
      }))
    : [];
