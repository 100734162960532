import { CH_SUCCESS_MODAL } from '../constants';
import { StyledParagraph } from '../index.styled';

import { StyledSecondaryHeading, StyledSuccessModal } from './cs01-success-modal.styled';

export default {
  component: StyledSuccessModal,
  title: CH_SUCCESS_MODAL.TITLE,
  additionalText: (
    <>
      <StyledSecondaryHeading>{CH_SUCCESS_MODAL.CS01_SUBMITTED_MESSAGE[0]}</StyledSecondaryHeading>
      <StyledParagraph>{CH_SUCCESS_MODAL.CS01_SUBMITTED_MESSAGE[1]}</StyledParagraph>
    </>
  ),
  buttonText: CH_SUCCESS_MODAL.BUTTON,
  modalWidth: 600,
  iconSize: 40,
};
