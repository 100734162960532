import styled from 'styled-components';

import { COLORS } from 'shared-parts/constants';

const { secondary100 } = COLORS;

const UserEmail = styled.div`
  background-color: ${secondary100};
  border-radius: 6px;
  padding: 5px;
`;

const UserName = styled.p`
  background: transparent;
  border: 0;
  cursor: pointer;
  white-space: nowrap;

  :hover {
    text-decoration: underline;
  }
`;

export { UserEmail, UserName };
