import humps from 'humps';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { hideSideForm } from 'app-state/actions/shared';
import { getCompany } from 'app-state/selectors';

import api from 'constants/api';
import request from 'shared-parts/helpers/request';

import {
  getShareCertificateConfigError,
  getShareCertificateConfigSuccess,
  updateShareCertificateConfig as updateShareCertificateConfigAction,
  updateShareCertificateConfigError,
  updateShareCertificateConfigSuccess,
} from './actions';
import { GET_SHARE_CERTIFICATE_CONFIG, UPDATE_SHARE_CERTIFICATE_CONFIG } from './constants';

function* getShareCertificateConfig(): Generator<any, any, any> {
  try {
    const company = yield select(getCompany);
    const { data } = yield call(request, api.ShareCertificateConfig.Get(company.data.uuid));
    yield put(getShareCertificateConfigSuccess(data));
  } catch (e: any) {
    console.error(e);

    if ('response' in e) {
      yield put(getShareCertificateConfigError(e.response));
    } else if (e instanceof TypeError || e instanceof SyntaxError) {
      yield put(getShareCertificateConfigError(e.message));
    }
  }
}

export function* getShareCertificateConfigWatcher() {
  yield takeLatest(GET_SHARE_CERTIFICATE_CONFIG, getShareCertificateConfig);
}

function* updateShareCertificateConfig(
  payload: ReturnType<typeof updateShareCertificateConfigAction>,
): Generator<any, any, any> {
  try {
    const company = yield select(getCompany);

    const { directors, ...fields } = payload.data;

    // Convert to form data
    const formData = new FormData();
    Object.entries(fields).forEach(([key, val]) => {
      // TODO: I think ts reverted object.entries, check as we go along
      if (Array.isArray(val)) {
        formData.set(humps.decamelize(key), val.toString());
      } else {
        formData.set(humps.decamelize(key), val);
      }
    });

    directors?.forEach(director => {
      Object.entries(director).forEach(([key, val]) => {
        formData.append(`directors[][${key}]`, val);
      });
    });

    const { data } = yield call(
      request,
      api.ShareCertificateConfig.Update(company.data.uuid),
      'PUT',
      formData,
    );
    yield put(updateShareCertificateConfigSuccess(data));
    yield put(hideSideForm());
  } catch (e: any) {
    console.error(e);

    if ('response' in e) {
      yield put(updateShareCertificateConfigError(e.response));
    } else if (e instanceof Error) {
      yield put(updateShareCertificateConfigError(e.message));
    }
  }
}

export function* updateShareCertificateConfigWatcher() {
  yield takeLatest(UPDATE_SHARE_CERTIFICATE_CONFIG, updateShareCertificateConfig);
}
