import { call, put, select, takeEvery } from 'redux-saga/effects';

import { hideModal, showModal } from 'app-state/actions/shared';

import API from 'constants/api';
import ErrorModal from 'shared-parts/components/modal-failed';
import request from 'shared-parts/helpers/request';

import {
  approveDealIOIError,
  approveDealIOISuccess,
  cancelDealIOIError,
  cancelDealIOISuccess,
  fetchDealIOIDetails as fetchDealIOIDetailsAction,
  fetchDealIOIDetailsError,
  fetchDealIOIDetailsSuccess,
  fetchIndicationOfInterestData as fetchIndicationOfInterestDataAction,
  fetchIndicationOfInterestDataError,
  fetchIndicationOfInterestDataSuccess,
} from './actions';
import {
  ADJUST_DEAL_IOI,
  APPROVE_DEAL_IOI,
  CANCEL_DEAL_IOI,
  FETCH_DEAL_IOI_DETAILS,
  FETCH_INDICATION_OF_INTEREST_DATA,
} from './constants';
import { getIndicationOfInterestMeta } from './selectors';

function* fetchIndicationOfInterestData({ payload }) {
  try {
    const { data } = yield call(request, API.FetchIndicationOfInterest(payload));

    yield put(fetchIndicationOfInterestDataSuccess(data));
  } catch (e) {
    yield put(fetchIndicationOfInterestDataError(e));
  }
}

export function* fetchIndicationOfInterestDataWatcher() {
  yield takeEvery(FETCH_INDICATION_OF_INTEREST_DATA, fetchIndicationOfInterestData);
}

function* fetchDealIOIDetails({ payload }) {
  try {
    const { data } = yield call(request, API.DealIOIDetails(payload));

    yield put(fetchDealIOIDetailsSuccess(data));
  } catch (error) {
    yield put(fetchDealIOIDetailsError(error));
  }
}

export function* fetchDealIOIDetailsWatcher() {
  yield takeEvery(FETCH_DEAL_IOI_DETAILS, fetchDealIOIDetails);
}

function* approveDealIOI({ payload: { dealUuid, ioiUuid } }) {
  try {
    yield call(request, API.ApproveDealIOI({ dealUuid, ioiUuid }), 'PUT');

    yield put(approveDealIOISuccess());
    yield put(fetchDealIOIDetailsAction({ dealUuid, ioiUuid }));
    yield put(hideModal());

    const {
      order,
      pagination: { page, perPage },
    } = yield select(getIndicationOfInterestMeta);
    yield put(fetchIndicationOfInterestDataAction({ dealUuid, page, perPage, order }));
  } catch (error) {
    yield put(approveDealIOIError(error));

    if (error.status === 400) {
      yield put(
        showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

export function* approveDealIOIWatcher() {
  yield takeEvery(APPROVE_DEAL_IOI, approveDealIOI);
}

function* cancelDealIOI({ payload: { dealUuid, ioiUuid, note } }) {
  try {
    yield call(request, API.CancelDealIOI({ dealUuid, ioiUuid }), 'PUT', {
      cancellationReason: note,
    });

    yield put(cancelDealIOISuccess());
    yield put(fetchDealIOIDetailsAction({ dealUuid, ioiUuid }));

    const {
      order,
      pagination: { page, perPage },
    } = yield select(getIndicationOfInterestMeta);
    yield put(fetchIndicationOfInterestDataAction({ dealUuid, page, perPage, order }));
  } catch (error) {
    yield put(cancelDealIOIError(error));

    if (error.status === 400) {
      yield put(
        showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

export function* cancelDealIOIWatcher() {
  yield takeEvery(CANCEL_DEAL_IOI, cancelDealIOI);
}

function* adjustDealIOI({ payload: { dealUuid, ioiUuid, data, hideForm } }) {
  try {
    yield call(request, API.AdjustDealIOI({ dealUuid, ioiUuid }), 'POST', data);

    yield put(fetchDealIOIDetailsAction({ dealUuid, ioiUuid }));

    yield hideForm();
    const {
      order,
      pagination: { page, perPage },
    } = yield select(getIndicationOfInterestMeta);
    yield put(fetchIndicationOfInterestDataAction({ dealUuid, page, perPage, order }));
  } catch (error) {
    if (error.status === 400) {
      yield put(
        showModal({
          closable: true,
          showHeader: false,
          component: ErrorModal,
        }),
      );
    }
  }
}

export function* adjustDealIOIWatcher() {
  yield takeEvery(ADJUST_DEAL_IOI, adjustDealIOI);
}
