import {
  GET_TREASURY_ACCOUNT,
  GET_TREASURY_ACCOUNT_ERROR,
  GET_TREASURY_ACCOUNT_SUCCESS,
  RESET_TREASURY_ACCOUNT,
} from 'app-state/constants/treasury-account';

export const getTreasuryAccount = companyUuid => ({ type: GET_TREASURY_ACCOUNT, companyUuid });
export const getTreasuryAccountSuccess = data => ({ type: GET_TREASURY_ACCOUNT_SUCCESS, data });
export const getTreasuryAccountError = error => ({ type: GET_TREASURY_ACCOUNT_ERROR, error });
export const resetTreasuryAccount = () => ({ type: RESET_TREASURY_ACCOUNT });
