import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'helpers';

function* acceptTerms({ companyUuid, userUuid, formData, onSuccess, form }) {
  try {
    yield call(request, API.AcceptTerms(companyUuid, userUuid), 'PUT', formData);

    yield onSuccess();
  } catch (e) {
    if (e.response.details) {
      yield form.setErrors(e.response.details);
      yield form.setSubmitting(false);
    }
    yield put(actions.acceptTermsError(e));
  }
}

function* acceptTermsWatcher() {
  yield takeEvery(constants.ACCEPT_TERMS, acceptTerms);
}

export { acceptTerms, acceptTermsWatcher };
