import * as constants from 'app-state/constants';

import { PAGE_SIZE } from 'modules/company/equity-administration/shareholders/constants';

import getReducerState from './get-reducer-state';

export const shareholdersDropdownDataInitialState = getReducerState({
  data: { items: [], meta: {} },
  loading: true,
});

export const shareholdersDropdownDataReducer = (
  state = shareholdersDropdownDataInitialState,
  { type, data, error, shouldBeRefreshed },
) => {
  switch (type) {
    case constants.GET_SHAREHOLDERS_DROPDOWN_DATA:
      return { ...state, loading: true };
    case constants.GET_SHAREHOLDERS_DROPDOWN_DATA_ERROR:
      return { loading: false, data: state.data, error };
    case constants.GET_SHAREHOLDERS_DROPDOWN_DATA_SUCCESS:
      return {
        loading: false,
        data: {
          ...data,
          items: [...(shouldBeRefreshed ? [] : [...state.data.items]), ...data.items],
          isLastItemFetched: data.meta.pagination.recordsCount < PAGE_SIZE,
        },
        error: null,
      };
    case constants.RESET_SHAREHOLDERS_DROPDOWN_DATA:
      return shareholdersDropdownDataInitialState;
    default:
      return state;
  }
};

export const holdingsInitialState = getReducerState({ data: [] });

export const holdingsReducer = (state = holdingsInitialState, action) => {
  switch (action.type) {
    case constants.GET_HOLDINGS:
      return holdingsInitialState;
    case constants.GET_HOLDINGS_SUCCESS:
      return getReducerState({ loading: false, data: action.data });
    case constants.GET_HOLDINGS_ERROR:
      return getReducerState({
        loading: false,
        data: state.data,
        error: action.error,
      });
    case constants.RESET_HOLDINGS:
      return holdingsInitialState;
    default:
      return state;
  }
};

export const shareholdersInitialState = getReducerState({
  data: {
    items: [],
    meta: {
      pagination: {
        totalCount: 0,
      },
    },
  },
  success: false,
});

export const shareholdersReducer = (
  state = shareholdersInitialState,
  { type, data, shouldBeRefreshed, pageSize, error },
) => {
  switch (type) {
    case constants.GET_SHAREHOLDERS:
      return { ...state, loading: true };
    case constants.GET_SHAREHOLDERS_SUCCESS:
      return {
        loading: false,
        data: {
          ...data,
          items: shouldBeRefreshed ? data.items : [...state.data.items, ...data.items],
          isLastItemFetched: data.meta.pagination.recordsCount < pageSize,
        },
        error: null,
      };
    case constants.GET_SHAREHOLDERS_ERROR:
      return { loading: false, data: state.data, error };
    case constants.EDIT_SHAREHOLDER:
      return {
        ...state,
        success: false,
      };
    case constants.EDIT_SHAREHOLDER_SUCCESS:
      return getReducerState({
        loading: false,
        data: {
          ...state.data,
          items: state.data.items.map(i => (i.id === data.id ? { ...data, expanded: true } : i)),
        },
        success: true,
      });
    case constants.EDIT_SHAREHOLDER_ERROR:
      return {
        ...state,
        success: false,
      };
    case constants.RESET_SHAREHOLDERS:
      return shareholdersInitialState;
    default:
      return state;
  }
};

const registeredShareholdersInitialState = getReducerState({
  data: {
    items: [],
  },
  loading: false,
  error: null,
});

export const registeredShareholdersReducer = (
  state = registeredShareholdersInitialState,
  { type, data, error },
) => {
  switch (type) {
    case constants.GET_REGISTERED_SHAREHOLDERS:
      return { ...state, loading: true };

    case constants.GET_REGISTERED_SHAREHOLDERS_SUCCESS:
      return {
        loading: false,
        data: {
          ...state.data,
          items: data,
        },
        error: null,
      };

    case constants.GET_REGISTERED_SHAREHOLDERS_ERROR:
      return { loading: false, data: state.data, error };

    case constants.RESET_REGISTERED_SHAREHOLDERS:
      return registeredShareholdersInitialState;

    default:
      return state;
  }
};

const transferShareholdersInitialState = getReducerState({
  data: {
    items: [],
  },
});

export const transferShareholdersReducer = (
  state = transferShareholdersInitialState,
  { type, data, error },
) => {
  switch (type) {
    case constants.GET_TRANSFER_SHAREHOLDERS:
      return { ...state, loading: false };
    case constants.GET_TRANSFER_SHAREHOLDERS_SUCCESS:
      return {
        loading: false,
        data: {
          ...data,
          items: [...data.items],
        },
        error: null,
      };
    case constants.GET_TRANSFER_SHAREHOLDERS_ERROR:
      return { loading: false, data: state.data, error };
    default:
      return state;
  }
};

export const initialShareholderDetailsState = {
  shares: {},
  companyBaseCurrency: '',
  error: null,
};

export const shareholderDetailsReducer = (state = initialShareholderDetailsState, action) => {
  switch (action.type) {
    case constants.CONVERT_SHARES_FOR_INDIVIDUAL_HOLDER_SUCCESS:
      return {
        baseCurrency: action.data.companyBaseCurrency,
        shares: { data: action.data.shares, loading: false },
        error: null,
      };
    default:
      return state;
  }
};

export const defaultTransactionState = getReducerState({
  data: { items: [], meta: { pagination: {} } },
});

export const shareholderTransactionsReducer = (state = defaultTransactionState, action) => {
  switch (action.type) {
    case constants.UPDATE_SHAREHOLDER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map(i =>
            i.transactionId === action.data.transactionId ? action.data : i,
          ),
        },
      };
    default:
      return state;
  }
};

export const currentShareholderTransactionReducer = (state = getReducerState(), action) => {
  switch (action.type) {
    case constants.SAVE_CURRENT_SHAREHOLDER_TRANSACTION:
    case constants.ADD_TRANSACTION_NOTE_SUCCESS:
    case constants.UPDATE_TRANSACTION_NOTE_SUCCESS:
    case constants.REMOVE_TRANSACTION_NOTE_SUCCESS:
      return { data: action.data, loading: false, error: null };
    case constants.RESET_CURRENT_SHAREHOLDER_TRANSACTION:
      return getReducerState();
    default:
      return state;
  }
};

export const transferShareholdingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case constants.TRANSFER_SHAREHOLDING_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const allocateShareholdingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case constants.ALLOCATE_SHAREHOLDING_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const initialLookupShareholderState = { loading: false, data: null, error: null };

export const lookupShareholderReducer = (state = initialLookupShareholderState, action) => {
  switch (action.type) {
    case constants.LOOKUP_SHAREHOLDER:
      return { ...initialLookupShareholderState, loading: true };
    case constants.LOOKUP_SHAREHOLDER_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.LOOKUP_SHAREHOLDER_ERROR:
      return { loading: false, data: null, error: action.error };
    case constants.RESET_LOOKUP_SHAREHOLDER:
      return initialLookupShareholderState;
    default:
      return state;
  }
};

const searchShareholdersInitialState = { loading: false, data: [], error: null };

export const searchShareholdersReducer = (
  state = searchShareholdersInitialState,
  { type, payload },
) => {
  switch (type) {
    case constants.SEARCH_SHAREHOLDERS:
      return { ...searchShareholdersInitialState, loading: true };
    case constants.SEARCH_SHAREHOLDERS_SUCCESS:
      return { loading: false, data: payload.items, error: null };
    case constants.SEARCH_SHAREHOLDERS_ERROR:
      return { loading: false, data: [], error: payload };
    case constants.RESET_SHAREHOLDERS_SEARCH:
      return searchShareholdersInitialState;
    default:
      return state;
  }
};

export const shareholderPerformanceReducer = (state = { updateSuccess: false }, { type }) => {
  switch (type) {
    case constants.EDIT_SHAREHOLDER_PERFORMANCE:
      return { ...state, updateSuccess: false };
    case constants.EDIT_SHAREHOLDER_PERFORMANCE_SUCCESS:
      return { ...state, updateSuccess: true };
    case constants.EDIT_SHAREHOLDER_PERFORMANCE_ERROR:
      return { ...state, updateSuccess: false };
    default:
      return state;
  }
};
