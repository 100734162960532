import {
  GET_CURRENCIES,
  GET_CURRENCIES_ERROR,
  GET_CURRENCIES_SUCCESS,
  RESET_CURRENCIES,
} from 'app-state/currencies/constants';
import type { CurrenciesActionTypes, CurrenciesState } from 'app-state/currencies/types';

const defaultCurrenciesState: CurrenciesState = {
  loading: false,
  data: {},
  error: null,
};

export const currenciesReducer = (
  state = defaultCurrenciesState,
  action: CurrenciesActionTypes,
): CurrenciesState => {
  switch (action.type) {
    case GET_CURRENCIES:
      return { ...state, loading: true };
    case GET_CURRENCIES_SUCCESS:
      return { loading: false, data: { ...state.data, ...action.payload }, error: null };
    case GET_CURRENCIES_ERROR:
      return { ...defaultCurrenciesState, error: action.payload };
    case RESET_CURRENCIES:
      return defaultCurrenciesState;
    default:
      return state;
  }
};
