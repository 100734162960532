import styled from 'styled-components';

const ExitWithoutSaving = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  padding: 0 20px;
`;

export { ExitWithoutSaving }; // eslint-disable-line
