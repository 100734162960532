import { FC, PropsWithChildren } from 'react';

import { Box, formControlClasses, formGroupClasses } from '@mui/material';
import { Stack } from '@mui/system';

type FormGroupExpanded = Record<string, unknown>;

/**
 * An indented group of form controls, representing an expanded piece of the form, such as
 * follow up questions for a certain form control or form element.
 */
export const FormGroupExpanded: FC<PropsWithChildren<FormGroupExpanded>> = ({ children }) => {
  return (
    <Stack
      width="100%"
      direction="row"
      sx={{
        marginTop: 5,

        // We don't get margin collapsing in some scenarios when we're the last child
        // e.g.
        // FormControl
        //    Checkbox
        //    FormGroupExpanded
        //        Checkbox
        // FormControl
        // So to be safe, we just ignore any bottom margin if we're last in a list
        '&:not(:last-child)': {
          marginBottom: 5,
        },

        // We want any form control siblings to be closer together in a group
        [`& .${formControlClasses.root}~.${formControlClasses.root}`]: {
          marginTop: 4,
        },

        // We also want this gap to include form groups
        [`& .${formGroupClasses.root}~.${formControlClasses.root}`]: {
          marginTop: 4,
        },
      }}
    >
      <Box
        sx={{
          marginLeft: 5,
          marginRight: 4,
          width: '4px',
          borderRadius: 5,
          backgroundColor: 'neutral.100',
        }}
      />
      <Box flex={1}>{children}</Box>
    </Stack>
  );
};
