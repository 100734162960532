export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_ERROR = 'GET_DEALS_ERROR';

export const GET_USER_DEALS = 'GET_USER_DEALS';
export const GET_USER_DEALS_SUCCESS = 'GET_USER_DEALS_SUCCESS';
export const GET_USER_DEALS_ERROR = 'GET_USER_DEALS_ERROR';
export const RESET_USER_DEALS = 'RESET_USER_DEALS';

export const CREATE_DEAL = 'CREATE_DEAL';
export const SET_DEAL_STRUCTURE_FORM_ERROR = 'SET_DEAL_STRUCTURE_FORM_ERROR';
export const RESET_DEAL_STRUCTURE_FORM_ERROR = 'RESET_DEAL_STRUCTURE_FORM_ERROR';
