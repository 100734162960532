import { sessionService } from 'redux-react-session';

import Permissions from 'constants/permissions';
import UserData from 'constants/user-data';
import { getUsersOrganizationType } from 'helpers';

export default {
  can(permission, criteria) {
    return sessionService.loadUser().then(user => {
      const organizationType = getUsersOrganizationType(user);

      return Permissions[organizationType][permission]({ user, permission, criteria });
    });
  },
  has(what, criteria) {
    return sessionService.loadUser().then(user => {
      const organizationType = getUsersOrganizationType(user);

      return UserData[organizationType][what]({ user, what, criteria });
    });
  },
};
