import React, { FC } from 'react';

import { useEnvVar } from '@frontend/config';

import Routes from 'constants/routes';
import { XIcon } from 'svg-icons';
import { Chevrons } from 'shared-parts/components/chevrons';
import GFIPoweredByGlobacap from 'shared-parts/components/gfi-powered-by-globacap';
import { COLORS } from 'shared-parts/constants';

import {
  AuthContainerFormWrapper,
  AuthContainerFormWrapperAlternative,
  CloseLink,
  OuterContainer,
  palette,
} from './auth-container.styles';
import { AuthContainerBackNavigation } from './auth-container-navigation-back';

const isLoginBackNavigationEnabled = useEnvVar('ENABLED_LOGIN_BACK_NAVIGATION');
const isPoweredbyGlobacapEnabled = useEnvVar('ENABLED_POWERED_BY_GLOBACAP');
const isLoginExtraSpaceFormatEnabled = useEnvVar('ENABLED_LOGIN_EXTRA_SPACE_FORMAT');

export const AuthContainerForm: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  if (isLoginExtraSpaceFormatEnabled) {
    return (
      <AuthContainerFormWrapperAlternative>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, palette);
          }

          return child;
        })}
      </AuthContainerFormWrapperAlternative>
    );
  }

  return <AuthContainerFormWrapper>{children}</AuthContainerFormWrapper>;
};

export const AuthContainer: FC<React.PropsWithChildren<{ showClose?: boolean }>> = ({
  children,
  showClose = false,
}) => (
  <OuterContainer>
    {isLoginBackNavigationEnabled && <AuthContainerBackNavigation />}
    {showClose && (
      <CloseLink url={Routes.Root()} title="Back to main page">
        {XIcon(21, 21, COLORS.primary)}
      </CloseLink>
    )}
    <AuthContainerForm>{children}</AuthContainerForm>
    <Chevrons width={20} unit="vw" position="right" />
    {isPoweredbyGlobacapEnabled && <GFIPoweredByGlobacap />}
  </OuterContainer>
);
