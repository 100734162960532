import { FinishIcon } from 'svg-icons';
import { StandardButton } from 'shared-parts/components';
import { COLORS } from 'shared-parts/constants';

import { ButtonsPart, Header, Message, ModalWrapper, Note } from './email-change-modal.styled';

const EmailChangeModal = ({ email, hideModal }) => (
  <ModalWrapper>
    {FinishIcon(64, 64, COLORS.success)}
    <Header>Email Change Request Sent</Header>
    <Message>
      Thanks for updating the email.
      <Note>Note: We have sent a verification email to {email}</Note>
    </Message>
    <ButtonsPart>
      <StandardButton onClick={hideModal}>OK</StandardButton>
    </ButtonsPart>
  </ModalWrapper>
);

export default EmailChangeModal;
