import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_TIME,
  DEFAULT_TIME_FORMAT,
  SHORT_MONTH_DATE_FORMAT,
  SHORT_MONTH_DATE_FORMAT_WITH_TIME,
} from 'shared-parts/constants/dates';

import { convertToISO8601Date } from './convert-time';
import validationHelpers from './validation';

const twoDigitsFormat = value => (value < 10 ? `0${value}` : value);

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getLocalTimeString = date => {
  const time = new Date(date).toLocaleTimeString('en-US');

  return `${time.slice(0, time.length - 6)}${time.slice(time.length - 2, time.length)}`;
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getLocalDateString = date =>
  new Date(date)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    .split(' ')
    .join('-');

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getLocalDateTimeString = date => `${getLocalDateString(date)} ${getLocalTimeString(date)}`;

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getTimezoneLocalDateString = ({ date, timezone = 'UTC', withTime = false, ...options }) => {
  if (!date) return '';

  // handles cases when an invalid timezone is specified
  try {
    const fullOptions = {
      timeZone: timezone,
      day: options.day || '2-digit',
      month: options.month || 'short',
      year: options.year || 'numeric',
      hour: options.hour || '2-digit',
      minute: options.minute || '2-digit',
      hour12: options.hour12 || true,
    };

    const [month, day, year, time, hour12] = new Date(date)
      .toLocaleString('en-US', fullOptions)
      .replace(/[-/]/g, ' ')
      .replace(/,/g, '')
      .split(' ');

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = withTime ? ` ${time} ${hour12.toUpperCase()}` : '';

    return formattedDate + formattedTime;
  } catch (e) {
    console.error(e);
    return 'Invalid date';
  }
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getShortMonthFormat = (day, month, yyyy) => {
  const dd = twoDigitsFormat(day);
  const monthIndex = month + 1;
  const mm = twoDigitsFormat(monthIndex);

  return `${dd}-${mm}-${yyyy}`;
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getDateFormatWithTime = (day, month, yyyy, hh, mm) => {
  const shortTimeFormat = getShortMonthFormat(day, month, yyyy);
  return `${shortTimeFormat} ${twoDigitsFormat(hh)}:${twoDigitsFormat(mm)}`;
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getLongMonthFormat = (day, month, yyyy) => {
  const dd = twoDigitsFormat(day);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const mmm = months[month];

  return `${dd}-${mmm}-${yyyy}`;
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getLongDateFormatWithTime = (day, month, yyyy, hh, mm) => {
  const longTimeFormat = getLongMonthFormat(day, month, yyyy);
  return `${longTimeFormat} ${twoDigitsFormat(hh)}:${twoDigitsFormat(mm)}`;
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const getTimeFormat = (hh, mm, ss) =>
  `${twoDigitsFormat(hh)}:${twoDigitsFormat(mm)}:${twoDigitsFormat(ss)}`;

/**
 * @deprecated use dayjs for proper internationalisation
 */
const formatDate = (date, format = SHORT_MONTH_DATE_FORMAT) => {
  if (!date) return '';
  const newDate = new Date(date);
  const dd = newDate.getDate();
  const mm = newDate.getMonth();
  const yyyy = newDate.getFullYear();
  const hh = newDate.getHours();
  const mmm = newDate.getMinutes();
  const ss = newDate.getSeconds();

  switch (format) {
    case SHORT_MONTH_DATE_FORMAT:
      return getShortMonthFormat(dd, mm, yyyy);
    case DEFAULT_DATE_FORMAT_WITH_TIME:
      return getLongDateFormatWithTime(dd, mm, yyyy, hh, mmm);
    case SHORT_MONTH_DATE_FORMAT_WITH_TIME:
      return getDateFormatWithTime(dd, mm, yyyy, hh, mmm);
    case DEFAULT_TIME_FORMAT:
      return getTimeFormat(hh, mmm, ss);
    case DEFAULT_DATE_FORMAT:
    default:
      return getLongMonthFormat(dd, mm, yyyy);
  }
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const toDate = dateStr => {
  if (!dateStr || typeof dateStr !== 'string') {
    return null;
  }

  const [day, month, year] = dateStr.split('-');

  return new Date(year, month - 1, day);
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const toUTCDate = dateStr => {
  const [day, month, year] = dateStr.split('-');

  return new Date(Date.UTC(year, month - 1, day));
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const parseDate = (date, isTimeIncluded = false) => {
  const isValidDate = validationHelpers.checkDate(date, { isTimeIncluded });

  if (!date || !isValidDate) return false;

  const parsedDate = isTimeIncluded ? new Date(convertToISO8601Date(date)) : toDate(date);
  const hoursInTwelveFormat = parsedDate.getHours() % 12;
  const isTwelveHour = hoursInTwelveFormat === 0;
  const day = parsedDate.getDate();
  const month = parsedDate.getMonth();
  const year = parsedDate.getFullYear();
  const hours = isTwelveHour ? 12 : hoursInTwelveFormat;
  const minutes = parsedDate.getMinutes();
  const am = parsedDate.getHours() < 12;

  return { day, month, year, hours, minutes, am };
};

/**
 * @deprecated use dayjs for proper internationalisation
 */
const checkIfDateIsToday = date => {
  const { day, month, year } = parseDate(date);

  return (
    year === new Date().getFullYear() &&
    month === new Date().getMonth() &&
    day === new Date().getDate()
  );
};

/**
 * Strips time from date.
 *
 * @param {Date | number | string} date date (in any format) to strip time from
 * @returns {Date} date without time
 */
const getDateWithoutTime = (date = Date.now()) => {
  return new Date(new Date(date).toDateString());
};

/**
 * `'Kathmandu (GMT+05:45)'` -> `{ timezoneLabel: 'Kathmandu', timezoneOffsetString: '(GMT+05:45)' }`
 */
const splitTimelineLabel = label => {
  const match = label.match(/(.+) (\([a-zA-Z]{1,3}[+-]\d{2}:\d{2}\))/);
  if (match) return { timezoneLabel: match[1], timezoneOffsetString: match[2] };
  return { timezoneLabel: label, timezoneOffsetString: '' };
};

/**
 * Extracts timezone offset number from timezone string
 *
 * `'(GMT+05:45) Kathmandu'` -> `5.75`
 *
 * `'Kathmandu (GMT+05:45)'` -> `5.75`
 *
 * `'(GMT-12:00) International Date Line West'` -> `-12`
 *
 * @deprecated use dayjs for proper internationalisation
 */
const getTimezoneOffsetNumber = timezoneString => {
  const match = timezoneString.match(/GMT([+-])(\d{2}):(\d{2})/);
  if (!match) return null;
  const [, timezoneSign, timezoneHours, timezoneMinutes] = match;
  const numberValue = +timezoneMinutes ? +timezoneHours + +timezoneMinutes / 60 : +timezoneHours;
  return timezoneSign === '-' ? -numberValue : numberValue;
};

const getTimezoneOffset = (date, timezone = 0) =>
  (date.getTimezoneOffset() + timezone * 60) * 60000;

const getTimezoneFromText = (timeZone = 'Europe/London') => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  const offset = (tzDate.getTime() - utcDate.getTime()) / 6e4;
  return offset / 60;
};

function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}

/**
 * convert date from ISO format to datepicker date format
 *
 * @deprecated use dayjs for proper internationalisation and `@frontend/forms` DatePicker / DateTimePicker
 */
const dateFromISO = ({ isoStr, timezone = 0, isTimeIncluded }) => {
  const date = new Date(isoStr);
  const withoutBrowserOffset = new Date(date.getTime() + getTimezoneOffset(date, timezone));
  const year = withoutBrowserOffset.getFullYear();
  const month = withoutBrowserOffset.getMonth() + 1;
  const day = withoutBrowserOffset.getDate();
  const hours = withoutBrowserOffset.getHours();
  const minutes = withoutBrowserOffset.getMinutes();
  const AMPM = hours > 11 ? 'PM' : 'AM';
  const twelveHoursFormat = hours === 12 ? 12 : hours % 12;
  const hh = twoDigitsFormat(twelveHoursFormat);
  const min = twoDigitsFormat(minutes);
  const time = isTimeIncluded ? ` ${hh}:${min}${AMPM}` : '';
  const dd = twoDigitsFormat(day);
  const mm = twoDigitsFormat(month);
  return `${dd}-${mm}-${year}${time}`;
};

/**
 * convert date from the datepicker to ISO date format
 *
 * @deprecated use dayjs for proper internationalisation and `@frontend/forms` DatePicker / DateTimePicker
 */
const dateToISO = ({ dateFromDatepicker, timezone = 0 }) => {
  if (!dateFromDatepicker) return '';

  const [date, time = ''] = dateFromDatepicker.split(' ');
  const [day, month, year] = date.split('-');
  const hours = time.slice(-7, -5);
  const minutes = time.slice(-4, -2);
  const isPM = time.slice(-2) === 'PM';
  let twentyFourFormatHours;
  if (hours !== '12') twentyFourFormatHours = isPM ? +hours + 12 : hours;
  else twentyFourFormatHours = isPM ? 12 : 0;
  const localDate = new Date(year, month - 1, day, twentyFourFormatHours, minutes);
  const utcDate = new Date(localDate.getTime() - getTimezoneOffset(localDate, timezone));
  return dateIsValid(utcDate) ? utcDate.toISOString() : 'Invalid time value';
};

export {
  twoDigitsFormat,
  toDate,
  toUTCDate,
  formatDate,
  getLocalTimeString,
  getLocalDateString,
  getLocalDateTimeString,
  getTimezoneLocalDateString,
  getShortMonthFormat,
  checkIfDateIsToday,
  parseDate,
  getDateWithoutTime,
  splitTimelineLabel,
  getTimezoneOffsetNumber,
  getTimezoneFromText,
  dateFromISO,
  dateToISO,
};
