import { put } from 'redux-saga/effects';

import { showModal } from 'app-state/actions';

import { SWITCH_TO_EDIT_MODE_DISABLED_TEXT } from 'modules/company/deal-management/deal-page/deal-setup/constants';
import ConfirmationModal from 'shared/confirmation-modal';

export default function* handleForbiddenDealEdit(error: { status: number }) {
  if (error.status === 403) {
    yield put(
      showModal({
        component: ConfirmationModal,
        text: SWITCH_TO_EDIT_MODE_DISABLED_TEXT,
        confirmButtonText: 'OK',
        showCloseButton: false,
      }),
    );
  }
}
