import {
  GET_COMPANY_ENTITIES,
  GET_COMPANY_ENTITIES_ERROR,
  GET_COMPANY_ENTITIES_SUCCESS,
} from 'app-state/company-entities/constants';
import type { CompanyEntitiesActionTypes, CompanyEntity } from 'app-state/company-entities/types';

export const getCompanyEntities = (): CompanyEntitiesActionTypes => ({
  type: GET_COMPANY_ENTITIES,
});

export const getCompanyEntitiesSuccess = (
  payload: CompanyEntity[],
): CompanyEntitiesActionTypes => ({
  type: GET_COMPANY_ENTITIES_SUCCESS,
  payload,
});

export const getCompanyEntitiesError = (payload: unknown): CompanyEntitiesActionTypes => ({
  type: GET_COMPANY_ENTITIES_ERROR,
  payload,
});
