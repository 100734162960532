import { createAsyncActionType } from 'helpers/redux.helpers';

const prefix = 'DOWNLOAD_CSV';

export const sendDownloadExportCommandRequest = createAsyncActionType(
  prefix,
  'SEND_EXPORT_COMMAND_REQUEST',
);

export const checkExportCommandJobProgress = createAsyncActionType(
  prefix,
  'CHECK_EXPORT_COMMAND_JOB_PROGRESS',
);
