import { formatDate, LDF } from '@frontend/datetime';

import {
  addCommasToNumber,
  addCurrencySign,
  cutZeros,
  toDefinedFractionSize,
} from 'shared-parts/helpers/format-number';

export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const EMAIL_LABEL = 'Email';
export const ENTITY_LABEL = 'Entity';
export const INSTITUTION_NAME_LABEL = 'Institution Name';
export const INSTITUTION_NUMBER_LABEL = 'Customer No. (Institution)';
export const INSTITUTION_COUNTRY_LABEL = 'Country of Registration';
export const COUNTRY_CODE_LABEL = 'Country';
export const INDIVIDUAL_COUNTRY_CODE_LABEL = 'Country of Residence';
export const ADDRESS_LABEL = 'Address';
export const CITY_LABEL = 'City';
export const POST_CODE_LABEL = 'Postal / Zip Code';

export const SAVE = 'SAVE';
export const EXIT_BUTTON_LABEL = 'Exit without saving';
export const SUBMIT_BUTTON_LABEL = 'Add investor';

export enum INVESTOR_DETAILS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ENTITY_COMPANY_ID = 'entityCompanyId',
  ENTITY_COMPANY_NAME = 'entityCompanyName',
  INSTITUTION_NAME = 'institutionName',
  INSTITUTION_NUMBER = 'institutionNumber',
  INSTITUTION_COUNTRY = 'institutionCountry',
  COUNTRY_CODE = 'countryCode',
  ADDRESS_FIRST_LINE = 'addressFirstLine',
  CITY = 'city',
  POST_CODE = 'postCode',
  LINE2 = 'line2',
  STATE = 'state',
  TAGS = 'tags',
}

export const INITIAL_INVESTOR_DETAILS_FORM_DATA = {
  [INVESTOR_DETAILS.FIRST_NAME]: '',
  [INVESTOR_DETAILS.LAST_NAME]: '',
  [INVESTOR_DETAILS.EMAIL]: '',
  [INVESTOR_DETAILS.ENTITY_COMPANY_ID]: undefined,
  [INVESTOR_DETAILS.INSTITUTION_NAME]: '',
  [INVESTOR_DETAILS.INSTITUTION_NUMBER]: '',
  [INVESTOR_DETAILS.INSTITUTION_COUNTRY]: '',
  [INVESTOR_DETAILS.COUNTRY_CODE]: '',
  [INVESTOR_DETAILS.ADDRESS_FIRST_LINE]: '',
  [INVESTOR_DETAILS.CITY]: '',
  [INVESTOR_DETAILS.POST_CODE]: '',
};

export const INVITE_NEW_INVESTOR = 'Add an investor';

export const INVESTOR_HISTORY_HEADERS = [
  { name: 'Deal' },
  { name: 'Date' },
  { name: '% Order Funded' },
  { name: 'Order Value' },
  { name: 'No Shares Ordered' },
  { name: 'No Shares Funded' },
];

export const INVESTOR_HISTORY_KEYS = [
  {
    field: 'issuanceName',
    styles: () => 'text-transform: capitalize; text-align: left;',
  },
  {
    field: 'createdAt',
    styles: () => 'text-align: right;',
    transform: (value: string): string => formatDate(value, LDF.DATE_TEXTUAL),
  },
  {
    field: 'orderFundedPercentage',
    styles: () => 'text-align: right;',
    transform: (value: string): string => (value && +value > 0 ? `${value}%` : '-'),
  },
  {
    field: 'intendedAmount',
    styles: () => 'text-align: right;',
    transform: (value: number, { currency }: { currency: string }): string =>
      value && +value > 0
        ? addCurrencySign(addCommasToNumber(toDefinedFractionSize(value, 2)), currency)
        : '-',
  },
  {
    field: 'intendedNumberOfTokens',
    styles: () => 'text-align: right;',
    transform: (value: number): string =>
      value && +value > 0 ? addCommasToNumber(cutZeros(value)) : '-',
  },
  {
    field: 'filledNumberOfTokens',
    styles: () => 'text-align: right;',
    transform: (value: number): string =>
      value && +value > 0 ? addCommasToNumber(cutZeros(value)) : '-',
  },
];

export const DOWNLOAD_AS_CSV = 'Download as .CSV';

export const PERMISSIONS_TITLE = 'Investor invited to the following deals:';
