import { useEffect, useState } from 'react';

import { useRequest } from '@frontend/http';

const useTimezones = () => {
  const [timezones, setTimezones] = useState<{ label: string; value: string }[]>([]);
  const regex = /\(([a-zA-Z]{1,3}[+-]\d{2}:\d{2})\) (.+)/;

  const { data: timezoneData } = useRequest(
    '/api/v1/timezones',
    { staleTime: Infinity },
    { camelCase: false },
  );

  useEffect(() => {
    if (timezoneData) {
      setTimezones(
        Object.keys(timezoneData)
          .map(key => {
            const match = timezoneData[key].match(regex);
            if (match && match[1] && match[2]) {
              return {
                value: key,
                label: `${match[2]} (${match[1]})`,
              };
            }
            return {
              value: key,
              label: timezoneData[key],
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
  }, [timezoneData]);

  return {
    timezones,
  };
};

export default useTimezones;
